import Auth from '@aws-amplify/auth'
import { API_SERVICE_URL } from '../config/service'
import { MyRegistryRequest } from '../models/myRegistryRequest'
import api from './auth/client'

const serviceConfig = require('../config/service.json')
const queryString = require('query-string')

class MyRequestService {
    async getUserId() {
        let auth = await Auth.currentSession()
        let idToken = auth.getIdToken()
        const ID_token = idToken.getJwtToken()
        let parts = ID_token.split('.')
        let user_info = JSON.parse(atob(parts[1]))
        return user_info['cognito:username']
    }
    async getOptimizationOptimizationUpload(optimizationRequest: any) {
        let offSet = 0
        let count = 0
        if (optimizationRequest) {
            offSet = optimizationRequest.offset
            count = optimizationRequest.count
        }
        var url = API_SERVICE_URL + serviceConfig.getOptimizationRequestURL
        return api.get(
            `${url}?requestType=optimizationUpload&offset=${offSet}&count=${count}`
        )
    }
    async getOptimizationNewOptimization(optimizationRequest: any) {
        let offSet = 0
        if (optimizationRequest) offSet = optimizationRequest.offset
        var url = API_SERVICE_URL + serviceConfig.getOptimizationRequestURL
        return api.get(`${url}?requestType=newOptimization`)
    }

    deleteOptimizationRequest(id: string) {
        var url = `${API_SERVICE_URL}${serviceConfig.getOptimizationRequestURL}/${id}`
        return api.delete(url)
    }

    async getBenchmarkRequests() {
        var url = API_SERVICE_URL + serviceConfig.getBenchmarkURL
        return api.get(`${url}`)
    }

    deleteBenchmarkRequest(id: string) {
        var url = `${API_SERVICE_URL}${serviceConfig.getFeedbackURL}/${id}`
        // return api.delete(url)
        return Promise.resolve({})
    }

    async getFeedback(feedbackRequest: any) {
        let offSet = 0
        let count = 0
        if (feedbackRequest) {
            offSet = feedbackRequest.offset
            count = feedbackRequest.count
        }
        var url =
            API_SERVICE_URL +
            serviceConfig.getFeedbackURL +
            '?offset=' +
            offSet +
            '&count=' +
            count
        return api.get(`${url}`)
    }
    async getOptimizationOwnerFeedback(feedbackRequest: any) {
        let offSet = 0
        let count = 0
        if (feedbackRequest) {
            offSet = feedbackRequest.offset
            count = feedbackRequest.count
        }
        var url =
            API_SERVICE_URL +
            serviceConfig.getOptimizationOwnerFeedbackURL +
            '?offset=' +
            offSet +
            '&count=' +
            count
        return api.get(`${url}`)
    }

    deleteFeedback(id: string) {
        var url = `${API_SERVICE_URL}${serviceConfig.getFeedbackURL}/${id}`
        return api.delete(url)
    }
    async getFeedbackById(paramObj: any) {
        const isController = paramObj && paramObj.isController ? true : false
        var url = `${API_SERVICE_URL}${serviceConfig.getFeedbackURL}/${paramObj.id}`
        if (isController)
            url = `${API_SERVICE_URL}${serviceConfig.getAllSupportRequestURL}/${paramObj.id}`
        return api.get(`${url}`)
    }
    downloadFeedbackFile(downloadUrl: string) {
        return api.get(downloadUrl, { responseType: 'blob' })
    }
    async getOptimizationRequestById(id: any) {
        var url = `${API_SERVICE_URL}${serviceConfig.getOptimizationRequestURL}/${id}`
        return api.get(`${url}`)
    }
    async getBenchmarkById(id: any) {
        var url = `${API_SERVICE_URL}${serviceConfig.getBenchmarkURL}/${id}`
        return api.get(`${url}`)
    }
}

export default new MyRequestService()
