/************************************************
 * Copyright (C) 2021 Intel Corporation
 ************************************************/
import styled from 'styled-components'
import { FunctionComponent, Dispatch, useEffect } from 'react'
import { connect } from 'react-redux'
import FooterPanel from '../../layout/footer'
import PageLinkPanel from '../../layout/pageLinks'
import { FormLabel } from 'react-bootstrap'
import CatalogPanel from '../../components/catalogPanel/catalogPanel'
import { CatalogSearchRequest } from '../../models/catalogSearchrequest'
import {
    getCatalog,
    downloadCatalog,
    submitFeedback,
    saveFavorite,
    getTagsValues,
    saveStarRating,
    saveVoting,
    getRecipeById,
    saveLinkClick,
    saveSiteFeedback,
    getLastLogin,
    saveLike,
} from '../../store/catalog.slice'
import { LoadingState } from '../../models/loadingState'
import { CatalogSearchResponse } from '../../models/catalogSearchResponse'
import { DownloadCatalogResponse } from '../../models/downloadCatalogResponse'
import { CatalogContainerLogic } from './catalogContainer.hook'
import { RecipeFeedbackRequest } from '../../models/recipeFeedbackRequest'
import { Recipefeedbackresponse } from '../../models/recipefeedbackresponse'
import { RecipeAddFavoriteRequest } from '../../models/recipeAddFavoriteRequest'
import { RecipeAddFavoriteResponse } from '../../models/recipeAddFavoriteResponse'
import { RecipeTagsValuesResponse } from '../../models/recipeTagsValuesResponse'
import { RecipeStarRatingResponse } from '../../models/recipeStarRatingResponse'
import { RecipeStarRatingRequest } from '../../models/recipeStarRatingRequest'
import { RecipeAddVotingRequest } from '../../models/recipeAddVotingRequest'
import { RecipeAddVotingResponse } from '../../models/recipeAddVotingResponse'
import { RecipeAddLinkClickRequest } from '../../models/recipeAddLinkClickRequest'
import { SiteFeedbackRequest } from '../../models/siteFeedbackRequest'
import { getCartItems, updateCartItems } from '../../store/shoppingCart.slice'
import { UpdateShoppingCartRequest } from '../../models/updateShoppingCartRequest'
import { ShoppingCartResponse } from '../../models/shoppingCartResponse'
import { useNavigate } from 'react-router-dom'
import { getSubscription } from '../../store/subscription.slice'
import { SubscriptionsResponse } from '../../models/subscriptionsResponse'
import { getAuthPermissions } from '../../store/authPermissions.slice'
import { AuthPermissions } from '../../models/authPermissions'
import { createOrg } from '../../store/createOrg.slice'
import { CreateOrgRequest } from '../../models/createOrgRequest'
import { ShoppingCartRequest } from '../../models/shoppingCartRequest'
import { RecipeAddLikeResponse } from '../../models/recipeAddLikeResponse'
import { RecipeAddLikeRequest } from '../../models/recipeAddLikeRequest'
import { getClickCounts } from '../../store/dashboard.slice'

const CatalogPanelContainer = styled.div`
    display: flex;
    height: ${(props) => props.theme.size.mainContainerHeight};
    width: ${(props) => props.theme.size.mainContainerWidth};

    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: space-between;
    @media screen and (max-width: 500px) {
        width: 100%;
        height: 100%;
    }
`

const CatalogHorizontalContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: space-between;
`
const CatalogBodyContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: center;
    background-color: ${(props) => props.theme.color.background.defaultGrey};
    padding-bottom: 10rem;
    width: 100%;
`

const CatalogBodyContainerReposition = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
`

const PageLinkPanelPanelWrapper = styled.div`
    display: flex;
    margin-left: 2rem;
    display: none;
`

export interface ICatalogContainerProps {
    getCatalog: typeof getCatalog
    downloadCatalog: typeof downloadCatalog
    downloadCatalogLoading: LoadingState
    downloadCatalogError: any
    catalogloading: LoadingState
    error: any
    catalogResult: CatalogSearchResponse
    downloadCatalogResult: DownloadCatalogResponse
    submitFeedback: typeof submitFeedback
    feedbackResult: Recipefeedbackresponse
    feedbackError: any
    feedbackLoading: LoadingState
    saveFavorite: typeof saveFavorite
    favoriteResult: RecipeAddFavoriteResponse
    favoriteError: any
    favoriteLoading: LoadingState
    getTagsValues: typeof getTagsValues
    getTagsValuesResult: RecipeTagsValuesResponse
    saveStarRating: typeof saveStarRating
    saveRatingResult: RecipeStarRatingResponse
    saveRatingError: any
    saveVoting: typeof saveVoting
    saveVotingResult: RecipeAddVotingResponse
    saveVotingError: any
    getRecipeById: typeof getRecipeById
    saveLinkClick: typeof saveLinkClick
    saveSiteFeedback: typeof saveSiteFeedback
    saveSiteFeedbackResult: any
    saveSiteFeedbackError?: any
    getCartItems: typeof getCartItems
    updateCartItems: typeof updateCartItems
    getCartItemsResult: ShoppingCartResponse
    getCartItemsResultLoading: LoadingState
    getCartItemsResultError: any
    updateCartResult: ShoppingCartResponse
    updateCartResultLoading: LoadingState
    updateCartResultError: any
    getSubscription: typeof getSubscription
    getSubscriptionResult: SubscriptionsResponse
    getSubscriptionResultLoading: LoadingState
    getSubscriptionResultError: any
    getAuthPermissions: typeof getAuthPermissions
    authPermissionsResult: AuthPermissions
    authPermissionsLoading: LoadingState
    authPermissionsError: any
    getLastLogin: typeof getLastLogin
    getLastLoginResult: any
    getLastLoginError: any
    getLastLoginLoading: LoadingState
    createOrg: typeof createOrg
    createOrgLoading: LoadingState
    createOrgResult: any
    createOrgError?: any
    isPublicSearch?: boolean
    saveLike: typeof saveLike
    likeResult: RecipeAddLikeResponse
    likeError?: any
    likeLoading: LoadingState
    getClickCounts: typeof getClickCounts
    clickCountsResult: any
}

const CatalogContainer: FunctionComponent<ICatalogContainerProps> & {
    defaultProps: Partial<ICatalogContainerProps>
} = ({
    getCatalog,
    downloadCatalog,
    downloadCatalogResult,
    downloadCatalogLoading,
    downloadCatalogError,
    catalogloading,
    catalogResult,
    error,
    submitFeedback,
    feedbackResult,
    feedbackError,
    feedbackLoading,
    saveFavorite,
    favoriteResult,
    favoriteError,
    favoriteLoading,
    getTagsValues,
    getTagsValuesResult,
    saveStarRating,
    saveRatingResult,
    saveRatingError,
    saveVoting,
    saveVotingResult,
    saveVotingError,
    getRecipeById,
    saveLinkClick,
    saveSiteFeedback,
    saveSiteFeedbackResult,
    saveSiteFeedbackError,
    getCartItems,
    updateCartItems,
    getCartItemsResult,
    getCartItemsResultLoading,
    getCartItemsResultError,
    updateCartResult,
    updateCartResultLoading,
    updateCartResultError,
    getSubscription,
    getSubscriptionResult,
    getSubscriptionResultLoading,
    getSubscriptionResultError,
    getAuthPermissions,
    authPermissionsResult,
    authPermissionsLoading,
    authPermissionsError,
    getLastLogin,
    getLastLoginResult,
    getLastLoginError,
    getLastLoginLoading,
    createOrg,
    createOrgLoading,
    createOrgResult,
    createOrgError,
    isPublicSearch,
    saveLike,
    likeResult,
    likeError,
    likeLoading,
    getClickCounts,
    clickCountsResult,
}: ICatalogContainerProps) => {
    let path = `${window.location.pathname}`
    if (path && path.indexOf('/public/catalog') > -1) {
        isPublicSearch = true
    } else isPublicSearch = false

    const { navigateToPage } = CatalogContainerLogic({
        getCatalog,
        catalogResult,
        catalogloading,
        error,
        getTagsValues,
        updateCartResult,
        updateCartResultError,
        getSubscription,
        getSubscriptionResult,
        getSubscriptionResultLoading,
        getSubscriptionResultError,
        getLastLogin,
        getLastLoginResult,
        getLastLoginError,
        getLastLoginLoading,
        getCartItems,
        isPublicSearch,
    } as ICatalogContainerProps)

    let navigate = useNavigate()

    useEffect(() => {
        if (navigateToPage) {
            navigate(navigateToPage)
        }
    }, [navigateToPage])

    const UserProfileImage = (
        <img src="/profile.png" height="30" width="30" alt="profile logo" />
    )
    return (
        <CatalogPanelContainer>
            <CatalogHorizontalContainer>
                <PageLinkPanelPanelWrapper>
                    {!isPublicSearch ? (
                        <PageLinkPanel
                            getAuthPermissions={getAuthPermissions}
                            authPermissionsResult={authPermissionsResult}
                            authPermissionsLoading={authPermissionsLoading}
                            authPermissionsError={authPermissionsError}
                            createOrg={createOrg}
                            createOrgLoading={createOrgLoading}
                            createOrgResult={createOrgResult}
                            createOrgError={createOrgError}
                        />
                    ) : (
                        ''
                    )}
                </PageLinkPanelPanelWrapper>
                <CatalogBodyContainer>
                    <CatalogBodyContainerReposition>
                        <CatalogPanel
                            getCatalog={getCatalog}
                            catalogloading={catalogloading}
                            catalogResult={catalogResult}
                            error={error}
                            downloadCatalog={downloadCatalog}
                            downloadCatalogResult={downloadCatalogResult}
                            downloadCatalogLoading={downloadCatalogLoading}
                            downloadCatalogError={downloadCatalogError}
                            submitFeedback={submitFeedback}
                            feedbackResult={feedbackResult}
                            feedbackError={feedbackError}
                            feedbackLoading={feedbackLoading}
                            saveFavorite={saveFavorite}
                            favoriteResult={favoriteResult}
                            favoriteError={favoriteError}
                            favoriteLoading={favoriteLoading}
                            getTagsValuesResult={getTagsValuesResult}
                            saveStarRating={saveStarRating}
                            saveRatingResult={saveRatingResult}
                            saveRatingError={saveRatingError}
                            saveVoting={saveVoting}
                            saveVotingResult={saveVotingResult}
                            saveVotingError={saveVotingError}
                            getRecipeById={getRecipeById}
                            saveLinkClick={saveLinkClick}
                            saveSiteFeedback={saveSiteFeedback}
                            saveSiteFeedbackResult={saveSiteFeedbackResult}
                            saveSiteFeedbackError={saveSiteFeedbackError}
                            getCartItems={getCartItems}
                            getCartItemsResult={getCartItemsResult}
                            getCartItemsResultLoading={
                                getCartItemsResultLoading
                            }
                            getCartItemsResultError={getCartItemsResultError}
                            updateCartItems={updateCartItems}
                            updateCartResult={updateCartResult}
                            updateCartResultLoading={updateCartResultLoading}
                            updateCartResultError={updateCartResultError}
                            authPermissionsResult={authPermissionsResult}
                            isPublicSearch={isPublicSearch}
                            saveLike={saveLike}
                            likeResult={likeResult}
                            likeError={likeError}
                            likeLoading={likeLoading}
                            getClickCounts={getClickCounts}
                            clickCountsResult={clickCountsResult}
                        />
                    </CatalogBodyContainerReposition>
                </CatalogBodyContainer>
            </CatalogHorizontalContainer>
            <FooterPanel></FooterPanel>
        </CatalogPanelContainer>
    )
}

CatalogContainer.defaultProps = {}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        getCatalog: (catalogSearchCriteria: CatalogSearchRequest) =>
            dispatch(getCatalog(catalogSearchCriteria)),
        downloadCatalog: (downloadUrl: string) =>
            dispatch(downloadCatalog(downloadUrl)),
        submitFeedback: (recipeFeedbackRequest: RecipeFeedbackRequest) =>
            dispatch(submitFeedback(recipeFeedbackRequest)),
        saveFavorite: (addFavoriteRequest: RecipeAddFavoriteRequest) =>
            dispatch(saveFavorite(addFavoriteRequest)),
        getTagsValues: (tagsName: string) => dispatch(getTagsValues(tagsName)),
        saveStarRating: (saveRatingRequest: RecipeStarRatingRequest) =>
            dispatch(saveStarRating(saveRatingRequest)),
        saveVoting: (saveVotingRequest: RecipeAddVotingRequest) =>
            dispatch(saveVoting(saveVotingRequest)),
        getRecipeById: (id: any) => dispatch(getRecipeById(id)),
        saveLinkClick: (addLinkClickRequest: RecipeAddLinkClickRequest) =>
            dispatch(saveLinkClick(addLinkClickRequest)),
        saveSiteFeedback: (siteFeedback: SiteFeedbackRequest) =>
            dispatch(saveSiteFeedback(siteFeedback)),
        getCartItems: (shoppingCartRequest: ShoppingCartRequest) =>
            dispatch(getCartItems(shoppingCartRequest)),
        updateCartItems: (
            updateShoppingCartRequest: UpdateShoppingCartRequest
        ) => dispatch(updateCartItems(updateShoppingCartRequest)),
        getSubscription: () => dispatch(getSubscription()),
        getAuthPermissions: () => dispatch(getAuthPermissions()),
        getLastLogin: () => dispatch(getLastLogin()),
        createOrg: (createOrgRequest: CreateOrgRequest) =>
            dispatch(createOrg(createOrgRequest)),
        saveLike: (addLikeRequest: RecipeAddLikeRequest) =>
            dispatch(saveLike(addLikeRequest)),
        getClickCounts: (filters: any) => dispatch(getClickCounts(filters)),
    }
}

const mapStateToProps = (state: any) => {
    return {
        error: state.catalogSearchResult.error,
        catalogloading: state.catalogSearchResult.loading,
        catalogResult: state.catalogSearchResult.data,
        recipeGetResult: state.recipeResult,
        downloadCatalogResult: state.downloadCatalogResult,
        downloadCatalogLoading: state.downloadCatalogResult.loading,
        downloadCatalogError: state.downloadCatalogResult.error,
        feedbackResult: state.submitFeedbackResult.data,
        feedbackError: state.submitFeedbackResult.error,
        feedbackLoading: state.submitFeedbackResult.loading,
        favoriteResult: state.saveFavoriteResult.data,
        favoriteError: state.saveFavoriteResult.error,
        favoriteLoading: state.saveFavoriteResult.loading,
        getTagsValuesResult: state.getTagsValuesResult.data,
        saveRatingResult: state.saveStarRatingResult.data,
        saveRatingError: state.saveStarRatingResult.error,
        saveVotingResult: state.saveVotingResult.data,
        saveVotingError: state.saveVotingResult.error,
        recipeByIdresult: state.getRecipeByIdResult.data,
        saveLinkClickResult: state.saveLinkClickResult.data,
        saveSiteFeedbackResult: state.saveSiteFeedbackResult.data,
        saveSiteFeedbackError: state.saveSiteFeedbackResult.error,
        getCartItemsResult: state.getCartItemsResult.data,
        getCartItemsResultLoading: state.getCartItemsResult.loading,
        getCartItemsResultError: state.getCartItemsResult.error,
        updateCartResult: state.updateCartResult.data,
        updateCartResultLoading: state.updateCartResult.loading,
        updateCartResultError: state.updateCartResult.error,
        getSubscriptionResult: state.getSubscriptionResult.data,
        getSubscriptionResultLoading: state.getSubscriptionResult.loading,
        getSubscriptionResultError: state.getSubscriptionResult.error,
        authPermissionsResult: state.authPermissionsResult.data,
        authPermissionsLoading: state.authPermissionsResult.loading,
        authPermissionsError: state.authPermissionsResult.error,
        getLastLoginResult: state.getLastLoginResult.data,
        getLastLoginLoading: state.getLastLoginResult.loading,
        getLastLoginError: state.getLastLoginResult.error,
        createOrgError: state.createOrgResult.error,
        createOrgLoading: state.createOrgResult.loading,
        createOrgResult: state.createOrgResult.data,
        likeResult: state.saveLikeResult.data,
        likeError: state.saveLikeResult.error,
        likeLoading: state.saveLikeResult.loading,
        clickCountsResult: state.getClickCountsResult.data,
    }
}

type StateToPropsType = ReturnType<typeof mapStateToProps>
type DispatchToPropsType = typeof mapDispatchToProps

export default connect<StateToPropsType, DispatchToPropsType>(
    mapStateToProps,
    mapDispatchToProps
)(CatalogContainer)
