import { useEffect, useState, useRef } from 'react'
import { useAppDispatch, useAppSelector } from '../../config/hooks'
import {
    getDiagnostics,
    downloadDiagnostics,
    processDiagnostic,
} from '../../store/diagnostics.slice'
import { Table, Select, Modal, message } from 'antd'
import styled from 'styled-components'
import { FormLabel, Spinner } from 'react-bootstrap'
import { deleteDiagnostic } from '../../store/diagnostics.slice'
import { LoadingState } from '../../models/loadingState'

const { Option } = Select

const DiagnosticsReportComponent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
    margin-left: 3rem;
`

const DiagnosticsPanelWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: flex-start;
`

const PageTitleTextLabel = styled(FormLabel)`
    ${(props) => props.theme.typography.xxxLarge}
    margin-top:2rem;
    margin-bottom: 1rem;
    font-weight: 400;
    font-size: 1.3rem;
`

const DiagnnosticsContainer = styled.div`
    background: #ffffff;
    box-shadow: 0px 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
    border-radius: 1.5rem;
    padding: 2rem;
    margin: 0 2rem 2rem 0;
    max-width: 80%;
    min-width: 50rem;
    min-height: 80%;
`
const SpinnerDiv = styled.div`
    display: flex;
    align-items: left;
    justify-content: left;
    margin-top: 5rem;
    width: 100%;
`

export const DiagnosticsReportPanel: React.FC = () => {
    const dispatch = useAppDispatch()
    const downloadLinkRef = useRef<any>(null)
    const [fileName, setFileName] = useState('')
    const [fileDownloadUrl, setFileDownloadUrl] = useState('')
    const [extension, setExtension] = useState('')
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [currentDiagnostic, setCurrentDiagnostic] = useState<any>(null)
    const [isActionRequested, setIsActionRequested] = useState(false)

    const diagnostics = useAppSelector<any>((state) => state.diagnostics.data)
    const diagnosticsError = useAppSelector<any>(
        (state) => state.diagnostics.error
    )
    const diagnosticsLoading = useAppSelector<any>(
        (state) => state.diagnostics.loading
    )
    const downloadData = useAppSelector<any>(
        (state) => state.diagnostics.downloadData
    )
    const deleteData = useAppSelector<any>(
        (state) => state.diagnostics.deleteData
    )
    const processData = useAppSelector<any>(
        (state) => state.diagnostics.processData
    )

    useEffect(() => {
        dispatch(getDiagnostics())
    }, [])

    useEffect(() => {
        if (diagnosticsError) message.error('Error retrieving Diagnostics')
    }, [diagnosticsError])

    useEffect(() => {
        if (downloadData && isActionRequested) {
            const fileDownloadUrl = URL.createObjectURL(
                new Blob([downloadData], { type: `application/${extension}` })
            )
            setFileDownloadUrl(fileDownloadUrl)
            if (downloadLinkRef && downloadLinkRef.current) {
                downloadLinkRef.current.href = fileDownloadUrl
                downloadLinkRef.current.download = fileName
                downloadLinkRef.current.click() //download file
            }
            URL.revokeObjectURL(fileDownloadUrl)
            setIsActionRequested(false)
        }
    }, [downloadData])

    useEffect(() => {
        if (deleteData && isActionRequested) {
            message.success('Successfully deleted diagnostic')
            dispatch(getDiagnostics())
            setIsActionRequested(false)
        }
    }, [deleteData])

    useEffect(() => {
        if (processData && isActionRequested) {
            message.success('Successfully processed diagnostic')
            dispatch(getDiagnostics())
            setIsActionRequested(false)
        }
    }, [processData])

    const handleChange = (value: string, record: any) => {
        setCurrentDiagnostic(record)
        if (value === 'delete') {
            setIsModalOpen(true)
            setIsActionRequested(true)
        } else if (value === 'process') {
            dispatch(processDiagnostic(record.uuid))
            setIsActionRequested(true)
        } else if (value === 'download_pdf') {
            dispatch(downloadDiagnostics(`${record.uuid}?report=pdf`))
            setFileName(record.uuid + '_IntelCloudInsightsReport.pdf')
            setExtension('pdf')
            setIsActionRequested(true)
        } else if (value === 'download_spreadsheet') {
            dispatch(downloadDiagnostics(`${record.uuid}?report=spreadsheet`))
            setFileName(record.uuid + '_IntelCloudInsightsReport.xlsx')
            setExtension('xlsx')
            setIsActionRequested(true)
        } else {
            dispatch(downloadDiagnostics(record.uuid))
            //let fields = record.filename.split('.')
            setFileName(record.uuid + '_IntelCloudInsightsReport')
            setExtension('')
            setIsActionRequested(true)
        }
    }
    const renderCloudimage = (analyzed_results: any) => (
        <div>
            {analyzed_results === 'azure' ? (
                <img
                    src="/azure.png"
                    height="35px"
                    width="55px"
                    alt="Cloud Computng"
                />
            ) : analyzed_results === 'aws' ? (
                <img
                    src="/aws.png"
                    height="30px"
                    width="50px"
                    alt="Cloud Computng"
                />
            ) : analyzed_results === 'gcp' ? (
                <img
                    src="/gcp.png"
                    height="40px"
                    width="55px"
                    alt="Cloud Computng"
                />
            ) : (
                <img
                    src="/Cloud-Computing.png"
                    height="39px"
                    width="55px"
                    alt="Cloud Computng"
                />
            )}
        </div>
    )

    const diagnosticsColumns = [
        {
            title: 'Cloud',
            dataIndex: 'analyzed_results',
            key: 'analyzed_results',
            render: (analyzed_results: any) => (
                <div>{renderCloudimage(analyzed_results)}</div>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'analyzed_status',
            key: 'analyzed_status',
        },
        {
            title: 'ID',
            dataIndex: 'uuid',
            key: 'uuid',
            render: (uuid: string) => (
                <div data-testid={`test-row-${uuid}`}>{uuid}</div>
            ),
        },
        {
            title: 'Created At',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (text: string) => new Date(text).toDateString(),
        },

        {
            title: 'Actions',
            width: '15%',
            render: (record: any) => (
                <Select
                    placeholder="Select"
                    onChange={(value: string) => handleChange(value, record)}
                    style={{ width: '15rem' }}
                >
                    <Option value="download_pdf">Download PDF Report</Option>
                    <Option value="download_spreadsheet">
                        Download Spreadsheet Report
                    </Option>
                    <Option value="delete">Delete</Option>
                    <Option value="process">Process</Option>
                </Select>
            ),
        },
    ]

    const handleOk = () => {
        currentDiagnostic && dispatch(deleteDiagnostic(currentDiagnostic.uuid))
        dispatch(getDiagnostics())
        setIsModalOpen(false)
    }

    const handleCancel = () => {
        setIsModalOpen(false)
    }

    return (
        <DiagnosticsReportComponent>
            <DiagnosticsPanelWrapper>
                <PageTitleTextLabel style={{ marginLeft: '0.2rem' }}>
                    Step 3: View Reports
                </PageTitleTextLabel>
                {diagnosticsLoading !== LoadingState.Pending ? (
                    <DiagnnosticsContainer>
                        <Table
                            rowKey="uuid"
                            columns={diagnosticsColumns}
                            dataSource={
                                diagnostics
                                    ? diagnostics.diagnostics
                                          .slice()
                                          .sort(
                                              (a: any, b: any) =>
                                                  new Date(
                                                      b.created_at
                                                  ).getTime() -
                                                  new Date(
                                                      a.created_at
                                                  ).getTime()
                                          )
                                    : ''
                            }
                        />
                    </DiagnnosticsContainer>
                ) : (
                    <SpinnerDiv>
                        <Spinner animation="border" variant="dark">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </SpinnerDiv>
                )}
            </DiagnosticsPanelWrapper>

            <a
                className="hidden"
                download={fileName}
                href={fileDownloadUrl}
                ref={downloadLinkRef}
            ></a>
            <Modal
                title="Delete Diagnostic"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <p>Are you sure you want to delete this Diagnostic?</p>
            </Modal>
        </DiagnosticsReportComponent>
    )
}
