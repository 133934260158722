/************************************************
 * Copyright (C) 2021 Intel Corporation
 ************************************************/
import styled from 'styled-components'
import { FunctionComponent, Dispatch } from 'react'
import { connect } from 'react-redux'
import { Alert, Button, Form, FormLabel } from 'react-bootstrap'
import { ActivateAccountPanelLogic } from './activateAccountContainer.hook'
import { createOrg } from '../../store/createOrg.slice'
import { LoadingState } from '../../models/loadingState'
import { acceptInvites } from '../../store/invitation.slice'
import { AcceptInvitesResponse } from '../../models/acceptInvitesResponse'
import { CreateOrgRequest } from '../../models/createOrgRequest'
import { AcceptInvitesRequest } from '../../models/acceptInvitesRequest'
import { useLocation } from 'react-router-dom'
import stringUtils from '../../lib/stringUtils'

const ActivateAccountPanelContainer = styled.div`
    display: flex;
    height: ${(props) => props.theme.size.mainContainerHeight};
    width: ${(props) => props.theme.size.mainContainerWidth};
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: space-between;
    @media screen and (min-width: 1200px) {
        overflow-x: hidden;
    }
`
const ActivateAccountHorizontalContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: space-between;
`
const ActivateAccountBodyContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: center;
    background-color: #fff;
    padding-bottom: 10rem;
`

const ActivateAccountBodyContainerReposition = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
`
const ActivateAccountPanelWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: center;
`
const IntelLogoStyling = styled.div`
    display: flex;
    margin-left: 2rem;
    margin-top: 1rem;
    padding: 0.5rem;
    @media screen and (max-width: 500px) {
        margin-left: 35%;
    }
`
const HeaderContainer = styled.div`
    display: flex;
    background-color: #fff;
    padding-bottom: 2rem;
`
const AccountFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
`
const HorizontalContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    margin-left: 2.6rem;
`
const PageTitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`

const PageTitleTextLabel = styled(FormLabel)`
    ${(props) => props.theme.typography.xxxLarge}
    text-align: left;
    font-family: 'IntelOne Display';
    font-style: normal;
    font-weight: 600;
`
const PageTitleTextLabel2 = styled(FormLabel)`
    text-align: left;
    font-family: 'IntelOne Display';
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    padding-top: 1rem;
    padding-bottom: 2rem;
`
const ConfirmCodeComponent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (min-width: 100rem) {
        flex-grow: 1;
    }
`
const ConfirmButtonComponentDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-top: 2rem;
`
const ResendCodeButtonComponent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
`
const DidNotGetCodeDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-top: 1rem;
    margin-left: 7rem;
    //width: 100%;
`
const AlertComponent = styled(Alert)`
    display: flex;
    margin-top: 1rem;
    width: 35vw;
    justify-content: center;
`
const TextBoxWrapper = styled(Form.Control)`
    display: flex;
    border-radius: ${(props) => props.theme.shape.input.borderRadius};
    margin-bottom: 1rem;
    max-width: 25rem;
    @media screen and (max-width: 500px) {
        width: 20rem;
    }
`
const TextLabel = styled(Form.Label)`
    display: flex;
    ${(props) => props.theme.typography.xLarge}
    text-align: left;
    font-weight: 500;
`

const RequiredTextLabel = styled(FormLabel)`
    display: flex;
    ${(props) => props.theme.typography.xLarge}
    color: red;
`
const SignUpButton = styled(Button)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 10rem;
    max-width: 10rem;
    margin-right: 1rem;
    border-radius: ${(props) => props.theme.shape.button.borderRadius};
    background-color: #337ab7;
    border-color: #2e6da4;
    &:hover {
        background-color: #0d6efd;
        border-color: #0d6efd;
        transition: 0.5s;
    }
    border-radius: 0px;
`
const ResetButton = styled(SignUpButton)`
    margin-left: 1rem;
`
const SubmitButton = styled(SignUpButton)`
    margin-right: 0rem;
`
const ResendCodeButton = styled(SignUpButton)`
    width: 75%;
    max-width: 80%;
`
const HavingAccountDiv = styled.div`
    display: flex;
    padding-top: 1.5rem;
    padding-bottom: 8rem;
`

export interface IActivateAccountProps {
    invitationKey?: string
    createOrg: typeof createOrg
    createOrgLoading: LoadingState
    createOrgResult: any
    createOrgError?: any

    acceptInvites: typeof acceptInvites
    acceptInvitesError: any
    acceptInvitesLoading: LoadingState
    acceptInvitesResult: AcceptInvitesResponse
}

const ActivateAccountContainer: FunctionComponent<IActivateAccountProps> & {
    defaultProps: Partial<IActivateAccountProps>
} = ({
    createOrg,
    createOrgLoading,
    createOrgResult,
    createOrgError,
    acceptInvites,
    acceptInvitesError,
    acceptInvitesLoading,
    acceptInvitesResult,
}: IActivateAccountProps) => {
    const location = useLocation()
    const qParam = new URLSearchParams(location.search)
    const invitationKey = qParam.get('invitationKey')
        ? qParam.get('invitationKey')
        : localStorage.getItem('invitationKey')
    const {
        onAccountOrgChange,
        onAccountEmailChange,
        onAccountPasswordChange,
        onCodeConfirmKeyDown,
        onSubmit,
        validated,
        onCancel,
        onError,
        onSuccess,
        onCodeConfirmChange,
        onResendConfirmationCode,
    } = ActivateAccountPanelLogic({
        createOrg,
        createOrgLoading,
        createOrgResult,
        createOrgError,
        acceptInvites,
        acceptInvitesError,
        acceptInvitesLoading,
        acceptInvitesResult,
        invitationKey,
    } as IActivateAccountProps)
    return (
        <ActivateAccountPanelContainer>
            <HeaderContainer>
                <IntelLogoStyling>
                    <img
                        src={stringUtils.resolve('/Intel_energyblue_logo.png')}
                        height="65"
                        width="171"
                        alt="intel logo"
                    />
                </IntelLogoStyling>
            </HeaderContainer>
            <ActivateAccountHorizontalContainer>
                <ActivateAccountBodyContainer>
                    <ActivateAccountBodyContainerReposition>
                        <ActivateAccountPanelWrapper>
                            <AccountFormContainer>
                                <PageTitleContainer>
                                    <PageTitleTextLabel>
                                        {'Activate an account'}
                                    </PageTitleTextLabel>
                                </PageTitleContainer>
                                <PageTitleContainer>
                                    <PageTitleTextLabel2 data-testid="pageSubTitle-lable">
                                        {
                                            'Already have confirmation code, activate your account here!'
                                        }
                                    </PageTitleTextLabel2>
                                </PageTitleContainer>
                                <HorizontalContainer>
                                    <ConfirmCodeComponent>
                                        <AlertComponent
                                            style={{ width: '25rem' }}
                                            variant="danger"
                                            show={
                                                onError?.message ? true : false
                                            }
                                            data-testid="signup-error-text"
                                        >
                                            {'Error! ' + onError?.message}
                                        </AlertComponent>
                                        <AlertComponent
                                            style={{ width: '25rem' }}
                                            variant="success"
                                            show={
                                                onSuccess?.message
                                                    ? true
                                                    : false
                                            }
                                        >
                                            {'Success! ' + onSuccess?.message}
                                        </AlertComponent>
                                        <Form
                                            noValidate
                                            validated={validated}
                                            onSubmit={onSubmit}
                                            id="CodeConfirmForm"
                                        >
                                            {stringUtils.isNullOrUndefinedOrEmpty(
                                                invitationKey
                                            ) ? (
                                                <Form.Group controlId="OrgName">
                                                    <TextLabel data-testid="organization-name-label">
                                                        <RequiredTextLabel>
                                                            *
                                                        </RequiredTextLabel>
                                                        Organization name{' '}
                                                    </TextLabel>
                                                    <TextBoxWrapper
                                                        placeholder="Enter your org's name..."
                                                        onChange={
                                                            onAccountOrgChange
                                                        }
                                                        required
                                                        data-testid="organization-name-text"
                                                    />

                                                    <Form.Control.Feedback type="invalid">
                                                        Please enter your org's
                                                        name.
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            ) : (
                                                <div></div>
                                            )}
                                            <Form.Group controlId="formBasicEmail">
                                                <TextLabel data-testid="email-address-label">
                                                    <RequiredTextLabel>
                                                        *
                                                    </RequiredTextLabel>
                                                    Email address{' '}
                                                </TextLabel>
                                                <TextBoxWrapper
                                                    type="email"
                                                    placeholder="Enter your email..."
                                                    onChange={
                                                        onAccountEmailChange
                                                    }
                                                    required
                                                    data-testid="email-address-text"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please enter an email
                                                    address in format
                                                    name@xyg.com.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group controlId="formBasicPassword">
                                                <TextLabel>
                                                    <RequiredTextLabel>
                                                        *
                                                    </RequiredTextLabel>
                                                    Password{' '}
                                                </TextLabel>
                                                <TextBoxWrapper
                                                    id="userPassword"
                                                    name="userPassword"
                                                    type="password"
                                                    placeholder="Enter your password..."
                                                    autoComplete="new-password"
                                                    onChange={
                                                        onAccountPasswordChange
                                                    }
                                                    required
                                                />

                                                <Form.Control.Feedback type="invalid">
                                                    Please enter your password.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group controlId="code">
                                                <TextLabel data-testid="entercode-label">
                                                    <RequiredTextLabel>
                                                        *
                                                    </RequiredTextLabel>
                                                    Confirmation Code{' '}
                                                </TextLabel>
                                                <TextBoxWrapper
                                                    placeholder="Enter your confirmation code here..."
                                                    type="search"
                                                    onChange={
                                                        onCodeConfirmChange
                                                    }
                                                    onKeyDown={
                                                        onCodeConfirmKeyDown
                                                    }
                                                    required
                                                    data-testid="entercode-text"
                                                />

                                                <Form.Control.Feedback type="invalid">
                                                    Please enter your
                                                    confirmation code.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group>
                                                <ConfirmButtonComponentDiv>
                                                    <SubmitButton
                                                        variant="primary"
                                                        type="submit"
                                                        onClick={onSubmit}
                                                        id="SubmitCodeConfirm"
                                                        data-testid="confirmCode-submit-button"
                                                    >
                                                        Submit
                                                    </SubmitButton>
                                                    <ResetButton
                                                        variant="primary"
                                                        type="reset"
                                                        onClick={onCancel}
                                                        data-testid="reset-button"
                                                    >
                                                        Reset
                                                    </ResetButton>
                                                </ConfirmButtonComponentDiv>
                                                <ResendCodeButtonComponent>
                                                    <DidNotGetCodeDiv>
                                                        Didn't get code?
                                                    </DidNotGetCodeDiv>
                                                    <ResendCodeButton
                                                        variant="primary"
                                                        type="resend"
                                                        onClick={
                                                            onResendConfirmationCode
                                                        }
                                                        data-testid="resend-button"
                                                    >
                                                        Resend confirmation code
                                                    </ResendCodeButton>
                                                </ResendCodeButtonComponent>

                                                <br />
                                                <HavingAccountDiv>
                                                    <div>
                                                        <span>
                                                            Already have an
                                                            account?
                                                        </span>
                                                        &nbsp;
                                                        <a href="/signIn">
                                                            Sign in
                                                        </a>
                                                    </div>
                                                    &nbsp; &nbsp;
                                                    <div>
                                                        <p>
                                                            <span>
                                                                Need an account?
                                                            </span>
                                                            &nbsp;
                                                            <a href="/createAccount">
                                                                Sign up
                                                            </a>
                                                        </p>
                                                    </div>{' '}
                                                </HavingAccountDiv>
                                            </Form.Group>
                                        </Form>
                                    </ConfirmCodeComponent>
                                </HorizontalContainer>
                            </AccountFormContainer>
                        </ActivateAccountPanelWrapper>
                    </ActivateAccountBodyContainerReposition>
                </ActivateAccountBodyContainer>
            </ActivateAccountHorizontalContainer>
        </ActivateAccountPanelContainer>
    )
}

ActivateAccountContainer.defaultProps = {}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        createOrg: (createOrgRequest: CreateOrgRequest) =>
            dispatch(createOrg(createOrgRequest)),

        acceptInvites: (acceptInvitesRequest: AcceptInvitesRequest) =>
            dispatch(acceptInvites(acceptInvitesRequest)),
    }
}

const mapStateToProps = (state: any) => {
    return {
        createOrgError: state.createOrgResult.error,
        createOrgLoading: state.createOrgResult.loading,
        createOrgResult: state.createOrgResult.data,

        acceptInvitesError: state.acceptInvitesResult.error,
        acceptInvitesLoading: state.acceptInvitesResult.loading,
        acceptInvitesResult: state.acceptInvitesResult.data,
    }
}

type StateToPropsType = ReturnType<typeof mapStateToProps>
type DispatchToPropsType = typeof mapDispatchToProps

export default connect<StateToPropsType, DispatchToPropsType>(
    mapStateToProps,
    mapDispatchToProps
)(ActivateAccountContainer)
