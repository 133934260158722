import { API_SERVICE_URL } from '../config/service'
import { PaymentProfileCreateFinalizeRequest } from '../models/paymentProfileCreateFinalizeRequest'
import { PaymentProfileCreateInitRequest } from '../models/paymentProfileCreateInitRequest'
import api from './auth/client'

const serviceConfig = require('../config/service.json')

class PaymentProfileService {
    getPaymentProfiles() {
        var url = API_SERVICE_URL + serviceConfig.paymentProfile
        return api.get(url)
    }

    paymentProfileCreateInit(
        paymentProfileCreateInitRequest: PaymentProfileCreateInitRequest
    ) {
        var url = API_SERVICE_URL + serviceConfig.paymentProfile
        return api.post(url, paymentProfileCreateInitRequest)
    }

    paymentProfileCreateFinalize(
        paymentProfileCreateFinalizeRequest: PaymentProfileCreateFinalizeRequest
    ) {
        var url =
            API_SERVICE_URL +
            serviceConfig.paymentProfile +
            '/' +
            paymentProfileCreateFinalizeRequest.uid
        return api.post(url, {})
    }
}

export default new PaymentProfileService()
