import { useState, useCallback, FormEvent, ChangeEvent } from 'react'
import { useNavigate } from 'react-router-dom'
import { IChangePasswordContainerProps } from './changePasswordContainer'
import amplify_conf from '../../services/auth/amplify'
import Amplify from '@aws-amplify/core'
import Auth from '@aws-amplify/auth'
Amplify.configure(amplify_conf)
import { KeyboardEvent } from 'react'

export function ChangePasswordContainerLogic({}: IChangePasswordContainerProps) {
    const [validated, setValidated] = useState(false)
    const [userEmail, setUserEmail] = useState<string>('')
    const [forgotPasswordError, setForgotPasswordError] = useState<any>('')
    const [changePasswordError, setchangePasswordError] = useState<any>('')
    const [showForgotPasswordForm, setshowForgotPasswordForm] =
        useState<boolean>(true)
    const [codeValidated, setCodeValidated] = useState(false)
    const [confirmationCode, setConfirmationCode] = useState<string>('')
    const [newPassword, setNewPassword] = useState<string>('')
    const [ConfirmNewPassword, setConfirmNewPassword] = useState<string>('')

    const [isPasswordContainLowerLetter, setIsPasswordContainLowerLetter] =
        useState<boolean>(false)
    const [isPasswordContainSpecialChar, setIsPasswordContainSpecialChar] =
        useState<boolean>(false)
    const [isPasswordContainNumber, setIsPasswordContainNumber] =
        useState<boolean>(false)
    const [isPasswordLength10Char, setIsPasswordLength10Char] =
        useState<boolean>(false)
    const [isPasswordMatched, setIsPasswordMatched] = useState<boolean>(false)
    const [showPasswordvalidation, setShowPasswordvalidation] =
        useState<boolean>(false)

    let navigate = useNavigate()

    async function initiateForgotPassword(username: string) {
        try {
            // Send confirmation code to user's email
            await Auth.forgotPassword(username).then((data) => {
                if (data) setshowForgotPasswordForm(false)
            })
        } catch (err) {
            console.log(err)
            setForgotPasswordError(err)
        }
    }
    async function forgotPasswordSubmit(
        username: string,
        code: string,
        new_password: string
    ) {
        try {
            // Collect confirmation code and new password, then
            await Auth.forgotPasswordSubmit(username, code, new_password).then(
                (data) => {
                    //console.log(data)
                    if (data) navigate('/signIn')
                }
            )
        } catch (err) {
            console.log(err)
            setchangePasswordError(err)
        }
    }

    const onUserEmailChange = useCallback((event: ChangeEvent<HTMLElement>) => {
        const target = event.currentTarget as HTMLTextAreaElement
        if (target.value) {
            setUserEmail(target.value)
        }
    }, [])

    const onForgotPasswordSubmit = useCallback(
        async (event: FormEvent<HTMLFormElement>) => {
            event.preventDefault()

            const form = event.currentTarget
            if (form.checkValidity() === false) {
                event.preventDefault()
                event.stopPropagation()
            }
            setValidated(true)
            if (userEmail) {
                initiateForgotPassword(userEmail) //initiate Forgot password flow
            }
        },
        [userEmail]
    )
    const onCodeConfirmChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLTextAreaElement
            if (target.value) {
                setConfirmationCode(target.value)
            }
        },
        []
    )
    const onNewPasswordChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLTextAreaElement
            if (target.value) {
                setNewPassword(target.value)
            }
        },
        []
    )
    const onConfirmNewPasswordChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLTextAreaElement
            if (target.value) {
                setConfirmNewPassword(target.value)
            }
        },
        []
    )
    const onPasswordKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
        const key = event.key
        const passwordCtrl = event.currentTarget
        if (passwordCtrl && passwordCtrl.value.length > 0) {
            setShowPasswordvalidation(true)

            if (key.match(/[a-z]/gi)) {
                setIsPasswordContainLowerLetter(true)
                if (!passwordCtrl.value.match(/[a-z]/gi)) {
                    setIsPasswordContainLowerLetter(false)
                }
            }
            if (key.match(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi)) {
                setIsPasswordContainSpecialChar(true)
            } else if (
                !passwordCtrl.value.match(
                    /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi
                )
            ) {
                setIsPasswordContainSpecialChar(false)
            }
            if (key.match(/[0-9]/gi)) {
                setIsPasswordContainNumber(true)
            } else if (!passwordCtrl.value.match(/[0-9]/gi)) {
                setIsPasswordContainNumber(false)
            }

            if (passwordCtrl.value.length > 10) {
                setIsPasswordLength10Char(true)
            } else setIsPasswordLength10Char(false)
        } else {
            setIsPasswordContainLowerLetter(false)
            setIsPasswordContainSpecialChar(false)
            setIsPasswordContainNumber(false)
            setIsPasswordLength10Char(false)
            setShowPasswordvalidation(false)
        }
    }
    const onPasswordMatchKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
        const key = event.key
        const passwordMatchCtrl = event.currentTarget
        if (passwordMatchCtrl && passwordMatchCtrl.value.length > 0) {
            if (newPassword === passwordMatchCtrl.value) {
                setIsPasswordMatched(true)
            } else setIsPasswordMatched(false)
        }
    }
    const onCodeConfirmKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        const key = event.key
        if (
            key.match(/[0-9,c,v]/gi) ||
            key === 'Enter' ||
            key === 'Backspace' ||
            key === 'Delete' ||
            key === 'ArrowLeft' ||
            key === 'ArrowRight' ||
            key === 'Home' ||
            key === 'End'
        ) {
            return true
        } else {
            event.preventDefault()
        }
    }
    const onChangePasswordSubmit = useCallback(
        async (event: FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            const form = event.currentTarget
            if (form.checkValidity() === false) {
                event.preventDefault()
                event.stopPropagation()
            }
            setCodeValidated(true)
            if (confirmationCode && newPassword && ConfirmNewPassword) {
                forgotPasswordSubmit(userEmail, confirmationCode, newPassword)
            }
        },
        [confirmationCode, newPassword, ConfirmNewPassword]
    )

    return {
        validated,
        onForgotPasswordSubmit,
        onChangePasswordSubmit,
        onUserEmailChange,
        showForgotPasswordForm,
        userEmail,
        changePasswordError,
        forgotPasswordError,
        codeValidated,
        onCodeConfirmChange,
        onNewPasswordChange,
        onConfirmNewPasswordChange,
        onCodeConfirmKeyDown,
        onPasswordKeyUp,
        onPasswordMatchKeyUp,
        isPasswordContainLowerLetter,
        isPasswordContainSpecialChar,
        isPasswordContainNumber,
        isPasswordLength10Char,
        isPasswordMatched,
        showPasswordvalidation,
    }
}
