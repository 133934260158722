import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { LoadingState } from '../models/loadingState'
import MyRequestService from '../services/myRequestService'

const getOptimizationRequestInitialState = {
    data: null,
    loading: LoadingState.Idle,
    error: null,
}

// First, create the thunk
export const getOptimizationUploadRequest = createAsyncThunk(
    'requests/getOptimizationOptimizationUpload',
    async (myOptimizationRequest: any) => {
        return await MyRequestService.getOptimizationOptimizationUpload(
            myOptimizationRequest
        )
    }
)

// Then, handle actions in your reducers:
const getOptimizationOptimizationUploadSlice = createSlice({
    name: 'getOptimizationUploadSlice',
    initialState: getOptimizationRequestInitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getOptimizationUploadRequest.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(
                getOptimizationUploadRequest.fulfilled,
                (state, action: any) => {
                    if (state.loading === LoadingState.Pending) {
                        state.loading = LoadingState.Idle
                        state.data = action.payload.data
                    }
                }
            )
            .addCase(
                getOptimizationUploadRequest.rejected,
                (state, action: any) => {
                    if (state.loading === LoadingState.Pending) {
                        state.loading = LoadingState.Idle
                        state.error = action.error
                    }
                }
            )
    },
})

// First, create the thunk
export const getOptimizationNewOptimizationRequest = createAsyncThunk(
    'requests/getOptimizationNewOptimization',
    async (myOptimizationRequest: any) => {
        return await MyRequestService.getOptimizationNewOptimization(
            myOptimizationRequest
        )
    }
)

// Then, handle actions in your reducers:
const getOptimizationNewOptimizationSlice = createSlice({
    name: 'getNewOptimizationSlice',
    initialState: getOptimizationRequestInitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(
                getOptimizationNewOptimizationRequest.pending,
                (state, _) => {
                    if (state.loading === LoadingState.Idle) {
                        state.loading = LoadingState.Pending
                    }
                }
            )
            .addCase(
                getOptimizationNewOptimizationRequest.fulfilled,
                (state, action: any) => {
                    if (state.loading === LoadingState.Pending) {
                        state.loading = LoadingState.Idle
                        state.data = action.payload.data
                    }
                }
            )
            .addCase(
                getOptimizationNewOptimizationRequest.rejected,
                (state, action: any) => {
                    if (state.loading === LoadingState.Pending) {
                        state.loading = LoadingState.Idle
                        state.error = action.error
                    }
                }
            )
    },
})

const deleteOptimizationRequestInitialState = {
    data: null,
    loading: LoadingState.Idle,
    error: null,
}

export const deleteOptimizationRequest = createAsyncThunk(
    'requests/deleteOptimizationRequest',
    async (id: string) => await MyRequestService.deleteOptimizationRequest(id)
)

const deleteOptimizationRequestSlice = createSlice({
    name: 'deleteOptimizationRequest',
    initialState: deleteOptimizationRequestInitialState,
    reducers: {
        clearOptimizationRequestData: (state) => {
            state.error = null
            state.data = null
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(deleteOptimizationRequest.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(
                deleteOptimizationRequest.fulfilled,
                (state, action: any) => {
                    if (state.loading === LoadingState.Pending) {
                        state.loading = LoadingState.Idle
                        state.data = action.payload.data
                    }
                }
            )
            .addCase(
                deleteOptimizationRequest.rejected,
                (state, action: any) => {
                    if (state.loading === LoadingState.Pending) {
                        state.loading = LoadingState.Idle
                        state.error = action.error
                    }
                }
            )
    },
})

const getBenchmarkRequestInitialState = {
    data: null,
    loading: LoadingState.Idle,
    error: null,
}

// First, create the thunk
export const getBenchmarkRequest = createAsyncThunk(
    'requests/benchmarkRequest',
    async (benchmarkRequest: any) => {
        return await MyRequestService.getBenchmarkRequests()
    }
)

// Then, handle actions in your reducers:
const getBenchmarkRequestSlice = createSlice({
    name: 'getBenchmarkRequest',
    initialState: getBenchmarkRequestInitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getBenchmarkRequest.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(getBenchmarkRequest.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload.data
                }
            })
            .addCase(getBenchmarkRequest.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})

const deleteBenchmarkRequestInitialState = {
    data: null,
    loading: LoadingState.Idle,
    error: null,
}

export const deleteBenchmarkRequest = createAsyncThunk(
    'requests/deleteOptimizationRequest',
    async (id: string) => await MyRequestService.deleteBenchmarkRequest(id)
)

const deleteBenchmarkRequestSlice = createSlice({
    name: 'deleteBenchmarkRequest',
    initialState: deleteBenchmarkRequestInitialState,
    reducers: {
        clearBenchmarkRequestData: (state) => {
            state.error = null
            state.data = null
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(deleteBenchmarkRequest.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(deleteBenchmarkRequest.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload.data
                }
            })
            .addCase(deleteBenchmarkRequest.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})

const getFeedbackInitialState = {
    data: null,
    loading: LoadingState.Idle,
    error: null,
}
let response: any = {}
// First, create the thunk
export const getFeedback = createAsyncThunk(
    'requests/getFeedback',
    async (feedbackRequest: any) => {
        response = await MyRequestService.getFeedback(feedbackRequest)
    }
)

// Then, handle actions in your reducers:
const getFeedbackSlice = createSlice({
    name: 'getFeedback',
    initialState: getFeedbackInitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getFeedback.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(getFeedback.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    if (
                        action.payload === undefined &&
                        response.data !== undefined
                    )
                        state.data = response.data
                    else state.data = action.payload.data
                    state.error = null
                }
            })
            .addCase(getFeedback.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})
export const getOptimizationOwnerFeedback = createAsyncThunk(
    'requests/getOptimizationOwnerFeedback',
    async (feedbackRequest: any) => {
        response = await MyRequestService.getOptimizationOwnerFeedback(
            feedbackRequest
        )
    }
)

// Then, handle actions in your reducers:
const getOptimizationOwnerFeedbackSlice = createSlice({
    name: 'getOptimizationOwnerFeedback',
    initialState: getFeedbackInitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getOptimizationOwnerFeedback.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(
                getOptimizationOwnerFeedback.fulfilled,
                (state, action: any) => {
                    if (state.loading === LoadingState.Pending) {
                        state.loading = LoadingState.Idle
                        if (
                            action.payload === undefined &&
                            response.data !== undefined
                        )
                            state.data = response.data
                        else state.data = action.payload.data
                        state.error = null
                    }
                }
            )
            .addCase(
                getOptimizationOwnerFeedback.rejected,
                (state, action: any) => {
                    if (state.loading === LoadingState.Pending) {
                        state.loading = LoadingState.Idle
                        state.error = action.error
                    }
                }
            )
    },
})

const deleteFeedbackInitialState = {
    data: null,
    loading: LoadingState.Idle,
    error: null,
}

export const deleteFeedback = createAsyncThunk(
    'requests/deleteFeedback',
    async (id: string) => await MyRequestService.deleteFeedback(id)
)

const deleteFeedbackSlice = createSlice({
    name: 'deleteFeedback',
    initialState: deleteFeedbackInitialState,
    reducers: {
        clearFeedbackData: (state) => {
            state.error = null
            state.data = null
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(deleteFeedback.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(deleteFeedback.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload.data
                }
            })
            .addCase(deleteFeedback.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})

const getFeedbackByIdInitialState = {
    data: null,
    loading: LoadingState.Idle,
    error: null,
}

// First, create the thunk
export const getFeedbackById = createAsyncThunk(
    'requests/getFeedbackById',
    async (paramObj: any) => {
        return await MyRequestService.getFeedbackById(paramObj)
    }
)

// Then, handle actions in your reducers:
const getFeedbackByIdSlice = createSlice({
    name: 'getFeedbackById',
    initialState: getFeedbackByIdInitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getFeedbackById.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(getFeedbackById.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload.data
                }
            })
            .addCase(getFeedbackById.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})
const downloadInitialState = {
    data: null,
    loading: LoadingState.Idle,
    error: null,
}
export const getFeedbackFile = createAsyncThunk(
    'requests/getFeedbackFile',
    async (url: any) => {
        return await MyRequestService.downloadFeedbackFile(url)
    }
)

// Then, handle actions in your reducers:
const getFeedbackFileSlice = createSlice({
    name: 'getFeedbackFile',
    initialState: downloadInitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getFeedbackFile.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(getFeedbackFile.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload.data
                }
            })
            .addCase(getFeedbackFile.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})
export const getOptimizationRequestById = createAsyncThunk(
    'getOptRequestById',
    async (url: any) => {
        return await MyRequestService.getOptimizationRequestById(url)
    }
)

// Then, handle actions in your reducers:
const getOptimizationRequestByIdSlice = createSlice({
    name: 'getOptRequestById',
    initialState: downloadInitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getOptimizationRequestById.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(
                getOptimizationRequestById.fulfilled,
                (state, action: any) => {
                    if (state.loading === LoadingState.Pending) {
                        state.loading = LoadingState.Idle
                        state.data = action.payload.data
                    }
                }
            )
            .addCase(
                getOptimizationRequestById.rejected,
                (state, action: any) => {
                    if (state.loading === LoadingState.Pending) {
                        state.loading = LoadingState.Idle
                        state.error = action.error
                    }
                }
            )
    },
})
export const getBenchmarkRequestById = createAsyncThunk(
    'getBenchmarkRequestById',
    async (id: any) => {
        return await MyRequestService.getBenchmarkById(id)
    }
)

// Then, handle actions in your reducers:
const getBenchmarkRequestByIdSlice = createSlice({
    name: 'getBenchmarkRequestById',
    initialState: downloadInitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getBenchmarkRequestById.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(
                getBenchmarkRequestById.fulfilled,
                (state, action: any) => {
                    if (state.loading === LoadingState.Pending) {
                        state.loading = LoadingState.Idle
                        state.data = action.payload.data
                    }
                }
            )
            .addCase(getBenchmarkRequestById.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})

export const { clearOptimizationRequestData } =
    deleteOptimizationRequestSlice.actions
export const { clearBenchmarkRequestData } = deleteBenchmarkRequestSlice.actions
export const { clearFeedbackData } = deleteFeedbackSlice.actions
export const optimizationOptimizationUploadResult =
    getOptimizationOptimizationUploadSlice.reducer
export const optimizationNewOptimizationResult =
    getOptimizationNewOptimizationSlice.reducer
export const deleteOptimizationRequestResult =
    deleteOptimizationRequestSlice.reducer
export const benchmarkRequestResult = getBenchmarkRequestSlice.reducer
export const deleteBenchmarkRequestResult = deleteBenchmarkRequestSlice.reducer
export const feedbackResult = getFeedbackSlice.reducer
export const deleteFeedbackResult = deleteFeedbackSlice.reducer
export const feedbackResultById = getFeedbackByIdSlice.reducer
export const feedbackAttachmentResult = getFeedbackFileSlice.reducer
export const optimizationRequestByIdResult =
    getOptimizationRequestByIdSlice.reducer
export const benchmarkResultById = getBenchmarkRequestByIdSlice.reducer
export const optimizationOwnerfeedbackResult =
    getOptimizationOwnerFeedbackSlice.reducer
