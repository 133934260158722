import { Stepper } from 'react-form-stepper'

export const CustomStepper = (props: any) => {
    return (
        <Stepper
            {...props}
            connectorStateColors={true}
            connectorStyleConfig={{
                completedColor: '#8B8E97',
                activeColor: '#8B8E97',
                disabledColor: '#eee',
            }}
            styleConfig={{
                activeBgColor: '#00C7FD',
                completedBgColor: '#00C7FD',
                inactiveBgColor: '#eee',
                activeTextColor: '#000',
                completedTextColor: '#000',
                inactiveTextColor: '#000',
            }}
        />
    )
}
