import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { GetMembershipRequest } from '../models/getMembershipRequest'
import { LoadingState } from '../models/loadingState'
import { UpdateMembershipRequest } from '../models/updateMembershipRequest'
import MembershipService from '../services/membershipService'
import { UpdateUserDefaultOrgRequest } from '../models/UpdateUserDefaultOrgRequest'

const initialState = {
    data: null,
    loading: LoadingState.Idle,
    error: null,
}

// First, create the thunk
export const getMembership = createAsyncThunk(
    'getMembership',
    async (getMembershipRequest: GetMembershipRequest) => {
        return await MembershipService.getMembers(getMembershipRequest)
    }
)

// Then, handle actions in your reducers:
const getMembershipSlice = createSlice({
    name: 'getMembership',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(getMembership.pending, (state, _) => {
            if (state.loading === LoadingState.Idle) {
                state.loading = LoadingState.Pending
            }
        })
        .addCase(getMembership.fulfilled, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.data = action.payload.data
                state.error = null
            }
        })
        .addCase(getMembership.rejected, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.error = action.error
            }
        })
    },
})

// First, create the thunk
export const updateMembership = createAsyncThunk(
    'updateMembership',
    async (updateMembershipRequest: UpdateMembershipRequest) => {
        return await MembershipService.updateMembers(updateMembershipRequest)
    }
)

// Then, handle actions in your reducers:
const updateMembershipSlice = createSlice({
    name: 'updateMembership',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(updateMembership.pending, (state, _) => {
            if (state.loading === LoadingState.Idle) {
                state.loading = LoadingState.Pending
            }
        })
        .addCase(updateMembership.fulfilled, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.data = action.payload.data
                state.error = null
            }
        })
        .addCase(updateMembership.rejected, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.error = action.error
            }
        })
    },
})

// First, create the thunk
export const updateUserDefaultOrg = createAsyncThunk(
    'updateUserDefaultOrg',
    async (updateUserDefaultOrgRequest: UpdateUserDefaultOrgRequest) => {
        return await MembershipService.updateUserDefaultOrg(
            updateUserDefaultOrgRequest
        )
    }
)

// Then, handle actions in your reducers:
const updateUserDefaultOrgSlice = createSlice({
    name: 'updateUserDefaultOrg',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(updateUserDefaultOrg.pending, (state, _) => {
            if (state.loading === LoadingState.Idle) {
                state.loading = LoadingState.Pending
            }
        })
        .addCase(updateUserDefaultOrg.fulfilled, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.data = action.payload.data
                state.error = null
            }
        })
        .addCase(updateUserDefaultOrg.rejected, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.error = action.error
            }
        })
    },
})

export const getMembershipResult = getMembershipSlice.reducer
export const updateMembershipResult = updateMembershipSlice.reducer
export const updateUserDefaultOrgResult = updateUserDefaultOrgSlice.reducer
