import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { LoadingState } from '../models/loadingState'
import RegistryService from '../services/uploadRegistryService'

const registryInitialState = {
    data: null,
    loading: LoadingState.Idle,
    error: null,
}

// First, create the thunk
export const uploadRegistry = createAsyncThunk(
    'registry/uploadRegistry',
    async (uploadRegistryCriteria: any) => {
        return await RegistryService.uploadRegistry(uploadRegistryCriteria)
    }
)

// Then, handle actions in your reducers:
const uploadRegistrySlice = createSlice({
    name: 'uploadRegistry',
    initialState: registryInitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(uploadRegistry.pending, (state, _) => {
            if (state.loading === LoadingState.Idle) {
                state.loading = LoadingState.Pending
            }
        })
        .addCase(uploadRegistry.fulfilled, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.data = action.payload.data
            }
        })
        .addCase(uploadRegistry.rejected, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.error = action.error
            }
        })
    },
})

export const attachFile = createAsyncThunk(
    'registry/attachFile',
    async (payload: any) => {
        return await RegistryService.attachFile(payload)
    }
)

const attachFileSlice = createSlice({
    name: 'attachFile',
    initialState: {
        data: null,
        loading: LoadingState.Idle,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(attachFile.pending, (state, _) => {
            if (state.loading === LoadingState.Idle) {
                state.loading = LoadingState.Pending
            }
        })
        .addCase(attachFile.fulfilled, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.data = action.payload.data
            }
        })
        .addCase(attachFile.rejected, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.error = action.error
            }
        })
    },
})

const initialState = {
    data: null,
    loading: LoadingState.Idle,
    error: null,
}

export const getRegistry = createAsyncThunk(
    'registry/getRegistry',
    async (payload: any) => await RegistryService.getRegistry(payload)
)

const getRegistrySlice = createSlice({
    name: 'getRegistry',
    initialState: initialState,
    reducers: {
        clearData: (state) => {
            state.error = null
            state.data = null
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(getRegistry.pending, (state, _) => {
            if (state.loading === LoadingState.Idle) {
                state.loading = LoadingState.Pending
            }
        })
        .addCase(getRegistry.fulfilled, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.data = action.payload.data
            }
        })
        .addCase(getRegistry.rejected, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.error = action.error
            }
        })
    },
})
export const deleteFile = createAsyncThunk(
    'registry/deleteFile',
    async (payload: any) => {
        return await RegistryService.deleteFile(payload)
    }
)

const deleteFileSlice = createSlice({
    name: 'deleteFile',
    initialState: {
        data: null,
        loading: LoadingState.Idle,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(deleteFile.pending, (state, _) => {
            if (state.loading === LoadingState.Idle) {
                state.loading = LoadingState.Pending
            }
        })
        .addCase(deleteFile.fulfilled, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.data = action.payload.data
            }
        })
        .addCase(deleteFile.rejected, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.error = action.error
            }
        })
    },
})

export const { clearData } = getRegistrySlice.actions

export const uploadRegistryResult = uploadRegistrySlice.reducer
export const getRegistryResult = getRegistrySlice.reducer
export const attachFileResult = attachFileSlice.reducer
export const deleteUploadedOptimizationFileResult = deleteFileSlice.reducer
