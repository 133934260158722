import { UserRoles } from '../models/userRoles'
import { _getDecryptedValueFromStorage } from './encryptStorage'

export function doesUserHaveRole(
    orgRole: UserRoles,
    allRoles: string | null
): boolean {
    let organizationalRoles: string[] = []
    let orgRoles = _getDecryptedValueFromStorage('organizationalRoles')
    if (allRoles && allRoles !== '') {
        orgRoles = allRoles
    }
    if (orgRoles) {
        organizationalRoles = orgRoles.split(',')
    }
    if (organizationalRoles.includes(orgRole)) {
        return true
    }
    return false
}
