import { FunctionComponent } from 'react'
import styled from 'styled-components'
import {
    Button,
    FormControl,
    Spinner,
    Alert,
    FormLabel,
    Form,
    Col,
    Row,
    OverlayTrigger,
    Popover,
    Modal,
} from 'react-bootstrap'
import { LoadingState } from '../../models/loadingState'
import { NewOptimizationPanelLogic } from './newOptimizationPanel.took'
import { NewOptimizationRequest } from '../../models/newOptimizationRequest'
import { NewOptimizationResponse } from '../../models/newOptimizationResponse'
import Select from 'react-select'

const NewOptimizationFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
`
const NewOptimizationFormComponent = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: flex-start;
`
const HorizontalContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-grow: 1;
`

const ButtonSpinnerHorizontalComponent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 1rem;
`

const SubmitButton = styled(Button)`
    display: flex;
    justify-content: center;

    border-radius: ${(props) => props.theme.shape.button.borderRadius};
    background-color: ${(props) =>
        props.theme.color.button.background.default.primary};
    &:hover {
        background-color: ${(props) =>
            props.theme.color.button.background.hover.primary};
        transition: 0.5s;
    }
`
const CancelButton = styled(Button)`
    display: flex;
    justify-content: center;
    margin-left: 1.35rem;
    border-radius: ${(props) => props.theme.shape.button.borderRadius};
    background-color: ${(props) =>
        props.theme.color.button.background.default.primary};
    &:hover {
        background-color: ${(props) =>
            props.theme.color.button.background.hover.primary};
        transition: 0.5s;
    }
`
const TextBoxWrapper = styled(FormControl)`
    display: flex;
    border-radius: ${(props) => props.theme.shape.input.borderRadius};
    margin-bottom: 1rem;
    min-height: 4vh;
`

const PageTitleTextLabel = styled(FormLabel)`
    ${(props) => props.theme.typography.xxxLarge}
    text-align: left;
    margin-bottom: 3rem;
    font-weight: 500;
    font-size: 2.25rem;
`

const TextLabel = styled(FormLabel)`
    display: flex;
    ${(props) => props.theme.typography.xLarge}
    text-align: left;
`

const NewOptimizationResultLabel = styled(FormLabel)`
    display: flex;
    ${(props) => props.theme.typography.medium}
    text-align: left;
    justify-content: left;
    background:  ${(props) => props.theme.color.background.label.title}
    white-space: pre-wrap;
    margin-left: 1rem;
    padding-top:1rem;
`
const SpinnerComponent = styled.div`
    display: flex;
    margin-top: 0.5rem;
`
const AlertComponent = styled(Alert)`
    display: flex;
    margin-top: 1rem;
    width: 35vw;
    justify-content: center;
`
const RequiredTextLabel = styled.span`
    display: flex;
    ${(props) => props.theme.typography.xLarge}
    color: red;
`
const SWMaturityDiv = styled.div`
    margin-left: 1.5rem;
    margin-top: 0.5rem;
`
const PopoverKeyDiv = styled.div`
    background-color: #cff4fc;
    font-size: 1rem;
    padding: 0.5rem;
`
const RequiredFieldMessage = styled.span`
    display: flex;
    font-size: 0.875em;
    color: #dc3545;
`
const ModalPopupContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

export interface INewOptimizationPanelProps {
    onNewOptimizationSubmit: (newOptimizationRequest: any) => void
    newOptimizationLoading: LoadingState
    newOptimizationResult?: NewOptimizationResponse | undefined
    newOptimizationError: any
    keyAudienceList?: { name: string; checkedValue: boolean }[]
    keySegmentList?: { name: string; checkedValue: boolean }[]
}

const NewOptimizationPanel: FunctionComponent<INewOptimizationPanelProps> = ({
    onNewOptimizationSubmit,
    newOptimizationLoading,
    newOptimizationResult,
    newOptimizationError,
    keyAudienceList,
    keySegmentList,
}: INewOptimizationPanelProps) => {
    var errorText = null
    if (newOptimizationError) {
        errorText = 'We are experiencing issues while creating the request.'
    }
    let audienceList: { name: string; checkedValue: boolean }[] = []
    let segmentList: { name: string; checkedValue: boolean }[] = []

    if (audienceList.length === 0) {
        audienceList.push({ name: 'Public Cloud', checkedValue: false })
        audienceList.push({ name: 'Private Cloud', checkedValue: false })
        audienceList.push({ name: 'Multicloud', checkedValue: false })
        audienceList.push({ name: 'Hybrid', checkedValue: false })
        audienceList.push({ name: 'Enterprise', checkedValue: false })
        audienceList.push({ name: 'HPC Cluster', checkedValue: false })
    }
    if (segmentList.length === 0) {
        segmentList.push({ name: 'Engineer Managers', checkedValue: false })
        segmentList.push({ name: 'Line Of Business ', checkedValue: false })
        segmentList.push({ name: 'Developer / Research', checkedValue: false })
        segmentList.push({ name: 'Dev Ops ', checkedValue: false })
        segmentList.push({ name: 'Infrastructure', checkedValue: false })
        segmentList.push({ name: 'Fin Ops', checkedValue: false })
    }

    keyAudienceList = audienceList
    keySegmentList = segmentList

    const {
        onSolutionDescriptionChange,
        onUseCaseChange,
        onScaleLevelChange,
        onDeploymentChange,
        onPlatformChange,
        onSWDetailsChange,
        onTimelineChange,
        onSolutionTypeChange,
        onRequestorChange,
        onCustomerChange,
        onSubmit,
        onCancle,
        validated,
        isRequireKeyAudience,
        isRequireKeySegment,
        onBusinessImpactChange,
        onOtherStakeholderChange,
        onIntelValuePropChange,
        onKeyAudianceChange,
        onKeySegmentChange,
        filtersValues,
        onOptimizationNameChange,
        useCase,
        platform,
        solutionType,
        scaleLevel,
        deploymentModel,
        showConfirmationPopup,
        handleConfirmationClose,
    } = NewOptimizationPanelLogic({
        onNewOptimizationSubmit,
        newOptimizationLoading,
        newOptimizationResult,
        newOptimizationError,
        keyAudienceList,
        keySegmentList,
    } as INewOptimizationPanelProps)

    const sw_maturityPopover = (
        <Popover id="popover-basic" style={{ maxWidth: '80%' }}>
            <Popover.Body>
                <PopoverKeyDiv>
                    <span>
                        Ease of adoption and code maintenance sustainability
                        needed
                    </span>
                    <br />
                    <img
                        src="/sw_maturity_image.png"
                        height="283"
                        width="724"
                        alt="cloud logo"
                    />
                </PopoverKeyDiv>
            </Popover.Body>
        </Popover>
    )
    let KeyAudianceRender = keyAudienceList.map((item, index) => {
        return (
            <Form.Check
                type="checkbox"
                id={item.name + index}
                label={item.name}
                value={item.name}
                aria-label={item.name}
                onChange={onKeyAudianceChange}
                required={isRequireKeyAudience}
                data-testid={`keyAudiance-checkboxLabel-${index}`}
            />
        )
    })
    let keySegmentRender = keySegmentList.map((item, index) => {
        return (
            <Form.Check
                type="checkbox"
                id={item.name + index}
                label={item.name}
                value={item.name}
                aria-label={item.name}
                onChange={onKeySegmentChange}
                required={isRequireKeySegment}
                data-testid={`keySegment-checkboxLabel-${index}`}
            />
        )
    })
    const useCaseOptions = [
        {
            value: 'API Gateways, Load Balancers, Proxies, Web Hosting',
            label: 'API Gateways, Load Balancers, Proxies, Web Hosting',
        },
        {
            value: 'Streaming',
            label: 'Streaming',
        },
        {
            value: 'Search/Indexing',
            label: 'Search/Indexing',
        },
        {
            value: 'Blockchain',
            label: 'Blockchain',
        },
        {
            value: 'Microservices',
            label: 'Microservices',
        },
        {
            value: 'Integrity Checking, Disk Encryption, Etc',
            label: 'Integrity Checking, Disk Encryption, Etc',
        },
        {
            value: 'Databases, Data Storage, Data Services',
            label: 'Databases, Data Storage, Data Services',
        },
        { value: 'AI/ML', label: 'AI/ML' },
        { value: 'HPC', label: 'HPC' },
    ]
    const deploymentOptions = [
        { value: 'On-Prem', label: 'On-Prem' },
        { value: 'Cloud', label: 'Cloud' },
        { value: 'Both', label: 'Both' },
    ]
    const platformOptions = filtersValues
        ? filtersValues['Platform'].map((tag: any) => ({
              value: tag,
              label: tag,
          }))
        : []
    const featureOptions = filtersValues
        ? filtersValues['Feature'].map((tag: any) => ({
              value: tag,
              label: tag,
          }))
        : []

    const scaleLevelValues = filtersValues ? filtersValues['Scale level'] : []
    const TypeOptions = filtersValues
        ? filtersValues['Type'].map((tag: any) => ({
              value: tag,
              label: tag,
          }))
        : []

    const scaleLevelOptions = scaleLevelValues
        ? [...scaleLevelValues].sort().map((tag: any) => ({
              value: tag,
              label: tag,
          }))
        : []

    return (
        <NewOptimizationFormContainer>
            <HorizontalContainer>
                <NewOptimizationFormComponent>
                    <PageTitleTextLabel data-testid="pageTitle-label">
                        Request new optimization
                    </PageTitleTextLabel>
                    <Form
                        noValidate
                        validated={validated}
                        onSubmit={onSubmit}
                        id="uploadForm"
                        style={{ paddingRight: '0.5rem' }}
                    >
                        <Row className="mb-3">
                            <Form.Group as={Col} md="3">
                                <TextLabel data-testid="opt-name-label">
                                    <RequiredTextLabel>*</RequiredTextLabel>
                                    Optimization Name
                                </TextLabel>
                                <TextBoxWrapper
                                    id="optimizationName"
                                    name="optimizationName"
                                    placeholder="Enter optimization name..."
                                    required
                                    data-testid="opt-name-value"
                                    onChange={onOptimizationNameChange}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please enter an optimization name.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="3">
                                <TextLabel data-testid="usecase-label">
                                    <RequiredTextLabel>*</RequiredTextLabel>
                                    Use Case
                                    <OverlayTrigger
                                        placement={'right'}
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                            <Popover
                                                id="pop_usecase"
                                                style={{ maxWidth: '30%' }}
                                            >
                                                <Popover.Body>
                                                    <PopoverKeyDiv>
                                                        API Gateways, AI
                                                        Inferencing, Load
                                                        Balancers, Proxies, Web
                                                        Hosting, Data Services,
                                                        Streaming,
                                                        Search/Indexing,
                                                        Microservices,
                                                        Blockchain, Integrity
                                                        checking, Disk
                                                        encryption, Data
                                                        Storage, Relational
                                                        Database
                                                    </PopoverKeyDiv>
                                                </Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <i
                                            className="uil uil-info-circle pointer"
                                            tabIndex={0}
                                        ></i>
                                    </OverlayTrigger>
                                </TextLabel>
                                <Select
                                    name="useCase"
                                    aria-label="Select use case"
                                    data-testid="usecase-value"
                                    isSearchable={true}
                                    isClearable={true}
                                    isMulti={true}
                                    options={useCaseOptions}
                                    onChange={(target: any) =>
                                        onUseCaseChange(target)
                                    }
                                />
                                {validated &&
                                useCase &&
                                useCase.length === 0 ? (
                                    <RequiredFieldMessage>
                                        Please select use case.
                                    </RequiredFieldMessage>
                                ) : (
                                    ''
                                )}
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="3">
                                <TextLabel data-testid="solutionDescription-label">
                                    <RequiredTextLabel>*</RequiredTextLabel>
                                    Solution Description
                                    <OverlayTrigger
                                        placement={'right'}
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                            <Popover
                                                id="pop_sol_desc"
                                                style={{ maxWidth: '30%' }}
                                            >
                                                <Popover.Body>
                                                    <PopoverKeyDiv>
                                                        High level request
                                                        description:
                                                        <>
                                                            <br />
                                                            <br />
                                                        </>
                                                        Example 1: High Value of
                                                        Intel technologies for
                                                        SPARK/Kafka application.
                                                        Value is
                                                        latency/throughput/cost
                                                        Any details behind
                                                        application Retail,
                                                        Digital services.
                                                        <>
                                                            <br />
                                                            <br />
                                                        </>
                                                        Example 2: Nginx HTTPs
                                                        with QAT Tuning Guide on
                                                        ICX
                                                    </PopoverKeyDiv>
                                                </Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <i
                                            className="uil uil-info-circle pointer"
                                            tabIndex={0}
                                        ></i>
                                    </OverlayTrigger>
                                </TextLabel>
                                <Form.Control
                                    id="solutiondesc"
                                    placeholder="High level request description..."
                                    onChange={onSolutionDescriptionChange}
                                    required
                                    as="textarea"
                                    rows={3}
                                    data-testid="solutionDescription-text"
                                />

                                <Form.Control.Feedback type="invalid">
                                    Please enter solution description.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="3">
                                <TextLabel data-testid="SWDetails-label">
                                    <RequiredTextLabel>*</RequiredTextLabel>
                                    SW Details
                                    <OverlayTrigger
                                        placement={'right'}
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                            <Popover
                                                id="pop_swdetails"
                                                style={{ maxWidth: '30%' }}
                                            >
                                                <Popover.Body>
                                                    <PopoverKeyDiv>
                                                        All SW components
                                                        needed, and dependencies
                                                        listed. What version of
                                                        SW should your requested
                                                        solutions be compatible
                                                        with?: TensorFlow 2.7 or
                                                        Java 11. An specific AI
                                                        model, to mention some
                                                    </PopoverKeyDiv>
                                                </Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <i
                                            className="uil uil-info-circle pointer"
                                            tabIndex={0}
                                        ></i>
                                    </OverlayTrigger>
                                </TextLabel>
                                <Form.Control
                                    id="sw_details"
                                    placeholder="All SW components needed, and dependencies listed..."
                                    onChange={onSWDetailsChange}
                                    as="textarea"
                                    rows={3}
                                    required
                                    data-testid="SWDetails-text"
                                />
                                <Form.Control.Feedback type="invalid">
                                    {'Please enter SW details.'}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3"></Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="3">
                                <TextLabel data-testid="scalelevel-label">
                                    <RequiredTextLabel>*</RequiredTextLabel>
                                    Scale level
                                    <OverlayTrigger
                                        placement={'right'}
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={sw_maturityPopover}
                                    >
                                        <i
                                            className="uil uil-info-circle pointer"
                                            tabIndex={0}
                                        ></i>
                                    </OverlayTrigger>
                                </TextLabel>
                                <Select
                                    name="scaleLevel"
                                    isSearchable={false}
                                    isClearable={false}
                                    isMulti={false}
                                    options={scaleLevelOptions}
                                    onChange={(target: any) =>
                                        onScaleLevelChange(target)
                                    }
                                    data-testid="scaleLevel-value"
                                />
                                {validated && scaleLevel === '' ? (
                                    <RequiredFieldMessage>
                                        Please select scale level.
                                    </RequiredFieldMessage>
                                ) : (
                                    ''
                                )}
                            </Form.Group>
                            <Form.Group as={Col} md="3">
                                <TextLabel data-testid="deployementModel-label">
                                    <RequiredTextLabel>*</RequiredTextLabel>
                                    Deployment
                                    <OverlayTrigger
                                        placement={'right'}
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                            <Popover
                                                id="dep_model"
                                                style={{ maxWidth: '20%' }}
                                            >
                                                <Popover.Body>
                                                    <PopoverKeyDiv>
                                                        On-prem/Public
                                                        cloud/Hybrid ( AWS or
                                                        GCP or ..) / Instance
                                                        type / Configuration
                                                    </PopoverKeyDiv>
                                                </Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <i
                                            className="uil uil-info-circle pointer"
                                            tabIndex={0}
                                        ></i>
                                    </OverlayTrigger>
                                </TextLabel>
                                <Select
                                    name="deployment"
                                    isSearchable={false}
                                    isClearable={false}
                                    isMulti={false}
                                    options={deploymentOptions}
                                    onChange={(target: any) =>
                                        onDeploymentChange(target)
                                    }
                                    data-testid="deployment-value"
                                />
                                {validated && deploymentModel === '' ? (
                                    <RequiredFieldMessage>
                                        Please select deployment.
                                    </RequiredFieldMessage>
                                ) : (
                                    ''
                                )}
                            </Form.Group>
                        </Row>

                        <Row className="mb-3">
                            <Form.Group as={Col} md="3">
                                <TextLabel data-testid="IntelPlatform-label">
                                    {' '}
                                    <RequiredTextLabel>*</RequiredTextLabel>
                                    {'Platform'}
                                    <OverlayTrigger
                                        placement={'top'}
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                            <Popover
                                                id="pop_platform"
                                                style={{ maxWidth: '20%' }}
                                            >
                                                <Popover.Body>
                                                    <PopoverKeyDiv>
                                                        ICX or SPR or DL1 and
                                                        any backward
                                                        compatibility needed.
                                                    </PopoverKeyDiv>
                                                </Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <i
                                            className="uil uil-info-circle pointer"
                                            tabIndex={0}
                                        ></i>
                                    </OverlayTrigger>
                                </TextLabel>
                                <Select
                                    name="platform"
                                    isSearchable={true}
                                    isClearable={true}
                                    isMulti={true}
                                    options={platformOptions}
                                    onChange={(target: any) =>
                                        onPlatformChange(target)
                                    }
                                    data-testid="platform-value"
                                />
                                {validated &&
                                platform &&
                                platform.length === 0 ? (
                                    <RequiredFieldMessage>
                                        Please select platform.
                                    </RequiredFieldMessage>
                                ) : (
                                    ''
                                )}
                            </Form.Group>
                            <Form.Group as={Col} md="3">
                                <TextLabel data-testid="timeline-label">
                                    <RequiredTextLabel>*</RequiredTextLabel>
                                    Timeline
                                    <OverlayTrigger
                                        placement={'right'}
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                            <Popover
                                                id="pop_timeline"
                                                style={{ maxWidth: '20%' }}
                                            >
                                                <Popover.Body>
                                                    <PopoverKeyDiv>
                                                        When do you need this
                                                        solution and what is
                                                        driving this due date?
                                                        Please justify any
                                                        urgent requests.
                                                    </PopoverKeyDiv>
                                                </Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <i
                                            className="uil uil-info-circle pointer"
                                            tabIndex={0}
                                        ></i>
                                    </OverlayTrigger>
                                </TextLabel>
                                <Form.Control
                                    type="date"
                                    onChange={onTimelineChange}
                                    required
                                    data-testid="timeline-value"
                                ></Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Please select timeline.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="3">
                                <TextLabel data-testid="valueProp-label">
                                    <RequiredTextLabel>*</RequiredTextLabel>
                                    Value Proposition
                                    <OverlayTrigger
                                        placement={'right'}
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                            <Popover
                                                id="pop_timeline"
                                                style={{ maxWidth: '20%' }}
                                            >
                                                <Popover.Body>
                                                    <PopoverKeyDiv>
                                                        How will this help to
                                                        differentiate against
                                                        competition?
                                                        <>
                                                            <br />
                                                        </>
                                                        i.e.: ICX/SPR/EMR Hero
                                                        features / Software
                                                        libraries =
                                                        Crypto/oneDNN
                                                    </PopoverKeyDiv>
                                                </Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <i
                                            className="uil uil-info-circle pointer"
                                            tabIndex={0}
                                        ></i>
                                    </OverlayTrigger>
                                </TextLabel>
                                <Form.Control
                                    id="valueProp"
                                    placeholder="How will this help to differentiate against competition?"
                                    onChange={onIntelValuePropChange}
                                    as="textarea"
                                    rows={3}
                                    required
                                    data-testid="valueProp-text"
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please enter value proposition.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="3">
                                <TextLabel data-testid="businessImpact-label">
                                    Business Impact
                                    <OverlayTrigger
                                        placement={'right'}
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                            <Popover
                                                id="pop_timeline"
                                                style={{ maxWidth: '20%' }}
                                            >
                                                <Popover.Body>
                                                    <PopoverKeyDiv>
                                                        What is the business
                                                        impact; KPI target or IA
                                                        affinity; customers
                                                        benefited or directly
                                                        impacted
                                                        <>
                                                            <br />
                                                        </>
                                                        2.What is the scale out
                                                        potential of this
                                                        request, list of
                                                        potential customers who
                                                        will be interested in
                                                        this solution:
                                                        UpStreamed into Open
                                                        sources, Solutions that
                                                        provide configurations.
                                                    </PopoverKeyDiv>
                                                </Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <i
                                            className="uil uil-info-circle pointer"
                                            tabIndex={0}
                                        ></i>
                                    </OverlayTrigger>
                                </TextLabel>
                                <Form.Control
                                    id="businessImpact"
                                    placeholder="What is the business impact?"
                                    onChange={onBusinessImpactChange}
                                    as="textarea"
                                    rows={3}
                                    data-testid="businessImpact-text"
                                />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="3">
                                <TextLabel data-testid="solutionType-label">
                                    <RequiredTextLabel>*</RequiredTextLabel>
                                    Type
                                    <OverlayTrigger
                                        placement={'right'}
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                            <Popover
                                                id="pop_timeline"
                                                style={{ maxWidth: '20%' }}
                                            >
                                                <Popover.Body>
                                                    <PopoverKeyDiv>
                                                        Key Deliverable Feature
                                                        <>
                                                            <br />
                                                        </>
                                                        Are you requesting
                                                        tuning guide pdf/PPT or
                                                        configuration or GitHub
                                                        script or Bitnami image
                                                    </PopoverKeyDiv>
                                                </Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <i
                                            className="uil uil-info-circle pointer"
                                            tabIndex={0}
                                        ></i>
                                    </OverlayTrigger>
                                </TextLabel>
                                <Select
                                    name="type"
                                    isSearchable={true}
                                    isClearable={true}
                                    isMulti={true}
                                    options={TypeOptions}
                                    onChange={(target: any) =>
                                        onSolutionTypeChange(target)
                                    }
                                    data-testid="type-value"
                                />
                                {validated &&
                                solutionType &&
                                solutionType.length === 0 ? (
                                    <RequiredFieldMessage>
                                        Please select Type.
                                    </RequiredFieldMessage>
                                ) : (
                                    ''
                                )}
                            </Form.Group>
                            <Form.Group as={Col} md="3">
                                <TextLabel data-testid="requestor-label">
                                    <RequiredTextLabel>*</RequiredTextLabel>
                                    Requestor
                                    <OverlayTrigger
                                        placement={'right'}
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                            <Popover
                                                id="pop_cust"
                                                style={{ maxWidth: '20%' }}
                                            >
                                                <Popover.Body>
                                                    <PopoverKeyDiv>
                                                        Your Name and your CSA
                                                        Sponsor
                                                    </PopoverKeyDiv>
                                                </Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <i
                                            className="uil uil-info-circle pointer"
                                            tabIndex={0}
                                        ></i>
                                    </OverlayTrigger>
                                </TextLabel>
                                <TextBoxWrapper
                                    id="requestor"
                                    placeholder="Your name, your CSA Sponsor..."
                                    onChange={onRequestorChange}
                                    required
                                    data-testid="requestor-text"
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please enter requestor.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="3">
                                <TextLabel data-testid="keyAudience-label">
                                    <RequiredTextLabel>*</RequiredTextLabel>
                                    Key Audience
                                </TextLabel>
                                <SWMaturityDiv>
                                    {[KeyAudianceRender]}
                                </SWMaturityDiv>
                            </Form.Group>
                            <Form.Group as={Col} md="3">
                                <TextLabel data-testid="keySegment-label">
                                    <RequiredTextLabel>*</RequiredTextLabel>
                                    Key Segment
                                </TextLabel>
                                <SWMaturityDiv>
                                    {[keySegmentRender]}
                                </SWMaturityDiv>
                            </Form.Group>
                        </Row>

                        <Row className="mb-3">
                            <Form.Group as={Col} md="3">
                                <TextLabel data-testid="whichCustomer-label">
                                    <RequiredTextLabel>*</RequiredTextLabel>
                                    Customer
                                </TextLabel>
                                <TextBoxWrapper
                                    id="customer"
                                    placeholder="Enter customer name..."
                                    onChange={onCustomerChange}
                                    required
                                    data-testid="whichCustomer-text"
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please enter customer name.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>

                        <ButtonSpinnerHorizontalComponent>
                            <SubmitButton
                                variant="primary"
                                type="submit"
                                data-testid="submit-button"
                            >
                                Submit
                            </SubmitButton>
                            <CancelButton
                                variant="primary"
                                type="cancel"
                                onClick={onCancle}
                                data-testid="reset-button"
                            >
                                Reset
                            </CancelButton>
                        </ButtonSpinnerHorizontalComponent>
                    </Form>
                    <SpinnerComponent>
                        {newOptimizationLoading === LoadingState.Pending ? (
                            <Spinner animation="border" variant="dark" />
                        ) : (
                            <div></div>
                        )}
                    </SpinnerComponent>
                    <ModalPopupContainer>
                        {/* Confirmation message Model popup */}
                        <Modal
                            show={showConfirmationPopup}
                            onHide={handleConfirmationClose}
                            backdrop="static"
                            keyboard={false}
                        >
                            <Modal.Header>
                                <Modal.Title>Confirmation</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {newOptimizationResult?.msg && (
                                    <NewOptimizationResultLabel>
                                        {newOptimizationResult?.msg}
                                    </NewOptimizationResultLabel>
                                )}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    variant="primary"
                                    type="submit"
                                    onClick={handleConfirmationClose}
                                >
                                    OK
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </ModalPopupContainer>
                    <AlertComponent
                        id="errorAlert"
                        variant="primary"
                        show={newOptimizationError?.message ? true : false}
                        data-testid="error-text"
                    >
                        {'Error!' + newOptimizationError?.message}
                    </AlertComponent>
                </NewOptimizationFormComponent>
            </HorizontalContainer>
        </NewOptimizationFormContainer>
    )
}

export default NewOptimizationPanel
