/************************************************
 * Copyright (C) 2021 Intel Corporation
 ************************************************/
import styled from 'styled-components'
import { Button, FormLabel } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

const PageNotFoundContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`
const PageNotFoundComponent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`
const PageLabel = styled(FormLabel)`
    display: flex;
    ${(props) => props.theme.typography.xxLarge}
    text-align: center;
    margin-bottom: 1rem;
    font-weight: 500;
    margin-top: 2rem;
`
const BackButton = styled(Button)`
    display: flex;
    justify-content: center;
    font-weight: 700;
    font-size: 1.25rem;
    background-color:#008000;
    border-radius: ${(props) => props.theme.shape.button.borderRadius};
    margin-top: 3rem;
    &:hover {
        background-color: ${(props) =>
            props.theme.color.button.background.hover.primary};
        transition: 0.5s;
`

const PageNotFoundPanel = () => {
    let navigate = useNavigate()
    return (
        <PageNotFoundContainer>
            <PageNotFoundComponent>
                <PageLabel>
                    404 error, Oops! Page not found. We can't find the page
                    you're looking for.
                </PageLabel>
            </PageNotFoundComponent>

            <PageNotFoundComponent>
                <img src="/404.png" width="50%" height="50%" />
            </PageNotFoundComponent>
            <PageNotFoundComponent>
                <BackButton
                    variant="primary"
                    type="submit"
                    onClick={() => navigate('/catalog')}
                >
                    Go Back Home
                </BackButton>
            </PageNotFoundComponent>
        </PageNotFoundContainer>
    )
}

export default PageNotFoundPanel
