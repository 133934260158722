import { colors } from '../../../utils/colors'
import createPalette from '@mui/material/styles/createPalette'

const palette = createPalette({
    primary: {
        main: colors.geode,
    },
    secondary: {
        main: colors.blueSteel,
    },
    success: {
        main: colors.successBright,
    },
    warning: {
        main: colors.daisyShade1,
    },
    error: {
        main: colors.errorBright,
    },
})

export default palette
