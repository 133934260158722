import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AcceptInvitesRequest } from '../models/acceptInvitesRequest'
import { GetInvitesRequest } from '../models/getInvitesRequest'
import { LoadingState } from '../models/loadingState'
import { SendInvitesRequest } from '../models/sendInvitesRequest'
import InvitesService from '../services/invitationService'

const initialState = {
    data: null,
    loading: LoadingState.Idle,
    error: null,
}

// First, create the thunk
export const getInvites = createAsyncThunk(
    'getInvites',
    async (getInvitesRequest: GetInvitesRequest) => {
        return await InvitesService.getInvites(getInvitesRequest)
    }
)

// Then, handle actions in your reducers:
const getInvitesSlice = createSlice({
    name: 'getInvites',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(getInvites.pending, (state, _) => {
            if (state.loading === LoadingState.Idle) {
                state.loading = LoadingState.Pending
            }
        })
        .addCase(getInvites.fulfilled, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.data = action.payload.data
                state.error = null
            }
        })
        .addCase(getInvites.rejected, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.error = action.error
            }
        })
    },
})

// First, create the thunk
export const sendInvites = createAsyncThunk(
    'sendInvites',
    async (sendInvitesRequest: SendInvitesRequest) => {
        return await InvitesService.sendInvites(sendInvitesRequest)
    }
)

// Then, handle actions in your reducers:
const sendInvitesSlice = createSlice({
    name: 'sendInvites',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(sendInvites.pending, (state, _) => {
            if (state.loading === LoadingState.Idle) {
                state.loading = LoadingState.Pending
            }
        })
        .addCase(sendInvites.fulfilled, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.data = action.payload.data
                state.error = null
            }
        })
        .addCase(sendInvites.rejected, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.error = action.error
            }
        })
    },
})

// First, create the thunk
export const acceptInvites = createAsyncThunk(
    'acceptInvites',
    async (acceptInvitesRequest: AcceptInvitesRequest) => {
        return await InvitesService.acceptInvites(acceptInvitesRequest)
    }
)

// Then, handle actions in your reducers:
const acceptInvitesSlice = createSlice({
    name: 'acceptInvites',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(acceptInvites.pending, (state, _) => {
            if (state.loading === LoadingState.Idle) {
                state.loading = LoadingState.Pending
            }
        })
        .addCase(acceptInvites.fulfilled, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.data = action.payload.data
                state.error = null
            }
        })
        .addCase(acceptInvites.rejected, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.error = action.error
            }
        })
    },
})

export const getInvitesResult = getInvitesSlice.reducer
export const sendInvitesResult = sendInvitesSlice.reducer
export const acceptInvitesResult = acceptInvitesSlice.reducer
