import { API_SERVICE_URL } from '../config/service'

import api from './auth/client'

const serviceConfig = require('../config/service.json')
const queryString = require('query-string')

class AllRequestService {
    async getAllUploadRequests(allUploadRequest: any) {
        let offSet = 0
        let searchKey = ''
        let count = 10
        if (allUploadRequest) {
            offSet = allUploadRequest.offset
            searchKey = allUploadRequest.searchKey
            count = allUploadRequest.count
        }
        var url = API_SERVICE_URL + serviceConfig.getAllUploadRequestURL
        url = `${url}?requestType=optimizationUpload&offset=${offSet}&search=${searchKey}&count=${count}`
        if (
            allUploadRequest &&
            allUploadRequest.fromDate &&
            allUploadRequest.toDate
        ) {
            url = `${url}&fromdate=${allUploadRequest.fromDate}&todate=${allUploadRequest.toDate}`
        }
        if (allUploadRequest && allUploadRequest.decision) {
            url = `${url}${allUploadRequest.decision}`
        }
        return api.get(url)
    }
    async updateRequestApprovalDecision(updateRequest: any) {
        const { id } = updateRequest
        var url =
            API_SERVICE_URL + serviceConfig.getAllUploadRequestURL + `/${id}`
        return api.post(url, updateRequest)
    }

    async deleteUploadRequest(id: string) {
        var url =
            API_SERVICE_URL + serviceConfig.getAllUploadRequestURL + `/${id}`
        return api.delete(url)
    }

    async getAllSupportRequests(allSupportRequest: any) {
        let offSet = 0
        let searchKey = ''
        let count = 10
        if (allSupportRequest) {
            offSet = allSupportRequest.offset
            searchKey = allSupportRequest.searchKey
            count = allSupportRequest.count
        }
        var url = API_SERVICE_URL + serviceConfig.getAllSupportRequestURL
        url = `${url}?offset=${offSet}&search=${searchKey}&count=${count}`
        if (
            allSupportRequest &&
            allSupportRequest.fromDate &&
            allSupportRequest.toDate
        ) {
            url = `${url}&fromdate=${allSupportRequest.fromDate}&todate=${allSupportRequest.toDate}`
        }
        if (allSupportRequest && allSupportRequest.status) {
            url = `${url}${allSupportRequest.status}`
        }
        return api.get(url)
    }
    async updateSupportRequest(updateRequest: any) {
        const { id } = updateRequest
        var url =
            API_SERVICE_URL + serviceConfig.getAllSupportRequestURL + `/${id}`
        return api.post(url, updateRequest)
    }
    async deleteSupportRequest(id: string) {
        var url =
            API_SERVICE_URL + serviceConfig.getAllSupportRequestURL + `/${id}`
        return api.delete(url)
    }
}

export default new AllRequestService()
