import { FunctionComponent } from 'react'
import StringUtils from '../lib/stringUtils'
import styled from 'styled-components'

const FooterContainer = styled.div`
    display: flex;
    flex-direction: row;
    background-color: #e5e5e5;
    margin-top: 0.5rem;
    @media screen and (max-width: 700px) {
        flex-direction: column;
        width: 100%;
    }
`

const IntelLogoCopyRightStyling = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 2rem;
`

const IntelLogoStyling = styled.div`
    display: flex;
    margin-top: 0.5rem;
    padding: 0.5rem;
    padding-right: 5rem;
    @media screen and (max-width: 700px) {
        display: none;
    }
`
const FooterContentDiv = styled.div`
    display: flex;
    flex-direction: column;
    font-family: 'IntelOne Display';
    font-size: 0.85rem;
    color: #000;
    margin-top: 3rem;
    margin-bottom: 0.5rem;
    vertical-align: middle !important;
    justify-content: center;
    padding-right: 2rem;
    @media screen and (max-width: 700px) {
        margin-top: 0rem;
        padding: 2rem;
        width: 400px;
    }
`
const LinkContainerStyle = styled.div`
    @media screen and (max-width: 700px) {
        overflow-wrap: break-word;
        max-width: 100%;
    }
`
const MobileLogoDiv = styled.div`
    display: none;
    @media screen and (max-width: 700px) {
        display: block;
    }
`
const FooterLink = styled.a`
    color: #000;
    text-decoration: none;
    font-family: 'IntelOne Display';
    &:hover {
        text-decoration: underline;
        text-decoration-color: #000;
    }
`
const FooterDisclaimerLink = styled.a`
    font-family: 'IntelOne Display';
    color: #000;
`
const SepratorSpan = styled.span`
    padding-left: 1rem;
    padding-right: 1rem;
`

const IntelLogoComponent = () => (
    <IntelLogoCopyRightStyling>
        <IntelLogoStyling>
            <a
                href="https://intel.com/content/www/us/en/homepage.html"
                target="_blank"
            >
                <img
                    src={StringUtils.resolve('/intel-footer-logo.svg')}
                    height="200"
                    width="118"
                    alt="intel footer logo"
                    className="footer-logo-rebrand"
                />
            </a>
        </IntelLogoStyling>
    </IntelLogoCopyRightStyling>
)
const MobileLogoComponent = () => (
    <div>
        <a
            href="https://intel.com/content/www/us/en/homepage.html"
            target="_blank"
        >
            <img
                src={StringUtils.resolve('/intel-footer-logo.svg')}
                height="100"
                width="110"
                alt="intel footer logo"
                className="footer-logo-rebrand"
            />
        </a>
    </div>
)

const FooterContent = () => (
    <FooterContentDiv>
        <MobileLogoDiv>
            <MobileLogoComponent></MobileLogoComponent>
        </MobileLogoDiv>
        {/* <div>
            For support, contact{' '}
            <a href="mailto:optimizations@intel.com" style={{ color: 'black' }}>
                optimizations@intel.com
            </a>
        </div> */}
        <LinkContainerStyle>
            <FooterLink href="https://www.intel.com/content/www/us/en/company-overview/company-overview.html">
                Company Overview
            </FooterLink>{' '}
            <SepratorSpan></SepratorSpan>{' '}
            <FooterLink href="https://www.intel.com/content/www/us/en/support/contact-intel.html">
                Contact Intel
            </FooterLink>{' '}
            <SepratorSpan></SepratorSpan>{' '}
            <FooterLink href="https://www.intel.com/content/www/us/en/newsroom/home.html">
                Newsroom
            </FooterLink>{' '}
            <SepratorSpan></SepratorSpan>{' '}
            <FooterLink href="https://www.intc.com/">Investors</FooterLink>{' '}
            <SepratorSpan></SepratorSpan>
            <FooterLink href="https://www.intel.com/content/www/us/en/jobs/jobs-at-intel.html">
                Careers
            </FooterLink>{' '}
            <SepratorSpan></SepratorSpan>{' '}
            <FooterLink href="https://www.intel.com/content/www/us/en/corporate-responsibility/corporate-responsibility.html">
                Corporate Responsibility
            </FooterLink>{' '}
            <SepratorSpan></SepratorSpan>{' '}
            <FooterLink href="https://www.intel.com/content/www/us/en/diversity/diversity-at-intel.html">
                Diversity & Inclusions
            </FooterLink>
            <SepratorSpan></SepratorSpan>{' '}
            <FooterLink href="https://www.intel.com/content/www/us/en/company-overview/public-policy.html">
                Public Policy
            </FooterLink>
        </LinkContainerStyle>
        <br />
        <br />
        <LinkContainerStyle>
            <a href="https://www.facebook.com/Intel">
                <img src={StringUtils.resolve('/facebook.svg')} />
            </a>
            <SepratorSpan></SepratorSpan>
            <a href="https://twitter.com/intel">
                <img src={StringUtils.resolve('/twitter.svg')} />
            </a>
            <SepratorSpan></SepratorSpan>
            <a href="https://www.linkedin.com/company/intel-corporation">
                <img src={StringUtils.resolve('/linkedin.svg')} />
            </a>
            <SepratorSpan></SepratorSpan>
            <a href="https://www.youtube.com/user/channelintel?sub_confirmation=1">
                <img src={StringUtils.resolve('/youtube.svg')} />
            </a>
            <SepratorSpan></SepratorSpan>
            <a href="https://www.instagram.com/intel/">
                <img src={StringUtils.resolve('/instagram.svg')} />
            </a>
        </LinkContainerStyle>
        <br />
        <br />
        <LinkContainerStyle>
            © Intel Corporation <SepratorSpan>|</SepratorSpan>
            <FooterLink
                href="https://intel.com/content/www/us/en/legal/terms-of-use.html"
                target="_blank"
            >
                Terms of Use
            </FooterLink>
            <SepratorSpan>|</SepratorSpan>
            <FooterLink
                href="https://intel.com/content/www/us/en/legal/trademarks.html"
                target="_blank"
            >
                *Trademarks
            </FooterLink>
            <SepratorSpan>|</SepratorSpan>
            <FooterLink
                href="https://intel.com/content/www/us/en/privacy/intel-cookie-notice.html"
                target="_blank"
            >
                Cookies
            </FooterLink>
            <SepratorSpan>|</SepratorSpan>
            <FooterLink
                href="https://intel.com/content/www/us/en/privacy/intel-cookie-notice.html"
                target="_blank"
            >
                Privacy
            </FooterLink>
            <SepratorSpan>|</SepratorSpan>
            <FooterLink
                href="https://intel.com/content/www/us/en/corporate-responsibility/statement-combating-modern-slavery.html"
                target="_blank"
            >
                Supply Chain Transparency
            </FooterLink>{' '}
        </LinkContainerStyle>
        <br />
        <br />
        <div>
            Intel technologies may require enabled hardware, software or service
            activation. // No product or component can be absolutely secure. //
            Your costs and results may vary. // Performance varies by use,
            configuration and other factors. Learn more at{' '}
            <FooterDisclaimerLink
                href="https://intel.com/PerformanceIndex"
                target="_blank"
            >
                www.intel.com/PerformanceIndex.
            </FooterDisclaimerLink>{' '}
            // See our complete legal{' '}
            <FooterDisclaimerLink
                href="https://edc.intel.com/content/www/us/en/products/performance/benchmarks/overview/#GUID-26B0C71C-25E9-477D-9007-52FCA56EE18C"
                target="_blank"
            >
                Notices and Disclaimers.
            </FooterDisclaimerLink>{' '}
            // Intel is committed to respecting human rights and avoiding
            complicity in human rights abuses. See Intel’s{' '}
            <FooterDisclaimerLink
                href="https://www.intel.com/content/www/us/en/policy/policy-human-rights.html"
                target="_blank"
            >
                Global Human Rights Principles.
            </FooterDisclaimerLink>{' '}
            Intel’s products and software are intended only to be used in
            applications that do not cause or contribute to a violation of an
            internationally recognized human right.
        </div>
    </FooterContentDiv>
)

const FooterPanel: FunctionComponent = ({}) => {
    return (
        <FooterContainer>
            <IntelLogoComponent></IntelLogoComponent>
            <FooterContent></FooterContent>
        </FooterContainer>
    )
}

export default FooterPanel
