import styled from 'styled-components'
import { useEffect, useState } from 'react'

const ScrollButton = styled.button`
    position: fixed;
    z-index: 5;
    bottom: 1rem;
    right: 1rem;
    color: #000;
    background-color: transparent;
    border: 1px solid #0054ae;
    width: 2rem;
    height: 2rem;
    font-size: 1.5rem;
    display: none;
    align-items: center;
    justify-content: center;
`

const ScrollToTop = () => {
    const [show, setShow] = useState(false)

    useEffect(() => {
        const onWindowScroll = () => {
            setShow(
                document.body.scrollTop > 64 ||
                    document.documentElement.scrollTop > 64
            )
        }
        window.addEventListener('scroll', onWindowScroll)
        return () => window.removeEventListener('scroll', onWindowScroll)
    }, [])

    const scrollToTop = () => {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
    }

    const displayStyles = {
        display: show ? 'flex' : 'none',
    }

    return (
        <ScrollButton style={displayStyles} onClick={scrollToTop}>
            <i className="uil uil-angle-double-up"></i>
        </ScrollButton>
    )
}

export default ScrollToTop
