import { useState, useCallback, ChangeEvent } from 'react'
import { IFavoritePanelProps } from './favoritePanel'

export function FavoritePanelLogic({
    getFavorites,
    getFavoriteResult,
}: IFavoritePanelProps) {
    const [currPage, setCurrPage] = useState<number>(1)
    const [pageCount, setPageCount] = useState(10)

    const totalPage = getFavoriteResult?.pageInfo.total
        ? getFavoriteResult?.pageInfo.total
        : -1
   
    const afterPageClicked = (pageNumber: number) => {
        setCurrPage(pageNumber)
        let offset = pageNumber * pageCount - pageCount
        //get new page data
        getFavorites({ offset: offset, count: pageCount })

    }

    const handlePageCount = useCallback((event: ChangeEvent<HTMLElement>) => {
        const target = event.currentTarget as HTMLSelectElement
        if (target.value != undefined && target.value != '') {
            setPageCount(parseInt(target.value))
            let offset = currPage * pageCount - pageCount
            let selectedPageCount = Number(target.value)
            //get new page data
            getFavorites({ offset: offset, count: selectedPageCount })
            if ((currPage - 1) * pageCount + 1 > totalPage) setCurrPage(1)
        }
    }, [])

    return {
        currPage,
        afterPageClicked,
        pageCount,
        handlePageCount,
    }
}
