import { useState, useCallback, FormEvent, useEffect, ChangeEvent } from 'react'
import { KeyboardEvent } from 'react'
import { _generateRandom } from '../../lib/generate'
import { useNavigate } from 'react-router'
import { IBenchmarkDetailsProps } from './benchmarkDetails'
import { useAppDispatch, useAppSelector } from '../../config/hooks'
import {
    downloadBenchmarkSlide,
    getWorkloadBenchmarks,
    getWorkloads,
} from '../../store/benchmark.slice'
import { getFiltersValues, saveLinkClick } from '../../store/catalog.slice'
import { UserSalesPermissions } from '../../models/userRoles'

export function BenchmarkDetailsLogic({
    SearchComponentRef,
    downloadLinkRef,
    workloadId,
    benchmarkGroupName,
}: IBenchmarkDetailsProps) {
    const navigate = useNavigate()
    const [showBenchmarkMetaData, setShowBenchmarkMetaData] = useState(false)
    const [rowId, setRowId] = useState('')
    const [searchKey, setSearchKey] = useState('')
    const [attachedFileName, setAttachedFileName] = useState('')
    const [attachmentFilenames, setAttachmentFilenames] = useState<string[]>([])
    const [fileDownloadUrlList, setfileDownloadUrlList] = useState<string[]>([])
    const [downloadCompleted, setDownloadCompleted] = useState(false)
    const [selectedAccessLevel, setSelectedAccessLevel] = useState<string>('')
    const [selectedFiltersValue, setSelectedFiltersValue] = useState<string[]>(
        []
    )
    const [showThumbnailPopup, setShowThumbnailPopup] = useState(false)
    const [attachedFileURL, setAttachedFileURL] = useState('')
    const [benchmarkId, setBenchmarkId] = useState('')
    const [fileIndex, setFileIndex] = useState<number>(0)

    const ShowPPTThumbnailPopup = (url: string) => {
        if (url) {
            setAttachedFileURL(url)
        }
        setShowThumbnailPopup(true)
    }
    const ClosePPTThumbnailPopup = () => {
        setShowThumbnailPopup(false)
        setAttachedFileURL('')
    }

    const dispatch = useAppDispatch()
    const getFilteredData = () => {
        const paramsValues = `${workloadId}/benchmark?benchmarkGroup=${benchmarkGroupName}&search=${
            searchKey ? searchKey.trim() : ''
        }${getSelectedAccessGroup()}`
        dispatch(
            getWorkloadBenchmarks({
                params: paramsValues,
                tagValues: selectedFiltersValue ? selectedFiltersValue : [],
            })
        )
    }

    useEffect(() => {
        dispatch(
            getFiltersValues(
                'filter=GMO Use Case&filter=Platform&filter=Feature&filter=Scale level&filter=Type&filter=CSP'
            )
        )
        if (workloadId) {
            getFilteredData()
        }
    }, [])
    useEffect(() => {
        //get data based on user access permission and filter change
        if (selectedAccessLevel || selectedFiltersValue) {
            getFilteredData()
        }
    }, [selectedAccessLevel, selectedFiltersValue])

    const filtersValues = useAppSelector<any>(
        (state: any) => state.getFiltersValuesResult.data
    )
    const downloadSlideResultData = useAppSelector<any>(
        (state: any) => state.downloadBenchmarkSlideResult.data
    )

    const onShareLinkClick = useCallback((event: FormEvent<HTMLElement>) => {
        event.preventDefault()
        const target = event.currentTarget as HTMLButtonElement
        if (target.value !== '') {
            navigator.clipboard.writeText(target.value)
            alert('Link copied to clipboard: ' + target.value)
        }
    }, [])
    const onShowBenchmarkMetaDataClick = useCallback(
        (
            event: FormEvent<HTMLElement>,
            id: string,
            benchmarkId: string,
            attachmentFilenames: string[]
        ) => {
            event.preventDefault()
            setDownloadCompleted(false) //reset
            if (showBenchmarkMetaData && rowId === id) {
                setShowBenchmarkMetaData(false)
                setRowId(id)
                //remove downloaded urllist on collapse
                fileDownloadUrlList.map((url: string) => {
                    URL.revokeObjectURL(url)
                })
                if (attachmentFilenames && attachmentFilenames.length > 0) {
                    attachmentFilenames.map((name: string, index: number) => {
                        localStorage.removeItem(attachmentFilenames[index])
                    })
                }
            } else {
                setShowBenchmarkMetaData(true)
                setRowId(id)
                //initiate download
                if (
                    benchmarkId &&
                    attachmentFilenames &&
                    attachmentFilenames.length > 0
                ) {
                    setBenchmarkId(benchmarkId)
                    setAttachmentFilenames(attachmentFilenames)
                    setAttachedFileName(attachmentFilenames[0])
                    setFileIndex(1)
                    dispatch(
                        downloadBenchmarkSlide(
                            `${benchmarkId}/attachment/${attachmentFilenames[0]}` //first slide download
                        )
                    )
                }
            }
        },
        [showBenchmarkMetaData, rowId]
    )
    useEffect(() => {
        if (downloadSlideResultData && showBenchmarkMetaData) {
            const fileType = attachedFileName.substring(
                attachedFileName.length - 3
            )
            const blob = new Blob([downloadSlideResultData], {
                type: `application/${fileType}`,
            })
            const fileDownloadUrl = URL.createObjectURL(blob)
            let urlList = fileDownloadUrlList
            urlList.push(attachedFileName)
            setfileDownloadUrlList(urlList) //add blob address to list

            if (downloadLinkRef.current !== undefined) {
                downloadLinkRef.current.href = fileDownloadUrl
                localStorage.setItem(attachedFileName, fileDownloadUrl)
            }

            if (
                attachmentFilenames &&
                attachmentFilenames.length > 0 &&
                attachmentFilenames.length > fileIndex
            ) {
                setAttachedFileName(attachmentFilenames[fileIndex])
                dispatch(
                    downloadBenchmarkSlide(
                        `${benchmarkId}/attachment/${attachmentFilenames[fileIndex]}` //first slide download
                    )
                )
                setFileIndex(fileIndex + 1)
            }
            if (attachmentFilenames.length === fileIndex) {
                setDownloadCompleted(true)
            }
        }
    }, [downloadSlideResultData])

    const downloadPPTPerfSlide = useCallback(
        (event: FormEvent<HTMLElement>) => {
            event.preventDefault()
            if (downloadLinkRef.current !== undefined && downloadCompleted) {
                downloadLinkRef.current.download = attachmentFilenames
                downloadLinkRef.current.click() //download file
            }
        },
        [downloadCompleted]
    )

    const getSelectedAccessGroup = () => {
        let accessGroupFilters = ''
        if (
            selectedAccessLevel &&
            selectedAccessLevel === UserSalesPermissions.InternalAll
        )
            accessGroupFilters = '&access-group=' + 'internal'
        else if (selectedAccessLevel) {
            accessGroupFilters =
                '&access-group=' + selectedAccessLevel.toLowerCase()
        } else {
            let getSalesAccessLevelValue = localStorage.getItem(
                'selectedSalesAccess'
            )
            if (getSalesAccessLevelValue) {
                accessGroupFilters =
                    '&access-group=' +
                    getSalesAccessLevelValue
                        .replace('All', '')
                        .trim()
                        .toLowerCase()
            }
        }

        return accessGroupFilters
    }
    const onAccessApplyChange = useCallback((selectedAccessLevel: string) => {
        setSelectedAccessLevel(selectedAccessLevel)
    }, [])

    const onHeaderSearchKeyChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLTextAreaElement
            if (target.value != undefined) {
                setSearchKey(target.value)
                if (target.value === '') {
                    //reset search refresh data for default search
                    if (workloadId) {
                        getFilteredData()
                    }
                }
            }
        },
        []
    )

    const onHeaderSearchKeydown = (eventKey: string) => {
        if (eventKey === 'Enter') {
            if (workloadId) {
                getFilteredData()
            }
        }
    }
    const onHeaderFilterClick = useCallback(
        (selectedFiltersValue: string[], count: number) => {
            setSelectedFiltersValue(selectedFiltersValue)
        },
        []
    )
    const onHeaderFilterClearAllClick = useCallback(
        (selectedFiltersValue: string[], count: number) => {
            setSelectedFiltersValue([])
        },
        []
    )
    const handleSalesLinkClick = useCallback(
        (
            linkUrl: string,
            workloadId: string,
            event: FormEvent<HTMLElement>
        ) => {
            event.preventDefault()
            if (linkUrl) {
                window.open(linkUrl, '_blank')
                //log link click into DB.
                if (workloadId)
                    dispatch(saveLinkClick({ uuid: workloadId, url: linkUrl }))
            }
        },
        []
    )

    return {
        onShareLinkClick,
        showBenchmarkMetaData,
        onShowBenchmarkMetaDataClick,
        rowId,
        filtersValues,
        attachmentFilenames,
        fileDownloadUrlList,
        downloadCompleted,
        downloadPPTPerfSlide,
        onAccessApplyChange,
        onHeaderSearchKeyChange,
        onHeaderSearchKeydown,
        onHeaderFilterClick,
        onHeaderFilterClearAllClick,
        ShowPPTThumbnailPopup,
        ClosePPTThumbnailPopup,
        showThumbnailPopup,
        attachedFileURL,
        handleSalesLinkClick,
        attachedFileName,
    }
}
