import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { useNavigate, useParams } from 'react-router-dom'
import { KeyboardEvent } from 'react'
import { FormControl, Nav, NavDropdown, Overlay } from 'react-bootstrap'
import AccessLevelPanel from './changeAccessLevel'
import SalesFiltersPanel from './salesFilters'
import StringUtils from '../../lib/stringUtils'
import { getSalesSidebarLinks } from '../../store/benchmark.slice'
import { useAppDispatch, useAppSelector } from '../../config/hooks'
import { APP_BASE_URL } from '../../config/service'
import PageLinksPanel from '../../layout/pageLinks'
import { Icon } from '@iconify/react'

const HeaderMainContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
`
const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 3.5rem;
    padding-right: 0px;
    align-items: center;
    flex-shrink: 0;
    background: var(--global-header-classic-blue-dark-fill, #00377c);
    box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.1);
`

const HeaderMenuDiv = styled.div`
    display: flex;
    justify-content: left;
    padding-right: 1rem;
`
const HeaderImageDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: left;
    padding: 0.6rem;
    margin-left: 0.5rem;
`

const OHTitleText = styled.a`
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    justify-content: center;
    padding-top: 0.5rem;
    &:hover {
        color: #00c7fd;
    }
    color: var(--text-dark-default, #fff);
    /* Project Name */
    font-family: IntelOne Display;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 125% */
`
const SearchTextboxComponent = styled(FormControl)`
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    font-size: 1rem !important;
    font-weight: 300;
    font-family: 'IntelOne Text';
    border-radius: 0px;
    padding: 0.2rem;
    align-items: flex-start;
    gap: 4px;
    width: 37.5rem;
    height: 2rem;
    color: var(--theme-light-gray-700, #6a6d75);
    border: 1px solid var(--theme-light-gray-400, #c9cace);
    background: var(--theme-light-gray-50, #fff);

    @media screen and (max-width: 500px) {
        width: 15.5rem;
    }
    @media screen and (min-width: 500px) {
        width: 18.5rem;
    }
    @media screen and (min-width: 1000px) {
        width: 28.8rem;
    }
    @media screen and (min-width: 1900px) {
        width: 37.5rem;
    }
`

const RightLinkPanelContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 1rem;
    min-width: 23rem;
    width: 23rem;
`
const ParentNavDiv = styled(NavDropdown.Item)`
    ${(props) => props.theme.typography.medium}
    font-weight: lighter;
    padding-left: 0.2rem;
    color: ${(props) => props.theme.color.text.default};
    font-weight: 400;
    &:active,
    &:hover {
        color: #ffc42a;
        font-weight: 500;
    }
`

const StyledNavDropdown = styled(NavDropdown)`
    ${(props) => props.theme.typography.medium}
    font-weight: lighter;
    padding-left: 0.2rem;
    color: ${(props) => props.theme.color.text.default};
    font-weight: 400;
    margin-right: 0.5rem;
`
const LinkPanelContainer = styled.div`
    display: inline-flex;
    flex-direction: column;
    flex-direction: space-between;
    justify-content: center;
    align-items: center;
    background: #fff;
`
const NavComponent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0;
    gap: 7px;
`
const StyledNavLink = styled(Nav.Link)`
    color: var(--classic-blue-classic-blue, #0054ae);
    text-align: center;
    font-family: 'IntelOne Display';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */

    padding-left: 0.2rem;
    &:active,
    &:hover {
        color: var(--classic-blue-classic-blue, #0054ae);
        font-weight: 400;
    }
`
const FilterContainerDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`
const TitleIcon = styled(Icon)`
    display: flex;
    width: 2.2rem;
    height: 2.2rem;
    cursor: pointer;
    margin-right: 0.25rem;
    color: #fff;
`

const HeaderImageComponent = () => (
    <HeaderImageDiv>
        <img
            src="/intel-header-logo.svg"
            height="40"
            width="76"
            alt="intel logo"
        />
    </HeaderImageDiv>
)

export interface IHeaderPanelProps {
    SearchComponentRef?: any
    onSearchKeyChange: (event: React.ChangeEvent<HTMLElement>) => void
    onSearchKeyDown: (eventKey: string) => void
    onApplyFilterCallback?: (
        selectedValues: string[],
        selectedFilterCount: number
    ) => void
    onClearAllCallback?: (
        selectedValues: string[],
        selectedFilterCount: number
    ) => void
    onAccessChangeCallback?: (selectedAccessLevel: string) => void
}

export const SalesHeaderPanel: FunctionComponent<IHeaderPanelProps> & {} = ({
    onSearchKeyChange,
    onSearchKeyDown,
    SearchComponentRef,
    onApplyFilterCallback,
    onClearAllCallback,
    onAccessChangeCallback,
}: IHeaderPanelProps) => {
    let navigate = useNavigate()
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(getSalesSidebarLinks())
    }, [])

    const sideLinksValues = useAppSelector<any>(
        (state: any) => state.getSalesSidebarLinksResult.data
    )

    const [selectedAccessLevel, setSelectedAccessLevel] = useState<string>('')
    const queryParameters = new URLSearchParams(location.search)
    const isDevViewValue = queryParameters.get('isDevView')
    let { workloadId } = useParams()

    const isDevView =
        location.pathname.indexOf('/benchmarks') > -1 ||
        (isDevViewValue && isDevViewValue === 'true')
            ? true
            : false

    const isSalesHomePage =
        location.pathname.indexOf('/salesview/home') > -1 ? true : false
    const isBenchmarkPage =
        location.pathname.indexOf('/benchmark') > -1 ? true : false
    const isAllWorkloadPage =
        location.pathname.indexOf('/allWorkloads') > -1 ? true : false

    const onAccessApplyChange = useCallback((selectedAccessLevel: string) => {
        setSelectedAccessLevel(selectedAccessLevel)
        if (onAccessChangeCallback) onAccessChangeCallback(selectedAccessLevel)
    }, [])

    const navigateOrRefreshPage = (navigateToAddress: string) => {
        if (window.location.pathname.indexOf(navigateToAddress) > -1) {
            //refresh & return to previous state
            window.location.href = navigateToAddress
        } else navigate(navigateToAddress)
    }

    const HeaderAppTitleComponent = () => (
        <HeaderImageDiv>
            {isDevView ? (
                <TitleIcon
                    icon="la:cloud-meatball"
                    onClick={() => {
                        navigate('/catalog')
                    }}
                />
            ) : (
                ''
            )}
            <OHTitleText
                style={isDevView ? { color: '#fff' } : {}}
                onClick={() => navigate('/catalog')}
                href={StringUtils.resolve('/catalog')}
            >
                Optimization Hub
            </OHTitleText>
        </HeaderImageDiv>
    )

    const UserProfileImage = (
        <img src="/sales_img/slaes_user_icon.png" alt="profile logo" />
    )
    const offCanvasNav = () => {
        return (
            <>
                {[false].map((expand) => (
                    <Navbar expand={expand}>
                        <Container fluid>
                            <Navbar.Toggle
                                style={{
                                    backgroundColor:
                                        'linear-gradient(180deg, #0d1b35, #1e3a84)',
                                }}
                                aria-controls={`offcanvasNavbar-expand-${expand}`}
                                id="offcanvasNavButton"
                            />
                            <Navbar.Offcanvas
                                id={`offcanvasNavbar-expand-${expand}`}
                                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                                placement="start"
                                style={{ width: '200px' }}
                            >
                                <Offcanvas.Header closeButton>
                                    <Offcanvas.Title
                                        id={`offcanvasNavbarLabel-expand-${expand}`}
                                    ></Offcanvas.Title>
                                </Offcanvas.Header>
                                <Offcanvas.Body>
                                    {!isDevView ? (
                                        <SalesViewPageLinksPanel></SalesViewPageLinksPanel>
                                    ) : (
                                        <PageLinksPanel></PageLinksPanel>
                                    )}
                                </Offcanvas.Body>
                            </Navbar.Offcanvas>
                        </Container>
                    </Navbar>
                ))}
            </>
        )
    }
    const SalesViewPageLinksPanel = () => (
        <LinkPanelContainer>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <NavComponent>
                    <StyledNavLink
                        onClick={() => navigateOrRefreshPage('/salesview/home')}
                        href="javascript:void(0)"
                    >
                        <img src="/sales_img/home-icon.svg" alt="home icon" />
                        <br />
                        Home
                    </StyledNavLink>
                </NavComponent>
                <NavComponent>
                    <StyledNavLink
                        onClick={() => navigate('/catalog')}
                        href="javascript:void(0)"
                    >
                        <img src="/gallery_view.svg" alt="home icon" />
                        <br />
                        Developer View
                    </StyledNavLink>
                </NavComponent>
                {sideLinksValues && sideLinksValues['Benchmark Dashboard'] ? (
                    <NavComponent>
                        <StyledNavLink
                            href={
                                sideLinksValues['Benchmark Dashboard']['link']
                            }
                            target="_blank"
                        >
                            <img
                                src="/sales_img/dashboard.svg"
                                alt="dashboard icon"
                            />
                            <br />
                            {sideLinksValues['Benchmark Dashboard']['name']}
                        </StyledNavLink>
                    </NavComponent>
                ) : (
                    ''
                )}
                {sideLinksValues && sideLinksValues['Benchmark Ingest'] ? (
                    <NavComponent>
                        <StyledNavLink
                            href={sideLinksValues['Benchmark Ingest']['link']}
                            target="_blank"
                        >
                            <img
                                src="/sales_img/add-new-icon.svg"
                                alt="add new icon"
                            />
                            <br />
                            {sideLinksValues['Benchmark Ingest']['name']}
                        </StyledNavLink>
                    </NavComponent>
                ) : (
                    ''
                )}
                {sideLinksValues &&
                sideLinksValues['Customer Case Study Ingest'] ? (
                    <NavComponent>
                        <StyledNavLink
                            href={
                                sideLinksValues['Customer Case Study Ingest'][
                                    'link'
                                ]
                            }
                            target="_blank"
                        >
                            <img
                                src="/sales_img/sideEditIcon.svg"
                                alt="case study icon"
                            />
                            <br />
                            {
                                sideLinksValues['Customer Case Study Ingest'][
                                    'name'
                                ]
                            }
                        </StyledNavLink>
                    </NavComponent>
                ) : (
                    ''
                )}
                {sideLinksValues &&
                sideLinksValues['Partner Solution Ingest'] ? (
                    <NavComponent>
                        <StyledNavLink
                            href={
                                sideLinksValues['Partner Solution Ingest'][
                                    'link'
                                ]
                            }
                            target="_blank"
                        >
                            <img
                                src="/sales_img/sideEditIcon.svg"
                                alt="dashboard icon"
                            />
                            <br />
                            {sideLinksValues['Partner Solution Ingest']['name']}
                        </StyledNavLink>
                    </NavComponent>
                ) : (
                    ''
                )}
                {sideLinksValues && sideLinksValues['Request Access'] ? (
                    <NavComponent>
                        <StyledNavLink
                            href={sideLinksValues['Request Access']['link']}
                            target="_blank"
                        >
                            <img
                                src="/sales_img/sideEditIcon.svg"
                                alt="dashboard icon"
                            />
                            <br />
                            {sideLinksValues['Request Access']['name']}
                        </StyledNavLink>
                    </NavComponent>
                ) : (
                    ''
                )}
                {sideLinksValues &&
                sideLinksValues['SW Optimization Ingest'] ? (
                    <NavComponent>
                        <StyledNavLink
                            href={
                                sideLinksValues['SW Optimization Ingest'][
                                    'link'
                                ]
                            }
                            target="_blank"
                        >
                            <img
                                src="/sales_img/request.svg"
                                alt="dashboard icon"
                            />
                            <br />
                            {sideLinksValues['SW Optimization Ingest']['name']}
                        </StyledNavLink>
                    </NavComponent>
                ) : (
                    ''
                )}
                {/* <NavComponent>
                    <StyledNavLink>
                        <img
                            src="/sales_img/circle-checks-icon.svg"
                            alt="home icon"
                        />
                        <br />
                        Following
                    </StyledNavLink>
                </NavComponent>
                */}
                <NavComponent>
                    <StyledNavLink
                        href={
                            APP_BASE_URL ? `${APP_BASE_URL}/logOut` : '/logOut'
                        }
                    >
                        <img src="/sales_img/logout.svg" alt="logout icon" />
                        <br />
                        Log out
                    </StyledNavLink>
                </NavComponent>
            </div>
        </LinkPanelContainer>
    )
    return (
        <HeaderMainContainer>
            <HeaderContainer
                style={
                    isDevView
                        ? {
                              background: `url('${process.env.PUBLIC_URL}/background-l1-mosaic.svg')`,
                              backgroundColor: 'rgb(101, 49, 113)',
                          }
                        : {}
                }
            >
                <HeaderMenuDiv>
                    {offCanvasNav()}
                    <HeaderImageComponent />
                    <HeaderAppTitleComponent />
                </HeaderMenuDiv>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <SearchTextboxComponent
                        placeholder={
                            isSalesHomePage
                                ? `🔍 Search workloads, benchmarks, optimizations and more..`
                                : isBenchmarkPage
                                ? `🔍 Search a benchmark`
                                : isAllWorkloadPage && !workloadId
                                ? `🔍 Search a workload`
                                : isAllWorkloadPage && workloadId
                                ? `🔍 Search a benchmark`
                                : ''
                        }
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            onSearchKeyChange(e)
                        }
                        onKeyDown={(event: KeyboardEvent<HTMLElement>) =>
                            onSearchKeyDown(event.key)
                        }
                        ref={SearchComponentRef}
                        type="search"
                    />

                    <FilterContainerDiv>
                        <SalesFiltersPanel
                            onApplyFilterCallback={onApplyFilterCallback}
                            onClearAllCallback={onClearAllCallback}
                            isHeaderLoad={true}
                            isSalesHomepageLoad={isSalesHomePage}
                            isDevView={isDevView}
                        />
                    </FilterContainerDiv>
                </div>
                <RightLinkPanelContainer>
                    <AccessLevelPanel
                        onApplyAccessCallBack={onAccessApplyChange}
                    ></AccessLevelPanel>
                    <StyledNavDropdown
                        title={UserProfileImage}
                        id="basic-nav-dropdown"
                        href="javascript:void(0)"
                    >
                        <ParentNavDiv
                            href={
                                APP_BASE_URL
                                    ? `${APP_BASE_URL}/membership`
                                    : '/membership'
                            }
                        >
                            <i className="uil uil-apps"></i> Membership
                        </ParentNavDiv>
                        <ParentNavDiv
                            href={
                                APP_BASE_URL
                                    ? `${APP_BASE_URL}/logOut`
                                    : '/logOut'
                            }
                        >
                            <i className="uil uil-sign-out-alt"></i> Log out
                        </ParentNavDiv>
                    </StyledNavDropdown>
                </RightLinkPanelContainer>
            </HeaderContainer>
        </HeaderMainContainer>
    )
}

export default SalesHeaderPanel
