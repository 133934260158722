import { API_SERVICE_URL } from '../config/service'
import { CatalogSearchRequest } from '../models/catalogSearchrequest'
import authApi from './auth/noAuthClient'

const serviceConfig = require('../config/service.json')
const queryString = require('query-string')

class CatalogPublicService {
    searchRecipes(catalogSearchCriteria: CatalogSearchRequest) {
        var url = API_SERVICE_URL + serviceConfig.getPublicRecipeURL
        const queryArgs = queryString.stringify(catalogSearchCriteria)
        if (
            catalogSearchCriteria.uuid !== undefined &&
            catalogSearchCriteria.uuid !== ''
        ) {
            return authApi.get(`${url}?uuid=${catalogSearchCriteria.uuid}`)
        } else if (catalogSearchCriteria.uuids) {
            return authApi.get(url + '?' + catalogSearchCriteria.uuids)
        } else return authApi.get(url + '?' + queryArgs)
    }

    downloadRecipe(downloadUrl: string) {
        return authApi.get(downloadUrl, { responseType: 'blob' })
    }
    getPublicFiltersValues(param: string) {
        var url = API_SERVICE_URL + serviceConfig.getPublicFiltersValues
        return authApi.get(url + '?' + param)
    }
}

export default new CatalogPublicService()
