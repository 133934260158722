import { API_SERVICE_URL } from '../config/service'
import { AcceptInvitesRequest } from '../models/acceptInvitesRequest'
import { GetInvitesRequest } from '../models/getInvitesRequest'
import { SendInvitesRequest } from '../models/sendInvitesRequest'
import api from './auth/client'
const serviceConfig = require('../config/service.json')

class InvitationService {
    getInvites(getInvitesRequest: GetInvitesRequest) {
        var url = API_SERVICE_URL + serviceConfig.getInvitesInAnOrg
        return api.get(url)
    }

    sendInvites(sendInvitesRequest: SendInvitesRequest) {
        var url = API_SERVICE_URL + serviceConfig.sendInvitesInAnOrg
        return api.post(url, sendInvitesRequest)
    }

    acceptInvites(acceptInvitesRequest: AcceptInvitesRequest) {
        var url =
            API_SERVICE_URL +
            serviceConfig.acceptInvitesInAnOrg +
            '/' +
            acceptInvitesRequest.invitationKey
        return api.post(url, acceptInvitesRequest)
    }
}

export default new InvitationService()
