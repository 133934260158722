import React, {
    FormEvent,
    FunctionComponent,
    useCallback,
    useState,
} from 'react'

export interface ICopyToClipboardPanelProps {
    isDisabled: boolean
    onClickCallBack: (event: FormEvent<HTMLElement>) => void
}

export const CopyToClipboardPanel: FunctionComponent<ICopyToClipboardPanelProps> = ({
    isDisabled,
    onClickCallBack
}: ICopyToClipboardPanelProps) => {
    const [isClicked, setIsChecked] = useState<boolean>(false)

    const handleChange = useCallback(
        (event: FormEvent<HTMLElement>) => {
            event.preventDefault()
            setIsChecked(true)
            onClickCallBack(event)
        },
        [isClicked]
    )

    return (
        <input
        src={
            isClicked ? '/tick-solid.svg' : '/copy.svg'
        }
        type="image"
        width="25"
        height="25"
        id={
            'copy-token'
        }
        onClick={(event: FormEvent<HTMLElement>) => handleChange(event)}
        disabled={isDisabled}
    />
    )
}
