import { FunctionComponent } from 'react'
import styled from 'styled-components'
import {
    Button,
    FormControl,
    Spinner,
    Alert,
    FormLabel,
    Form,
    Row,
    Col,
} from 'react-bootstrap'

import { LoadingState } from '../../models/loadingState'
import { CreateOrgPanelLogic } from './createOrgPanel.hook'
import { useNavigate } from 'react-router-dom'
import { acceptInvites } from '../../store/invitation.slice'
import { AcceptInvitesResponse } from '../../models/acceptInvitesResponse'
import { AuthToken } from '../../models/authToken'
import stringUtils from '../../lib/stringUtils'
import { getOauthSignInURL } from '../../services/auth/amplify'

const CreateOrgFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
`
const HorizontalContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`
const PageTitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: left;
`

const CreateOrgFormComponent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (min-width: 100rem) {
        flex-grow: 1;
    }
`
const ConfirmCodeComponent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (min-width: 100rem) {
        flex-grow: 1;
    }
`

const ButtonSpinnerHorizontalComponent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-top: 1rem;
`
const HavingAccountDiv = styled.div`
    display: flex;
    padding-top: 1.5rem;
    padding-bottom: 8rem;
`
const ConfirmButtonComponentDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-top: 1rem;
`
const ResendCodeButtonComponent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 10rem;
`
const DidNotGetCodeDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 1rem;
    width: 100%;
`

const SignUpButton = styled(Button)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 9rem;
    max-width: 9rem;
    margin-right: 1rem;
    border-radius: ${(props) => props.theme.shape.button.borderRadius};
    background-color: #337ab7;
    border-color: #2e6da4;
    &:hover {
        background-color: #0d6efd;
        border-color: #0d6efd;
        transition: 0.5s;
    }
    border-radius: 0px;
`
const ResetButton = styled(SignUpButton)`
    margin-left: 1rem;
    width: 8rem;
    max-width: 8rem;
`
const SubmitConfirmationButton = styled(SignUpButton)`
    margin-right: 0rem;
    width: 100%;
    max-width: 100%;
`
const ResendCodeButton = styled(SignUpButton)`
    width: 100%;
    max-width: 100%;
`

const PageTitleTextLabel = styled(FormLabel)`
    ${(props) => props.theme.typography.xxxLarge}
    text-align: left;
    font-family: 'IntelOne Display';
    font-style: normal;
    font-weight: 600;
`
const PageTitleTextLabel2 = styled(FormLabel)`
    text-align: left;
    font-family: 'IntelOne Display';
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    padding-top: 1rem;
    padding-bottom: 2rem;
`
const TextBoxWrapper = styled(Form.Control)`
    display: flex;
    border-radius: ${(props) => props.theme.shape.input.borderRadius};
    margin-bottom: 1rem;
    max-width: 25rem;
    @media screen and (max-width: 500px) {
        width: 20rem;
    }
`
const TextLabel = styled(Form.Label)`
    display: flex;
    ${(props) => props.theme.typography.xLarge}
    text-align: left;
    font-weight: 500;
`

const RequiredTextLabel = styled(FormLabel)`
    display: flex;
    ${(props) => props.theme.typography.xLarge}
    color: red;
`
const ValidPasswordSpan = styled.span`
    display: flex;
    color: #19bf00;
    font-size: 14px;
    font-family: 'Arial';
`
const InValidPasswordSpan = styled(ValidPasswordSpan)`
    color: #df3312;
`
const PasswordValidationdiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 20rem;
    padding-bottom: 1rem;
`
const AlertComponent = styled(Alert)`
    display: flex;
    margin-top: 1rem;
    width: 35vw;
    justify-content: center;
`
const AuthPanelWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: center;
    max-width: 100%;
`
const LoginContainerSSOPanel = styled.div`
    margin-bottom: 20px;
    padding: 20px;
    box-shadow: 0 1px 1px rgb(0 0 0 / 0%);
    border-radius: 0;
    background: transparent;
    margin-bottom-3rem;
    justify-content: center;    
`
const HExternalLoginPanel = styled.div`
    background: transparent;
    display: none;
    @media screen and (max-width: 600px) {
        display: block;
    }
`
const HSeparatorDiv = styled.div`
    justify-content: center;
    padding: 30px;
    @media screen and (max-width: 600px) {
        border-bottom-style: solid;
        border-bottom-color: #f5f5f5;
        border-bottom-width: 2px;
        margin-bottom: -2px;
    }
`
const ExternalLoginContainerPanel = styled.div`
    disply: flex;
    flex-direction: column;
    padding: 30px;
    box-shadow: 0 1px 1px rgb(0 0 0 / 0%);
    border-radius: 0;
    background: transparent;
    @media screen and (min-width: 601px) {
        border-left-style: solid;
        border-left-color: #f5f5f5;
        border-left-width: 2px;
        margin-left: -2px;
    }
    width: 100%;
`
const VExternalLoginPanel = styled.div`
    background: transparent;
    display: none;
    @media screen and (min-width: 601px) {
        display: block;
    }
    //width: 100%;
`
const HorPanel = styled.div`
    display: none;
    @media screen and (max-width: 600px) {
        font-size: 16px;
        display: block;
        position: absolute;
        background-color: #fff;
        text-align: center;
        color: #a3a3a3;
        padding-top: 15px;
        padding-bottom: 15px;
        margin-top: 0.2rem;
        margin-left: 35%;
    }
`
const VorPanel = styled.div`
    @media screen and (min-width: 601px) {
        font-size: 16px;
        display: block;
        position: absolute;
        background-color: #fff;
        text-align: center;
        color: #a3a3a3;
        padding-top: 15px;
        padding-bottom: 15px;
        top: 40%;
        margin-left: -8px;
    }
`
const IdpDescriptionPanel = styled.span`
    padding-top: 10px;
    padding-bottom: 10px;
    display: block;
`
const IntelSSOButton = styled(Button)`
    font-size: 14px;
    font-weight: bold;
    width: 20rem;
    color: #fff;
    background-color: #0dcaf0;
    border-color: #0dcaf0;
    max-width: 25rem;
    border-radius: 0px;
    margin-bottom: 3rem;
`
const SSOTextSpan = styled.span`
    color: #fff;
    font-weight: 500;
    font-size: 1.2rem;
    vertical-align: middle;
`

export interface ICreateOrgPanelProps {
    onCreateOrgSubmit: (createOrgRequest: any) => void
    createOrgLoading: LoadingState
    createOrgResponse?: any
    createOrgError: any
    invitationKey: string | null
    acceptInvites: typeof acceptInvites
    acceptInvitesError: any
    acceptInvitesLoading: LoadingState
    acceptInvitesResult: AcceptInvitesResponse
    clearSession: (authToken: AuthToken | null) => void
}

const CreateOrgPanel: FunctionComponent<ICreateOrgPanelProps> = ({
    onCreateOrgSubmit,
    createOrgLoading,
    createOrgResponse,
    createOrgError,
    invitationKey,
    acceptInvites,
    acceptInvitesError,
    acceptInvitesLoading,
    acceptInvitesResult,
    clearSession,
}: ICreateOrgPanelProps) => {
    const {
        onAccountEmailChange,
        onUserFullNameChange,
        onAccountPasswordChange,
        onAccountConfirmPasswordChange,
        onSubmit,
        validated,
        onCancel,
        onAccountOrgChange,
        signUpUserData,
        showSignupForm,
        onCodeConfirmSubmit,
        onResendConfirmationCode,
        onCodeConfirmChange,
        signUpError,
        codeValidated,
        onCodeConfirmKeyDown,
        onPasswordKeyUp,
        isPasswordContainLowerLetter,
        isPasswordContainSpecialChar,
        isPasswordContainNumber,
        isPasswordLength10Char,
        showPasswordvalidation,
    } = CreateOrgPanelLogic({
        onCreateOrgSubmit,
        createOrgResponse,
        createOrgError,
        invitationKey,
        acceptInvites,
        acceptInvitesError,
        acceptInvitesLoading,
        acceptInvitesResult,
    } as ICreateOrgPanelProps)

    let navigate = useNavigate()
    localStorage.setItem('signInRoute', '')

    const createAccountComponent = (
        <ExternalLoginContainerPanel>
            <PageTitleContainer>
                <PageTitleTextLabel data-testid="pagetitle-lable">
                    {showSignupForm ? 'Create an account' : 'Confirm Code'}{' '}
                </PageTitleTextLabel>
            </PageTitleContainer>
            <PageTitleContainer>
                <PageTitleTextLabel2 data-testid="pageSubTitle-lable">
                    {showSignupForm
                        ? 'Get the most performance out of your software!'
                        : 'Please check your email for confirmation code!'}
                </PageTitleTextLabel2>
            </PageTitleContainer>

            <HorizontalContainer>
                {showSignupForm ? (
                    <CreateOrgFormComponent>
                        <AlertComponent
                            style={{ width: '25rem' }}
                            variant="danger"
                            show={signUpError ? true : false}
                            data-testid="error-text"
                        >
                            {'Error! ' + signUpError?.message}
                        </AlertComponent>
                        <Form
                            noValidate
                            validated={validated}
                            onSubmit={onSubmit}
                            id="createOrgForm"
                        >
                            {stringUtils.isNullOrUndefinedOrEmpty(
                                invitationKey
                            ) ? (
                                <Form.Group controlId="OrgName">
                                    <TextLabel data-testid="organization-name-label">
                                        <RequiredTextLabel>*</RequiredTextLabel>
                                        Organization name{' '}
                                    </TextLabel>
                                    <TextBoxWrapper
                                        placeholder="Enter your org's name..."
                                        onChange={onAccountOrgChange}
                                        required
                                        data-testid="organization-name-text"
                                    />

                                    <Form.Control.Feedback type="invalid">
                                        Please enter your org's name.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            ) : (
                                <div></div>
                            )}
                            <Form.Group controlId="userFullName">
                                <TextLabel data-testid="userfullname-label">
                                    <RequiredTextLabel>*</RequiredTextLabel>
                                    Full name{' '}
                                </TextLabel>
                                <TextBoxWrapper
                                    placeholder="Enter your full name..."
                                    onChange={onUserFullNameChange}
                                    required
                                    data-testid="userfullname-text"
                                />

                                <Form.Control.Feedback type="invalid">
                                    Please enter your full name.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="formBasicEmail">
                                <TextLabel data-testid="email-address-label">
                                    <RequiredTextLabel>*</RequiredTextLabel>
                                    Email address{' '}
                                </TextLabel>
                                <TextBoxWrapper
                                    type="email"
                                    placeholder="Enter email..."
                                    onChange={onAccountEmailChange}
                                    required
                                    data-testid="email-address-text"
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please enter an email address in format
                                    name@xyg.com.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="formBasicPassword">
                                <TextLabel data-testid="choose-password-label">
                                    <RequiredTextLabel>*</RequiredTextLabel>
                                    Choose a password{' '}
                                </TextLabel>
                                <TextBoxWrapper
                                    id="userPassword"
                                    name="userPassword"
                                    type="password"
                                    placeholder="Please enter password..."
                                    autoComplete="new-password"
                                    onChange={onAccountPasswordChange}
                                    onKeyUp={onPasswordKeyUp}
                                    required
                                    data-testid="choose-password-text"
                                />
                                {showPasswordvalidation ? (
                                    <PasswordValidationdiv>
                                        {isPasswordContainLowerLetter ? (
                                            <ValidPasswordSpan>
                                                ✓ Password must contain a lower
                                                case letter
                                            </ValidPasswordSpan>
                                        ) : (
                                            <InValidPasswordSpan>
                                                ✖ Password must contain a lower
                                                case letter
                                            </InValidPasswordSpan>
                                        )}

                                        {isPasswordContainSpecialChar ? (
                                            <ValidPasswordSpan>
                                                ✓ Password must contain a
                                                special character
                                            </ValidPasswordSpan>
                                        ) : (
                                            <InValidPasswordSpan>
                                                ✖ Password must contain a
                                                special character
                                            </InValidPasswordSpan>
                                        )}

                                        {isPasswordContainNumber ? (
                                            <ValidPasswordSpan>
                                                ✓ Password must contain a number
                                            </ValidPasswordSpan>
                                        ) : (
                                            <InValidPasswordSpan>
                                                ✖ Password must contain a number
                                            </InValidPasswordSpan>
                                        )}
                                        {isPasswordLength10Char ? (
                                            <ValidPasswordSpan>
                                                ✓ Password must contain at least
                                                10 characters
                                            </ValidPasswordSpan>
                                        ) : (
                                            <InValidPasswordSpan>
                                                ✖ Password must contain at least
                                                10 characters
                                            </InValidPasswordSpan>
                                        )}
                                    </PasswordValidationdiv>
                                ) : (
                                    ''
                                )}
                                <Form.Control.Feedback type="invalid">
                                    Please enter your password.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="formMatchPassword">
                                <TextLabel data-testid="confirm-password-label">
                                    <RequiredTextLabel>*</RequiredTextLabel>
                                    Confirm password{' '}
                                </TextLabel>
                                <TextBoxWrapper
                                    type="password"
                                    placeholder="Please re-enter password..."
                                    onChange={onAccountConfirmPasswordChange}
                                    required
                                    data-testid="confirm-password-text"
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please enter confirm password.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <ButtonSpinnerHorizontalComponent>
                                <SignUpButton
                                    variant="primary"
                                    type="submit"
                                    onClick={onSubmit}
                                    data-testid="signup-button"
                                >
                                    Submit
                                </SignUpButton>

                                <ResetButton
                                    variant="primary"
                                    type="reset"
                                    onClick={onCancel}
                                    data-testid="reset-button"
                                >
                                    Reset
                                </ResetButton>
                            </ButtonSpinnerHorizontalComponent>
                            <HavingAccountDiv>
                                <span>Already have an account?</span>&nbsp;
                                <a href="/signIn">Sign in</a>
                            </HavingAccountDiv>
                        </Form>
                    </CreateOrgFormComponent>
                ) : (
                    <ConfirmCodeComponent>
                        <AlertComponent
                            style={{ width: '25rem' }}
                            variant="danger"
                            show={signUpError ? true : false}
                            data-testid="signup-error-text"
                        >
                            {'Error! ' + signUpError?.message}
                        </AlertComponent>
                        <Form
                            noValidate
                            validated={codeValidated}
                            onSubmit={onCodeConfirmSubmit}
                            id="CodeConfirmForm"
                        >
                            <Form.Group controlId="code">
                                <TextLabel data-testid="entercode-label">
                                    <RequiredTextLabel>*</RequiredTextLabel>
                                    Enter Code{' '}
                                </TextLabel>
                                <TextBoxWrapper
                                    placeholder="Enter your code here..."
                                    type="search"
                                    onChange={onCodeConfirmChange}
                                    onKeyDown={onCodeConfirmKeyDown}
                                    required
                                    data-testid="entercode-text"
                                />

                                <Form.Control.Feedback type="invalid">
                                    Please enter your code.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <ConfirmButtonComponentDiv>
                                <SubmitConfirmationButton
                                    variant="primary"
                                    type="submit"
                                    onClick={onCodeConfirmSubmit}
                                    id="SubmitCodeConfirm"
                                    data-testid="confirmCode-submit-button"
                                >
                                    Submit
                                </SubmitConfirmationButton>
                            </ConfirmButtonComponentDiv>
                            <ResendCodeButtonComponent>
                                <DidNotGetCodeDiv data-testid="didnot-getcode-label">
                                    Didn't get code?
                                </DidNotGetCodeDiv>
                                <ResendCodeButton
                                    variant="primary"
                                    type="resend"
                                    onClick={onResendConfirmationCode}
                                    data-testid="resend-button"
                                >
                                    Resend confirmation code
                                </ResendCodeButton>
                            </ResendCodeButtonComponent>
                            {createOrgResponse?.msg &&
                                console.log(createOrgResponse?.msg)}
                        </Form>
                    </ConfirmCodeComponent>
                )}
            </HorizontalContainer>
        </ExternalLoginContainerPanel>
    )

    return (
        <CreateOrgFormContainer>
            <AuthPanelWrapper>
                <LoginContainerSSOPanel>
                    <HSeparatorDiv>
                        <IdpDescriptionPanel>
                            <TextLabel style={{ fontSize: '1.2rem' }}>
                                Intel employee sign in here:
                            </TextLabel>
                        </IdpDescriptionPanel>
                        <IntelSSOButton
                            variant="info"
                            type="submit"
                            href={getOauthSignInURL()}
                            aria-label="Intel"
                        >
                            <img
                                src={stringUtils.resolve(
                                    '/Intel_classicblue_white.png'
                                )}
                                height="32px"
                                width="95px"
                                alt="intel logo"
                                style={{ verticalAlign: 'middle' }}
                            />{' '}
                            <SSOTextSpan>SSO</SSOTextSpan>
                        </IntelSSOButton>
                        <HorPanel>or</HorPanel>
                    </HSeparatorDiv>
                    <HExternalLoginPanel>
                        {createAccountComponent}
                    </HExternalLoginPanel>
                </LoginContainerSSOPanel>
                <VExternalLoginPanel>
                    <VorPanel>or</VorPanel>
                    {createAccountComponent}
                </VExternalLoginPanel>
            </AuthPanelWrapper>
        </CreateOrgFormContainer>
    )
}

export default CreateOrgPanel
