import { useCallback } from 'react'
import { NewBenchmarkRequest } from '../../models/newBenchmarkRequest'
import { INewBenchmarkContainerProps } from './newBenchmarkContainer'

export function NewBenchmarkContainerLogic({
    submitNewBenchmark,
}: INewBenchmarkContainerProps) {
    const onNewBenchmarkSubmit = useCallback(
        async (newBenchmarkRequest: NewBenchmarkRequest) => {
            submitNewBenchmark(newBenchmarkRequest)
        },
        []
    )
    return {
        onNewBenchmarkSubmit,
    }
}
