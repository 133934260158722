/************************************************
 * Copyright (C) 2021 Intel Corporation
 ************************************************/
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import './index.css'
import './css/intel-nav.min.css'
import './fonts/IntelClearPro_W_Bd.woff'
import './fonts/intelone-display-light.woff2'
import './fonts/intelone-display-regular.woff'
import './fonts/intelone-text-regular.woff'
import reportWebVitals from './reportWebVitals'
import store from './store/store'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'antd/dist/antd.min.css'
import { ThemeProvider } from 'styled-components'
import { defaultTheme } from './themes/defaultTheme/theme'
import DashboardContainer from './containers/dashboardContainer/dashboardContainer'
import FavoritesContainer from './containers/favoritesContainer/favoritesContainer'
import CatalogContainer from './containers/catalogContainer/catalogContainer'
import LoginCallBackContainer from './containers/loginContainer/loginCallBackContainer'
import MyRequestContainer from './containers/myRequest/myRequestContainer'
import NewOptimizationContainer from './containers/newOptimization/newOptimizationContainer'
import NewBenchmarkContainer from './containers/newBenchmark/newBenchmarkContainer'
import ViewDetailsContainer from './containers/myRequest/viewDetailsContainer'
import AboutContainer from './containers/aboutContainer/aboutContainer'
import FlamegraphContainer from './containers/flamegraphContainer/flamegraphContainer'
import TryNowPageContainer from './containers/tryNowPageContainer/tryNowPageContainer'
import CartContainer from './containers/cartContainer/cartContainer'
import PageNotFoundContainer from './containers/pageNotFoundContainer/pageNotFoundContainer'
import CreateOrgContainer from './containers/createOrgContainer/createOrgContainer'
import AccessMgmtContainer from './containers/accessMgmtContainer/accessMgmtContainer'
import MembershipContainer from './containers/membershipContainer/membershipContainer'
import { PerformanceSamplesContainer } from './containers/performanceSamplesContainer/perfomanceSamplesContainer'
import DiscountContainer from './containers/discountContainer/discountContainer'
import { UAParser } from 'ua-parser-js'
import UnSupportedBrowserError from './layout/unSupportedBrowserError'
import StringUtils from './lib/stringUtils'
import AuthenticationContainer from './containers/authenticationContainer/authenticationContainer'
import ChangePasswordContainer from './containers/changePasswordContainer/changePasswordContainer'
import OptimizationDetailsContainer from './containers/optimizationDetailsContainer/optimizationDetailsContainer'
import ActivateAccountContainer from './containers/authenticationContainer/activateAccountContainer'
import AcceptInviteContainer from './containers/acceptInviteContainer/acceptInviteContainer'
import SwaggerContainer from './containers/swaggerContainer/swaggerContainer'
import BenchmarkContainer from './containers/benchmarkContainer/benchmarkContainer'
import BenchmarkHomeContainer from './containers/benchmarkContainer/benchmarkHomeContainer'
import WorkloadsContainer from './containers/benchmarkContainer/workloadsContainer'
import { APP_BASE_URL } from './config/service'
import AddNewOptimizationContainer from './containers/addNewOptimization/addNewOptimizationContainer'
import AllRequestContainer from './containers/allRequest/allRequestContainer'
import AllSupportReqContainer from './containers/allRequest/allSupportReqContainer'
import BenchmarkDevHomeContainer from './containers/benchmarkContainer/benchmarkDevHomeContainer'
import AddOrganizationContainer from './containers/addOrganizationContainer/addOrganizationContainer'
import { CloudInsightsContainer } from './containers/cloudInsightsContainer/cloudInsightsContainer'
import ChatbotContainer from './containers/chatbotContainer/chatbotContainer'
import LandingPageContainer from './containers/homePageContainer/landingPageContainer'
import DiscoverContainer from './aiModels/containers/discoverContainer'
import OtherModelContainer from './aiModels/containers/OtherModelContainer'
import RetrievalContainer from './aiModels/containers/retrievalContainer'
import CompareContainer from './aiModels/containers/compareContainer'
import VisualContainer from './aiModels/containers/visualContainer'
import SummarizationContainer from './aiModels/containers/summarizationContainer'
import TranslationContainer from './aiModels/containers/translationContainer'
import CreateDiscount from  './containers/createDiscountContainer/createDiscountContainer'
import { APP_DOMAIN } from './config/service'
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

console.log('v5.0')
let ugerAgent = navigator.userAgent
let isChromeBrowser: boolean = true
let isAllowedOtherBrowser: boolean = false
const ua = new UAParser(ugerAgent)
let browserName = ua.getBrowser().name
if (browserName !== undefined && browserName !== 'Chrome') {
    isChromeBrowser = false
}
let allowOtherBrowser = localStorage.getItem('isAllowedOtherBrowser')
if (!StringUtils.isNullOrUndefinedOrEmpty(allowOtherBrowser)) {
    isAllowedOtherBrowser = true
}
const aiExplorerHost = 'aiexplorer.intel.com'
root.render(
    // <React.StrictMode>
    <ThemeProvider theme={defaultTheme}>
        <Provider store={store}>
            <BrowserRouter basename={APP_BASE_URL ? APP_BASE_URL : ''}>
                <Routes>
                    <Route
                        index
                        element={
                            (isChromeBrowser || isAllowedOtherBrowser) &&
                            APP_DOMAIN === aiExplorerHost ? (
                                <DiscoverContainer />
                            ) : isChromeBrowser || isAllowedOtherBrowser ? (
                                <LandingPageContainer />
                            ) : (
                                <UnSupportedBrowserError />
                            )
                        }
                    />
                    <Route
                        path="/home"
                        element={
                            APP_DOMAIN === aiExplorerHost ? (
                                <DiscoverContainer />
                            ) : (
                                <LandingPageContainer />
                            )
                        }
                    />

                    <Route
                        path="/login/callback"
                        element={<LoginCallBackContainer />}
                    />
                    <Route path="/catalog" element={<CatalogContainer />} />
                    <Route
                        path="/optimizations"
                        element={<CatalogContainer />}
                    />
                    <Route
                        path="/public/catalog"
                        element={<CatalogContainer />}
                    />
                    <Route
                        path="/salesview/home"
                        element={<BenchmarkHomeContainer />}
                    />
                    <Route
                        path="/benchmarks"
                        element={<BenchmarkDevHomeContainer />}
                    />
                    <Route
                        path="/cloudInsights"
                        element={<CloudInsightsContainer />}
                    />
                    <Route
                        path="/benchmark/:workloadId"
                        element={<BenchmarkContainer />}
                    />
                    <Route
                        path="/optimization/:workloadId"
                        element={<BenchmarkContainer />}
                    />
                    <Route
                        path="/allWorkloads"
                        element={<WorkloadsContainer />}
                    />
                    <Route
                        path="/allWorkloads/:workloadId"
                        element={<WorkloadsContainer />}
                    />
                    <Route path="/dashboard" element={<DashboardContainer />} />
                    <Route path="/favorites" element={<FavoritesContainer />} />
                    <Route
                        path="/addNewOptimization"
                        element={<AddNewOptimizationContainer />}
                    >
                        <Route
                            path=":recipeId"
                            element={<AddNewOptimizationContainer />}
                        />
                        <Route
                            path=":recipeId/view"
                            element={<AddNewOptimizationContainer />}
                        />
                        <Route
                            path="controller/:recipeId"
                            element={<AddNewOptimizationContainer />}
                        />
                        <Route
                            path="controller/:recipeId/view"
                            element={<AddNewOptimizationContainer />}
                        />
                    </Route>
                    <Route
                        path="/allUploadRequest"
                        element={<AllRequestContainer />}
                    />
                    <Route
                        path="/allSupportRequest"
                        element={<AllSupportReqContainer />}
                    />

                    <Route
                        path={`/performanceSamples/:id`}
                        element={<PerformanceSamplesContainer />}
                    />
                    <Route path="/myRequest" element={<MyRequestContainer />} />
                    <Route
                        path={'/viewDetails/:feedbackId'}
                        element={<ViewDetailsContainer />}
                    />
                    <Route
                        path={'/viewDetails/all/:feedbackId'}
                        element={<ViewDetailsContainer />}
                    />
                    <Route
                        path={'/viewDetails/request/:requestId'}
                        element={<ViewDetailsContainer />}
                    />
                    <Route
                        path={'/viewDetails/benchmarkRequest/:benchmarkId'}
                        element={<ViewDetailsContainer />}
                    />
                    <Route
                        path="/requestNewOptimization"
                        element={<NewOptimizationContainer />}
                    />
                    <Route
                        path="/requestNewBenchmark"
                        element={<NewBenchmarkContainer />}
                    />
                    <Route
                        path="/addOrganization"
                        element={<AddOrganizationContainer />}
                    />
                    <Route
                        path="/createAccount"
                        element={<CreateOrgContainer />}
                    />
                    <Route
                        path="/activateAccount"
                        element={<ActivateAccountContainer />}
                    />
                    <Route
                        path="/invitation"
                        element={<AcceptInviteContainer />}
                    />
                    <Route
                        path="/membership"
                        element={<MembershipContainer />}
                    />
                    <Route path="/about" element={<AboutContainer />} />
                    <Route
                        path="/gProfilerAnalyzer"
                        element={<FlamegraphContainer />}
                    />
                    <Route path={'/trynow'} element={<TryNowPageContainer />} />
                    {/* <Route path="/cart" element={<CartContainer />} /> */}
                    <Route
                        path="/discounts/:code"
                        element={<DiscountContainer />}
                    />
                    <Route
                        path={'/accessMgmt'}
                        element={<AccessMgmtContainer />}
                    />
                    <Route
                        path="/signIn"
                        element={<AuthenticationContainer />}
                    />
                    <Route
                        path="/forgotPassword"
                        element={<ChangePasswordContainer />}
                    />
                    <Route
                        path="/optDetails"
                        element={<OptimizationDetailsContainer />}
                    />
                    <Route path="/chatbot" element={<ChatbotContainer />} />
                    <Route path="/discounter" element={<CreateDiscount />} />
                    
                    {/* aiModels routings start*/}
                    <Route path="/explore" element={<DiscoverContainer />} />
                    <Route
                        path="/explore/retrieval"
                        element={<RetrievalContainer />}
                    />
                    <Route
                        path="/explore/search"
                        element={<OtherModelContainer />}
                    />
                    <Route
                        path="/explore/visual"
                        element={<VisualContainer />}
                    />
                    <Route
                        path="/explore/evaluate"
                        element={<CompareContainer />}
                    />
                    <Route
                        path="/explore/summarization"
                        element={<SummarizationContainer />}
                    />
                    <Route
                        path="/explore/translation"
                        element={<TranslationContainer />}
                    />
                    {/* <Route
                        path="/explore/biology"
                        element={<OtherModelContainer />}
                    />
                    <Route
                        path="/explore/gaming"
                        element={<OtherModelContainer />}
                    />
                    <Route
                        path="/explore/healthcare"
                        element={<OtherModelContainer />}
                    />
                    <Route
                        path="/explore/industrial"
                        element={<OtherModelContainer />}
                    /> */}

                    {/* aiModels routings ends*/}
                    <Route path="/swagger" element={<SwaggerContainer />} />
                    <Route path="/logOut" element={<LandingPageContainer />} />
                    <Route path="*" element={<PageNotFoundContainer />} />
                </Routes>
            </BrowserRouter>
        </Provider>
    </ThemeProvider>
    // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
