import { FunctionComponent, useEffect, useRef, useState } from 'react'
import { Button, Form, Overlay } from 'react-bootstrap'
import styled from 'styled-components'
import { useAppSelector } from '../../config/hooks'
import { UserSalesPermissions } from '../../models/userRoles'

const AccessContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0.2rem 1.5rem;
`
const AccessLayerButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 0px solid var(--input-stroke-dark-normal, #8e9099);
    background: none;
    content: unset !important;
    height: 1.8rem;
    color: var(--black-and-white-white, #fff);
    text-align: center;
    font-family: IntelOne Text;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    &:hover {
        border: 0px solid var(--input-stroke-dark-normal, #8e9099);
        background: none;
    }
    &:focus {
        border: 1px solid var(--input-stroke-dark-normal, #8e9099);
        background: none;
    }
`

export interface IAccessLevelPanelProps {
    onApplyAccessCallBack: (selection: string) => void
}

export const AccessLevelPanel: FunctionComponent<IAccessLevelPanelProps> = ({
    onApplyAccessCallBack,
}: IAccessLevelPanelProps) => {
    const accessLevelTarget = useRef(null)
    const accessLayerArr = [
        {
            name: 'CNDA',
            value: 'cnda',
            isChecked: false,
        },
        {
            name: 'NDA',
            value: 'nda',
            isChecked: false,
        },
        {
            name: 'Internal All',
            value: 'internal',
            isChecked: false,
        },
        {
            name: 'Public',
            value: 'public',
            isChecked: false,
        },
        {
            name: 'Category Sales',
            value: 'category-sales',
            isChecked: false,
        },
    ]
    const [accessLayerOptions, setAccessLayerOptions] =
        useState<any>(accessLayerArr)
    const [showAccessItems, setShowAccessItems] = useState(false)
    const [userAccessPermission, setUserAccessPermission] = useState<string[]>(
        []
    )
    let userAccess: string[] = []
    let userAccessOptions: any[] = []

    const [accessSelection, setAccessSelection] = useState<{
        value: string
        isChecked: boolean
    }>({ value: '', isChecked: false })

    const [selectedAccessDisplay, setSelectedAccessDisplay] =
        useState<string>('')

    const authPermission = useAppSelector<any>(
        (state: any) => state.authPermissionsResult.data
    )
    useEffect(() => {
        //assign default access from user authorization
        if (authPermission && authPermission?.readerAccessGroups) {
            authPermission?.readerAccessGroups.map((group: string) => {
                var item = accessLayerOptions.filter(
                    (item: any) => item.value === group.toLowerCase().trim()
                )
                if (item && item.length > 0) {
                    if (!userAccess.includes(item[0].name)) {
                        userAccess.push(item[0].name)
                        userAccessOptions.push(item[0])
                    }
                }
            })
            setUserAccessPermission(userAccess)
            setAccessLayerOptions(
                userAccessOptions.sort((a, b) =>
                    (a.value ? a.value : '') > (b.value ? b.value : '') ? 1 : -1
                )
            )
            let getSalesAccessLevelValue = localStorage.getItem(
                'selectedSalesAccess'
            )
            if (getSalesAccessLevelValue) {
                const isAccessExist = accessLayerOptions?.filter(
                    (val: any) => val === getSalesAccessLevelValue
                )
                    ? true
                    : false
                if (isAccessExist) {
                    setAccessSelection({
                        value: getSalesAccessLevelValue,
                        isChecked: true,
                    })
                }
            } else if (userAccess.includes(UserSalesPermissions.InternalAll)) {
                setAccessSelection({
                    value: UserSalesPermissions.InternalAll,
                    isChecked: true,
                })
            }
            setShowAccessItems(false)
        }
    }, [authPermission])

    useEffect(() => {
        //callback on change
        if (accessSelection.isChecked) {
            localStorage.setItem('selectedSalesAccess', accessSelection.value)
            onApplyAccessCallBack(accessSelection.value)
        } else {
            onApplyAccessCallBack('')
        }

        setSelectedAccessDisplay(getAccessDisplayValues())
        setShowAccessItems(!showAccessItems)
    }, [accessSelection])

    const accessLayerComponent = (
        <div>
            {userAccessPermission.includes(UserSalesPermissions.NDA) ||
            userAccessPermission.includes(UserSalesPermissions.CNDA) ||
            userAccessPermission.includes(UserSalesPermissions.InternalAll) ? (
                <AccessContainer>
                    <AccessLayerButton
                        type="submit"
                        data-testid="access-button"
                        onClick={() => {
                            setShowAccessItems(!showAccessItems)
                        }}
                        ref={accessLevelTarget}
                    >
                        <span style={{ padding: '0 0.2rem' }}>
                            {selectedAccessDisplay
                                ? `Current View: ${selectedAccessDisplay} `
                                : 'Select View'}
                        </span>
                        <img
                            src="/sales_img/sales_eye_icon.png"
                            alt="access icon"
                        />
                        <i className="uil uil-angle-down"></i>
                    </AccessLayerButton>
                    <Overlay
                        target={accessLevelTarget.current}
                        show={showAccessItems}
                        placement="bottom"
                    >
                        {({
                            placement,
                            arrowProps,
                            show: _show,
                            popper,
                            ...props
                        }) => (
                            <div
                                {...props}
                                style={{
                                    position: 'absolute',
                                    backgroundColor: '#ffff',
                                    padding: '1rem 2rem',
                                    marginTop: '0.1rem',
                                    borderRadius: 5,
                                    border: '1px',
                                    borderColor: '#6c757d',
                                    borderStyle: 'double',
                                    ...props.style,
                                }}
                                onMouseLeave={() => setShowAccessItems(false)}
                            >
                                {accessLayerOptions?.map(
                                    (option: any, index: number) => (
                                        <Form.Check
                                            type="switch"
                                            key={option.name + index}
                                            id={option.name + index}
                                            label={option.name}
                                            value={option.value}
                                            aria-label={option.name}
                                            checked={
                                                accessSelection.value ===
                                                    option.name &&
                                                accessSelection.isChecked
                                                    ? true
                                                    : false
                                            }
                                            onChange={(e: any) => {
                                                setAccessSelection({
                                                    value: option.name,
                                                    isChecked: e.target.checked,
                                                })
                                            }}
                                        />
                                    )
                                )}
                            </div>
                        )}
                    </Overlay>
                </AccessContainer>
            ) : (
                ''
            )}
        </div>
    )

    const getAccessDisplayValues = () => {
        let accessGroupFilters = ''
        if (accessSelection && accessSelection.isChecked)
            accessGroupFilters = accessSelection.value

        return accessGroupFilters
    }

    return <div>{accessLayerComponent}</div>
}
export default AccessLevelPanel
