import { FunctionComponent } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { KeyboardEvent } from 'react'
import {
    Button,
    Container,
    FormControl,
    InputGroup,
    Navbar,
    Offcanvas,
} from 'react-bootstrap'
import { useAppDispatch } from '../../config/hooks'
import SideLinkPanel from './sidePanel'
import { background } from '../../themes/defaultTheme/color'
import { useEffect } from 'react'

const HeaderMainContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    position: fixed;
    z-index: 50;
`
const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 3.5rem;
    padding-right: 0px;
    align-items: center;
    flex-shrink: 0;
    // background: var(--global-header-classic-blue-dark-fill, #00377c);
    // box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.1);
    background-color: rgb(101, 49, 113);
    background-image: ${(props) =>
        `url('${process.env.PUBLIC_URL}/background-l1-mosaic.svg')`};
    background-position-x: 50%;
    background-position-y: 50%;
    background-repeat: no-repeat;
    background-size: 100%;
    width: 100%;
`

const HeaderMenuDiv = styled.div`
    display: flex;
    justify-content: left;
    padding-left: 1rem;
`
const HeaderNavDiv = styled(HeaderMenuDiv)`
    display: none;
    @media screen and (max-width: 600px) {
        display: block;
        padding-left: 0;
    }
`
const HeaderImageDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: left;
    padding: 0.5rem;
    margin-left: 0.5rem;
`

const RightLinkPanelContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 1.5rem;
    @media screen and (max-width: 600px) {
        display: none;
    }
`
const LoginButton = styled.button`
    font-family: 'IntelOne Text';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    text-decoration: none;
    color: #fff;
    background-color: #78deff; //#8f5da2;
    margin-right: 1.5rem;
    border: none;
    min-width: 6rem;
    height: 2rem;
    border-radius: 20px;
    border: none;
`
const ContactUsButton = styled(Button)`
    font-family: 'IntelOne Text';
    font-style: normal;
    font-size: 1rem;
    text-decoration: none;
    border: none;
    color: #fff;
    background-color: #78deff;
    min-width: 8.5rem;
    border-radius: 0px;
    border: none;
    height: 2.2rem;
    //margin-right: 0.5rem;
`
const OHTitleText = styled.h3`
    text-decoration: none;
    text-align: center;
    justify-content: center;
    padding-top: 0.5rem;
    color: rgb(0, 199, 253);
    font-family: IntelOne Display;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 125% */
`

const HeaderImageComponent = () => (
    <HeaderImageDiv>
        <img
            src="/intel-header-logo.svg"
            height="40"
            width="76"
            alt="intel logo"
        />
    </HeaderImageDiv>
)

export interface IHeaderPanelProps {}

export const AiModelHeaderPanel: FunctionComponent<
    IHeaderPanelProps
> = ({}: IHeaderPanelProps) => {
    let navigate = useNavigate()
    const dispatch = useAppDispatch()

    const HeaderAppTitleComponent = () => (
        <HeaderImageDiv>
            <OHTitleText>AI Explorer</OHTitleText>
        </HeaderImageDiv>
    )
    useEffect(() => {
        document.title = 'Intel® AI Explorer'
        let metaDescription = document.querySelector('meta[name="description"]')
        if (metaDescription) {
            metaDescription.setAttribute(
                'content',
                'Interact with AI solutions on Gaudi 2'
            )
        }
    }, [])

    const offCanvasNav = () => {
        return (
            <>
                {[false].map((expand, index) => (
                    <Navbar expand={expand} key={`nav-${index}`}>
                        <Container fluid>
                            <Navbar.Toggle
                                style={{
                                    backgroundColor:
                                        'linear-gradient(180deg, #0d1b35, #1e3a84)',
                                }}
                                aria-controls={`offcanvasNavbar-expand-${expand}`}
                                id="offcanvasNavButton"
                            />
                            <Navbar.Offcanvas
                                id={`offcanvasNavbar-expand-${expand}`}
                                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                                placement="start"
                                style={{
                                    width: '250px',
                                    backgroundColor: 'rgb(242, 242, 242)',
                                }}
                            >
                                <Offcanvas.Header closeButton>
                                    <Offcanvas.Title
                                        id={`offcanvasNavbarLabel-expand-${expand}`}
                                    ></Offcanvas.Title>
                                </Offcanvas.Header>
                                <Offcanvas.Body>
                                    {<SideLinkPanel></SideLinkPanel>}
                                </Offcanvas.Body>
                            </Navbar.Offcanvas>
                        </Container>
                    </Navbar>
                ))}
            </>
        )
    }

    return (
        <HeaderMainContainer>
            <HeaderContainer>
                <HeaderMenuDiv>
                    <HeaderNavDiv>{offCanvasNav()}</HeaderNavDiv>
                    <HeaderImageComponent />
                    <HeaderAppTitleComponent />
                </HeaderMenuDiv>
                <RightLinkPanelContainer>
                    {/* <LoginButton onClick={() => navigate('/signIn')}>
                        Login
                    </LoginButton> */}

                    {/* <ContactUsButton
                        href="mailto:optimizations@intel.com"
                        style={{ color: '#fff' }}
                    >
                        Contact us
                    </ContactUsButton> */}
                </RightLinkPanelContainer>
            </HeaderContainer>
        </HeaderMainContainer>
    )
}

export default AiModelHeaderPanel
