import { FormEvent, FunctionComponent, useCallback } from 'react'
import styled from 'styled-components'
import StringUtils from '../../lib/stringUtils'

const FavoriteContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: right;
    padding-right: 3rem;
    @media screen and (max-width: 500px) {
        padding-left: 11rem !important;
        padding-right: 4rem;
    }
`
const FavoriteCountDiv = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: right;
    padding-bottom: 0.1rem;
    border-radius: 0.1rem;
    min-width: 20%;
    padding-left: 0.5rem;
`
const FavoriteButton = styled.button`
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    tabindex: 0;
    &:hover {
        color: #00a3fb;
    }
    &:focus {
        color: #00a3fb;
        border: 1px solid transparent;
        outline: auto;
    }
`
const CountSpan = styled.span`
    position: relative;
    top: 25%;
`
export interface IFavoritePanel {
    isFavorite: boolean
    recipePipelineStage: string
    onFavoriteClick: (event: FormEvent<HTMLElement>) => void
    favoriteCount: number
}

export const SetFavorite: FunctionComponent<IFavoritePanel> = ({
    isFavorite,
    recipePipelineStage,
    onFavoriteClick,
    favoriteCount,
}: IFavoritePanel) => {
    const handleOnFavoriteClick = useCallback(
        (event: FormEvent<HTMLElement>) => {
            onFavoriteClick(event)
        },
        []
    )
    const favCountRender = (
        <CountSpan>
            {favoriteCount && favoriteCount >= 1000
                ? favoriteCount / 1000 + 'k'
                : favoriteCount}
        </CountSpan>
    )

    return (
        <FavoriteContainer>
            <FavoriteCountDiv>
                <FavoriteButton
                    type="button"
                    onClick={(e) => handleOnFavoriteClick(e)}
                    value={'' + isFavorite + ''}
                    title={!isFavorite ? 'Set Favorite' : 'Favorite'}
                >
                    {isFavorite ? (
                        <img
                            src={StringUtils.resolve('/favorite_filled.png')}
                            height="20"
                            width="20"
                            alt="Favorite logo"
                        />
                    ) : (
                        <img
                            src={StringUtils.resolve('/favorite.png')}
                            height="20"
                            width="20"
                            alt="unFavorite logo"
                        />
                    )}
                </FavoriteButton>
                {[favCountRender]}
            </FavoriteCountDiv>
        </FavoriteContainer>
    )
}

export default SetFavorite
