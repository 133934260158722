import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { LoadingState } from '../models/loadingState'
import AnalyticsService from '../services/analyticsService'

const initialState = {
    data: null,
    loading: LoadingState.Idle,
    error: null,
}

export const getDashboardData = createAsyncThunk(
    'getDashboardData/getDashboardData',
    async (args: any) => await AnalyticsService.getDashboardData(args)
)

const getDashboardDataSlice = createSlice({
    name: 'getDashboardData',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getDashboardData.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(getDashboardData.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload.data
                }
            })
            .addCase(getDashboardData.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})

export const getAnalytics = createAsyncThunk(
    'getAnalytics/getAnalytics',
    async (args: any) => await AnalyticsService.getAnalytics(args)
)

const getAnalyticsSlice = createSlice({
    name: 'getAnalytics',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAnalytics.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(getAnalytics.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload.data
                }
            })
            .addCase(getAnalytics.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})

export const getSearchDist = createAsyncThunk(
    'getSearchDist/getSearchDist',
    async (args: any) => await AnalyticsService.getSearchDist(args)
)

const getSearchDistSlice = createSlice({
    name: 'getSearchDist',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getSearchDist.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(getSearchDist.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload.data
                }
            })
            .addCase(getSearchDist.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})

export const getIntelSearchDist = createAsyncThunk(
    'getSearchDist/getIntelSearchDist',
    async (args: any) => await AnalyticsService.getSearchDist(args)
)

const getIntelSearchDistSlice = createSlice({
    name: 'getIntelSearchDist',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getIntelSearchDist.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(getIntelSearchDist.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload.data
                }
            })
            .addCase(getIntelSearchDist.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})

export const getUniqueLogins = createAsyncThunk(
    'getUniqueLogins/getUniqueLogins',
    async (timeStr: string) => await AnalyticsService.getUniqueLogins(timeStr)
)

const getUniqueLoginsSlice = createSlice({
    name: 'getUniqueLogins',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getUniqueLogins.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(getUniqueLogins.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload.data
                }
            })
            .addCase(getUniqueLogins.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})

export const getUniqueLoginsWithToDate = createAsyncThunk(
    'getUniqueLoginsWithToDate/getUniqueLoginsWithToDate',
    async (timeObj: any) =>
        await AnalyticsService.getUniqueLoginsWithToDate(timeObj)
)

const getUniqueLoginsWithToDateSlice = createSlice({
    name: 'getUniqueLoginsWithToDate',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getUniqueLoginsWithToDate.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(
                getUniqueLoginsWithToDate.fulfilled,
                (state, action: any) => {
                    if (state.loading === LoadingState.Pending) {
                        state.loading = LoadingState.Idle
                        state.data = action.payload.data
                    }
                }
            )
            .addCase(
                getUniqueLoginsWithToDate.rejected,
                (state, action: any) => {
                    if (state.loading === LoadingState.Pending) {
                        state.loading = LoadingState.Idle
                        state.error = action.error
                    }
                }
            )
    },
})

export const getNoHits = createAsyncThunk(
    'getNoHits/getNoHits',
    async (obj: any) => await AnalyticsService.getNoHits(obj)
)

const getNoHitsSlice = createSlice({
    name: 'getNoHits',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getNoHits.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(getNoHits.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload.data
                }
            })
            .addCase(getNoHits.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})

export const getAllNoHits = createAsyncThunk(
    'getAllNoHits/getAllNoHits',
    async (obj: any) => await AnalyticsService.getAllNoHits(obj)
)

const getAllNoHitsSlice = createSlice({
    name: 'getAllNoHits',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllNoHits.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(getAllNoHits.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload.data
                }
            })
            .addCase(getAllNoHits.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})

export const getTotalHits = createAsyncThunk(
    'getTotalHits/getTotalHits',
    async (obj: any) => await AnalyticsService.getTotalHits(obj)
)

const getTotalHitsSlice = createSlice({
    name: 'getTotalHits',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getTotalHits.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(getTotalHits.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload.data
                }
            })
            .addCase(getTotalHits.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})

export const getTotalNoHits = createAsyncThunk(
    'getTotalNoHits/getTotalNoHits',
    async (obj: any) => await AnalyticsService.getTotalNoHits(obj)
)

const getTotalNoHitsSlice = createSlice({
    name: 'getTotalNoHits',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getTotalNoHits.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(getTotalNoHits.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload.data
                }
            })
            .addCase(getTotalNoHits.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})

export const getClickCounts = createAsyncThunk(
    'getClickCounts/getClickCounts',
    async (obj: any) => await AnalyticsService.getClickCounts(obj)
)

const getClickCountsSlice = createSlice({
    name: 'getClickCounts',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getClickCounts.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(getClickCounts.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload.data
                }
            })
            .addCase(getClickCounts.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})
export const getCardsActivitySummary = createAsyncThunk(
    'getCardsActivitySummary',
    async (args: any) => await AnalyticsService.getCardsActivityDist(args)
)

const CardsActivitySummarySlice = createSlice({
    name: 'getCardsActivitySummary',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCardsActivitySummary.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(
                getCardsActivitySummary.fulfilled,
                (state, action: any) => {
                    if (state.loading === LoadingState.Pending) {
                        state.loading = LoadingState.Idle
                        state.data = action.payload.data
                    }
                }
            )
            .addCase(getCardsActivitySummary.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})

export const getEngagedUsers = createAsyncThunk(
    'getEngagedUsers/getEngagedUsers',
    async (obj: any) => await AnalyticsService.getEngagedUsers(obj)
)

const getEngagedUsersSlice = createSlice({
    name: 'getEngagedUsers',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getEngagedUsers.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(getEngagedUsers.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload.data
                }
            })
            .addCase(getEngagedUsers.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})

export const getDisengagedUsers = createAsyncThunk(
    'getDisengagedUsers/getDisengagedUsers',
    async (obj: any) => await AnalyticsService.getDisengagedUsers(obj)
)

const getDisengagedUsersSlice = createSlice({
    name: 'getDisengagedUsers',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getDisengagedUsers.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(getDisengagedUsers.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload.data
                }
            })
            .addCase(getDisengagedUsers.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})

export const getFirstTimeCustomers = createAsyncThunk(
    'getFirstTimeCustomers/getFirstTimeCustomers',
    async (obj: any) => await AnalyticsService.getFirstTimeCustomers(obj)
)

const getFirstTimeCustomersSlice = createSlice({
    name: 'getFirstTimeCustomers',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getFirstTimeCustomers.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(getFirstTimeCustomers.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload.data
                }
            })
            .addCase(getFirstTimeCustomers.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})

export const getDashboardDataResult = getDashboardDataSlice.reducer
export const getAnalyticsResult = getAnalyticsSlice.reducer
export const getSearchDistResult = getSearchDistSlice.reducer
export const getIntelSearchDistResult = getIntelSearchDistSlice.reducer
export const getUniqueLoginsResult = getUniqueLoginsSlice.reducer
export const getUniqueLoginsWithToDateResult =
    getUniqueLoginsWithToDateSlice.reducer
export const getNoHitsResult = getNoHitsSlice.reducer
export const getAllNoHitsResult = getAllNoHitsSlice.reducer
export const getTotalHitsResult = getTotalHitsSlice.reducer
export const getTotalNoHitsResult = getTotalNoHitsSlice.reducer
export const getClickCountsResult = getClickCountsSlice.reducer
export const getCardsActivitySummaryResult = CardsActivitySummarySlice.reducer
export const getEngagedUsersResult = getEngagedUsersSlice.reducer
export const getDisengagedUsersResult = getDisengagedUsersSlice.reducer
export const getFirstTimeCustomersResult = getFirstTimeCustomersSlice.reducer
