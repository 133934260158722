import { useState, useCallback, FormEvent, ChangeEvent, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../config/hooks'
import { LoadingState } from '../../models/loadingState'
import { NewOptimizationRequest } from '../../models/newOptimizationRequest'
import { getFiltersValues } from '../../store/catalog.slice'
import { INewOptimizationPanelProps } from './newOptimizationPanel'
import { useNavigate } from 'react-router-dom'

export function NewOptimizationPanelLogic({
    onNewOptimizationSubmit,
    newOptimizationLoading,
    newOptimizationResult,
    newOptimizationError,
    keyAudienceList,
    keySegmentList,
}: INewOptimizationPanelProps) {
    const dispatch = useAppDispatch()
    const [optimizationName, setOptimizationName] = useState<string>('')
    const [solutionDescription, setSolutionDescription] = useState<string>('')
    const [useCase, setUseCase] = useState<any>([])
    const [scaleLevel, setScaleLevel] = useState<string>('')
    const [deploymentModel, setDeploymentModel] = useState('')
    const [platform, setPlatform] = useState<any>([])
    const [swDetails, setSWDetails] = useState<string>('')
    const [timeline, setTimeline] = useState<string>('')
    const [scaleOut, setScaleOut] = useState<string>('')
    const [keyAudience, setKeyAudience] = useState<
        { name: string | null; checkedValue: boolean }[]
    >([])
    const [keySegment, setKeySegment] = useState<
        { name: string | null; checkedValue: boolean }[]
    >([])
    const [solutionType, setSolutionType] = useState<any>([])
    const [requestor, setRequestor] = useState<string>('')
    const [customer, setCustomer] = useState<string>('')
    const [validated, setValidated] = useState(false)
    const [isRequireDeploymentModel, setIsRequireDeploymentModel] =
        useState(true)
    const [isRequireSolutiontype, setIsRequireSolutiontype] = useState(true)
    const [isRequireKeyAudience, setIsRequireKeyAudience] = useState(true)
    const [isRequireKeySegment, setIsRequireKeySegment] = useState(true)
    const [businessImpact, setBusinessImpact] = useState<string>('')
    const [otherStakeholder, setOtherStakeholder] = useState<string>('')
    const [intelValueProp, setIntelValueProp] = useState<string>('')
    const [isFormSubmitted, setIsFormSubmitted] = useState(false)
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false)

    const handleConfirmationClose = () => {
        setShowConfirmationPopup(false)
        navigate('/myRequest')
    }

    const filtersValues = useAppSelector<any>(
        (state: any) => state.getFiltersValuesResult.data
    )
    const navigate = useNavigate()
    useEffect(() => {
        let filtersParm =
            'filter=Platform&filter=Feature&filter=Scale level&filter=Type'
        dispatch(getFiltersValues(filtersParm))
    }, [])

    const onOptimizationNameChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLTextAreaElement
            if (target.value) {
                setOptimizationName(target.value)
            }
        },
        []
    )
    const onSolutionDescriptionChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLTextAreaElement
            if (target.value) {
                setSolutionDescription(target.value)
            }
        },
        []
    )

    const onUseCaseChange = useCallback((target: any) => {
        if (target) {
            setUseCase(target)
        }
    }, [])
    const onScaleLevelChange = useCallback((target: any) => {
        if (target && target.value) {
            setScaleLevel(target.value)
        }
    }, [])
    const onDeploymentChange = useCallback((target: any) => {
        if (target && target.value) {
            setDeploymentModel(target.value)
        }
    }, [])
    const onPlatformChange = useCallback((target: any) => {
        if (target) {
            setPlatform(target)
        }
    }, [])

    const onSWDetailsChange = useCallback((event: ChangeEvent<HTMLElement>) => {
        const target = event.currentTarget as HTMLTextAreaElement
        if (target.value) {
            setSWDetails(target.value)
        }
    }, [])
    const onTimelineChange = useCallback((event: ChangeEvent<HTMLElement>) => {
        const target = event.currentTarget as HTMLDataElement
        if (target.value) {
            setTimeline(target.value)
        }
    }, [])
    const onBusinessImpactChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLTextAreaElement
            if (target.value) {
                setBusinessImpact(target.value)
            }
        },
        []
    )
    const onOtherStakeholderChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLTextAreaElement
            if (target.value) {
                setOtherStakeholder(target.value)
            }
        },
        []
    )
    const onIntelValuePropChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLTextAreaElement
            if (target.value) {
                setIntelValueProp(target.value)
            }
        },
        []
    )

    const onKeyAudianceChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLFormElement
            if (target) {
                setIsRequireKeyAudience(!target.checked)
                if (keyAudienceList) {
                    const newSolution = keyAudienceList?.map(
                        (item: {
                            name: string | null
                            checkedValue: boolean
                        }) => {
                            if (target.value === item.name) {
                                item.checkedValue = target.checked
                            }

                            return item
                        }
                    )
                    setKeyAudience(newSolution)
                }
            }
        },
        []
    )
    const onKeySegmentChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLFormElement
            if (target) {
                setIsRequireKeySegment(!target.checked)
                if (keySegmentList) {
                    const newSolution = keySegmentList?.map(
                        (item: {
                            name: string | null
                            checkedValue: boolean
                        }) => {
                            if (target.value === item.name) {
                                item.checkedValue = target.checked
                            }

                            return item
                        }
                    )
                    setKeySegment(newSolution)
                }
            }
        },
        []
    )

    const onSolutionTypeChange = useCallback((target: any) => {
        if (target) {
            setSolutionType(target)
        }
    }, [])

    const onRequestorChange = useCallback((event: ChangeEvent<HTMLElement>) => {
        const target = event.currentTarget as HTMLInputElement
        if (target.value) {
            setRequestor(target.value)
        }
    }, [])
    const onCustomerChange = useCallback((event: ChangeEvent<HTMLElement>) => {
        const target = event.currentTarget as HTMLInputElement
        if (target.value) {
            setCustomer(target.value)
        }
    }, [])

    const onSubmit = useCallback(
        (event: FormEvent<HTMLFormElement>) => {
            event.preventDefault()

            const form = event.currentTarget
            if (form.checkValidity() === false) {
                event.preventDefault()
                event.stopPropagation()
            }
            setValidated(true)
            let audience = ''
            let segment = ''
            if (
                optimizationName &&
                solutionDescription &&
                useCase.length > 0 &&
                platform.length > 0 &&
                solutionType.length > 0 &&
                swDetails &&
                timeline &&
                requestor &&
                customer &&
                intelValueProp
            ) {
                setIsFormSubmitted(true)
                const formData = new FormData(form),
                    formDataObj = Object.fromEntries(formData.entries())

                formDataObj['platform'] = platform.map((p: any) => ({
                    value: p.value,
                }))
                formDataObj['useCase'] = useCase.map((u: any) => ({
                    value: u.value,
                }))
                formDataObj['type'] = solutionType.map((t: any) => ({
                    value: t.value,
                }))

                keyAudience.map((item) => {
                    if (item.checkedValue && item.name) {
                        audience = audience
                            ? audience + ',' + item.name
                            : item.name
                    }
                })
                keySegment.map((item) => {
                    if (item.checkedValue && item.name)
                        segment = segment
                            ? segment + ',' + item.name
                            : item.name
                })
                formDataObj['description'] = solutionDescription
                formDataObj['deployment'] = deploymentModel
                formDataObj['scaleLevel'] = scaleLevel
                formDataObj['swdetails'] = swDetails
                formDataObj['timeline'] = timeline
                formDataObj['requestor'] = requestor
                formDataObj['customer'] = customer
                formDataObj['business'] = businessImpact
                formDataObj['intelValueProp'] = intelValueProp
                formDataObj['audience'] = audience
                formDataObj['segment'] = segment

                const payload: any = {
                    requestType: 'newOptimization',
                    additionalProperties: formDataObj,
                }

                //console.log(payload)
                onNewOptimizationSubmit(payload)
            }
        },
        [
            optimizationName,
            solutionDescription,
            useCase,
            scaleLevel,
            platform,
            deploymentModel,
            swDetails,
            timeline,
            solutionType,
            requestor,
            customer,
            intelValueProp,
            businessImpact,
            onNewOptimizationSubmit,
        ]
    )

    const onCancle = useCallback((event: FormEvent<HTMLElement>) => {
        event.preventDefault()
        setValidated(false)
        setDeploymentModel('')
        setSolutionType([])
        window.location.reload()
    }, [])

    useEffect(() => {
        if (newOptimizationLoading !== LoadingState.Pending) {
            if (
                newOptimizationResult &&
                newOptimizationResult.msg &&
                isFormSubmitted
            ) {
                setIsFormSubmitted(false)
                setShowConfirmationPopup(true)
            }
        }
    }, [newOptimizationResult])

    return {
        onSubmit,
        onCancle,
        validated,
        onSolutionDescriptionChange,
        onUseCaseChange,
        onScaleLevelChange,
        onDeploymentChange,
        onPlatformChange,
        onSWDetailsChange,
        onTimelineChange,
        onSolutionTypeChange,
        onRequestorChange,
        onCustomerChange,
        isRequireDeploymentModel,
        isRequireSolutiontype,
        isRequireKeyAudience,
        isRequireKeySegment,
        onBusinessImpactChange,
        onOtherStakeholderChange,
        onIntelValuePropChange,
        onKeyAudianceChange,
        onKeySegmentChange,
        filtersValues,
        onOptimizationNameChange,
        useCase,
        platform,
        solutionType,
        scaleLevel,
        deploymentModel,
        showConfirmationPopup,
        handleConfirmationClose,
    }
}
