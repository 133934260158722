import { API_SERVICE_URL } from '../config/service'

import api from './auth/client'

const serviceConfig = require('../config/service.json')

class SwaggerService {
    getSwagger() {
        var url = API_SERVICE_URL + serviceConfig.getSwagger
        return api.get(url)
    }
}

export default new SwaggerService()
