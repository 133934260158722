import React, { FC } from 'react'
import { Chart } from 'react-google-charts'
import { Box } from '@mui/material'
import ChartWrapper from './ChartWrapper'
import { LoadingState } from '../../../../models/loadingState'
import chartColors from './chartColors'
import ChartLoader from './ChartLoader'

const steppedAreaChartBoxSx = {
    minHeight: '400px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
}

const chartOptions = {
    fontName: 'IntelOne Text',
    fontSize: 12,
    chartArea: {
        top: 32,
        bottom: 32,
        left: 64,
        right: 0,
    },
    legend: {
        position: 'none',
    },
    connectSteps: false,
    areaOpacity: 1,
    vAxis: {
        titleTextStyle: {
            fontSize: 12,
            bold: false,
            italic: false,
        },
        format: '0',
        minValue: 0,
    },
    hAxis: {
        titleTextStyle: {
            fontSize: 12,
            bold: false,
            italic: false,
        },
    },
    colors: chartColors,
}

interface SteppedAreaChartProps {
    chartData: SteppedAreaChartData
    dataLoadingState: LoadingState | LoadingState[]
    xAxisTitle?: string
    yAxisTitle?: string
}

export type SteppedAreaChartData = [
    [string, string, { role: string }, { role: string }],
    ...[string, number, string, string][]
]

const SteppedAreaChart: FC<SteppedAreaChartProps> = ({
    chartData,
    dataLoadingState,
    xAxisTitle,
    yAxisTitle,
}) => {
    const isDataLoading = Array.isArray(dataLoadingState)
        ? dataLoadingState.some((state) => state === LoadingState.Pending)
        : dataLoadingState === LoadingState.Pending

    return (
        <Box sx={steppedAreaChartBoxSx}>
            <ChartWrapper
                isDataLoading={isDataLoading}
                noDataCondition={chartData.length === 1}
            >
                <Chart
                    chartType="SteppedAreaChart"
                    data={chartData}
                    loader={<ChartLoader message="Loading chart..." />}
                    width="100%"
                    height={400}
                    chartLanguage="en-US"
                    options={{
                        ...chartOptions,
                        vAxis: {
                            ...chartOptions.vAxis,
                            title: yAxisTitle,
                        },
                        hAxis: {
                            ...chartOptions.hAxis,
                            title: xAxisTitle,
                        },
                    }}
                />
            </ChartWrapper>
        </Box>
    )
}

export default SteppedAreaChart
