import {
    COGNITO_USER_POOL_ID,
    COGNITO_CLIENT_ID,
    APP_DOMAIN,
    COGNITO_REGION,
    COGNITO_LOGIN_URL,
    APP_BASE_URL,
} from '../../config/service'
import { Hub } from 'aws-amplify'
import {
    _generateRandom,
    _generateState,
    generateChallenge,
} from '../../lib/generate'

const port = window.location.port
let formattedport =
    port === undefined || port === null || port.trim() === '' ? '' : ':' + port
let location_callback = `${window.location.protocol}//${
    window.location.hostname
}${formattedport}${APP_BASE_URL ? APP_BASE_URL : ''}/login/callback`
let location_logout = `${window.location.protocol}//${
    window.location.hostname
}${formattedport}${APP_BASE_URL ? APP_BASE_URL : ''}`

export const getOauthSignInURL = () => {
    const generatedState = _generateState(32)
    const pkce_key = _generateRandom(128)
    window.sessionStorage.setItem('ouath_pkce_key', pkce_key) //require
    const code_challenge = generateChallenge(pkce_key)
    const code_challenge_method = 'S256'

    const queryString = `identity_provider=Intel&redirect_uri=${encodeURIComponent(
        location_callback
    )}&response_type=CODE&client_id=${COGNITO_CLIENT_ID}&scope=email%20profile%20openid&state=${generatedState}&code_challenge=${encodeURIComponent(
        code_challenge
    )}&code_challenge_method=${code_challenge_method}`

    const oauthSignInURL =
        'https://' + COGNITO_LOGIN_URL + '/oauth2/authorize?' + queryString
    //alert(oauthSignInURL)

    return oauthSignInURL
}
const amplify_conf = {
    Auth: {
        region: COGNITO_REGION,
        userPoolId: COGNITO_USER_POOL_ID,
        userPoolWebClientId: COGNITO_CLIENT_ID,
        mandatorySignIn: false,
        cookieStorage: {
            domain: APP_DOMAIN,
            path: '/',
            secure: true,
        },
        oauth: {
            domain: COGNITO_LOGIN_URL,
            scope: ['email', 'profile', 'openid'],
            redirectSignIn: location_callback,
            redirectSignOut: location_logout,
            //responseType: 'token',
            responseType: 'code', //require for refresh token
        },
    },
}

const listener = (data: { payload?: any }) => {
    switch (data.payload.event) {
        case 'signIn':
        case 'cognitoHostedUI':
            console.log('Authenticated...')
            break
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
            console.log('Error', data)
            break
    }
}

Hub.listen('auth', listener)

export default amplify_conf
