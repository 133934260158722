import DashboardCard from '../shared/cards/DashboardCard'
import { useAppSelector } from '../../../config/hooks'
import { useEffect, useState } from 'react'
import DonutChart, { DonutChartData } from '../shared/charts/DonutChart'

const appSearchesChartHeaders: [string, string] = ['Search Term', 'Count']

const AppSearchesCard = () => {
    const dashboardData = useAppSelector<any>(
        (state: any) => state.getDashboardDataResult.data
    )
    const dashboardDataLoading = useAppSelector(
        (state: any) => state.getDashboardDataResult.loading
    )

    const [chartData, setChartData] = useState<DonutChartData>([
        appSearchesChartHeaders,
    ])

    useEffect(() => {
        if (dashboardData !== null && dashboardData?.filteredMetrics) {
            const { searchperappsearch } = dashboardData.filteredMetrics
            const appSearchesData = searchperappsearch
                .filter((item: any) => item.Appsearch !== '')
                .sort((itemA: any, itemB: any) => itemB.Count - itemA.Count)
                .map((item: any) => [item.Appsearch, item.Count])
            setChartData([appSearchesChartHeaders, ...appSearchesData])
        }
    }, [dashboardData])

    return (
        <DashboardCard
            title="Top App Searches"
            dataTestId="Top App Searches-label"
        >
            <DonutChart
                chartData={chartData}
                dataLoadingState={dashboardDataLoading}
            />
        </DashboardCard>
    )
}

export default AppSearchesCard
