import { FunctionComponent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

const LoginPanelWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: right;
    margin-top: -2.8rem;
    margin-left: 50%;
    margin-right: 1.5rem;
    @media screen and (max-width: 600px) {
        margin-top: 0;
        margin-left: 0;
    }
`
const LogInDiv = styled.div`
    display: flex;
    flex-direction: row;
    padding-right: 0.5rem;
    border-right: 1px solid #d7d7d7;
    width: 3.5rem;
`
const SignUpDiv = styled.div`
    display: flex;
    flex-direction: row;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    //width: 10rem;
`
const LoginLink = styled.a`
    color: #fff;
    font-weight: 500;
    text-decoration: none;
    &:hover {
        color: #fff;
    }
`

export interface ISignInHeader {}

export const SignInHeader: FunctionComponent<
    ISignInHeader
> = ({}: ISignInHeader) => {
    let navigate = useNavigate()

    const HeaderSignInComponent = () => (
        <LoginPanelWrapper>
            <LogInDiv>
                <LoginLink href="/signIn">Log in</LoginLink>{' '}
            </LogInDiv>{' '}
            <SignUpDiv>
                {' '}
                <LoginLink href="/createAccount">Sign up here</LoginLink>{' '}
            </SignUpDiv>
            <SignUpDiv
                style={{
                    borderLeft: '1px solid #d7d7d7',
                    marginRight: '1rem',
                }}
            >
                {' '}
                <LoginLink href="mailto:optimizations@intel.com">
                    Contact
                </LoginLink>{' '}
            </SignUpDiv>
        </LoginPanelWrapper>
    )

    return (
        <div>
            <HeaderSignInComponent />
        </div>
    )
}

export default SignInHeader
