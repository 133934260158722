import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { MyRegistryRequest } from '../models/myRegistryRequest'
import { LoadingState } from '../models/loadingState'
import MyRegistryService from '../services/myRegistryService'

const myRegistryInitialState = {
    data: null,
    loading: LoadingState.Idle,
    error: null,
}

// First, create the thunk
export const getMyRegistry = createAsyncThunk(
    'registry/myRegistry',
    async (myRegistryRequest: MyRegistryRequest) => {
        return await MyRegistryService.getRecipes(myRegistryRequest)
    }
)

// Then, handle actions in your reducers:
const getMyRegistrySlice = createSlice({
    name: 'getMyRegistry',
    initialState: myRegistryInitialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(getMyRegistry.pending, (state, _) => {
            if (state.loading === LoadingState.Idle) {
                state.loading = LoadingState.Pending
            }
        })
        .addCase(getMyRegistry.fulfilled, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.data = action.payload.data
            }
        })
        .addCase(getMyRegistry.rejected, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.error = action.error
            }
        })
    },
})

const initialState = {
    data: null,
    loading: LoadingState.Idle,
    error: null,
}

export const deleteRegistry = createAsyncThunk(
    'registry/deleteRegistry',
    async (id: string) => await MyRegistryService.deleteRegistry(id)
)

const deleteRegistrySlice = createSlice({
    name: 'deleteRegistry',
    initialState: initialState,
    reducers: {
        clearData: (state) => {
            state.error = null
            state.data = null
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(deleteRegistry.pending, (state, _) => {
            if (state.loading === LoadingState.Idle) {
                state.loading = LoadingState.Pending
            }
        })
        .addCase(deleteRegistry.fulfilled, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.data = action.payload.data
            }
        })
        .addCase(deleteRegistry.rejected, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.error = action.error
            }
        })
    },
})

export const { clearData } = deleteRegistrySlice.actions

export const myRegistryResult = getMyRegistrySlice.reducer
export const deleteRegistryResult = deleteRegistrySlice.reducer
