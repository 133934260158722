import { FormEvent, FunctionComponent, useRef } from 'react'
import styled from 'styled-components'
import { FormLabel, Alert, Button } from 'react-bootstrap'
import { FeedbackResultById } from '../../models/feedbackResultById'
import moment from 'moment'
import { ViewDetailsLogic } from './viewDetailsPanel.hook'
import { LoadingState } from '../../models/loadingState'
import { API_SERVICE_URL } from '../../config/service'
const serviceConfig = require('../../config/service.json')
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { OptimizationtRequestById } from '../../models/optimizationtRequestById'
import { BenchmarkRequestById } from '../../models/benchmarkRequestById'

const ViewDetailsFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
`
const ViewDetailsFormComponent = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: -1rem;
`
const HorizontalContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-grow: 1;
`
const PageTitleTextLabel = styled(FormLabel)`
    ${(props) => props.theme.typography.xxxLarge}
    text-align: left;
    font-weight: 500;
    font-size: 2.25rem;
    margin-bottom: 2rem;
    margin-top: 1rem;
`
const FeedbackUserContainer = styled.div`
    background: #ffffff;
    box-shadow: 0px 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
    border-radius: 1.5rem;
    padding: 2rem;
    margin: 0 2rem 2rem 0;
    max-width: 57rem;
`
const UserDetails = styled.div`
    display: flex;
    flex-direction: row;
    padding-bottom: 2rem;
`
const DataDisplayCont = styled(FormLabel)`
    display: flex;
    ${(props) => props.theme.typography.medium}
    text-align: left;
    margin-top: 0.5rem;
`
const DataTextLabel = styled(FormLabel)`
    display: flex;
    ${(props) => props.theme.typography.medium}
    text-align: left;
    font-weight: 500;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    width: 30%;
`
const MultiDataDisplayCont = styled(FormLabel)`
    display: flex;
    ${(props) => props.theme.typography.medium}
    text-align: left;
    //margin-top: 0.5rem;
    padding-left: 2rem;
    margin-left: 25%;
`
const DataTextLabelValue = styled.span`
    padding-right: 0.5rem;
    overflow-wrap: break-word;
    max-width: 30rem;
    padding-left: 0.5rem;
`

const AlertComponent = styled(Alert)`
    display: flex;
    margin-top: 1rem;
    width: 35vw;
    justify-content: center;
    margin-left: 1rem;
`
const UserTextLabel = styled(FormLabel)`
    display: flex;
    ${(props) => props.theme.typography.medium}
    text-align: left;
    padding-left: 1rem;
    padding-top: 0.5rem;
`
const DownloadButton = styled(Button)`
    display: flex;
    justify-content: center;
    vertical-align: middle;
    font-weight: 400;
    font-size: 1rem;
    background-color: #ff8918;
    width: 15%;
    min-width: 15%;
    height: 2.25rem;
    &:hover {
        background-color: ${(props) =>
            props.theme.color.button.background.hover.primary};
        transition: 0.5s;
    }
    &:disabled {
        background-color: ${(props) =>
            props.theme.color.button.background.disabled.primary};
    }
`
const OptRequestContainer = styled.div`
    background: #ffffff;
    box-shadow: 0px 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
    border-radius: 1.5rem;
    padding: 2rem;
    margin: 0 2rem 2rem 0;
    max-width: 57rem;
`
const BenchmarkRequestContainer = styled(OptRequestContainer)`
    max-width: 50rem;
`

export interface IViewDetailsPanelProps {
    getFeedbackByIdData: any
    feedbackResultById: FeedbackResultById
    feedbackResultError: any
    title: string
    downloadLinkRef?: any
    getFeedbackFile: any
    downloadFileResult: any
    downloadFileLoading: LoadingState
    downloadFileError: any
    getOptimizationRequestById: any
    optRequestByIdResult: OptimizationtRequestById
    optRequestByIdError: any
    benchmarkResultById: BenchmarkRequestById
    benchmarkResultError: any
    isFeedbackId: boolean
    isOptimizationId: boolean
    isBenchmarkId: boolean
}

const ViewDetailsPanel: FunctionComponent<IViewDetailsPanelProps> = ({
    getFeedbackByIdData,
    feedbackResultById,
    feedbackResultError,
    title,
    getFeedbackFile,
    downloadFileResult,
    downloadFileLoading,
    downloadFileError,
    getOptimizationRequestById,
    optRequestByIdResult,
    optRequestByIdError,
    benchmarkResultById,
    benchmarkResultError,
    isFeedbackId,
    isOptimizationId,
    isBenchmarkId,
}: IViewDetailsPanelProps) => {
    var errorText = 'We are experiencing issues retrieving the data. '
    if (feedbackResultError) {
        errorText = errorText + feedbackResultError.message
    }
    if (downloadFileError) {
        errorText = errorText + downloadFileError.message
    }
    if (!isFeedbackId && optRequestByIdError) {
        errorText = errorText + downloadFileError.optRequestByIdError
    }
    if (isBenchmarkId && benchmarkResultError) {
        errorText = errorText + benchmarkResultError.message
    }
    const downloadLinkRef = useRef(null)

    const fileName =
        feedbackResultById?.additionalFields?.additionalProperties?.fileName

    const fileDownloadUrl =
        API_SERVICE_URL +
        serviceConfig.getFeedbackURL +
        '/' +
        feedbackResultById?.uuid +
        '/attachment/' +
        fileName

    const { onDownloadClick } = ViewDetailsLogic({
        downloadLinkRef,
        getFeedbackFile,
        downloadFileResult,
        downloadFileLoading,
        downloadFileError,
    } as IViewDetailsPanelProps)
    let optTimeCreated = ''
    let typeRender
    let usecaseRender
    let platformRender
    let benchmarkTimeCreated = ''
    let benchmarkDueDate = ''
    const creationDate = feedbackResultById?.timeCreated
        ? moment(feedbackResultById?.timeCreated).format('MMM Do YYYY, h:mm a')
        : ''

    let dateNeeded = feedbackResultById?.additionalFields?.additionalProperties
        ?.date
        ? moment(
              feedbackResultById.additionalFields?.additionalProperties.date
          ).format('MMMM Do YYYY, h:mm a')
        : ''
    if (dateNeeded.indexOf('1969') > -1) dateNeeded = ''

    if (isOptimizationId) {
        optTimeCreated = optRequestByIdResult?.timeCreated
            ? moment(optRequestByIdResult?.timeCreated).format(
                  'MMM Do YYYY, h:mm a'
              )
            : ''
        typeRender =
            optRequestByIdResult?.additionalFields?.additionalProperties
                ?.type &&
            optRequestByIdResult.additionalFields.additionalProperties.type.map(
                (item) => {
                    return (
                        <>
                            <li key={item}>{item?.value}</li>
                        </>
                    )
                }
            )
        usecaseRender =
            optRequestByIdResult?.additionalFields?.additionalProperties
                ?.useCase &&
            optRequestByIdResult.additionalFields?.additionalProperties.useCase.map(
                (item) => {
                    return (
                        <>
                            <li key={item}>{item?.value}</li>
                        </>
                    )
                }
            )
        platformRender =
            optRequestByIdResult?.additionalFields?.additionalProperties
                ?.platform &&
            optRequestByIdResult.additionalFields?.additionalProperties.platform.map(
                (item) => {
                    return (
                        <>
                            <li key={item}>{item?.value}</li>
                        </>
                    )
                }
            )
    }
    if (isBenchmarkId) {
        benchmarkTimeCreated = benchmarkResultById?.timeCreated
            ? moment(benchmarkResultById?.timeCreated).format(
                  'MMM Do YYYY, h:mm a'
              )
            : ''
        benchmarkDueDate = benchmarkResultById?.dueDate
            ? moment(benchmarkResultById.dueDate).format('MMMM Do YYYY, h:mm a')
            : ''
        if (benchmarkDueDate.indexOf('1969') > -1) benchmarkDueDate = ''
    }
    const UserProfileImage = (
        <img src="/profile.png" height="40" width="40" alt="profile logo" />
    )
    const isController =
        window.location.pathname.indexOf('all') > -1 ? true : false

    return (
        <ViewDetailsFormContainer>
            <HorizontalContainer>
                <ViewDetailsFormComponent>
                    <Breadcrumb>
                        <Breadcrumb.Item
                            href="/myRequest"
                            style={{
                                textDecoration: 'none',
                                color: '#0068b5',
                            }}
                        >
                            Requests
                        </Breadcrumb.Item>
                        {isController ? (
                            <Breadcrumb.Item
                                href="/allSupportRequest"
                                style={{
                                    textDecoration: 'none',
                                    color: '#0068b5',
                                }}
                            >
                                All Support Requests
                            </Breadcrumb.Item>
                        ) : (
                            <Breadcrumb.Item
                                href="/myRequest"
                                style={{
                                    textDecoration: 'none',
                                    color: '#0068b5',
                                }}
                            >
                                My Requests
                            </Breadcrumb.Item>
                        )}

                        <Breadcrumb.Item
                            active
                            style={{
                                fontWeight: '500',
                                color: '#262626',
                            }}
                        >
                            View Details
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <PageTitleTextLabel>{title}</PageTitleTextLabel>
                    <AlertComponent
                        variant="danger"
                        show={
                            feedbackResultError || downloadFileError
                                ? true
                                : false
                        }
                    >
                        {errorText}
                    </AlertComponent>
                    {isFeedbackId ? (
                        <FeedbackUserContainer>
                            <UserDetails>
                                {UserProfileImage}
                                <UserTextLabel>
                                    {feedbackResultById?.submitterEmail
                                        ? feedbackResultById.submitterEmail
                                        : feedbackResultById?.submitterId}
                                </UserTextLabel>
                            </UserDetails>
                            <DataDisplayCont>
                                <DataTextLabel>
                                    Optimization Name:
                                </DataTextLabel>
                                <DataTextLabelValue>
                                    {feedbackResultById?.additionalFields
                                        ?.additionalProperties?.appName
                                        ? feedbackResultById.additionalFields
                                              .additionalProperties.appName
                                        : ''}
                                </DataTextLabelValue>
                            </DataDisplayCont>
                            <DataDisplayCont>
                                <DataTextLabel>Status:</DataTextLabel>
                                <DataTextLabelValue>
                                    {feedbackResultById?.status
                                        ? feedbackResultById.status
                                        : ''}
                                </DataTextLabelValue>
                            </DataDisplayCont>

                            <DataDisplayCont>
                                <DataTextLabel>Priority:</DataTextLabel>
                                <DataTextLabelValue>
                                    {feedbackResultById?.additionalFields
                                        ?.additionalProperties?.priority
                                        ? feedbackResultById.additionalFields
                                              ?.additionalProperties.priority
                                        : ''}
                                </DataTextLabelValue>
                            </DataDisplayCont>
                            <DataDisplayCont>
                                <DataTextLabel>Submitted date:</DataTextLabel>
                                <DataTextLabelValue>
                                    {creationDate}
                                </DataTextLabelValue>
                            </DataDisplayCont>
                            <DataDisplayCont>
                                <DataTextLabel>Feedback:</DataTextLabel>
                                <DataTextLabelValue>
                                    {feedbackResultById?.additionalFields
                                        ?.additionalProperties?.feedback
                                        ? feedbackResultById.additionalFields
                                              ?.additionalProperties.feedback
                                        : ''}
                                </DataTextLabelValue>
                            </DataDisplayCont>
                            <DataDisplayCont>
                                <DataTextLabel>Attachment:</DataTextLabel>
                                <DataTextLabelValue>
                                    {feedbackResultById?.additionalFields
                                        ?.additionalProperties?.fileName
                                        ? feedbackResultById.additionalFields
                                              ?.additionalProperties.fileName
                                        : ''}
                                </DataTextLabelValue>
                                <DownloadButton
                                    onClick={(
                                        url: string,
                                        name: string,
                                        ev: FormEvent<HTMLElement>
                                    ) => {
                                        onDownloadClick(
                                            fileDownloadUrl,
                                            fileName,
                                            ev
                                        )
                                    }}
                                    disabled={
                                        feedbackResultById?.additionalFields
                                            ?.additionalProperties?.fileName
                                            ? false
                                            : true
                                    }
                                >
                                    Download
                                </DownloadButton>
                                <a
                                    className="hidden"
                                    download={
                                        feedbackResultById?.additionalFields
                                            ?.fileName
                                            ? feedbackResultById
                                                  ?.additionalFields.fileName
                                                  ?.additionalProperties
                                            : 'Attachment'
                                    }
                                    href={fileDownloadUrl}
                                    id={
                                        'downloadLinkId-' +
                                        feedbackResultById?.additionalFields
                                            ?.additionalProperties?.uuid
                                    }
                                    ref={downloadLinkRef}
                                ></a>
                            </DataDisplayCont>
                        </FeedbackUserContainer>
                    ) : isOptimizationId ? (
                        <OptRequestContainer>
                            <UserDetails>
                                {UserProfileImage}
                                <UserTextLabel>
                                    {optRequestByIdResult?.submitterEmail
                                        ? optRequestByIdResult.submitterEmail
                                        : feedbackResultById?.submitterId}
                                </UserTextLabel>
                            </UserDetails>
                            <DataDisplayCont>
                                <DataTextLabel>
                                    Optimization Name:
                                </DataTextLabel>
                                <DataTextLabelValue>
                                    {
                                        optRequestByIdResult?.additionalFields
                                            ?.additionalProperties
                                            ?.optimizationName
                                    }
                                </DataTextLabelValue>
                            </DataDisplayCont>
                            <DataDisplayCont>
                                <DataTextLabel>
                                    Solution Description:
                                </DataTextLabel>
                                <DataTextLabelValue>
                                    {
                                        optRequestByIdResult?.additionalFields
                                            ?.additionalProperties?.description
                                    }
                                </DataTextLabelValue>
                            </DataDisplayCont>
                            <DataDisplayCont>
                                <DataTextLabel>Use Case:</DataTextLabel>
                                <DataTextLabelValue>
                                    <ul>
                                        {usecaseRender ? usecaseRender : ''}
                                    </ul>
                                </DataTextLabelValue>
                            </DataDisplayCont>
                            <DataDisplayCont>
                                <DataTextLabel>Scale Level:</DataTextLabel>
                                <DataTextLabelValue>
                                    {
                                        optRequestByIdResult?.additionalFields
                                            ?.additionalProperties?.scaleLevel
                                    }
                                </DataTextLabelValue>
                            </DataDisplayCont>
                            <DataDisplayCont>
                                <DataTextLabel>Deployment:</DataTextLabel>
                                <DataTextLabelValue>
                                    {
                                        optRequestByIdResult?.additionalFields
                                            ?.additionalProperties?.deployment
                                    }
                                </DataTextLabelValue>
                            </DataDisplayCont>
                            <DataDisplayCont>
                                <DataTextLabel>Platform:</DataTextLabel>
                                <DataTextLabelValue>
                                    {platformRender ? platformRender : ''}
                                </DataTextLabelValue>
                            </DataDisplayCont>
                            <DataDisplayCont>
                                <DataTextLabel>SW Details:</DataTextLabel>
                                <DataTextLabelValue>
                                    {
                                        optRequestByIdResult?.additionalFields
                                            ?.additionalProperties?.swDetails
                                    }
                                </DataTextLabelValue>
                            </DataDisplayCont>
                            <DataDisplayCont>
                                <DataTextLabel>Timeline:</DataTextLabel>
                                <DataTextLabelValue>
                                    {
                                        optRequestByIdResult?.additionalFields
                                            ?.additionalProperties?.timeline
                                    }
                                </DataTextLabelValue>
                            </DataDisplayCont>
                            <DataDisplayCont>
                                <DataTextLabel>
                                    Value Proposition:
                                </DataTextLabel>
                                <DataTextLabelValue>
                                    {
                                        optRequestByIdResult?.additionalFields
                                            ?.additionalProperties
                                            ?.intelValueProp
                                    }
                                </DataTextLabelValue>
                            </DataDisplayCont>
                            <DataDisplayCont>
                                <DataTextLabel>Type:</DataTextLabel>
                            </DataDisplayCont>
                            <MultiDataDisplayCont>
                                <ul>{typeRender ? typeRender : ''}</ul>
                            </MultiDataDisplayCont>
                            <DataDisplayCont>
                                <DataTextLabel>Key Audience:</DataTextLabel>
                            </DataDisplayCont>
                            <MultiDataDisplayCont>
                                <ul>
                                    {
                                        optRequestByIdResult?.additionalFields
                                            ?.additionalProperties?.audience
                                    }
                                </ul>
                            </MultiDataDisplayCont>
                            <DataDisplayCont>
                                <DataTextLabel>Key Segment:</DataTextLabel>
                            </DataDisplayCont>
                            <MultiDataDisplayCont>
                                <ul>
                                    {
                                        optRequestByIdResult?.additionalFields
                                            ?.additionalProperties?.segment
                                    }
                                </ul>
                            </MultiDataDisplayCont>
                            <DataDisplayCont>
                                <DataTextLabel>Business Impact:</DataTextLabel>
                                <DataTextLabelValue>
                                    {
                                        optRequestByIdResult?.additionalFields
                                            ?.additionalProperties?.business
                                    }
                                </DataTextLabelValue>
                            </DataDisplayCont>
                            <DataDisplayCont>
                                <DataTextLabel>Requestor:</DataTextLabel>
                                <DataTextLabelValue>
                                    {
                                        optRequestByIdResult?.additionalFields
                                            ?.additionalProperties?.requestor
                                    }
                                </DataTextLabelValue>
                            </DataDisplayCont>
                            <DataDisplayCont>
                                <DataTextLabel>Customer :</DataTextLabel>
                                <DataTextLabelValue>
                                    {
                                        optRequestByIdResult?.additionalFields
                                            ?.additionalProperties?.customer
                                    }
                                </DataTextLabelValue>
                            </DataDisplayCont>
                            <DataDisplayCont>
                                <DataTextLabel>Submitted date:</DataTextLabel>
                                <DataTextLabelValue>
                                    {optTimeCreated}
                                </DataTextLabelValue>
                            </DataDisplayCont>
                        </OptRequestContainer>
                    ) : (
                        <BenchmarkRequestContainer>
                            <UserDetails>
                                {UserProfileImage}
                                <UserTextLabel>
                                    {benchmarkResultById?.submitterEmail
                                        ? benchmarkResultById.submitterEmail
                                        : benchmarkResultById?.submitterId}
                                </UserTextLabel>
                            </UserDetails>
                            <DataDisplayCont>
                                <DataTextLabel>Workload Name:</DataTextLabel>
                                <DataTextLabelValue>
                                    {benchmarkResultById?.name
                                        ? benchmarkResultById.name
                                        : benchmarkResultById?.existingBenchmark
                                        ? benchmarkResultById.existingBenchmark
                                        : ''}
                                </DataTextLabelValue>
                            </DataDisplayCont>
                            <DataDisplayCont>
                                <DataTextLabel>Workload version:</DataTextLabel>
                                <DataTextLabelValue>
                                    {benchmarkResultById?.version}
                                </DataTextLabelValue>
                            </DataDisplayCont>
                            <DataDisplayCont>
                                <DataTextLabel>Targated HW:</DataTextLabel>
                                <DataTextLabelValue>
                                    {benchmarkResultById?.targetHardware}
                                </DataTextLabelValue>
                            </DataDisplayCont>
                            <DataDisplayCont>
                                <DataTextLabel>Customers:</DataTextLabel>
                                <DataTextLabelValue>
                                    {benchmarkResultById?.customer}
                                </DataTextLabelValue>
                            </DataDisplayCont>
                            <DataDisplayCont>
                                <DataTextLabel>Business impact:</DataTextLabel>
                                <DataTextLabelValue>
                                    {benchmarkResultById?.businessImpact}
                                </DataTextLabelValue>
                            </DataDisplayCont>
                            <DataDisplayCont>
                                <DataTextLabel>Due date:</DataTextLabel>
                                <DataTextLabelValue>
                                    {benchmarkDueDate}
                                </DataTextLabelValue>
                            </DataDisplayCont>
                            <DataDisplayCont>
                                <DataTextLabel>Business Unit:</DataTextLabel>
                                <DataTextLabelValue>
                                    {benchmarkResultById?.businessUnit}
                                </DataTextLabelValue>
                            </DataDisplayCont>
                            <DataDisplayCont>
                                <DataTextLabel>Requester's Name:</DataTextLabel>
                                <DataTextLabelValue>
                                    {benchmarkResultById?.requester}
                                </DataTextLabelValue>
                            </DataDisplayCont>
                            <DataDisplayCont>
                                <DataTextLabel>Test case:</DataTextLabel>
                                <DataTextLabelValue>
                                    {benchmarkResultById?.testCase}
                                </DataTextLabelValue>
                            </DataDisplayCont>
                            <DataDisplayCont>
                                <DataTextLabel>
                                    List of all Software components to be used
                                    with versions:
                                </DataTextLabel>
                                <DataTextLabelValue>
                                    {benchmarkResultById?.softwareComponents}
                                </DataTextLabelValue>
                            </DataDisplayCont>
                            <DataDisplayCont>
                                <DataTextLabel>Configuration:</DataTextLabel>
                                <DataTextLabelValue>
                                    {benchmarkResultById?.configuration}
                                </DataTextLabelValue>
                            </DataDisplayCont>
                            <DataDisplayCont>
                                <DataTextLabel>
                                    KPI/Metric to measure:
                                </DataTextLabel>
                                <DataTextLabelValue>
                                    {benchmarkResultById?.kpi}
                                </DataTextLabelValue>
                            </DataDisplayCont>
                            <DataDisplayCont>
                                <DataTextLabel>
                                    Where source of WL can be found:
                                </DataTextLabel>
                                <DataTextLabelValue>
                                    {benchmarkResultById?.source}
                                </DataTextLabelValue>
                            </DataDisplayCont>
                            <DataDisplayCont>
                                <DataTextLabel>
                                    Contact for tech details:
                                </DataTextLabel>
                                <DataTextLabelValue>
                                    {benchmarkResultById?.contact}
                                </DataTextLabelValue>
                            </DataDisplayCont>
                            <DataDisplayCont>
                                <DataTextLabel>Comments:</DataTextLabel>
                                <DataTextLabelValue>
                                    {benchmarkResultById?.comments}
                                </DataTextLabelValue>
                            </DataDisplayCont>
                            <DataDisplayCont>
                                <DataTextLabel>Submitted date:</DataTextLabel>
                                <DataTextLabelValue>
                                    {benchmarkTimeCreated}
                                </DataTextLabelValue>
                            </DataDisplayCont>
                        </BenchmarkRequestContainer>
                    )}
                </ViewDetailsFormComponent>
            </HorizontalContainer>
        </ViewDetailsFormContainer>
    )
}

export default ViewDetailsPanel
