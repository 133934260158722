import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ChatbotRequest } from '../models/chatbotRequest'
import { LoadingState } from '../models/loadingState'
import ChatbotService from '../services/chatbotService'

const initialState = {
    data: null,
    loading: LoadingState.Idle,
    error: null,
}

export const getAnswerFromChatbot = createAsyncThunk(
    'getAnswerFromChatbot',
    async (chatbotRequest: ChatbotRequest) => {
        return await ChatbotService.SubmitQuestiontoChatbot(chatbotRequest)
    }
)

// Then, handle actions in your reducers:
const getAnswerFromChatbotSlice = createSlice({
    name: 'getAnswerFromChatbot',
    initialState: initialState,
    reducers: {},

    extraReducers: (builder) => {
        builder
            .addCase(getAnswerFromChatbot.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(getAnswerFromChatbot.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload.data
                }
            })
            .addCase(getAnswerFromChatbot.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})

export const chatbotAnswer = getAnswerFromChatbotSlice.reducer
