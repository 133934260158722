import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useAppDispatch, useAppSelector } from '../../config/hooks'
import FooterPanel from '../../layout/footer'
import UserPanel from '../../layout/userProfile'
import { getAuthPermissions } from '../../store/authPermissions.slice'
import { MainPagePanel } from '../../components/cloudInsightsPanel/mainPagePanel'
import { DiagnosticsReportPanel } from '../../components/cloudInsightsPanel/diagnosticsReportPanel'
import { acceptInvites } from '../../store/invitation.slice'
import { AcceptInvitesRequest } from '../../models/acceptInvitesRequest'
import HeaderPanel from '../../layout/header'
import { UserRoles } from '../../models/userRoles'
import { FormLabel } from 'react-bootstrap'

const MainPanelContainer = styled.div`
    display: flex;
    height: ${(props) => props.theme.size.mainContainerHeight};
    width: ${(props) => props.theme.size.mainContainerWidth};
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: space-between;
    @media screen and (min-width: 1200px) {
        overflow-x: hidden;
    }
`

const MainHorizontalContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: space-between;
`
const MainBodyContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: center;
    background-color: ${(props) => props.theme.color.background.defaultGrey};
    padding-bottom: 10rem;
`

const MainBodyContainerReposition = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
`

const MainPanelWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: flex-start;
`
const NotAutorizeContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: center;
    margin: 2rem;
`
const NotAutorizeLabel = styled(FormLabel)`
    text-align: left;
    font-weight: 400;
    font-size: 1.1rem;
    color: red;
`

export const CloudInsightsContainer: React.FC = () => {
    const dispatch = useAppDispatch()
    let invitationKey = localStorage.getItem('invitationKey')
    const [iscloudInsightUser, setIscloudInsightUser] = useState(false)

    useEffect(() => {
        dispatch(getAuthPermissions())
    }, [])

    const userAuthPermissions = useAppSelector<any>(
        (state: any) => state.authPermissionsResult.data
    )
    const acceptInvitesResult = useAppSelector<any>(
        (state: any) => state.acceptInvitesResult.data
    )
    useEffect(() => {
        if (userAuthPermissions) {
            setIscloudInsightUser(
                userAuthPermissions?.organizationalRoles.includes(
                    UserRoles.OrgAdmin
                ) ||
                    userAuthPermissions?.organizationalRoles.includes(
                        UserRoles.CiOrgWriter
                    ) ||
                    userAuthPermissions?.organizationalRoles.includes(
                        UserRoles.CiOrgReader
                    )
            )

            if (userAuthPermissions?.isIntelEmployee && invitationKey) {
                //accept invitation when user has invitation & signing through Intel SSO
                dispatch(
                    acceptInvites({
                        decision: 'accept',
                        invitationKey: invitationKey,
                        displayName: '',
                    } as AcceptInvitesRequest)
                )
                localStorage.setItem('invitationKey', '')
            }
        }
    }, [userAuthPermissions])
    useEffect(() => {
        if (acceptInvitesResult) {
            // refresh auth permission after accepting invites
            dispatch(getAuthPermissions())
        }
    }, [acceptInvitesResult])

    return (
        <MainPanelContainer>
            <HeaderPanel />
            <MainHorizontalContainer>
                <MainBodyContainer>
                    <MainBodyContainerReposition>
                        <UserPanel></UserPanel>
                        {iscloudInsightUser ? (
                            <MainPanelWrapper>
                                <MainPagePanel
                                    userAuthPermissions={userAuthPermissions}
                                />
                                <DiagnosticsReportPanel />
                            </MainPanelWrapper>
                        ) : (
                            <NotAutorizeContainer>
                                <NotAutorizeLabel>
                                    Permission denied! User doesn't have
                                    permission to use the cloud insights
                                    application. <br />
                                    For support, contact{' '}
                                    <a href="mailto:optimizations@intel.com">
                                        optimizations@intel.com
                                    </a>
                                </NotAutorizeLabel>
                            </NotAutorizeContainer>
                        )}
                    </MainBodyContainerReposition>
                </MainBodyContainer>
            </MainHorizontalContainer>
            <FooterPanel></FooterPanel>
        </MainPanelContainer>
    )
}
