import { FC } from 'react'
import { CircularProgress, Typography } from '@mui/material'

const chartLoaderMessageSx = { marginTop: '0.5rem' }

interface ChartLoaderProps {
    message: string
}

const ChartLoader: FC<ChartLoaderProps> = ({ message }) => (
    <>
        <CircularProgress />
        <Typography sx={chartLoaderMessageSx}>{message}</Typography>
    </>
)

export default ChartLoader
