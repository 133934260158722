import DashboardCard from '../shared/cards/DashboardCard'
import { useAppSelector } from '../../../config/hooks'
import { useEffect, useState } from 'react'
import BarChart from '../shared/charts/BarChart'
import { Box, Typography } from '@mui/material'
import { LoadingState } from '../../../models/loadingState'

const linkClicksHeaderBoxSx = { marginLeft: '1rem', marginBlock: '0.5rem' }

type BarChartData = [
    [string, string, { role: string }],
    ...(string | number)[][]
]

const chartDataHeaders: [string, string, { role: string }] = [
    'Optimization Name',
    '# Link Clicks',
    { role: 'annotation' },
]

const LinkClicksCountsCard = () => {
    const cardsActivitySummaryData = useAppSelector<any>(
        (state) => state.getCardsActivitySummaryResult.data
    )
    const cardsActivitySummaryLoading = useAppSelector(
        (state) => state.getCardsActivitySummaryResult.loading
    )

    const [chartData, setChartData] = useState<BarChartData>([chartDataHeaders])
    const [totalClicks, setTotalClicks] = useState(0)

    useEffect(() => {
        if (
            cardsActivitySummaryData !== null &&
            cardsActivitySummaryData?.clickedLinks &&
            cardsActivitySummaryData?.totalClicks
        ) {
            const linksClicksData = Object.entries(
                cardsActivitySummaryData?.clickedLinks as {
                    [key: string]: number
                }
            )
                .filter(([_, value]) => value > 0)
                .sort(([_A, valueA], [_B, valueB]) => valueB - valueA)
                .map(([name, value]) => [name, value, value])

            setChartData([chartDataHeaders, ...linksClicksData])
            setTotalClicks(cardsActivitySummaryData.totalClicks)
        }
    }, [cardsActivitySummaryData])

    const showTotalClicks =
        totalClicks > 0 && cardsActivitySummaryLoading === LoadingState.Idle

    return (
        <DashboardCard>
            <Box sx={linkClicksHeaderBoxSx}>
                <Typography variant="h6">Most Clicked Optimizations</Typography>
                {showTotalClicks && (
                    <Typography variant="body2">
                        Total Clicks: {totalClicks}
                    </Typography>
                )}
            </Box>
            <BarChart
                chartData={chartData}
                dataLoadingState={cardsActivitySummaryLoading}
                vAxisTitle="Optimization"
                hAxisTitle="# Link Clicks"
            />
        </DashboardCard>
    )
}

export default LinkClicksCountsCard
