import React, {
    FunctionComponent,
    useEffect,
    useState,
} from 'react'
import Select from 'react-select'
import {LoadingState} from '../../models/loadingState'
import styled from "styled-components";

const NoOptionMessage = styled.div`
  display: flex;
  ${(props) => props.theme.typography.small}
`

export interface ISelectDropDownPanelProps {
    options: any
    loading: LoadingState
    onOptionChange: (value: any) => void | undefined
    placeholder: String
    noOptionsMessage: String
}

export const SelectDropDownPanel: FunctionComponent<ISelectDropDownPanelProps
    > = ({ options, onOptionChange, loading, placeholder, noOptionsMessage }: ISelectDropDownPanelProps) => {
    const [selectedOption, setSelectedOption] = useState([])

    const handleChange = (event: any) => {
        const target = event as any
        setSelectedOption(target)
    }

    useEffect(() => {
        if (onOptionChange) {
            onOptionChange(selectedOption)
            if(selectedOption.length != 0){
                setSelectedOption([])
            }
        }
    }, [selectedOption, onOptionChange])

    return (
        <Select
            value={selectedOption}
            onChange={handleChange}
            isSearchable={true}
            options={options}
            isLoading={loading === LoadingState.Pending}
            placeholder={placeholder}
            noOptionsMessage={() => (
                <NoOptionMessage>
                    {noOptionsMessage}
                </NoOptionMessage>
            )}
        />
    )
}