import { useState, useCallback, ChangeEvent, FormEvent, useEffect } from 'react'
import { GetMembershipRequest } from '../../models/getMembershipRequest'
import { Member } from '../../models/getMembershipResponse'
import { UpdateMembershipRequest } from '../../models/updateMembershipRequest'
import { IMembershipPanelProps } from './membershipPanel'
import { KeyboardEvent } from 'react'
import { isEmailFormatValid } from '../../lib/validate'
import { UserRoles } from '../../models/userRoles'
import { LoadingState } from '../../models/loadingState'
import { SendInvitesRequest } from '../../models/sendInvitesRequest'
import { UpdateUserDefaultOrgRequest } from '../../models/UpdateUserDefaultOrgRequest'

export function MembershipPanelLogic({
    getOrganization,
    getMembership,
    updateMembership,
    updateMembershipResult,
    updateMembershipLoading,
    organizationInfoResult,
    getMembershipResult,
    getMembershipLoading,
    organizationInfoLoading,
    getInvites,
    getInvitesError,
    getInvitesLoading,
    getInvitesResult,
    sendInvites,
    sendInvitesError,
    sendInvitesLoading,
    sendInvitesResult,
    authPermissionsResult,
    getUserOrganizationsList,
    userOrganizationsListError,
    userOrganizationsListLoading,
    userOrganizationsListResult,
    updateUserDefaultOrg,
    updateUserDefaultOrgError,
    updateUserDefaultOrgLoading,
    updateUserDefaultOrgResult,
}: IMembershipPanelProps) {
    const [email, setEmail] = useState<string>('')
    const [badEmailFormatMsg, setBadEmailFormatMsg] = useState<string>('')
    const [memberSelected, setMemberSelected] = useState<Member>()
    const [isUserUpdated, setIsUserUpdated] = useState<boolean>(false)
    const [showAddRolesPopup, setShowAddRolesPopup] = useState<boolean>(false)
    const [showAddRolesInvitePopup, setShowAddRolesInvitePopup] =
        useState<boolean>(false)
    const [inviteSentMessage, setInviteSentMessage] = useState<string>('')

    const memberDDActions = [
        { label: 'Delete user', value: 'Delete' },
        { label: 'Edit user', value: 'Edit' },
    ]

    const [selectedUserOrganization, setSelectedUserOrganization] =
        useState<any>([])
    const [userOrgsListOptions, setUserOrgsListOptions] = useState<any>([])

    const badFormatMsg = 'Please enter an email address in format name@xyg.com.'
    const roles = Object.values(UserRoles)

    useEffect(() => {
        getOrganization()
        getUserOrganizationsList()
    }, [])
    useEffect(() => {
        if (organizationInfoResult) {
            setSelectedUserOrganization({
                value: organizationInfoResult.id,
                label: organizationInfoResult.name,
            })
            const getMembershipRequest = new GetMembershipRequest()
            getMembershipRequest.organizationId = organizationInfoResult.id
            getMembership(getMembershipRequest)
        }
    }, [organizationInfoResult])
    useEffect(() => {
        if (userOrganizationsListResult) {
            const userOrgsList: any = userOrganizationsListResult.organizations
                ? userOrganizationsListResult.organizations?.map(
                      (orgs: any) => ({
                          label: orgs.name,
                          value: orgs.id,
                      })
                  )
                : []
            setUserOrgsListOptions(userOrgsList)
        }
    }, [userOrganizationsListResult])

    const onSelectMember = useCallback(
        (event: FormEvent<HTMLElement>, member: Member) => {
            setMemberSelected(member)
        },
        []
    )

    const onUpdateRoles = useCallback(
        (member: Member | undefined, selectedRoles: string[]) => {
            const toAdd: string[] = []
            const toRemove: string[] = []
            for (const role of roles) {
                if (selectedRoles.includes(role)) {
                    toAdd.push(role)
                } else {
                    toRemove.push(role)
                }
            }

            if (organizationInfoResult) {
                updateMembership({
                    editMember: {
                        userId: member?.userId,
                        organizationId:
                            selectedUserOrganization &&
                            selectedUserOrganization.value
                                ? selectedUserOrganization.value
                                : organizationInfoResult.id,
                        rolesToRemove: toRemove,
                        rolesToAdd: toAdd,
                    },
                } as UpdateMembershipRequest)
            }
        },
        [updateMembership, organizationInfoResult, selectedUserOrganization]
    )

    useEffect(() => {
        if (updateMembershipResult) {
            setIsUserUpdated(true)
            if (organizationInfoResult) {
                const getMembershipRequest = new GetMembershipRequest()
                getMembershipRequest.organizationId = organizationInfoResult.id
                getMembership(getMembershipRequest)
            }
        }
    }, [updateMembershipResult, organizationInfoResult])

    useEffect(() => {
        if (updateMembershipLoading) {
            setIsUserUpdated(false)
        }
    }, [updateMembershipLoading])

    const onRemoveMember = useCallback(
        (member: Member | undefined) => {
            // user doesn't remove themself from org
            if (
                organizationInfoResult &&
                member &&
                authPermissionsResult &&
                member.userId != authPermissionsResult.userId
            ) {
                updateMembership({
                    editMember: {
                        userId: member?.userId,
                        organizationId: organizationInfoResult.id,
                        rolesToRemove: roles,
                    },
                } as UpdateMembershipRequest)
            }
            setMemberSelected(undefined)
        },
        [email, roles, updateMembership, organizationInfoResult]
    )

    const onActionsClick = useCallback(
        (newValue: any) => {
            const action = newValue?.value
            if (action == 'Delete') {
                onRemoveMember(memberSelected)
            } else if (action == 'Edit') {
                setShowAddRolesPopup(true)
            }
        },
        [memberSelected, onRemoveMember]
    )

    const onClosePopup = useCallback(
        (event: any) => {
            event.preventDefault()
            setMemberSelected(undefined)
            if (showAddRolesPopup) {
                setShowAddRolesPopup(false)
            }
        },
        [showAddRolesPopup]
    )

    const isLoading =
        getMembershipLoading === LoadingState.Pending ||
        updateMembershipLoading === LoadingState.Pending ||
        organizationInfoLoading === LoadingState.Pending

    // Invitations

    const onInputInviteEmail = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLTextAreaElement
            if (target.value != undefined) {
                setEmail(target.value)
                setBadEmailFormatMsg('')
            }
            setInviteSentMessage('')
        },
        []
    )

    const onAddInviteEmail = useCallback(
        (event: FormEvent<HTMLElement>) => {
            event.preventDefault()

            const isEmailFormatCorrect = isEmailFormatValid(email)
            if (!isEmailFormatCorrect) {
                setBadEmailFormatMsg(badFormatMsg)
            } else if (organizationInfoResult) {
                setShowAddRolesInvitePopup(true)
            }
        },
        [email, roles, organizationInfoResult]
    )

    const onAddInviteKeyDown = useCallback(
        (event: KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter') {
                event.preventDefault()

                const isEmailFormatCorrect = isEmailFormatValid(email)
                if (!isEmailFormatCorrect) {
                    setBadEmailFormatMsg(badFormatMsg)
                } else if (organizationInfoResult) {
                    setShowAddRolesInvitePopup(true)
                }
            }
        },
        [email, roles, organizationInfoResult]
    )

    const onInviteClosePopup = useCallback((event: any) => {
        event.preventDefault()
        setShowAddRolesInvitePopup(false)
    }, [])

    const onInviteUpdatedRoles = useCallback(
        (inviteEmail: string | undefined, selectedRoles: string[]) => {
            const isNotUserDefaultOrg =
                selectedUserOrganization &&
                selectedUserOrganization.value &&
                selectedUserOrganization.value !== organizationInfoResult.id
                    ? true
                    : false
            if (organizationInfoResult) {
                sendInvites({
                    email: inviteEmail,
                    roles: selectedRoles,
                    source:
                        selectedRoles.includes(UserRoles.CiOrgReader) ||
                        selectedRoles.includes(UserRoles.CiOrgWriter)
                            ? 'cloud-insights'
                            : '',
                    isNotUserDefaultOrg: isNotUserDefaultOrg,
                    organizationId:
                        selectedUserOrganization &&
                        selectedUserOrganization.value
                            ? selectedUserOrganization.value
                            : organizationInfoResult.id,
                } as SendInvitesRequest)
                setEmail('')
                setBadEmailFormatMsg('')
            }
        },
        [organizationInfoResult, selectedUserOrganization]
    )

    useEffect(() => {
        if (sendInvitesResult && organizationInfoResult) {
            getInvites({})
            setInviteSentMessage('Invite sent')
        }
    }, [sendInvitesResult, organizationInfoResult])

    useEffect(() => {
        if (sendInvitesLoading === LoadingState.Pending) {
            setInviteSentMessage('')
        }
    }, [sendInvitesLoading])

    const onApplyUserOrgChange = useCallback(() => {
        if (selectedUserOrganization && selectedUserOrganization.value) {
            updateUserDefaultOrg({
                organizationId: selectedUserOrganization.value,
            } as UpdateUserDefaultOrgRequest)
        }
    }, [selectedUserOrganization])

    useEffect(() => {
        if (updateUserDefaultOrgResult) getOrganization() //refresh default org after update
    }, [updateUserDefaultOrgResult])

    return {
        onInputInviteEmail,
        onAddInviteEmail,
        onSelectMember,
        onActionsClick,
        onUpdateRoles,
        onClosePopup,
        memberSelected,
        email,
        roles,
        showAddRolesPopup,
        showAddRolesInvitePopup,
        onAddInviteKeyDown,
        badEmailFormatMsg,
        isLoading,
        memberDDActions,
        onInviteClosePopup,
        onInviteUpdatedRoles,
        inviteSentMessage,
        userOrgsListOptions,
        selectedUserOrganization,
        setSelectedUserOrganization,
        onApplyUserOrgChange,
    }
}
