import { useState, useCallback, FormEvent, useEffect } from 'react'
import { IViewDetailsPanelProps } from './viewDetailsPanel'
import { LoadingState } from '../../models/loadingState'

export function ViewDetailsLogic({
    downloadLinkRef,
    getFeedbackFile,
    downloadFileResult,
    downloadFileLoading,
    downloadFileError,
}: IViewDetailsPanelProps) {
    const [isDownloadClicked, setDownloadClick] = useState<boolean>(false)
    const [fileDownloadUrl, setfileDownloadUrl] = useState<string>('')
    const [downloadFileName, setDownloadFileName] = useState<string>('')

    const onDownloadClick = useCallback(
        (
            fileDownloadUrl: string,
            fileName: string,
            event: FormEvent<HTMLElement>
        ) => {
            setDownloadFileName(fileName)
            if (fileDownloadUrl && fileName) getFeedbackFile(fileDownloadUrl)
            setDownloadClick(true)
        },
        []
    )
    useEffect(() => {
        if (downloadFileResult && isDownloadClicked) {
            if (downloadFileLoading !== LoadingState.Pending) {
                if (
                    downloadFileLoading !== undefined &&
                    downloadFileError === null
                ) {
                    const blob = new Blob([downloadFileResult], {
                        type: 'application/pdf',
                    })
                    const fileDownloadUrl = URL.createObjectURL(blob)
                    setfileDownloadUrl(fileDownloadUrl)
                    if (downloadLinkRef.current !== undefined) {
                        downloadLinkRef.current.href = fileDownloadUrl
                        downloadLinkRef.current.download = downloadFileName
                        downloadLinkRef.current.click() //download file
                    }
                }
                if (fileDownloadUrl) URL.revokeObjectURL(fileDownloadUrl)
                setDownloadClick(false)
            }
        }
    }, [downloadFileResult, isDownloadClicked, downloadFileLoading])

    return {
        onDownloadClick,
    }
}
