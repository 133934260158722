import { useEffect } from 'react'
import styled from 'styled-components'
import { useAppDispatch, useAppSelector } from '../../config/hooks'
import UserPanel from '../../layout/userProfile'
import { getSwagger } from '../../store/swagger.slice'
import HeaderPanel from '../../layout/header'
import SwaggerUI from 'swagger-ui-react'
import 'swagger-ui-react/swagger-ui.css'

const SwaggerContainerPanel = styled.div`
    display: flex;
    height: ${(props) => props.theme.size.mainContainerHeight};
    width: ${(props) => props.theme.size.mainContainerWidth};
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: space-between;
    @media screen and (min-width: 1200px) {
        overflow-x: hidden;
    }
`

const HorizontalContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: space-between;
`

const BodyContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: center;
    background-color: ${(props) => props.theme.color.background.defaultGrey};
    padding-bottom: 10rem;
`

const BodyContainerReposition = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
`

const MainContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: center;
    background-color: ${(props) => props.theme.color.background.defaultGrey};
    padding-bottom: 10rem;
`

const VerticalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: no-wrap;
    border-radius: 1.56rem;
    margin: 2rem;
    padding-bottom: 1rem;
    height: 100%;
`

const SwaggerContainer = () => {
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(getSwagger())
    }, [])
    const swaggerData = useAppSelector<any>(
        (state) => state.getSwaggerResult.data
    )
    console.log(swaggerData)

    return (
        <SwaggerContainerPanel>
            <HeaderPanel />
            <HorizontalContainer>
                <BodyContainer>
                    <BodyContainerReposition>
                        <UserPanel></UserPanel>
                        <MainContainer>
                            <VerticalWrapper>
                                <SwaggerUI
                                    spec={
                                        swaggerData
                                            ? JSON.stringify(swaggerData)
                                            : ''
                                    }
                                />
                            </VerticalWrapper>
                        </MainContainer>
                    </BodyContainerReposition>
                </BodyContainer>
            </HorizontalContainer>
        </SwaggerContainerPanel>
    )
}

export default SwaggerContainer
