import { FunctionComponent, useEffect, useState } from 'react'
import { Nav } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { AuthPermissions } from '../models/authPermissions'
import { LoadingState } from '../models/loadingState'
import StringUtils from '../lib/stringUtils'
import { UserRoles, UserOtherMetaRoles } from '../models/userRoles'
import { CreateOrgRequest } from '../models/createOrgRequest'
import {
    _getDecryptedValueFromStorage,
    _setEncryptedValueInStorage,
} from '../lib/encryptStorage'
import { Auth } from 'aws-amplify'
import stringUtils from '../lib/stringUtils'
import { APP_BASE_URL, APP_DOMAIN } from '../config/service'
import { isDevAndLocal } from '../lib/host'
import { doesUserHaveRole } from '../lib/user'

const LinkPanelContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: white;
    width: 10rem;
    max-width: 25rem;
    min-width: 10rem;
    flex-grow: 1;
`

const LinkPanelBodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: white;
    flex-grow: 1;
    margin-top: 2rem;
`

const IntelLogoStyling = styled.div`
    display: flex;
    padding: 0.5rem;
`
const GroupChildLinks = styled.div`
    display: flex;
    flex-direction: column;
`

const StyledNavLink = styled(Nav.Link)`
    font-family: 'IntelOne Display', sans-serif;
    font-size: 1rem;
    color: #292f4c;
    font-weight: lighter;
    line-height: 2.5rem;
    padding-left: 0.2rem;
    margin: 0.5rem 0;
    &:active,
    &:hover {
        color: #ffc42a;
        font-weight: 500;
    }
`
const ParentNavDiv = styled(StyledNavLink)`
    font-family: 'IntelOne Display', sans-serif;
    font-size: 1rem;
    padding-left: 0.2rem;
    color: #292f4c;
    font-weight: 400;
    line-height: 2rem;
    &:active,
    &:hover {
        color: #ffc42a;
        font-weight: 500;
    }
`
const ChildNavLink = styled(StyledNavLink)`
    font-family: 'IntelOne Display', sans-serif;
    font-size: 1rem;
    line-height: 1.4;
    color: #292f4c;
    font-weight: lighter;
    padding-left: 1.3rem;

    &:active,
    &:hover {
        color: #ffc42a;
        font-weight: 400;
    }
`
export interface IPageLinkPanel {
    getAuthPermissions?: () => void
    authPermissionsResult?: AuthPermissions
    authPermissionsError?: any
    authPermissionsLoading?: LoadingState
    createOrg?: (createOrgRequest: CreateOrgRequest) => void
    createOrgLoading?: LoadingState
    createOrgResult?: any
    createOrgError?: any
}

export const PageLinkPanel: FunctionComponent<IPageLinkPanel> = ({
    getAuthPermissions,
    authPermissionsResult,
    authPermissionsError,
    authPermissionsLoading,
    createOrg,
    createOrgLoading,
    createOrgResult,
    createOrgError,
}: IPageLinkPanel) => {
    let navigate = useNavigate()
    let isAdmin: boolean = false
    let isIntelEmployee: boolean = false
    let organizationalRoles: string[] = []
    let otherMetaPermissions: string[] = []
    let organizationName: string = ''
    let featureFlags: string[] = []

    const requestedUrl = `${window.location.pathname}${window.location.search}`
    let expandState: boolean = false
    let settingExpandState: boolean = false
    if (
        requestedUrl &&
        (requestedUrl.startsWith('/myRequest') ||
            requestedUrl.startsWith('/addNewOptimization') ||
            requestedUrl.startsWith('/allUploadRequest') ||
            requestedUrl.startsWith('/allSupportRequest'))
    ) {
        expandState = true
    }

    const [showRequestLinks, setShowRequestLinks] = useState(expandState)

    const getNameOfIntelEmployeeFromCognito = async () => {
        try {
            const response = await Auth.currentSession()
            const idToken = response.getIdToken()
            const ID_token = idToken.getJwtToken()
            let parts = ID_token.split('.')
            let user_info = atob(parts[1])
            if (user_info) {
                let userInfo = JSON.parse(user_info)
                if (
                    userInfo &&
                    userInfo['name'] &&
                    !stringUtils.isNullOrUndefinedOrEmpty(userInfo['name'])
                ) {
                    return userInfo['name']
                }
                return null
            }
            return null
        } catch (error) {
            return null
        }
    }

    useEffect(() => {
        if (getAuthPermissions) getAuthPermissions()
    }, [])

    useEffect(() => {
        const extractAuthPermissionsDetails = async () => {
            if (
                authPermissionsLoading !== LoadingState.Pending &&
                authPermissionsResult !== undefined
            ) {
                if (authPermissionsResult?.isAdmin) {
                    _setEncryptedValueInStorage(
                        'isAdmin',
                        `${authPermissionsResult.isAdmin}`
                    )
                    isAdmin = authPermissionsResult.isAdmin
                }
                if (authPermissionsResult?.isIntelEmployee) {
                    _setEncryptedValueInStorage(
                        'isIntelEmployee',
                        `${authPermissionsResult.isIntelEmployee}`
                    )
                    isIntelEmployee = authPermissionsResult.isIntelEmployee
                }
                if (authPermissionsResult?.isPerformanceUploader) {
                    _setEncryptedValueInStorage(
                        'isPerformanceUploader',
                        `${authPermissionsResult.isPerformanceUploader}`
                    )
                }
                if (authPermissionsResult?.organizationalRoles) {
                    let orgRoles =
                        authPermissionsResult.organizationalRoles.toString()
                    _setEncryptedValueInStorage('organizationalRoles', orgRoles)
                }
                if (authPermissionsResult?.otherMetaPermissions) {
                    let metaPermissions =
                        authPermissionsResult.otherMetaPermissions.toString()
                    _setEncryptedValueInStorage(
                        'otherMetaPermissions',
                        metaPermissions
                    )
                }
                if (authPermissionsResult?.featureFlags) {
                    const isLoginRoute = localStorage.getItem('loginRoute')
                    let featureFlagsValue =
                        authPermissionsResult?.featureFlags.toString()
                    _setEncryptedValueInStorage(
                        'featureFlags',
                        featureFlagsValue
                    )
                }
                if (authPermissionsResult?.organizationName) {
                    let orgName =
                        authPermissionsResult.organizationName.toString()
                    _setEncryptedValueInStorage('orgName', orgName)
                }
                if (authPermissionsResult?.inAnyOrg === true) {
                    localStorage.setItem(
                        'userDisplayName',
                        authPermissionsResult?.userDisplayName ?? ''
                    )
                }
                if (
                    authPermissionsResult?.isIntelEmployee &&
                    authPermissionsResult?.inAnyOrg === false
                ) {
                    const nameOfIntelEmployee =
                        await getNameOfIntelEmployeeFromCognito()
                    if (createOrg) {
                        var createOrgRequest = new CreateOrgRequest()
                        if (nameOfIntelEmployee) {
                            createOrgRequest.displayName = nameOfIntelEmployee
                        }
                        createOrgRequest.name = 'self'
                        createOrgRequest.description =
                            'Self org created for Intel SSO signin user'
                        createOrg(createOrgRequest)
                    }
                }
            }
        }

        extractAuthPermissionsDetails()
    }, [authPermissionsResult, authPermissionsLoading])

    useEffect(() => {
        if (
            createOrgLoading !== LoadingState.Pending &&
            createOrgResult !== undefined
        ) {
            let orgName = _getDecryptedValueFromStorage('orgName')
            if (orgName === '') {
                if (getAuthPermissions) {
                    getAuthPermissions() //refresh permission
                }
            }
        }
    }, [createOrgResult, createOrgLoading])

    //get isAdmin and isIntelEmployee info from storage
    let isAdminExist = _getDecryptedValueFromStorage('isAdmin')
    let isIntelEmployeeExist = _getDecryptedValueFromStorage('isIntelEmployee')
    let orgRoles = _getDecryptedValueFromStorage('organizationalRoles')
    let otherPermissions = _getDecryptedValueFromStorage('otherMetaPermissions')
    let featureFlagsValue = _getDecryptedValueFromStorage('featureFlags')

    if (!StringUtils.isNullOrUndefinedOrEmpty(isAdminExist)) {
        isAdmin = Boolean(isAdminExist)
    }
    if (!StringUtils.isNullOrUndefinedOrEmpty(isIntelEmployeeExist)) {
        isIntelEmployee = Boolean(isIntelEmployeeExist)
    }
    if (orgRoles) {
        organizationalRoles = orgRoles.split(',')
    }
    if (otherPermissions) {
        otherMetaPermissions = otherPermissions.split(',')
    }
    if (featureFlagsValue) featureFlags = featureFlagsValue.split(',')

    let isAnalyst = otherMetaPermissions.includes(UserOtherMetaRoles.Analyst)
    let isDiscounter = otherMetaPermissions.includes(
        UserOtherMetaRoles.Discounter
    )
    const isDataController = otherMetaPermissions.includes(
        UserOtherMetaRoles.DataController
    )
    let isOrgAdmin = doesUserHaveRole(UserRoles.OrgAdmin, orgRoles)

    const navigateOrRefreshPage = (navigateToAddress: string) => {
        if (
            window.location.pathname.indexOf(navigateToAddress) > -1 ||
            navigateToAddress.indexOf(
                APP_BASE_URL ? APP_BASE_URL : '/optimizations'
            ) > -1
        ) {
            //refresh & return to previous state
            window.location.href = navigateToAddress
        } else {
            navigate(navigateToAddress)
        }
    }

    return (
        <LinkPanelContainer>
            <IntelLogoStyling>
                <img
                    src="/Intel_energyblue_logo.png"
                    height="40"
                    width="96"
                    alt="intel logo"
                />
            </IntelLogoStyling>
            <LinkPanelBodyContainer>
                <ParentNavDiv
                    onClick={() =>
                        navigateOrRefreshPage(
                            APP_BASE_URL
                                ? `${APP_BASE_URL}/catalog`
                                : '/catalog'
                        )
                    }
                    href="javascript:void(0)"
                >
                    <i className="uil uil-apps"></i> Catalog
                </ParentNavDiv>
                <StyledNavLink
                    href={
                        APP_BASE_URL
                            ? `${APP_BASE_URL}/gProfilerAnalyzer`
                            : '/gProfilerAnalyzer'
                    }
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'left',
                            width: '155px',
                        }}
                    >
                        <img
                            src={StringUtils.resolve('/gprofiler.svg')}
                            height="25px"
                            width="20px"
                            style={{ marginTop: '0.7rem' }}
                        ></img>
                        gProfiler Analyzer
                    </div>
                </StyledNavLink>
                <div>
                    <StyledNavLink
                        onClick={() =>
                            navigateOrRefreshPage(
                                APP_BASE_URL
                                    ? `${APP_BASE_URL}/benchmarks`
                                    : '/benchmarks'
                            )
                        }
                        href="javascript:void(0)"
                    >
                        <i className="uil uil-chart-line"></i> Benchmarks
                    </StyledNavLink>
                    {featureFlags?.includes('isDemoViewer') ? (
                        <StyledNavLink
                            href="javascript:void(0)"
                            onClick={() => navigate('/cloudInsights')}
                        >
                            <i className="uil uil-message "></i>Cloud Insights
                        </StyledNavLink>
                    ) : (
                        ''
                    )}
                    {isIntelEmployee && (
                        <StyledNavLink
                            onClick={() =>
                                navigateOrRefreshPage(
                                    APP_BASE_URL
                                        ? `${APP_BASE_URL}/salesview/home`
                                        : '/salesview/home'
                                )
                            }
                            href="javascript:void(0)"
                        >
                            <i className="uil uil-analytics"></i> Sales view
                        </StyledNavLink>
                    )}
                </div>
                {isAnalyst && isIntelEmployee ? (
                    <StyledNavLink
                        href={
                            APP_BASE_URL
                                ? `${APP_BASE_URL}/dashboard`
                                : '/dashboard'
                        }
                    >
                        <i className="uil uil-layer-group"></i> Dashboard
                    </StyledNavLink>
                ) : (
                    ''
                )}
                {isDiscounter && isIntelEmployee ? (
                    <StyledNavLink
                        href={
                            APP_BASE_URL
                                ? `${APP_BASE_URL}/discounter`
                                : '/discounter'
                        }
                    >
                        <i className="uil uil-moneybag"></i> Discounter
                    </StyledNavLink>
                ) : (
                    ''
                )}
                <StyledNavLink
                    href={
                        APP_BASE_URL
                            ? `${APP_BASE_URL}/favorites`
                            : '/favorites'
                    }
                >
                    <i className="uil uil-bookmark"></i> Favorites
                </StyledNavLink>
                <ParentNavDiv
                    onClick={() =>
                        showRequestLinks
                            ? setShowRequestLinks(false)
                            : setShowRequestLinks(true)
                    }
                    href="javascript:void(0)"
                >
                    <i className="uil uil-users-alt"></i>Requests
                    {showRequestLinks ? (
                        <i className="uil uil-angle-double-up"></i>
                    ) : (
                        <i className="uil uil-angle-double-down"></i>
                    )}
                </ParentNavDiv>
                {showRequestLinks ? (
                    <GroupChildLinks>
                        <ChildNavLink
                            href={
                                APP_BASE_URL
                                    ? `${APP_BASE_URL}/myRequest`
                                    : '/myRequest'
                            }
                        >
                            My requests
                        </ChildNavLink>
                        {isIntelEmployee ? (
                            <div>
                                <ChildNavLink
                                    onClick={() =>
                                        (window.location.href = APP_BASE_URL
                                            ? `${APP_BASE_URL}/addNewOptimization`
                                            : '/addNewOptimization')
                                    }
                                    href="javascript:void(0)"
                                >
                                    Add new optimization
                                </ChildNavLink>
                                {/* <ChildNavLink
                                    onClick={() =>
                                        navigateOrRefreshPage(
                                            '/requestNewOptimization'
                                        )
                                    }
                                >
                                    Request new optimization
                                </ChildNavLink> */}
                                {/* <ChildNavLink
                                    onClick={() =>
                                        navigateOrRefreshPage(
                                            '/requestNewBenchmark'
                                        )
                                    }
                                >
                                    Request new benchmark
                                </ChildNavLink> */}
                                {/* <ChildNavLink
                                    href="/Optimization_Registry_What_to_Expect.pdf"
                                    target="_blank"
                                >
                                    What to expect when you request something
                                </ChildNavLink> */}
                                {isDataController ? (
                                    <div>
                                        <ChildNavLink
                                            href={
                                                APP_BASE_URL
                                                    ? `${APP_BASE_URL}/allUploadRequest`
                                                    : '/allUploadRequest'
                                            }
                                        >
                                            All upload requests
                                        </ChildNavLink>
                                        <ChildNavLink
                                            href={
                                                APP_BASE_URL
                                                    ? `${APP_BASE_URL}/allSupportRequest`
                                                    : '/allSupportRequest'
                                            }
                                        >
                                            All support requests
                                        </ChildNavLink>
                                    </div>
                                ) : (
                                    ''
                                )}
                                <StyledNavLink
                                    href="/Optimization_Registry_FAQ.pdf"
                                    target="_blank"
                                >
                                    <i className="uil uil-shield-question"></i>{' '}
                                    FAQ
                                </StyledNavLink>
                            </div>
                        ) : (
                            ''
                        )}
                    </GroupChildLinks>
                ) : (
                    ''
                )}
                {featureFlags?.includes('isAboutViewer') ? (
                    <StyledNavLink
                        href={APP_BASE_URL ? `${APP_BASE_URL}/about` : '/about'}
                    >
                        <i className="uil uil-notes"></i> About
                    </StyledNavLink>
                ) : (
                    ''
                )}
                <StyledNavLink
                    href={APP_BASE_URL ? `${APP_BASE_URL}/swagger` : '/swagger'}
                >
                    <i className="uil uil-document-info"></i> API docs
                </StyledNavLink>
                <StyledNavLink
                    href="/dependencies-2022-09-08T22_40_40.553Z.csv"
                    target="_blank"
                >
                    <i className="uil uil-notes"></i>
                    Dependencies
                </StyledNavLink>{' '}
                <StyledNavLink href="/LICENSES.txt" target="_blank">
                    <i className="uil uil-notes"></i>
                    Licenses
                </StyledNavLink>{' '}
                <StyledNavLink
                    href={APP_BASE_URL ? `${APP_BASE_URL}/logOut` : '/logOut'}
                >
                    <i className="uil uil-sign-out-alt"></i> Log out
                </StyledNavLink>
            </LinkPanelBodyContainer>
        </LinkPanelContainer>
    )
}

export default PageLinkPanel
