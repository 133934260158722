import { FormEvent, FunctionComponent, useCallback } from 'react'
import styled from 'styled-components'
import StringUtils from '../../lib/stringUtils'

const GranulateCardContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0.1rem;
    &:hover {
        transform: scale(1.03);
        transition: 0.5s;
    }
`
const GalleryViewContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0.5rem 0 0.5rem 0;
    padding: 0.5rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background: ${(props) => props.theme.color.background.defaultWhite};
    width: 16rem;
`
const RecipeCardColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`
const RecipeCardImageHorizonalComponent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`
const RecipeCardImage = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 0.3rem;
    height: 6.5rem;
`
const RecipeCardInnerImage = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 0.3rem;
    height: 10rem;
`
const RecipeCardComponent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`
const RecipeCardDescriptionComponent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 0.5rem;
    padding: 0.5rem;
`
const RecipeCardDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    font-family: 'IntelOne Display';
    font-weight: 400;
    font-size: 0.8rem;
    ${(props) => props.theme.typography.medium}
    margin-bottom: 0.2rem;
`
const AppNameTextLabel = styled.div`
    display: flex;
    text-align: center;
    justify-content: center;
    font-weight: 500;
    max-width: 18rem;
    max-height: 5rem;
    word-break: break-word;
    font-size: 1.28rem;
    font-family: 'IntelOne Display';
`
const RecipeTileValue = styled.span`
    padding-left: 0.5rem;
    overflow-wrap: break-word;
    max-width: 30rem;
`

export interface IGranulateCardProps {}
const GranulateCard: FunctionComponent<
    IGranulateCardProps
> = ({}: IGranulateCardProps) => {
    const granulateLogo = (
        <img
            src={StringUtils.resolve('/Granulate.png')}
            alt="product logo"
            style={{
                height: '28px',
                width: '176px',
                marginTop: '1.5rem',
            }}
        />
    )

    const productBasedImage = (
        <RecipeCardInnerImage>{granulateLogo}</RecipeCardInnerImage>
    )

    const ExternalCardPictureComponent = () => {
        let style: any = {}
        style['width'] = '100px'
        style['height'] = '100px'
        return (
            <div style={{ display: 'flex' }}>
                <RecipeCardImage>{productBasedImage}</RecipeCardImage>
            </div>
        )
    }

    const galleryViewPanel = (
        <RecipeCardColumn>
            <RecipeCardImageHorizonalComponent>
                {ExternalCardPictureComponent()}
            </RecipeCardImageHorizonalComponent>

            <RecipeCardComponent
                style={{
                    justifyContent: 'flex-start',
                    maxHeight: '16.5rem',
                    height: '16.5rem',
                }}
            >
                <RecipeCardDescriptionComponent>
                    <RecipeCardDiv>
                        <AppNameTextLabel>{'Granulate'}</AppNameTextLabel>
                    </RecipeCardDiv>
                </RecipeCardDescriptionComponent>
                <RecipeCardDiv>
                    <RecipeTileValue
                        style={{
                            fontSize: '1.15rem',
                            padding: '0.5rem',
                            maxWidth: '15rem',
                            textAlign: 'center',
                        }}
                    >
                        {`Granulate improves application performance and cuts costs
                                by up to 45% - with no code changes involved`}
                    </RecipeTileValue>
                </RecipeCardDiv>
            </RecipeCardComponent>
        </RecipeCardColumn>
    )

    const onCardClick = useCallback((event: FormEvent<HTMLElement>) => {
        event.preventDefault()
        window.open('https://granulate.io/', '_blank')
    }, [])
    return (
        <GranulateCardContainer>
            <a style={{ cursor: 'pointer' }} onClick={onCardClick}>
                <GalleryViewContainer>{galleryViewPanel}</GalleryViewContainer>
            </a>
        </GranulateCardContainer>
    )
}
export default GranulateCard
