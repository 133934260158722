import styled from 'styled-components'

const ErrorTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    justify-content: center;
    color: var(--text-default, #2b2c30);
    text-align: center;
    font-family: 'IntelOne Display';
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`
const TryAnotherSearchDiv = styled(ErrorTextContainer)`
    color: var(--text-default, #2b2c30);
    font-size: 16px;
    font-weight: 400;
`

export const NoResultFoundPanel = () => {
    const NoResultComponent = (
        <ErrorTextContainer>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <img
                    src="/sales_img/noresult.svg"
                    width="80px"
                    height="80px"
                ></img>
            </div>
            No results found
            <TryAnotherSearchDiv>Try another Search</TryAnotherSearchDiv>
        </ErrorTextContainer>
    )
    return <div>{NoResultComponent}</div>
}
export default NoResultFoundPanel
