import { css } from 'styled-components'
import { ITypography } from '../typography'

export enum TypographySize {
    giga = 'giga',
    mega = 'mega',
    xxxLarge = 'xxxLarge',
    xxLarge = 'xxLarge',
    xLarge = 'xLarge',
    large = 'large',
    medium = 'medium',
    small = 'small',
    xSmall = 'xSmall',
    xxSmall = 'xxSmall',
    caption = 'caption',
    legal = 'legal',
}

export enum TypographyWeight {
    light = 300,
    normal = 400,
    semibold = 600,
    bold = 700,
}

const mainFont = 'IntelOne Display'

const typography: ITypography = {
    giga: css`
        font-family: '${mainFont}';
        font-weight: ${TypographyWeight.light};
        font-size: 4.75rem;
        line-height: 1.25;
    `,
    mega: css`
        font-family: '${mainFont}';
        font-weight: ${TypographyWeight.light};
        font-size: 3.375rem;
        line-height: 1.25;
    `,
    xxxLarge: css`
        font-family: '${mainFont}';
        font-weight: ${TypographyWeight.light};
        font-size: 2.25rem;
        line-height: 1.25;
    `,
    xxLarge: css`
        font-family: '${mainFont}';
        font-weight: ${TypographyWeight.light};
        font-size: 1.75rem;
        line-height: 1.25;
    `,
    xLarge: css`
        font-family: '${mainFont}';
        font-weight: ${TypographyWeight.normal};
        font-size: 1.25rem;
        line-height: 1.25;
    `,
    // 16px
    large: css`
        font-family: '${mainFont}';
        font-weight: ${TypographyWeight.normal};
        font-size: 1.2rem;
        line-height: 1.2;
    `,
    largeSemiBold: css`
        font-family: '${mainFont}';
        font-weight: ${TypographyWeight.semibold};
        font-size: 1.2rem;
        line-height: 1.2;
    `,
    // 14px
    medium: css`
        font-family: '${mainFont}';
        font-weight: ${TypographyWeight.normal};
        font-size: 1rem;
        line-height: 1.4;
    `,
    mediumBold: css`
        font-family: '${mainFont}';
        font-weight: ${TypographyWeight.bold};
        font-size: 1rem;
        line-height: 1.4;
    `,
    mediumSemiBold: css`
        font-family: '${mainFont}';
        font-weight: ${TypographyWeight.semibold};
        font-size: 1rem;
        line-height: 1.4;
    `,
    // 12px
    small: css`
        font-family: '${mainFont}';
        font-weight: ${TypographyWeight.normal};
        font-size: 0.875rem;
        line-height: 1.2;
    `,
    smallBold: css`
        font-family: '${mainFont}';
        font-weight: ${TypographyWeight.bold};
        font-size: 0.875rem;
        line-height: 1.2;
    `,
    // 10px
    xSmall: css`
        font-family: '${mainFont}';
        font-weight: ${TypographyWeight.semibold};
        font-size: 0.75rem;
        line-height: 1;
    `,
    xxSmall: css`
        font-family: '${mainFont}';
        font-weight: ${TypographyWeight.normal};
        font-size: 0.5rem;
        line-height: 1.25;
    `,
    caption: css`
        font-family: '${mainFont}';
        font-weight: ${TypographyWeight.normal};
        font-size: 0.625rem;
        line-height: 1.5;
    `,
    timestamp: css`
        font-family: '${mainFont}';
        font-weight: ${TypographyWeight.normal};
        font-size: 0.625rem;
        line-height: 1.5;
    `,
    timestampBold: css`
        font-family: '${mainFont}';
        font-weight: ${TypographyWeight.bold};
        font-size: 0.625rem;
        line-height: 1.5;
    `,
}

export { typography }
