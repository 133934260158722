/************************************************
 * Copyright (C) 2021 Intel Corporation
 ************************************************/
import styled from 'styled-components'
import { FunctionComponent, Dispatch } from 'react'
import { connect } from 'react-redux'

import {
    getOptimizationUploadRequest,
    getOptimizationNewOptimizationRequest,
    deleteOptimizationRequest,
    clearOptimizationRequestData,
    getBenchmarkRequest,
    deleteBenchmarkRequest,
    clearBenchmarkRequestData,
    getFeedback,
    deleteFeedback,
    clearFeedbackData,
    getOptimizationOwnerFeedback,
} from '../../store/myRequest.slice'
import { IMyRequestContainerLogic } from './myRequestContainer.hook'
import MyRequestPanel from '../../components/myRequestPanel/myRequestPanel'
import FooterPanel from '../../layout/footer'
import { FormLabel, Alert, Breadcrumb, BreadcrumbItem } from 'react-bootstrap'
import UserPanel from '../../layout/userProfile'
import StringUtils from '../../lib/stringUtils'
import HeaderPanel from '../../layout/header'
import { useAppSelector } from '../../config/hooks'

const MyRequestPanelContainer = styled.div`
    display: flex;
    height: ${(props) => props.theme.size.mainContainerHeight};
    width: ${(props) => props.theme.size.mainContainerWidth};
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: space-between;
    @media screen and (min-width: 80rem) {
        overflow-x: hidden;
    }
    @media screen and (max-width: 500px) {
        width: 100%;
        overflow-x: hidden;
    }
`

const MyRegistryHorizontalContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: space-between;
`
const MyRegistryBodyContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: center;
    background-color: #f4f5f5;
    padding-bottom: 10rem;
    width: 100%;
`

const MyRegistryBodyContainerReposition = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
`

const PageLinkPanelPanelWrapper = styled.div`
    display: flex;
    margin-left: 2rem;
    @media screen and (max-width: 500px) {
        display: none;
    }
`
const MobileHeaderWrapper = styled.div`
    display: flex;
    display: none;
    @media screen and (max-width: 500px) {
        display: block;
    }
`

const MyRegistryPanelWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: flex-start;
`
const AlertComponent = styled(Alert)`
    display: flex;
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: auto;
    justify-content: center;
`
const NotAutorizeContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: left;
`
const NotAutorizeLabel = styled(FormLabel)`
    ${(props) => props.theme.typography.xLarge}
    text-align: left;
    font-weight: 400;
    font-size: 1rem;
    margin-bottom: 50rem;
    color: red;
`
const HeaderTitleComponent = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: -1rem;
    justify-content: center;
    padding: 0 3%;
    background-color: rgb(101, 49, 113);
    background-image: ${(props) =>
        `url('${process.env.PUBLIC_URL}/background-l1-mosaic.svg')`};
    background-position-x: 50%;
    background-position-y: 50%;
    background-repeat: no-repeat;
    background-size: 100%;
    width: 100%;
    height: 200px;
`
const PageTitleTextLabel = styled(FormLabel)`
    color: var(
        --global-header-light-light-fill,
        var(--black-and-white-white, #fff)
    );
    text-align: center;
    font-family: 'IntelOne Display';
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px; /* 120% */
    letter-spacing: -0.2px;
    text-align: left;
    padding: 1rem 0;
`

export interface IMyRequestContainerProps {
    getOptimizationUploadRequest: any
    optimizationOptimizationUploadResult: any
    getOptimizationNewOptimizationRequest: any
    optimizationNewOptimizationResult: any
    getBenchmarkRequest: typeof getBenchmarkRequest
    getFeedback: typeof getFeedback
    getOptimizationOwnerFeedback: typeof getOptimizationOwnerFeedback
    optimizationRequestResult: any
    benchmarkRequestResult: any
    feedbackResult: any
    ownerFeedbackResult: any
    deleteOptimizationRequest: typeof deleteOptimizationRequest
    deleteBenchmarkRequest: typeof deleteBenchmarkRequest
    deleteFeedback: any
    deleteOptimizationRequestResult: any
    deleteBenchmarkRequestResult: any
    deleteFeedbackResult: any
    clearOptimizationRequestData: any
    clearBenchmarkRequestData: any
    clearFeedbackData: any
    userId: string
    appName: string
    optimizationUploadRequestResult: any
    newOptimizationRequestResult: any
    isIntelEmployee: boolean
}

const MyRequestContainer: FunctionComponent<IMyRequestContainerProps> & {
    defaultProps: Partial<IMyRequestContainerProps>
} = ({
    deleteOptimizationRequest,
    deleteOptimizationRequestResult,
    clearOptimizationRequestData,
    getBenchmarkRequest,
    benchmarkRequestResult,
    deleteBenchmarkRequest,
    deleteBenchmarkRequestResult,
    clearBenchmarkRequestData,
    getFeedback,
    getOptimizationOwnerFeedback,
    ownerFeedbackResult,
    feedbackResult,
    deleteFeedback,
    deleteFeedbackResult,
    clearFeedbackData,
    userId,
    appName,
    isIntelEmployee,
}: IMyRequestContainerProps) => {
    let intelEmpStatus = localStorage.getItem('isIntelEmployee')
    isIntelEmployee = !StringUtils.isNullOrUndefinedOrEmpty(intelEmpStatus)
        ? Boolean(intelEmpStatus)
        : false

    const {} = IMyRequestContainerLogic({
        getOptimizationUploadRequest,
        getOptimizationNewOptimizationRequest,
        getBenchmarkRequest,
        getFeedback,
        getOptimizationOwnerFeedback,
        appName,
        userId,
        deleteOptimizationRequestResult,
        deleteBenchmarkRequestResult,
        deleteFeedbackResult,
        isIntelEmployee,
    } as IMyRequestContainerProps)

    const optimizationUploadRequestResult = useAppSelector<any>(
        (state) => state.optimizationOptimizationUploadResult
    )
    const newOptimizationRequestResult = useAppSelector<any>(
        (state) => state.optimizationNewOptimizationResult
    )

    return (
        <MyRequestPanelContainer>
            <HeaderPanel />
            <MyRegistryHorizontalContainer>
                <MyRegistryBodyContainer>
                    <MyRegistryBodyContainerReposition>
                        <UserPanel></UserPanel>
                        <MyRegistryPanelWrapper>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    background: '#F4F5F5',
                                    width: '100%',
                                }}
                            >
                                <Breadcrumb style={{ padding: '0.5rem 1rem' }}>
                                    <BreadcrumbItem
                                        href={StringUtils.resolve('/catalog')}
                                        style={{
                                            textDecoration: 'none',
                                            color: '#0068b5',
                                        }}
                                    >
                                        Intel Optimization Hub
                                    </BreadcrumbItem>

                                    <BreadcrumbItem
                                        active
                                        style={{
                                            fontWeight: '500',
                                            color: '#262626',
                                        }}
                                    >
                                        My Requests
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            </div>
                            <HeaderTitleComponent>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'left',
                                    }}
                                >
                                    <PageTitleTextLabel>
                                        My Requests
                                    </PageTitleTextLabel>
                                </div>
                            </HeaderTitleComponent>
                            {isIntelEmployee ? (
                                <MyRequestPanel
                                    title={'Upload Optimization Requests'}
                                    getData={getOptimizationUploadRequest}
                                    optimizationAdd={true}
                                    dataResponse={
                                        optimizationUploadRequestResult.data
                                    }
                                    dataLoading={
                                        optimizationUploadRequestResult.loading
                                    }
                                    dataError={
                                        optimizationUploadRequestResult.error
                                    }
                                    deleteData={deleteOptimizationRequest}
                                    deleteResult={
                                        deleteOptimizationRequestResult
                                    }
                                    clearData={clearOptimizationRequestData}
                                />
                            ) : (
                                ''
                            )}
                            <MyRequestPanel
                                title={'Support Requests Submitted'}
                                getData={getFeedback}
                                dataResponse={feedbackResult.data}
                                dataLoading={feedbackResult.loading}
                                dataError={feedbackResult.error}
                                deleteData={deleteFeedback}
                                deleteResult={deleteFeedbackResult}
                                clearData={clearFeedbackData}
                            />
                            {isIntelEmployee ? (
                                <MyRequestPanel
                                    title={'Support Requests Received'}
                                    getData={getOptimizationOwnerFeedback}
                                    dataResponse={ownerFeedbackResult.data}
                                    dataLoading={ownerFeedbackResult.loading}
                                    dataError={ownerFeedbackResult.error}
                                    deleteData={deleteFeedback}
                                    deleteResult={deleteFeedbackResult}
                                    clearData={clearFeedbackData}
                                    isOptOwnersSupportRequest={true}
                                />
                            ) : (
                                ''
                            )}
                            {/* {newOptimizationRequestResult ? (
                                    <MyRequestPanel
                                        title={'My Optimization Requests'}
                                        optimizationRequest={true}
                                        getData={
                                            getOptimizationNewOptimizationRequest
                                        }
                                        dataResponse={
                                            newOptimizationRequestResult.data
                                        }
                                        dataLoading={
                                            newOptimizationRequestResult.loading
                                        }
                                        dataError={
                                            newOptimizationRequestResult.error
                                        }
                                        deleteData={deleteOptimizationRequest}
                                        deleteResult={
                                            deleteOptimizationRequestResult
                                        }
                                        clearData={clearOptimizationRequestData}
                                    />
                                ) : (
                                    ''
                                )}
                                <MyRequestPanel
                                    title={'My Benchmark Requests'}
                                    getData={getBenchmarkRequest}
                                    dataResponse={benchmarkRequestResult.data}
                                    dataLoading={benchmarkRequestResult.loading}
                                    dataError={benchmarkRequestResult.error}
                                    deleteData={deleteBenchmarkRequest}
                                    deleteResult={deleteBenchmarkRequestResult}
                                    clearData={clearBenchmarkRequestData}
                                /> */}
                        </MyRegistryPanelWrapper>
                    </MyRegistryBodyContainerReposition>
                </MyRegistryBodyContainer>
            </MyRegistryHorizontalContainer>
            <FooterPanel></FooterPanel>
        </MyRequestPanelContainer>
    )
}

MyRequestContainer.defaultProps = {}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        deleteOptimizationRequest: (id: string) =>
            dispatch(deleteOptimizationRequest(id)),
        clearOptimizationRequestData: () =>
            dispatch(clearOptimizationRequestData()),
        getBenchmarkRequest: (benchmarkRequest: any) =>
            dispatch(getBenchmarkRequest(benchmarkRequest)),
        deleteBenchmarkRequest: (id: string) =>
            dispatch(deleteBenchmarkRequest(id)),
        clearBenchmarkRequestData: () => dispatch(clearBenchmarkRequestData()),
        getFeedback: (feedbackRequest: any) =>
            dispatch(getFeedback(feedbackRequest)),
        deleteFeedback: (id: string) => dispatch(deleteFeedback(id)),
        clearFeedbackData: () => dispatch(clearFeedbackData()),
        getOptimizationOwnerFeedback: (feedbackRequest: any) =>
            dispatch(getOptimizationOwnerFeedback(feedbackRequest)),
    }
}

const mapStateToProps = (state: any) => {
    return {
        benchmarkRequestResult: state.benchmarkRequestResult,
        feedbackResult: state.feedbackResult,
        deleteOptimizationRequestResult: state.deleteOptimizationRequestResult,
        deleteBenchmarkRequestResult: state.deleteBenchmarkRequestResult,
        deleteFeedbackResult: state.deleteFeedbackResult,
        ownerFeedbackResult: state.optimizationOwnerfeedbackResult,
    }
}

type StateToPropsType = ReturnType<typeof mapStateToProps>
type DispatchToPropsType = typeof mapDispatchToProps

export default connect<StateToPropsType, DispatchToPropsType>(
    mapStateToProps,
    mapDispatchToProps
)(MyRequestContainer)
