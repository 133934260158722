import { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react'
import { IBenchmarkHomeContainerProps } from './benchmarkHomeContainer'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch } from '../../config/hooks'
import { getAuthPermissions } from '../../store/authPermissions.slice'
import { getWorkloads, getDefaultWorkloads } from '../../store/benchmark.slice'
import { UserSalesPermissions } from '../../models/userRoles'

export function BenchmarkHomeContainerLogic({
    SearchComponentRef,
    countRef,
}: IBenchmarkHomeContainerProps) {
    const dispatch = useAppDispatch()

    const [searchKey, setSearchKey] = useState<string>('')
    const [activeTabKey, setActiveTabKey] = useState('All')
    const [selectedWorkloadCategoryValue, setSelectedWorkloadCategoryValue] =
        useState<string>('')
    const [offsetCount, setOffsetCount] = useState<number>(0)
    const [selectedAccessLevel, setSelectedAccessLevel] = useState<string>('')
    const [selectedFiltersValue, setSelectedFiltersValue] = useState<string[]>(
        []
    )
    const [pageCount, setPageCount] = useState(10)
    const [pageOffsetCount, setPageOffsetCount] = useState(0)
    const [categoryDisplayGroup, setCategoryDisplayGroup] = useState<number>(1)

    let navigate = useNavigate()
    const qParam = new URLSearchParams(location.search)
    let searchKeyValue = qParam.get('search')

    useEffect(() => {
        dispatch(getAuthPermissions())
        if (searchKeyValue) {
            setSearchKey(searchKeyValue)
            getData(searchKeyValue)
        } else {
            //load data
            getData('')
        }
    }, [])

    const getData = (searchKey: string) => {
        dispatch(
            getDefaultWorkloads({
                count: 6,
                offset: 0,
                accessGroup: getSelectedAccessGroup(),
                searchValue: searchKey,
                tagValues: selectedFiltersValue,
            })
        )
        dispatch(
            getWorkloads({
                searchValue: searchKey,
                accessGroup: getSelectedAccessGroup(),
                groupType:
                    activeTabKey && activeTabKey !== 'All' ? activeTabKey : '',
                tagValues: selectedFiltersValue,
                count: pageCount,
                offset: pageOffsetCount,
            })
        )
    }

    useEffect(() => {
        if (
            activeTabKey ||
            pageCount ||
            pageOffsetCount ||
            selectedFiltersValue ||
            selectedAccessLevel
        ) {
            getData(searchKey)
        }
    }, [
        activeTabKey,
        selectedAccessLevel,
        selectedFiltersValue,
        pageCount,
        pageOffsetCount,
    ])

    const onSearchKeyChange = useCallback((event: ChangeEvent<HTMLElement>) => {
        const target = event.currentTarget as HTMLTextAreaElement
        if (target.value != undefined) {
            setSearchKey(target.value)
            setOffsetCount(0) //reset default offeset
            if (target.value === '') {
                getData('')
            }
        }
    }, [])
    const getSelectedAccessGroup = () => {
        let accessGroupFilters = ''
        if (selectedAccessLevel === UserSalesPermissions.InternalAll)
            accessGroupFilters = '&access-group=' + 'internal'
        else if (selectedAccessLevel) {
            accessGroupFilters =
                '&access-group=' + selectedAccessLevel.toLowerCase()
        } else {
            let getSalesAccessLevelValue = localStorage.getItem(
                'selectedSalesAccess'
            )
            if (getSalesAccessLevelValue) {
                accessGroupFilters =
                    '&access-group=' +
                    getSalesAccessLevelValue.replace('All', '').toLowerCase()
            }
        }

        return accessGroupFilters
    }
    const onFilterClick = useCallback(
        (selectedFiltersValue: string[], count: number) => {
            setOffsetCount(0) //reset default offeset
            if (!searchKey) localStorage.setItem('benchmarkSearchKey', '')
            setSelectedFiltersValue(selectedFiltersValue)
        },
        []
    )
    const onFilterClearAllClick = useCallback(
        (selectedFiltersValue: string[], count: number) => {
            setSelectedFiltersValue([])
        },
        []
    )

    const onSearchKeydown = (eventKey: string) => {
        if (eventKey === 'Enter') {
            setOffsetCount(0)
            localStorage.setItem('benchmarkSearchKey', searchKey)
            getData(searchKey)
        }
    }
    const onWorkloadClick = useCallback(
        (event: FormEvent<HTMLElement>, workloadId: string) => {
            event.preventDefault()
            navigate(`/allWorkloads/${workloadId}`)
        },
        []
    )
    const onPreviousNavigationClick = useCallback(
        (event: FormEvent<HTMLElement>) => {
            event.preventDefault()
            let offset = offsetCount - 6
            setOffsetCount(offset)
            //const categoryFiltersValue = getSelectedCategory()
            dispatch(
                getDefaultWorkloads({
                    count: 6,
                    offset: offset,
                    searchValue: searchKey,
                    accessGroup: getSelectedAccessGroup(),
                    tagValues: selectedFiltersValue,
                })
            )
        },
        [offsetCount]
    )
    const onNextNavigationClick = useCallback(
        (event: FormEvent<HTMLElement>) => {
            event.preventDefault()
            let offset = offsetCount + 6
            setOffsetCount(offset)
            //const categoryFiltersValue = getSelectedCategory()
            dispatch(
                getDefaultWorkloads({
                    count: 6,
                    offset: offset,
                    searchValue: searchKey,
                    accessGroup: getSelectedAccessGroup(),
                    tagValues: selectedFiltersValue,
                })
            )
        },
        [offsetCount]
    )
    const onPageScrollClick = useCallback((event: FormEvent<HTMLElement>) => {
        event.preventDefault()
        if (SearchComponentRef.current !== undefined)
            SearchComponentRef.current.focus()
    }, [])

    const onAccessApplyChange = useCallback((selectedAccessLevel: string) => {
        setSelectedAccessLevel(selectedAccessLevel)
    }, [])

    const onBenchmarkTabClick = useCallback(
        (selectedTabValue: string, event: FormEvent<HTMLElement>) => {
            event.preventDefault()
            setActiveTabKey(selectedTabValue)
            setPageCount(10)
            setPageOffsetCount(0)
        },
        [activeTabKey]
    )
    const onAfterPageClicked = (offset: number, pageCount: number) => {
        setPageCount(pageCount)
        setPageOffsetCount(offset)
    }
    const onPageCountChange = (offset: number, pageCount: number) => {
        setPageCount(pageCount)
        setPageOffsetCount(offset)
    }
    const onCategoryNextClick = useCallback(
        (event: FormEvent<HTMLElement>) => {
            event.preventDefault()
            if (categoryDisplayGroup === 1) setCategoryDisplayGroup(2)
            else if (categoryDisplayGroup === 2) setCategoryDisplayGroup(1)
        },
        [categoryDisplayGroup]
    )

    return {
        onSearchKeyChange,
        onFilterClick,
        onFilterClearAllClick,
        onSearchKeydown,
        onWorkloadClick,
        onNextNavigationClick,
        onPreviousNavigationClick,
        searchKey,
        selectedWorkloadCategoryValue,
        onPageScrollClick,
        onAccessApplyChange,
        selectedFiltersValue,
        onBenchmarkTabClick,
        onAfterPageClicked,
        onPageCountChange,
        categoryDisplayGroup,
        onCategoryNextClick,
    }
}
