import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AuthToken } from '../models/authToken'

export interface IAuthLoginState {
    authToken: AuthToken | null
    isLoggedIn: boolean
}

const initialState: IAuthLoginState = {
    authToken: null,
    isLoggedIn: false,
}

// Then, handle actions in your reducers:
const authLoginSlice = createSlice({
    name: 'authlogin',
    initialState,
    reducers: {
        setSession(state:any, action: PayloadAction<any>) {
            state.authToken = action.payload
            state.isLoggedIn = true
        },
        clearSession(state:any, action: PayloadAction<any>) {
            state.authToken = action.payload
            state.isLoggedIn = false
        },
    },
})

export const { setSession, clearSession } = authLoginSlice.actions
export const authLogin = authLoginSlice.reducer
