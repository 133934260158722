/************************************************
 * Copyright (C) 2021 Intel Corporation
 ************************************************/
import styled from 'styled-components'
import { FunctionComponent } from 'react'
import { Alert, Button, Form, FormLabel, Row } from 'react-bootstrap'
import { ChangePasswordContainerLogic } from './changePasswordContainer.hook'

const ChangePasswordPanelContainer = styled.div`
    display: flex;
    height: ${(props) => props.theme.size.mainContainerHeight};
    width: ${(props) => props.theme.size.mainContainerWidth};
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: space-between;
    @media screen and (min-width: 1200px) {
        overflow-x: hidden;
    }
`
const PasswordHorizontalContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: space-between;
`
const PasswordBodyContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: center;
    background-color: #fff;
    padding-bottom: 10rem;
`

const PasswordBodyContainerReposition = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
`
const IntelLogoStyling = styled.div`
    display: flex;
    margin-left: 2rem;
    margin-top: 1rem;
    padding: 0.5rem;
`
const HeaderContainer = styled.div`
    display: flex;
    padding-bottom: 2rem;
    justify-content: left;
`
const AlertComponent = styled(Alert)`
    display: flex;
    margin-top: 1rem;
    width: 35vw;
    justify-content: center;
`

const ResetPasswordButton = styled(Button)`
    font-size: 14px;
    font-weight: bold;
    margin: 20px 0px 10px 0px;
    height: 40px;
    width: 100%;
    color: #fff;
    background-color: #337ab7;
    border-color: #2e6da4;
    max-width: 80%;
`
const ChangePasswordButton = styled(ResetPasswordButton)`
    max-width: 85%;
`
const TextLabel = styled(Form.Label)`
    display: flex;
    ${(props) => props.theme.typography.medium}
    text-align: left;
    font-weight: 400;
`

const RequiredTextLabel = styled(FormLabel)`
    display: flex;
    ${(props) => props.theme.typography.xLarge}
    color: red;
`
const TextBoxWrapper = styled(Form.Control)`
    display: flex;
    border-radius: ${(props) => props.theme.shape.input.borderRadius};
    margin-bottom: 1rem;
    max-width: 25rem;
`
const ForgotPasswordWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: center;
    max-width: 100%;
`
const ChangePasswordWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: center;
    max-width: 100%;
`
const PageTitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`
const PageTitleheading = styled.h1`
    ${(props) => props.theme.typography.xxxLarge}
    text-align: left;
    font-family: 'IntelOne Display';
    font-style: normal;
    font-weight: 600;
`
const PageTitleTextLabel2 = styled(FormLabel)`
    text-align: left;
    font-family: 'IntelOne Display';
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    padding-top: 1rem;
    padding-bottom: 2rem;
`
const ValidPasswordSpan = styled.span`
    display: flex;
    color: #19bf00;
    font-size: 14px;
    font-family: 'Arial';
`
const InValidPasswordSpan = styled(ValidPasswordSpan)`
    color: #df3312;
`
const PasswordValidationdiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 20rem;
    padding-bottom: 1rem;
`

export interface IChangePasswordContainerProps {}

const ChangePasswordContainer: FunctionComponent<
    IChangePasswordContainerProps
> = () => {
    const {
        validated,
        codeValidated,
        onForgotPasswordSubmit,
        onChangePasswordSubmit,
        onUserEmailChange,
        showForgotPasswordForm,
        userEmail,
        changePasswordError,
        forgotPasswordError,
        onCodeConfirmChange,
        onNewPasswordChange,
        onConfirmNewPasswordChange,
        onCodeConfirmKeyDown,
        onPasswordKeyUp,
        onPasswordMatchKeyUp,
        isPasswordContainLowerLetter,
        isPasswordContainSpecialChar,
        isPasswordContainNumber,
        isPasswordLength10Char,
        isPasswordMatched,
        showPasswordvalidation,
    } = ChangePasswordContainerLogic({} as IChangePasswordContainerProps)
    return (
        <ChangePasswordPanelContainer>
            <HeaderContainer>
                <IntelLogoStyling>
                    <img
                        src="/Intel_energyblue_logo.png"
                        height="65px"
                        width="171px"
                        alt="intel logo"
                        style={{ verticalAlign: 'middle' }}
                    />
                </IntelLogoStyling>
            </HeaderContainer>
            <PasswordHorizontalContainer>
                <PasswordBodyContainer>
                    <PasswordBodyContainerReposition>
                        <ForgotPasswordWrapper>
                            {showForgotPasswordForm ? (
                                <div className="col-md-3 col-lg-3">
                                    <PageTitleheading>
                                        Forgot your password?
                                    </PageTitleheading>

                                    <PageTitleContainer>
                                        <PageTitleTextLabel2 data-testid="pageSubTitle-lable">
                                            Enter your Email below and we will
                                            send a message to reset your
                                            password
                                        </PageTitleTextLabel2>
                                    </PageTitleContainer>
                                    <AlertComponent
                                        style={{ width: '25rem' }}
                                        variant="danger"
                                        show={
                                            forgotPasswordError ? true : false
                                        }
                                        data-testid="forgotpass-error-text"
                                    >
                                        {'Error! ' +
                                            forgotPasswordError?.message}
                                    </AlertComponent>

                                    <Form
                                        noValidate
                                        validated={validated}
                                        onSubmit={onForgotPasswordSubmit}
                                        id="forgotPasswordForm"
                                    >
                                        <Form.Group controlId="formBasicEmail">
                                            <TextLabel data-testid="email-address-label">
                                                <RequiredTextLabel>
                                                    *
                                                </RequiredTextLabel>
                                                Email address{' '}
                                            </TextLabel>
                                            <TextBoxWrapper
                                                type="email"
                                                placeholder="Enter email..."
                                                onChange={onUserEmailChange}
                                                required
                                                data-testid="email-address-text"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please enter an email address in
                                                format name@xyg.com.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <ResetPasswordButton
                                            variant="primary"
                                            type="submit"
                                            data-testid="reset-password-button"
                                        >
                                            Reset my password
                                        </ResetPasswordButton>
                                    </Form>
                                </div>
                            ) : (
                                ''
                            )}
                        </ForgotPasswordWrapper>
                        <ChangePasswordWrapper>
                            {!showForgotPasswordForm ? (
                                <div className="col-md-3 col-lg-3">
                                    <PageTitleContainer>
                                        <PageTitleTextLabel2 data-testid="pageSubTitle-lable">
                                            We have sent a password code by
                                            email to {userEmail}. Enter it below
                                            to reset your password.
                                        </PageTitleTextLabel2>
                                    </PageTitleContainer>
                                    <AlertComponent
                                        style={{ width: '25rem' }}
                                        variant="danger"
                                        show={
                                            changePasswordError ? true : false
                                        }
                                        data-testid="changepass-error-text"
                                    >
                                        {'Error! ' +
                                            changePasswordError?.message}
                                    </AlertComponent>

                                    <Form
                                        noValidate
                                        validated={codeValidated}
                                        onSubmit={onChangePasswordSubmit}
                                        id="changePasswordForm"
                                    >
                                        <Form.Group controlId="code">
                                            <TextLabel data-testid="entercode-label">
                                                <RequiredTextLabel>
                                                    *
                                                </RequiredTextLabel>
                                                Code{' '}
                                            </TextLabel>
                                            <TextBoxWrapper
                                                placeholder="Enter your code here..."
                                                type="search"
                                                onChange={onCodeConfirmChange}
                                                onKeyDown={onCodeConfirmKeyDown}
                                                required
                                                data-testid="entercode-text"
                                            />

                                            <Form.Control.Feedback type="invalid">
                                                Please enter your code.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicPassword">
                                            <TextLabel data-testid="choose-password-label">
                                                <RequiredTextLabel>
                                                    *
                                                </RequiredTextLabel>
                                                New Password{' '}
                                            </TextLabel>
                                            <TextBoxWrapper
                                                id="newPassword"
                                                name="newPassword"
                                                type="password"
                                                placeholder="Please enter new password..."
                                                autoComplete="new-password"
                                                onChange={onNewPasswordChange}
                                                onKeyUp={onPasswordKeyUp}
                                                required
                                                data-testid="new-password-text"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please enter new password.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group controlId="formMatchPassword">
                                            <TextLabel data-testid="confirm-password-label">
                                                <RequiredTextLabel>
                                                    *
                                                </RequiredTextLabel>
                                                Enter New Password Again{' '}
                                            </TextLabel>
                                            <TextBoxWrapper
                                                type="password"
                                                placeholder="Please enter new password again..."
                                                onChange={
                                                    onConfirmNewPasswordChange
                                                }
                                                onKeyUp={onPasswordMatchKeyUp}
                                                required
                                                data-testid="confirm-password-text"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please enter new password again.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        {showPasswordvalidation ? (
                                            <PasswordValidationdiv>
                                                {isPasswordContainLowerLetter ? (
                                                    <ValidPasswordSpan>
                                                        ✓ Password must contain
                                                        a lower case letter
                                                    </ValidPasswordSpan>
                                                ) : (
                                                    <InValidPasswordSpan>
                                                        ✖ Password must contain
                                                        a lower case letter
                                                    </InValidPasswordSpan>
                                                )}

                                                {isPasswordContainSpecialChar ? (
                                                    <ValidPasswordSpan>
                                                        ✓ Password must contain
                                                        a special character
                                                    </ValidPasswordSpan>
                                                ) : (
                                                    <InValidPasswordSpan>
                                                        ✖ Password must contain
                                                        a special character
                                                    </InValidPasswordSpan>
                                                )}

                                                {isPasswordContainNumber ? (
                                                    <ValidPasswordSpan>
                                                        ✓ Password must contain
                                                        a number
                                                    </ValidPasswordSpan>
                                                ) : (
                                                    <InValidPasswordSpan>
                                                        ✖ Password must contain
                                                        a number
                                                    </InValidPasswordSpan>
                                                )}
                                                {isPasswordLength10Char ? (
                                                    <ValidPasswordSpan>
                                                        ✓ Password must contain
                                                        at least 10 characters
                                                    </ValidPasswordSpan>
                                                ) : (
                                                    <InValidPasswordSpan>
                                                        ✖ Password must contain
                                                        at least 10 characters
                                                    </InValidPasswordSpan>
                                                )}
                                                {isPasswordMatched ? (
                                                    <ValidPasswordSpan>
                                                        ✓ Password must match
                                                    </ValidPasswordSpan>
                                                ) : (
                                                    <InValidPasswordSpan>
                                                        ✖ Password must match
                                                    </InValidPasswordSpan>
                                                )}
                                            </PasswordValidationdiv>
                                        ) : (
                                            ''
                                        )}
                                        <ChangePasswordButton
                                            variant="primary"
                                            type="submit"
                                            data-testid="change-password-button"
                                        >
                                            Change Password
                                        </ChangePasswordButton>
                                    </Form>
                                </div>
                            ) : (
                                ''
                            )}
                        </ChangePasswordWrapper>
                    </PasswordBodyContainerReposition>
                </PasswordBodyContainer>
            </PasswordHorizontalContainer>
        </ChangePasswordPanelContainer>
    )
}

export default ChangePasswordContainer
