import { API_SERVICE_URL } from '../config/service'
import { NewBenchmarkRequest } from '../models/newBenchmarkRequest'

import api from './auth/client'

const serviceConfig = require('../config/service.json')

class NewBenchmarkService {
    submitNewBenchmark(newBenchmarkRequest: NewBenchmarkRequest) {
        var url = API_SERVICE_URL + serviceConfig.submitNewBenchmarkURL
        return api.post(url, newBenchmarkRequest)
    }
}

export default new NewBenchmarkService()
