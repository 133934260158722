import { combineReducers } from '@reduxjs/toolkit'
import { authLogin } from './authLogin.slice'
import {
    uploadRegistryResult,
    getRegistryResult,
    attachFileResult,
    deleteUploadedOptimizationFileResult,
} from './uploadRegistry.slice'
import { myRegistryResult, deleteRegistryResult } from './myRegistry.slice'
import {
    catalogSearchResult,
    downloadCatalogResult,
    submitFeedbackResult,
    saveFavoriteResult,
    getTagsValuesResult,
    saveStarRatingResult,
    saveVotingResult,
    getRecipeByIdResult,
    saveLinkClickResult,
    saveSiteFeedbackResult,
    getLastLoginResult,
    getBenchmarksResults,
    getFiltersValuesResult,
    saveLikeResult,
    getPublicFiltersValuesResult,
    feedbackAttachFileResult,
    addOptimizationSubscriptionResult,
    deleteOptimizationSubscriptionResult,
} from './catalog.slice'
import {
    optimizationOptimizationUploadResult,
    optimizationNewOptimizationResult,
    deleteOptimizationRequestResult,
    benchmarkRequestResult,
    deleteBenchmarkRequestResult,
    feedbackResult,
    deleteFeedbackResult,
    feedbackResultById,
    feedbackAttachmentResult,
    optimizationRequestByIdResult,
    benchmarkResultById,
    optimizationOwnerfeedbackResult,
} from './myRequest.slice'
import { newOptimizationResult } from './newOptimization.slice'
import { getFavoritesResult } from './favorite.slice'
import { newBenchmarkResult } from './newBenchmark.slice'
import {
    checkoutItemsResult,
    getCartItemsResult,
    preCheckoutItemsResult,
    updateCartResult,
} from './shoppingCart.slice'
import {
    getSubscriptionResult,
    removeSubscriptionResult,
} from './subscription.slice'
import {
    getMembershipResult,
    updateMembershipResult,
    updateUserDefaultOrgResult,
} from './membership.slice'
import {
    organizationInfoResult,
    userOrganizationsListResult,
} from './organization.slice'
import { createOrgResult } from './createOrg.slice'
import { accessGroups } from './accessMgmt.slice'
import { authPermissionsResult } from './authPermissions.slice'
import {
    paymentProfiles,
    paymentProfileCreateFinalize,
    paymentProfileCreateInit,
    paymentProfileHPP,
} from './paymentProfile.slice'
import { performanceSamplesResult } from './performanceSamples.slice'
import { getDiscountResult, acceptDiscountResult, createDiscountCodeResult } from './discount.slice'
import {
    acceptInvitesResult,
    getInvitesResult,
    sendInvitesResult,
} from './invitation.slice'
import { getLicenseResult, saveLicenseDecisionResult } from './license.slice'
import {
    getDashboardDataResult,
    getAnalyticsResult,
    getSearchDistResult,
    getUniqueLoginsResult,
    getUniqueLoginsWithToDateResult,
    getNoHitsResult,
    getAllNoHitsResult,
    getTotalHitsResult,
    getTotalNoHitsResult,
    getIntelSearchDistResult,
    getClickCountsResult,
    getCardsActivitySummaryResult,
    getEngagedUsersResult,
    getDisengagedUsersResult,
    getFirstTimeCustomersResult,
} from './dashboard.slice'
import { getSwaggerResult } from './swagger.slice'
import {
    getWorkloadBenchmarksResult,
    getWorkloadsResult,
    getDefaultWorkloadsResult,
    getWorkloadBenchmarksListResult,
    downloadBenchmarkSlideResult,
    getWorkloadOptimizationsResult,
    getWorkloadPartnerSolutionsResult,
    getWorkloadCustomerCaseStudiesResult,
    getSalesSidebarLinksResult,
} from './benchmark.slice'
import {
    deleteSupportRequestResult,
    deleteUploadRequestResult,
    getAllSupportRequestResult,
    getAllUploadRequestResult,
    updateApprovalDecisionResult,
    updateSupportApprovalResult,
} from './allRequest.slice'
import { diagnostics, diagnosticsSubmit } from './diagnostics.slice'
import { chatbotAnswer } from './chatbot.slice'
import {
    getAIModelsResult,
    postChatCompletionResult,
    postRetrievalQueryResult,
} from './chatCompletion.slice'
import { submitImageGenerationResult } from './imageGeneration.slice'

const rootReducer = combineReducers({
    authLogin: authLogin,
    uploadRegistryResult: uploadRegistryResult,
    myRegistryResult: myRegistryResult,
    catalogSearchResult: catalogSearchResult,
    downloadCatalogResult: downloadCatalogResult,
    getRegistryResult: getRegistryResult,
    deleteRegistryResult: deleteRegistryResult,
    attachFileResult: attachFileResult,
    submitFeedbackResult: submitFeedbackResult,
    optimizationOptimizationUploadResult: optimizationOptimizationUploadResult,
    optimizationNewOptimizationResult: optimizationNewOptimizationResult,
    deleteOptimizationRequestResult: deleteOptimizationRequestResult,
    benchmarkRequestResult: benchmarkRequestResult,
    deleteBenchmarkRequestResult: deleteBenchmarkRequestResult,
    feedbackResult: feedbackResult,
    deleteFeedbackResult: deleteFeedbackResult,
    newOptimizationResult: newOptimizationResult,
    saveFavoriteResult: saveFavoriteResult,
    feedbackResultById: feedbackResultById,
    feedbackAttachmentResult: feedbackAttachmentResult,
    optimizationRequestByIdResult: optimizationRequestByIdResult,
    getTagsValuesResult: getTagsValuesResult,
    saveStarRatingResult: saveStarRatingResult,
    getFavoritesResult: getFavoritesResult,
    saveVotingResult: saveVotingResult,
    getDashboardDataResult: getDashboardDataResult,
    getRecipeByIdResult: getRecipeByIdResult,
    saveLinkClickResult: saveLinkClickResult,
    saveSiteFeedbackResult: saveSiteFeedbackResult,
    newBenchmarkResult: newBenchmarkResult,
    getCartItemsResult: getCartItemsResult,
    updateCartResult: updateCartResult,
    preCheckoutItemsResult: preCheckoutItemsResult,
    checkoutItemsResult: checkoutItemsResult,
    getSubscriptionResult: getSubscriptionResult,
    removeSubscriptionResult: removeSubscriptionResult,
    benchmarkResultById: benchmarkResultById,
    getMembershipResult: getMembershipResult,
    updateMembershipResult: updateMembershipResult,
    organizationInfoResult: organizationInfoResult,
    createOrgResult: createOrgResult,
    accessGroups: accessGroups,
    authPermissionsResult: authPermissionsResult,
    getLastLoginResult: getLastLoginResult,
    paymentProfiles: paymentProfiles,
    paymentProfileCreateInit: paymentProfileCreateInit,
    paymentProfileCreateFinalize: paymentProfileCreateFinalize,
    paymentProfileHPP: paymentProfileHPP,
    getBenchmarksResults: getBenchmarksResults,
    performanceSamplesResult: performanceSamplesResult,
    getFiltersValuesResult: getFiltersValuesResult,
    getDiscountResult: getDiscountResult,
    acceptDiscountResult: acceptDiscountResult,
    getInvitesResult: getInvitesResult,
    sendInvitesResult: sendInvitesResult,
    acceptInvitesResult: acceptInvitesResult,
    getLicenseResult: getLicenseResult,
    saveLikeResult: saveLikeResult,
    getAnalyticsResult: getAnalyticsResult,
    getSearchDistResult: getSearchDistResult,
    getIntelSearchDistResult: getIntelSearchDistResult,
    getSwaggerResult: getSwaggerResult,
    getWorkloadsResult: getWorkloadsResult,
    getWorkloadBenchmarksResult: getWorkloadBenchmarksResult,
    getUniqueLoginsResult: getUniqueLoginsResult,
    getUniqueLoginsWithToDateResult: getUniqueLoginsWithToDateResult,
    getNoHitsResult: getNoHitsResult,
    getAllNoHitsResult: getAllNoHitsResult,
    getTotalHitsResult: getTotalHitsResult,
    getTotalNoHitsResult: getTotalNoHitsResult,
    getDefaultWorkloadsResult: getDefaultWorkloadsResult,
    getWorkloadBenchmarksListResult: getWorkloadBenchmarksListResult,
    downloadBenchmarkSlideResult: downloadBenchmarkSlideResult,
    getClickCountsResult: getClickCountsResult,
    getWorkloadOptimizationsResult: getWorkloadOptimizationsResult,
    getWorkloadPartnerSolutionsResult: getWorkloadPartnerSolutionsResult,
    getWorkloadCustomerCaseStudiesResult: getWorkloadCustomerCaseStudiesResult,
    getSalesSidebarLinksResult: getSalesSidebarLinksResult,
    deleteUploadedOptimizationFileResult: deleteUploadedOptimizationFileResult,
    getAllUploadRequestResult: getAllUploadRequestResult,
    updateApprovalDecisionResult: updateApprovalDecisionResult,
    deleteUploadRequestResult: deleteUploadRequestResult,
    getAllSupportRequestResult: getAllSupportRequestResult,
    updateSupportApprovalResult: updateSupportApprovalResult,
    deleteSupportRequestResult: deleteSupportRequestResult,
    getPublicFiltersValuesResult: getPublicFiltersValuesResult,
    userOrganizationsListResult: userOrganizationsListResult,
    updateUserDefaultOrgResult: updateUserDefaultOrgResult,
    diagnostics: diagnostics,
    diagnosticsSubmit: diagnosticsSubmit,
    saveLicenseDecisionResult: saveLicenseDecisionResult,
    getCardsActivitySummaryResult: getCardsActivitySummaryResult,
    getEngagedUsersResult,
    getDisengagedUsersResult,
    getFirstTimeCustomersResult,
    feedbackAttachFileResult,
    chatbotAnswer,
    addOptimizationSubscriptionResult,
    deleteOptimizationSubscriptionResult,
    optimizationOwnerfeedbackResult,
    getAIModelsResult,
    postChatCompletionResult,
    postRetrievalQueryResult,
    submitImageGenerationResult,
    createDiscountCodeResult,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
