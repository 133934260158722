/************************************************
 * Copyright (C) 2021 Intel Corporation
 ************************************************/
import styled from 'styled-components'
import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../config/hooks'
import FooterPanel from '../../layout/footer'
import { Form, FormLabel, Table, Button, Modal, Alert } from 'react-bootstrap'
import UserPanel from '../../layout/userProfile'
import {
    getAccessGroups,
    addGroup,
    getGroupMembers,
    removeMember,
    setAccess,
    resetAccessGroupsData,
} from '../../store/accessMgmt.slice'
import MobileHeader from '../../layout/mobileHeader'
import HeaderPanel from '../../layout/header'

const AccessMgmtPanelContainer = styled.div`
    display: flex;
    height: ${(props) => props.theme.size.mainContainerHeight};
    width: ${(props) => props.theme.size.mainContainerWidth};
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: space-between;
    @media screen and (min-width: 1200px) {
        overflow-x: hidden;
    }
`

const AccessMgmtHorizontalContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: space-between;
`
const AccessMgmtBodyContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: center;
    background-color: ${(props) => props.theme.color.background.defaultGrey};
    padding-bottom: 10rem;
`

const AccessMgmtBodyContainerReposition = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    margin-left: 2rem;
`

const PageLinkPanelPanelWrapper = styled.div`
    display: flex;
    margin-left: 2rem;
    @media screen and (max-width: 500px) {
        display: none;
    }
`
const MobileHeaderWrapper = styled.div`
    display: flex;
    display: none;
    @media screen and (max-width: 500px) {
        display: block;
    }
`

const AccessMgmtPanelWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: flex-start;
    margin-top: 2rem;
`
const PageTitleTextLabel = styled(FormLabel)`
    ${(props) => props.theme.typography.xxxLarge}
    text-align: left;
    margin-bottom: 3rem;
    font-weight: 500;
    font-size: 2.25rem;
`

const RecipeContainer = styled.div`
    background: #ffffff;
    box-shadow: 0px 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
    border-radius: 1.5rem;
    padding: 2rem;
    margin: 0 2rem 20rem 0;
    max-width: 57rem;
`

const TagContainer = styled.div`
    box-sizing: border-box;
    display: inline-block;
    white-space: nowrap;
    color: white;
    background: #108ee9;
    box-shadow: 0px 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    padding: 0 7px;
    margin: 0.5rem 0.5rem 0.5rem;
`

const AddButton = styled(Button)`
    width: 7em;
    margin-bottom: 1em;
    margin-right: 1em;
`

const ModalButton = styled(Button)`
    margin-right: 1em;
`

const ButtonRow = styled.div`
    display: inline-block;
`

const CloseX = styled.span`
    cursor: pointer;
`

export interface ITagProps {
    name: string
    userId?: string
    roles?: string[]
    cancelOption?: boolean
}

export interface GroupObj {
    email: string
    group?: string
    roles: string[]
}

const AccessMgmtContainer: React.FC = () => {
    const dispatch = useAppDispatch()
    const [showAddGroupModal, setShowAddGroupModal] = useState(false)
    const [showSetAccessModal, setShowSetAccessModal] = useState(false)
    const [showAdminModal, setShowAdminModal] = useState(false)
    const [showToast, setShowToast] = useState(false)
    const [isError, setIsError] = useState(false)
    const [message, setMessage] = useState('')
    const [validated, setValidated] = useState(false)
    const [selectedGroup, setSelectedGroup] = useState('')

    const accessGroups = useAppSelector<any>(
        (state: any) => state.accessGroups.data
    )
    const groupMemberData = useAppSelector<any>(
        (state: any) => state.accessGroups.groupMemberData
    )
    const addGroupSuccess = useAppSelector<any>(
        (state: any) => state.accessGroups.addGroupData
    )
    const setAccessSuccess = useAppSelector<any>(
        (state: any) => state.accessGroups.setAccessData
    )
    const removeMemberSuccess = useAppSelector<any>(
        (state: any) => state.accessGroups.removeMemberData
    )
    const error = useAppSelector<any>((state: any) => state.accessGroups.error)

    const Tag: React.FC<ITagProps> = ({
        name,
        userId,
        roles,
        cancelOption,
    }: ITagProps) => {
        return (
            <TagContainer>
                {name}{' '}
                {cancelOption ? (
                    <CloseX
                        onClick={() => {
                            const payload = {
                                email: name,
                                userId: userId,
                                roles,
                                group: selectedGroup,
                            }
                            dispatch(removeMember(payload))
                        }}
                    >
                        X
                    </CloseX>
                ) : (
                    ''
                )}
            </TagContainer>
        )
    }

    useEffect(() => {
        dispatch(getAccessGroups())
    }, [])

    useEffect(() => {
        setIsError(false)
        setValidated(false)

        if (addGroupSuccess) {
            setMessage('Successfully added group!')
            setTimeout(() => {
                handleClose()
                dispatch(resetAccessGroupsData(''))
            }, 3000)
        } else if (setAccessSuccess) {
            setMessage('Successfully updated access!')
            setTimeout(() => {
                handleClose()
                dispatch(resetAccessGroupsData(''))
            }, 3000)
        } else if (removeMemberSuccess) {
            dispatch(getGroupMembers(selectedGroup))
            setTimeout(
                () => dispatch(resetAccessGroupsData('groupMemberData')),
                3000
            )
            setMessage('Successfully removed member from group')
        }
        dispatch(getAccessGroups())
    }, [addGroupSuccess, setAccessSuccess, removeMemberSuccess])

    useEffect(() => {
        setValidated(false)
        if (error) {
            setIsError(true)
            setMessage('Error updating')
            setShowToast(true)
            setTimeout(() => dispatch(resetAccessGroupsData('error')), 3000)
        }
    }, [error])

    const handleClose = () => {
        setShowAddGroupModal(false)
        setShowSetAccessModal(false)
        setShowAdminModal(false)
        setIsError(false)
    }
    const saveGroup = (event: any) => {
        event.preventDefault()
        const form = event.currentTarget

        if (form.checkValidity() === false) {
            event.preventDefault()
            event.stopPropagation()
            setValidated(false)
            return
        }

        // handleClose()
        setValidated(true)
        const formData = new FormData(form),
            formDataObj = Object.fromEntries(formData.entries())

        dispatch(addGroup(formDataObj.name as string))
    }

    const saveAccess = (event: any) => {
        event.preventDefault()
        const form = event.currentTarget

        if (form.checkValidity() === false) {
            event.preventDefault()
            event.stopPropagation()
            setValidated(false)
            return
        }

        // handleClose()
        setValidated(true)
        const formData = new FormData(form),
            formDataObj = Object.fromEntries(formData.entries())
        let groupObj: GroupObj = { email: '', group: '', roles: [] }
        groupObj.email = formDataObj.email as string
        groupObj.group = formDataObj.group as string
        if (formDataObj['read']) groupObj.roles.push('reader')
        if (formDataObj['write']) groupObj.roles.push('writer')
        if (formDataObj['admin']) groupObj.roles.push('admin')

        dispatch(setAccess(groupObj))
    }

    return (
        <AccessMgmtPanelContainer>
            <HeaderPanel />
            <AccessMgmtHorizontalContainer>
                <AccessMgmtBodyContainer>
                    <AccessMgmtBodyContainerReposition>
                        <UserPanel></UserPanel>
                        <PageTitleTextLabel data-testid="page-title-label">
                            Access Group Management
                        </PageTitleTextLabel>
                        <ButtonRow>
                            <AddButton
                                variant="primary"
                                onClick={() => setShowAddGroupModal(true)}
                                data-testid="add-button"
                            >
                                Add Group
                            </AddButton>
                            <AddButton
                                variant="primary"
                                onClick={() => setShowSetAccessModal(true)}
                                data-testid="set-access-button"
                            >
                                Set Access
                            </AddButton>
                            {accessGroups?.admin ? (
                                <AddButton
                                    variant="primary"
                                    onClick={() => setShowAdminModal(true)}
                                    data-testid="admin-button"
                                >
                                    Admin
                                </AddButton>
                            ) : null}
                        </ButtonRow>
                        <RecipeContainer>
                            {accessGroups &&
                                Object.entries(accessGroups).length !== 0 && (
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th data-testid="access-level-header">
                                                    Access Level
                                                </th>
                                                <th data-testid="groups-header">
                                                    Groups
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {accessGroups['admin'] ? (
                                                <tr>
                                                    <td data-testid="admin-group">
                                                        Admin
                                                    </td>
                                                    <td data-testid="admin-group-data">
                                                        {accessGroups[
                                                            'admin'
                                                        ].map((d: any) => (
                                                            <Tag
                                                                key={d}
                                                                name={d}
                                                            />
                                                        ))}
                                                    </td>
                                                </tr>
                                            ) : (
                                                <tr></tr>
                                            )}
                                            {accessGroups['reader'] ? (
                                                <tr>
                                                    <td data-testid="read-group">
                                                        Read
                                                    </td>
                                                    <td data-testid="read-group-data">
                                                        {accessGroups[
                                                            'reader'
                                                        ].map((d: any) => (
                                                            <Tag
                                                                key={d}
                                                                name={d}
                                                            />
                                                        ))}
                                                    </td>
                                                </tr>
                                            ) : (
                                                <tr></tr>
                                            )}
                                            {accessGroups['writer'] ? (
                                                <tr>
                                                    <td data-testid="write-group">
                                                        Write
                                                    </td>
                                                    <td data-testid="write-group-data">
                                                        {accessGroups[
                                                            'writer'
                                                        ].map((d: any) => (
                                                            <Tag
                                                                key={d}
                                                                name={d}
                                                            />
                                                        ))}
                                                    </td>
                                                </tr>
                                            ) : (
                                                <tr></tr>
                                            )}
                                        </tbody>
                                    </Table>
                                )}
                        </RecipeContainer>
                        <AccessMgmtPanelWrapper></AccessMgmtPanelWrapper>
                    </AccessMgmtBodyContainerReposition>
                </AccessMgmtBodyContainer>
            </AccessMgmtHorizontalContainer>
            <FooterPanel></FooterPanel>
            <Modal show={showAddGroupModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Group</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form
                        noValidate
                        validated={validated}
                        onSubmit={saveGroup}
                        id="addGroupForm"
                    >
                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>Group name</Form.Label>
                            <Form.Control
                                placeholder="Group name"
                                name="name"
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter a group name.
                            </Form.Control.Feedback>
                            <Alert
                                variant="success"
                                show={addGroupSuccess ? true : false}
                            >
                                {message}
                            </Alert>
                            <Alert variant="danger" show={error ? true : false}>
                                {message}
                            </Alert>
                        </Form.Group>
                        <Form.Group
                            controlId="buttons"
                            style={{ float: 'right' }}
                        >
                            <ModalButton variant="primary" type="submit">
                                Save Changes
                            </ModalButton>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>

            <Modal show={showSetAccessModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Set Access</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form
                        noValidate
                        validated={validated}
                        onSubmit={saveAccess}
                        id="setAccessForm"
                    >
                        <Form.Group className="mb-3" controlId="email">
                            <Form.Label>User email</Form.Label>
                            <Form.Control
                                placeholder="Email"
                                name="email"
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter a valid email.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="group">
                            <Form.Label>Group</Form.Label>
                            <Form.Control
                                placeholder="Group"
                                name="group"
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter a group name.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="permissions">
                            <Form.Label>Permissions</Form.Label>
                            <Form.Check
                                type="checkbox"
                                label="Read"
                                name="read"
                            />
                            <Form.Check
                                type="checkbox"
                                label="Write"
                                name="write"
                            />
                            <Form.Check
                                type="checkbox"
                                label="Admin"
                                name="admin"
                            />
                        </Form.Group>
                        <Alert
                            variant="success"
                            show={setAccessSuccess ? true : false}
                        >
                            {message}
                        </Alert>
                        <Alert variant="danger" show={error ? true : false}>
                            {message}
                        </Alert>
                        <Form.Group
                            controlId="buttons"
                            style={{ float: 'right' }}
                        >
                            <ModalButton variant="primary" type="submit">
                                Save Changes
                            </ModalButton>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>

            <Modal show={showAdminModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Admin</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3" controlId="group">
                        <Form.Label>Group</Form.Label>
                        <Form.Select
                            aria-label="admin groups"
                            onChange={(evt) => {
                                const group = evt.target.value
                                if (!group) return
                                setSelectedGroup(group)
                                dispatch(getGroupMembers(group))
                            }}
                        >
                            <option value="">Select a group</option>
                            {accessGroups?.admin?.map((group: string) => (
                                <option value={group}>{group}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="group">
                        <Form.Label>Members</Form.Label>
                        {groupMemberData ? (
                            <div>
                                {groupMemberData?.users.map((d: any) => (
                                    <Tag
                                        key={d.email}
                                        name={d.email}
                                        userId={d.userId}
                                        roles={d.role}
                                        cancelOption={true}
                                    />
                                ))}
                            </div>
                        ) : null}
                    </Form.Group>
                    <Alert
                        variant="success"
                        show={removeMemberSuccess ? true : false}
                    >
                        {message}
                    </Alert>
                    <Alert variant="danger" show={error ? true : false}>
                        {message}
                    </Alert>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>
        </AccessMgmtPanelContainer>
    )
}

export default AccessMgmtContainer
