import DashboardCard from '../shared/cards/DashboardCard'
import { Box, Grid, Typography } from '@mui/material'
import SteppedAreaChart, {
    SteppedAreaChartData,
} from '../shared/charts/SteppedAreaChart'
import React, { useEffect, useState } from 'react'
import ColumnChart, { ColumnChartData } from '../shared/charts/ColumnChart'
import { useAppSelector } from '../../../config/hooks'
import { colors } from '../../../utils/colors'

const usersEngagementChartTitleSx = {
    marginLeft: '1rem',
    marginBottom: '0.5rem',
}

const usersEngagementProperties = [
    'previousFrom',
    'previousTo',
    'previousCount',
    'from',
    'to',
    'currentCount',
    'domainDistribution',
]

const usersEngagementChartDataHeaders: [
    string,
    string,
    { role: string },
    { role: string }
] = ['', '# Users', { role: 'style' }, { role: 'annotation' }]

const engagedUsersChartDataHeaders: [string, string, { role: string }] = [
    'Domain',
    '# Users',
    { role: 'annotation' },
]

const disengagedUsersChartDataHeaders: [string, string, { role: string }] = [
    'Domain',
    '# Users',
    { role: 'annotation' },
]

const UsersEngagementCard = () => {
    const engagedUsersData = useAppSelector<any>(
        (state) => state.getEngagedUsersResult.data
    )
    const engagedUsersLoading = useAppSelector(
        (state) => state.getEngagedUsersResult.loading
    )
    const disengagedUsersData = useAppSelector<any>(
        (state) => state.getDisengagedUsersResult.data
    )
    const disengagedUsersLoading = useAppSelector(
        (state) => state.getDisengagedUsersResult.loading
    )

    const [usersEngagementChartData, setUsersEngagementChartData] =
        useState<SteppedAreaChartData>([usersEngagementChartDataHeaders])
    const [disengagedUsersChartData, setDisengagedUsersChartData] =
        useState<ColumnChartData>([engagedUsersChartDataHeaders])
    const [engagedUsersChartData, setEngagedUsersChartData] =
        useState<ColumnChartData>([disengagedUsersChartDataHeaders])

    useEffect(() => {
        if (
            disengagedUsersData !== null &&
            usersEngagementProperties.every(
                (prop) => ![null, undefined].includes(disengagedUsersData[prop])
            )
        ) {
            const previousEngagement: [string, number, string, string] = [
                `${disengagedUsersData.previousFrom.split('T')[0]} to ${
                    disengagedUsersData.previousTo.split('T')[0]
                }`,
                Number(disengagedUsersData.previousCount),
                colors.geode,
                disengagedUsersData.previousCount.toString(),
            ]
            const currentEngagement: [string, number, string, string] = [
                `${disengagedUsersData.from.split('T')[0]} to ${
                    disengagedUsersData.to.split('T')[0]
                }`,
                Number(disengagedUsersData.currentCount),
                colors.blueSteel,
                disengagedUsersData.currentCount.toString(),
            ]
            setUsersEngagementChartData([
                usersEngagementChartDataHeaders,
                previousEngagement,
                currentEngagement,
            ])

            const distribution = Object.entries(
                disengagedUsersData?.domainDistribution as {
                    [key: string]: number
                }
            ).map(([key, value]) => [key, value, value])
            if (distribution.length > 0) {
                setDisengagedUsersChartData([
                    disengagedUsersChartDataHeaders,
                    ...distribution,
                ])
            } else {
                setDisengagedUsersChartData([disengagedUsersChartDataHeaders])
            }
        } else {
            setUsersEngagementChartData([usersEngagementChartDataHeaders])
            setDisengagedUsersChartData([disengagedUsersChartDataHeaders])
        }
    }, [disengagedUsersData])

    useEffect(() => {
        if (![null, undefined].includes(engagedUsersData?.domainDistribution)) {
            const distribution = Object.entries(
                engagedUsersData?.domainDistribution as {
                    [key: string]: number
                }
            ).map(([key, value]) => [key, value, value])

            setEngagedUsersChartData([
                engagedUsersChartDataHeaders,
                ...distribution,
            ])
        } else {
            setEngagedUsersChartData([engagedUsersChartDataHeaders])
        }
    }, [engagedUsersData])

    return (
        <DashboardCard title="Users Engagement">
            <Grid item xs={12} container columnSpacing={2}>
                <Grid item xs={12} md={6}>
                    <SteppedAreaChart
                        chartData={usersEngagementChartData}
                        dataLoadingState={[
                            engagedUsersLoading,
                            disengagedUsersLoading,
                        ]}
                        yAxisTitle="# Users"
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    container
                    direction="column"
                    justifyContent="space-between"
                >
                    <Box>
                        <Typography
                            variant="h6"
                            sx={usersEngagementChartTitleSx}
                        >
                            Engaged Users
                        </Typography>
                        <ColumnChart
                            chartData={engagedUsersChartData}
                            dataLoadingState={engagedUsersLoading}
                            height={150}
                        />
                    </Box>
                    <Box>
                        <Typography
                            variant="h6"
                            sx={usersEngagementChartTitleSx}
                        >
                            Disengaged Users
                        </Typography>
                        <ColumnChart
                            chartData={disengagedUsersChartData}
                            dataLoadingState={disengagedUsersLoading}
                            height={150}
                        />
                    </Box>
                </Grid>
            </Grid>
        </DashboardCard>
    )
}

export default UsersEngagementCard
