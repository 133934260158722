import { FormEvent, FunctionComponent, useCallback, useState } from 'react'
import styled from 'styled-components'
import { Tabs, Tab, FormLabel, Alert, Form, Spinner } from 'react-bootstrap'
import { LoadingState } from '../../models/loadingState'
import BenchamrkCard from './benchmarkCard'
import { WorkloadSearchResponse } from '../../models/benchmarkSearchResponse'
import { useAppDispatch, useAppSelector } from '../../config/hooks'
import { BenchmarkSearchRequest } from '../../models/benchmarkSearchRequest'
import { BenchmarkCardPanelLogic } from './benchmarkCardPanel.hook'
import Pagination from '../pagination/pagination'
import NoResultFoundPanel from './noResultFound'

const BenchmarkPanelContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0.5rem;
    margin: 3% 5%;
`
const BenchmarkResultWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
`
const BenchmarkResultComponent = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
`
const TabsContainer = styled.div`
    width: 100%;
`
const StyledTab = styled(Tab)`
    display: table-cell;
    color: var(--text-placeholder, #6a6d75);
`
const StyledTabLabel = styled.div`
    min-width: 100px;
    max-width: 150px;
    height: 55px;
    padding: 0.5rem;
    margin: 0 0.1rem;
    text-align: center;
    font-family: Intel Clear;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 112.5% */
    border-radius: 6px 6px 0px 0px;
    box-shadow: 1px 0px 0px 0px rgba(43, 44, 48, 0.3) inset,
        -1px 0px 0px 0px rgba(43, 44, 48, 0.3) inset,
        0px 1px 0px 0px rgba(43, 44, 48, 0.3) inset;
`
const ExploreSolutionsComponent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 3rem;
    background: var(--classic-blue-classic-blue-shade-1, #00377c);
`
const ExploreSolutionsLabel = styled(FormLabel)`
    color: var(--white-white, #fff);
    font-family: IntelOne Display;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 45px; /* 187.5% */
`
const PaginationComponent = styled.div`
    display: flex;
    flex-grow: 1;
    margin-top: 1rem;
    flex-direction: column;
    justify-content: center;
`
const PaginationRow = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: space-between;
`
const PaginationPanelContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: right;
    padding-right: 5%;
    @media screen and (max-width: 700px) {
        justify-content: center;
    }
`
const PageDetailsComponent = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: center;
`

const ShowingPageContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: left;
    padding-left: 7%;
    margin-top: 1rem;
    width: 50%;
    vertical-align: middle;
`
const PageCountContainerDiv = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: right;
    padding-right: 7.7%;
    margin-top: 1rem;
    width: 50%;
    vertical-align: middle;
`

const AlertComponent = styled(Alert)`
    display: flex;
    margin-top: 1rem;
    justify-content: center;
`
const PageCountSelect = styled(Form.Select)`
    width: 5rem;
    height: 2rem;
    margin-left: 0.5rem;
`
const ErrorContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0.2rem 0.5rem;
`
const SpinnerDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5rem;
    width: 100%;
`

export interface IBenchmarkCardPanelProps {
    getWorkloads: (benchmarkSearchRequest: BenchmarkSearchRequest) => void
    getWorkloadsLoading: LoadingState
    getWorkloadsError: any
    getWorkloadsResult: WorkloadSearchResponse
    urlNavigation?: string
    onTabClickCallback: (
        selectedValue: string,
        event: FormEvent<HTMLElement>
    ) => void
    paginationPageClickCallback: (offset: number, pageCount: number) => void
    pageCountChangeCallback: (offset: number, pageCount: number) => void
}

const BenchmarkCardPanel: FunctionComponent<IBenchmarkCardPanelProps> = ({
    getWorkloads,
    getWorkloadsLoading,
    getWorkloadsError,
    getWorkloadsResult,
    urlNavigation,
    onTabClickCallback,
    paginationPageClickCallback,
    pageCountChangeCallback,
}: IBenchmarkCardPanelProps) => {
    var errorText = null
    if (getWorkloadsError) {
        errorText =
            getWorkloadsError?.message.indexOf('404') > -1
                ? 'Workload ID not found.'
                : 'We are experiencing issues retrieving the data.'
    }
    const {
        currPage,
        afterPageClicked,
        handlePageCount,
        pageCount,
        onTabClick,
        activeTabKey,
        setActiveTabKey,
    } = BenchmarkCardPanelLogic({
        getWorkloads,
        getWorkloadsLoading,
        getWorkloadsError,
        getWorkloadsResult,
        onTabClickCallback,
        paginationPageClickCallback,
        pageCountChangeCallback,
    } as IBenchmarkCardPanelProps)

    const authPermissionsResult = useAppSelector<any>(
        (state: any) => state.authPermissionsResult.data
    )
    let isDataValidator = false
    if (
        authPermissionsResult &&
        authPermissionsResult?.featureFlags &&
        authPermissionsResult?.featureFlags?.includes('isDataValidator')
    ) {
        isDataValidator = true
    }

    const totalPage = getWorkloadsResult?.pageInfo.total
        ? getWorkloadsResult?.pageInfo.total
        : -1
    const nextPage = getWorkloadsResult?.pageInfo.next
        ? getWorkloadsResult?.pageInfo.next
        : -1

    const allAssetCount = getWorkloadsResult?.workloadAssetsCounts
        ? getWorkloadsResult?.workloadAssetsCounts.all
        : 0
    const cloudAssetCount = getWorkloadsResult?.workloadAssetsCounts
        ? getWorkloadsResult?.workloadAssetsCounts.cloud
        : 0
    const customerAssetCount = getWorkloadsResult?.workloadAssetsCounts
        ? getWorkloadsResult?.workloadAssetsCounts.customerStories
        : 0

    const onPremAssetCount = getWorkloadsResult?.workloadAssetsCounts
        ? getWorkloadsResult?.workloadAssetsCounts.onPrem
        : 0

    const optAssetCount = getWorkloadsResult?.workloadAssetsCounts
        ? getWorkloadsResult?.workloadAssetsCounts.optimizations
        : 0
    const partnerSolAssetCount = getWorkloadsResult?.workloadAssetsCounts
        ? getWorkloadsResult?.workloadAssetsCounts.partnerSolutions
        : 0

    let isNoBenchmarkData: boolean = false
    if (
        getWorkloadsResult?.workloadGroups == undefined ||
        getWorkloadsResult?.workloadGroups == null ||
        getWorkloadsResult?.workloadGroups.length == 0 ||
        getWorkloadsResult?.workloadGroups[0].uuid == null
    ) {
        isNoBenchmarkData = true
    }

    const isSalesHomePage =
        location.pathname.indexOf('/salesview/home') > -1 ? true : false
    const isBenchmarksPage =
        location.pathname.indexOf('/benchmarks') > -1 ? true : false

    let pageCountContainer = (
        <PageCountContainerDiv>
            {totalPage > 10 && totalPage >= nextPage && pageCount > 0 ? (
                <span data-testid="search-page-count-label">
                    {' '}
                    Page {currPage} of {Math.ceil(totalPage / pageCount)}
                </span>
            ) : (
                ''
            )}
        </PageCountContainerDiv>
    )

    let resultCountContainer = (
        <ShowingPageContainer>
            {totalPage > 0 ? (
                <span data-testid="search-result-count-label">
                    {' '}
                    Showing {(currPage - 1) * pageCount + 1} to{' '}
                    {currPage * pageCount < totalPage
                        ? currPage * pageCount
                        : totalPage}{' '}
                    of {totalPage} results{' '}
                </span>
            ) : (
                ''
            )}
            {totalPage > 10 && totalPage >= nextPage ? (
                <PageCountSelect
                    size="sm"
                    onChange={handlePageCount}
                    value={pageCount}
                >
                    <option value={10}>10</option>
                    {totalPage >= 10 ? <option value={20}>20</option> : ''}
                    {totalPage > 20 ? <option value={50}>{50}</option> : ''}
                    {totalPage >= 50 ? <option value={100}>{100}</option> : ''}
                </PageCountSelect>
            ) : (
                ''
            )}
        </ShowingPageContainer>
    )

    const workloadList = getWorkloadsResult?.workloadGroups
        ? [...getWorkloadsResult.workloadGroups]
        : undefined

    const workloadCards = workloadList?.map((data) => {
        return (
            <>
                <BenchamrkCard
                    key={data.uuid}
                    workloadData={data}
                    urlNavigation={urlNavigation}
                ></BenchamrkCard>
            </>
        )
    })

    const NoBenchmarkAvailable = (
        <div>{isNoBenchmarkData ? NoResultFoundPanel() : ''}</div>
    )
    const renderErrorContainer = (
        <AlertComponent
            style={{ width: '75%' }}
            variant="danger"
            show={getWorkloadsError ? true : false}
        >
            {errorText}
        </AlertComponent>
    )

    const workloadBenchmarkCardsRender = (
        <BenchmarkPanelContainer>
            {getWorkloadsLoading !== LoadingState.Pending ? (
                <BenchmarkResultWrapper>
                    <PaginationRow>
                        <PageDetailsComponent>
                            {totalPage > 10 && totalPage >= nextPage
                                ? resultCountContainer
                                : ''}
                            {/* {pageCountContainer} */}
                        </PageDetailsComponent>

                        <PaginationComponent>
                            <PaginationPanelContainer>
                                {totalPage > 10 && totalPage >= nextPage ? (
                                    <>
                                        <Pagination
                                            totalPage={Math.ceil(
                                                totalPage / pageCount
                                            )}
                                            currentPage={currPage}
                                            onPageClicked={(ele: any) => {
                                                afterPageClicked(ele)
                                            }}
                                        />
                                    </>
                                ) : (
                                    <div></div>
                                )}
                            </PaginationPanelContainer>
                        </PaginationComponent>
                    </PaginationRow>
                    <BenchmarkResultComponent>
                        {workloadCards}
                        {NoBenchmarkAvailable}
                    </BenchmarkResultComponent>
                </BenchmarkResultWrapper>
            ) : (
                <SpinnerDiv>
                    <Spinner animation="border" variant="dark">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </SpinnerDiv>
            )}
        </BenchmarkPanelContainer>
    )

    return (
        <div>
            {isSalesHomePage ? (
                <div>
                    <ExploreSolutionsComponent>
                        <ExploreSolutionsLabel>
                            Explore Benchmarks, Optimizations, Solutions, & Case
                            Studies
                        </ExploreSolutionsLabel>
                    </ExploreSolutionsComponent>
                    <div>
                        <TabsContainer>
                            <Tabs
                                variant="tabs"
                                id="tabs"
                                className="mb-3"
                                style={{
                                    width: '100%',
                                    justifyContent: 'center',
                                    background: '#fff',
                                    paddingTop: '1rem',
                                    borderBottom: '2px solid #c9cace',
                                }}
                                defaultActiveKey={activeTabKey}
                                onSelect={(k: any, evt: any) =>
                                    onTabClick(k, evt)
                                }
                            >
                                <StyledTab
                                    eventKey="All"
                                    title={
                                        <StyledTabLabel tabIndex={0}>
                                            All <br />
                                            {`(${allAssetCount})`}
                                        </StyledTabLabel>
                                    }
                                >
                                    {workloadBenchmarkCardsRender}
                                </StyledTab>
                                <StyledTab
                                    eventKey="On-Prem"
                                    title={
                                        <StyledTabLabel tabIndex={0}>
                                            On-Prem Benchmarks
                                            {` (${onPremAssetCount})`}
                                        </StyledTabLabel>
                                    }
                                >
                                    {workloadBenchmarkCardsRender}
                                </StyledTab>
                                <StyledTab
                                    eventKey="Cloud"
                                    title={
                                        <StyledTabLabel tabIndex={0}>
                                            Cloud Benchmarks
                                            {` (${cloudAssetCount})`}
                                        </StyledTabLabel>
                                    }
                                >
                                    {workloadBenchmarkCardsRender}
                                </StyledTab>
                                {false ? (
                                    <StyledTab
                                        eventKey="Software Optimizations"
                                        title={
                                            <StyledTabLabel tabIndex={0}>
                                                SW
                                                <br /> Optimizations
                                                {` (${optAssetCount})`}
                                            </StyledTabLabel>
                                        }
                                    >
                                        {workloadBenchmarkCardsRender}
                                    </StyledTab>
                                ) : (
                                    ''
                                )}
                                {partnerSolAssetCount > 0 ? (
                                    <StyledTab
                                        eventKey="Partner Solutions"
                                        title={
                                            <StyledTabLabel tabIndex={0}>
                                                Partner <br />
                                                Solutions
                                                {` (${partnerSolAssetCount})`}
                                            </StyledTabLabel>
                                        }
                                    >
                                        {workloadBenchmarkCardsRender}
                                    </StyledTab>
                                ) : (
                                    ''
                                )}
                                <StyledTab
                                    eventKey="Customer Success Stories"
                                    title={
                                        <StyledTabLabel tabIndex={0}>
                                            Customer <br /> Case Studies
                                            {` (${customerAssetCount})`}
                                        </StyledTabLabel>
                                    }
                                >
                                    {workloadBenchmarkCardsRender}
                                </StyledTab>
                            </Tabs>
                        </TabsContainer>
                        <ErrorContainer>{renderErrorContainer}</ErrorContainer>
                    </div>
                </div>
            ) : isBenchmarksPage ? (
                <div>
                    <TabsContainer
                        style={{
                            width: '100%',
                            justifyContent: 'center',
                            backgroundColor: 'rgb(229, 229, 229)',
                            paddingTop: '1rem',
                            borderTop: '2px solid #c9cace',
                        }}
                    >
                        {workloadBenchmarkCardsRender}
                    </TabsContainer>
                    <ErrorContainer>{renderErrorContainer}</ErrorContainer>
                </div>
            ) : (
                ''
            )}
        </div>
    )
}

export default BenchmarkCardPanel
