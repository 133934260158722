import { API_SERVICE_URL } from '../config/service'
import { CheckoutRequest } from '../models/checkoutRequest'
import { ShoppingCartRequest } from '../models/shoppingCartRequest'
import { UpdateShoppingCartRequest } from '../models/updateShoppingCartRequest'

import api from './auth/client'

const serviceConfig = require('../config/service.json')

class ShoppingCartService {
    getCartItems(shoppingCartRequest: ShoppingCartRequest) {
        var url =
            API_SERVICE_URL +
            serviceConfig.getItemsInCart +
            '?' +
            shoppingCartRequest.tags.map((tag) => 'tag=' + tag).join('&')
        return api.get(url)
    }

    updateCartItems(updateShoppingCartRequest: UpdateShoppingCartRequest) {
        var url = API_SERVICE_URL + serviceConfig.updateItemsToCart
        return api.post(url, updateShoppingCartRequest)
    }

    preCheckoutItems() {
        var url = API_SERVICE_URL + serviceConfig.cartPreCheckout
        return api.post(url, {})
    }

    checkoutItems(checkoutRequest: CheckoutRequest) {
        var url = API_SERVICE_URL + serviceConfig.cartCheckout
        return api.post(url, checkoutRequest)
    }
}

export default new ShoppingCartService()
