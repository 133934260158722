/************************************************
 * Copyright (C) 2021 Intel Corporation
 ************************************************/
import styled from 'styled-components'
import { FunctionComponent, Dispatch, useEffect } from 'react'
import { connect } from 'react-redux'
import { LoadingState } from '../../models/loadingState'
import { CreateOrgContainerLogic } from './createOrgContainer.hook'
import CreateOrgPanel from '../../components/createOrgPanel/createOrgPanel'
import { CreateOrgRequest } from '../../models/createOrgRequest'
import { createOrg } from '../../store/createOrg.slice'
import { AcceptInvitesResponse } from '../../models/acceptInvitesResponse'
import { AuthToken } from '../../models/authToken'
import { acceptInvites } from '../../store/invitation.slice'
import { useLocation } from 'react-router-dom'
import { AcceptInvitesRequest } from '../../models/acceptInvitesRequest'
import { clearSession } from '../../store/authLogin.slice'

const CreateOrgPanelContainer = styled.div`
    display: flex;
    height: ${(props) => props.theme.size.mainContainerHeight};
    width: ${(props) => props.theme.size.mainContainerWidth};
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: space-between;
    @media screen and (min-width: 1200px) {
        overflow-x: hidden;
    }
`
const CreateOrgHorizontalContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: space-between;
`
const CreateOrgBodyContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: center;
    background-color: #fff;
    padding-bottom: 2rem;
`

const CreateOrgBodyContainerReposition = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
`
const CreateOrgPanelWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: center;
`
const IntelLogoStyling = styled.div`
    display: flex;
    margin-left: 2rem;
    margin-top: 1rem;
    padding: 0.5rem;
    @media screen and (max-width: 500px) {
        margin-left: 35%;
    }
`
const HeaderContainer = styled.div`
    display: flex;
    background-color: #fff;
    padding-bottom: 2rem;
`

export interface ICreateOrgContainerProps {
    createOrg: typeof createOrg
    createOrgLoading: LoadingState
    createOrgResult: any
    createOrgError?: any
    acceptInvites: typeof acceptInvites
    acceptInvitesError: any
    acceptInvitesLoading: LoadingState
    acceptInvitesResult: AcceptInvitesResponse
    clearSession: (authToken: AuthToken | null) => void
}

const CreateOrgContainer: FunctionComponent<ICreateOrgContainerProps> & {
    defaultProps: Partial<ICreateOrgContainerProps>
} = ({
    createOrg,
    createOrgLoading,
    createOrgResult,
    createOrgError,
    acceptInvites,
    acceptInvitesError,
    acceptInvitesLoading,
    acceptInvitesResult,
    clearSession,
}: ICreateOrgContainerProps) => {
    const { onCreateOrgSubmit } = CreateOrgContainerLogic({
        createOrg,
    } as ICreateOrgContainerProps)

    let invitationKey = localStorage.getItem('invitationKey')

    return (
        <CreateOrgPanelContainer>
            <HeaderContainer>
                <IntelLogoStyling>
                    <img
                        src="/Intel_energyblue_logo.png"
                        height="65"
                        width="171"
                        alt="intel logo"
                    />
                </IntelLogoStyling>
            </HeaderContainer>
            <CreateOrgHorizontalContainer>
                <CreateOrgBodyContainer>
                    <CreateOrgBodyContainerReposition>
                        <CreateOrgPanelWrapper>
                            <CreateOrgPanel
                                onCreateOrgSubmit={onCreateOrgSubmit}
                                createOrgResponse={createOrgResult}
                                createOrgLoading={createOrgLoading}
                                createOrgError={createOrgError}
                                invitationKey={invitationKey}
                                acceptInvites={acceptInvites}
                                acceptInvitesError={acceptInvitesError}
                                acceptInvitesLoading={acceptInvitesLoading}
                                acceptInvitesResult={acceptInvitesResult}
                                clearSession={clearSession}
                            />
                        </CreateOrgPanelWrapper>
                    </CreateOrgBodyContainerReposition>
                </CreateOrgBodyContainer>
            </CreateOrgHorizontalContainer>
        </CreateOrgPanelContainer>
    )
}

CreateOrgContainer.defaultProps = {}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        createOrg: (createOrgRequest: CreateOrgRequest) =>
            dispatch(createOrg(createOrgRequest)),

        acceptInvites: (acceptInvitesRequest: AcceptInvitesRequest) =>
            dispatch(acceptInvites(acceptInvitesRequest)),

        clearSession: (authToken: AuthToken | null) =>
            dispatch(clearSession(authToken)),
    }
}

const mapStateToProps = (state: any) => {
    return {
        createOrgError: state.createOrgResult.error,
        createOrgLoading: state.createOrgResult.loading,
        createOrgResult: state.createOrgResult.data,

        acceptInvitesError: state.acceptInvitesResult.error,
        acceptInvitesLoading: state.acceptInvitesResult.loading,
        acceptInvitesResult: state.acceptInvitesResult.data,
    }
}

type StateToPropsType = ReturnType<typeof mapStateToProps>
type DispatchToPropsType = typeof mapDispatchToProps

export default connect<StateToPropsType, DispatchToPropsType>(
    mapStateToProps,
    mapDispatchToProps
)(CreateOrgContainer)
