import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { LoadingState } from '../models/loadingState'
import DiagnosticsService from '../services/diagnosticsService'

export interface IReportState {
    data: null
    diagnosticData: null
    uploadError: null
    downloadData: null
    deleteData: null
    userGroupsData: null
    processData: null
    loading: LoadingState
    error: null
    downloadScraperData: null
    downloadScraperError: null
}

const initialState: IReportState = {
    data: null,
    diagnosticData: null,
    uploadError: null,
    downloadData: null,
    deleteData: null,
    userGroupsData: null,
    processData: null,
    loading: LoadingState.Idle,
    error: null,
    downloadScraperData: null,
    downloadScraperError: null,
}

export const getDiagnostics = createAsyncThunk(
    'diagnostics/getDiagnostics',
    async () => {
        return await DiagnosticsService.getDiagnostics()
    }
)
export const getUserGroups = createAsyncThunk(
    'diagnostics/getUserGroups',
    async () => {
        return await DiagnosticsService.getUserGroups()
    }
)

export const submitDiagnostic = createAsyncThunk(
    'diagnostics/submitDiagnostics',
    async (diagnostic: any) =>
        await DiagnosticsService.submitDiagnostic(diagnostic)
)

export const downloadDiagnostics = createAsyncThunk(
    'diagnostics/downloadDiagnostics',
    async (uuid: string) => await DiagnosticsService.downloadDiagnostic(uuid)
)

export const deleteDiagnostic = createAsyncThunk(
    'diagnostics/deleteDiagnostics',
    async (uuid: string) => await DiagnosticsService.deleteDiagnostic(uuid)
)
export const processDiagnostic = createAsyncThunk(
    'diagnostics/processDiagnostics',
    async (uuid: string) => await DiagnosticsService.processDiagnostic(uuid)
)
export const downloadScraperScript = createAsyncThunk(
    'diagnostics/downloadScraperScript',
    async (cspName: string) =>
        await DiagnosticsService.downloadScraperScript(cspName)
)

const diagnosticsSubmitSlice = createSlice({
    name: 'diagnosticsSubmit',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(submitDiagnostic.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(submitDiagnostic.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.diagnosticData = action.payload.data
                }
            })
            .addCase(submitDiagnostic.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
            .addCase(downloadScraperScript.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(
                downloadScraperScript.fulfilled,
                (state, action: any) => {
                    //console.log(state, action, state.loading)
                    if (state.loading === LoadingState.Pending) {
                        state.loading = LoadingState.Idle
                        state.downloadScraperError = null
                        state.downloadScraperData = action.payload.data
                    }
                }
            )
            .addCase(
                downloadDiagnostics.rejected,
                (state, action: any) => {
                    if (state.loading === LoadingState.Pending) {
                        state.loading = LoadingState.Idle
                        state.downloadScraperError = action.error
                    }
                }
            )
    },
})

// Then, handle actions in your reducers:
const diagnosticsSlice = createSlice({
    name: 'diagnostics',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getDiagnostics.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(getDiagnostics.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload.data
                    state.error = null
                }
            })
            .addCase(getDiagnostics.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })

            .addCase(downloadDiagnostics.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(
                downloadDiagnostics.fulfilled,
                (state, action: any) => {
                    console.log(state, action, state.loading)
                    if (state.loading === LoadingState.Pending) {
                        state.loading = LoadingState.Idle
                        state.downloadData = action.payload.data
                    }
                }
            )
            .addCase(
                downloadDiagnostics.rejected,
                (state, action: any) => {
                    if (state.loading === LoadingState.Pending) {
                        state.loading = LoadingState.Idle
                        state.error = action.error
                    }
                }
            )
            .addCase(deleteDiagnostic.fulfilled, (state, action: any) => {
                // if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.deleteData = action.payload.data
                // }
            })
            .addCase(deleteDiagnostic.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
            .addCase(getUserGroups.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(getUserGroups.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.userGroupsData = action.payload.data
                }
            })
            .addCase(getUserGroups.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
            .addCase(processDiagnostic.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(processDiagnostic.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.processData = action.payload.data
                }
            })
            .addCase(processDiagnostic.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})

export const diagnostics = diagnosticsSlice.reducer
export const diagnosticsSubmit = diagnosticsSubmitSlice.reducer
