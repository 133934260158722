/************************************************
 * Copyright (C) 2021 Intel Corporation
 ************************************************/
import styled from 'styled-components'
import { FunctionComponent, Dispatch, useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { NewOptimizationContainerLogic } from './newOptimizationContainer.hook'
import FooterPanel from '../../layout/footer'
import { submitNewOptimization } from '../../store/newOptimization.slice'
import { NewOptimizationRequest } from '../../models/newOptimizationRequest'
import { State } from '../../models/state'
import { NewOptimizationResponse } from '../../models/newOptimizationResponse'
import UserPanel from '../../layout/userProfile'
import NewOptimizationPanel from '../../components/newOptimizationPanel/newOptimizationPanel'
import StringUtils from '../../lib/stringUtils'
import { FormLabel } from 'react-bootstrap'
import HeaderPanel from '../../layout/header'

const NewOptimizationPanelContainer = styled.div`
    display: flex;
    height: ${(props) => props.theme.size.mainContainerHeight};
    width: ${(props) => props.theme.size.mainContainerWidth};
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: space-between;
    overflow-x: hidden;
    @media screen and (min-width: 64rem), screen and (min-height: 48rem) {
        /*overflow-x: auto;*/
    }
`

const NewOptimizationHorizontalContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: space-between;
`
const NewOptimizationBodyContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: center;
    background-color: ${(props) => props.theme.color.background.defaultGrey};
    padding-bottom: 10rem;
`

const NewOptimizationContainerReposition = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    margin-left: 2rem;
`

const PageLinkPanelPanelWrapper = styled.div`
    display: flex;
    margin-left: 2rem;
    @media screen and (max-width: 500px) {
        display: none;
    }
`
const MobileHeaderWrapper = styled.div`
    display: flex;
    display: none;
    @media screen and (max-width: 500px) {
        display: block;
    }
`

const NewOptimizationPanelWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: flex-start;
`
const NotAutorizeContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: left;
`
const NotAutorizeLabel = styled(FormLabel)`
    ${(props) => props.theme.typography.xLarge}
    text-align: left;
    font-weight: 400;
    font-size: 1rem;
    margin-bottom: 50rem;
    color: red;
`

export interface INewOptimizationContainerProps {
    submitNewOptimization: typeof submitNewOptimization
    newOptimizationResult: State<NewOptimizationResponse>
}

const NewOptimizationContainer: FunctionComponent<INewOptimizationContainerProps> & {
    defaultProps: Partial<INewOptimizationContainerProps>
} = ({
    submitNewOptimization,
    newOptimizationResult,
}: INewOptimizationContainerProps) => {
    const { onNewOptimizationSubmit } = NewOptimizationContainerLogic({
        submitNewOptimization,
    } as INewOptimizationContainerProps)
    let intelEmpStatus = localStorage.getItem('isIntelEmployee')
    let isIntelEmployee = !StringUtils.isNullOrUndefinedOrEmpty(intelEmpStatus)
        ? Boolean(intelEmpStatus)
        : false

    return (
        <NewOptimizationPanelContainer>
            <HeaderPanel />
            <NewOptimizationHorizontalContainer>
                <NewOptimizationBodyContainer>
                    <NewOptimizationContainerReposition>
                        <UserPanel></UserPanel>
                        <NewOptimizationPanelWrapper>
                            {isIntelEmployee ? (
                                <NewOptimizationPanel
                                    onNewOptimizationSubmit={
                                        onNewOptimizationSubmit
                                    }
                                    newOptimizationResult={
                                        newOptimizationResult?.data
                                    }
                                    newOptimizationError={
                                        newOptimizationResult?.error
                                    }
                                    newOptimizationLoading={
                                        newOptimizationResult?.loading
                                    }
                                />
                            ) : (
                                <NotAutorizeContainer>
                                    <NotAutorizeLabel>
                                        Permission denied!
                                    </NotAutorizeLabel>
                                </NotAutorizeContainer>
                            )}
                        </NewOptimizationPanelWrapper>
                    </NewOptimizationContainerReposition>
                </NewOptimizationBodyContainer>
            </NewOptimizationHorizontalContainer>
            <FooterPanel></FooterPanel>
        </NewOptimizationPanelContainer>
    )
}

NewOptimizationContainer.defaultProps = {}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        submitNewOptimization: (
            newOptimizationRequest: NewOptimizationRequest
        ) => dispatch(submitNewOptimization(newOptimizationRequest)),
    }
}

const mapStateToProps = (state: any) => {
    return {
        newOptimizationResult: state.newOptimizationResult,
    }
}

type StateToPropsType = ReturnType<typeof mapStateToProps>
type DispatchToPropsType = typeof mapDispatchToProps

export default connect<StateToPropsType, DispatchToPropsType>(
    mapStateToProps,
    mapDispatchToProps
)(NewOptimizationContainer)
