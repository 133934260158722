import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { LoadingState } from '../models/loadingState'
import AllRequestService from '../services/allRequestService'

const initialState = {
    data: null,
    loading: LoadingState.Idle,
    error: null,
}

// First, create the thunk
export const getAllUploadRequest = createAsyncThunk(
    'allRequest/getAllUploadRequest',
    async (allUploadRequest: any) => {
        return await AllRequestService.getAllUploadRequests(allUploadRequest)
    }
)

// Then, handle actions in your reducers:
const getAllUploadRequestSlice = createSlice({
    name: 'getAllUploadRequestSlice',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllUploadRequest.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(getAllUploadRequest.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload.data
                }
            })
            .addCase(getAllUploadRequest.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})
export const updateApprovalDecision = createAsyncThunk(
    'allRequest/updateRequestDecision',
    async (updateRequest: any) => {
        return await AllRequestService.updateRequestApprovalDecision(
            updateRequest
        )
    }
)

// Then, handle actions in your reducers:
const updateApprovalDecisionSlice = createSlice({
    name: 'updateApprovalDecisionSlice',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(updateApprovalDecision.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(updateApprovalDecision.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload.data
                }
            })
            .addCase(updateApprovalDecision.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})
export const deleteUploadRequest = createAsyncThunk(
    'allRequest/deleteUploadRequest',
    async (id: string) => await AllRequestService.deleteUploadRequest(id)
)

const deleteUploadRequestSlice = createSlice({
    name: 'deleteUploadRequest',
    initialState: initialState,
    reducers: {},

    extraReducers: (builder) => {
        builder
            .addCase(deleteUploadRequest.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(deleteUploadRequest.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload.data
                }
            })
            .addCase(deleteUploadRequest.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})
export const getAllSupportRequest = createAsyncThunk(
    'allRequest/getAllSupportRequest',
    async (allSupportRequest: any) => {
        return await AllRequestService.getAllSupportRequests(allSupportRequest)
    }
)

// Then, handle actions in your reducers:
const getAllSupportRequestSlice = createSlice({
    name: 'getAllUploadRequestSlice',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllSupportRequest.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(getAllSupportRequest.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload.data
                }
            })
            .addCase(getAllSupportRequest.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})
export const updateSupportApproval = createAsyncThunk(
    'allRequest/updateSupportApproval',
    async (updateRequest: any) => {
        return await AllRequestService.updateSupportRequest(updateRequest)
    }
)

// Then, handle actions in your reducers:
const updateSupportApprovalSlice = createSlice({
    name: 'updateSupportApprovalSlice',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(updateSupportApproval.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(updateSupportApproval.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload.data
                }
            })
            .addCase(updateSupportApproval.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})
export const deleteSupportRequest = createAsyncThunk(
    'allSupportRequest/deleteSupportRequest',
    async (id: string) => await AllRequestService.deleteSupportRequest(id)
)

const deleteSupportRequestSlice = createSlice({
    name: 'deleteSupportRequest',
    initialState: initialState,
    reducers: {},

    extraReducers: (builder) => {
        builder
            .addCase(deleteSupportRequest.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(deleteSupportRequest.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload.data
                }
            })
            .addCase(deleteSupportRequest.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})

export const getAllUploadRequestResult = getAllUploadRequestSlice.reducer
export const updateApprovalDecisionResult = updateApprovalDecisionSlice.reducer
export const deleteUploadRequestResult = deleteUploadRequestSlice.reducer
export const getAllSupportRequestResult = getAllSupportRequestSlice.reducer
export const updateSupportApprovalResult = updateSupportApprovalSlice.reducer
export const deleteSupportRequestResult = deleteSupportRequestSlice.reducer
