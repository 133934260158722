import { useState, useCallback, FormEvent, ChangeEvent, useEffect } from 'react'
import { CreateOrgRequest } from '../../models/createOrgRequest'
import { useNavigate } from 'react-router-dom'
import { ICreateOrgPanelProps } from './createOrgPanel'
import amplify_conf from '../../services/auth/amplify'
import Amplify from '@aws-amplify/core'
import Auth from '@aws-amplify/auth'
Amplify.configure(amplify_conf)
import { CognitoUser } from 'amazon-cognito-identity-js'
import { Hub } from 'aws-amplify'
import { KeyboardEvent } from 'react'
import stringUtils from '../../lib/stringUtils'
import { AcceptInvitesRequest } from '../../models/acceptInvitesRequest'

export function CreateOrgPanelLogic({
    onCreateOrgSubmit,
    createOrgResponse,
    createOrgError,
    invitationKey,
    acceptInvites,
    acceptInvitesError,
    acceptInvitesLoading,
    acceptInvitesResult,
}: ICreateOrgPanelProps) {
    const [validated, setValidated] = useState(false)
    const [acountOrgName, setAcountOrgName] = useState<string>('')
    const [userFullName, setUserFullName] = useState<string>('')
    const [accountEmail, setAccountEmail] = useState<string>('')
    const [accountPassword, setAccountPassword] = useState<string>('')
    const [accountConfirmPassword, setAccountConfirmPassword] =
        useState<string>('')
    const [signUpUserData, setSignUpUserData] = useState<CognitoUser>()
    const [showSignupForm, setShowSignupForm] = useState<boolean>(true)
    const [confirmationCode, setConfirmationCode] = useState<string>('')
    const [signUpError, setSignUpError] = useState<any>('')
    const [codeValidated, setCodeValidated] = useState(false)

    const [isPasswordContainLowerLetter, setIsPasswordContainLowerLetter] =
        useState<boolean>(false)
    const [isPasswordContainSpecialChar, setIsPasswordContainSpecialChar] =
        useState<boolean>(false)
    const [isPasswordContainNumber, setIsPasswordContainNumber] =
        useState<boolean>(false)
    const [isPasswordLength10Char, setIsPasswordLength10Char] =
        useState<boolean>(false)
    const [showPasswordvalidation, setShowPasswordvalidation] =
        useState<boolean>(false)

    let navigate = useNavigate()

    const onAccountOrgChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLTextAreaElement
            if (target.value) {
                setAcountOrgName(target.value)
            }
        },
        []
    )

    const onAccountEmailChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLTextAreaElement
            if (target.value) {
                setAccountEmail(target.value)
            }
        },
        []
    )
    const onUserFullNameChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLTextAreaElement
            if (target.value) {
                setUserFullName(target.value)
            }
        },
        []
    )
    const onAccountPasswordChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLTextAreaElement
            if (target.value) {
                setAccountPassword(target.value)
            }
        },
        []
    )
    const onAccountConfirmPasswordChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLTextAreaElement
            if (target.value) {
                setAccountConfirmPassword(target.value)
            }
        },
        []
    )
    async function signUp(username: string, password: string, email: string) {
        try {
            const { user } = await Auth.signUp({
                username,
                password,
                attributes: {
                    email, // optional
                    //phone_number,   // optional - E.164 number convention
                    // other custom attributes
                },
            })
            if (user) {
                setSignUpUserData(user)
                setShowSignupForm(false)
                console.log(user)
            }
        } catch (error) {
            setSignUpError(error)
        }
    }
    async function confirmSignUp(
        username: string,
        code: string,
        userFullName: string
    ) {
        try {
            await Auth.confirmSignUp(username, code).then((res) => {
                //silent signIn
                signIn(accountEmail, accountPassword, userFullName)
            })
        } catch (error) {
            setSignUpError(error)
        }
    }
    async function resendConfirmationCode(username: string) {
        try {
            await Auth.resendSignUp(username)
            console.log('code resent successfully')
        } catch (err) {
            setSignUpError(err)
        }
    }
    async function signIn(
        username: string,
        password: string,
        userFullName: string
    ) {
        try {
            const user = await Auth.signIn(username, password)
            if (user) {
                if (invitationKey) {
                    acceptInvites({
                        decision: 'accept',
                        invitationKey: invitationKey,
                        displayName: userFullName,
                    } as AcceptInvitesRequest)
                } else {
                    //create Organization after signup silently
                    var createOrgRequest = new CreateOrgRequest()
                    if (acountOrgName) {
                        createOrgRequest.name = acountOrgName
                        createOrgRequest.displayName = userFullName
                        createOrgRequest.description = ''
                        onCreateOrgSubmit(createOrgRequest)
                    }
                }
            }
        } catch (error) {
            console.log('error signing in', error)
            setSignUpError(error)
        }
    }
    useEffect(() => {
        if (createOrgError) {
            let error: {
                message: string
            } = { message: 'Error creating org' }

            setSignUpError(error)
        }
    }, [createOrgError])

    useEffect(() => {
        if (createOrgResponse) {
            if (createOrgResponse.id) {
                //nevigate to catalog
                navigate('/catalog')
            } else {
                let error: {
                    message: string
                } = { message: 'Error retrieving org id' }

                setSignUpError(error)
            }
        }
    }, [createOrgResponse])

    useEffect(() => {
        if (acceptInvitesError) {
            let error: {
                message: string
            } = { message: 'Error adding new user to the invited org' }

            setSignUpError(error)
        }
    }, [acceptInvitesError])

    useEffect(() => {
        if (acceptInvitesResult) {
            navigate('/catalog')
        }
    }, [acceptInvitesResult])

    const onSubmit = useCallback(
        async (event: FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            let error: {
                message: string
            } = { message: '' }

            const form = event.currentTarget
            let signUpResult: any
            if (form.checkValidity() === false) {
                event.preventDefault()
                event.stopPropagation()
            }
            setValidated(true)
            if (
                accountPassword !== '' &&
                accountConfirmPassword !== '' &&
                accountPassword !== accountConfirmPassword
            ) {
                error.message =
                    'Choose password not matched with confirm password'
                setSignUpError(error)
            } else {
                //cognito signUp
                error.message = ''
                setSignUpError(null)
                if (
                    !stringUtils.isNullOrUndefinedOrEmpty(accountEmail) &&
                    !stringUtils.isNullOrUndefinedOrEmpty(accountPassword) &&
                    !stringUtils.isNullOrUndefinedOrEmpty(
                        accountConfirmPassword
                    ) &&
                    !stringUtils.isNullOrUndefinedOrEmpty(userFullName) &&
                    (!stringUtils.isNullOrUndefinedOrEmpty(invitationKey) ||
                        !stringUtils.isNullOrUndefinedOrEmpty(acountOrgName))
                ) {
                    signUpResult = signUp(
                        accountEmail,
                        accountPassword,
                        accountEmail
                    )
                } else {
                    error.message = 'Invitation key or Org name'
                    setSignUpError(error)
                }
            }
        },
        [
            accountEmail,
            accountPassword,
            accountConfirmPassword,
            invitationKey,
            userFullName,
            acountOrgName,
        ]
    )

    const onCancel = useCallback((event: FormEvent<HTMLElement>) => {
        event.preventDefault()
        setValidated(false)
        setSignUpError('')
        setCodeValidated(false)
        window.location.reload()
    }, [])

    const onCodeConfirmChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLTextAreaElement
            if (target.value) {
                setConfirmationCode(target.value)
            }
        },
        []
    )

    const onCodeConfirmSubmit = useCallback(
        async (event: FormEvent<HTMLFormElement>) => {
            event.preventDefault()

            const form = event.currentTarget
            let signUpResult: any
            if (form.checkValidity() === false) {
                event.preventDefault()
                event.stopPropagation()
            }
            setCodeValidated(true)

            //confirm signup using user entered code
            confirmSignUp(accountEmail, confirmationCode, userFullName)
        },
        [accountEmail, confirmationCode, userFullName]
    )

    const onResendConfirmationCode = useCallback(
        (event: FormEvent<HTMLElement>) => {
            event.preventDefault()
            //resend code
            resendConfirmationCode(accountEmail)
        },
        [accountEmail]
    )

    function listenToAutoSignInEvent() {
        Hub.listen('auth', ({ payload }) => {
            const { event } = payload
            if (event === 'autoSignIn') {
                const user = payload.data
                // assign user
            } else if (event === 'autoSignIn_failure') {
                // redirect to sign in page
                console.log('autoSignIn failed')
            }
        })
    }
    const onCodeConfirmKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        const key = event.key
        if (
            key.match(/[0-9,c,v]/gi) ||
            key === 'Enter' ||
            key === 'Backspace' ||
            key === 'Delete' ||
            key === 'ArrowLeft' ||
            key === 'ArrowRight' ||
            key === 'Home' ||
            key === 'End'
        ) {
            return true
        } else {
            event.preventDefault()
        }
    }
    const onPasswordKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
        const key = event.key
        const passwordCtrl = event.currentTarget
        if (passwordCtrl && passwordCtrl.value.length > 0) {
            setShowPasswordvalidation(true)

            if (key.match(/[a-z]/gi)) {
                setIsPasswordContainLowerLetter(true)
                if (!passwordCtrl.value.match(/[a-z]/gi)) {
                    setIsPasswordContainLowerLetter(false)
                }
            }
            if (key.match(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi)) {
                setIsPasswordContainSpecialChar(true)
            } else if (
                !passwordCtrl.value.match(
                    /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi
                )
            ) {
                setIsPasswordContainSpecialChar(false)
            }
            if (key.match(/[0-9]/gi)) {
                setIsPasswordContainNumber(true)
            } else if (!passwordCtrl.value.match(/[0-9]/gi)) {
                setIsPasswordContainNumber(false)
            }

            if (passwordCtrl.value.length >= 10) {
                setIsPasswordLength10Char(true)
            } else setIsPasswordLength10Char(false)
        } else {
            setIsPasswordContainLowerLetter(false)
            setIsPasswordContainSpecialChar(false)
            setIsPasswordContainNumber(false)
            setIsPasswordLength10Char(false)
            setShowPasswordvalidation(false)
        }
    }

    return {
        onUserFullNameChange,
        onAccountEmailChange,
        onAccountPasswordChange,
        onAccountConfirmPasswordChange,
        onSubmit,
        validated,
        onCancel,
        onAccountOrgChange,
        signUpUserData,
        showSignupForm,
        onCodeConfirmSubmit,
        onResendConfirmationCode,
        onCodeConfirmChange,
        signUpError,
        codeValidated,
        onCodeConfirmKeyDown,
        onPasswordKeyUp,
        isPasswordContainLowerLetter,
        isPasswordContainSpecialChar,
        isPasswordContainNumber,
        isPasswordLength10Char,
        showPasswordvalidation,
    }
}
