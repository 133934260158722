import { useCallback, useState, ChangeEvent, useEffect, FormEvent } from 'react'
import { IAllRequestPanelProps } from './allRequestPanel'
import { useAppDispatch, useAppSelector } from '../../config/hooks'
import {
    deleteUploadRequest,
    updateApprovalDecision,
} from '../../store/allRequest.slice'
import moment from 'moment'

export function AllRequestPanelLogic({
    getData,
    dataResponse,
    handleClose,
}: IAllRequestPanelProps) {
    const dispatch = useAppDispatch()

    const [currPage, setCurrPage] = useState<number>(1)
    const [pageCount, setPageCount] = useState(10)
    const [selectedDecision, setSelectedDecision] = useState<string>('')
    const [showApprovalMessage, setShowApprovalMessage] = useState(false)
    const [showApprovalErrorMessage, setShowApprovalErrorMessage] =
        useState(false)
    const [searchKey, setSearchKey] = useState<string>('')
    const [showFilters, setShowFilters] = useState(false)
    const [totalFilterCount, setTotalFilterCount] = useState<number>(0)
    const [fromDate, setFromDate] = useState<string>('')
    const [toDate, setToDate] = useState<string>('')
    const [selectedStatusValue, setSelectedStatusValue] = useState<string>('')
    const [recipeData, setRecipeData] = useState<any>(null)
    const [disableButtons, setDisableButtons] = useState(false)
    const [isPopupLoad, setIsPopupLoad] = useState(false)
    const [fromDateValError, setFromDateValError] = useState('')
    const [toDateValError, setToDateValError] = useState('')

    const statusList = [
        { name: 'Submitted', checkedValue: false },
        { name: 'Approved', checkedValue: false },
        { name: 'Rejected', checkedValue: false },
        { name: 'Change Requested', checkedValue: false },
        { name: 'Awaiting Re-Approval', checkedValue: false },
    ]
    const [statusFilters, setStatusFilters] =
        useState<{ name: string | null; checkedValue: boolean }[]>(statusList)

    const totalPage = dataResponse?.pageInfo?.total
        ? dataResponse?.pageInfo.total
        : -1

    const deleteResult = useAppSelector<any>(
        (state) => state.deleteUploadRequestResult.data
    )
    const getAllRequestData = (pageNumber: number) => {
        dispatch(
            getData({
                searchKey: searchKey,
                offset:
                    pageNumber > 0
                        ? pageNumber * pageCount - pageCount
                        : currPage * pageCount - pageCount,
                count: pageCount,
                decision: selectedStatusValue,
                fromDate: fromDate ? moment(fromDate).format('YYYY-MM-DD') : '',
                toDate: toDate ? moment(toDate).format('YYYY-MM-DD') : '',
            })
        )
    }

    const afterPageClicked = (pageNumber: number) => {
        setCurrPage(pageNumber)
        getAllRequestData(pageNumber)
    }

    const handlePageCount = useCallback((event: ChangeEvent<HTMLElement>) => {
        const target = event.currentTarget as HTMLSelectElement
        if (target.value) {
            setPageCount(parseInt(target.value))
            dispatch(
                getData({
                    searchKey: searchKey,
                    offset: currPage * pageCount - pageCount,
                    count: parseInt(target.value),
                })
            )
            if ((currPage - 1) * pageCount + 1 > totalPage) setCurrPage(1)
        }
    }, [])

    const handleDecisionChange = useCallback(
        (
            event: ChangeEvent<HTMLElement>,
            uuid: string,
            recipeAdditionalProperties: any
        ) => {
            const target = event.currentTarget as HTMLSelectElement
            if (target.value != undefined) {
                const payload: any = {
                    requestType: 'optimizationUpload',
                    additionalProperties: recipeAdditionalProperties,
                    id: uuid,
                    decision: target.value,
                }
                dispatch(updateApprovalDecision(payload))
            }
        },
        []
    )
    const updateDecisionSuccess = useAppSelector<any>(
        (state) => state.updateApprovalDecisionResult.data
    )
    const updateDecisionError = useAppSelector<any>(
        (state) => state.updateApprovalDecisionResult.error
    )
    useEffect(() => {
        if (updateDecisionSuccess) {
            setShowApprovalMessage(true)
            setTimeout(() => setShowApprovalMessage(false), 3000)
            //refresh data
            getAllRequestData(currPage)
        }
    }, [updateDecisionSuccess])

    const onSearchKeyChange = useCallback((event: ChangeEvent<HTMLElement>) => {
        const target = event.currentTarget as HTMLTextAreaElement
        if (target.value != undefined) {
            setSearchKey(target.value)
            if (target.value === '') {
                //reset pagination
                setCurrPage(1)
                setPageCount(10)
                //reset search refresh data for empty search
                getAllRequestData(1)
            }
        }
    }, [])

    const onSearchKeydown = (eventKey: string) => {
        if (eventKey === 'Enter') {
            //reset pagination
            setCurrPage(1)
            setPageCount(10)
            getAllRequestData(1)
        }
    }
    const onCloseClick = useCallback((event: FormEvent<HTMLElement>) => {
        event.preventDefault()
        setShowFilters(false)
    }, [])

    const onRemoveFiltersClick = useCallback(
        (event: FormEvent<HTMLElement>) => {
            event.preventDefault()
            setFromDate('')
            setToDate('')
            statusFilters.map((item) => {
                item.checkedValue = false
            })
            setTotalFilterCount(0)
            setShowFilters(false)
            setSelectedStatusValue('')
            setSearchKey('')
            setFromDate('')
            setToDate('')
            setToDateValError('')
            setFromDateValError('')
            //reset pagination
            setCurrPage(1)
            setPageCount(10)
            dispatch(
                getData({
                    searchKey: '',
                    offset: 0,
                    count: pageCount,
                    decision: '',
                    fromDate: '',
                    toDate: '',
                })
            )
        },
        [totalFilterCount, selectedStatusValue, fromDate, toDate, searchKey]
    )
    const onApplyFiltersClick = useCallback(
        (event: FormEvent<HTMLElement>) => {
            event.preventDefault()
            setShowFilters(false)
            let filterCount = 0
            let statusSelectedValue = ''
            if (fromDate && toDate) filterCount = filterCount + 1

            statusFilters.map((item) => {
                if (item.name !== null && item.checkedValue) {
                    statusSelectedValue =
                        statusSelectedValue === ''
                            ? `&decision=${item.name}`
                            : statusSelectedValue + `&decision=${item.name}`
                    filterCount = filterCount + 1
                }
            })
            setSelectedStatusValue(statusSelectedValue)
            //reset pagination
            setCurrPage(1)
            setPageCount(10)
            setTotalFilterCount(filterCount)
            dispatch(
                getData({
                    searchKey: searchKey,
                    offset: 0,
                    count: pageCount,
                    decision: statusSelectedValue,
                    fromDate: fromDate
                        ? moment(fromDate).format('YYYY-MM-DD')
                        : '',
                    toDate: toDate ? moment(toDate).format('YYYY-MM-DD') : '',
                })
            )

            setShowFilters(false)
        },
        [totalFilterCount, fromDate, toDate, searchKey]
    )
    const onFromDateChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLDataElement
            if (target.value) {
                setFromDate(target.value)
                setShowFilters(true)
                const today = new Date()
                const fromDateObj = new Date(target.value)
                const toDateObj = toDate ? new Date(toDate) : new Date()
                if (fromDateObj > today)
                    setFromDateValError(
                        'From date cannot be greater than today'
                    )
                else if (fromDateObj > toDateObj) {
                    setFromDateValError('From date cannot be greater to date')
                } else {
                    setFromDateValError('')
                    setToDateValError('')
                }
            } else setFromDateValError('')
        },
        [toDate]
    )
    const onToDateChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLDataElement
            if (target.value) {
                setToDate(target.value)
                setShowFilters(true)
                const today = new Date()
                const toDateObj = new Date(target.value)
                const fromDateObj = fromDate ? new Date(fromDate) : new Date()
                if (toDateObj > today)
                    setToDateValError('To date cannot be greater than today')
                else if (toDateObj < fromDateObj) {
                    setToDateValError('To date must be greater than from date')
                } else {
                    setToDateValError('')
                    setFromDateValError('')
                }
            } else setToDateValError('')
        },
        [fromDate]
    )
    const onStatusFilterChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLFormElement
            if (target) {
                const updatedType = statusFilters?.map(
                    (item: { name: string | null; checkedValue: boolean }) => {
                        if (target.value === item.name) {
                            item.checkedValue = target.checked
                        }

                        return item
                    }
                )
                setStatusFilters(updatedType)
            }
        },
        [statusFilters]
    )

    const handleDelete = useCallback(
        (event: FormEvent<HTMLElement>) => {
            event.preventDefault()
            const { uuid } = recipeData
            setDisableButtons(true)
            setIsPopupLoad(false)
            dispatch(deleteUploadRequest(uuid))
            setTimeout(() => {
                handleClose()
            }, 10000)
        },
        [recipeData]
    )
    useEffect(() => {
        if (deleteResult?.msg) {
            getAllRequestData(0)
        }
    }, [deleteResult])

    return {
        currPage,
        afterPageClicked,
        pageCount,
        handlePageCount,
        handleDecisionChange,
        selectedDecision,
        setSelectedDecision,
        showApprovalErrorMessage,
        showApprovalMessage,
        updateDecisionError,
        updateDecisionSuccess,
        onSearchKeyChange,
        onSearchKeydown,
        totalFilterCount,
        showFilters,
        setShowFilters,
        onRemoveFiltersClick,
        onCloseClick,
        onApplyFiltersClick,
        onFromDateChange,
        onToDateChange,
        fromDate,
        toDate,
        statusFilters,
        onStatusFilterChange,
        searchKey,
        handleDelete,
        setRecipeData,
        recipeData,
        deleteResult,
        disableButtons,
        setDisableButtons,
        isPopupLoad,
        setIsPopupLoad,
        fromDateValError,
        toDateValError,
    }
}
