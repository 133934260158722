/************************************************
 * Copyright (C) 2024 Intel Corporation
 ************************************************/
import {
    ChangeEvent,
    FunctionComponent,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react'
import styled from 'styled-components'
import { LoadingState } from '../../models/loadingState'
import {
    Button,
    Form,
    FormLabel,
    OverlayTrigger,
    Popover,
    Spinner,
} from 'react-bootstrap'

import { postRetrievalQuery } from '../../store/chatCompletion.slice'
import Select from 'react-select'
import { RetrievalModelRequest } from '../models/retrievalModelRequest'

const ChatContainer = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    border: 1px solid rgb(101, 49, 113);
    border-radius: 0;
    padding: 1rem 0;
`
const Container = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    alignitems: center;
    height: 100%;
    width: 100%;
    margin-top: 1rem;
    padding: 1rem;
    @media screen and (max-width: 500px) {
        width: 100%;
    }
`
const InputContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
`

const RunButton = styled.button`
    font-family: 'IntelOne Text';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    text-decoration: none;
    color: #fff;
    background-color: #8f5da2;
    margin-right: 1.5rem;
    border: none;
    min-width: 6rem;
    height: 2rem;
    border-radius: 0;
    border: none;
`
const ResetButton = styled(RunButton)`
    background-color: #8f5da2;
`

const TextLabel = styled(FormLabel)`
    display: flex;
    color: var(--input-label-light-default, #494b51);
    font-family: 'IntelOne Display';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
`
const PopoverKeyDiv = styled.div`
    background-color: #000;
    color: #fff;
    font-size: 1rem;
    padding: 0.5rem;
`

export interface IRetrievalInputPanelProps {
    postRetrievalQuery: typeof postRetrievalQuery
    retrievalQueryAnswer: any
    retrievalQueryLoading: LoadingState
    retrievalQueryError: any
    selectedModelName: string
    selectedModelPublishedBy: string
    setIsResetClick: any
}

const RetrievalInputPanel: FunctionComponent<IRetrievalInputPanelProps> = ({
    postRetrievalQuery,
    retrievalQueryAnswer,
    retrievalQueryLoading,
    retrievalQueryError,
    selectedModelName,
    selectedModelPublishedBy,
    setIsResetClick,
}: IRetrievalInputPanelProps) => {
    const defaultInputQuery = 'What is the capital of France?'
    const inputRef = useRef<HTMLTextAreaElement>(null)
    const [inputText, setInputText] = useState(defaultInputQuery)
    const [inputType, setInputType] = useState<any>([
        { value: 'query', label: 'query' },
    ])
    const [encodingFormat, setEncodingFormat] = useState<any>([
        { value: 'float', label: 'float' },
    ])

    const inputTypeOptions = [
        {
            value: 'query',
            label: 'query',
        },
        { value: 'passage', label: 'passage' },
    ]
    const encodingFormatOptions = [
        {
            value: 'float',
            label: 'float',
        },
        { value: 'base64', label: 'base64' },
    ]

    const handleRunClick = async () => {
        //get answer
        setIsResetClick(false)
        const request = new RetrievalModelRequest()
        request.input = inputText
        request.input_type =
            inputType && inputType.value ? inputType.value : 'query'
        request.model = 'tgi-embed'
        request.encoding_format =
            encodingFormat && encodingFormat.value
                ? encodingFormat.value
                : 'float'
        postRetrievalQuery(request)
    }
    const handleResetClick = async () => {
        setInputText(defaultInputQuery)
        if (
            inputRef &&
            inputRef.current !== undefined &&
            inputRef.current !== null
        )
            inputRef.current.value = defaultInputQuery
        //set other input
        setInputType({ value: 'query', label: 'query' })
        setEncodingFormat({ value: 'float', label: 'float' })
        setIsResetClick(true)
    }

    const onInputTextChange = useCallback((event: ChangeEvent<HTMLElement>) => {
        const target = event.currentTarget as HTMLTextAreaElement
        setInputText(target.value)
    }, [])

    return (
        <ChatContainer>
            <InputContainer>
                <Container>
                    <TextLabel>
                        Input
                        <OverlayTrigger
                            placement={'right'}
                            delay={{
                                show: 250,
                                hide: 400,
                            }}
                            overlay={
                                <Popover
                                    id="pop_timeline"
                                    style={{
                                        maxWidth: '20%',
                                    }}
                                >
                                    <Popover.Body>
                                        <PopoverKeyDiv>
                                            Input text to embed, encoded as a
                                            string or array of tokens. To embed
                                            multiple inputs in a single request,
                                            pass an array of strings. The input
                                            must not exceed the max input tokens
                                            for the model (512 tokens) and
                                            cannot be an empty string.
                                        </PopoverKeyDiv>
                                    </Popover.Body>
                                </Popover>
                            }
                        >
                            <i
                                className="uil uil-info-circle pointer"
                                tabIndex={0}
                            ></i>
                        </OverlayTrigger>
                    </TextLabel>
                    <Form.Control
                        ref={inputRef}
                        id="input"
                        name="input"
                        placeholder="Enter your query.."
                        as="textarea"
                        rows={3}
                        onChange={onInputTextChange}
                        defaultValue={defaultInputQuery}
                        autoFocus
                    />
                    <div style={{ paddingTop: '1rem' }}>
                        <TextLabel>
                            Input Type
                            <OverlayTrigger
                                placement={'right'}
                                delay={{ show: 250, hide: 400 }}
                                overlay={
                                    <Popover
                                        id="pop_timeline"
                                        style={{
                                            maxWidth: '20%',
                                        }}
                                    >
                                        <Popover.Body>
                                            <PopoverKeyDiv>
                                                This model operate in passage or
                                                query mode, and thus require the
                                                input_type parameter. passage is
                                                used when generating embeddings
                                                during indexing. query is used
                                                when generating embeddings
                                                during querying. It is very
                                                important to use the correct
                                                input_type. Failure to do so
                                                will result in large drops in
                                                retrieval accuracy.
                                            </PopoverKeyDiv>
                                        </Popover.Body>
                                    </Popover>
                                }
                            >
                                <i
                                    className="uil uil-info-circle pointer"
                                    tabIndex={0}
                                ></i>
                            </OverlayTrigger>
                        </TextLabel>
                        <Select
                            name="inputType"
                            aria-label="Select input type"
                            isSearchable={false}
                            isClearable={false}
                            isMulti={false}
                            options={inputTypeOptions}
                            value={inputType}
                            onChange={(target: any) => setInputType(target)}
                        />
                    </div>
                    <div style={{ paddingTop: '1rem' }}>
                        <TextLabel>
                            Encoding Format
                            <OverlayTrigger
                                placement={'right'}
                                delay={{ show: 250, hide: 400 }}
                                overlay={
                                    <Popover
                                        id="pop_timeline"
                                        style={{
                                            maxWidth: '20%',
                                        }}
                                    >
                                        <Popover.Body>
                                            <PopoverKeyDiv>
                                                The format to return the
                                                embeddings in
                                            </PopoverKeyDiv>
                                        </Popover.Body>
                                    </Popover>
                                }
                            >
                                <i
                                    className="uil uil-info-circle pointer"
                                    tabIndex={0}
                                ></i>
                            </OverlayTrigger>
                        </TextLabel>
                        <Select
                            name="encodingFormat"
                            aria-label="Select encoding Format"
                            isSearchable={false}
                            isClearable={false}
                            isMulti={false}
                            options={encodingFormatOptions}
                            value={encodingFormat}
                            onChange={(target: any) =>
                                setEncodingFormat(target)
                            }
                        />
                    </div>
                </Container>
                <Container style={{ flexDirection: 'row' }}>
                    <ResetButton onClick={handleResetClick}>Reset</ResetButton>
                    <RunButton onClick={handleRunClick}>Run</RunButton>
                </Container>
            </InputContainer>
        </ChatContainer>
    )
}

export default RetrievalInputPanel
