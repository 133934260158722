export const languageList = [
    'Afrikaans',
    'Aghem',
    'Akan',
    'Albanian',
    'Amharic',
    'Arabic',
    'Armenian',
    'Assamese',
    'Asturian',
    'Asu',
    'Azerbaijani',
    'Bafia',
    'Bambara',
    'Bangla',
    'Basaa',
    'Bashkir',
    'Basque',
    'Belarusian',
    'Bemba',
    'Bena',
    'Blin',
    'Bodo',
    'Bosnian',
    'Breton',
    'Bulgarian',
    'Burmese',
    'Cantonese',
    'Catalan',
    'Cebuano',
    'Central Atlas Tamazight',
    'Chakma',
    'Chechen',
    'Cherokee',
    'Chiga',
    'Chinese',
    'Chuvash',
    'Colognian',
    'Cornish',
    'Corsican',
    'Croatian',
    'Czech',
    'Danish',
    'Dhivehi',
    'Duala',
    'Dutch',
    'Dzongkha',
    'Embu',
    'English',
    'English (United States)',
    'Erzya',
    'Esperanto',
    'Estonian',
    'Ewe',
    'Ewondo',
    'Faroese',
    'Filipino',
    'Finnish',
    'French',
    'Friulian',
    'Fulah',
    'Ga',
    'Galician',
    'Ganda',
    'Geez',
    'Georgian',
    'German',
    'Greek',
    'Guarani',
    'Gujarati',
    'Gusii',
    'Hausa',
    'Hawaiian',
    'Hebrew',
    'Hindi',
    'Hungarian',
    'Icelandic',
    'Ido',
    'Igbo',
    'Inari Sami',
    'Indonesian',
    'Interlingua',
    'Inuktitut',
    'Irish',
    'Italian',
    'Japanese',
    'Javanese',
    'Jju',
    'Jola-Fonyi',
    'Kabuverdianu',
    'Kabyle',
    'Kako',
    'Kalaallisut',
    'Kalenjin',
    'Kamba',
    'Kannada',
    'Kashmiri',
    'Kazakh',
    'Khmer',
    'Kikuyu',
    'Kinyarwanda',
    'Konkani',
    'Korean',
    'Koyra Chiini',
    'Koyraboro Senni',
    'Kpelle',
    'Kurdish',
    'Kurdish, Sorani',
    'Kwasio',
    'Kyrgyz',
    'Lakota',
    'Langi',
    'Language',
    'Lao',
    'Latvian',
    'Lingala',
    'Lithuanian',
    'Lojban',
    'Low German',
    'Lower Sorbian',
    'Luba-Katanga',
    'Luo',
    'Luxembourgish',
    'Luyia',
    'Macedonian',
    'Machame',
    'Makhuwa-Meetto',
    'Makonde',
    'Malagasy',
    'Malay',
    'Malayalam',
    'Maltese',
    'Manipuri',
    'Manx',
    'Maori',
    'Mapuche',
    'Marathi',
    'Masai',
    'Mazanderani',
    'Meru',
    'Metaʼ',
    'Mohawk',
    'Mongolian',
    'Morisyen',
    'Mundang',
    'N’Ko',
    'Nama',
    'Nepali',
    'Ngiemboon',
    'Ngomba',
    'North Ndebele',
    'Northern Luri',
    'Northern Sami',
    'Northern Sotho',
    'Norwegian Bokmål',
    'Norwegian Nynorsk',
    'Nuer',
    'Nyanja',
    'Nyankole',
    'Occitan',
    'Odia',
    'Oromo',
    'Ossetic',
    'Pashto',
    'Persian',
    'Polish',
    'Portuguese',
    'Punjabi',
    'Quechua',
    'Romanian',
    'Romansh',
    'Rombo',
    'Rundi',
    'Russian',
    'Rwa',
    'Sakha',
    'Samburu',
    'Sango',
    'Sangu',
    'Sanskrit',
    'Santali',
    'Sardinian',
    'Scottish Gaelic',
    'Sena',
    'Serbian',
    'Shambala',
    'Shona',
    'Sichuan Yi',
    'Sicilian',
    'Sindhi',
    'Sinhala',
    'Slovak',
    'Slovenian',
    'Soga',
    'Somali',
    'South Ndebele',
    'Southern Sotho',
    'Spanish',
    'Standard Moroccan Tamazight',
    'Swahili',
    'Swati',
    'Swedish',
    'Swiss German',
    'Syriac',
    'Tachelhit',
    'Taita',
    'Tajik',
    'Tamil',
    'Taroko',
    'Tasawaq',
    'Tatar',
    'Telugu',
    'Teso',
    'Thai',
    'Tibetan',
    'Tigre',
    'Tigrinya',
    'Tongan',
    'Tsonga',
    'Tswana',
    'Turkish',
    'Turkmen',
    'Tyap',
    'Ukrainian',
    'Upper Sorbian',
    'Urdu',
    'Uyghur',
    'Uzbek',
    'Vai',
    'Venda',
    'Vietnamese',
    'Vunjo',
    'Walloon',
    'Walser',
    'Welsh',
    'Western Frisian',
    'Wolaytta',
    'Wolof',
    'Xhosa',
    'Yangben',
    'Yiddish',
    'Yoruba',
    'Zarma',
    'Zulu',
]
