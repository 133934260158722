/************************************************
 * Copyright (C) 2021 Intel Corporation
 ************************************************/
import styled from 'styled-components'
import { FunctionComponent, useEffect } from 'react'
import { Alert, Button, Form, FormLabel, Row } from 'react-bootstrap'
import { AuthenticationContainerLogic } from './authenticationContainer.hook'
import { getOauthSignInURL } from '../../services/auth/amplify'
import { useNavigate } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import stringUtils from '../../lib/stringUtils'
import { Cache } from '@aws-amplify/cache'
import { clearAllItemsFromStorage } from '../../lib/encryptStorage'

const AuthPanelContainer = styled.div`
    display: flex;
    height: ${(props) => props.theme.size.mainContainerHeight};
    width: ${(props) => props.theme.size.mainContainerWidth};
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: space-between;
    @media screen and (min-width: 1200px) {
        overflow-x: hidden;
    }
`
const AuthHorizontalContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: space-between;
`
const AuthBodyContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: center;
    background-color: #fff;
    padding-bottom: 10rem;
`

const AuthBodyContainerReposition = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
`
const AuthPanelWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: center;
    max-width: 100%;
`
const IntelLogoStyling = styled.div`
    display: flex;
    margin-left: 2rem;
    margin-top: 1rem;
    padding: 0.5rem;
`
const HeaderContainer = styled.div`
    display: flex;
    //background-color: ${(props) => props.theme.color.background.defaultGrey};
    padding-bottom: 2rem;
    justify-content: left;
`
const AlertComponent = styled(Alert)`
    display: flex;
    margin-top: 1rem;
    width: 35vw;
    justify-content: center;
`
const LoginContainerSSOPanel = styled.div`   
    margin-bottom: 20px;
    padding: 20px;
    box-shadow: 0 1px 1px rgb(0 0 0 / 0%);
    border-radius: 0;
    background: transparent;
    margin-bottom-3rem;
    justify-content: center;
`
const HExternalLoginPanel = styled.div`
    background: transparent;
    display: none;
    @media screen and (max-width: 600px) {
        display: block;
    }
`
const HSeparatorDiv = styled.div`
    justify-content: center;
    padding: 30px;
    @media screen and (max-width: 600px) {
        border-bottom-style: solid;
        border-bottom-color: #f5f5f5;
        border-bottom-width: 2px;
        margin-bottom: -2px;
    }
`
const ExternalLoginContainerPanel = styled.div`
    margin-top: 30px;
    margin-bottom: 20px;
    padding: 30px;
    box-shadow: 0 1px 1px rgb(0 0 0 / 0%);
    border-radius: 0;
    background: transparent;
    @media screen and (min-width: 601px) {
        border-left-style: solid;
        border-left-color: #f5f5f5;
        border-left-width: 2px;
        margin-left: -2px;
    }
`
const VExternalLoginPanel = styled.div`
    background: transparent;
    display: none;
    @media screen and (min-width: 601px) {
        display: block;
    }
`
const HorPanel = styled.div`
    display: none;
    @media screen and (max-width: 600px) {
        font-size: 16px;
        display: block;
        position: absolute;
        background-color: #fff;
        text-align: center;
        color: #a3a3a3;
        padding-top: 15px;
        padding-bottom: 15px;
        margin-top: 0.2rem;
        margin-left: 35%;
    }
`
const VorPanel = styled.div`
    @media screen and (min-width: 601px) {
        font-size: 16px;
        display: block;
        position: absolute;
        background-color: #fff;
        text-align: center;
        color: #a3a3a3;
        padding-top: 15px;
        padding-bottom: 15px;
        top: 40%;
        margin-left: -8px;
    }
`
const IdpDescriptionPanel = styled.span`
    padding-top: 10px;
    padding-bottom: 10px;
    display: block;
`
const SignInButton = styled(Button)`
    font-size: 14px;
    font-weight: bold;
    margin: 20px 0px 10px 0px;
    height: 40px;
    width: 100%;
    color: #fff;
    background-color: #337ab7;
    border-color: #2e6da4;
    max-width: 25rem;
    border-radius: 0px;
`
const IntelSSOButton = styled(Button)`
    font-size: 14px;
    font-weight: bold;
    width: 100%;
    color: #fff;
    background-color: #0dcaf0;
    border-color: #0dcaf0;
    max-width: 25rem;
    border-radius: 0px;
    margin-bottom: 3rem;
`
const TextLabel = styled(Form.Label)`
    display: flex;
    ${(props) => props.theme.typography.medium}
    text-align: left;
    font-weight: 400;
`

const RequiredTextLabel = styled(FormLabel)`
    display: flex;
    ${(props) => props.theme.typography.xLarge}
    color: red;
`
const TextBoxWrapper = styled(Form.Control)`
    display: flex;
    border-radius: ${(props) => props.theme.shape.input.borderRadius};
    margin-bottom: 1rem;
    max-width: 25rem;
`
const SSOTextSpan = styled.span`
    color: #fff;
    font-weight: 500;
    font-size: 1.2rem;
    vertical-align: middle;
`

export interface IAuthenticationContainerProps {}
const AuthenticationContainer: FunctionComponent<
    IAuthenticationContainerProps
> = () => {
    const {
        signInError,
        validated,
        onSubmit,
        onUserEmailChange,
        onUserPasswordChange,
    } = AuthenticationContainerLogic({} as IAuthenticationContainerProps)

    let navigate = useNavigate()

    useEffect(() => {
        let invitationKey = localStorage.getItem('invitationKey')
        const fetchUserData = async () => {
            try {
                await Auth.currentAuthenticatedUser()
                console.log('user logged in already')
                if (!stringUtils.isNullOrUndefinedOrEmpty(invitationKey)) {
                    navigate('/invitation')
                } else {
                    localStorage.setItem('loginRoute', 'catalog')
                    navigate('/catalog')
                }
            } catch {
                Cache.clear() //clear cache
                clearAllItemsFromStorage() //clear previous storage
                console.log('user not logged in. sign in required!')
            }
        }
        fetchUserData()
    }, [])

    const externalSignInComponent = (
        <ExternalLoginContainerPanel>
            <Form.Group>
                <TextLabel style={{ fontSize: '1.2rem' }}>
                    Sign in with your email and password
                </TextLabel>
            </Form.Group>
            <Form
                noValidate
                validated={validated}
                onSubmit={onSubmit}
                id="signInForm"
            >
                <Form.Group>
                    <AlertComponent
                        style={{ width: '100%' }}
                        variant="danger"
                        show={signInError ? true : false}
                        data-testid="error-text"
                    >
                        {'Error! ' + signInError?.message}
                    </AlertComponent>
                </Form.Group>
                <Form.Group controlId="formBasicEmail">
                    <TextLabel data-testid="email-address-label">
                        <RequiredTextLabel>*</RequiredTextLabel>
                        Email address{' '}
                    </TextLabel>
                    <TextBoxWrapper
                        type="email"
                        placeholder="Enter email..."
                        onChange={onUserEmailChange}
                        required
                        data-testid="email-address-text"
                    />
                    <Form.Control.Feedback type="invalid">
                        Please enter an email address in format name@xyg.com.
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="formBasicPassword">
                    <TextLabel data-testid="choose-password-label">
                        <RequiredTextLabel>*</RequiredTextLabel>
                        Choose a password{' '}
                    </TextLabel>
                    <TextBoxWrapper
                        id="userPassword"
                        name="userPassword"
                        type="password"
                        placeholder="Enter your password..."
                        autoComplete="new-password"
                        onChange={onUserPasswordChange}
                        required
                        data-testid="choose-password-text"
                    />
                    <Form.Control.Feedback type="invalid">
                        Please enter your password.
                    </Form.Control.Feedback>
                </Form.Group>

                <div>
                    <a href="/forgotPassword">Forgot your password?</a>
                </div>
                <SignInButton
                    variant="primary"
                    type="submit"
                    onClick={onSubmit}
                    data-testid="signin-button"
                >
                    Sign in
                </SignInButton>
            </Form>
            <br />
            <div>
                <p>
                    <span>Need an account?</span>&nbsp;
                    <a href="/createAccount">Sign up</a>
                </p>
            </div>
            <div>
                <p>
                    <span>Already have account confirmation code?</span>
                    &nbsp;
                    <a href="/activateAccount">Activate your account here</a>
                </p>
            </div>
        </ExternalLoginContainerPanel>
    )

    return (
        <AuthPanelContainer>
            <HeaderContainer>
                <IntelLogoStyling>
                    <img
                        src={stringUtils.resolve('/Intel_energyblue_logo.png')}
                        height="65px"
                        width="171px"
                        alt="intel logo"
                        style={{ verticalAlign: 'middle' }}
                    />
                </IntelLogoStyling>
            </HeaderContainer>
            <AuthHorizontalContainer>
                <AuthBodyContainer>
                    <AuthBodyContainerReposition>
                        <AuthPanelWrapper>
                            <LoginContainerSSOPanel className="col-md-3">
                                <HSeparatorDiv>
                                    <IdpDescriptionPanel>
                                        <TextLabel
                                            style={{ fontSize: '1.2rem' }}
                                        >
                                            Intel employee sign in here:
                                        </TextLabel>
                                    </IdpDescriptionPanel>
                                    <IntelSSOButton
                                        variant="info"
                                        type="submit"
                                        href={getOauthSignInURL()}
                                        aria-label="Intel"
                                    >
                                        <img
                                            src={stringUtils.resolve(
                                                '/Intel_classicblue_white.png'
                                            )}
                                            height="32px"
                                            width="95px"
                                            alt="intel logo"
                                            style={{ verticalAlign: 'middle' }}
                                        />{' '}
                                        <SSOTextSpan>SSO</SSOTextSpan>
                                    </IntelSSOButton>
                                    <HorPanel>or</HorPanel>
                                </HSeparatorDiv>
                                <HExternalLoginPanel>
                                    {externalSignInComponent}
                                </HExternalLoginPanel>
                            </LoginContainerSSOPanel>
                            <VExternalLoginPanel className="col-md-3">
                                <VorPanel>or</VorPanel>
                                {externalSignInComponent}
                            </VExternalLoginPanel>
                        </AuthPanelWrapper>
                    </AuthBodyContainerReposition>
                </AuthBodyContainer>
            </AuthHorizontalContainer>
        </AuthPanelContainer>
    )
}

export default AuthenticationContainer
