export class CatalogSearchResponse {
    public recipes: Array<RecipeData> = []
    public pageInfo: PageInfo = { total: 0, next: 0 }
}

export class RecipeData {
    public uuid: string = ''
    public description: string = ''
    public attachments: { filename: string; isFree: boolean }[] = []
    public subscriptionStatus: SubscriptionStatus =
        SubscriptionStatus.NotSubscribed
    public appName: string = ''
    public msg: string = ''
    public tags: { [key: string]: any[] } = {}
    public resolution: string = ''
    public isFavorite: boolean = false
    public favoriteCount: number = 0
    public timeCreated: string = ''
    public timeUpdated: string = ''
    public downloadCount: number = 0
    public rating: number = 0
    public userRating: number = 0
    public ratingCount: number = 0
    public accessGroup: string = ''
    public isPerformanceDataExist: boolean = false
    public isBenchmarkDataExist: boolean = false
    public licensePlugin: string = ''
    public isLiked: boolean = false
    public likeCount: number = 0
    public usageTerms: string = ''
    public hasAcceptedUsageTerms: boolean = false
    public isPremium: boolean = false
    public isWatched: boolean = false
}

export class PageInfo {
    public total: number = 0
    public next: number = 0
}
export enum SubscriptionStatus {
    Subscribed = 'subscribed',
    NotSubscribed = 'not_subscribed',
    NotApplicable = 'not_applicable',
    Free = 'free',
}
