import { useCallback } from 'react'
import { NewOptimizationRequest } from '../../models/newOptimizationRequest'
import { INewOptimizationContainerProps } from './newOptimizationContainer'

export function NewOptimizationContainerLogic({
    submitNewOptimization,
}: INewOptimizationContainerProps) {
    const onNewOptimizationSubmit = useCallback(
        async (newOptimizationRequest: NewOptimizationRequest) => {
            submitNewOptimization(newOptimizationRequest)
        },
        []
    )
    return {
        onNewOptimizationSubmit,
    }
}
