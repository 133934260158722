/************************************************
 * Copyright (C) 2021 Intel Corporation
 ************************************************/
import styled from 'styled-components'
import { FunctionComponent, Dispatch, useEffect } from 'react'
import { connect } from 'react-redux'
import { CreateDiscountContainerLogic } from './createDiscountContainer.hook'
import FooterPanel from '../../layout/footer'
import { State } from '../../models/state'
import UserPanel from '../../layout/userProfile'
import StringUtils from '../../lib/stringUtils'
import { FormLabel } from 'react-bootstrap'

import HeaderPanel from '../../layout/header'
import CreateDiscountPanel from '../../components/createDiscountPanel/createDiscountPanel'
import { _getDecryptedValueFromStorage } from '../../lib/encryptStorage'
import { UserOtherMetaRoles } from '../../models/userRoles'
import React from 'react'
import { createDiscountCode } from '../../store/discount.slice'

const CreateDiscountPanelContainer = styled.div`
    display: flex;
    height: ${(props) => props.theme.size.mainContainerHeight};
    width: ${(props) => props.theme.size.mainContainerWidth};
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: space-between;
    overflow-x: hidden;
    @media screen and (min-width: 64rem), screen and (min-height: 48rem) {
        /*overflow-x: auto;*/
    }
`

const CreateDiscountHorizontalContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: space-between;
`
const CreateDiscountBodyContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: center;
    background-color: ${(props) => props.theme.color.background.defaultGrey};
    padding-bottom: 15rem;
`

const CreateDiscountBodyContainerReposition = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
`

const CreateDiscountPanelWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: flex-start;
`
const NotAutorizeContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: left;
`
const NotAutorizeLabel = styled(FormLabel)`
    ${(props) => props.theme.typography.xLarge}
    text-align: left;
    font-weight: 400;
    font-size: 1rem;
    margin-bottom: 50rem;
    color: red;
`

export interface ICreateDiscountContainerProps {
    createDiscountCode: typeof createDiscountCode
    createDiscountCodeResult: State<any>
    clearData: any
}

const CreateDiscountContainer: FunctionComponent<ICreateDiscountContainerProps> & {
    defaultProps: Partial<ICreateDiscountContainerProps>
} = ({
    createDiscountCode,
    createDiscountCodeResult,
    clearData,
}: ICreateDiscountContainerProps) => {
    const { onCreateDiscountSubmit } = CreateDiscountContainerLogic({
        createDiscountCode,
    } as ICreateDiscountContainerProps)
    let otherMetaPermissions: string[] = []

    let otherPermissions = _getDecryptedValueFromStorage('otherMetaPermissions')
    if (otherPermissions) {
        otherMetaPermissions = otherPermissions.split(',')
    }

    let intelEmpStatus = localStorage.getItem('isIntelEmployee')
    let isIntelEmployee = !StringUtils.isNullOrUndefinedOrEmpty(intelEmpStatus)
        ? Boolean(intelEmpStatus)
        : false

    let isDiscounter = otherMetaPermissions.includes(
        UserOtherMetaRoles.Discounter
    )
    return (
        <CreateDiscountPanelContainer>
            <HeaderPanel />
            <CreateDiscountHorizontalContainer>
                <CreateDiscountBodyContainer>
                    <CreateDiscountBodyContainerReposition>
                        <UserPanel></UserPanel>
                        {isIntelEmployee && isDiscounter ? (
                            <CreateDiscountPanelWrapper>
                                <CreateDiscountPanel
                                    onCreateDiscountSubmit={
                                        onCreateDiscountSubmit
                                    }
                                    createDiscountLoading={
                                        createDiscountCodeResult.loading
                                    }
                                    createDiscountResult={
                                        createDiscountCodeResult.data
                                    }
                                    createDiscountError={
                                        createDiscountCodeResult.error
                                    }
                                    clearData={clearData}
                                />
                            </CreateDiscountPanelWrapper>
                        ) : (
                            <NotAutorizeContainer>
                                <NotAutorizeLabel>
                                    Permission denied!
                                </NotAutorizeLabel>
                            </NotAutorizeContainer>
                        )}
                    </CreateDiscountBodyContainerReposition>
                </CreateDiscountBodyContainer>
            </CreateDiscountHorizontalContainer>
            <FooterPanel></FooterPanel>
        </CreateDiscountPanelContainer>
    )
}

CreateDiscountContainer.defaultProps = {}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        createDiscountCode: (payload: any) =>
            dispatch(createDiscountCode(payload)),
    }
}

const mapStateToProps = (state: any) => {
    return {
        createDiscountCodeResult: state.createDiscountCodeResult,
    }
}

type StateToPropsType = ReturnType<typeof mapStateToProps>
type DispatchToPropsType = typeof mapDispatchToProps

export default connect<StateToPropsType, DispatchToPropsType>(
    mapStateToProps,
    mapDispatchToProps
)(CreateDiscountContainer)
