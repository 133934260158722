import { useState, useCallback, FormEvent } from 'react'
import { IBenchmarkCardProps } from './benchmarkCard'
import { RecipeAddLinkClickRequest } from '../../models/recipeAddLinkClickRequest'
import { _generateRandom } from '../../lib/generate'
import { useNavigate } from 'react-router'

export function BenchmarkCardLogic({ workloadData }: IBenchmarkCardProps) {
    let navigate = useNavigate()

    const onBenchmarkCardClick = useCallback(
        (
            event: FormEvent<HTMLElement>,
            uuid: string | undefined,
            group: string | undefined,
            groupType: string | undefined,
            assetType: string | undefined,
            workloadName: string | undefined,
            urlNavigation: string | undefined
        ) => {
            event.preventDefault()
            if (uuid)
                navigate(
                    `/benchmark/${uuid}?workload=${workloadName}&benchmarkGroup=${group}&benchmarkGroupType=${groupType}&assetType=${assetType}&nav=${urlNavigation}`
                )
        },
        []
    )

    return {
        onBenchmarkCardClick,
    }
}
