import { useEffect } from 'react'
import { IMembershipContainerProps } from './membershipContainer'

export function MembershipContainerLogic({getAuthPermissions}: IMembershipContainerProps) {

    useEffect(() => {
        if (getAuthPermissions) {
            getAuthPermissions()
        }
    }, [])
}
