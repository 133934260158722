import { useState, useCallback, FormEvent, ChangeEvent } from 'react'
import { useNavigate } from 'react-router-dom'
import { IAuthenticationContainerProps } from './authenticationContainer'
import amplify_conf from '../../services/auth/amplify'
import Amplify from '@aws-amplify/core'
import Auth from '@aws-amplify/auth'
Amplify.configure(amplify_conf)
import { CognitoUser } from 'amazon-cognito-identity-js'
import stringUtils from '../../lib/stringUtils'

export function AuthenticationContainerLogic({}: IAuthenticationContainerProps) {
    const [validated, setValidated] = useState(false)
    const [userEmail, setUserEmail] = useState<string>('')
    const [userPassword, setUserPassword] = useState<string>('')
    const [signInUserData, setSignInUserData] = useState<CognitoUser>()
    const [signInError, setSignInError] = useState<any>('')

    let navigate = useNavigate()
    const publicUrlRouteId = localStorage.getItem('publicUrlRoute')

    async function signIn(username: string, password: string) {
        try {
            const user = await Auth.signIn(username, password)
            const callback_url = localStorage.getItem('original_url')
            if (user) {
                setSignInUserData(user)
                console.log(user)
                let invitationKey = localStorage.getItem('invitationKey')
                if (!stringUtils.isNullOrUndefinedOrEmpty(invitationKey)) {
                    navigate('/invitation')
                } else {
                    //nevigate to application main page
                    if (
                        publicUrlRouteId &&
                        publicUrlRouteId?.startsWith('/catalog?uuid=')
                    )
                        navigate(publicUrlRouteId)
                    else if (callback_url && callback_url !== '') {
                        navigate(callback_url)
                    } else {
                        localStorage.setItem('loginRoute', 'catalog')
                        navigate('/catalog')
                    }
                }
            }
        } catch (error) {
            console.log('error signing in', error)
            setSignInError(error)
        }
    }

    const onUserEmailChange = useCallback((event: ChangeEvent<HTMLElement>) => {
        const target = event.currentTarget as HTMLTextAreaElement
        if (target.value) {
            setUserEmail(target.value)
        }
    }, [])
    const onUserPasswordChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLTextAreaElement
            if (target.value) {
                setUserPassword(target.value)
            }
        },
        []
    )

    const onSubmit = useCallback(
        async (event: FormEvent<HTMLFormElement>) => {
            event.preventDefault()

            const form = event.currentTarget
            let signInResult: any
            if (form.checkValidity() === false) {
                event.preventDefault()
                event.stopPropagation()
            }
            setValidated(true)
            setSignInError('')
            if (userEmail && userEmail.indexOf('@') > -1 && userPassword) {
                signIn(userEmail, userPassword) //cognito signIn
            }
        },
        [userEmail, userPassword]
    )

    return {
        signInError,
        validated,
        onSubmit,
        onUserEmailChange,
        onUserPasswordChange,
    }
}
