import { SVGParser } from "./svg"

export {
    HTMLParser,
    ColParser,
    SVGParser,
}

export interface Stack {
    name: string;
    value: number;
    children: Array<Stack>;
}

interface CollapsedSample {
    metadataIndex: number;
    containerName?: string;
    processName: string;
    functions: Array<string>;
    sampleCount: number;
}

function HTMLParser(input: string): Stack {
    const parser = new DOMParser();
    const dom = parser.parseFromString(input, "text/html");
    const scripts = dom.getElementsByTagName("script");
    if (scripts.length != 4) {
        throw {message: "unexpected html file format"};
    }

    // the HTML file has four script elements, the last one being the inlined JS one
    // which is supposed to have the flamegraph data
    const scriptNode = scripts[3];
    const script = scriptNode.text;

    // script.text by now should be the script body.
    // We know that it calls something like 'd3.select("#chart").datum(...)',
    // and our call stacks are inside 'datum(...)'
    //
    // Search for its index
    const idx = script.indexOf(".datum")
    if (idx == -1) {
        throw {message: "unexpected file format"}
    }

    // After '.datum' we expect an open parenthesis,
    // let's find the matching closing one.
    const parenStart = idx + 6; // cause .datum...
    if (script[parenStart] != "(") {
        throw {message: "unexpected token found"}
    }
    let level = 0; // we count open parens with this, when it reaches 0 we found the closing one.
    let closingParenIdx = -1;
    for (let i = parenStart; i < script.length; i++) {
        if (script[i] == "(") {
            level += 1;
            continue;
        }
        if (script[i] == ")") {
            level -= 1;
        }
        if (level == 0) {
            closingParenIdx = i;
            break;
        }
    }

    if (closingParenIdx == -1) {
        throw {message: "did not find closing parenthesis"}
    }

    // Okay, now we can just grab that whole JSON object, hopefully
    const stack = JSON.parse(script.slice(parenStart+1, closingParenIdx-1))
    return stack
}

// For reference: https://github.com/Granulate/gprofiler/blob/master/README.md#collapsed-files
// Collapsed files!
function ColParser(input: string): Stack {
    const lines = input.split("\n");
    var idx = 0;
    // Look for the first "comment" line, parse as json. Its the profile metadata
    let metadata: Object = {};
    for (; idx < lines.length; idx++) {
        var line = lines[idx];
        // Allow empty lines at the top
        line = line.trimLeft();
        if (line.length == 0) {
            continue;
        }
        if (line[0] != "#") {
            throw {message: "unexpected .col file format: no comment line with metadata found"};
        }
        if (line.length < 2) {
            throw {message: "unexpected .col file format"};
        }
        metadata = JSON.parse(line.slice(1));
        break;
    }

    // Move the index after getting the metadata line
    idx += 1

    // We expect the following format
    // <app_metadata_idx>;<container_name>;<process_name>;<frame>;<frame>.... <number_of_samples>
    //
    //
    var samples: Array<CollapsedSample> = [];

    var totalSamples = 0;
    for (; idx < lines.length; idx++) {
        var line = lines[idx];
        line = line.trim();
        if (line == "") {
            continue;
        }
        const lastSpace = line.lastIndexOf(" ");
        if (lastSpace == -1) {
            throw {message: "unexpected format: did not find space while looking for number of samples"};
        }

        const numberOfSamples = +line.slice(lastSpace);
        totalSamples += numberOfSamples;
        line = line.slice(undefined, lastSpace);
        const bits = line.split(";");
        if (bits.length < 4) {
            throw {message: "unexpected stack format"};
        }
        const appMetadataIdx = +bits[0]
        const containerName = bits[1]
        const processName = bits[2]
        // The resulting tree starts from the root, then the first level
        // is the process name, and the next levels are the actual functions.
        // So, start with processName as the first element in functions array.
        var functions = [processName].concat(bits.slice(3));
        samples.push({
            metadataIndex: appMetadataIdx,
            containerName: containerName,
            processName: processName,
            functions: functions,
            sampleCount: numberOfSamples,
        })
    }

    var root: Stack = {
        name: "root",
        value: totalSamples,
        children: [],
    }

    function add(sample: CollapsedSample, functions: Array<string>, stack: Stack) {
        if (functions.length == 0) {
            return;
        }
        for (let func of functions) {
            var child = stack.children.find((s) => s.name == func);
            if (child === undefined) {
                child = {
                    name: func,
                    value: sample.sampleCount,
                    children: [],
                };
                stack.children.push(child);
            } else {
                child.value += sample.sampleCount;
            }
            add(sample, functions.slice(1), child)
            return
        }
    }

    for (let sample of samples) {
        add(sample, sample.functions, root);
    }

    return root;
}

