import styled from 'styled-components'
import { FunctionComponent, Dispatch, useEffect } from 'react'
import { connect } from 'react-redux'
import {
    getFeedbackById,
    getFeedbackFile,
    getOptimizationRequestById,
    getBenchmarkRequestById,
} from '../../store/myRequest.slice'
import ViewDetailsPanel from '../../components/myRequestPanel/viewDetailsPanel'
import FooterPanel from '../../layout/footer'
import UserPanel from '../../layout/userProfile'
import { useParams } from 'react-router-dom'
import { LoadingState } from '../../models/loadingState'
import HeaderPanel from '../../layout/header'

const ViewDetailsPanelContainer = styled.div`
    display: flex;
    height: ${(props) => props.theme.size.mainContainerHeight};
    width: ${(props) => props.theme.size.mainContainerWidth};
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: space-between;
    @media screen and (min-width: 80rem) {
        overflow-x: hidden;
    }
`

const ViewDetailsHorizontalContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: space-between;
`
const ViewDetailsBodyContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: center;
    background-color: ${(props) => props.theme.color.background.defaultGrey};
    padding-bottom: 10rem;
`

const ViewDetailsContainerReposition = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    margin-left: 2rem;
`

const ViewDetailsPanelWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: flex-start;
    margin-top: 2rem;
`

export interface IViewDetailsContainerProps {
    getFeedbackById: typeof getFeedbackById
    getFeedbackFile: typeof getFeedbackFile
    getOptimizationRequestById: typeof getOptimizationRequestById
    getBenchmarkRequestById: typeof getBenchmarkRequestById
    feedbackResultById?: any
    feedbackResultError?: any
    feedbackId: string
    downloadFileResult: any
    downloadFileLoading: LoadingState
    downloadFileError: any
    optRequestByIdResult: any
    optRequestByIdError: any
    benchmarkResultById: any
    benchmarkResultError: any
    isFeedbackId: boolean | false
    isOptimizationId: boolean | false
    isBenchmarkId: boolean | false
}

const ViewDetailsContainer: FunctionComponent<IViewDetailsContainerProps> & {
    defaultProps: Partial<IViewDetailsContainerProps>
} = ({
    getFeedbackById,
    feedbackResultById,
    feedbackResultError,
    getFeedbackFile,
    downloadFileResult,
    downloadFileLoading,
    downloadFileError,
    getOptimizationRequestById,
    optRequestByIdResult,
    optRequestByIdError,
    getBenchmarkRequestById,
    benchmarkResultById,
    benchmarkResultError,
    isFeedbackId,
    isOptimizationId,
    isBenchmarkId,
}: IViewDetailsContainerProps) => {
    let { feedbackId } = useParams()
    let { requestId } = useParams()
    let { benchmarkId } = useParams()

    const isController =
        window.location.pathname.indexOf('all') > -1 ? true : false

    useEffect(() => {
        if (feedbackId) {
            getFeedbackById({ id: feedbackId, isController: isController })
        }
        if (requestId) {
            getOptimizationRequestById(requestId)
        }
        if (benchmarkId) {
            getBenchmarkRequestById(benchmarkId)
        }
    }, [feedbackId, requestId, benchmarkId])

    return (
        <ViewDetailsPanelContainer>
            <HeaderPanel />
            <ViewDetailsHorizontalContainer>
                <ViewDetailsBodyContainer>
                    <ViewDetailsContainerReposition>
                        <UserPanel></UserPanel>
                        <ViewDetailsPanelWrapper>
                            <ViewDetailsPanel
                                title={
                                    feedbackId
                                        ? 'My Support Request Details'
                                        : requestId
                                        ? 'My Optimization Request Details'
                                        : 'My Benchmark Request Details'
                                }
                                isFeedbackId={feedbackId ? true : false}
                                isOptimizationId={requestId ? true : false}
                                isBenchmarkId={benchmarkId ? true : false}
                                getFeedbackByIdData={getFeedbackById}
                                feedbackResultById={feedbackResultById}
                                feedbackResultError={feedbackResultError}
                                getFeedbackFile={getFeedbackFile}
                                downloadFileResult={downloadFileResult}
                                downloadFileLoading={downloadFileLoading}
                                downloadFileError={downloadFileError}
                                getOptimizationRequestById={
                                    getOptimizationRequestById
                                }
                                optRequestByIdResult={optRequestByIdResult}
                                optRequestByIdError={optRequestByIdError}
                                benchmarkResultById={benchmarkResultById}
                                benchmarkResultError={benchmarkResultError}
                            />
                        </ViewDetailsPanelWrapper>
                    </ViewDetailsContainerReposition>
                </ViewDetailsBodyContainer>
            </ViewDetailsHorizontalContainer>
            <FooterPanel></FooterPanel>
        </ViewDetailsPanelContainer>
    )
}

ViewDetailsContainer.defaultProps = {}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        getFeedbackById: (feedbackByIdRequest: any) =>
            dispatch(getFeedbackById(feedbackByIdRequest)),
        getFeedbackFile: (downloadURL: any) =>
            dispatch(getFeedbackFile(downloadURL)),
        getOptimizationRequestById: (id: any) =>
            dispatch(getOptimizationRequestById(id)),
        getBenchmarkRequestById: (id: any) => {
            dispatch(getBenchmarkRequestById(id))
        },
    }
}

const mapStateToProps = (state: any) => {
    return {
        feedbackResultById: state.feedbackResultById.data,
        feedbackResultError: state.feedbackResultById.error,
        downloadFileResult: state.feedbackAttachmentResult.data,
        downloadFileLoading: state.feedbackAttachmentResult.loading,
        downloadFileError: state.feedbackAttachmentResult.error,
        optRequestByIdResult: state.optimizationRequestByIdResult.data,
        optRequestByIdError: state.optimizationRequestByIdResult.error,
        benchmarkResultById: state.benchmarkResultById.data,
        benchmarkResulterror: state.benchmarkResultById.error,
    }
}

type StateToPropsType = ReturnType<typeof mapStateToProps>
type DispatchToPropsType = typeof mapDispatchToProps

export default connect<StateToPropsType, DispatchToPropsType>(
    mapStateToProps,
    mapDispatchToProps
)(ViewDetailsContainer)
