import StringUtils from '../../lib/stringUtils'

export const nginxLogo = (
    <img
        src={StringUtils.resolve('/nginx.svg')}
        alt="product logo"
        style={{
            height: '100px',
            width: '100px',
        }}
    />
)
export const cassandraLogo = (
    <img
        src={StringUtils.resolve('/cassandra.jpg')}
        alt="product logo"
        style={{
            height: '100px',
            width: '100px',
        }}
    />
)
export const wordpressLogo = (
    <img
        src={StringUtils.resolve('/wordpress.png')}
        alt="product logo"
        style={{
            height: '100px',
            width: '100px',
        }}
    />
)
export const mysqlLogo = (
    <img
        src={StringUtils.resolve('/mysql.png')}
        alt="product logo"
        style={{
            height: '100px',
            width: '100px',
        }}
    />
)
export const rocksdbLogo = (
    <img
        src={StringUtils.resolve('/rocksdb.png')}
        alt="product logo"
        style={{
            height: '100px',
            width: '100px',
            borderRadius: '5rem',
        }}
    />
)
export const tenserflowLogo = (
    <img
        src={StringUtils.resolve('/tenserflow.png')}
        alt="product logo"
        style={{
            height: '100px',
            width: '100px',
        }}
    />
)
export const pytorchLogo = (
    <img
        src={StringUtils.resolve('/pytorch.png')}
        alt="product logo"
        style={{
            height: '100px',
            width: '100px',
        }}
    />
)
export const clickhouseLogo = (
    <img
        src={StringUtils.resolve('/clickhouse.png')}
        alt="product logo"
        style={{
            height: '100px',
            width: '100px',
        }}
    />
)
export const lammpsLogo = (
    <img
        src={StringUtils.resolve('/lammps_logo.png')}
        alt="product logo"
        style={{
            height: '24%',
            width: '100%',
            marginTop: '2%',
        }}
    />
)
export const openFomeLogo = (
    <img
        src={StringUtils.resolve('/openFome_logo.png')}
        alt="product logo"
        style={{
            height: '22%',
            width: 'auto',
            marginTop: '2%',
        }}
    />
)
export const scikitLearnLogo = (
    <img
        src={StringUtils.resolve('/scikitlearn.png')}
        alt="product logo"
        style={{
            height: '30%',
            width: '100%',
            marginTop: '2%',
        }}
    />
)
export const dethStarBenchLogo = (
    <img
        src={StringUtils.resolve('/dethstarbench_logo.png')}
        alt="product logo"
        style={{
            height: '100px',
            width: '100px',
        }}
    />
)
export const kafkaLogo = (
    <img
        src={StringUtils.resolve('/kafka_logo.png')}
        alt="product logo"
        style={{
            height: '50%',
            width: '100%',
        }}
    />
)
export const mongoDbLogo = (
    <img
        src={StringUtils.resolve('/mongodb.png')}
        alt="product logo"
        style={{
            height: '8rem',
            width: '100%',
            marginTop: '-2rem',
        }}
    />
)
export const mssqlLogo = (
    <img
        src={StringUtils.resolve('/mssql.png')}
        alt="product logo"
        style={{
            height: '85px',
            width: '100%',
        }}
    />
)
export const javaLogo = (
    <img
        src={StringUtils.resolve('/java.jpg')}
        alt="product logo"
        style={{
            height: '100px',
            width: '100%',
        }}
    />
)
export const CloudComputingImage = (
    <img
        src={StringUtils.resolve('/default_ server.png')}
        height="100px"
        width="100px"
        alt="cloud logo"
        style={{ marginTop: '-0.8rem' }}
    />
)
export const openSearchLogo = (
    <img
        src={StringUtils.resolve('/opensearch.svg')}
        alt="product logo"
        style={{
            height: '30%',
            width: '60%',
            marginTop: '2%',
        }}
    />
)
export const openJDKLogo = (
    <img
        src={StringUtils.resolve('/openjdk.png')}
        alt="product logo"
        style={{
            height: '30%',
            width: '80%',
            marginTop: '2%',
        }}
    />
)
export const hpcgLogo = (
    <img
        src={StringUtils.resolve('/hpcg.gif')}
        alt="product logo"
        style={{
            height: '30%',
            width: '75%',
            marginTop: '2%',
        }}
    />
)
export const postgreLogo = (
    <img
        src={StringUtils.resolve('/postgresql.png')}
        alt="product logo"
        style={{
            height: '50%',
            width: '80%',
            marginTop: '2%',
        }}
    />
)
export const ffmpegLogo = (
    <img
        src={StringUtils.resolve('/ffmpeg.png')}
        alt="product logo"
        style={{
            height: '90px',
            width: '80%',
        }}
    />
)
export const xboostLogo = (
    <img
        src={StringUtils.resolve('/xgboost.png')}
        alt="product logo"
        style={{
            height: '50px',
            width: '90%',
            marginTop: '2%',
        }}
    />
)
export const envoyLogo = (
    <img
        src={StringUtils.resolve('/envoy.png')}
        alt="product logo"
        style={{
            height: '100px',
            width: '100px',
        }}
    />
)
export const openvinoLogo = (
    <img
        src={StringUtils.resolve('/openvino.png')}
        alt="product logo"
        style={{
            height: '100px',
            width: '100px',
            marginTop: '-1rem',
        }}
    />
)
export const lstioLogo = (
    <img
        src={StringUtils.resolve('/lstio.svg')}
        alt="product logo"
        style={{
            height: '60px',
            width: '100px',
        }}
    />
)
export const calicoLogo = (
    <img
        src={StringUtils.resolve('/calico.png')}
        alt="product logo"
        style={{
            height: '100px',
            width: '100px',
        }}
    />
)
