import {
    FunctionComponent,
    useEffect,
    useRef,
    useState,
    FormEvent,
    useCallback,
} from 'react'
import styled from 'styled-components'
import {
    Button,
    FormControl,
    Spinner,
    Alert,
    FormLabel,
    Form,
    Col,
    Row,
    OverlayTrigger,
    Tooltip,
    Accordion,
    CloseButton,
    Popover,
    Nav,
    InputGroup,
    Modal,
    Breadcrumb,
    BreadcrumbItem,
} from 'react-bootstrap'
import Select from 'react-select'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../config/hooks'
import { attachFile, uploadRegistry } from '../../store/uploadRegistry.slice'
import { LoadingState } from '../../models/loadingState'
import { AddNewOptimizationContainerLogic } from './addNewOptimizationPanel.hook'
import { UploadRegistryResponse } from '../../models/uploadRegistryResponse'
import { AnyAaaaRecord } from 'dns'
import MultiKeyField from './multiKeyField'
import StringUtils from '../../lib/stringUtils'
import { Step } from 'react-form-stepper'
import { CustomStepper } from './customStepper'

const UploadRegistryFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
`
const UploadRegistryFormComponent = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
const HorizontalContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`

const ButtonSpinnerHorizontalComponent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 1rem;
`

const UploadButton = styled(Button)`
    display: flex;
    justify-content: center;
    margin-left: 0.6rem;
    margin-right: 1.35rem;
    border-radius: 0;
    background: #0054ae;
    &:hover {
        border-radius: 0;
        background: #0054ae;
        transition: 0.5s;
    }
`
const CancelButton = styled(Button)`
    display: flex;
    justify-content: center;
    margin-right: 1rem;
    border-radius: 0;
    background: #0054ae;
    &:hover {
        border-radius: 0;
        background: #0054ae;
        transition: 0.5s;
    }
`

const AddMoreFilesButton = styled(Button)`
    display: flex;
    justify-content: center;

    margin-top: 2rem;

    border-radius: ${(props) => props.theme.shape.button.borderRadius};
    background-color: ${(props) =>
        props.theme.color.button.background.default.primary};
    &:hover {
        background-color: ${(props) =>
            props.theme.color.button.background.hover.primary};
        transition: 0.5s;
    }
    &:disabled {
        background-color: ${(props) =>
            props.theme.color.button.background.disabled.primary};
    }
`
const AddTagsButton = styled(Button)`
    display: flex;
    justify-content: center;
    margin-left: 0.5rem !important;
    border-radius: ${(props) => props.theme.shape.button.borderRadius};
    background-color: ${(props) =>
        props.theme.color.button.background.default.primary};
    &:hover {
        background-color: ${(props) =>
            props.theme.color.button.background.hover.primary};
        transition: 0.5s;
    }
`

const TextBoxWrapper = styled(FormControl)`
    display: flex;
    border-radius: ${(props) => props.theme.shape.input.borderRadius};
    margin-bottom: 1rem;
    min-height: 4vh;
`

const FileUploadControl = styled(FormControl)`
    display: flex;
    margin-bottom: 1rem;
    min-height: 4vh;
`

const PageTitleTextLabel = styled(FormLabel)`
    color: var(
        --global-header-light-light-fill,
        var(--black-and-white-white, #fff)
    );
    text-align: center;
    font-family: 'IntelOne Display';
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px; /* 120% */
    letter-spacing: -0.2px;
    text-align: left;
    padding: 1rem 0;
`

const TextLabel = styled(FormLabel)`
    display: flex;
    color: var(--input-label-light-default, #494b51);
    font-family: 'IntelOne Display';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
`

const ModalPopupContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const UploadResultHorizontalContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`

const UploadResultVerticalContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: ${(props) => props.theme.color.background.label.title};
`

const SpinnerComponent = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    justify-content: center;
    align-items: center;
`

const UploadResultTitleTextLabel = styled(FormLabel)`
    display: flex;
    ${(props) => props.theme.typography.medium}
    text-align: left;
    justify-content: left;
    background:  ${(props) => props.theme.color.background.label.title}
    white-space: pre-wrap;
    margin-left: 1rem;
    padding-top:1rem;
`

const UploadResultTextLabel = styled(FormLabel)`
    display: flex;
    ${(props) => props.theme.typography.medium}

    text-align: left;
    white-space: pre-wrap;
    background-color: ${(props) => props.theme.color.background.defaultBeige};
    padding: 1rem;
    margin: 1rem;
    width: 18vw;
    justify-content: left;
`

const UploadTextLabelHorizontalContainer = styled.div`
    display: flex;
`

const AlertComponent = styled(Alert)`
    display: flex;
    margin-top: 1rem;
    width: 35vw;
    justify-content: center;
`
const RequiredTextLabel = styled.span`
    display: flex;
    ${(props) => props.theme.typography.xLarge}
    color: red;
`
const AccordianExpandTags = styled(Accordion)`
    width: 45%;
`
const DeleteMoreFileSpan = styled.span`
    display: inline-flex;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: center;
    margin-top: 2.2rem;
    border-radius: ${(props) => props.theme.shape.button.borderRadius};
    background-color: #00a3fb;
    height: 1.8vw;
`
const AddTagsComponent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 1rem;
`

const TagBannerSpan = styled.span`
    display: inline-flex;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: center;
    padding: 4px 10px;
    margin-left: 1px;
    margin-top: 6px;
    margin-right: 8px;
    background: #00a3fb;
    border-radius: 100px;
    align-items: center;
    line-height: 0.2;
    color: white;
`
const TagsBannerComponent = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
`
const TagsBannerContainer = styled.div`
    width: 60vw;
`

const PopoverKeyDiv = styled.div`
    background-color: #cff4fc;
    font-size: 1rem;
    padding: 0.5rem;
`
const PageTitleTextLabel2 = styled(FormLabel)`
    color: var(--text-default, #2b2c30);
    font-family: IntelOne Display;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px; /* 150% */
    padding: 2rem 0.8rem;
`
const StyledRow = styled(Row)`
    //width: 70%;
    padding: 0.5rem;
`
const FormGroupCol = styled(Form.Group)`
    //width: 50%;
`

const RequiredFieldMessage = styled.span`
    display: flex;
    font-size: 0.875em;
    color: #dc3545;
`
const AddUrlsButton = styled(Button)`
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    border-radius: ${(props) => props.theme.shape.button.borderRadius};
    background-color: ${(props) =>
        props.theme.color.button.background.default.primary};
    &:hover {
        background-color: ${(props) =>
            props.theme.color.button.background.hover.primary};
        transition: 0.5s;
    }
`
const AddUrlsComponent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 1rem;
`
const DeleteUrlSpan = styled.span`
    display: inline-flex;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: center;
    margin-left: 1rem;
    border-radius: 20rem;
    border: 2px solid;
    border-color: #6c757d;
    padding: 0.01rem 0 0.2rem 0;
`
const HeaderComponent = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: -1rem;
    justify-content: center;
    padding: 0 3%;
    background-color: rgb(101, 49, 113);
    background-image: ${(props) =>
        `url('${process.env.PUBLIC_URL}/background-l1-mosaic.svg')`};
    background-position-x: 50%;
    background-position-y: 50%;
    background-repeat: no-repeat;
    background-size: 100%;
    width: 100%;

    height: 200px;
`

export interface IUploadRegistryPanelProps {
    onUploadRegistrySubmit: (uploadRegistryRequest: any) => void
    uploadRegistryloading: LoadingState
    uploadRegistryResult: UploadRegistryResponse | undefined
    uploadRegistryError: any
    getRegistryResult?: any
    id: string | undefined
    addUrlKeyRef?: any
    addUrlValueRef?: any
    formRef?: any
    isController: boolean
    isControllerPage?: boolean
}

const UploadRegistryPanel: FunctionComponent<IUploadRegistryPanelProps> = ({
    onUploadRegistrySubmit,
    uploadRegistryloading,
    uploadRegistryResult,
    uploadRegistryError,
    getRegistryResult,
    id,
    isController,
    isControllerPage,
}: IUploadRegistryPanelProps) => {
    var errorText = null
    if (uploadRegistryError) {
        errorText =
            'We are experiencing issues while adding new optimization request.'
    }
    isControllerPage =
        window.location.pathname.indexOf('controller') > -1 ? true : false

    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const { recipeId } = useParams()
    const addUrlKeyRef = useRef(null)
    const addUrlValueRef = useRef(null)
    const formRef = useRef<HTMLFormElement>(null)
    const {
        validated,
        setValidated,
        onCancle,
        additionalProperties,
        tagValues,
        status,
        existingFilesList,
        setExistingFilesList,
        activeStep,
        onNextClick,
        onPreviousClick,
        optimizationName,
        onOptNameChange,
        optimizationSummary,
        workload,
        onWorkloadChange,
        onOptimizationSummaryChange,
        valueProposition,
        onValuePropositionChange,
        optimizationOwnerEmail,
        onOwnerEmailChange,
        usageGuidance,
        onUsageGuidanceChange,
        performance_details,
        onPerfDetailsChange,
        gProfilerFilter,
        ongProfilerFilterChange,
        tags,
        onTagsChange,
        setOptimizationName,
        setOptimizationSummary,
        setWorkload,
        setValueProposition,
        setOptimizationOwnerEmail,
        setUsageGuidance,
        setPerformance_details,
        setGProfilerFilter,
        setTags,
        approvalStatus,
        setApprovalStatus,
        onClearAll,
        scale,
        setScale,
        legalClaim,
        setLegalClaim,
        platform,
        setPlatform,
        feature,
        setFeature,
        deployment,
        setDeployment,
        useCase,
        setUseCase,
        CSP,
        setCSP,
        swApplication,
        setSWApplication,
        optimizationType,
        setOptimizationType,
        PCRApproved,
        setPCRApproved,
        PDTApproved,
        setPDTApproved,
        readonly,
        setReadonly,
        visibility,
        setVisibility,
        isPremium,
        setIsPremium,
    } = AddNewOptimizationContainerLogic({
        id,
        onUploadRegistrySubmit,
        getRegistryResult,
        addUrlValueRef,
        addUrlKeyRef,
        formRef,
        isController,
        isControllerPage,
    } as IUploadRegistryPanelProps)
    const [finalize, setFinalize] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [urlList, setUrlList] = useState<
        {
            id: string
            isDeleted: boolean
            type: string
            url: string
            otherTypeValue: string
        }[]
    >([])
    const [swConfigList, setSWConfigList] = useState<
        { id: string; isDeleted: boolean; key: string; value: string }[]
    >([])
    const [hwConfigList, setHWConfigList] = useState<
        { id: string; isDeleted: boolean; key: string; value: string }[]
    >([])
    const [biosList, setBiosList] = useState<
        { id: string; isDeleted: boolean; key: string; value: string }[]
    >([])
    const [filesList, setFilesList] = useState<
        {
            id: string
            isDeleted: boolean
            files: FileList
            fileAccessGroups: []
        }[]
    >([])

    const [licenseText, setLicenseText] = useState('')
    const [auxTagsList, setAuxTagsList] = useState<
        { id: string; isDeleted: boolean; key: string; value: string }[]
    >([])
    const [deleteUrlId, setDeleteUrlId] = useState('')
    const [isAddUrl, setIsAddUrl] = useState(false)
    const [totalUrlsCount, setTotalUrlsCount] = useState<number>(0)
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false)

    const handleConfirmationClose = () => {
        setShowConfirmationPopup(false)
        if (isControllerPage) navigate('/allUploadRequest')
        else navigate('/myRequest')
    }

    const attachError = useAppSelector<any>(
        (state) => state.attachFileResult.error
    )
    const submitResult = useAppSelector<any>(
        (state) => state.uploadRegistryResult.data
    )

    useEffect(() => {
        if (location.pathname.indexOf('view') !== -1) setReadonly(true)
        formRef?.current?.reset()
    }, [location])
    useEffect(() => {
        if (additionalProperties) {
            if (
                additionalProperties.useCase &&
                typeof additionalProperties?.useCase !== 'string'
            )
                setUseCase(
                    additionalProperties?.useCase.map((val: any) => ({
                        value: val.value,
                        label: val.value,
                    }))
                )
            if (
                additionalProperties.platform &&
                typeof additionalProperties?.platform !== 'string'
            )
                setPlatform(
                    additionalProperties?.platform.map((val: any) => ({
                        value: val.value,
                        label: val.value,
                    }))
                )
            if (
                additionalProperties.feature &&
                typeof additionalProperties?.feature !== 'string'
            )
                setFeature(
                    additionalProperties?.feature.map((val: any) => ({
                        value: val.value,
                        label: val.value,
                    }))
                )
            if (additionalProperties.scale)
                setScale({
                    value: additionalProperties?.scale,
                    label: additionalProperties?.scale,
                })
            if (additionalProperties.legalClaim)
                setLegalClaim({
                    value: additionalProperties?.legalClaim,
                    label: additionalProperties?.legalClaim,
                })
            if (additionalProperties.deployment)
                setDeployment({
                    value: additionalProperties?.deployment,
                    label: additionalProperties?.deployment,
                })
            if (
                additionalProperties?.swApplications &&
                additionalProperties?.swApplications.length > 0 &&
                typeof additionalProperties?.swApplications !== 'string'
            )
                setSWApplication(
                    additionalProperties?.swApplications.map((val: any) => ({
                        value: val.value,
                        label: val.value,
                    }))
                )
            else if (
                additionalProperties?.swApplication &&
                typeof additionalProperties?.swApplication === 'string'
            ) {
                setSWApplication([
                    {
                        value: additionalProperties?.swApplication,
                        label: additionalProperties?.swApplication,
                    },
                ])
            }
            if (additionalProperties.optimizationType)
                setOptimizationType({
                    value: additionalProperties?.optimizationType,
                    label: additionalProperties?.optimizationType,
                })
            if (additionalProperties.pcrApproved)
                setPCRApproved({
                    value: additionalProperties?.pcrApproved,
                    label: additionalProperties?.pcrApproved,
                })
            if (additionalProperties.pdtApproved)
                setPDTApproved({
                    value: additionalProperties?.pdtApproved,
                    label: additionalProperties?.pdtApproved,
                })
            if (additionalProperties.optimizationName)
                setOptimizationName(additionalProperties.optimizationName)
            if (additionalProperties.optimizationSummary)
                setOptimizationSummary(additionalProperties.optimizationSummary)
            if (additionalProperties.workload)
                setWorkload(additionalProperties.workload)
            if (additionalProperties.valueProposition)
                setValueProposition(additionalProperties.valueProposition)
            if (additionalProperties.optimizationOwner)
                setOptimizationOwnerEmail(
                    additionalProperties.optimizationOwner
                )
            if (additionalProperties.usageGuidance)
                setUsageGuidance(additionalProperties.usageGuidance)
            if (additionalProperties.performance_details)
                setPerformance_details(additionalProperties.performance_details)
            if (additionalProperties.gProfilerFilter)
                setGProfilerFilter(additionalProperties.gProfilerFilter)
            if (additionalProperties.tags) setTags(additionalProperties.tags)

            if (
                additionalProperties.csp &&
                typeof additionalProperties?.csp !== 'string'
            )
                setCSP(
                    additionalProperties?.csp.map((val: any) => ({
                        value: val.value,
                        label: val.value,
                    }))
                )
        }

        if (
            additionalProperties &&
            additionalProperties.urls &&
            additionalProperties.urls.length > 0
        ) {
            let urlArr: {
                id: string
                isDeleted: boolean
                type: string
                url: string
                otherTypeValue: string
            }[] = []
            additionalProperties?.urls.map((url: any, index: number) => {
                urlArr.push({
                    id: `${index + 1}`,
                    isDeleted: false,
                    type: url.type,
                    url: url.value,
                    otherTypeValue: url.otherTypeValue,
                })
            })
            setTotalUrlsCount(urlArr.length)
            setUrlList(urlArr)
        } else {
            if (urlList && urlList.length === 0) {
                setUrlList([
                    {
                        id: '1',
                        isDeleted: false,
                        type: '',
                        url: '',
                        otherTypeValue: '',
                    },
                ])
                setTotalUrlsCount(1)
            }
        }
        if (getRegistryResult?.licenseText)
            setLicenseText(getRegistryResult?.licenseText)
    }, [additionalProperties])

    useEffect(() => {
        if (submitResult) {
            setSubmitted(true)
        }
        const id = recipeId || uploadRegistryResult?.uuid
        if (filesList.length > 0 && uploadRegistryResult && id !== undefined) {
            const filesPromises = filesList
                .filter((files) => files.files !== undefined)
                .map((file) => {
                    const payload = {
                        id,
                        file: file.files[0],
                        isController: isController,
                    }
                    return () => dispatch(attachFile(payload))
                })

            Promise.all(filesPromises.map((promise) => promise())).then(() =>
                setShowConfirmationPopup(true)
            )
        } else if (
            (uploadRegistryResult && uploadRegistryResult['uuid']) ||
            finalize ||
            submitResult
        ) {
            setFinalize(false)
            setShowConfirmationPopup(true)
        }
    }, [uploadRegistryResult, submitResult])

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        let urlArr: any = []
        let swConfigArr: any = []
        let hwConfigArr: any = []
        let biosArr: any = []
        let auxTagsArr: any = []
        let accessGroups: string[] = []

        const form = event.currentTarget

        if (finalize && form.checkValidity() === false) {
            event.preventDefault()
            event.stopPropagation()
            setValidated(true)
            return
        }

        urlList.map((url: any) => {
            if (!url.isDeleted && url.type !== '')
                urlArr.push({
                    type: url.type,
                    value: url.url,
                    otherTypeValue: url.otherTypeValue,
                })
        })
        swConfigList.map((entry: any) => {
            if (!entry.isDeleted && entry.key !== '')
                swConfigArr.push({ key: entry.key, value: entry.value })
        })
        hwConfigList.map((entry: any) => {
            if (!entry.isDeleted && entry.key !== '')
                hwConfigArr.push({ key: entry.key, value: entry.value })
        })
        biosList.map((entry: any) => {
            if (!entry.isDeleted && entry.key !== '')
                biosArr.push({ key: entry.key, value: entry.value })
        })

        auxTagsList.map((entry: any) => {
            if (!entry.isDeleted && entry.key !== '')
                auxTagsArr.push({ key: entry.key, value: entry.value })
        })

        visibility.map((p: any) => {
            accessGroups.push(p.value)
        })

        const formData = new FormData(form),
            formDataObj = Object.fromEntries(formData.entries())

        formDataObj['optimizationName'] = optimizationName
        formDataObj['optimizationSummary'] = optimizationSummary
        formDataObj['workload'] = workload

        formDataObj['platform'] = platform.map((p: any) => ({
            value: p.value,
        }))
        formDataObj['useCase'] = useCase.map((u: any) => ({
            value: u.value,
        }))
        formDataObj['feature'] = feature.map((f: any) => ({
            value: f.value,
        }))
        formDataObj['csp'] = CSP.map((f: any) => ({
            value: f.value,
        }))
        formDataObj['scale'] = scale.value
        formDataObj['deployment'] = deployment.value
        formDataObj['swApplications'] = swApplication.map((f: any) => ({
            value: f.value,
        }))
        formDataObj['optimizationType'] = optimizationType.value
        formDataObj['pcrApproved'] = PCRApproved.value
        formDataObj['pdtApproved'] = PDTApproved.value
        formDataObj['legalClaim'] = legalClaim.value

        formDataObj['valueProposition'] = valueProposition
        formDataObj['optimizationOwner'] = optimizationOwnerEmail
        formDataObj['usageGuidance'] = usageGuidance
        formDataObj['performance_details'] = performance_details
        formDataObj['gProfilerFilter'] = gProfilerFilter
        formDataObj['tags'] = tags

        formDataObj['urls'] = urlArr.map((u: any) => ({
            type: u.type,
            value: u.value,
            otherTypeValue: u.otherTypeValue,
        }))
        formDataObj['swConfiguration'] = swConfigArr.map((u: any) => ({
            key: u.key,
            value: u.value,
        }))
        formDataObj['hwConfiguration'] = hwConfigArr.map((u: any) => ({
            key: u.key,
            value: u.value,
        }))
        formDataObj['bios'] = biosArr.map((u: any) => ({
            key: u.key,
            value: u.value,
        }))

        formDataObj['auxTags'] = auxTagsArr.map((u: any) => ({
            key: u.key,
            value: u.value,
        }))

        const payload: any = {
            requestType: 'optimizationUpload',
            additionalProperties: formDataObj,
        }

        payload['accessGroups'] = accessGroups
        if (licenseText) {
            payload['licenseText'] = licenseText
        }

        if (id) payload['id'] = id
        if (finalize) {
            payload['finalize'] = true
            if (isController && approvalStatus) payload['decision'] = 'Approved'
            if (isController) payload['isPremium'] = isPremium
        } else payload['finalize'] = false
        if (isController)
            payload['isController'] = approvalStatus
                ? approvalStatus
                : isControllerPage
        //add file access groups as object
        if (filesList && filesList.length > 0) {
            const attachmentWithAccess = filesList
                .filter((files) => files.files !== undefined)
                .map((file) => ({
                    accessGroups: file.fileAccessGroups,
                    fileName: file.files[0].name,
                }))
            if (existingFilesList && existingFilesList.length > 0) {
                existingFilesList.map((item) => {
                    attachmentWithAccess.push({
                        accessGroups: item.fileAccessGroups,
                        fileName: item.fileName,
                    })
                })
            }

            const attachmentObj = attachmentWithAccess.reduce(
                (obj, value) => ({
                    ...obj,
                    [value.fileName]: value.accessGroups,
                }),
                {}
            )
            payload['attachmentAccessGroups'] = attachmentObj
        }
        if (finalize && !id) {
            if (
                platform &&
                platform.length > 0 &&
                useCase &&
                useCase.length > 0 &&
                feature &&
                feature.length > 0 &&
                scale.value &&
                deployment.value &&
                legalClaim.value
            ) {
                onUploadRegistrySubmit(payload)
            }
        } else {
            onUploadRegistrySubmit(payload)
        }
    }
    const handleAddUrls = useCallback((event: FormEvent<HTMLElement>) => {
        event.preventDefault()
        setTotalUrlsCount((state) => state + 1)
        setIsAddUrl(true)
    }, [])

    useEffect(() => {
        if (isAddUrl) {
            urlList.push({
                id: `${totalUrlsCount}`,
                isDeleted: false,
                type: '',
                url: '',
                otherTypeValue: '',
            })
            setUrlList(urlList)
            setIsAddUrl(false)
        }
    }, [isAddUrl])

    const handleDeleteUrl = useCallback(
        (event: FormEvent<HTMLElement>) => {
            event.preventDefault()
            const idx = event.currentTarget.id
            setDeleteUrlId(idx)
            urlList.map((url) => {
                if (idx === url.id) {
                    url.isDeleted = true
                }
            })
        },
        [urlList]
    )
    useEffect(() => {
        if (deleteUrlId !== undefined && deleteUrlId !== '') {
            setUrlList(urlList)
        }
    }, [deleteUrlId, urlList])

    const handleUrlTypeSelect = useCallback(
        (target: any, idx: string) => {
            let urlArr: any = []
            urlList.map((url) => {
                urlArr.push({
                    id: url.id,
                    isDeleted: url.isDeleted,
                    type: idx === url.id ? target.value : url.type,
                    url: url.url,
                    otherTypeValue: url.otherTypeValue,
                })
            })
            setUrlList(urlArr)
        },
        [urlList]
    )
    const handleUrlEdit = useCallback(
        (event: FormEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLTextAreaElement
            const idx = event.currentTarget.id
            urlList.map((url) => {
                if (idx === url.id) {
                    url.url = target.value
                }
            })
        },
        [urlList]
    )
    const handleUrlOtherTypeEdit = useCallback(
        (event: FormEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLTextAreaElement
            const idx = event.currentTarget.id
            urlList.map((url) => {
                if (idx === url.id) {
                    url.otherTypeValue = target.value
                }
            })
        },
        [urlList]
    )
    const visibilityOptions =
        tagValues && tagValues['Visibility']
            ? tagValues['Visibility'].map((tag: any) => ({
                  value: tag,
                  label: tag,
              }))
            : []
    const platformOptions = tagValues
        ? tagValues['Platform'].map((tag: any) => ({
              value: tag,
              label: tag,
          }))
        : []
    const featureOptions = tagValues
        ? tagValues['Feature'].map((tag: any) => ({
              value: tag,
              label: tag,
          }))
        : []
    const cspOptions = tagValues
        ? tagValues['CSP'].map((tag: any) => ({
              value: tag,
              label: tag,
          }))
        : []

    const scaleLevelValues = tagValues ? tagValues['Scale level'] : []

    const TypeOptions = tagValues
        ? tagValues['Type'].map((tag: any) => ({
              value: tag,
              label: tag,
          }))
        : []

    const scaleLevelOptions = scaleLevelValues
        ? [...scaleLevelValues].sort().map((tag: any) => ({
              value: tag,
              label: tag,
          }))
        : []

    const legalClaimOptions = tagValues
        ? tagValues['Legal Claim'].map((tag: any) => ({
              value: tag,
              label: tag,
          }))
        : []

    const deploymentOptions = tagValues
        ? tagValues['Deployment'].map((tag: any) => ({
              value: tag,
              label: tag,
          }))
        : []
    const OptimizationTypeOptions =
        tagValues && tagValues['Optimization Type']
            ? tagValues['Optimization Type'].map((tag: any) => ({
                  value: tag,
                  label: tag,
              }))
            : []
    const softwareAppOptions = tagValues
        ? tagValues['Workloads'].map((tag: any) => ({
              value: tag,
              label: tag,
          }))
        : []
    const useCaseOptions = tagValues
        ? tagValues['Use Case'].map((tag: any) => ({
              value: tag,
              label: tag,
          }))
        : []
    const pdtOptions = tagValues
        ? tagValues['PDT Approved'].map((tag: any) => ({
              value: tag,
              label: tag,
          }))
        : []
    const pcrOptions = tagValues
        ? tagValues['PCR Approved'].map((tag: any) => ({
              value: tag,
              label: tag,
          }))
        : []
    const attachmentGroupsOptions = tagValues
        ? tagValues['AttachmentGroups'].map((tag: any) => ({
              value: tag,
              label: tag,
          }))
        : []
    const scaleLevelPopover = (
        <Popover id="popover-basic" style={{ maxWidth: '80%' }}>
            <Popover.Body>
                The maturity of the optimization with respect to scale ranging
                from Optimized by Default to Recipes
                <PopoverKeyDiv>
                    <img
                        src="/sw_maturity_image.png"
                        height="283"
                        width="724"
                        alt="cloud logo"
                    />
                </PopoverKeyDiv>
            </Popover.Body>
        </Popover>
    )

    const renderBesicInfoStep1 = (
        <div>
            <StyledRow>
                <PageTitleTextLabel2>
                    Software Optimization Basic Info
                </PageTitleTextLabel2>
            </StyledRow>
            <StyledRow>
                <FormGroupCol as={Col}>
                    <TextLabel data-testid="opt-name-label">
                        <RequiredTextLabel>*</RequiredTextLabel>
                        Optimization Name
                        <OverlayTrigger
                            placement={'right'}
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                                <Popover
                                    id="pop_timeline"
                                    style={{
                                        maxWidth: '20%',
                                    }}
                                >
                                    <Popover.Body>
                                        <PopoverKeyDiv>
                                            The name to identify the
                                            optimization entry (not more than
                                            1-4 words)
                                        </PopoverKeyDiv>
                                    </Popover.Body>
                                </Popover>
                            }
                        >
                            <i
                                className="uil uil-info-circle pointer"
                                tabIndex={0}
                            ></i>
                        </OverlayTrigger>
                    </TextLabel>
                    <TextBoxWrapper
                        id="optimizationName"
                        name="optimizationName"
                        placeholder="Enter name..."
                        required
                        defaultValue={
                            optimizationName ||
                            additionalProperties?.optimizationName
                        }
                        data-testid="opt-name-value"
                        readOnly={readonly}
                        onChange={onOptNameChange}
                    />
                    <Form.Control.Feedback type="invalid">
                        Please enter an optimization name.
                    </Form.Control.Feedback>
                </FormGroupCol>
                <FormGroupCol as={Col}>
                    <TextLabel data-testid="csp-label">
                        <RequiredTextLabel>*</RequiredTextLabel>
                        Cloud Service Provider (CSP)
                        <OverlayTrigger
                            placement={'right'}
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                                <Popover
                                    id="pop_timeline"
                                    style={{
                                        maxWidth: '20%',
                                    }}
                                >
                                    <Popover.Body>
                                        <PopoverKeyDiv>
                                            Any CSP associated with this
                                            optimization
                                        </PopoverKeyDiv>
                                    </Popover.Body>
                                </Popover>
                            }
                        >
                            <i
                                className="uil uil-info-circle pointer"
                                tabIndex={0}
                            ></i>
                        </OverlayTrigger>
                    </TextLabel>
                    <Select
                        name="csp"
                        id="csp"
                        aria-label="Select csp"
                        isSearchable={true}
                        isClearable={true}
                        isMulti={true}
                        options={cspOptions}
                        value={CSP || additionalProperties?.csp}
                        onChange={(target: any) => setCSP(target)}
                        data-testid="csp-value"
                        isDisabled={readonly}
                    />
                    {validated && CSP && CSP.length === 0 ? (
                        <RequiredFieldMessage>
                            Please select at least one csp.
                        </RequiredFieldMessage>
                    ) : (
                        ''
                    )}
                </FormGroupCol>
            </StyledRow>
            <StyledRow>
                <FormGroupCol as={Col}>
                    <TextLabel data-testid="opt-summary-label">
                        <RequiredTextLabel>*</RequiredTextLabel>
                        Optimization Summary
                        <OverlayTrigger
                            placement={'right'}
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                                <Popover
                                    id="pop_timeline"
                                    style={{
                                        maxWidth: '20%',
                                    }}
                                >
                                    <Popover.Body>
                                        <PopoverKeyDiv>
                                            The 1-2 sentence Optimization
                                            description. May include - the
                                            workload, feature, platform &
                                            performance expected
                                        </PopoverKeyDiv>
                                    </Popover.Body>
                                </Popover>
                            }
                        >
                            <i
                                className="uil uil-info-circle pointer"
                                tabIndex={0}
                            ></i>
                        </OverlayTrigger>
                    </TextLabel>
                    <TextBoxWrapper
                        id="optimizationSummary"
                        name="optimizationSummary"
                        placeholder="Enter summary..."
                        required
                        defaultValue={
                            optimizationSummary ||
                            additionalProperties?.optimizationSummary
                        }
                        data-testid="opt-summary-value"
                        readOnly={readonly}
                        maxLength={255}
                        onChange={onOptimizationSummaryChange}
                    />
                    <Form.Control.Feedback type="invalid">
                        Please enter an optimization summary.
                    </Form.Control.Feedback>
                </FormGroupCol>

                <FormGroupCol as={Col}>
                    <TextLabel data-testid="workload-name-label">
                        Workload
                        <OverlayTrigger
                            placement={'right'}
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                                <Popover
                                    id="pop_timeline"
                                    style={{
                                        maxWidth: '20%',
                                    }}
                                >
                                    <Popover.Body>
                                        <PopoverKeyDiv>
                                            The customer workload or software
                                            engine the optimization is based on
                                            or is related to
                                        </PopoverKeyDiv>
                                    </Popover.Body>
                                </Popover>
                            }
                        >
                            <i
                                className="uil uil-info-circle pointer"
                                tabIndex={0}
                            ></i>
                        </OverlayTrigger>
                    </TextLabel>
                    <TextBoxWrapper
                        id="workload"
                        name="workload"
                        placeholder="Enter workload..."
                        defaultValue={
                            workload || additionalProperties?.workload
                        }
                        data-testid="workload-value"
                        readOnly={readonly}
                        onChange={onWorkloadChange}
                    />
                </FormGroupCol>
            </StyledRow>
            <StyledRow>
                <FormGroupCol as={Col}>
                    <TextLabel data-testid="usecase-label">
                        {' '}
                        <RequiredTextLabel>*</RequiredTextLabel>
                        Optimization Categories
                        <OverlayTrigger
                            placement={'right'}
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                                <Popover
                                    id="pop_timeline"
                                    style={{
                                        maxWidth: '20%',
                                    }}
                                >
                                    <Popover.Body>
                                        <PopoverKeyDiv>
                                            How the optimization can be used for
                                            different customers & audiences
                                        </PopoverKeyDiv>
                                    </Popover.Body>
                                </Popover>
                            }
                        >
                            <i
                                className="uil uil-info-circle pointer"
                                tabIndex={0}
                            ></i>
                        </OverlayTrigger>
                    </TextLabel>
                    <Select
                        name="useCase"
                        aria-label="Select workload categories"
                        data-testid="usecase-value"
                        isSearchable={true}
                        isClearable={true}
                        isMulti={true}
                        options={useCaseOptions}
                        value={useCase || additionalProperties?.useCase}
                        onChange={(target: any) => setUseCase(target)}
                        isDisabled={readonly}
                    />
                    {validated && useCase && useCase.length === 0 ? (
                        <RequiredFieldMessage>
                            Please select workload categories.
                        </RequiredFieldMessage>
                    ) : (
                        ''
                    )}
                </FormGroupCol>
                <FormGroupCol as={Col}>
                    <TextLabel data-testid="scalelevel-label">
                        <RequiredTextLabel>*</RequiredTextLabel>
                        Scale level
                        <OverlayTrigger
                            placement={'right'}
                            delay={{ show: 250, hide: 400 }}
                            overlay={scaleLevelPopover}
                        >
                            <i
                                className="uil uil-info-circle pointer"
                                tabIndex={0}
                            ></i>
                        </OverlayTrigger>
                    </TextLabel>
                    <Select
                        name="scale"
                        aria-label="Select scale level"
                        data-testid="scalelevel-value"
                        isSearchable={false}
                        isClearable={false}
                        isMulti={false}
                        options={scaleLevelOptions}
                        value={scale || null}
                        onChange={(target: any) => setScale(target)}
                        isDisabled={readonly}
                    />
                    {validated && scale.length === 0 ? (
                        <RequiredFieldMessage>
                            Please enter scale level.
                        </RequiredFieldMessage>
                    ) : (
                        ''
                    )}
                </FormGroupCol>
            </StyledRow>

            <StyledRow>
                <FormGroupCol as={Col}>
                    <TextLabel data-testid="legal-perfClaim-label">
                        <RequiredTextLabel>*</RequiredTextLabel>
                        Data Classification
                        <OverlayTrigger
                            placement={'right'}
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                                <Popover
                                    id="pop_timeline"
                                    style={{
                                        maxWidth: '20%',
                                    }}
                                >
                                    <Popover.Body>
                                        <PopoverKeyDiv>
                                            Reflects how this data can be shared
                                            with external sources. Internal
                                            only, NDA or Public
                                        </PopoverKeyDiv>
                                    </Popover.Body>
                                </Popover>
                            }
                        >
                            <i
                                className="uil uil-info-circle pointer"
                                tabIndex={0}
                            ></i>
                        </OverlayTrigger>
                    </TextLabel>
                    <Select
                        name="legalClaim"
                        aria-label="Select data classification"
                        data-testid="legal-perfClaim-value"
                        isSearchable={false}
                        isClearable={false}
                        isMulti={false}
                        options={legalClaimOptions}
                        value={legalClaim || null}
                        onChange={(target: any) => setLegalClaim(target)}
                        isDisabled={readonly}
                    />
                    {validated && legalClaim.length === 0 ? (
                        <RequiredFieldMessage>
                            Please select data classification.
                        </RequiredFieldMessage>
                    ) : (
                        ''
                    )}
                </FormGroupCol>
                <FormGroupCol as={Col}>
                    <TextLabel data-testid="deployement-label">
                        <RequiredTextLabel>*</RequiredTextLabel>
                        Deployment
                        <OverlayTrigger
                            placement={'right'}
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                                <Popover
                                    id="pop_timeline"
                                    style={{
                                        maxWidth: '20%',
                                    }}
                                >
                                    <Popover.Body>
                                        <PopoverKeyDiv>
                                            This optimization targets what kind
                                            of environments: Cloud, On-Prem or
                                            Both?
                                        </PopoverKeyDiv>
                                    </Popover.Body>
                                </Popover>
                            }
                        >
                            <i
                                className="uil uil-info-circle pointer"
                                tabIndex={0}
                            ></i>
                        </OverlayTrigger>
                    </TextLabel>
                    <Select
                        name="deployment"
                        aria-label="Select deployment"
                        data-testid="deployment-value"
                        isSearchable={false}
                        isClearable={false}
                        isMulti={false}
                        options={deploymentOptions}
                        value={deployment}
                        onChange={(target: any) => setDeployment(target)}
                        isDisabled={readonly}
                    />
                    {validated && deployment.length === 0 ? (
                        <RequiredFieldMessage>
                            Please select deployment.
                        </RequiredFieldMessage>
                    ) : (
                        ''
                    )}
                </FormGroupCol>
            </StyledRow>
            <StyledRow>
                <FormGroupCol as={Col}>
                    <TextLabel data-testid="feature-label">
                        <RequiredTextLabel>*</RequiredTextLabel>
                        Feature
                        <OverlayTrigger
                            placement={'right'}
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                                <Popover
                                    id="pop_timeline"
                                    style={{
                                        maxWidth: '20%',
                                    }}
                                >
                                    <Popover.Body>
                                        <PopoverKeyDiv>
                                            Any feature associated with this
                                            optimization
                                        </PopoverKeyDiv>
                                    </Popover.Body>
                                </Popover>
                            }
                        >
                            <i
                                className="uil uil-info-circle pointer"
                                tabIndex={0}
                            ></i>
                        </OverlayTrigger>
                    </TextLabel>
                    <Select
                        name="feature"
                        id="feature"
                        aria-label="Select feature"
                        isSearchable={true}
                        isClearable={true}
                        isMulti={true}
                        options={featureOptions}
                        value={feature || additionalProperties?.feature}
                        onChange={(target: any) => setFeature(target)}
                        data-testid="feature-value"
                        isDisabled={readonly}
                    />
                    {validated && feature && feature.length === 0 ? (
                        <RequiredFieldMessage>
                            Please select at least one feature.
                        </RequiredFieldMessage>
                    ) : (
                        ''
                    )}
                </FormGroupCol>
                <FormGroupCol as={Col}>
                    <TextLabel data-testid="platform-label">
                        <RequiredTextLabel>*</RequiredTextLabel>
                        Platform
                        <OverlayTrigger
                            placement={'right'}
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                                <Popover
                                    id="pop_timeline"
                                    style={{
                                        maxWidth: '20%',
                                    }}
                                >
                                    <Popover.Body>
                                        <PopoverKeyDiv>
                                            The Platforms & Processor this
                                            optimization works on
                                        </PopoverKeyDiv>
                                    </Popover.Body>
                                </Popover>
                            }
                        >
                            <i
                                className="uil uil-info-circle pointer"
                                tabIndex={0}
                            ></i>
                        </OverlayTrigger>
                    </TextLabel>
                    <Select
                        name="platform"
                        isSearchable={true}
                        isClearable={true}
                        isMulti={true}
                        options={platformOptions}
                        value={platform || additionalProperties?.platform}
                        onChange={(target: any) => setPlatform(target)}
                        data-testid="platform-value"
                        isDisabled={readonly}
                    />
                    {validated && platform && platform.length === 0 ? (
                        <RequiredFieldMessage>
                            Please select at least one platform.
                        </RequiredFieldMessage>
                    ) : (
                        ''
                    )}
                </FormGroupCol>
            </StyledRow>
            <StyledRow>
                <FormGroupCol as={Col}>
                    <TextLabel data-testid="sw-application-label">
                        <RequiredTextLabel>*</RequiredTextLabel>
                        SW Application
                        <OverlayTrigger
                            placement={'right'}
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                                <Popover
                                    id="pop_timeline"
                                    style={{
                                        maxWidth: '20%',
                                    }}
                                >
                                    <Popover.Body>
                                        <PopoverKeyDiv>
                                            Software Application
                                        </PopoverKeyDiv>
                                    </Popover.Body>
                                </Popover>
                            }
                        >
                            <i
                                className="uil uil-info-circle pointer"
                                tabIndex={0}
                            ></i>
                        </OverlayTrigger>
                    </TextLabel>
                    <Select
                        name="swApplication"
                        aria-label="Select SW Application"
                        data-testid="sw-application-value"
                        isSearchable={true}
                        isClearable={true}
                        isMulti={true}
                        options={softwareAppOptions}
                        value={swApplication}
                        onChange={(target: any) => setSWApplication(target)}
                        isDisabled={readonly}
                    />
                    {validated && swApplication.length === 0 ? (
                        <RequiredFieldMessage>
                            Please select SW application.
                        </RequiredFieldMessage>
                    ) : (
                        ''
                    )}
                </FormGroupCol>
                <FormGroupCol as={Col}>
                    <TextLabel data-testid="optType-label">
                        <RequiredTextLabel>*</RequiredTextLabel>
                        Type
                        <OverlayTrigger
                            placement={'right'}
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                                <Popover
                                    id="pop_timeline"
                                    style={{
                                        maxWidth: '20%',
                                    }}
                                >
                                    <Popover.Body>
                                        <PopoverKeyDiv>
                                            Optimization Type
                                        </PopoverKeyDiv>
                                    </Popover.Body>
                                </Popover>
                            }
                        >
                            <i
                                className="uil uil-info-circle pointer"
                                tabIndex={0}
                            ></i>
                        </OverlayTrigger>
                    </TextLabel>
                    <Select
                        name="optimizationType"
                        aria-label="Select Type"
                        data-testid="optType-value"
                        isSearchable={false}
                        isClearable={false}
                        isMulti={false}
                        options={OptimizationTypeOptions}
                        value={optimizationType}
                        onChange={(target: any) => setOptimizationType(target)}
                        isDisabled={readonly}
                    />
                    {validated && optimizationType.length === 0 ? (
                        <RequiredFieldMessage>
                            Please select Type.
                        </RequiredFieldMessage>
                    ) : (
                        ''
                    )}
                </FormGroupCol>
            </StyledRow>
            <StyledRow>
                <FormGroupCol as={Col}>
                    <TextLabel data-testid="pcr-approved-name-label">
                        <RequiredTextLabel>*</RequiredTextLabel>
                        PCR Approved
                        <OverlayTrigger
                            placement={'right'}
                            delay={{
                                show: 250,
                                hide: 400,
                            }}
                            overlay={
                                <Popover
                                    id="pop_timeline"
                                    style={{
                                        maxWidth: '20%',
                                    }}
                                >
                                    <Popover.Body>
                                        <PopoverKeyDiv>
                                            {`Has the optimization been
                                                 approved by Performance PCR?`}
                                        </PopoverKeyDiv>
                                    </Popover.Body>
                                </Popover>
                            }
                        >
                            <i
                                className="uil uil-info-circle pointer"
                                tabIndex={0}
                            ></i>
                        </OverlayTrigger>
                    </TextLabel>
                    <Select
                        name="PCR Approved"
                        aria-label="Select pcr approved"
                        data-testid="pcr-approved-value"
                        isSearchable={false}
                        isClearable={false}
                        isMulti={false}
                        options={pcrOptions}
                        value={PCRApproved}
                        onChange={(target: any) => setPCRApproved(target)}
                        isDisabled={readonly}
                    />
                    {validated && PCRApproved.length === 0 ? (
                        <RequiredFieldMessage>
                            Please select PCR Approved.
                        </RequiredFieldMessage>
                    ) : (
                        ''
                    )}
                </FormGroupCol>
                <FormGroupCol as={Col}>
                    <TextLabel data-testid="pdt-approved-name-label">
                        <RequiredTextLabel>*</RequiredTextLabel>
                        PDT Approved
                        <OverlayTrigger
                            placement={'right'}
                            delay={{
                                show: 250,
                                hide: 400,
                            }}
                            overlay={
                                <Popover
                                    id="pop_timeline"
                                    style={{
                                        maxWidth: '20%',
                                    }}
                                >
                                    <Popover.Body>
                                        <PopoverKeyDiv>
                                            {`Has the optimization been
                                                 approved by Performance PDT?`}
                                        </PopoverKeyDiv>
                                    </Popover.Body>
                                </Popover>
                            }
                        >
                            <i
                                className="uil uil-info-circle pointer"
                                tabIndex={0}
                            ></i>
                        </OverlayTrigger>
                    </TextLabel>
                    <Select
                        name="PDT Approved"
                        aria-label="Select PDT approved"
                        data-testid="pdt-approved-value"
                        isSearchable={false}
                        isClearable={false}
                        isMulti={false}
                        options={pdtOptions}
                        value={PDTApproved}
                        onChange={(target: any) => setPDTApproved(target)}
                        isDisabled={readonly}
                    />
                    {validated && PDTApproved.length === 0 ? (
                        <RequiredFieldMessage>
                            Please select PDT Approved.
                        </RequiredFieldMessage>
                    ) : (
                        ''
                    )}
                </FormGroupCol>
            </StyledRow>
        </div>
    )
    const renderDetailsStep2 = (
        <div>
            <StyledRow>
                <PageTitleTextLabel2>
                    Software Optimization Details
                </PageTitleTextLabel2>
            </StyledRow>
            <StyledRow>
                <FormGroupCol as={Col}>
                    <TextLabel data-testid="valueProp-label">
                        <RequiredTextLabel>*</RequiredTextLabel>
                        Value Proposition
                        <OverlayTrigger
                            placement={'right'}
                            delay={{
                                show: 250,
                                hide: 400,
                            }}
                            overlay={
                                <Popover
                                    id="pop_timeline"
                                    style={{
                                        maxWidth: '20%',
                                    }}
                                >
                                    <Popover.Body>
                                        <PopoverKeyDiv>
                                            The value propositions this
                                            optimization brings to targeted
                                            audiences, segments and use cases.
                                            May be more than one
                                        </PopoverKeyDiv>
                                    </Popover.Body>
                                </Popover>
                            }
                        >
                            <i
                                className="uil uil-info-circle pointer"
                                tabIndex={0}
                            ></i>
                        </OverlayTrigger>
                    </TextLabel>
                    <Form.Control
                        id="valueProposition"
                        name="valueProposition"
                        placeholder="Enter value proposition..."
                        as="textarea"
                        rows={3}
                        required
                        data-testid="valueProp-text"
                        defaultValue={
                            additionalProperties?.valueProposition ||
                            valueProposition
                        }
                        readOnly={readonly}
                        onChange={onValuePropositionChange}
                    />
                    <Form.Control.Feedback type="invalid">
                        Please enter value proposition.
                    </Form.Control.Feedback>
                </FormGroupCol>
                <FormGroupCol as={Col}>
                    <TextLabel data-testid="opt_owner-label">
                        <RequiredTextLabel>*</RequiredTextLabel>
                        Optimization Owner Email
                    </TextLabel>
                    <Form.Control
                        id="optimizationOwner"
                        name="optimizationOwner"
                        placeholder="Enter optimization owner email..."
                        data-testid="opt_owner-text"
                        defaultValue={
                            additionalProperties?.optimizationOwner ||
                            optimizationOwnerEmail
                        }
                        readOnly={readonly}
                        required
                        onChange={onOwnerEmailChange}
                        type="email"
                        multiple
                    />
                    <Form.Control.Feedback type="invalid">
                        Please enter an optimization owner email address in
                        format name@xyg.com.
                    </Form.Control.Feedback>
                </FormGroupCol>
            </StyledRow>
            <StyledRow>
                <FormGroupCol as={Col}>
                    <TextLabel data-testid="usage_guidance-label">
                        How To Apply
                        <OverlayTrigger
                            placement={'right'}
                            delay={{
                                show: 250,
                                hide: 400,
                            }}
                            overlay={
                                <Popover
                                    id="pop_timeline"
                                    style={{
                                        maxWidth: '20%',
                                    }}
                                >
                                    <Popover.Body>
                                        <PopoverKeyDiv>
                                            Any notes on optimization guidance
                                            and usage. Unsupported,
                                            discontinued, partner messaging,
                                            competitive stance & issues,
                                            pre-requisites. Any changes that
                                            happen through the workload
                                            lifecycle.
                                        </PopoverKeyDiv>
                                    </Popover.Body>
                                </Popover>
                            }
                        >
                            <i
                                className="uil uil-info-circle pointer"
                                tabIndex={0}
                            ></i>
                        </OverlayTrigger>
                    </TextLabel>
                    <Form.Control
                        id="usageGuidance"
                        name="usageGuidance"
                        placeholder="Enter steps to execute the optimization..."
                        as="textarea"
                        rows={3}
                        data-testid="usage_guidance-text"
                        defaultValue={
                            additionalProperties?.usageGuidance || usageGuidance
                        }
                        readOnly={readonly}
                        onChange={onUsageGuidanceChange}
                    />
                </FormGroupCol>
                <FormGroupCol as={Col}>
                    <TextLabel data-testid="perf-label">
                        Performance Details
                        <OverlayTrigger
                            placement={'right'}
                            delay={{
                                show: 250,
                                hide: 400,
                            }}
                            overlay={
                                <Popover
                                    id="pop_timeline"
                                    style={{
                                        maxWidth: '20%',
                                    }}
                                >
                                    <Popover.Body>
                                        <PopoverKeyDiv>
                                            Details (with appropriate context)
                                            about all the performance changes
                                            observed for all the relevant
                                            parameters.
                                        </PopoverKeyDiv>
                                    </Popover.Body>
                                </Popover>
                            }
                        >
                            <i
                                className="uil uil-info-circle pointer"
                                tabIndex={0}
                            ></i>
                        </OverlayTrigger>
                    </TextLabel>
                    <Form.Control
                        id="performance_details"
                        name="performance_details"
                        placeholder="Enter performance details..."
                        as="textarea"
                        rows={3}
                        data-testid="perf-text"
                        defaultValue={
                            performance_details ||
                            additionalProperties?.performance_details
                        }
                        readOnly={readonly}
                        onChange={onPerfDetailsChange}
                    />
                </FormGroupCol>
            </StyledRow>
            <StyledRow>
                <FormGroupCol as={Col}>
                    <TextLabel data-testid="gprofiler-filter-label">
                        gProfiler Filter
                        <OverlayTrigger
                            placement={'right'}
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                                <Popover
                                    id="pop_timeline"
                                    style={{
                                        maxWidth: '20%',
                                    }}
                                >
                                    <Popover.Body>
                                        <PopoverKeyDiv>
                                            gprofiler filter
                                        </PopoverKeyDiv>
                                    </Popover.Body>
                                </Popover>
                            }
                        >
                            <i
                                className="uil uil-info-circle pointer"
                                tabIndex={0}
                            ></i>
                        </OverlayTrigger>
                    </TextLabel>
                    <TextBoxWrapper
                        id="gProfilerFilter"
                        name="gProfilerFilter"
                        placeholder="Enter gProfiler Filter..."
                        defaultValue={
                            gProfilerFilter ||
                            additionalProperties?.gProfilerFilter
                        }
                        data-testid="gprofiler-filter-value"
                        readOnly={readonly}
                        onChange={ongProfilerFilterChange}
                    />
                </FormGroupCol>
                <FormGroupCol as={Col}>
                    <TextLabel data-testid="tags-label">
                        Tags
                        <OverlayTrigger
                            placement={'right'}
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                                <Popover
                                    id="pop_timeline"
                                    style={{
                                        maxWidth: '20%',
                                    }}
                                >
                                    <Popover.Body>
                                        <PopoverKeyDiv>Data Tags</PopoverKeyDiv>
                                    </Popover.Body>
                                </Popover>
                            }
                        >
                            <i
                                className="uil uil-info-circle pointer"
                                tabIndex={0}
                            ></i>
                        </OverlayTrigger>
                    </TextLabel>
                    <TextBoxWrapper
                        id="tags"
                        name="tags"
                        placeholder="Enter tags..."
                        defaultValue={tags || additionalProperties?.tags}
                        data-testid="tags-value"
                        readOnly={readonly}
                        onChange={onTagsChange}
                    />
                </FormGroupCol>
            </StyledRow>

            <StyledRow>
                <MultiKeyField
                    label="SOFTWARE CONFIGURATION"
                    keyLabel="SW Parameters"
                    valueLabel="Version Number/Value"
                    name="swConfiguration"
                    entries={swConfigList}
                    setEntries={setSWConfigList}
                    additionalProperties={additionalProperties}
                    readonly={readonly}
                    type="text"
                    required={false}
                    infoText="The SW Stack and associated versions that this Optimization utilizes"
                />
            </StyledRow>
            <StyledRow>
                <MultiKeyField
                    label="HARDWARE CONFIGURATION"
                    keyLabel="HW Parameters"
                    valueLabel="Version Number/Value"
                    name="hwConfiguration"
                    entries={hwConfigList}
                    setEntries={setHWConfigList}
                    additionalProperties={additionalProperties}
                    readonly={readonly}
                    type="text"
                    required={false}
                    infoText="HW Configurations this Optimizations utilizes"
                />
            </StyledRow>
            <StyledRow>
                <MultiKeyField
                    label="BIOS CONFIGURATION"
                    keyLabel="BIOS Parameters"
                    valueLabel="Version Number/Value"
                    name="bios"
                    entries={biosList}
                    setEntries={setBiosList}
                    additionalProperties={additionalProperties}
                    readonly={readonly}
                    type="text"
                    required={false}
                    infoText="BIOS Configurations this Optimizations utilizes"
                />
            </StyledRow>
        </div>
    )
    const renderResourcesStep3 = (
        <div>
            <StyledRow>
                <PageTitleTextLabel2>
                    Software Optimization Resources
                </PageTitleTextLabel2>
            </StyledRow>
            <StyledRow>
                <AddTagsComponent>
                    <AccordianExpandTags
                        defaultActiveKey="0"
                        style={{
                            width: '100%',
                        }}
                    >
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                Links{' '}
                                <OverlayTrigger
                                    placement={'right'}
                                    delay={{
                                        show: 250,
                                        hide: 400,
                                    }}
                                    overlay={
                                        <Popover
                                            id="pop_timeline"
                                            style={{
                                                maxWidth: '20%',
                                            }}
                                        >
                                            <Popover.Body>
                                                <PopoverKeyDiv>
                                                    {
                                                        'Please select the type of collateral and provide the appropriate link to the collateral - if any.'
                                                    }
                                                </PopoverKeyDiv>
                                            </Popover.Body>
                                        </Popover>
                                    }
                                >
                                    <i
                                        className="uil uil-info-circle pointer"
                                        tabIndex={0}
                                    ></i>
                                </OverlayTrigger>
                            </Accordion.Header>
                            <Accordion.Body>
                                {urlList.map((url) => {
                                    return (
                                        <div>
                                            {!url.isDeleted ? (
                                                <Row>
                                                    <Form.Group
                                                        as={Col}
                                                        md={
                                                            url.type === 'Other'
                                                                ? '3'
                                                                : '5'
                                                        }
                                                    >
                                                        <TextLabel
                                                            style={{
                                                                fontSize:
                                                                    '1rem',
                                                            }}
                                                        >
                                                            Link Type
                                                        </TextLabel>

                                                        <Select
                                                            id={`type_${url.id}`}
                                                            isSearchable={false}
                                                            isClearable={false}
                                                            isMulti={false}
                                                            options={
                                                                TypeOptions
                                                            }
                                                            defaultValue={
                                                                url.type
                                                                    ? [
                                                                          {
                                                                              value: url.type,
                                                                              label: url.type,
                                                                          },
                                                                      ]
                                                                    : null
                                                            }
                                                            onChange={(
                                                                target: any
                                                            ) =>
                                                                handleUrlTypeSelect(
                                                                    target,
                                                                    url.id
                                                                )
                                                            }
                                                            isDisabled={
                                                                readonly
                                                            }
                                                        />
                                                    </Form.Group>
                                                    {url &&
                                                    url.type === 'Other' ? (
                                                        <Form.Group
                                                            as={Col}
                                                            md="3"
                                                        >
                                                            <TextLabel
                                                                style={{
                                                                    fontSize:
                                                                        '1rem',
                                                                }}
                                                            >
                                                                Other Link Label
                                                            </TextLabel>
                                                            <TextBoxWrapper
                                                                id={`${url.id}`}
                                                                placeholder="Enter other link label..."
                                                                defaultValue={
                                                                    url.otherTypeValue ||
                                                                    null
                                                                }
                                                                readOnly={
                                                                    readonly
                                                                }
                                                                onChange={
                                                                    handleUrlOtherTypeEdit
                                                                }
                                                            />
                                                        </Form.Group>
                                                    ) : (
                                                        ''
                                                    )}

                                                    <Form.Group
                                                        as={Col}
                                                        md={
                                                            url.type === 'Other'
                                                                ? '3'
                                                                : '5'
                                                        }
                                                    >
                                                        <TextLabel
                                                            style={{
                                                                fontSize:
                                                                    '1rem',
                                                            }}
                                                        >
                                                            Link URL
                                                        </TextLabel>
                                                        <TextBoxWrapper
                                                            id={`${url.id}`}
                                                            placeholder="Enter url..."
                                                            defaultValue={
                                                                url.url || null
                                                            }
                                                            readOnly={readonly}
                                                            onChange={
                                                                handleUrlEdit
                                                            }
                                                        />
                                                    </Form.Group>
                                                    <Form.Group
                                                        as={Col}
                                                        style={{
                                                            width: '5rem',
                                                            marginTop: '2.2rem',
                                                        }}
                                                    >
                                                        {!readonly ? (
                                                            <DeleteUrlSpan>
                                                                <CloseButton
                                                                    className="p-1"
                                                                    onClick={
                                                                        handleDeleteUrl
                                                                    }
                                                                    id={`${url.id}`}
                                                                />
                                                            </DeleteUrlSpan>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </Form.Group>
                                                </Row>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    )
                                })}
                                <Row>
                                    <Form.Group
                                        as={Col}
                                        md="3"
                                        style={{
                                            padding: '2rem 1rem',
                                        }}
                                    >
                                        {!readonly ? (
                                            <a
                                                href="javascript:void(0)"
                                                onClick={handleAddUrls}
                                            >
                                                +Add More Link
                                            </a>
                                        ) : (
                                            ''
                                        )}
                                    </Form.Group>
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    </AccordianExpandTags>
                </AddTagsComponent>
            </StyledRow>
            <StyledRow>
                <MultiKeyField
                    label="External File"
                    keyLabel=""
                    valueLabel=""
                    name="files"
                    entries={filesList}
                    uuid={id}
                    isController={isController}
                    setEntries={setFilesList}
                    additionalProperties={additionalProperties}
                    existingFilesList={existingFilesList}
                    setExistingFilesList={setExistingFilesList}
                    readonly={readonly}
                    type="file"
                    required={false}
                    infoText="Please attach any solution files (pdf, doc, docx, jpg, pptx, ppt etc.) and recipes that you intend to provide to the customer (Internal and External). The files visible to the external customer should have the filename in the mentioned format: “EXTERNAL_filename”"
                    licenseText={licenseText}
                    setLicenseText={setLicenseText}
                    addButtonText="+Add New File"
                    attachmentAccessLevelOptions={attachmentGroupsOptions}
                />
            </StyledRow>
            {isController ? (
                <div>
                    <StyledRow>
                        <MultiKeyField
                            label="Additional Metadata"
                            keyLabel="Tags"
                            valueLabel="Value"
                            name="auxTags"
                            entries={auxTagsList}
                            setEntries={setAuxTagsList}
                            additionalProperties={additionalProperties}
                            readonly={readonly}
                            type="text"
                            required={false}
                            infoText="The additional metadata."
                            addButtonText="+Add New Metadata"
                        />
                    </StyledRow>

                    <StyledRow style={{ width: '50%' }}>
                        <FormGroupCol as={Col}>
                            <TextLabel data-testid="visibility-label">
                                Visibility
                            </TextLabel>
                            <Select
                                id="accessGroups"
                                isSearchable={true}
                                isClearable={true}
                                isMulti={true}
                                options={visibilityOptions}
                                value={visibility}
                                onChange={(target: any) =>
                                    setVisibility(target)
                                }
                                data-testid="visibility-value"
                                isDisabled={readonly}
                            />
                        </FormGroupCol>
                    </StyledRow>

                    <StyledRow style={{ padding: '1rem' }}>
                        <Form.Check
                            type="checkbox"
                            key="approved"
                            id="approved"
                            label="Approved"
                            aria-label="is approved"
                            checked={approvalStatus}
                            data-testid={`approved-value`}
                            onChange={() => setApprovalStatus(!approvalStatus)}
                            disabled={readonly}
                        />

                        <Form.Check
                            type="checkbox"
                            key="premium"
                            id="premium"
                            label="Premium"
                            aria-label="is premium"
                            checked={isPremium}
                            onChange={() => setIsPremium(!isPremium)}
                            disabled={readonly}
                        />
                    </StyledRow>
                </div>
            ) : (
                ''
            )}
        </div>
    )
    const getActiveStepsComponent = () => {
        switch (activeStep) {
            case 0:
                return renderBesicInfoStep1
            case 1:
                return renderDetailsStep2
            case 2:
                return renderResourcesStep3
            default:
                return null
        }
    }

    return (
        <UploadRegistryFormContainer>
            <HorizontalContainer>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        background: '#fff',
                    }}
                >
                    <Breadcrumb style={{ padding: '0.5rem 1rem' }}>
                        <BreadcrumbItem
                            href={StringUtils.resolve('/catalog')}
                            style={{ textDecoration: 'none', color: '#0068b5' }}
                        >
                            Intel Optimization Hub
                        </BreadcrumbItem>
                        {id && isControllerPage ? (
                            <BreadcrumbItem
                                href={StringUtils.resolve('/allUploadRequest')}
                                style={{
                                    textDecoration: 'none',
                                    color: '#0068b5',
                                }}
                            >
                                All Request
                            </BreadcrumbItem>
                        ) : id ? (
                            <BreadcrumbItem
                                href={StringUtils.resolve('/myRequest')}
                                style={{
                                    textDecoration: 'none',
                                    color: '#0068b5',
                                }}
                            >
                                My Request
                            </BreadcrumbItem>
                        ) : (
                            ''
                        )}

                        <BreadcrumbItem
                            active
                            style={{ fontWeight: '500', color: '#262626' }}
                        >
                            {id ? 'View or Edit' : 'Add New'} Optimization
                        </BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <HeaderComponent>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <PageTitleTextLabel data-testid="addNewOptimizationPage-title">
                            {id ? 'View/Edit' : 'Add New'} Optimization
                        </PageTitleTextLabel>
                    </div>
                    <div>
                        <CustomStepper
                            activeStep={activeStep}
                            style={{ padding: '0.2rem 30%' }}
                        >
                            <Step label="Basic Info" />
                            <Step label="Details" />
                            <Step label="Resources" />
                        </CustomStepper>
                    </div>
                </HeaderComponent>

                <UploadRegistryFormComponent>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: '100%',
                        }}
                    >
                        <Form
                            ref={formRef}
                            noValidate
                            validated={validated}
                            id="uploadForm"
                            onSubmit={handleSubmit}
                            style={{ paddingRight: '0.5rem', width: '60%' }}
                        >
                            <div>{getActiveStepsComponent()}</div>
                            <ButtonSpinnerHorizontalComponent>
                                {activeStep > 0 ? (
                                    <UploadButton
                                        variant="primary"
                                        type="cancel"
                                        onClick={onPreviousClick}
                                        data-testid="next-button"
                                    >
                                        Previous
                                    </UploadButton>
                                ) : (
                                    ''
                                )}
                                {!readonly ? (
                                    <UploadButton
                                        variant="primary"
                                        type="submit"
                                        onClick={() => setFinalize(false)}
                                        data-testid="save-button"
                                        disabled={readonly || submitted}
                                    >
                                        Save for Later
                                    </UploadButton>
                                ) : (
                                    ''
                                )}
                                {!(
                                    id && location.pathname.indexOf('view') > -1
                                ) ? (
                                    <CancelButton
                                        variant="primary"
                                        type="cancel"
                                        onClick={(evt: any) => onClearAll(evt)}
                                        data-testid="cancel-button"
                                    >
                                        {'Clear All'}
                                    </CancelButton>
                                ) : (
                                    ''
                                )}
                                <CancelButton
                                    variant="primary"
                                    type="cancel"
                                    onClick={(evt: any) => onCancle(evt)}
                                    data-testid="cancel-button"
                                >
                                    {'Cancel'}
                                </CancelButton>

                                {activeStep === 2 && !readonly ? (
                                    <UploadButton
                                        variant="primary"
                                        type="submit"
                                        onClick={() => setFinalize(true)}
                                        data-testid="finalize-button"
                                        disabled={readonly || submitted}
                                    >
                                        Submit
                                    </UploadButton>
                                ) : (
                                    ''
                                )}

                                {activeStep < 2 ? (
                                    <UploadButton
                                        variant="primary"
                                        onClick={onNextClick}
                                        data-testid="next-button"
                                    >
                                        Next
                                    </UploadButton>
                                ) : (
                                    ''
                                )}
                            </ButtonSpinnerHorizontalComponent>
                            {/* </div> */}
                        </Form>
                        <SpinnerComponent>
                            {uploadRegistryloading === LoadingState.Pending ? (
                                <Spinner animation="border" variant="dark" />
                            ) : (
                                <div></div>
                            )}
                        </SpinnerComponent>
                    </div>

                    <ModalPopupContainer>
                        {/* Confirmation message Model popup */}
                        <Modal
                            show={showConfirmationPopup}
                            onHide={handleConfirmationClose}
                            backdrop="static"
                            keyboard={false}
                        >
                            <Modal.Header>
                                <Modal.Title>Confirmation</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {uploadRegistryResult?.msg && (
                                    <div>
                                        {!id ? (
                                            <div>
                                                <UploadResultTitleTextLabel>
                                                    <span>
                                                        {
                                                            uploadRegistryResult.msg
                                                        }
                                                    </span>{' '}
                                                </UploadResultTitleTextLabel>
                                                <UploadResultTitleTextLabel>
                                                    {`Thank you. Optimization Upload request has been created.  
                                                    To edit the optimization upload form, select "Requests" option from the menu and navigate to "My Requests".  
                                                    Select "Edit" option under the "Action" dropdown list corresponding to a particular "upload optimization request"`}
                                                </UploadResultTitleTextLabel>
                                            </div>
                                        ) : (
                                            <UploadResultTitleTextLabel>
                                                <span>
                                                    {
                                                        'Request updated successfully. Thank you!'
                                                    }
                                                </span>{' '}
                                            </UploadResultTitleTextLabel>
                                        )}
                                    </div>
                                )}
                                {attachError?.message && (
                                    <UploadResultTextLabel>
                                        {`Error uploading file: ${attachError.message}`}
                                    </UploadResultTextLabel>
                                )}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    variant="primary"
                                    type="submit"
                                    onClick={handleConfirmationClose}
                                >
                                    OK
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <AlertComponent
                            id="errorAlert"
                            variant="primary"
                            show={uploadRegistryError?.message ? true : false}
                            data-testid="error-text"
                        >
                            {'Error!: ' +
                                errorText +
                                ' Another possibility is that The Use Case, Feature and/or Platform field is not filled out'}
                        </AlertComponent>
                    </ModalPopupContainer>
                </UploadRegistryFormComponent>
            </HorizontalContainer>
        </UploadRegistryFormContainer>
    )
}

export default UploadRegistryPanel
