import styled from 'styled-components'
import { FunctionComponent, Dispatch } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from 'react-bootstrap'

const UnSupportedBrowserPanelContainer = styled.div`
    display: flex;
    height: ${(props) => props.theme.size.mainContainerHeight};
    width: ${(props) => props.theme.size.mainContainerWidth};
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: center;
    @media screen and (min-width: 1200px) {
        overflow-x: hidden;
    }
`

const BrowserHorizontalContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: center;
    margin-top: 2rem;
`
const MessageDiv = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: center;
    font-size: 1.5rem;
    @media screen and (max-width: 700px) {
        font-size: 1rem;
    }
`
const MainMessageDiv = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    font-size: 2rem;
    @media screen and (max-width: 700px) {
        font-size: 1rem;
    }
`
const DownloadChromeContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: center;
`
const BackButton = styled(Button)`
    display: flex;
    justify-content: center;
    font-weight: 700;
    font-size: 1.25rem;
    background-color:#008000;
    border-radius: ${(props) => props.theme.shape.button.borderRadius};
    margin-top: 3rem;
    &:hover {
        background-color: ${(props) =>
            props.theme.color.button.background.hover.primary};
        transition: 0.5s;
`

export interface IUnSupportedBrowserErrorProps {}

const UnSupportedBrowserError: FunctionComponent<IUnSupportedBrowserErrorProps> & {
    defaultProps: Partial<IUnSupportedBrowserErrorProps>
} = ({}: IUnSupportedBrowserErrorProps) => {
    let navigate = useNavigate()

    const handleOnclick = (event: any) => {
        localStorage.setItem('isAllowedOtherBrowser', 'true')
        navigate('/home')
    }

    return (
        <UnSupportedBrowserPanelContainer>
            <BrowserHorizontalContainer>
                <div>
                    <MainMessageDiv>
                        <strong>
                            Optimization Hub gives best performance in Chrome
                            browser!
                        </strong>
                    </MainMessageDiv>

                    <br />
                    <MessageDiv>
                        Please use Google Chrome.
                        <br /> <br />
                    </MessageDiv>

                    <DownloadChromeContainer>
                        <a href="https://www.google.com/chrome/browser/desktop/index.html">
                            <img
                                height="150px"
                                width="280px"
                                src="/google-chrome.png"
                                className="img-responsive"
                            />
                            <p style={{ marginLeft: '50px' }}>
                                Download Google Chrome
                            </p>
                        </a>{' '}
                        <br />
                    </DownloadChromeContainer>
                    <DownloadChromeContainer>
                        Still wants to go ahead? <br />
                    </DownloadChromeContainer>
                    <DownloadChromeContainer>
                        <BackButton
                            variant="primary"
                            type="submit"
                            onClick={(event: any) => handleOnclick(event)}
                        >
                            Go Home
                        </BackButton>
                    </DownloadChromeContainer>
                </div>
            </BrowserHorizontalContainer>
        </UnSupportedBrowserPanelContainer>
    )
}

UnSupportedBrowserError.defaultProps = {}

export default UnSupportedBrowserError
