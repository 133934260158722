import {
    useState,
    useCallback,
    ChangeEvent,
    FormEvent,
    SetStateAction,
    useEffect,
} from 'react'
import { KeyboardEvent } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ICatalogPanelProps } from './catalogPanel'
import { CatalogSearchRequest } from '../../models/catalogSearchrequest'
import { SiteFeedbackRequest } from '../../models/siteFeedbackRequest'
import {
    CatalogCardType,
    LayoutViewPreference,
} from '../../models/LayoutViewPreference'
import { useAppDispatch, useAppSelector } from '../../config/hooks'
import {
    getFiltersValues,
    getPublicFiltersValues,
} from '../../store/catalog.slice'
import { LoadingState } from '../../models/loadingState'
import { APP_BASE_URL } from '../../config/service'

export function CatalogPanelLogic({
    getCatalog,
    catalogResult,
    SearchComponentRef,
    sortTargetRef,
    getTagsValuesResult,
    saveSiteFeedback,
    isPublicSearch,
}: ICatalogPanelProps) {
    const defaultPageSize = 30
    const location = useLocation()
    const dispatch = useAppDispatch()
    const [appName, setAppName] = useState<string>('')
    const [searchBannerNames, setSearchBannerNames] = useState<String[]>([])
    const [currPage, setCurrPage] = useState<number>(1)
    const [pageCount, setPageCount] = useState(defaultPageSize)
    const [showFilters, setFiltersShow] = useState(false)
    const [showSorting, setSortingShow] = useState(false)
    const [totalFilterCount, setTotalFilterCount] = useState<number>(0)
    const [selectedSortItem, setSelectedSortItem] = useState<{
        name: string
        id: string
    }>({ name: 'Newest', id: 'published_time' })

    const [cloudCSP, setCloudCSP] = useState<
        { name: string | null; checkedValue: boolean }[]
    >([])
    const [solutionScale, setSolutionScale] = useState<
        {
            name: string | null
            checkedValue: boolean
            displayName: string | null
        }[]
    >([])
    const [contentType, setContentType] = useState<
        { name: string | null; checkedValue: boolean }[]
    >([])

    const [platformFilters, setPlatformFilters] = useState<
        { name: string | null; checkedValue: boolean }[]
    >([])
    const [featureFilters, setFeaureFilters] = useState<
        { name: string | null; checkedValue: boolean }[]
    >([])
    const [validated, setValidated] = useState(false)
    const [showSiteFeedbackPopup, setShowSiteFeedbackPopup] = useState(false)
    const [siteFeedbackMessage, setSiteFeedbackMessage] = useState('')
    const [isSiteFeedbackLoad, setIsSiteFeedbackLoad] = useState(false)

    const handleSiteFeedbackClose = () => setShowSiteFeedbackPopup(false)
    const handleSiteFeedbackShow = () => setShowSiteFeedbackPopup(true)
    const [layoutView, setLayoutView] = useState<string>(
        LayoutViewPreference.GalleryView
    )
    const navigate = useNavigate()
    const filtersValues = useAppSelector<any>((state: any) =>
        isPublicSearch
            ? state.getPublicFiltersValuesResult.data
            : state.getFiltersValuesResult.data
    )

    useEffect(() => {
        let filtersParam =
            'filter=Platform&filter=Feature&filter=Scale level&filter=Type&filter=CSP&filter=Optimization%20Type'
        if (isPublicSearch) dispatch(getPublicFiltersValues(filtersParam))
        else dispatch(getFiltersValues(filtersParam))
    }, [])

    const nextPage = catalogResult?.pageInfo.next
        ? catalogResult?.pageInfo.next
        : -1
    const totalPage = catalogResult?.pageInfo.total
        ? catalogResult?.pageInfo.total
        : -1

    let requestedPlatformFilter = ''
    let sourceType = ''
    const requestedUrl = `${window.location.pathname}${window.location.search}`
    //record search when it performed through PH by passing source as ph
    if (APP_BASE_URL && requestedUrl.indexOf(`${APP_BASE_URL}/catalog`) > -1) {
        sourceType = 'ph'
    }

    if (requestedUrl.startsWith('/catalog?platform=')) {
        requestedPlatformFilter = requestedUrl.substring(
            requestedUrl.indexOf('=') + 1,
            requestedUrl.length
        )
    }
    let cspList: { name: string; checkedValue: boolean }[] = []
    let ScaleList: {
        name: string
        checkedValue: boolean
        displayName: string | null
    }[] = []
    let TypeList: { name: string; checkedValue: boolean }[] = []
    let PlatformList: { name: string; checkedValue: boolean }[] = []
    let FeatureList: { name: string; checkedValue: boolean }[] = []

    const isItemExist = (paramValues: string, item: string) => {
        let isPlatformExistInItem = false
        const pItems = paramValues ? paramValues.split(',') : []
        if (pItems && pItems.length > 0) {
            const pItem = pItems.find((pt) => pt === item)
            if (pItem) isPlatformExistInItem = true
        }
        return isPlatformExistInItem
    }

    useEffect(() => {
        if (filtersValues && filtersValues['CSP'] !== undefined) {
            let qParams = getUrlParames()
            let totalFilterCount = 0
            const cspFiltersValues = filtersValues['CSP'].filter(
                (item: any) =>
                    item !== 'Any' &&
                    item !== 'Other' &&
                    item !== 'None' &&
                    item !== 'Not Applicable'
            )
            const featureFiltersValues = filtersValues['Feature'].filter(
                (item: any) =>
                    item !== 'Any' &&
                    item !== 'Other' &&
                    item !== 'None' &&
                    item !== 'Not Applicable'
            )
            const platformFiltersValues = filtersValues['Platform'].filter(
                (item: any) =>
                    item !== 'Any' &&
                    item !== 'Other' &&
                    item !== 'None' &&
                    item !== 'Not Applicable'
            )
            cspList = cspFiltersValues
                ? cspFiltersValues.map((item: any) => ({
                      name: item,
                      checkedValue:
                          qParams['c'] && qParams['c'].indexOf(item) > -1
                              ? true
                              : false,
                  }))
                : []
            ScaleList =
                filtersValues && filtersValues['Scale level']
                    ? filtersValues['Scale level'].map((item: any) => ({
                          name: item,
                          checkedValue:
                              qParams['s'] && qParams['s'].indexOf(item) > -1
                                  ? true
                                  : false,
                          displayName: item,
                      }))
                    : []
            TypeList =
                filtersValues && filtersValues['Optimization Type']
                    ? filtersValues['Optimization Type'].map((item: any) => ({
                          name: item,
                          checkedValue:
                              qParams['t'] &&
                              qParams['t'].indexOf(item) > -1 &&
                              isItemExist(qParams['t'], item)
                                  ? true
                                  : false,
                      }))
                    : []
            PlatformList = platformFiltersValues
                ? platformFiltersValues.map((item: any) => ({
                      name: item,
                      checkedValue:
                          qParams['p'] &&
                          qParams['p'].indexOf(item) > -1 &&
                          isItemExist(qParams['p'], item)
                              ? true
                              : false,
                  }))
                : []
            FeatureList = featureFiltersValues
                ? featureFiltersValues.map((item: any) => ({
                      name: item,
                      checkedValue:
                          qParams['f'] && qParams['f'].indexOf(item) > -1
                              ? true
                              : false,
                  }))
                : []

            if (cspList) {
                setCloudCSP(cspList)
                cspList.map((item) => {
                    item.checkedValue
                        ? (totalFilterCount = totalFilterCount + 1)
                        : ''
                })
            }
            if (TypeList) {
                setContentType(TypeList)
                TypeList.map((item) => {
                    item.checkedValue
                        ? (totalFilterCount = totalFilterCount + 1)
                        : ''
                })
            }
            if (ScaleList) {
                setSolutionScale(ScaleList)
                ScaleList.map((item) => {
                    item.checkedValue
                        ? (totalFilterCount = totalFilterCount + 1)
                        : ''
                })
            }
            if (PlatformList) {
                setPlatformFilters(PlatformList)
                PlatformList.map((item) => {
                    item.checkedValue
                        ? (totalFilterCount = totalFilterCount + 1)
                        : ''
                })
            }
            if (FeatureList) {
                setFeaureFilters(FeatureList)
                FeatureList.map((item) => {
                    item.checkedValue
                        ? (totalFilterCount = totalFilterCount + 1)
                        : ''
                })
            }

            setTotalFilterCount(totalFilterCount)
        }
    }, [filtersValues])

    const onAppNameChange = useCallback((event: ChangeEvent<HTMLElement>) => {
        const target = event.currentTarget as HTMLTextAreaElement
        if (target.value != undefined) {
            setAppName(target.value)
            if (target.value === '') {
                //reset search banner and refresh data for default search
                setSearchBannerNames([])
                setSearchURL(false)
                setCurrPage(1)
            }
        }
    }, [])
    const setSearchURL = (isSortby: boolean, sortById?: string) => {
        let { filtersParam } = getAllFiltersParam()
        if (!sortById && sortTargetRef.current) {
            sortById = sortTargetRef.current.id
        }
        if (SearchComponentRef.current !== undefined) {
            let searchUrl =
                window.location.pathname +
                '?q=' +
                encodeURIComponent(SearchComponentRef.current.value.trim())
            if (isSortby && sortById)
                searchUrl =
                    searchUrl + '&sortby=' + encodeURIComponent(sortById)
            if (filtersParam !== '') {
                window.location.href = searchUrl + filtersParam
            } else {
                window.location.href = searchUrl
            }
        }
    }
    const setSortingFromParam = (sortById: string) => {
        let sortDisplayName = 'Newest'
        if (sortById) {
            if (sortById === 'updated_time')
                sortDisplayName = 'Recently Updated'
            else if (sortById === 'most_relevant')
                sortDisplayName = 'Most Relevant'
            else if (sortById === 'top_downloads')
                sortDisplayName = 'Top Downloads'
            else if (sortById === 'top_favorites')
                sortDisplayName = 'Top Favorites'
            else if (sortById === 'published_time') sortDisplayName = 'Newest'

            setSelectedSortItem({ name: sortDisplayName, id: sortById })
        }
    }

    const getAllCustomSearchNames = (bannerElement?: string) => {
        let newAppName = ''
        let qParams = {} as any
        if (window.location.href.indexOf('?') > -1) {
            qParams = getUrlParames()
            setSortingFromParam(qParams['sortby'])
            if (qParams['q']) {
                newAppName = GetUpdatedBannerElements(
                    qParams['q'],
                    bannerElement
                )

                SearchComponentRef.current.value = newAppName
                setAppName(newAppName) //reset app name
                return qParams['q']
            } else {
                setSearchBannerNames([])
            }
        } else return ''
    }
    const getUrlParames = () => {
        let qParams = {} as any
        if (window.location.href.indexOf('?') > -1) {
            let qString = window.location.href.split('?')
            if (qString && qString.length > 0) {
                let params = qString[1].split('&')
                for (let i = 0; i < params.length; i++) {
                    let pair: any = params[i].split('=')
                    const key = pair[0]
                    qParams[key] = decodeURIComponent(pair[1])
                }
            }
        }

        return qParams
    }
    function getAllFiltersParam() {
        let CSPTagsValue = ''
        let scaleTagsValue = ''
        let typeTagsValue = ''
        let platformTagsValue = ''
        let featureTagsValue = ''
        let totalFilterCount = 0
        let filtersParam = ''

        cloudCSP.map((item) => {
            if (item.name !== null && item.checkedValue) {
                CSPTagsValue =
                    CSPTagsValue === ''
                        ? item.name
                        : CSPTagsValue + ',' + encodeURIComponent(item.name)
                totalFilterCount = totalFilterCount + 1
            }
        })

        solutionScale.map((item) => {
            if (item.name !== null && item.checkedValue) {
                scaleTagsValue =
                    scaleTagsValue === ''
                        ? item.name
                        : scaleTagsValue + ',' + encodeURIComponent(item.name)
                totalFilterCount = totalFilterCount + 1
            }
        })

        contentType.map((item) => {
            if (item.name !== null && item.checkedValue) {
                typeTagsValue =
                    typeTagsValue === ''
                        ? item.name
                        : typeTagsValue + ',' + encodeURIComponent(item.name)
                totalFilterCount = totalFilterCount + 1
            }
        })
        platformFilters.map((item) => {
            if (item.name !== null && item.checkedValue) {
                platformTagsValue =
                    platformTagsValue === ''
                        ? item.name
                        : platformTagsValue +
                          ',' +
                          encodeURIComponent(item.name)
                totalFilterCount = totalFilterCount + 1
            }
        })
        featureFilters.map((item) => {
            if (item.name !== null && item.checkedValue) {
                featureTagsValue =
                    featureTagsValue === ''
                        ? item.name
                        : featureTagsValue + ',' + encodeURIComponent(item.name)
                totalFilterCount = totalFilterCount + 1
            }
        })
        if (totalFilterCount > 0)
            filtersParam =
                '&c=' +
                CSPTagsValue +
                '&s=' +
                scaleTagsValue +
                '&t=' +
                typeTagsValue +
                '&p=' +
                platformTagsValue +
                '&f=' +
                featureTagsValue +
                '&fcount=' +
                totalFilterCount

        return {
            filtersParam,
            totalFilterCount,
        }
    }
    const getData = (
        pageCount: number,
        offset: number,
        bannerElement?: string
    ) => {
        let sortById = selectedSortItem.id
        if (sortTargetRef.current) {
            sortById = sortTargetRef.current.id
        }
        let customAppNames = getAllCustomSearchNames(bannerElement)
        let qParams = getUrlParames()
        if (qParams['sortby']) sortById = qParams['sortby']

        getCatalog({
            appName: customAppNames ? customAppNames : '',
            'tags-CSP': qParams['c'] ? qParams['c'] : '',
            'tags-Scale level': qParams['s'] ? qParams['s'] : '',
            'tags-Type': qParams['t'] ? qParams['t'] : '',
            'tags-Platform': qParams['p'] ? qParams['p'] : '',
            'tags-Feature': qParams['f'] ? qParams['f'] : '',
            sortBy: sortById,
            offset: offset,
            count: pageCount,
            source: sourceType,
            isPublicSearch: isPublicSearch,
        } as CatalogSearchRequest)
    }

    const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault()
            setSearchURL(false)
        }
    }
    useEffect(() => {
        if (window.location.href.indexOf('?uuid') === -1) {
            //get search data
            getData(pageCount, 0)
            //reset pagination
            setCurrPage(1)
            setPageCount(defaultPageSize)
        }
    }, [location])

    const GetUpdatedBannerElements = (
        searchElement: string,
        bannerElement?: string
    ) => {
        const serachBanners: SetStateAction<String[] | undefined> = []
        let appNameList = []
        let newAppName = ''
        appNameList = searchElement.split(' ')
        appNameList.forEach((item: string | null | undefined) => {
            if (item !== undefined && item !== null && item.trim() !== '') {
                if (item.trim() !== bannerElement) {
                    newAppName = newAppName + ' ' + item
                    serachBanners.push(item)
                }
            }
        })
        setSearchBannerNames(serachBanners)
        return newAppName
    }

    const handleBannerClick = useCallback((event: FormEvent<HTMLElement>) => {
        event.preventDefault()

        const bannerElement = event.currentTarget.id
        if (bannerElement) {
            if (
                SearchComponentRef.current !== undefined &&
                SearchComponentRef.current.value
            ) {
                let newAppName = GetUpdatedBannerElements(
                    SearchComponentRef.current.value,
                    bannerElement
                )
                window.location.href =
                    window.location.pathname +
                    '?q=' +
                    encodeURIComponent(newAppName.trim())
            }
        }
    }, [])

    const afterPageClicked = (pageNumber: number) => {
        setCurrPage(pageNumber)
        let offset = pageNumber * pageCount - pageCount
        //get updated search data
        getData(pageCount, offset)
        if (SearchComponentRef.current !== undefined)
            SearchComponentRef.current.focus()
    }

    const handlePageCount = useCallback((event: ChangeEvent<HTMLElement>) => {
        const target = event.currentTarget as HTMLSelectElement
        if (target.value != undefined && target.value != '') {
            setPageCount(parseInt(target.value))
            let offset = currPage * pageCount - pageCount
            let updatedPageCount = Number(target.value)
            //get updated search data
            getData(updatedPageCount, offset)
            if ((currPage - 1) * pageCount + 1 > totalPage) setCurrPage(1)
        }
    }, [])

    //Filter hook Changes
    const onCSPChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLFormElement
            if (target) {
                const updatedCSPList = cloudCSP?.map(
                    (item: { name: string | null; checkedValue: boolean }) => {
                        if (target.value === item.name) {
                            item.checkedValue = target.checked
                        }
                        return item
                    }
                )

                setCloudCSP(updatedCSPList)
            }
        },
        [cloudCSP]
    )

    const onSolutionScaleChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLFormElement
            if (target) {
                const updatedScaleList = solutionScale?.map(
                    (item: {
                        name: string | null
                        checkedValue: boolean
                        displayName: string | null
                    }) => {
                        if (target.value === item.name) {
                            item.checkedValue = target.checked
                        }

                        return item
                    }
                )
                if (updatedScaleList.length > 0)
                    setSolutionScale(updatedScaleList)
            }
        },
        [solutionScale]
    )

    const onContentTypeChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLFormElement
            if (target) {
                const updatedType = contentType?.map(
                    (item: { name: string | null; checkedValue: boolean }) => {
                        if (target.value === item.name) {
                            item.checkedValue = target.checked
                        }

                        return item
                    }
                )
                if (updatedType.length > 0) setContentType(updatedType)
            }
        },
        [contentType]
    )
    const onPlatformFilterChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLFormElement
            if (target) {
                const updatedType = platformFilters?.map(
                    (item: { name: string | null; checkedValue: boolean }) => {
                        if (target.value === item.name) {
                            item.checkedValue = target.checked
                        }

                        return item
                    }
                )
                setPlatformFilters(updatedType)
            }
        },
        [platformFilters]
    )
    const onFeatureFilterChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLFormElement
            if (target) {
                const updatedType = featureFilters?.map(
                    (item: { name: string | null; checkedValue: boolean }) => {
                        if (target.value === item.name) {
                            item.checkedValue = target.checked
                        }

                        return item
                    }
                )
                setFeaureFilters(updatedType)
            }
        },
        [featureFilters]
    )

    const onCloseClick = useCallback((event: FormEvent<HTMLElement>) => {
        event.preventDefault()
        setFiltersShow(false)
    }, [])

    const onApplyFiltersClick = useCallback(
        (event: FormEvent<HTMLElement>) => {
            event.preventDefault()
            setSearchURL(true)
            setFiltersShow(false)
            //reset pagination
            setCurrPage(1)
            setPageCount(defaultPageSize)
        },
        [cloudCSP, solutionScale, contentType, platformFilters, featureFilters]
    )

    const onRemoveFiltersClick = useCallback(
        (event: FormEvent<HTMLElement>) => {
            event.preventDefault()
            cloudCSP.map((item) => {
                item.checkedValue = false
            })
            solutionScale.map((item) => {
                item.checkedValue = false
            })
            contentType.map((item) => {
                item.checkedValue = false
            })
            platformFilters.map((item) => {
                item.checkedValue = false
            })
            featureFilters.map((item) => {
                item.checkedValue = false
            })

            setSearchURL(false)
            setTotalFilterCount(0)
            setFiltersShow(false)
            //reset pagination
            setCurrPage(1)
            setPageCount(defaultPageSize)
            if (requestedPlatformFilter !== '') navigate('/catalog')
        },
        [cloudCSP, solutionScale, contentType, platformFilters, featureFilters]
    )
    const onSortItemClick = useCallback((event: FormEvent<HTMLElement>) => {
        event.preventDefault()
        const target = event.currentTarget as HTMLButtonElement
        if (target.id && target.value) {
            let sortby = { name: target.value, id: target.id }
            setSelectedSortItem(sortby)
            setSearchURL(true, target.id)
            //reset pagination
            setCurrPage(1)
            setPageCount(defaultPageSize)
        }
        setSortingShow(false)
    }, [])
    const onSiteFeedbackClick = useCallback((event: FormEvent<HTMLElement>) => {
        event.preventDefault()
        handleSiteFeedbackShow()
        setIsSiteFeedbackLoad(true)
        setValidated(false)
        setSiteFeedbackMessage('')
    }, [])

    const onSiteFeedbackTextChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLTextAreaElement
            if (target.value) {
                setSiteFeedbackMessage(target.value)
            }
        },
        []
    )

    const onSiteFeedbackFormSubmit = useCallback(
        (event: FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            const form = event.currentTarget
            if (form.checkValidity() === false) {
                event.preventDefault()
                event.stopPropagation()
            }
            setValidated(true)
            if (siteFeedbackMessage) {
                var feedbackRequest = new SiteFeedbackRequest()
                feedbackRequest.feedback = siteFeedbackMessage
                if (feedbackRequest.feedback) {
                    saveSiteFeedback(feedbackRequest)
                    setIsSiteFeedbackLoad(false)
                }
            }
        },
        [siteFeedbackMessage]
    )
    const onLayoutViewClick = useCallback(
        (layoutViewValue: string, event: FormEvent<HTMLElement>) => {
            event.preventDefault()
            if (layoutViewValue) {
                setLayoutView(layoutViewValue)
            }
            if (layoutViewValue === LayoutViewPreference.SalesView) {
                navigate('/salesview/home')
            }
            if (layoutViewValue === LayoutViewPreference.ChatbotView) {
                navigate('/chatbot')
            }
        },
        [layoutView]
    )

    return {
        onAppNameChange,
        onKeyDown,
        searchBannerNames,
        handleBannerClick,
        currPage,
        afterPageClicked,
        pageCount,
        handlePageCount,
        showFilters,
        onCloseClick,
        setFiltersShow,
        onCSPChange,
        onSolutionScaleChange,
        onContentTypeChange,
        onApplyFiltersClick,
        onRemoveFiltersClick,
        totalFilterCount,
        cloudCSP,
        solutionScale,
        contentType,
        showSorting,
        setSortingShow,
        onSortItemClick,
        selectedSortItem,
        onPlatformFilterChange,
        onFeatureFilterChange,
        platformFilters,
        featureFilters,
        validated,
        showSiteFeedbackPopup,
        isSiteFeedbackLoad,
        handleSiteFeedbackClose,
        onSiteFeedbackFormSubmit,
        onSiteFeedbackTextChange,
        onSiteFeedbackClick,
        onLayoutViewClick,
        layoutView,
        defaultPageSize,
    }
}
