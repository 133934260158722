import { API_SERVICE_URL } from '../config/service'
import { UnSubscribeRequest } from '../models/unSubscribeRequest'

import api from './auth/client'

const serviceConfig = require('../config/service.json')

class SubscriptionService {
    getSubscription() {
        var url = API_SERVICE_URL + serviceConfig.getSubscriptions
        return api.get(url)
    }

    removeSubscription(unsubscribeRequest: UnSubscribeRequest) {
        var url = API_SERVICE_URL + serviceConfig.removeSubscriptions
        return api.post(url, unsubscribeRequest)
    }
}

export default new SubscriptionService()
