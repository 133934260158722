import { FormEvent, FunctionComponent, useCallback } from 'react'
import styled from 'styled-components'
import StringUtils from '../../lib/stringUtils'

const LikesContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: right;
    padding-right: 1rem;
    @media screen and (max-width: 500px) {
        padding-left: 11rem !important;
        padding-right: 4rem;
    }
`
const LikesCountDiv = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: right;
    padding-bottom: 0.1rem;
    border-radius: 0.1rem;
    min-width: 20%;
    padding-left: 0.5rem;
`
const LikesButton = styled.button`
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    tabindex: 0;
    &:hover {
        color: #00a3fb;
    }
    &:focus {
        color: #00a3fb;
        border: 1px solid transparent;
        outline: auto;
    }
`
const CountSpan = styled.span`
    position: relative;
    top: 25%;
`
export interface ILikesPanel {
    isLiked: boolean
    onLikeClick: (event: FormEvent<HTMLElement>) => void
    likesCount?: number
}

export const SetLike: FunctionComponent<ILikesPanel> = ({
    isLiked,
    onLikeClick,
    likesCount,
}: ILikesPanel) => {
    const handleOnLikesClick = useCallback((event: FormEvent<HTMLElement>) => {
        onLikeClick(event)
    }, [])
    const likeCountRender = (
        <CountSpan>
            {likesCount && likesCount >= 1000
                ? likesCount / 1000 + 'k'
                : likesCount}
        </CountSpan>
    )

    return (
        <LikesContainer>
            <LikesCountDiv>
                <LikesButton
                    type="button"
                    onClick={(e) => handleOnLikesClick(e)}
                    value={'' + isLiked + ''}
                    title={'Like'}
                >
                    {isLiked ? (
                        <img
                            src={StringUtils.resolve('/like_filled.png')}
                            height="20"
                            width="20"
                            alt="Like logo"
                        />
                    ) : (
                        <img
                            src={StringUtils.resolve('/like.png')}
                            height="20"
                            width="20"
                            alt="unLike logo"
                        />
                    )}
                </LikesButton>
                {[likeCountRender]}
            </LikesCountDiv>
        </LikesContainer>
    )
}

export default SetLike
