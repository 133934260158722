import { useState, useCallback, FormEvent, ChangeEvent, useEffect } from 'react'
import { NewBenchmarkRequest } from '../../models/newBenchmarkRequest'
import { INewBenchmarkPanelProps } from './newBenchmarkPanel'

export function NewBenchmarkPanelLogic({
    onNewBenchmarkSubmit,
}: INewBenchmarkPanelProps) {
    const [showExistingWorkloadlist, setShowExistingWorkloadlist] =
        useState<boolean>(false)
    const [isRequired, setIsRequired] = useState(true)
    const [validated, setValidated] = useState(false)
    const [newWorkloadName, setNewWorkloadName] = useState<string>('')
    const [newWorkloadVersion, setNewWorkloadVersion] = useState<string>('')
    const [existingWorkloadName, setExistingWorkloadName] = useState<string>('')
    const [targetHW, setTargetHW] = useState<string>('')
    const [customer, setCustomer] = useState<string>('')
    const [businessImpact, setBusinessImpact] = useState<string>('')
    const [dueDate, setDueDate] = useState<string>('')
    const [businesUnit, setBusinesUnit] = useState<string>('')
    const [requesterName, setRequesterName] = useState<string>('')
    const [testCase, setTestCase] = useState<string>('')
    const [softwareComponent, setSoftwareComponent] = useState<string>('')
    const [configuration, setConfiguration] = useState<string>('')
    const [KPIMetric, setKPIMetric] = useState<string>('')
    const [workloadLocation, setWorkloadLocation] = useState<string>('')
    const [contact, setContact] = useState<string>('')
    const [comments, setComments] = useState<string>('')

    const onNewOneRequestChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLFormElement
            if (target.checked === true) {
                setIsRequired(true)
                setShowExistingWorkloadlist(false)
            } else {
                setShowExistingWorkloadlist(true)
            }
        },
        []
    )
    const onExistingRequestChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLFormElement
            if (target.value === true) {
                setIsRequired(false)
                setShowExistingWorkloadlist(false)
            } else setShowExistingWorkloadlist(true)
        },
        []
    )
    const onNewWorkloadNameChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLFormElement
            if (target.value) setNewWorkloadName(target.value)
        },
        []
    )
    const onNewWorkloadVersionChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLFormElement
            if (target.value) setNewWorkloadVersion(target.value)
        },
        []
    )
    const onExistingWorkloadChange = useCallback((event: any) => {
        const target = event as any
        if (target !== null && target.value) {
            setExistingWorkloadName(target.value)
        }
    }, [])
    const onTargetHWChange = useCallback((event: ChangeEvent<HTMLElement>) => {
        const target = event.currentTarget as HTMLFormElement
        if (target.value) setTargetHW(target.value)
    }, [])
    const onCustomersChange = useCallback((event: ChangeEvent<HTMLElement>) => {
        const target = event.currentTarget as HTMLFormElement
        if (target.value) setCustomer(target.value)
    }, [])
    const onBusinessImpactChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLFormElement
            if (target.value) setBusinessImpact(target.value)
        },
        []
    )
    const onDueDateChange = useCallback((event: ChangeEvent<HTMLElement>) => {
        const target = event.currentTarget as HTMLDataElement
        if (target.value) {
            setDueDate(target.value)
        }
    }, [])
    const onBusinessUnitChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLFormElement
            if (target.value) setBusinesUnit(target.value)
        },
        []
    )

    const onRequesterNameChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLFormElement
            if (target.value) setRequesterName(target.value)
        },
        []
    )
    const onTestCaseChange = useCallback((event: ChangeEvent<HTMLElement>) => {
        const target = event.currentTarget as HTMLFormElement
        if (target.value) setTestCase(target.value)
    }, [])
    const onSoftwareComponentChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLFormElement
            if (target.value) setSoftwareComponent(target.value)
        },
        []
    )
    const onConfigurationChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLFormElement
            if (target.value) setConfiguration(target.value)
        },
        []
    )
    const onKPIMetricChange = useCallback((event: ChangeEvent<HTMLElement>) => {
        const target = event.currentTarget as HTMLFormElement
        if (target.value) setKPIMetric(target.value)
    }, [])
    const onWorkloadLocationChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLFormElement
            if (target.value) setWorkloadLocation(target.value)
        },
        []
    )
    const onContactChange = useCallback((event: ChangeEvent<HTMLElement>) => {
        const target = event.currentTarget as HTMLFormElement
        if (target.value) setContact(target.value)
    }, [])
    const onCommentChange = useCallback((event: ChangeEvent<HTMLElement>) => {
        const target = event.currentTarget as HTMLFormElement
        if (target.value) setComments(target.value)
    }, [])

    const onSubmit = useCallback(
        (event: FormEvent<HTMLFormElement>) => {
            event.preventDefault()

            const form = event.currentTarget
            if (form.checkValidity() === false) {
                event.preventDefault()
                event.stopPropagation()
            }
            setValidated(true)
            var newBenchmarkRequest = new NewBenchmarkRequest()
            let isSubmit = false
            if (
                (showExistingWorkloadlist && existingWorkloadName !== '') ||
                (newWorkloadName && newWorkloadVersion)
            )
                isSubmit = true
            //Service Call

            if (
                isSubmit &&
                targetHW &&
                customer &&
                businessImpact &&
                businesUnit &&
                requesterName &&
                testCase &&
                softwareComponent &&
                configuration &&
                KPIMetric &&
                workloadLocation &&
                contact
            ) {
                newBenchmarkRequest.existingBenchmark = existingWorkloadName
                newBenchmarkRequest.name = newWorkloadName
                newBenchmarkRequest.version = newWorkloadVersion
                newBenchmarkRequest.targetHardware = targetHW
                newBenchmarkRequest.customer = customer
                newBenchmarkRequest.businessImpact = businessImpact
                newBenchmarkRequest.dueDate = dueDate
                newBenchmarkRequest.businessUnit = businesUnit
                newBenchmarkRequest.requester = requesterName
                newBenchmarkRequest.testCase = testCase
                newBenchmarkRequest.softwareComponents = softwareComponent
                newBenchmarkRequest.configuration = configuration
                newBenchmarkRequest.KPI = KPIMetric
                newBenchmarkRequest.source = workloadLocation
                newBenchmarkRequest.contact = contact
                newBenchmarkRequest.comments = comments
                onNewBenchmarkSubmit(newBenchmarkRequest)
                //console.log(newBenchmarkRequest)
            }
        },
        [
            existingWorkloadName,
            newWorkloadName,
            newWorkloadVersion,
            existingWorkloadName,
            targetHW,
            customer,
            businessImpact,
            dueDate,
            businesUnit,
            requesterName,
            testCase,
            softwareComponent,
            configuration,
            KPIMetric,
            workloadLocation,
            contact,
            comments,
            onNewBenchmarkSubmit,
        ]
    )

    const onCancle = useCallback((event: FormEvent<HTMLElement>) => {
        event.preventDefault()
        setValidated(false)
        window.location.reload()
    }, [])

    return {
        onSubmit,
        onCancle,
        validated,
        showExistingWorkloadlist,
        existingWorkloadName,
        onNewOneRequestChange,
        onExistingRequestChange,
        onNewWorkloadNameChange,
        onNewWorkloadVersionChange,
        onExistingWorkloadChange,
        onTargetHWChange,
        onCustomersChange,
        onBusinessImpactChange,
        onDueDateChange,
        onBusinessUnitChange,
        onRequesterNameChange,
        onTestCaseChange,
        onSoftwareComponentChange,
        onConfigurationChange,
        onKPIMetricChange,
        onWorkloadLocationChange,
        onContactChange,
        onCommentChange,
        isRequired,
        newWorkloadName,
        targetHW,
    }
}
