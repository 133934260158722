import axios from 'axios'
const serviceConfig = require('../../config/service.json')
import { Cache } from '@aws-amplify/cache'
import { clearSession } from '../../store/authLogin.slice'
import amplify_conf from '../../services/auth/amplify'
import Amplify from '@aws-amplify/core'
import Auth from '@aws-amplify/auth'
import { clearAllItemsFromStorage } from '../../lib/encryptStorage'
Amplify.configure(amplify_conf)

const authClient = axios.create({
    baseURL: serviceConfig.authServiceURL,
})

const signOut = async () => {
    try {
        Cache.clear()
        await Auth.signOut()
    } catch (error) {
        console.log('error signing out: ', error)
    }
}

authClient.interceptors.request.use(
    async (config: any) => {
        // Gets current user session token after cognito sign in.
        await Auth.currentSession()
            .then((res) => {
                let accessToken = res.getAccessToken()
                const access_token = accessToken.getJwtToken()
                if (access_token) {
                    config.headers['Authorization'] = 'Bearer ' + access_token
                }
            })
            .catch((err) => {
                clearSession(null)
                Cache.clear() //clear cache
                clearAllItemsFromStorage() //clear previous storage
                let orig = window.location.pathname + window.location.search
                localStorage.setItem('original_url', orig)
                window.location.href = '/signIn'
            })
        return config
    },
    (error: any) => {
        return Promise.reject(error)
    }
)

export default authClient
