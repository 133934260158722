import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { LoadingState } from '../models/loadingState'
import SwaggerService from '../services/swaggerService'

const initialState = {
    data: null,
    loading: LoadingState.Idle,
    error: null,
}

export const getSwagger = createAsyncThunk(
    'getSwagger',
    async () => await SwaggerService.getSwagger()
)

const getSwaggerSlice = createSlice({
    name: 'state.error',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(getSwagger.pending, (state, _) => {
            if (state.loading === LoadingState.Idle) {
                state.loading = LoadingState.Pending
            }
        })
        .addCase(getSwagger.fulfilled, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.data = action.payload.data
                state.error = null
            }
        })
        .addCase(getSwagger.rejected, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.error = action.error
            }
        })
    },
})

export const getSwaggerResult = getSwaggerSlice.reducer
