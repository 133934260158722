/************************************************
 * Copyright (C) 2021 Intel Corporation
 ************************************************/
import styled from 'styled-components'
import { FunctionComponent, Dispatch } from 'react'
import { connect } from 'react-redux'
import FooterPanel from '../../layout/footer'
import { FormLabel } from 'react-bootstrap'
import { LoadingState } from '../../models/loadingState'
import { GetMembershipRequest } from '../../models/getMembershipRequest'
import { UpdateMembershipRequest } from '../../models/updateMembershipRequest'
import {
    getMembership,
    updateMembership,
    updateUserDefaultOrg,
} from '../../store/membership.slice'
import { GetMembershipResponse } from '../../models/getMembershipResponse'
import UserPanel from '../../layout/userProfile'
import { MembershipContainerLogic } from './membershipContainer.hook'
import MembershipPanel from '../../components/membershipPanel/membershipPanel'
import { UpdateMembershipResponse } from '../../models/updateMembershipResponse'
import { OrganizationInfoResponse } from '../../models/organizationInfoResponse'
import {
    getOrganization,
    getUserOrganizationsList,
} from '../../store/organization.slice'
import { GetInvitesRequest } from '../../models/getInvitesRequest'
import { getInvites, sendInvites } from '../../store/invitation.slice'
import { SendInvitesResponse } from '../../models/sendInvitesResponse'
import { SendInvitesRequest } from '../../models/sendInvitesRequest'
import { GetInvitesResponse } from '../../models/getInvitesResponse'
import { getAuthPermissions } from '../../store/authPermissions.slice'
import { AuthPermissions } from '../../models/authPermissions'
import HeaderPanel from '../../layout/header'
import { UserOrganizationsResponse } from '../../models/UserOrganizationsResponse'
import { UpdateUserDefaultOrgResponse } from '../../models/UpdateUserDefaultOrgResponse'
import { UpdateUserDefaultOrgRequest } from '../../models/UpdateUserDefaultOrgRequest'
import { doesUserHaveRole } from '../../lib/user'
import { UserRoles } from '../../models/userRoles'

const MembershipPanelContainer = styled.div`
    display: flex;
    flex-grow: 1;
    height: ${(props) => props.theme.size.mainContainerHeight};
    width: ${(props) => props.theme.size.mainContainerWidth};
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: space-between;
    @media screen and (min-width: 1200px) {
        overflow-x: hidden;
    }
`

const MembershipHorizontalContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: space-between;
`
const MembershipBodyContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: no-wrap;
    background-color: ${(props) => props.theme.color.background.defaultGrey};
`

const PageLinkPanelPanelWrapper = styled.div`
    display: flex;
    margin-left: 2rem;
    @media screen and (max-width: 500px) {
        display: none;
    }
`
const MobileHeaderWrapper = styled.div`
    display: flex;
    display: none;
    @media screen and (max-width: 500px) {
        display: block;
    }
`
const UserPanelWrapper = styled.div`
    max-height: 15vh;
`

const MembershipPanelWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: center;
    align-items: center;
`

const PageTitleTextLabel = styled(FormLabel)`
    ${(props) => props.theme.typography.xxxLarge}
    text-align: left;
    font-weight: 500;
    font-size: 2rem;
    margin-top: 1rem;
`
const NotAuthorizedTextLabel = styled(FormLabel)`
    ${(props) => props.theme.typography.medium}
    text-align: left;
    font-weight: 500;
    margin-left: 2rem;
    margin-top: 2rem;
    color: #c0392b;
`

export interface IMembershipContainerProps {
    getMembership: typeof getMembership
    getMembershipError: any
    getMembershipLoading: LoadingState
    getMembershipResult: GetMembershipResponse
    updateMembership: typeof updateMembership
    updateMembershipError: any
    updateMembershipLoading: LoadingState
    updateMembershipResult: UpdateMembershipResponse
    getOrganization: typeof getOrganization
    organizationInfoError: any
    organizationInfoLoading: LoadingState
    organizationInfoResult: OrganizationInfoResponse

    getInvites: typeof getInvites
    getInvitesError: any
    getInvitesLoading: LoadingState
    getInvitesResult: GetInvitesResponse

    sendInvites: typeof sendInvites
    sendInvitesError: any
    sendInvitesLoading: LoadingState
    sendInvitesResult: SendInvitesResponse

    getAuthPermissions: typeof getAuthPermissions
    authPermissionsResult: AuthPermissions
    authPermissionsLoading: LoadingState
    authPermissionsError: any

    getUserOrganizationsList: typeof getUserOrganizationsList
    userOrganizationsListError: any
    userOrganizationsListLoading: LoadingState
    userOrganizationsListResult: UserOrganizationsResponse

    updateUserDefaultOrg: typeof updateUserDefaultOrg
    updateUserDefaultOrgError: any
    updateUserDefaultOrgLoading: LoadingState
    updateUserDefaultOrgResult: UpdateUserDefaultOrgResponse
}

const MembershipContainer: FunctionComponent<IMembershipContainerProps> & {
    defaultProps: Partial<IMembershipContainerProps>
} = ({
    getMembership,
    getMembershipError,
    getMembershipLoading,
    getMembershipResult,
    updateMembership,
    updateMembershipError,
    updateMembershipLoading,
    updateMembershipResult,
    getOrganization,
    organizationInfoError,
    organizationInfoLoading,
    organizationInfoResult,

    getInvites,
    getInvitesError,
    getInvitesLoading,
    getInvitesResult,
    sendInvites,
    sendInvitesError,
    sendInvitesLoading,
    sendInvitesResult,

    getAuthPermissions,
    authPermissionsResult,
    authPermissionsLoading,
    authPermissionsError,

    getUserOrganizationsList,
    userOrganizationsListError,
    userOrganizationsListLoading,
    userOrganizationsListResult,

    updateUserDefaultOrg,
    updateUserDefaultOrgError,
    updateUserDefaultOrgLoading,
    updateUserDefaultOrgResult,
}: IMembershipContainerProps) => {
    MembershipContainerLogic({
        getMembership,
        getAuthPermissions,
    } as IMembershipContainerProps)
    return (
        <MembershipPanelContainer>
            <HeaderPanel />
            <MembershipHorizontalContainer>
                <MembershipBodyContainer>
                    <UserPanelWrapper>
                        <UserPanel></UserPanel>
                    </UserPanelWrapper>
                    <MembershipPanelWrapper>
                        <PageTitleTextLabel data-testid="page-title-label">
                            Membership
                        </PageTitleTextLabel>
                        {doesUserHaveRole(UserRoles.OrgAdmin, '') ? (
                            <MembershipPanel
                                getMembership={getMembership}
                                getMembershipError={getMembershipError}
                                getMembershipLoading={getMembershipLoading}
                                getMembershipResult={getMembershipResult}
                                updateMembership={updateMembership}
                                updateMembershipError={updateMembershipError}
                                updateMembershipLoading={
                                    updateMembershipLoading
                                }
                                updateMembershipResult={updateMembershipResult}
                                getOrganization={getOrganization}
                                organizationInfoError={organizationInfoError}
                                organizationInfoLoading={
                                    organizationInfoLoading
                                }
                                organizationInfoResult={organizationInfoResult}
                                getInvites={getInvites}
                                getInvitesError={getInvitesError}
                                getInvitesLoading={getInvitesLoading}
                                getInvitesResult={getInvitesResult}
                                sendInvites={sendInvites}
                                sendInvitesError={sendInvitesError}
                                sendInvitesLoading={sendInvitesLoading}
                                sendInvitesResult={sendInvitesResult}
                                authPermissionsResult={authPermissionsResult}
                                authPermissionsLoading={authPermissionsLoading}
                                authPermissionsError={authPermissionsError}
                                getUserOrganizationsList={
                                    getUserOrganizationsList
                                }
                                userOrganizationsListError={
                                    getUserOrganizationsList
                                }
                                userOrganizationsListResult={
                                    userOrganizationsListResult
                                }
                                userOrganizationsListLoading={
                                    userOrganizationsListLoading
                                }
                                updateUserDefaultOrg={updateUserDefaultOrg}
                                updateUserDefaultOrgError={
                                    updateUserDefaultOrgError
                                }
                                updateUserDefaultOrgLoading={
                                    updateUserDefaultOrgLoading
                                }
                                updateUserDefaultOrgResult={
                                    updateUserDefaultOrgResult
                                }
                            />
                        ) : (
                            <NotAuthorizedTextLabel>
                                Sorry, you need admin privileges to view this
                                page.
                            </NotAuthorizedTextLabel>
                        )}
                    </MembershipPanelWrapper>
                </MembershipBodyContainer>
            </MembershipHorizontalContainer>
            <FooterPanel></FooterPanel>
        </MembershipPanelContainer>
    )
}

MembershipContainer.defaultProps = {}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        getAuthPermissions: () => dispatch(getAuthPermissions()),

        getMembership: (getMembershipRequest: GetMembershipRequest) =>
            dispatch(getMembership(getMembershipRequest)),

        updateMembership: (updateMembershipRequest: UpdateMembershipRequest) =>
            dispatch(updateMembership(updateMembershipRequest)),

        getOrganization: () => dispatch(getOrganization()),

        getInvites: (getInvitesRequest: GetInvitesRequest) =>
            dispatch(getInvites(getInvitesRequest)),

        sendInvites: (sendInvitesRequest: SendInvitesRequest) =>
            dispatch(sendInvites(sendInvitesRequest)),

        getUserOrganizationsList: () => dispatch(getUserOrganizationsList()),

        updateUserDefaultOrg: (
            updateUserDefaultOrgRequest: UpdateUserDefaultOrgRequest
        ) => dispatch(updateUserDefaultOrg(updateUserDefaultOrgRequest)),
    }
}

const mapStateToProps = (state: any) => {
    return {
        getMembershipError: state.getMembershipResult.error,
        getMembershipLoading: state.getMembershipResult.loading,
        getMembershipResult: state.getMembershipResult.data,
        updateMembershipError: state.updateMembershipResult.error,
        updateMembershipLoading: state.updateMembershipResult.loading,
        updateMembershipResult: state.updateMembershipResult.data,
        organizationInfoError: state.organizationInfoResult.error,
        organizationInfoLoading: state.organizationInfoResult.loading,
        organizationInfoResult: state.organizationInfoResult.data,

        getInvitesError: state.getInvitesResult.error,
        getInvitesLoading: state.getInvitesResult.loading,
        getInvitesResult: state.getInvitesResult.data,

        sendInvitesError: state.sendInvitesResult.error,
        sendInvitesLoading: state.sendInvitesResult.loading,
        sendInvitesResult: state.sendInvitesResult.data,

        authPermissionsResult: state.authPermissionsResult.data,
        authPermissionsLoading: state.authPermissionsResult.loading,
        authPermissionsError: state.authPermissionsResult.error,

        userOrganizationsListResult: state.userOrganizationsListResult.data,
        userOrganizationsListLoading: state.userOrganizationsListResult.loading,
        userOrganizationsListError: state.userOrganizationsListResult.error,

        updateUserDefaultOrgResult: state.updateUserDefaultOrgResult.data,
        updateUserDefaultOrgError: state.updateUserDefaultOrgResult.error,
        updateUserDefaultOrgLoading: state.updateUserDefaultOrgResult.Loading,
    }
}

type StateToPropsType = ReturnType<typeof mapStateToProps>
type DispatchToPropsType = typeof mapDispatchToProps

export default connect<StateToPropsType, DispatchToPropsType>(
    mapStateToProps,
    mapDispatchToProps
)(MembershipContainer)
