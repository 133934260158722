import { idText } from 'typescript'
import { API_SERVICE_URL } from '../config/service'

import api from './auth/client'

const serviceConfig = require('../config/service.json')

class PerformanceSamplesService {
    getPerformanceSamples(payload: any) {
        const { id, startDate } = payload

        const endDate = payload.endDate ? `&end-date=${payload.endDate}` : ''

        var url =
            API_SERVICE_URL +
            serviceConfig.getRecipeURL +
            `/${id}/performance?start-date=${startDate}${endDate}`
        return api.get(url)
    }
}

export default new PerformanceSamplesService()
