import { API_SERVICE_URL } from '../config/service'
import { CatalogSearchRequest } from '../models/catalogSearchrequest'
import api from './auth/client'
import { RecipeFeedbackRequest } from '../models/recipeFeedbackRequest'
import { RecipeAddFavoriteRequest } from '../models/recipeAddFavoriteRequest'
import { RecipeStarRatingRequest } from '../models/recipeStarRatingRequest'
import { RecipeAddVotingRequest } from '../models/recipeAddVotingRequest'
import { RecipeAddLinkClickRequest } from '../models/recipeAddLinkClickRequest'
import { SiteFeedbackRequest } from '../models/siteFeedbackRequest'
import { RecipeAddLikeRequest } from '../models/recipeAddLikeRequest'
import { RecipeAddSubscriptionRequest } from '../models/recipeAddSubscriptionRequest'

const serviceConfig = require('../config/service.json')
const queryString = require('query-string')

class CatalogService {
    searchRecipes(catalogSearchCriteria: CatalogSearchRequest) {
        var url = API_SERVICE_URL + serviceConfig.getNewSearchURL
        const queryArgs = queryString.stringify(catalogSearchCriteria)
        if (
            catalogSearchCriteria.uuid !== undefined &&
            catalogSearchCriteria.uuid !== ''
        ) {
            url = API_SERVICE_URL + serviceConfig.getRecipeURL
            return api.get(`${url}/share/${catalogSearchCriteria.uuid}`)
        } else if (catalogSearchCriteria.uuids) {
            return api.get(url + '?' + catalogSearchCriteria.uuids)
        } else return api.get(url + '?' + queryArgs)
    }

    downloadRecipe(downloadUrl: string) {
        return api.get(downloadUrl, { responseType: 'blob' })
    }
    submitRecipeFeedback(feedbackRequest: RecipeFeedbackRequest) {
        var url = API_SERVICE_URL + serviceConfig.submitFeedbackURL
        return api.post(url, feedbackRequest)
    }
    feedbackAttachFile(payload: any) {
        const { id, file } = payload
        const formData = new FormData()
        formData.append('id', id)
        formData.append('filename', file[0].name)
        formData.append('document', file[0])
        var url = API_SERVICE_URL + serviceConfig.submitFeedbackURL
        url = url + `/${id}/attachment/${file[0].name}`

        return api.post(url, formData.get('document'), {
            headers: {
                'Content-Type': 'application/octet-stream',
            },
        })
    }
    saveRecipeFavorite(favoriteRequest: RecipeAddFavoriteRequest) {
        var url =
            API_SERVICE_URL +
            serviceConfig.saveRecipeFavorite +
            `/${favoriteRequest.uuid}` +
            '/favorite'
        return api.post(url, favoriteRequest)
    }
    getTagsValues(tagNames: string) {
        var url = API_SERVICE_URL + serviceConfig.getTagsValues
        return api.get(url + '?tagName=' + tagNames)
    }
    getFiltersValues(param: string) {
        var url = API_SERVICE_URL + serviceConfig.getFiltersValues
        return api.get(url + '?' + param)
    }
    saveRecipeStarRating(recipeStarRatingRequest: RecipeStarRatingRequest) {
        var url =
            API_SERVICE_URL +
            serviceConfig.getRecipeURL +
            `/${recipeStarRatingRequest.uuid}` +
            '/rating'
        return api.post(url, recipeStarRatingRequest)
    }
    saveRecipeVoting(votingRequest: RecipeAddVotingRequest) {
        var url =
            API_SERVICE_URL +
            serviceConfig.getRecipeURL +
            `/${votingRequest.uuid}` +
            '/vote'
        return api.post(url, votingRequest)
    }
    async getRecipeById(id: any) {
        var url = `${API_SERVICE_URL}${serviceConfig.getRecipeURL}/${id}`
        return api.get(`${url}`)
    }
    addRecipeLinkClickLog(addLinkClickRequest: RecipeAddLinkClickRequest) {
        var url =
            API_SERVICE_URL +
            serviceConfig.getRecipeURL +
            `/${addLinkClickRequest.uuid}` +
            '/link'
        return api.post(url, addLinkClickRequest)
    }
    saveSiteFeedback(siteFeedback: SiteFeedbackRequest) {
        var url = API_SERVICE_URL + serviceConfig.saveSiteFeedback
        return api.post(url, siteFeedback)
    }
    getLastLogin() {
        var url = API_SERVICE_URL + serviceConfig.getLastloginURL
        return api.get(url)
    }
    getBenchmarks(id: string) {
        var url = `${API_SERVICE_URL}${serviceConfig.getRecipeBenchmarks}/${id}/benchmark`
        return api.get(url)
    }
    saveRecipeLike(likeRequest: RecipeAddLikeRequest) {
        var url =
            API_SERVICE_URL +
            serviceConfig.saveRecipeFavorite +
            `/${likeRequest.uuid}` +
            '/like'
        return api.post(url, likeRequest)
    }
    addOptimizationSubscription(request: RecipeAddSubscriptionRequest) {
        var url = API_SERVICE_URL + serviceConfig.addOptimizationSubscription
        return api.post(url, request)
    }
    deleteOptimizationSubscription(uuid: string) {
        var url = API_SERVICE_URL + serviceConfig.addOptimizationSubscription
        url = url + `/${uuid}`

        return api.delete(url)
    }
}

export default new CatalogService()
