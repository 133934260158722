import { useEffect, useState, useRef, FunctionComponent } from 'react'
import styled from 'styled-components'
import {
    Button,
    Form,
    Input,
    Select,
    Upload,
    Progress,
    message,
    Row,
} from 'antd'

import { PaperClipOutlined, UploadOutlined } from '@ant-design/icons'
import { useAppDispatch, useAppSelector } from '../../config/hooks'
import { FormLabel } from 'react-bootstrap'
import {
    submitDiagnostic,
    downloadScraperScript,
} from '../../store/diagnostics.slice'

const { Option } = Select

const MainPagePanelComponent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
`

const MainPagePanelWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: flex-start;
`

const PageTitleTextLabel = styled(FormLabel)`
    ${(props) => props.theme.typography.xxxLarge}
    margin-bottom: 0.5rem;
    font-weight: 500;
    font-size: 2.25rem;
`
const UserOrgTextLabel = styled(FormLabel)`
    font-weight: 500;
    font-size: 1rem;
    color: green;
    //font-style: italic;
    font-family: IntelOne Text;
    text-align: left;
    color: #198754;
    margin-left: 3.3rem;
    margin-bottom: 2rem;
`

const ButtonRow = styled.div`
    display: flex;
    margin-top: 1rem;
`

const FormEl = styled(Form)`
    width: 100%;
    margin-left: 1.5rem;
`
const ButtonEl = styled(Button)`
    border-radius: 0px;
`
const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: left;
    flex-wrap: wrap;
`
const UploadContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-left: 3.3rem;
    width: 77%;
    min-width: 50rem;
    padding-top: 2rem;
    border-style: solid;
    border-color: #198754;
    border-width: 0.5px;
    border-radius: 1rem;
    margin-top: 1rem;
`
const DownloadContainer = styled(UploadContainer)`
    width: 77%;
`
const StepsTextLabel = styled(FormLabel)`
    ${(props) => props.theme.typography.xLarge}
    margin-top:1rem;
    margin-bottom: 1rem;
    font-weight: 400;
    font-size: 1.3rem;
    margin-left: 3.5rem;
`
export interface IMainPagePanel {
    userAuthPermissions?: any
}

export const MainPagePanel: FunctionComponent<IMainPagePanel> = ({
    userAuthPermissions,
}: IMainPagePanel) => {
    const dispatch = useAppDispatch()
    const [form] = Form.useForm()
    const scraperDownloadLinkRef = useRef<any>(null)

    const [percent, setPercent] = useState(0)
    const [fileList, setFileList] = useState([])
    const [selectedCSP, setSelectedCSP] = useState('')
    const [isfileUploadRequested, setIsfileUploadRequested] = useState(false)
    const [scraperFileDownloadUrl, setScraperFileDownloadUrl] = useState('')
    const [scraperFileName, setFileName] = useState('script.py')
    const [uploadFileName, setuploadFileName] = useState('')

    let userOrgName = ''

    if (userAuthPermissions) {
        userOrgName = userAuthPermissions?.organizationName
    }

    const uploadStatus = useAppSelector<any>(
        (state) => state.diagnosticsSubmit.diagnosticData
    )
    const uploadError = useAppSelector<any>(
        (state) => state.diagnosticsSubmit.uploadError
    )

    useEffect(() => {
        if (isfileUploadRequested) {
            if (uploadStatus && uploadStatus.id) {
                message.success('Successfully submitted diagnostic')
                setTimeout(() => window.location.reload(), 1000)
            }
            if (uploadError)
                message.error(
                    'Unauthorized - missing permissions in optimization.'
                )
            setIsfileUploadRequested(false)
            setPercent(0)
            form.setFieldsValue({ group: '' })
            form.setFieldsValue({ upload: '' })
        }
    }, [uploadStatus, uploadError])

    const getFile = (e: any) => {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }

    const onFinish = (values: any) => {
        const callback = (event: any) => {
            setPercent(Math.round((100 * event.loaded) / event.total))
        }
        values.callback = callback
        dispatch(submitDiagnostic(values))
        setIsfileUploadRequested(true)
    }
    const onFileChange = (fileInfo: any) => {
        setuploadFileName(fileInfo.file.name)
    }
    const onFormReset = () => {
        setuploadFileName('')
    }
    const downloadScraperData = useAppSelector<any>(
        (state) => state.diagnosticsSubmit.downloadScraperData
    )
    const downloadScraperError = useAppSelector<any>(
        (state) => state.diagnosticsSubmit.downloadScraperError
    )
    useEffect(() => {
        if (downloadScraperData) {
            const scraperDownloadUrl = URL.createObjectURL(
                new Blob([downloadScraperData], { type: `application/zip` })
            )
            setScraperFileDownloadUrl(scraperDownloadUrl)
            if (
                scraperDownloadLinkRef &&
                scraperDownloadLinkRef.current &&
                scraperDownloadUrl
            ) {
                scraperDownloadLinkRef.current.href = scraperDownloadUrl
                scraperDownloadLinkRef.current.download = `intel-cloud-insights-${selectedCSP}.zip`
                scraperDownloadLinkRef.current.click() //download file
            }
            URL.revokeObjectURL(scraperDownloadUrl)
        }
        if (downloadScraperError) {
            message.error(
                'error while downloading: ' + downloadScraperError.message
            )
        }
    }, [downloadScraperData, downloadScraperError])
    const handleCSPChange = (value: string) => {
        if (value) {
            setSelectedCSP(value)
        }
    }
    const onDownloadScraper = () => {
        dispatch(downloadScraperScript(selectedCSP))
    }

    return (
        <MainPagePanelComponent>
            <MainPagePanelWrapper>
                <PageTitleTextLabel style={{ marginLeft: '3.2rem' }}>
                    Cloud Insights
                </PageTitleTextLabel>
                <UserOrgTextLabel>
                    {'Organization: '}
                    {userOrgName}
                </UserOrgTextLabel>

                <FormContainer>
                    <StepsTextLabel>
                        Step 1: Download CSP collector file
                    </StepsTextLabel>
                    <DownloadContainer>
                        <FormEl
                            name="basic_download"
                            layout="vertical"
                            initialValues={{ remember: true }}
                            onFinish={onDownloadScraper}
                            autoComplete="off"
                        >
                            <Form.Item
                                label="Select CSP"
                                name="download"
                                rules={[
                                    {
                                        required: true,
                                        message: 'CSP name is required!',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select csp..."
                                    onChange={(value: string) =>
                                        handleCSPChange(value)
                                    }
                                    style={{ width: '15rem' }}
                                >
                                    <Option value="aws">AWS</Option>
                                    <Option value="azure">Azure</Option>
                                    {/* <Option value="gcp">GCP</Option> */}
                                </Select>
                            </Form.Item>
                            <ButtonRow>
                                <Form.Item>
                                    <ButtonEl
                                        type="primary"
                                        htmlType="submit"
                                        name=""
                                    >
                                        Download
                                    </ButtonEl>
                                </Form.Item>
                            </ButtonRow>
                        </FormEl>
                    </DownloadContainer>
                    <StepsTextLabel>
                        Step 2: Upload collector output file
                    </StepsTextLabel>
                    <UploadContainer>
                        <FormEl
                            name="basic"
                            layout="vertical"
                            initialValues={{ remember: true }}
                            onFinish={onFinish}
                            autoComplete="off"
                            form={form}
                            onReset={onFormReset}
                        >
                            <Form.Item
                                label="Select collector file"
                                name="upload"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Collector output file is required!',
                                    },
                                ]}
                                getValueFromEvent={getFile}
                            >
                                <Upload
                                    beforeUpload={() => false}
                                    fileList={fileList}
                                    onChange={onFileChange}
                                >
                                    <Button
                                        icon={
                                            <UploadOutlined
                                                style={{
                                                    marginBottom: '0.5rem',
                                                }}
                                                rev={undefined}
                                            />
                                        }
                                    >
                                        Click to Select File
                                    </Button>
                                    {percent > 0 ? (
                                        <Progress
                                            percent={percent}
                                            status="active"
                                        />
                                    ) : null}
                                </Upload>
                            </Form.Item>
                            {uploadFileName ? (
                                <FormLabel
                                    style={{
                                        color: '#0d6efd',
                                    }}
                                >
                                    <PaperClipOutlined rev={undefined} />
                                    {uploadFileName}
                                </FormLabel>
                            ) : (
                                ''
                            )}

                            <ButtonRow>
                                <Form.Item>
                                    <ButtonEl
                                        htmlType="reset"
                                        data-testid="reset_button"
                                    >
                                        Clear
                                    </ButtonEl>
                                </Form.Item>

                                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                                    <ButtonEl
                                        type="primary"
                                        htmlType="submit"
                                        data-testid="submit_diagnostic_button"
                                    >
                                        Submit
                                    </ButtonEl>
                                </Form.Item>
                            </ButtonRow>
                        </FormEl>
                    </UploadContainer>
                    <a
                        className="hidden"
                        download={scraperFileName}
                        href={scraperFileDownloadUrl}
                        ref={scraperDownloadLinkRef}
                    ></a>
                </FormContainer>
            </MainPagePanelWrapper>
        </MainPagePanelComponent>
    )
}
