import { FunctionComponent } from 'react'
import { Breadcrumb, Button, FormLabel, Spinner } from 'react-bootstrap'
import styled from 'styled-components'
import { _getDecryptedValueFromStorage } from '../../lib/encryptStorage'
import { WorkloadDetailsLogic } from './workloadDetails.hook'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../config/hooks'
import BenchamrkCard from './benchmarkCard'
import { LoadingState } from '../../models/loadingState'
import SalesHeaderPanel from './salesHeader'
import NoResultFoundPanel from './noResultFound'
import StringUtils from '../../lib/stringUtils'
import WorkloadBubble from './workloadBubble'

const WorkloadDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
`
const BenchmarkDetailsWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    flex-wrap: no-wrap;

    background: #ffffff;
`

const BreadcrumbItem = styled(Breadcrumb.Item)`
    display: flex;
    font-family: 'IntelOne Display';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    align-items: center;
    color: #2b2c30;
    .a {
        color: #2b2c30 !important;
        text-decoration: underline;
    }
`
const HeaderComponent = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: -1rem;
    justify-content: left;
    padding: 0 3%;
    background: #00377c;
    box-shadow: inset 0px 1px 0px rgba(43, 44, 48, 0.12),
        inset -1px 0px 0px rgba(43, 44, 48, 0.12),
        inset 1px 0px 0px rgba(43, 44, 48, 0.12),
        inset 0px -1px 0px rgba(43, 44, 48, 0.12);
    width: 100%;
    height: 275px;
`
const OverviewComponent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: left;
    background: #ffffff;
    width: 100%;
    padding: 0 2%;
`
const ExploreSolutionsComponent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: var(--steel-blue-steel-blue, #548fad);
    width: 100%;
    color: var(--white-white, #fff);
    font-family: 'IntelOne Display';
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 45px; /* 187.5% */
`

const WorkloadLogoComponent = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    margin-left: 5%;
    padding: 0.5rem;
    margin-top: 2rem;
    gap: 20px;
    left: 7.5rem;
    width: 200px;
    height: 200px;
    background: #ffffff;
    box-shadow: inset 0px 1px 0px rgba(43, 44, 48, 0.12),
        inset -1px 0px 0px rgba(43, 44, 48, 0.12),
        inset 1px 0px 0px rgba(43, 44, 48, 0.12),
        inset 0px -1px 0px rgba(43, 44, 48, 0.12);
`
const WorkloadComponent = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: 1rem;
    vertical-align: top;
    flex-direction: column;
    width: 80%;
    height: 10rem;
`

const TabsContainer = styled.div`
    flex-direction: center;
    width: 100%;
`
const BenchmarkTabComponent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: #f9f9f9;

    /* table outlines - light/table-outline-plain/full */
    box-shadow: inset 0px 1px 0px rgba(43, 44, 48, 0.12),
        inset -1px 0px 0px rgba(43, 44, 48, 0.12),
        inset 1px 0px 0px rgba(43, 44, 48, 0.12),
        inset 0px -1px 0px rgba(43, 44, 48, 0.12);
    width: 100%;
    padding-bottom: 10rem;
`
const StyledTab = styled(Tab)`
    display: table-cell;
    color: var(--text-placeholder, #6a6d75);
`
const StyledTabLabel = styled.div`
    width: 120px;
    height: 50px;
    padding: 0.5rem;
    margin: 0 0.1rem;
    text-align: center;
    font-family: Intel Clear;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 112.5% */
    border-radius: 6px 6px 0px 0px;
    box-shadow: 1px 0px 0px 0px rgba(43, 44, 48, 0.3) inset,
        -1px 0px 0px 0px rgba(43, 44, 48, 0.3) inset,
        0px 1px 0px 0px rgba(43, 44, 48, 0.3) inset;
`
const BenchmarkTabContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0.5rem;
    margin: 5% 8%;
`
const ResourcesTabComponent = styled.div`
    // display: flex;
    // flex-direction: row;
    // justify-content: center;
    // background: #fff;
    // width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0.5rem;
    margin: 5% 8%;
`
const ResourcesTabContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 90%;
    padding: 0.5rem;
    margin: 0 8%;
`
const ResourcesInnerDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 3%;
`
const ResourcesTextLabel = styled(FormLabel)`
    font-family: 'IntelOne Display';
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 45px;
    display: flex;
    align-items: center;
    padding-bottom: 1rem;
    padding-left: 2.5rem;
    color: #0054ae;
`
const CollateralTextLabel = styled(FormLabel)`
    font-weight: 700;
    font-size: 16px;
    color: #2b2c30;
    padding: 1rem;
    padding-left: 2.5rem;
`
const ResourcesLinksContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 2rem;
`
const ResourcesLinksText = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-family: 'IntelOne Display';
    font-weight: 400;
    font-size: 0.8rem;
    padding-left: 0.9rem;
    ${(props) => props.theme.typography.medium}
    justify-content: left;
    margin-bottom: 1rem;
    padding-left: 1rem;
`
const ResourcesLinksTextValue = styled.span`
    padding-right: 0.5rem;
    overflow-wrap: break-word;
    word-break: break-word;
    max-width: 30rem;
    padding-left: 0.5rem;
`
const WorkloadCount = styled.p`
    margin: 0 0 2rem 0;
    font-family: IntelOne Display;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(0, 84, 174, 1);
`
const ResourcesLink = styled.a`
    font-size: 14px;
    font-family: 'IntelOne Display';
    font-weight: 400;
    line-height: 20px;
`
const BenchmarkStatusComponent = styled.div`
    display: flex;
    background: rgba(0, 84, 174, 0.15);
    border-radius: 5px;
    height: 24px;
    margin: 0.1rem 0.5rem;
`
const BenchmarkStatusLabel = styled(FormLabel)`
    display: flex;
    padding: 0.6rem 0.5rem;
    align-items: center;
    font-family: 'IntelOne Display';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;

    /* Badge/Fill/Light/Primary */
    color: #0054ae;
    margin-top: 0.1rem;
`
const NotAutorizeContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: left;
    margin: 2rem;
`
const NotAutorizeLabel = styled(FormLabel)`
    ${(props) => props.theme.typography.xLarge}
    text-align: left;
    font-weight: 400;
    font-size: 1rem;
    margin-bottom: 50rem;
    color: red;
`
const SpinnerDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5rem;
    width: 100%;
`
const ShareButtoncontainer = styled.div`
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    margin-top: 2rem;
    margin-right: 2rem;
`
const ShareButton = styled(Button)`
    background-color: transparent;
    cursor: pointer;
    color: black;
    border-radius: 0;
    border: none;
    content: unset !important;
    &:hover {
        background-color: transparent;
        border: 0px none transparent;
    }
    &:focus {
        outline: none;
        /*box-shadow: none;*/
        background-color: transparent;
        border: 0px none transparent;
    }
`
export interface IWorkloadDetailsProps {}

const WorkloadDetails: FunctionComponent<
    IWorkloadDetailsProps
> = ({}: IWorkloadDetailsProps) => {
    let { workloadId } = useParams()
    const {
        onAccessApplyChange,
        activeTabKey,
        setActiveTabKey,
        onSearchKeyChange,
        onSearchKeydown,
        onShareLinkClick,
        onHeaderFilterClick,
        onHeaderFilterClearAllClick,
    } = WorkloadDetailsLogic({
        workloadId,
    } as IWorkloadDetailsProps)

    const queryParameters = new URLSearchParams(location.search)
    const isDevViewValue = queryParameters.get('isDevView')

    const isDevView = isDevViewValue && isDevViewValue === 'true' ? true : false

    let isNoBenchmarkData: boolean = false
    const shareLinkURL = `${window.location.href}`

    const workloadResults = useAppSelector(
        (state: any) => state.getWorkloadBenchmarksResult.data
    )
    const workloadBenchmarksList = useAppSelector(
        (state: any) => state.getWorkloadBenchmarksListResult.data
    )

    const workloadList = workloadBenchmarksList?.workloadGroups
        ? [...workloadBenchmarksList.workloadGroups]
        : undefined

    const workloadResources = workloadResults?.resources
        ? workloadResults?.resources
        : undefined
    const authPermissionsResult = useAppSelector<any>(
        (state: any) => state.authPermissionsResult.data
    )
    const authPermissionsLoading = useAppSelector<any>(
        (state: any) => state.authPermissionsResult.loading
    )
    let isIntelEmployee = false
    if (authPermissionsResult && authPermissionsResult?.isIntelEmployee) {
        isIntelEmployee = authPermissionsResult?.isIntelEmployee
    }

    if (workloadList?.length === 0) isNoBenchmarkData = true

    const workloadBenchmarksCard =
        workloadList &&
        workloadList.map((benchmark: any) => {
            console.log(benchmark)
            return (
                <>
                    <BenchamrkCard
                        key={benchmark.uuid}
                        workloadData={benchmark}
                        urlNavigation={'workload'}
                    ></BenchamrkCard>
                </>
            )
        })
    const workloadBenchmarkCardsRender = (
        <BenchmarkTabContainer>
            {!isNoBenchmarkData ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexWrap: 'wrap',
                    }}
                >
                    {workloadBenchmarksCard}
                </div>
            ) : (
                <div>{NoResultFoundPanel()}</div>
            )}
        </BenchmarkTabContainer>
    )
    const linksArray =
        workloadList && workloadList[0] ? workloadList[0].tags['links'] : []

    const LinkImageRender = (
        <span
            style={{
                paddingLeft: '0.2rem',
            }}
        >
            <img
                src="/links.png"
                height="15px"
                width="15px"
                alt="link logo"
                style={{
                    paddingBottom: '0.1rem',
                }}
            />
        </span>
    )
    const renderStatusLegandLabel = (
        label: string,
        FontColor: string,
        background: string
    ) => {
        return (
            <BenchmarkStatusComponent
                style={background ? { background: `${background}` } : {}}
            >
                <BenchmarkStatusLabel
                    style={FontColor ? { color: `${FontColor}` } : {}}
                >
                    {label}
                </BenchmarkStatusLabel>
            </BenchmarkStatusComponent>
        )
    }
    const renderLinkComponent =
        workloadResources &&
        Object.keys(workloadResources).map((category: any) => {
            {
                const data = workloadResources[category]

                return (
                    <>
                        <div>
                            <CollateralTextLabel>
                                {category}
                            </CollateralTextLabel>
                            {data && data.length > 0
                                ? data.map((link: any) => (
                                      <ResourcesLinksText
                                          style={{ marginLeft: '1rem' }}
                                      >
                                          <ResourcesLinksTextValue>
                                              <ResourcesLink
                                                  href={link.url}
                                                  target={'_blank'}
                                                  rel="noopener"
                                              >
                                                  {link.name}
                                                  {LinkImageRender}
                                              </ResourcesLink>
                                          </ResourcesLinksTextValue>
                                          {link.labels[0] &&
                                          link.labels[0] === 'Public'
                                              ? renderStatusLegandLabel(
                                                    'Public',
                                                    '#fff',
                                                    '#8BAE46'
                                                )
                                              : link.labels[0] === 'CNDA'
                                              ? renderStatusLegandLabel(
                                                    'CNDA',
                                                    '#C81326',
                                                    '#FFB6B9'
                                                )
                                              : link.labels[0] === 'NDA'
                                              ? renderStatusLegandLabel(
                                                    'NDA',
                                                    '#C81326',
                                                    '#FFB6B9'
                                                )
                                              : link.labels[0] ===
                                                'Internal Only'
                                              ? renderStatusLegandLabel(
                                                    'Internal Only',
                                                    '#906700',
                                                    '#FFE17A'
                                                )
                                              : ''}
                                      </ResourcesLinksText>
                                  ))
                                : ''}
                        </div>
                    </>
                )
            }
        })

    return (
        <WorkloadDetailsContainer>
            {authPermissionsLoading !== LoadingState.Pending ? (
                <BenchmarkDetailsWrapper>
                    <WorkloadDetailsContainer>
                        <SalesHeaderPanel
                            onSearchKeyDown={onSearchKeydown}
                            onSearchKeyChange={onSearchKeyChange}
                            onAccessChangeCallback={onAccessApplyChange}
                            onApplyFilterCallback={onHeaderFilterClick}
                            onClearAllCallback={onHeaderFilterClearAllClick}
                        />
                        <BenchmarkDetailsWrapper>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Breadcrumb style={{ padding: '0.5rem 1rem' }}>
                                    <BreadcrumbItem
                                        href="/catalog"
                                        style={{
                                            textDecoration: 'none',
                                            color: '#2B2C30',
                                        }}
                                    >
                                        Intel Optimization Hub
                                    </BreadcrumbItem>
                                    {isDevView ? (
                                        <BreadcrumbItem
                                            href={StringUtils.resolve(
                                                '/benchmarks'
                                            )}
                                            style={{
                                                textDecoration: 'none',
                                                color: '#2B2C30',
                                            }}
                                        >
                                            Benchmarks
                                        </BreadcrumbItem>
                                    ) : (
                                        <BreadcrumbItem
                                            href={StringUtils.resolve(
                                                '/salesview/home'
                                            )}
                                            style={{
                                                textDecoration: 'none',
                                                color: '#2B2C30',
                                            }}
                                        >
                                            Sales View Home
                                        </BreadcrumbItem>
                                    )}
                                    <BreadcrumbItem
                                        active
                                        style={{
                                            fontWeight: '500',
                                            color: '#0054AE',
                                        }}
                                    >
                                        {`${workloadResults?.workload} on Intel® Xeon® Processors`}
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            </div>

                            <HeaderComponent>
                                <WorkloadLogoComponent>
                                    <WorkloadBubble
                                        hrefValue={`/allWorkloads/${workloadResults?.uuid}?isDevView=${isDevView}`}
                                        uuid={workloadResults?.uuid}
                                        iconURL={workloadResults?.iconURL}
                                        name={workloadResults?.name}
                                        isWinningWorkload={
                                            workloadResults?.tags[
                                                'selling prioritization'
                                            ] &&
                                            workloadResults?.tags[
                                                'selling prioritization'
                                            ][0] &&
                                            (workloadResults?.tags[
                                                'selling prioritization'
                                            ][0] === 'Priority' ||
                                                workloadResults?.tags[
                                                    'selling prioritization'
                                                ][0] === 'Prioritized')
                                                ? true
                                                : false
                                        }
                                    />
                                </WorkloadLogoComponent>

                                <WorkloadComponent>
                                    <p
                                        style={{
                                            color: 'white',
                                            fontSize: '2.25rem',
                                            fontFamily: 'IntelOne Display',
                                            lineHeight: '3rem',
                                            marginBottom: 0,
                                        }}
                                    >
                                        {`${workloadResults?.workload} on Intel® Xeon® Processors`}
                                    </p>
                                    <p
                                        style={{
                                            color: '#FFF',
                                            fontSize: '1rem',
                                            fontFamily: 'IntelOne Display',
                                            lineHeight: '1.5rem',
                                        }}
                                    >
                                        {workloadResults?.description}
                                    </p>
                                </WorkloadComponent>
                                <ShareButtoncontainer>
                                    <ShareButton
                                        type="button"
                                        onClick={(e: any) =>
                                            onShareLinkClick(e)
                                        }
                                        value={shareLinkURL}
                                        title={'Share with others'}
                                    >
                                        <img
                                            src="/sales_img/share.svg"
                                            alt="share link"
                                            style={{
                                                height: '24px',
                                                width: '22px',
                                            }}
                                        />
                                    </ShareButton>
                                </ShareButtoncontainer>
                            </HeaderComponent>
                            <ExploreSolutionsComponent>
                                {isDevView
                                    ? `Explore Benchmarks`
                                    : `
                                    Explore Benchmarks, Optimizations,
                                    Solutions, & Case Studies
                                    `}
                            </ExploreSolutionsComponent>
                            <BenchmarkTabComponent>
                                <TabsContainer>
                                    <Tabs
                                        variant="tabs"
                                        id="tabs"
                                        defaultActiveKey={activeTabKey}
                                        onSelect={(k: any) =>
                                            setActiveTabKey(k)
                                        }
                                        className="mb-3"
                                        style={{
                                            width: '100%',
                                            justifyContent: 'center',
                                            paddingTop: '0.5rem',
                                            borderBottom: '2px solid #c9cace',
                                        }}
                                    >
                                        <StyledTab
                                            eventKey="listings"
                                            title={
                                                <StyledTabLabel tabIndex={0}>
                                                    Listings <br />
                                                    {workloadList &&
                                                    workloadList.length > 0
                                                        ? `(${workloadList?.length})`
                                                        : ''}
                                                </StyledTabLabel>
                                            }
                                        >
                                            {workloadBenchmarkCardsRender}
                                        </StyledTab>
                                        {workloadResources &&
                                        false &&
                                        Object.keys(workloadResources).length >
                                            0 ? (
                                            <StyledTab
                                                eventKey="Cloud"
                                                title={
                                                    <StyledTabLabel
                                                        tabIndex={0}
                                                    >
                                                        Resources
                                                    </StyledTabLabel>
                                                }
                                            >
                                                <ResourcesTabComponent>
                                                    <ResourcesTabContainer>
                                                        <ResourcesInnerDiv>
                                                            <ResourcesTextLabel>
                                                                Resources
                                                            </ResourcesTextLabel>

                                                            <ResourcesLinksContainer>
                                                                {
                                                                    renderLinkComponent
                                                                }
                                                            </ResourcesLinksContainer>
                                                        </ResourcesInnerDiv>
                                                    </ResourcesTabContainer>
                                                </ResourcesTabComponent>
                                            </StyledTab>
                                        ) : (
                                            ''
                                        )}
                                    </Tabs>
                                </TabsContainer>
                            </BenchmarkTabComponent>
                        </BenchmarkDetailsWrapper>
                    </WorkloadDetailsContainer>
                </BenchmarkDetailsWrapper>
            ) : (
                <SpinnerDiv>
                    <Spinner animation="border" variant="dark">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </SpinnerDiv>
            )}
        </WorkloadDetailsContainer>
    )
}

export default WorkloadDetails
