import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { LoadingState } from '../models/loadingState'
import BenchmarkService from '../services/benchmarkService'
import { BenchmarkSearchRequest } from '../models/benchmarkSearchRequest'

const initialState = {
    data: null,
    loading: LoadingState.Idle,
    error: null,
}

// First, create the thunk
let response: any = {}
export const getWorkloads = createAsyncThunk(
    'workloads/getWorkload',
    async (benchmarkSearchCriteria: BenchmarkSearchRequest) => {
        response = await BenchmarkService.getWorkloads(benchmarkSearchCriteria)
    }
)

const getWorkloadsSlice = createSlice({
    name: 'workloads',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getWorkloads.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(getWorkloads.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    if (
                        action.payload === undefined &&
                        response.data !== undefined
                    )
                        state.data = response.data
                }
            })
            .addCase(getWorkloads.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})

export const getDefaultWorkloads = createAsyncThunk(
    'getDefaultWorkloads/getDefaultWorkloads',
    async (searchObject: any) =>
        await BenchmarkService.getDefaultWorkloads(searchObject)
)

const getDefaultWorkloadsSlice = createSlice({
    name: 'getDefaultWorkloads',
    initialState: initialState,
    reducers: {},

    extraReducers: (builder) => {
        builder
        .addCase(getDefaultWorkloads.pending, (state, _) => {
            if (state.loading === LoadingState.Idle) {
                state.loading = LoadingState.Pending
            }
        })
        .addCase(getDefaultWorkloads.fulfilled, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.data = action.payload.data
            }
        })
        .addCase(getDefaultWorkloads.rejected, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.error = action.error
            }
        })
    },
})

export const getWorkloadBenchmarks = createAsyncThunk(
    'workloadBenchmarks/getWorkloadBenchmarks',
    async (searchObject: any) =>
        await BenchmarkService.getWorkloadBenchmarks(searchObject)
)

const getWorkloadBenchmarksSlice = createSlice({
    name: 'getWorkloadBenchmarks',
    initialState: initialState,
    reducers: {},

    extraReducers: (builder) => {
        builder
        .addCase(getWorkloadBenchmarks.pending, (state, _) => {
            if (state.loading === LoadingState.Idle) {
                state.loading = LoadingState.Pending
            }
        })
        .addCase(getWorkloadBenchmarks.fulfilled, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.data = action.payload.data
            }
        })
        .addCase(getWorkloadBenchmarks.rejected, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.error = action.error
            }
        })
    },
})
export const getWorkloadBenchmarksList = createAsyncThunk(
    'workloadBenchmarks/getWorkloadBenchmarksList',
    async (searchObject: any) =>
        await BenchmarkService.getWorkloadBenchmarksList(searchObject)
)

const getWorkloadBenchmarksListSlice = createSlice({
    name: 'getWorkloadBenchmarksList',
    initialState: initialState,
    reducers: {},

    extraReducers: (builder) => {
        builder
        .addCase(getWorkloadBenchmarksList.pending, (state, _) => {
            if (state.loading === LoadingState.Idle) {
                state.loading = LoadingState.Pending
            }
        })
        .addCase(getWorkloadBenchmarksList.fulfilled, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.data = action.payload.data
            }
        })
        .addCase(getWorkloadBenchmarksList.rejected, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.error = action.error
            }
        })
    },
})
// First, create the thunk
export const downloadBenchmarkSlide = createAsyncThunk(
    'benchmark/downloadBenchmarkSlide',
    async (downloadURL: string) => {
        return await BenchmarkService.downloadBenchmarkSlide(downloadURL)
    }
)

// Then, handle actions in your reducers:
const downloadBenchmarkSlideSlice = createSlice({
    name: 'downloadBenchmarkSlide',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(downloadBenchmarkSlide.pending, (state, _) => {
            if (state.loading === LoadingState.Idle) {
                state.loading = LoadingState.Pending
            }
        })
        .addCase(downloadBenchmarkSlide.fulfilled, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.data = action.payload.data
                state.error = null
            }
        })
        .addCase(downloadBenchmarkSlide.rejected, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.error = action.error
            }
        })
    },
})
export const getWorkloadOptimizations = createAsyncThunk(
    'workloadOptimizations/getWorkloadOptimizations',
    async (searchObject: any) =>
        await BenchmarkService.getWorkloadOptimizations(searchObject)
)

const getWorkloadOptimizationsSlice = createSlice({
    name: 'getWorkloadOptimizations',
    initialState: initialState,
    reducers: {},

    extraReducers: (builder) => {
        builder
        .addCase(getWorkloadOptimizations.pending, (state, _) => {
            if (state.loading === LoadingState.Idle) {
                state.loading = LoadingState.Pending
            }
        })
        .addCase(getWorkloadOptimizations.fulfilled, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.data = action.payload.data
            }
        })
        .addCase(getWorkloadOptimizations.rejected, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.error = action.error
            }
        })
    },
})
export const getWorkloadPartnerSolutions = createAsyncThunk(
    'WorkloadPartnerSolutions/getWorkloadPartnerSolutions',
    async (searchObject: any) =>
        await BenchmarkService.getWorkloadPartnerSolutions(searchObject)
)

const getWorkloadPartnerSolutionsSlice = createSlice({
    name: 'getWorkloadPartnerSolutions',
    initialState: initialState,
    reducers: {},

    extraReducers: (builder) => {
        builder
        .addCase(getWorkloadPartnerSolutions.pending, (state, _) => {
            if (state.loading === LoadingState.Idle) {
                state.loading = LoadingState.Pending
            }
        })
        .addCase(getWorkloadPartnerSolutions.fulfilled, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.data = action.payload.data
            }
        })
        .addCase(getWorkloadPartnerSolutions.rejected, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.error = action.error
            }
        })
    },
})
export const getWorkloadCustomerCaseStudies = createAsyncThunk(
    'WorkloadCustomerCaseStudies/getWorkloadCustomerCaseStudies',
    async (searchObject: any) =>
        await BenchmarkService.getWorkloadCustomerCaseStudies(searchObject)
)

const getWorkloadCustomerCaseStudiesSlice = createSlice({
    name: 'getWorkloadCustomerCaseStudies',
    initialState: initialState,
    reducers: {},

    extraReducers: (builder) => {
        builder
        .addCase(getWorkloadCustomerCaseStudies.pending, (state, _) => {
            if (state.loading === LoadingState.Idle) {
                state.loading = LoadingState.Pending
            }
        })
        .addCase(getWorkloadCustomerCaseStudies.fulfilled, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.data = action.payload.data
            }
        })
        .addCase(getWorkloadCustomerCaseStudies.rejected, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.error = action.error
            }
        })
    },
})

export const getSalesSidebarLinks = createAsyncThunk(
    'salesview/getSalesSidebarLinks',
    async () => await BenchmarkService.getSalesSidebarLinks()
)

const getSalesSidebarLinksSlice = createSlice({
    name: 'getSalesSidebarLinks',
    initialState: initialState,
    reducers: {},

    extraReducers: (builder) => {
        builder
        .addCase(getSalesSidebarLinks.pending, (state, _) => {
            if (state.loading === LoadingState.Idle) {
                state.loading = LoadingState.Pending
            }
        })
        .addCase(getSalesSidebarLinks.fulfilled, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.data = action.payload.data
            }
        })
        .addCase(getSalesSidebarLinks.rejected, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.error = action.error
            }
        })
    },
})

export const getWorkloadsResult = getWorkloadsSlice.reducer
export const getWorkloadBenchmarksResult = getWorkloadBenchmarksSlice.reducer
export const getDefaultWorkloadsResult = getDefaultWorkloadsSlice.reducer
export const getWorkloadBenchmarksListResult =
    getWorkloadBenchmarksListSlice.reducer
export const downloadBenchmarkSlideResult = downloadBenchmarkSlideSlice.reducer
export const getWorkloadOptimizationsResult =
    getWorkloadOptimizationsSlice.reducer
export const getWorkloadPartnerSolutionsResult =
    getWorkloadPartnerSolutionsSlice.reducer
export const getWorkloadCustomerCaseStudiesResult =
    getWorkloadCustomerCaseStudiesSlice.reducer
export const getSalesSidebarLinksResult = getSalesSidebarLinksSlice.reducer
