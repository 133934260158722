import { useAppSelector } from '../../../../config/hooks'
import { useEffect, useState } from 'react'
import { Typography } from '@mui/material'
import BarChart, { BarChartData } from '../../shared/charts/BarChart'

const topSearchesChartTitleSx = { fontSize: '1rem', marginLeft: '2rem' }

const topSearchesChartHeaders: [string, string, { role: string }] = [
    'Term',
    '# Searches',
    { role: 'annotation' },
]

const TopSearchesByDomain = () => {
    const searchDistData = useAppSelector<any>(
        (state) => state.getSearchDistResult.data
    )
    const searchDistLoading = useAppSelector<any>(
        (state) => state.getSearchDistResult.loading
    )

    const [chartData, setChartData] = useState<BarChartData>([
        topSearchesChartHeaders,
    ])

    useEffect(() => {
        if (searchDistData !== null && searchDistData?.data) {
            const newChartData = Object.entries(
                searchDistData.data as {
                    [key: string]: number
                }
            )
                .filter(([key, _]) => key !== '')
                .sort(([_A, valueA], [_B, valueB]) => valueB - valueA)
                .map(([name, value]) => [name, value, value])
                .slice(0, 10)

            setChartData([topSearchesChartHeaders, ...newChartData])
        }
    }, [searchDistData])

    return (
        <>
            <Typography variant="h6" sx={topSearchesChartTitleSx}>
                Top Searches
            </Typography>
            <BarChart
                chartData={chartData}
                dataLoadingState={searchDistLoading}
                fixedHeight
            />
        </>
    )
}

export default TopSearchesByDomain
