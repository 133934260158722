import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { LoadingState } from '../models/loadingState'
import LicenseService from '../services/licenseService'
import { SaveLicenseRequest } from '../models/saveLicenseRequest'

const initialState = {
    data: null,
    loading: LoadingState.Idle,
    error: null,
}

export const getLicense = createAsyncThunk(
    'getLicense/getLicense',
    async (id: string = '') => await LicenseService.getLicense(id)
)

const getLicenseSlice = createSlice({
    name: 'getLicense',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(getLicense.pending, (state, _) => {
            if (state.loading === LoadingState.Idle) {
                state.loading = LoadingState.Pending
            }
        })
        .addCase(getLicense.fulfilled, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.data = action.payload.data
            }
        })
        .addCase(getLicense.rejected, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.error = action.error
            }
        })
    },
})
let response: any = {}
export const saveLicenseDecision = createAsyncThunk(
    'saveLicenseDecision',
    async (saveLicenseRequest: SaveLicenseRequest) => {
        response = await LicenseService.saveLicenseAcceptenceDecision(
            saveLicenseRequest
        )
    }
)
// Then, handle actions in your reducers:
const saveLicenseDecisionSlice = createSlice({
    name: 'saveLicenseDecision',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(saveLicenseDecision.pending, (state, _) => {
            if (state.loading === LoadingState.Idle) {
                state.loading = LoadingState.Pending
            }
        })
        .addCase(saveLicenseDecision.fulfilled, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                if (action.payload === undefined && response.data !== undefined)
                    state.data = response.data
                else state.data = action.payload.data
                state.error = null
            }
        })
        .addCase(saveLicenseDecision.rejected, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.error = action.error
            }
        })
    },
})

export const getLicenseResult = getLicenseSlice.reducer
export const saveLicenseDecisionResult = saveLicenseDecisionSlice.reducer
