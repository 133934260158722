import { Stack, HTMLParser, ColParser } from '../../lib/flamegraph/parser'

const fgutils = require('../../lib/flamegraph/utils')

export interface Optimization {
    uuid: string
    tags: Tags
}

export interface Tags {
    filter?: Array<string>
    regex?: Array<string>
    ratio?: Array<number>
}

export interface MatchedStack {
    name: string
    samples: number
    benefit: number
    optimization: Optimization
    ratio: number
    time: number // The percentage of samples against the total
    pattern: string
}

export type {
    Stack
}

export
function findOptimizationsForFlamegraph(fg: Stack, opts: Array<Optimization>): Array<MatchedStack> {
    // Let's filter all optimizations that have a non-empty 'filter'
    // tag, which is used to try and match stacks in the flamegraph
    var optimizations = opts.filter((opt: Optimization) => {
        const filters = opt.tags.filter
        if (filters === undefined) {
            return false
        }
        if ((filters as Array<string>).length < 1) {
            return false
        }
        if ((filters as Array<string>)[0] == "") {
            return false
        }

        // True prevents from going deeper into the tree
        return true
    });
    //
    // We now have our flamegraph
    const totalSamples = fg.value;

    let optimizationMatches: Map<string, Array<MatchedStack>> = new Map();

    const addMatch = (opt: Optimization, stack: Stack) => {
        const match = {
            name: stack.name,
            samples: stack.value,
            benefit: 0,
            time: 0,
            optimization: opt,
            ratio: opt.tags.ratio![0],
            pattern: opt.tags.filter![0],
        }
        if (optimizationMatches.has(opt.uuid)) {
            // Is there a better way to do this?
            // Not using the ? operator, I mean.
            optimizationMatches.get(opt.uuid)?.push(match);
            return;
        }
        optimizationMatches.set(opt.uuid, [match]);
    }

    fgutils.Search(fg, (s: Stack): boolean => {
        var matched = false;
        for (let opt of optimizations) {
            if (!opt.tags.filter) {
                return false
            }
            if (opt.tags.regex && opt.tags.regex[0] == "true") {
                const re = RegExp(opt.tags.filter[0])
                if (re.test(s.name)) {
                    addMatch(opt, s);
                    matched = true
                    // see if there are more optimizations that match
                    continue
                }
                continue
            }
            // We treat it as regular strings pattern
            for (let bit of opt.tags.filter[0].split("|")) {
                if (s.name.includes(bit)) {
                    addMatch(opt, s);
                    matched = true
                    // see if there are more optimizations that match
                    continue
                }
            }
        }
        return matched
    })

    var out: Array<MatchedStack> = new Array();
    optimizationMatches.forEach((matches, uuid) => {
        if (matches.length == 0) {
            // ????
            optimizationMatches.delete(uuid);
            return
        }
        const reduced = matches.reduce((acc, cur) => {
            acc.name = cur.name
            acc.optimization = cur.optimization
            acc.samples += cur.samples
            if (cur.optimization.tags.ratio) {
                acc.ratio = cur.optimization.tags.ratio[0]
            }
            if (cur.optimization.tags.ratio) {
                acc.benefit += (cur.samples / totalSamples) *
                    100 * cur.optimization.tags.ratio[0]
                acc.time += (cur.samples / totalSamples) * 100
            }
            return acc;
        }, {
            name: matches[0].name,
            optimization: matches[0].optimization,
            time: 0,
            benefit: 0,
            samples: 0,
            ratio: 0,
            pattern: matches[0].pattern,
        })
        out.push(reduced)
    })


    return out
}
