import { useState, useCallback, useEffect, ChangeEvent, FormEvent } from 'react'
import { IWorkloadDetailsProps } from './workloadDetails'
import { useAppDispatch } from '../../config/hooks'
import { useNavigate, useParams } from 'react-router-dom'
import {
    getWorkloadBenchmarks,
    getWorkloadBenchmarksList,
} from '../../store/benchmark.slice'
import { getAuthPermissions } from '../../store/authPermissions.slice'
import { UserSalesPermissions } from '../../models/userRoles'

export function WorkloadDetailsLogic({}: IWorkloadDetailsProps) {
    const [selectedAccessLevel, setSelectedAccessLevel] = useState<string>('')

    const [activeTabKey, setActiveTabKey] = useState('listings')
    const [searchKey, setSearchKey] = useState<string>('')
    const [selectedFiltersValue, setSelectedFiltersValue] = useState<string[]>(
        []
    )
    const navigate = useNavigate()

    const dispatch = useAppDispatch()
    let { workloadId } = useParams()

    useEffect(() => {
        dispatch(getAuthPermissions())
        if (workloadId) {
            dispatch(
                getWorkloadBenchmarks({
                    params: workloadId,
                    tagValues: [],
                })
            )
            getBenchmarksListData()
        }
    }, [])

    const getBenchmarksListData = () => {
        dispatch(
            getWorkloadBenchmarksList({
                id: workloadId,
                groupType: '',
                accessGroup: getSelectedAccessGroup(),
                tagValues: selectedFiltersValue ? selectedFiltersValue : [],
            })
        )
    }

    useEffect(() => {
        getBenchmarksListData()
    }, [activeTabKey])

    const getSelectedAccessGroup = () => {
        let accessGroupFilters = ''
        if (selectedAccessLevel === UserSalesPermissions.InternalAll)
            accessGroupFilters = '&access-group=' + 'internal'
        else if (selectedAccessLevel) {
            accessGroupFilters =
                '&access-group=' + selectedAccessLevel.toLowerCase()
        }

        return accessGroupFilters
    }

    const onAccessApplyChange = useCallback((selectedAccessLevel: string) => {
        setSelectedAccessLevel(selectedAccessLevel)
    }, [])
    useEffect(() => {
        //get data based on user access permission
        getBenchmarksListData()
    }, [selectedAccessLevel])

    const onSearchKeyChange = useCallback((event: ChangeEvent<HTMLElement>) => {
        const target = event.currentTarget as HTMLTextAreaElement
        if (target.value != undefined) {
            setSearchKey(target.value)
            if (target.value === '') {
                //reset search refresh data for default search
                if (workloadId) {
                    getBenchmarksListData()
                }
            }
        }
    }, [])

    const onSearchKeydown = (eventKey: string) => {
        if (eventKey === 'Enter') {
            if (workloadId) {
                getBenchmarksListData()
            }
        }
    }
    const onShareLinkClick = useCallback((event: FormEvent<HTMLElement>) => {
        event.preventDefault()
        const target = event.currentTarget as HTMLButtonElement
        if (target.value !== '') {
            navigator.clipboard.writeText(target.value)
            alert('Link copied to clipboard: ' + target.value)
        }
    }, [])
    const onHeaderFilterClick = useCallback(
        (selectedFiltersValue: string[], count: number) => {
            setSelectedFiltersValue(selectedFiltersValue)
        },
        []
    )
    const onHeaderFilterClearAllClick = useCallback(
        (selectedFiltersValue: string[], count: number) => {
            setSelectedFiltersValue([])
        },
        []
    )

    return {
        onAccessApplyChange,
        activeTabKey,
        setActiveTabKey,
        onSearchKeyChange,
        onSearchKeydown,
        onShareLinkClick,
        onHeaderFilterClick,
        onHeaderFilterClearAllClick,
    }
}
