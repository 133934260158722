import { useEffect, useState, useCallback, FormEvent, ChangeEvent } from 'react'
import styled from 'styled-components'
import {
    FormLabel,
    Form,
    Col,
    Row,
    CloseButton,
    Accordion,
    FormControl,
    OverlayTrigger,
    Popover,
    Modal,
    Button,
} from 'react-bootstrap'
import { useAppDispatch, useAppSelector } from '../../config/hooks'
import { deleteFile, getRegistry } from '../../store/uploadRegistry.slice'
import Select from 'react-select'

const MultiKeyAccordion = styled(Accordion)`
    margin-bottom: 2rem;
    width: 100%;
`

const TextLabel = styled(FormLabel)`
    display: flex;
    color: var(--input-label-light-default, #494b51);
    font-family: 'IntelOne Display';
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
`

const DeleteSpan = styled.span`
    display: inline-flex;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: center;
    margin-left: 1rem;
    border-radius: 20rem;
    border: 2px solid;
    border-color: #6c757d;
    padding: 0.01rem 0 0.2rem 0;
`

const FileUploadControl = styled(FormControl)`
    display: flex;
    margin-bottom: 1rem;
    min-height: 4vh;
`

const PopoverKeyDiv = styled.div`
    background-color: #cff4fc;
    font-size: 1rem;
    padding: 0.5rem;
`
const AddLicenseDiv = styled.div`
    //margin-left: 2rem;
`
const ModalPopupContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`
const RequiredTextLabel = styled.span`
    display: flex;
    ${(props) => props.theme.typography.xLarge}
    color: red;
`

export interface MultiKeyFieldProps {
    label: string
    keyLabel: string
    valueLabel: string
    name: string
    entries: any
    setEntries: any
    additionalProperties: any
    readonly: boolean
    type: string
    required: boolean
    infoText: string
    uuid?: string
    isController?: boolean
    licenseText?: string
    setLicenseText?: any
    addButtonText?: string
    attachmentAccessLevelOptions?: any
    existingFilesList?: any
    setExistingFilesList?: any
}

const MultiKeyField = ({
    label,
    keyLabel,
    valueLabel,
    name,
    entries,
    setEntries,
    additionalProperties,
    readonly,
    type,
    required,
    infoText,
    uuid,
    isController,
    licenseText,
    setLicenseText,
    addButtonText,
    attachmentAccessLevelOptions,
    existingFilesList,
    setExistingFilesList,
}: MultiKeyFieldProps) => {
    const [deleteEntryId, setDeleteEntryId] = useState('')
    const [isAddEntry, setIsAddEntry] = useState(false)
    const [isFileExist, setIsFileExist] = useState(false)
    const [totalEntriesCount, setTotalEntriesCount] = useState(0)
    const [showLicensePopup, setShowLicensePopup] = useState(false)
    const [isAllEntriesDeleted, setIsAllEntriesDeleted] = useState(false)
    const [selectedAttachmentAccess, setSelectedAttachmentAccess] = useState<
        {
            id: string
            accessGroups: []
        }[]
    >([])
    const [existingAttachmentAccess, setExistingAttachmentAccess] = useState<
        {
            id: string
            accessGroups: []
        }[]
    >([])

    const handleLicensePopupClose = () => setShowLicensePopup(false)

    const dispatch = useAppDispatch()

    const handleAddEntry = useCallback((event: FormEvent<HTMLElement>) => {
        event.preventDefault()
        setTotalEntriesCount((state) => state + 1)
        setIsAddEntry(true)
    }, [])

    useEffect(() => {
        if (additionalProperties) {
            if (
                additionalProperties[name] &&
                typeof additionalProperties[name] !== 'string' &&
                additionalProperties[name].length > 0 &&
                entries &&
                entries.length === 0
            ) {
                let entriesArray: {
                    id: string
                    isDeleted: boolean
                    key: string
                    value: string
                }[] = []
                additionalProperties[name].map((entry: any, index: number) => {
                    entriesArray.push({
                        id: `${index}`,
                        isDeleted: false,
                        key: entry.key,
                        value: entry.value,
                    })
                })
                setTotalEntriesCount(entriesArray.length)
                setEntries(entriesArray)
            } else {
                if (entries && entries.length === 0) {
                    setEntries([
                        { id: '1', isDeleted: false, key: '', value: '' },
                    ])
                    setTotalEntriesCount(1)
                    setSelectedAttachmentAccess([{ id: '1', accessGroups: [] }])
                }
            }
        } else {
            if (entries && entries.length === 0) {
                setEntries([{ id: '1', isDeleted: false, key: '', value: '' }])
                setTotalEntriesCount(1)
                setSelectedAttachmentAccess([{ id: '1', accessGroups: [] }])
            }
        }
        if (existingFilesList) {
            const selectedFileAccess = existingFilesList.map((item: any) => ({
                id: item.id,
                accessGroups: item.fileAccessGroups
                    ? item.fileAccessGroups.map((val: string) => ({
                          value: val,
                          label: val,
                      }))
                    : [],
            }))
            setExistingAttachmentAccess(selectedFileAccess)
        }
    }, [additionalProperties])

    useEffect(() => {
        if (isAddEntry) {
            entries.push({
                id: `${totalEntriesCount}`,
                isDeleted: false,
                key: '',
                value: '',
            })
            setEntries(entries)
            setIsAddEntry(false)
            selectedAttachmentAccess.push({
                id: `${totalEntriesCount}`,
                accessGroups: [],
            })

            setSelectedAttachmentAccess(selectedAttachmentAccess)
        }
    }, [isAddEntry])

    const handleDeleteExistingFileRow = useCallback(
        (event: FormEvent<HTMLElement>) => {
            event.preventDefault()
            const deletedFile = existingFilesList.filter(
                (item: any) => item.id == event.currentTarget.id
            )
            if (deletedFile && deletedFile.length > 0)
                dispatch(
                    deleteFile({
                        id: uuid,
                        fileName: deletedFile[0].fileName,
                        isController: true,
                    })
                )
        },
        []
    )
    const deleteFileResult = useAppSelector<any>(
        (state: any) => state.deleteUploadedOptimizationFileResult.data
    )
    useEffect(() => {
        if (deleteFileResult) {
            if (uuid) {
                dispatch(getRegistry({ id: uuid, isController: isController }))
            }
        }
    }, [deleteFileResult])
    const handleDeleteRow = useCallback(
        (event: FormEvent<HTMLElement>) => {
            event.preventDefault()
            const idx = event.currentTarget.id
            setDeleteEntryId(idx)
            entries.map((entry: any) => {
                if (idx === entry.id) {
                    entry.isDeleted = true
                }
            })
            const entriesItem = entries.find(
                (item: any) => item.isDeleted === false
            )
            if (!entriesItem) setIsAllEntriesDeleted(true)
        },
        [entries]
    )
    useEffect(() => {
        if (deleteEntryId !== undefined && deleteEntryId !== '') {
            setEntries(entries)
        }
    }, [deleteEntryId, entries])

    const handleFieldEdit = useCallback(
        (event: FormEvent<HTMLElement>, type: string) => {
            const target = event.currentTarget as HTMLTextAreaElement
            const idx = event.currentTarget.id
            entries.map((entry: any) => {
                if (idx === entry.id) {
                    entry[type] = target.value
                }
            })
        },
        [entries]
    )

    const onFileChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLInputElement
            const idx = event.currentTarget.id
            if (target.files) {
                entries.map((entry: any) => {
                    if (idx === entry.id) {
                        entry.files = target.files
                        setIsFileExist(true)
                        setIsAllEntriesDeleted(false)
                    }
                })
            }
        },
        [entries]
    )
    const onAddLicenseClick = useCallback((event: FormEvent<HTMLElement>) => {
        setShowLicensePopup(true)
    }, [])

    const onLicenseTextChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLTextAreaElement
            setLicenseText(target.value)
        },
        []
    )
    const handleAddLicenseClick = useCallback(
        (event: FormEvent<HTMLElement>) => {
            setShowLicensePopup(false)
        },
        []
    )
    const renderExistingFileNames = (type: string) => {
        if (type === 'file' && existingFilesList) {
            return (
                <Row>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            margin: '1rem 0',
                        }}
                    >
                        {existingFilesList.length > 0 &&
                            existingFilesList.map((item: any) => {
                                return (
                                    <div key={item.id}>
                                        {!item.isDeleted ? (
                                            <Row
                                                style={{ padding: '0.5rem 0' }}
                                            >
                                                <Form.Group as={Col} md="6">
                                                    <div
                                                        style={{
                                                            color: '#0a58ca',
                                                        }}
                                                    >
                                                        <i className="uil uil-paperclip"></i>{' '}
                                                        {item.fileName}
                                                    </div>
                                                </Form.Group>
                                                <Form.Group as={Col} md="4">
                                                    <Select
                                                        name="attachmentAccessLevel"
                                                        aria-label="Select access level"
                                                        isSearchable={true}
                                                        isClearable={true}
                                                        isMulti={true}
                                                        options={
                                                            attachmentAccessLevelOptions
                                                        }
                                                        value={
                                                            existingAttachmentAccess[
                                                                Number(
                                                                    item.id
                                                                ) - 1
                                                            ]?.accessGroups
                                                        }
                                                        onChange={(
                                                            target: any
                                                        ) =>
                                                            handleExistingAttachmentAccessChange(
                                                                target,
                                                                item.id
                                                            )
                                                        }
                                                        isDisabled={readonly}
                                                        placeholder="Select attachment access groups..."
                                                    />
                                                </Form.Group>
                                                <Form.Group
                                                    as={Col}
                                                    style={{
                                                        width: '0.5rem',
                                                        marginTop: '.2rem',
                                                    }}
                                                >
                                                    {!readonly ? (
                                                        <DeleteSpan>
                                                            <CloseButton
                                                                className="p-1"
                                                                id={item.id}
                                                                onClick={
                                                                    handleDeleteExistingFileRow
                                                                }
                                                            />
                                                        </DeleteSpan>
                                                    ) : (
                                                        ''
                                                    )}
                                                </Form.Group>
                                            </Row>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                )
                            })}
                    </div>
                </Row>
            )
        } else return <div></div>
    }
    const handleAttachmentAccessChange = useCallback(
        (target: any, idx: string) => {
            let newAccessGroups: {
                id: string
                accessGroups: []
            }[] = []

            selectedAttachmentAccess.map((item) => {
                newAccessGroups.push({
                    id: item.id,
                    accessGroups: item.id === idx ? target : item.accessGroups,
                })
            })
            setSelectedAttachmentAccess(newAccessGroups)
            const accessValues = target.map((item: any) => {
                return item.value
            })
            entries.map((entry: any) => {
                entry.fileAccessGroups =
                    idx === entry.id ? accessValues : entry.fileAccessGroups
            })
        },
        [entries, selectedAttachmentAccess]
    )
    const handleExistingAttachmentAccessChange = useCallback(
        (target: any, idx: string) => {
            let newAccessGroups: {
                id: string
                accessGroups: []
            }[] = []

            existingAttachmentAccess.map((item) => {
                newAccessGroups.push({
                    id: item.id,
                    accessGroups: item.id === idx ? target : item.accessGroups,
                })
            })
            setExistingAttachmentAccess(newAccessGroups)
            const accessValues = target.map((item: any) => {
                return item.value
            })
            existingFilesList.map((entry: any) => {
                entry.fileAccessGroups =
                    idx === entry.id ? accessValues : entry.fileAccessGroups
            })
        },
        [existingFilesList, existingAttachmentAccess]
    )

    return (
        <MultiKeyAccordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
                <Accordion.Header>
                    {label}
                    {infoText.length > 0 && (
                        <OverlayTrigger
                            placement={'right'}
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                                <Popover
                                    id="pop_timeline"
                                    style={{
                                        maxWidth: '20%',
                                    }}
                                >
                                    <Popover.Body>
                                        <PopoverKeyDiv>
                                            {infoText}
                                        </PopoverKeyDiv>
                                    </Popover.Body>
                                </Popover>
                            }
                        >
                            <i
                                className="uil uil-info-circle pointer"
                                tabIndex={0}
                            ></i>
                        </OverlayTrigger>
                    )}
                </Accordion.Header>
                <Accordion.Body>
                    {renderExistingFileNames(type)}
                    {entries.map((entry: any) => {
                        if (type === 'text') {
                            return (
                                <div
                                    key={entry.id}
                                    style={{ marginTop: '0.5rem' }}
                                >
                                    {!entry.isDeleted ? (
                                        <Row>
                                            <Form.Group as={Col} md="5">
                                                <TextLabel
                                                    data-testid={`key-label`}
                                                >
                                                    {keyLabel}
                                                </TextLabel>
                                                <Form.Control
                                                    id={`${entry.id}`}
                                                    // name={`${name}-key-${entry.id}`}
                                                    required={required}
                                                    data-testid={`${name}-key-${entry.id}-text`}
                                                    defaultValue={
                                                        entry.key
                                                            ? entry.key
                                                            : ''
                                                    }
                                                    onChange={(evt) =>
                                                        handleFieldEdit(
                                                            evt,
                                                            'key'
                                                        )
                                                    }
                                                    readOnly={readonly}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} md="5">
                                                <TextLabel
                                                    data-testid={`${name}-value-${entry.id}-label`}
                                                >
                                                    {valueLabel}
                                                </TextLabel>
                                                <Form.Control
                                                    id={`${entry.id}`}
                                                    // name={`${name}-key-${entry.id}`}
                                                    required={required}
                                                    data-testid={`${name}-value-${entry.id}-text`}
                                                    defaultValue={
                                                        entry.value
                                                            ? entry.value
                                                            : ''
                                                    }
                                                    onChange={(evt) =>
                                                        handleFieldEdit(
                                                            evt,
                                                            'value'
                                                        )
                                                    }
                                                    readOnly={readonly}
                                                />
                                            </Form.Group>
                                            <Form.Group
                                                as={Col}
                                                style={{
                                                    width: '5rem',
                                                    marginTop: '2.2rem',
                                                }}
                                            >
                                                {!readonly ? (
                                                    <DeleteSpan>
                                                        <CloseButton
                                                            className="p-1"
                                                            onClick={
                                                                handleDeleteRow
                                                            }
                                                            id={`${entry.id}`}
                                                        />
                                                    </DeleteSpan>
                                                ) : (
                                                    ''
                                                )}
                                            </Form.Group>
                                        </Row>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            )
                        } else if (type === 'file') {
                            return (
                                <div key={entry.id}>
                                    {!entry.isDeleted ? (
                                        <Row>
                                            <Form.Group as={Col} md="6">
                                                <FileUploadControl
                                                    type="file"
                                                    id={`${entry.id}`}
                                                    onChange={onFileChange}
                                                    disabled={readonly}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} md="4">
                                                <Select
                                                    name="attachmentAccessLevel"
                                                    aria-label="Select access level"
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    isMulti={true}
                                                    options={
                                                        attachmentAccessLevelOptions
                                                    }
                                                    value={
                                                        selectedAttachmentAccess[
                                                            Number(entry.id) - 1
                                                        ]?.accessGroups || null
                                                    }
                                                    onChange={(target: any) =>
                                                        handleAttachmentAccessChange(
                                                            target,
                                                            entry.id
                                                        )
                                                    }
                                                    isDisabled={readonly}
                                                    placeholder="Select attachment access groups..."
                                                />
                                            </Form.Group>
                                            <Form.Group
                                                as={Col}
                                                style={{
                                                    width: '0.5rem',
                                                    marginTop: '.2rem',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                    }}
                                                >
                                                    {!readonly ? (
                                                        <div>
                                                            <DeleteSpan>
                                                                <CloseButton
                                                                    className="p-1"
                                                                    onClick={
                                                                        handleDeleteRow
                                                                    }
                                                                    id={`${entry.id}`}
                                                                />
                                                            </DeleteSpan>
                                                        </div>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                            </Form.Group>
                                        </Row>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            )
                        }
                    })}
                    <Row>
                        <Form.Group as={Col} md="3" style={{ padding: '2rem' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}
                            >
                                {!readonly ? (
                                    <a
                                        href="javascript:void(0)"
                                        onClick={handleAddEntry}
                                    >
                                        {addButtonText
                                            ? addButtonText
                                            : '+Add New'}
                                    </a>
                                ) : (
                                    ''
                                )}
                            </div>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group
                            as={Col}
                            md="3"
                            style={{ padding: '1rem 1.5rem' }}
                        >
                            {(!readonly &&
                                isFileExist &&
                                !isAllEntriesDeleted) ||
                            (!readonly &&
                                existingFilesList &&
                                existingFilesList.length > 0) ? (
                                <AddLicenseDiv>
                                    <Button
                                        variant="primary"
                                        onClick={onAddLicenseClick}
                                        style={{ width: '8rem' }}
                                    >
                                        Add License
                                    </Button>
                                </AddLicenseDiv>
                            ) : (
                                ''
                            )}
                        </Form.Group>
                    </Row>
                </Accordion.Body>
            </Accordion.Item>
            <ModalPopupContainer>
                {/* License Model */}
                <Modal
                    show={showLicensePopup}
                    onHide={handleLicensePopupClose}
                    backdrop="static"
                    keyboard={true}
                    size="lg"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Add License</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-3">
                            <TextLabel>
                                <RequiredTextLabel>*</RequiredTextLabel>
                                License:
                            </TextLabel>
                            <Form.Control
                                as="textarea"
                                rows={20}
                                placeholder="Enter license text here..."
                                autoFocus
                                required
                                onChange={onLicenseTextChange}
                                defaultValue={licenseText}
                            />
                        </Form.Group>
                        <Modal.Footer>
                            <Button
                                variant="secondary"
                                onClick={handleLicensePopupClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                disabled={licenseText !== '' ? false : true}
                                onClick={handleAddLicenseClick}
                            >
                                Add
                            </Button>
                        </Modal.Footer>
                    </Modal.Body>
                </Modal>
            </ModalPopupContainer>
        </MultiKeyAccordion>
    )
}

export default MultiKeyField
