import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { LoadingState } from '../models/loadingState'
import DiscountService from '../services/discountService'
import { CreateDiscountCodeResponse } from '../models/createDiscountCodeResponse'
import { enableMapSet } from 'immer'

enableMapSet()

const initialState = {
    data: null,
    loading: LoadingState.Idle,
    error: null,
}
const codeState = {
    data: new Map<String, CreateDiscountCodeResponse>(),
    loading: LoadingState.Idle,
    error: null,
}

export const getDiscounts = createAsyncThunk(
    'getDiscount/getDiscounts',
    async (code: string | undefined = '') =>
        await DiscountService.getDiscounts(code)
)

const getDiscountSlice = createSlice({
    name: 'getDiscount',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getDiscounts.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(getDiscounts.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload.data
                }
            })
            .addCase(getDiscounts.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})

export const acceptDiscounts = createAsyncThunk(
    'acceptDiscount/acceptDiscounts',
    async (code: string | undefined = '') =>
        await DiscountService.acceptDiscounts(code)
)

const acceptDiscountSlice = createSlice({
    name: 'acceptDiscount',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(acceptDiscounts.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(acceptDiscounts.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload.data
                }
            })
            .addCase(acceptDiscounts.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})

export const createDiscountCode = createAsyncThunk(
    'createDiscountCode/createDiscountCode',
    async (request: any) => {
        return await DiscountService.generateDiscount(request)
    }
)


const getCodeMap = (
    state: Map<String, CreateDiscountCodeResponse>,
    response: CreateDiscountCodeResponse
): Map<String, CreateDiscountCodeResponse> => {
    if (
        response === undefined ||
        response === null ||
        response.code === undefined
    )
        return state

    let map = new Map<String, CreateDiscountCodeResponse>()
    map.set(response.code, response)
    const newState = new Map<String, CreateDiscountCodeResponse>([...map, ...state])

    return newState
}

const createDiscountCodeSlice = createSlice({
    name: 'createDiscountCode',
    initialState: codeState,
    reducers: {
        resetCodeState: (state, action) => {
            return codeState
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(createDiscountCode.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                    state.error = null
                }
            })
            .addCase(createDiscountCode.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                }

                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Idle
                    state.data = getCodeMap(state.data, action.payload.data)
                    state.error = null
                }
            })
            .addCase(createDiscountCode.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})

export const getDiscountResult = getDiscountSlice.reducer
export const acceptDiscountResult = acceptDiscountSlice.reducer
export const createDiscountCodeResult = createDiscountCodeSlice.reducer
const { resetCodeState } = createDiscountCodeSlice.actions
export const clearCodeState = () => (dispatch: any) => {
    try {
        return dispatch(resetCodeState({}))
    } catch (e: any) {
        return console.error(e.message)
    }
}
