import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ImageGenerationRequest } from '../aiModels/models/imageGenerationRequest'
import { LoadingState } from '../models/loadingState'
import ImageGenerationService from '../services/imageGenerationService'

const initialState = {
    data: null,
    loading: LoadingState.Idle,
    error: null,
}

export const SubmitImageGeneration = createAsyncThunk(
    'PostImageGeneration',
    async (request: ImageGenerationRequest) => {
        return await ImageGenerationService.PostImageGeneration(request)
    }
)

// Then, handle actions in your reducers:
const SubmitImageGenerationSlice = createSlice({
    name: 'PostImageGeneration',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(SubmitImageGeneration.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(SubmitImageGeneration.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload?.data
                        ? action.payload?.data
                        : []
                }
            })
            .addCase(SubmitImageGeneration.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})

export const submitImageGenerationResult = SubmitImageGenerationSlice.reducer
