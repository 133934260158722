import createTypography from '@mui/material/styles/createTypography'
import palette from './palette'

const typography = createTypography(palette, {
    fontFamily: 'IntelOne Text',
    h1: {
        fontFamily: 'IntelOne Display',
        fontSize: '2.5rem',
        fontWeight: 400,
        marginBlock: '1rem 0',
    },
    h2: {
        fontFamily: 'IntelOne Display',
        fontSize: '1.5rem',
        fontWeight: 400,
        marginBlock: '1rem',
    },
    h3: {
        fontFamily: 'IntelOne Display',
    },
    h4: {
        fontFamily: 'IntelOne Display',
    },
    h5: {
        fontFamily: 'IntelOne Display',
    },
    h6: {
        fontFamily: 'IntelOne Display',
    },
    button: {
        textTransform: 'none',
    },
})

export default typography
