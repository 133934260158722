import { typography } from './typography'
import { color } from './color'
import { shape } from './shape'
import { spacing } from './spacing'
import { size } from './size'
import { DefaultTheme } from 'styled-components'

const defaultTheme: DefaultTheme = {
    color,
    typography,
    shape,
    spacing,
    size,
}

export { defaultTheme }
