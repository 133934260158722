/************************************************
 * Copyright (C) 2021 Intel Corporation
 ************************************************/
import styled from 'styled-components'
import { FunctionComponent, Dispatch } from 'react'
import { connect } from 'react-redux'
import { LoadingState } from '../../models/loadingState'
import { createOrg } from '../../store/createOrg.slice'
import { CreateOrgRequest } from '../../models/createOrgRequest'
import { Alert, Button, Form, FormLabel } from 'react-bootstrap'
import stringUtils from '../../lib/stringUtils'
import PageLinkPanel from '../../layout/pageLinks'
import UserPanel from '../../layout/userProfile'
import FooterPanel from '../../layout/footer'
import { AddOrganizationContainerLogic } from './addOrganizationContainer.hook'
import HeaderPanel from '../../layout/header'

const AddOrganizationPanelContainer = styled.div`
    display: flex;
    flex-grow: 1;
    height: ${(props) => props.theme.size.mainContainerHeight};
    width: ${(props) => props.theme.size.mainContainerWidth};
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: space-between;
    @media screen and (min-width: 1200px) {
        overflow-x: hidden;
    }
`
const AddOrganizationHorizontalContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: space-between;
`
const AddOrganizationBodyContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: center;
    background-color: ${(props) => props.theme.color.background.defaultGrey};
    padding-bottom: 10rem;
`

const AddOrganizationContainerReposition = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
`
const AddOrganizationPanelWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: center;
    align-items: center;
`
const AddOrgFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 3rem;
    min-height: 25rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    background: #ffffff;
    margin-top: 1rem;
    margin-bottom: 2rem;
`

const PageTitleTextLabel = styled(FormLabel)`
    text-align: left;
    font-weight: 500;
    font-size: 2rem;
    margin-top: 1rem;
`
const CreateOrgComponent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 2rem;
`
const TextLabel = styled(Form.Label)`
    display: flex;
    ${(props) => props.theme.typography.xLarge}
    text-align: left;
    font-weight: 500;
`
const TextBoxWrapper = styled(Form.Control)`
    display: flex;
    border-radius: ${(props) => props.theme.shape.input.borderRadius};
    margin-bottom: 1rem;
    max-width: 20vw;
    min-height: 4vh;
`
const RequiredTextLabel = styled(FormLabel)`
    display: flex;
    ${(props) => props.theme.typography.xLarge}
    color: red;
`
const ButtonComponent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-top: 3rem;
    padding-bottom: 3rem;
`
const SignUpButton = styled(Button)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 9rem;
    max-width: 9rem;
    margin-right: 1rem;
    border-radius: 0;
    &:hover {
        background-color: ${(props) =>
            props.theme.color.button.background.hover.primary};
        transition: 0.5s;
    }
`
const ResetButton = styled(SignUpButton)`
    margin-left: 1rem;
    width: 8rem;
    max-width: 8rem;
`
const PageLinkPanelPanelWrapper = styled.div`
    display: flex;
    margin-left: 2rem;
`

export interface IAddOrganizationContainerProps {
    createOrg: typeof createOrg
    createOrgLoading: LoadingState
    createOrgResult: any
    createOrgError?: any
}

const AddOrganizationContainer: FunctionComponent<IAddOrganizationContainerProps> & {
    defaultProps: Partial<IAddOrganizationContainerProps>
} = ({
    createOrg,
    createOrgLoading,
    createOrgResult,
    createOrgError,
}: IAddOrganizationContainerProps) => {
    const {
        onError,
        onSuccessMessage,
        validated,
        onSubmit,
        onCancel,
        onOrgChange,
        organizationName,
    } = AddOrganizationContainerLogic({
        createOrg,
        createOrgLoading,
        createOrgResult,
        createOrgError,
    } as IAddOrganizationContainerProps)

    return (
        <AddOrganizationPanelContainer>
            <HeaderPanel />
            <AddOrganizationHorizontalContainer>
                <AddOrganizationBodyContainer>
                    <UserPanel></UserPanel>
                    <AddOrganizationContainerReposition>
                        <AddOrganizationPanelWrapper>
                            <PageTitleTextLabel data-testid="page-title-label">
                                Add New Organization
                            </PageTitleTextLabel>
                            <AddOrgFormContainer>
                                <CreateOrgComponent>
                                    <Form
                                        noValidate
                                        validated={validated}
                                        onSubmit={onSubmit}
                                        id="createOrgForm"
                                    >
                                        <Form.Group controlId="OrgName">
                                            <TextLabel>
                                                <RequiredTextLabel>
                                                    *
                                                </RequiredTextLabel>
                                                Organization name{' '}
                                            </TextLabel>
                                            <TextBoxWrapper
                                                placeholder="Enter org's name..."
                                                onChange={onOrgChange}
                                                required
                                                value={organizationName}
                                            />

                                            <Form.Control.Feedback type="invalid">
                                                Please enter your org's name.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <ButtonComponent>
                                            <SignUpButton
                                                variant="primary"
                                                type="submit"
                                                onClick={onSubmit}
                                            >
                                                Submit
                                            </SignUpButton>

                                            <ResetButton
                                                variant="primary"
                                                type="reset"
                                                onClick={onCancel}
                                            >
                                                Reset
                                            </ResetButton>
                                        </ButtonComponent>
                                    </Form>
                                </CreateOrgComponent>
                            </AddOrgFormContainer>
                        </AddOrganizationPanelWrapper>
                    </AddOrganizationContainerReposition>
                </AddOrganizationBodyContainer>
            </AddOrganizationHorizontalContainer>
            <FooterPanel></FooterPanel>
        </AddOrganizationPanelContainer>
    )
}

AddOrganizationContainer.defaultProps = {}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        createOrg: (createOrgRequest: CreateOrgRequest) =>
            dispatch(createOrg(createOrgRequest)),
    }
}

const mapStateToProps = (state: any) => {
    return {
        createOrgError: state.createOrgResult.error,
        createOrgLoading: state.createOrgResult.loading,
        createOrgResult: state.createOrgResult.data,
    }
}

type StateToPropsType = ReturnType<typeof mapStateToProps>
type DispatchToPropsType = typeof mapDispatchToProps

export default connect<StateToPropsType, DispatchToPropsType>(
    mapStateToProps,
    mapDispatchToProps
)(AddOrganizationContainer)
