import {Stack} from "./parser";

// If the f() argument returns true, the search won't go deeper in the current
// branch, but it will continue with the rest of the siblings.
export function Search(s: Stack, f: (s: Stack) => boolean) {
    const searcher = makeSearcher();
    searcher(s, f)
}

// We need a closure which skips matching the root which must not be matched
// as it is NOT a function name
function makeSearcher() {
    var isRoot = true;

    const searcher = function(s: Stack, f: (s: Stack) => boolean) {
        if (!isRoot) {
            if (f(s)) {
                // This node matches, let's not go any deeper
                return
            }
        } else {
            isRoot = false;
        }

        for (let c of s.children) {
            searcher(c, f);
        }
    }
    return searcher
}

