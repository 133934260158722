import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { CreateOrgRequest } from '../models/createOrgRequest'
import { LoadingState } from '../models/loadingState'
import CreateOrgService from '../services/createOrgService'

const initialState = {
    data: null,
    loading: LoadingState.Idle,
    error: null,
}

// First, create the thunk
export const createOrg = createAsyncThunk(
    'createOrg',
    async (createOrgRequest: CreateOrgRequest) => {
        return await CreateOrgService.CreateOrg(createOrgRequest)
    }
)

// Then, handle actions in your reducers:
const createOrgSlice = createSlice({
    name: 'createOrg',
    initialState: initialState,
    reducers: {},

    extraReducers: (builder) => {
        builder
        .addCase(createOrg.pending, (state, _) => {
            if (state.loading === LoadingState.Idle) {
                state.loading = LoadingState.Pending
            }
        })
        .addCase(createOrg.fulfilled, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.data = action.payload.data
            }
        })
        .addCase(createOrg.rejected, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.error = action.error
            }
        })
    },
})

export const createOrgResult = createOrgSlice.reducer
