/************************************************
 * Copyright (C) 2021 Intel Corporation
 ************************************************/
import styled from 'styled-components'
import { FunctionComponent, Dispatch } from 'react'
import { connect } from 'react-redux'
import FooterPanel from '../../layout/footer'
import UserPanel from '../../layout/userProfile'
import NewBenchmarkPanel from '../../components/newBenchmarkPanel/newBenchmarkPanel'
import { NewBenchmarkResponse } from '../../models/newBenchmarkResponse'
import { State } from '../../models/state'
import { submitNewBenchmarkReq } from '../../store/newBenchmark.slice'
import { NewBenchmarkContainerLogic } from '../newBenchmark/newBenchmarkContainer.hook'
import { LoadingState } from '../../models/loadingState'
import { FormLabel } from 'react-bootstrap'
import StringUtils from '../../lib/stringUtils'
import HeaderPanel from '../../layout/header'

const NewBenchmarkPanelContainer = styled.div`
    display: flex;
    height: ${(props) => props.theme.size.mainContainerHeight};
    width: ${(props) => props.theme.size.mainContainerWidth};
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: space-between;
    overflow-x: hidden;
    @media screen and (min-width: 64rem), screen and (min-height: 48rem) {
        /*overflow-x: auto;*/
    }
`

const NewBenchmarkHorizontalContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: space-between;
`
const NewBenchmarkBodyContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: center;
    background-color: ${(props) => props.theme.color.background.defaultGrey};
    padding-bottom: 18rem;
`

const NewBenchmarkContainerReposition = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    margin-left: 2rem;
`

const PageLinkPanelPanelWrapper = styled.div`
    display: flex;
    margin-left: 2rem;
    @media screen and (max-width: 500px) {
        display: none;
    }
`
const MobileHeaderWrapper = styled.div`
    display: flex;
    display: none;
    @media screen and (max-width: 500px) {
        display: block;
    }
`

const NewBenchmarkPanelWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: flex-start;
`
const NotAutorizeContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: left;
`
const NotAutorizeLabel = styled(FormLabel)`
    ${(props) => props.theme.typography.xLarge}
    text-align: left;
    font-weight: 400;
    font-size: 1rem;
    margin-bottom: 50rem;
    color: red;
`

export interface INewBenchmarkContainerProps {
    submitNewBenchmark: typeof submitNewBenchmarkReq
    newBenchmarkResult: NewBenchmarkResponse
    newBenchmarkError?: any
    newBenchmarkLoading: LoadingState
}

const NewBenchmarkContainer: FunctionComponent<INewBenchmarkContainerProps> & {
    defaultProps: Partial<INewBenchmarkContainerProps>
} = ({
    newBenchmarkResult,
    submitNewBenchmark,
    newBenchmarkError,
    newBenchmarkLoading,
}: INewBenchmarkContainerProps) => {
    const { onNewBenchmarkSubmit } = NewBenchmarkContainerLogic({
        submitNewBenchmark,
    } as INewBenchmarkContainerProps)
    let intelEmpStatus = localStorage.getItem('isIntelEmployee')
    let isIntelEmployee = !StringUtils.isNullOrUndefinedOrEmpty(intelEmpStatus)
        ? Boolean(intelEmpStatus)
        : false
    return (
        <NewBenchmarkPanelContainer>
            <HeaderPanel />
            <NewBenchmarkHorizontalContainer>
                <NewBenchmarkBodyContainer>
                    <NewBenchmarkContainerReposition>
                        <UserPanel></UserPanel>
                        {isIntelEmployee ? (
                            <NewBenchmarkPanelWrapper>
                                <NewBenchmarkPanel
                                    onNewBenchmarkSubmit={onNewBenchmarkSubmit}
                                    newBenchmarkResult={newBenchmarkResult}
                                    newBenchmarkError={newBenchmarkError}
                                    newBenchmarkLoading={newBenchmarkLoading}
                                />
                            </NewBenchmarkPanelWrapper>
                        ) : (
                            <NotAutorizeContainer>
                                <NotAutorizeLabel>
                                    Permission denied!
                                </NotAutorizeLabel>
                            </NotAutorizeContainer>
                        )}
                        <NewBenchmarkPanelWrapper></NewBenchmarkPanelWrapper>
                    </NewBenchmarkContainerReposition>
                </NewBenchmarkBodyContainer>
            </NewBenchmarkHorizontalContainer>
            <FooterPanel></FooterPanel>
        </NewBenchmarkPanelContainer>
    )
}

NewBenchmarkContainer.defaultProps = {}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        submitNewBenchmark: (newBenchmarkRequest: any) => {
            dispatch(submitNewBenchmarkReq(newBenchmarkRequest))
        },
    }
}

const mapStateToProps = (state: any) => {
    return {
        newBenchmarkResult: state.newBenchmarkResult.data,
        newBenchmarkError: state.newBenchmarkResult.error,
        newBenchmarkLoading: state.newBenchmarkResult.loading,
    }
}

type StateToPropsType = ReturnType<typeof mapStateToProps>
type DispatchToPropsType = typeof mapDispatchToProps

export default connect<StateToPropsType, DispatchToPropsType>(
    mapStateToProps,
    mapDispatchToProps
)(NewBenchmarkContainer)
