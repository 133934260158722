import {
    ChangeEvent,
    FormEvent,
    FunctionComponent,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react'
import styled from 'styled-components'
import { Button, CloseButton, Form, FormLabel, Overlay } from 'react-bootstrap'

import { useAppDispatch, useAppSelector } from '../../config/hooks'
import { getFiltersValues } from '../../store/catalog.slice'

const FiltersMainContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`

const FilterContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 0.5rem;
`
const FilterContainerDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`
const FilterComponent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-top-style: ridge;
    margin-top: 0.5rem;
    max-height: 30rem;
    overflow: auto;
    overflow-x: hidden;

    /* scroll style-width */
    ::-webkit-scrollbar {
        width: 0.25rem;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 20px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 20px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
`
const CloseButtonDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: right;
    padding-top: 0.6rem;
    padding-left: 0.8rem;
`
const CategoryFilterButton = styled(Button)`
    display: flex;
    padding: 8px 14px;
    align-items: center;
    gap: 4px;
    border-radius: 24px;
    background: var(--theme-light-gray-300, #e2e2e4);
    border-color: var(--theme-light-gray-300, #e2e2e4);
    color: var(--button-light-action-default, #0054ae);
    font-family: IntelOne Display;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 114.286% */
    letter-spacing: -0.14px;
    margin-bottom: 0.3rem;

    &:hover {
        border-radius: 24px;
        background: var(--theme-light-gray-300, #e2e2e4);
        border-color: var(--theme-light-gray-300, #e2e2e4);
        color: var(--button-light-action-default, #0054ae);
        transform: scale(1.03);
        transition: 0.5s;
    }

    &:focus {
        border-radius: 24px;
        background: var(--theme-light-gray-300, #e2e2e4);
        color: var(--button-light-action-default, #0054ae);
    }
    &:click {
        border-radius: 24px;
        background: var(--theme-light-gray-300, #e2e2e4);
        color: var(--button-light-action-default, #0054ae);
    }
`
const PriorityFilterButton = styled(Button)`
    display: flex;
    padding: 8px 14px;
    height: 2.1rem;
    align-items: center;
    border-radius: 24px;
    background: #e2e2e4;
    border: 1px solid #b2b3b9;
    font-family: IntelOne Display;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.14px;
    margin-bottom: 0.3rem;

    &:hover {
        border-radius: 24px;
        background: #e2e2e4;
        border: 1px solid #b2b3b9;
        transform: scale(1.03);
        transition: 0.5s;
    }

    &:focus {
        border-radius: 24px;
        background: #e2e2e4;
        border: 1px solid #b2b3b9;
    }
`

const FilterButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 32px;
    padding: 0px 12px;
    border: 1px solid var(--input-stroke-dark-normal, #8e9099);
    background: none;
    &:hover {
        border: 1px solid var(--input-stroke-dark-normal, #8e9099);
        background: var(--classic-blue-classic-blue-shade-1, #00377c);
    }
    &:focus {
        border: 1px solid var(--input-stroke-dark-normal, #8e9099);
        background: var(--classic-blue-classic-blue-shade-1, #00377c);
    }
`
const SubFilterButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    border: 0.5px solid #8b8e97;
    background: #fff;
    overflow: hidden;
    color: var(--classic-blue-classic-blue, #0054ae);
    text-overflow: ellipsis;
    whitespace: nowrap;
    font-family: 'IntelOne Display';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    height: 2rem;
    padding: 0.8rem 1.5rem 0.65rem 1.5rem;
    &:hover {
        border: 0.5px solid #8b8e97;
        background: #fff;
        color: var(--classic-blue-classic-blue, #0054ae);
    }
    &:focus {
        border: 0.5px solid #8b8e97;
        background: #fff;
        color: var(--classic-blue-classic-blue, #0054ae);
    }
`
const FilterButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`
const ApplyFilters = styled(Button)`
    display: flex;
    justify-content: center;
    vertical-align: middle;
    background-color: #0054ae;
    height: 2rem;
    margin: 0.5rem;
    color: var(--text-dark-default, #fff);
    font-family: 'IntelOne Display';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;

    &:hover {
        background-color: #0054ae;
        transition: 0.5s;
    }
`
const RemoveFilters = styled(ApplyFilters)`
    background-color: #fff;
    border: none;
    color: var(--text-light-default, #2b2c30);
    /* Button/Medium */
    font-family: 'IntelOne Display';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 114.286% */
    height: 2rem;
    padding-top: 0.6rem;
    margin: 0.5rem;
    &:hover {
        background-color: #fff;
        border: none;
        color: var(--text-light-default, #2b2c30);
    }
`

const FilterRenderDiv = styled.div`
    margin-top: 1rem;
    border-right-style: ridge;
    padding-right: 1rem;
    padding-left: 1rem;
`
const FilterHeadingSpan = styled.span`
    padding: 2rem, 0;
    font-size: 0.9rem;
    color: var(--text-light-default, #2b2c30);
    font-family: IntelOne Display;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 187.5% */
    letter-spacing: 0.16px;
`
const FilterCheckbox = styled(Form.Check)`
    color: var(--text-default, #2b2c30);
    /* List/Label/Medium */
    font-family: IntelOne Text;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
`
const EcosystemComponents = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
`
const EcosystemButton = styled(Button)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    color: var(--button-light-action-default, #0054ae);
    font-family: IntelOne Text;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 125% */
    border: none;
    max-width: 100%;
    height: 1.5rem;
    &:hover {
        background-color: transparent;
        border: 0px none transparent;
        color: var(--button-light-action-default, #0054ae);
    }
    &:focus {
        outline: none;
        /*box-shadow: none;*/
        background-color: transparent;
        border: 0px none transparent;
        color: var(--button-light-action-default, #0054ae);
    }
    &:disabled {
        background-color: unset;
    }
`
const EcosystemExpandDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 0.5rem;
    margin-left: 1.5rem;
`
const CategoriesLableDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-top: 0.5rem;
`
const PriorityLableDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: left;
    border-top-style: ridge;
    margin-top: 0.5rem;
`
const CategoriesLabel = styled(FormLabel)`
    color: var(--text-light-default, #2b2c30);
    font-family: IntelOne Display;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 187.5% */
    letter-spacing: 0.16px;
    padding-left: 0.5rem;
    margin-top: 1rem;
`
const CategoryContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: left;
    padding: 0.5rem 0.5rem 1rem 0.5rem;
    width: 100%;
    // background: var(--theme-light-gray-100, #f4f5f5);
    // /* table outlines - light/table-outline-plain/bottom */
    // box-shadow: 0px -1px 0px 0px rgba(43, 44, 48, 0.12) inset;
`
const DataFilterComponent = styled.div`
    display: flex;
    margin: 1rem 0 0 1rem;
`
const NextButton = styled.button`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 2.25rem;
    height: 2.25rem;
    padding: 10px;
    gap: 10px;
    border-radius: 18px;
    //background: var(--black-and-white-white, #fff);
    //border-color: #0054ae;
    cursor: pointer;
    tabindex: 0;
    border: none;
    &:hover {
        color: #00a3fb;
    }
    &:focus {
        color: #00a3fb;
        //border: 1px solid transparent;
        outline: auto;
    }
    margin-left: 0.4rem;
`

export interface IFiltersPanelProps {
    onApplyFilterCallback?: (
        selectedValues: string[],
        selectedFilterCount: number
    ) => void
    onClearAllCallback?: (
        selectedValues: string[],
        selectedFilterCount: number
    ) => void
    isHeaderLoad: boolean
    isSalesHomepageLoad: boolean
    isDevView?: boolean
}

export const SalesFiltersPanel: FunctionComponent<IFiltersPanelProps> & {} = ({
    onApplyFilterCallback,
    onClearAllCallback,
    isHeaderLoad,
    isSalesHomepageLoad,
    isDevView,
}: IFiltersPanelProps) => {
    const dispatch = useAppDispatch()
    const filterTarget = useRef(null)

    const [showFilters, setShowFilters] = useState(false)
    const [showCSP, setShowCSP] = useState(true)
    const [showOEM, setShowOEM] = useState(false)
    const [showISV, setShowISV] = useState(false)
    const [showOSV, setShowOSV] = useState(false)
    const [showSI, setShowSI] = useState(false)
    const [totalFilterCount, setTotalFilterCount] = useState<number>(0)
    const [cspFilters, setCSPFilters] = useState<
        { name: string | null; checkedValue: boolean }[]
    >([])
    const [industryFilters, setIndustryFilters] = useState<
        { name: string | null; checkedValue: boolean }[]
    >([])
    const [comparisonFilters, setComparisonFilters] = useState<
        { name: string | null; checkedValue: boolean }[]
    >([])
    const [environmentFilters, setEnvironmentFilters] = useState<
        { name: string | null; checkedValue: boolean }[]
    >([])
    const [platformFilters, setPlatformFilters] = useState<
        { name: string | null; checkedValue: boolean }[]
    >([])
    const [featureFilters, setFeaureFilters] = useState<
        { name: string | null; checkedValue: boolean }[]
    >([])
    const [OEMFilters, setOEMFilters] = useState<
        { name: string | null; checkedValue: boolean }[]
    >([])
    const [ISVFilters, setISVFilters] = useState<
        { name: string | null; checkedValue: boolean }[]
    >([])
    const [OSVFilters, setOSVFilters] = useState<
        { name: string | null; checkedValue: boolean }[]
    >([])
    const [SIFilters, setSIFilters] = useState<
        { name: string | null; checkedValue: boolean }[]
    >([])
    const categoryfilterList = [
        {
            name: 'Artifical Intelligence',
            value: 'ML/DL/AI',
            img: '/sales_img/ai-simple.svg',
            selectedImg: '/sales_img/ai-simple-white.svg',
            alt: 'cpu',
            isChecked: false,
            checkedIcon: '/sales_img/checked_icon_1x.png',
            displayGroup: 1,
        },
        {
            name: 'Web and Microservices',
            value: 'Web and Microservices',
            img: '/sales_img/webIcon.svg',
            selectedImg: '/sales_img/webIcon-white.svg',
            alt: 'globe',
            isChecked: false,
            checkedIcon: '/sales_img/checked_icon_1x.png',
            displayGroup: 1,
        },
        {
            name: 'Database and Analytics',
            value: 'Data Services',
            img: '/sales_img/database.svg',
            selectedImg: '/sales_img/database-white.svg',
            alt: 'CPU',
            isChecked: false,
            checkedIcon: '/sales_img/checked_icon_1x.png',
            displayGroup: 1,
        },
        {
            name: 'Infrastructure and Storage',
            value: 'Networking & Infra SW & Synthetic',
            img: '/sales_img/refresh-gear.svg',
            selectedImg: '/sales_img/refresh-gear-white.svg',
            alt: 'cloud',
            isChecked: false,
            checkedIcon: '/sales_img/checked_icon_1x.png',
            displayGroup: 1,
        },
        {
            name: 'Network',
            value: 'Network',
            img: '/sales_img/network.svg',
            selectedImg: '/sales_img/network-white.svg',
            alt: 'clipboard play',
            isChecked: false,
            checkedIcon: '/sales_img/checked_icon_1x.png',
            displayGroup: 1,
        },
        {
            name: 'High Performance Computing',
            value: 'HPC',
            img: '/sales_img/rack-mount.svg',
            selectedImg: '/sales_img/rack-mount-white.svg',
            alt: 'rack mount',
            isChecked: false,
            checkedIcon: '/sales_img/checked_icon_1x.png',
            displayGroup: 2,
        },
        {
            name: 'Security',
            value: 'Security',
            img: '/sales_img/lock.svg',
            selectedImg: '/sales_img/lock-white.svg',
            alt: 'clipboard play',
            isChecked: false,
            checkedIcon: '/sales_img/checked_icon_1x.png',
            displayGroup: 2,
        },
        {
            name: 'Sustainability',
            value: 'Sustainability',
            img: '/sales_img/recycle.svg',
            selectedImg: '/sales_img/recycle-white.svg',
            alt: 'graph chart',
            isChecked: false,
            checkedIcon: '/sales_img/checked_icon_1x.png',
            displayGroup: 2,
        },
        {
            name: 'Media',
            value: 'Media',
            img: '/sales_img/media.svg',
            selectedImg: '/sales_img/media-white.svg',
            alt: 'media play',
            isChecked: false,
            checkedIcon: '/sales_img/checked_icon_1x.png',
            displayGroup: 2,
        },
        {
            name: 'Edge',
            value: 'Edge',
            img: '/sales_img/edge.svg',
            selectedImg: '/sales_img/edge-white.svg',
            alt: 'Edge',
            isChecked: false,
            checkedIcon: '/sales_img/checked_icon_1x.png',
            displayGroup: 2,
        },
        {
            name: 'Blockchain',
            value: 'Blockchain',
            img: '/sales_img/blockchain.svg',
            selectedImg: '/sales_img/blockchain-white.svg',
            alt: 'Blockchain',
            isChecked: false,
            checkedIcon: '/sales_img/checked_icon_1x.png',
            displayGroup: 2,
        },
    ]

    const [categoryFilterArray, setCategoryFilterArray] =
        useState<any>(categoryfilterList)
    const [categoryDisplayGroup, setCategoryDisplayGroup] = useState<number>(1)
    const [priorityFilter, setPriorityFilter] = useState<boolean>(false)

    let industryList: { name: string; checkedValue: boolean }[] = []
    let comparisonList: { name: string; checkedValue: boolean }[] = []
    let platformList: { name: string; checkedValue: boolean }[] = []
    let environmentList: { name: string; checkedValue: boolean }[] = []
    let featureList: { name: string; checkedValue: boolean }[] = []
    let cspList: { name: string; checkedValue: boolean }[] = []
    let OEMFilterList: { name: string; checkedValue: boolean }[] = []
    let ISVFilterList: { name: string; checkedValue: boolean }[] = []
    let OSVFilterList: { name: string; checkedValue: boolean }[] = []
    let SIFilterList: { name: string; checkedValue: boolean }[] = []

    useEffect(() => {
        dispatch(
            getFiltersValues(
                '&filter=Industry&filter=Comparison&filter=Platform&filter=Feature&filter=OEM&filter=ISV&filter=OS%20%26%20OSV&filter=SI&filter=CSP&filter=Group%20Type'
            )
        )
    }, [])
    const filtersValues = useAppSelector<any>(
        (state: any) => state.getFiltersValuesResult.data
    )
    useEffect(() => {
        if (filtersValues && filtersValues['CSP'] !== undefined) {
            let totalFilterCount = 0
            cspList =
                filtersValues &&
                filtersValues['CSP'] &&
                filtersValues['CSP'].length > 0
                    ? filtersValues['CSP'].map((item: any) => ({
                          name: item,
                          checkedValue: false,
                      }))
                    : []
            industryList =
                filtersValues &&
                filtersValues['Industry'] &&
                filtersValues['Industry'].length > 0
                    ? filtersValues['Industry'].map((item: any) => ({
                          name: item,
                          checkedValue: false,
                      }))
                    : []
            comparisonList =
                filtersValues &&
                filtersValues['Comparison'] &&
                filtersValues['Comparison'].length > 0
                    ? filtersValues['Comparison'].map((item: any) => ({
                          name: item,
                          checkedValue: false,
                      }))
                    : []
            environmentList =
                filtersValues &&
                filtersValues['Group Type'] &&
                filtersValues['Group Type'].length > 0
                    ? filtersValues['Group Type'].map((item: any) => ({
                          name: item,
                          checkedValue: false,
                      }))
                    : []
            platformList =
                filtersValues &&
                filtersValues['Platform'] &&
                filtersValues['Platform'].length > 0
                    ? filtersValues['Platform'].map((item: any) => ({
                          name: item,
                          checkedValue: false,
                      }))
                    : []
            featureList =
                filtersValues &&
                filtersValues['Feature'] &&
                filtersValues['Feature'].length > 0
                    ? filtersValues['Feature'].map((item: any) => ({
                          name: item,
                          checkedValue: false,
                      }))
                    : []
            OEMFilterList =
                filtersValues &&
                filtersValues['OEM'] &&
                filtersValues['OEM'].length > 0
                    ? filtersValues['OEM'].map((item: any) => ({
                          name: item,
                          checkedValue: false,
                      }))
                    : []
            ISVFilterList =
                filtersValues &&
                filtersValues['ISV'] &&
                filtersValues['ISV'].length > 0
                    ? filtersValues['ISV'].map((item: any) => ({
                          name: item,
                          checkedValue: false,
                      }))
                    : []
            OSVFilterList =
                filtersValues &&
                filtersValues['OS & OSV'] &&
                filtersValues['OS & OSV'].length > 0
                    ? filtersValues['OS & OSV'].map((item: any) => ({
                          name: item,
                          checkedValue: false,
                      }))
                    : []
            SIFilterList =
                filtersValues &&
                filtersValues['SI'] &&
                filtersValues['SI'].length > 0
                    ? filtersValues['SI'].map((item: any) => ({
                          name: item,
                          checkedValue: false,
                      }))
                    : []
            if (industryList) {
                setIndustryFilters(industryList)
                industryList.map((item) => {
                    item.checkedValue
                        ? (totalFilterCount = totalFilterCount + 1)
                        : ''
                })
            }
            if (comparisonList) {
                setComparisonFilters(comparisonList)
                comparisonList.map((item) => {
                    item.checkedValue
                        ? (totalFilterCount = totalFilterCount + 1)
                        : ''
                })
            }
            if (environmentList) {
                setEnvironmentFilters(environmentList)
                environmentList.map((item) => {
                    item.checkedValue
                        ? (totalFilterCount = totalFilterCount + 1)
                        : ''
                })
            }
            if (platformList) {
                setPlatformFilters(platformList)
                platformList.map((item) => {
                    item.checkedValue
                        ? (totalFilterCount = totalFilterCount + 1)
                        : ''
                })
            }
            if (featureList) {
                setFeaureFilters(featureList)
                featureList.map((item) => {
                    item.checkedValue
                        ? (totalFilterCount = totalFilterCount + 1)
                        : ''
                })
            }
            if (cspList) {
                setCSPFilters(cspList)
                cspList.map((item) => {
                    item.checkedValue
                        ? (totalFilterCount = totalFilterCount + 1)
                        : ''
                })
            }
            if (OEMFilterList) {
                setOEMFilters(OEMFilterList)
                OEMFilterList.map((item) => {
                    item.checkedValue
                        ? (totalFilterCount = totalFilterCount + 1)
                        : ''
                })
            }
            if (ISVFilterList) {
                setISVFilters(ISVFilterList)
                ISVFilterList.map((item) => {
                    item.checkedValue
                        ? (totalFilterCount = totalFilterCount + 1)
                        : ''
                })
            }
            if (OSVFilterList) {
                setOSVFilters(OSVFilterList)
                OSVFilterList.map((item) => {
                    item.checkedValue
                        ? (totalFilterCount = totalFilterCount + 1)
                        : ''
                })
            }
            if (SIFilterList) {
                setSIFilters(SIFilterList)
                SIFilterList.map((item) => {
                    item.checkedValue
                        ? (totalFilterCount = totalFilterCount + 1)
                        : ''
                })
            }

            setTotalFilterCount(totalFilterCount)
        }
    }, [filtersValues])

    const onCategoryNextClick = useCallback(
        (event: FormEvent<HTMLElement>) => {
            event.preventDefault()
            if (categoryDisplayGroup === 1) setCategoryDisplayGroup(2)
            else if (categoryDisplayGroup === 2) setCategoryDisplayGroup(1)
        },
        [categoryDisplayGroup]
    )
    const onPriorityFilterClick = useCallback(
        (event: FormEvent<HTMLElement>) => {
            event.preventDefault()

            setPriorityFilter(!priorityFilter)
        },
        [priorityFilter]
    )
    const onCategoryFilterClick = useCallback(
        (
            event: FormEvent<HTMLElement>,
            filterValue: string,
            checked: boolean
        ) => {
            event.preventDefault()
            //set selected category
            const updatedCategoryFilter = categoryFilterArray?.map(
                (item: any) => {
                    if (filterValue === item.value) {
                        item.isChecked = item.isChecked ? false : true
                    }

                    return item
                }
            )
            setCategoryFilterArray(updatedCategoryFilter)
        },
        []
    )

    const onCategoriesFilterChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLFormElement
            if (target) {
                const updatedType = categoryFilterArray?.map((item: any) => {
                    if (target.value === item.name) {
                        item.isChecked = target.checked
                    }

                    return item
                })
                setCategoryFilterArray(updatedType)
            }
        },
        [categoryFilterArray]
    )

    const onIndustryFilterChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLFormElement
            if (target) {
                const updatedType = industryFilters?.map(
                    (item: { name: string | null; checkedValue: boolean }) => {
                        if (target.value === item.name) {
                            item.checkedValue = target.checked
                        }

                        return item
                    }
                )
                setIndustryFilters(updatedType)
            }
        },
        [industryFilters]
    )
    const onComparisonFilterChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLFormElement
            if (target) {
                const updatedType = comparisonFilters?.map(
                    (item: { name: string | null; checkedValue: boolean }) => {
                        if (target.value === item.name) {
                            item.checkedValue = target.checked
                        }

                        return item
                    }
                )
                setComparisonFilters(updatedType)
            }
        },
        [comparisonFilters]
    )
    const onEnvironmentFilterChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLFormElement
            if (target) {
                const updatedType = environmentFilters?.map(
                    (item: { name: string | null; checkedValue: boolean }) => {
                        if (target.value === item.name) {
                            item.checkedValue = target.checked
                        }

                        return item
                    }
                )
                setEnvironmentFilters(updatedType)
            }
        },
        [environmentFilters]
    )

    const onPlatformFilterChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLFormElement
            if (target) {
                const updatedType = platformFilters?.map(
                    (item: { name: string | null; checkedValue: boolean }) => {
                        if (target.value === item.name) {
                            item.checkedValue = target.checked
                        }

                        return item
                    }
                )
                setPlatformFilters(updatedType)
            }
        },
        [platformFilters]
    )
    const onFeatureFilterChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLFormElement
            if (target) {
                const updatedType = featureFilters?.map(
                    (item: { name: string | null; checkedValue: boolean }) => {
                        if (target.value === item.name) {
                            item.checkedValue = target.checked
                        }

                        return item
                    }
                )
                setFeaureFilters(updatedType)
            }
        },
        [featureFilters]
    )
    const onCSPFilterChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLFormElement
            if (target) {
                const updatedType = cspFilters?.map(
                    (item: { name: string | null; checkedValue: boolean }) => {
                        if (target.value === item.name) {
                            item.checkedValue = target.checked
                        }

                        return item
                    }
                )
                setCSPFilters(updatedType)
            }
        },
        [cspFilters]
    )
    const onOEMFilterChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLFormElement
            if (target) {
                const updatedType = OEMFilters?.map(
                    (item: { name: string | null; checkedValue: boolean }) => {
                        if (target.value === item.name) {
                            item.checkedValue = target.checked
                        }

                        return item
                    }
                )
                setOEMFilters(updatedType)
            }
        },
        [OEMFilters]
    )
    const onISVFilterChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLFormElement
            if (target) {
                const updatedType = ISVFilters?.map(
                    (item: { name: string | null; checkedValue: boolean }) => {
                        if (target.value === item.name) {
                            item.checkedValue = target.checked
                        }

                        return item
                    }
                )
                setISVFilters(updatedType)
            }
        },
        [ISVFilters]
    )
    const onOSVFilterChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLFormElement
            if (target) {
                const updatedType = OSVFilters?.map(
                    (item: { name: string | null; checkedValue: boolean }) => {
                        if (target.value === item.name) {
                            item.checkedValue = target.checked
                        }

                        return item
                    }
                )
                setOSVFilters(updatedType)
            }
        },
        [OSVFilters]
    )
    const onSIFilterChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLFormElement
            if (target) {
                const updatedType = SIFilters?.map(
                    (item: { name: string | null; checkedValue: boolean }) => {
                        if (target.value === item.name) {
                            item.checkedValue = target.checked
                        }

                        return item
                    }
                )
                setSIFilters(updatedType)
            }
        },
        [ISVFilters]
    )

    let CategoriesFiltersRender = categoryFilterArray.map(
        (item: any, index: number) => {
            if (item.name !== null) {
                return (
                    <Form.Check
                        type="checkbox"
                        key={item.name + index}
                        id={item.name + index}
                        value={item.name}
                        label={item.name}
                        aria-label={item.name}
                        checked={item.isChecked}
                        onChange={onCategoriesFilterChange}
                    />
                )
            }
        }
    )

    let IndustryFiltersRender = industryFilters.map((item, index) => {
        if (item.name !== null) {
            return (
                <Form.Check
                    type="checkbox"
                    key={item.name + index}
                    id={item.name + index}
                    value={item.name}
                    label={item.name}
                    aria-label={item.name}
                    checked={item.checkedValue}
                    onChange={onIndustryFilterChange}
                />
            )
        }
    })
    let ComparisonFiltersRender = comparisonFilters.map((item, index) => {
        if (item.name !== null) {
            return (
                <FilterCheckbox
                    type="checkbox"
                    key={item.name + index}
                    id={item.name + index}
                    value={item.name}
                    label={item.name}
                    aria-label={item.name}
                    checked={item.checkedValue}
                    onChange={onComparisonFilterChange}
                />
            )
        }
    })
    let EnvironmentFilterRender = environmentFilters.map((item, index) => {
        if (item.name !== null) {
            return (
                <FilterCheckbox
                    type="checkbox"
                    key={item.name + index}
                    id={item.name + index}
                    value={item.name}
                    label={item.name}
                    aria-label={item.name}
                    checked={item.checkedValue}
                    onChange={onEnvironmentFilterChange}
                />
            )
        }
    })
    let PlatformFilterRender = platformFilters.map((item, index) => {
        if (item.name !== null) {
            return (
                <FilterCheckbox
                    type="checkbox"
                    key={item.name + index}
                    id={item.name + index}
                    value={item.name}
                    label={item.name}
                    aria-label={item.name}
                    checked={item.checkedValue}
                    onChange={onPlatformFilterChange}
                />
            )
        }
    })
    let FeatureFilterRender = featureFilters.map((item, index) => {
        if (item.name !== null) {
            return (
                <FilterCheckbox
                    type="checkbox"
                    key={item.name + index}
                    id={item.name + index}
                    value={item.name}
                    label={item.name}
                    aria-label={item.name}
                    checked={item.checkedValue}
                    onChange={onFeatureFilterChange}
                />
            )
        }
    })
    let CSPFiltersRender = cspFilters.map((item, index) => {
        if (item.name !== null) {
            return (
                <FilterCheckbox
                    type="checkbox"
                    key={item.name + index}
                    id={item.name + index}
                    value={item.name}
                    label={item.name}
                    aria-label={item.name}
                    checked={item.checkedValue}
                    onChange={onCSPFilterChange}
                />
            )
        }
    })
    let OEMFiltersRender = OEMFilters.map((item, index) => {
        if (item.name !== null) {
            return (
                <FilterCheckbox
                    type="checkbox"
                    key={item.name + index}
                    id={item.name + index}
                    value={item.name}
                    label={item.name}
                    aria-label={item.name}
                    checked={item.checkedValue}
                    onChange={onOEMFilterChange}
                />
            )
        }
    })
    let ISVFiltersRender = ISVFilters.map((item, index) => {
        if (item.name !== null) {
            return (
                <FilterCheckbox
                    type="checkbox"
                    key={item.name + index}
                    id={item.name + index}
                    value={item.name}
                    label={item.name}
                    aria-label={item.name}
                    checked={item.checkedValue}
                    onChange={onISVFilterChange}
                />
            )
        }
    })
    let OSVFiltersRender = OSVFilters.map((item, index) => {
        if (item.name !== null) {
            return (
                <FilterCheckbox
                    type="checkbox"
                    key={item.name + index}
                    id={item.name + index}
                    value={item.name}
                    label={item.name}
                    aria-label={item.name}
                    checked={item.checkedValue}
                    onChange={onOSVFilterChange}
                />
            )
        }
    })
    let SIFiltersRender = SIFilters.map((item, index) => {
        if (item.name !== null) {
            return (
                <FilterCheckbox
                    type="checkbox"
                    key={item.name + index}
                    id={item.name + index}
                    value={item.name}
                    label={item.name}
                    aria-label={item.name}
                    checked={item.checkedValue}
                    onChange={onSIFilterChange}
                />
            )
        }
    })

    const arrowUpImg = (
        <img
            src="/sales_img/collapse-submenu.png"
            alt="down arrow icon"
            style={{
                display: 'flex',
                justifyContent: 'flex-end',
                height: '5',
                width: '8',
                padding: '0.5rem',
            }}
        />
    )
    const arrowDownImg = (
        <img
            src="/sales_img/expand-submenu.png"
            alt="down arrow icon"
            style={{
                display: 'flex',
                justifyContent: 'flex-end',
                height: '5',
                width: '8',
                padding: '0.5rem',
            }}
        />
    )
    const getSelectedCategory = () => {
        let categoryFilters = ''
        categoryFilterArray?.map((item: any) => {
            if (item.isChecked) {
                categoryFilters = categoryFilters
                    ? categoryFilters + ',' + item.value
                    : item.value
            }
        })
        return categoryFilters
    }

    const onCloseClick = useCallback((event: FormEvent<HTMLElement>) => {
        event.preventDefault()
        setShowFilters(false)
    }, [])

    const onApplyFiltersClick = useCallback(
        (event: FormEvent<HTMLElement>) => {
            event.preventDefault()
            setShowFilters(false)
            let selectedValues: any[] = []

            let totalFilterCount = 0
            let industryTagsValue = ''
            let compTagsValue = ''
            let featureTagsValue = ''
            let environmentTagsValue = ''
            let platformTagsValue = ''
            let oemTagsValue = ''
            let osvTagsValue = ''
            let isvTagsValue = ''
            let siTagsValue = ''
            let cspTagsValue = ''
            let categoryFilters = ''

            // industryFilters.map((item) => {
            //     if (item.name !== null && item.checkedValue) {
            //         industryTagsValue =
            //             industryTagsValue === ''
            //                 ? item.name
            //                 : industryTagsValue +
            //                   ',' +
            //                   encodeURIComponent(item.name)
            //         totalFilterCount = totalFilterCount + 1
            //     }
            // })
            // if (industryTagsValue) {
            //     selectedValues.push({
            //         tagName: 'industry',
            //         tagValue: industryTagsValue,
            //     })
            // }

            comparisonFilters.map((item) => {
                if (item.name !== null && item.checkedValue) {
                    compTagsValue =
                        compTagsValue === ''
                            ? item.name
                            : compTagsValue +
                              ',' +
                              encodeURIComponent(item.name)
                    totalFilterCount = totalFilterCount + 1
                }
            })
            if (compTagsValue) {
                selectedValues.push({
                    tagName: 'comparison type',
                    tagValue: compTagsValue,
                })
            }

            environmentFilters.map((item) => {
                if (item.name !== null && item.checkedValue) {
                    environmentTagsValue =
                        environmentTagsValue === ''
                            ? item.name
                            : environmentTagsValue +
                              ',' +
                              encodeURIComponent(item.name)
                    totalFilterCount = totalFilterCount + 1
                }
            })
            if (environmentTagsValue) {
                selectedValues.push({
                    tagName: 'group-type',
                    tagValue: environmentTagsValue,
                })
            }

            platformFilters.map((item) => {
                if (item.name !== null && item.checkedValue) {
                    platformTagsValue =
                        platformTagsValue === ''
                            ? item.name
                            : platformTagsValue +
                              ',' +
                              encodeURIComponent(item.name)
                    totalFilterCount = totalFilterCount + 1
                }
            })
            if (platformTagsValue) {
                selectedValues.push({
                    tagName: 'platform',
                    tagValue: platformTagsValue,
                })
            }

            featureFilters.map((item) => {
                if (item.name !== null && item.checkedValue) {
                    featureTagsValue =
                        featureTagsValue === ''
                            ? item.name
                            : featureTagsValue +
                              ',' +
                              encodeURIComponent(item.name)
                    totalFilterCount = totalFilterCount + 1
                }
            })
            if (featureTagsValue) {
                selectedValues.push({
                    tagName: 'feature',
                    tagValue: featureTagsValue,
                })
            }

            OEMFilters.map((item) => {
                if (item.name !== null && item.checkedValue) {
                    oemTagsValue =
                        oemTagsValue === ''
                            ? item.name
                            : oemTagsValue + ',' + encodeURIComponent(item.name)
                    totalFilterCount = totalFilterCount + 1
                }
            })
            if (oemTagsValue) {
                selectedValues.push({ tagName: 'oem', tagValue: oemTagsValue })
            }

            OSVFilters.map((item) => {
                if (item.name !== null && item.checkedValue) {
                    osvTagsValue =
                        osvTagsValue === ''
                            ? item.name
                            : osvTagsValue + ',' + encodeURIComponent(item.name)
                    totalFilterCount = totalFilterCount + 1
                }
            })
            if (osvTagsValue) {
                selectedValues.push({
                    tagName: 'osv',
                    tagValue: osvTagsValue,
                })
            }
            ISVFilters.map((item) => {
                if (item.name !== null && item.checkedValue) {
                    isvTagsValue =
                        isvTagsValue === ''
                            ? item.name
                            : isvTagsValue + ',' + encodeURIComponent(item.name)
                    totalFilterCount = totalFilterCount + 1
                }
            })
            if (isvTagsValue)
                selectedValues.push({ tagName: 'isv', tagValue: isvTagsValue })
            SIFilters.map((item) => {
                if (item.name !== null && item.checkedValue) {
                    siTagsValue =
                        siTagsValue === ''
                            ? item.name
                            : siTagsValue + ',' + encodeURIComponent(item.name)
                    totalFilterCount = totalFilterCount + 1
                }
            })
            if (siTagsValue)
                selectedValues.push({ tagName: 'si', tagValue: siTagsValue })
            cspFilters.map((item) => {
                if (item.name !== null && item.checkedValue) {
                    cspTagsValue =
                        cspTagsValue === ''
                            ? item.name
                            : cspTagsValue + ',' + encodeURIComponent(item.name)
                    totalFilterCount = totalFilterCount + 1
                }
            })
            if (cspTagsValue)
                selectedValues.push({
                    tagName: 'group',
                    tagValue: cspTagsValue,
                })

            categoryFilterArray?.map((item: any) => {
                if (item.isChecked) {
                    categoryFilters = categoryFilters
                        ? categoryFilters + ',' + item.value
                        : item.value
                    totalFilterCount = totalFilterCount + 1
                }
            })
            if (categoryFilters) {
                selectedValues.push({
                    tagName: 'workload categories',
                    tagValue: categoryFilters,
                })
            }
            if (priorityFilter) {
                selectedValues.push({
                    tagName: 'selling prioritization',
                    tagValue: 'Prioritized',
                })
                totalFilterCount = totalFilterCount + 1
            }
            setTotalFilterCount(totalFilterCount)
            setShowFilters(false)

            //callback
            if (onApplyFilterCallback !== undefined)
                onApplyFilterCallback(selectedValues, totalFilterCount)
        },
        [
            categoryFilterArray,
            comparisonFilters,
            platformFilters,
            featureFilters,
            OEMFilters,
            OSVFilters,
            ISVFilters,
            SIFilters,
            cspFilters,
            priorityFilter,
            totalFilterCount,
        ]
    )

    const onRemoveFiltersClick = useCallback(
        (event: FormEvent<HTMLElement>) => {
            event.preventDefault()

            industryFilters.map((item) => {
                item.checkedValue = false
            })
            console.log(industryFilters)

            comparisonFilters.map((item) => {
                item.checkedValue = false
            })

            environmentFilters.map((item) => {
                item.checkedValue = false
            })

            platformFilters.map((item) => {
                item.checkedValue = false
            })
            featureFilters.map((item) => {
                item.checkedValue = false
            })
            OEMFilters.map((item) => {
                item.checkedValue = false
            })
            OSVFilters.map((item) => {
                item.checkedValue = false
            })
            ISVFilters.map((item) => {
                item.checkedValue = false
            })
            SIFilters.map((item) => {
                item.checkedValue = false
            })
            cspFilters.map((item) => {
                item.checkedValue = false
            })
            categoryFilterArray?.map((item: any) => {
                item.isChecked = false
            })
            setPriorityFilter(false)
            setTotalFilterCount(0)
            setShowFilters(false)
            if (onClearAllCallback !== undefined) onClearAllCallback([], 0)
        },
        [
            industryFilters,
            comparisonFilters,
            platformFilters,
            featureFilters,
            OEMFilters,
            OSVFilters,
            ISVFilters,
            SIFilters,
            cspFilters,
            priorityFilter,
            totalFilterCount,
        ]
    )
    const onEcoSystemClick = useCallback(
        (event: FormEvent<HTMLElement>, value: string) => {
            event.preventDefault()
            if (value === 'csp') setShowCSP((showcsp) => !showCSP)
            else if (value === 'oem') setShowOEM((showOEM) => !showOEM)
            else if (value === 'isv') setShowISV((showISV) => !showISV)
            else if (value === 'osv') setShowOSV((showOSV) => !showOSV)
            else if (value === 'si') setShowSI((showSI) => !showSI)
        },
        [showCSP]
    )

    const filterComponents = (
        <FilterContainer>
            {isHeaderLoad ? (
                <FilterButton
                    style={
                        totalFilterCount > 0
                            ? { width: '3.5rem' }
                            : { width: '2.5rem' }
                    }
                    type="submit"
                    data-testid="filter-button"
                    onClick={() => {
                        setShowFilters(!showFilters)
                    }}
                    ref={filterTarget}
                >
                    <span
                        style={
                            totalFilterCount > 0 ? { padding: '0 0.2rem' } : {}
                        }
                    >
                        {totalFilterCount > 0
                            ? ' (' + totalFilterCount + ')'
                            : ''}
                    </span>
                    <i className="uil uil-filter"></i>
                </FilterButton>
            ) : (
                <SubFilterButton
                    style={
                        totalFilterCount > 0
                            ? { width: '7.5rem' }
                            : { width: '6rem' }
                    }
                    type="submit"
                    data-testid="sub-filter-button"
                    onClick={() => {
                        setShowFilters(!showFilters)
                    }}
                    ref={filterTarget}
                >
                    filter
                    <i className="uil uil-filter"></i>
                    <span
                        style={
                            totalFilterCount > 0 ? { padding: '0 0.2rem' } : {}
                        }
                    >
                        {totalFilterCount > 0
                            ? ' (' + totalFilterCount + ')'
                            : ''}
                    </span>{' '}
                </SubFilterButton>
            )}
            <Overlay
                target={filterTarget.current}
                show={showFilters}
                placement="bottom-end"
            >
                {({ placement, arrowProps, show: _show, popper, ...props }) => (
                    <div
                        {...props}
                        style={{
                            position: 'absolute',
                            backgroundColor: '#ffff',
                            padding: '1rem 1rem',
                            marginTop: '0.29rem',
                            borderRadius: 5,
                            border: '1px',
                            borderColor: '#6c757d',
                            borderStyle: 'double',
                            ...props.style,
                        }}
                        onMouseLeave={() => setShowFilters(false)}
                    >
                        <FilterButtonContainer>
                            <RemoveFilters onClick={onRemoveFiltersClick}>
                                Clear All
                            </RemoveFilters>
                            <ApplyFilters onClick={onApplyFiltersClick}>
                                Apply Filters
                            </ApplyFilters>
                            <CloseButtonDiv>
                                <CloseButton
                                    className="p-1"
                                    onClick={onCloseClick}
                                />
                            </CloseButtonDiv>
                        </FilterButtonContainer>

                        <PriorityLableDiv>
                            <CategoriesLabel>Priority</CategoriesLabel>
                            <DataFilterComponent>
                                <PriorityFilterButton
                                    onClick={onPriorityFilterClick}
                                    style={
                                        priorityFilter
                                            ? {
                                                  background: '#0054AE',
                                                  color: '#fff',
                                              }
                                            : {
                                                  whiteSpace: 'pre-line',
                                                  color: '#00377c',
                                              }
                                    }
                                >
                                    <img
                                        src="/sales_img/badge.png"
                                        alt="priority"
                                        height="20px"
                                        width="20px"
                                    />
                                    {'Winning Workloads'}
                                </PriorityFilterButton>
                            </DataFilterComponent>
                        </PriorityLableDiv>

                        {/* <CategoriesLableDiv> //disable categories
                            <CategoriesLabel>Categories</CategoriesLabel>
                        </CategoriesLableDiv>
                        <CategoryContainer>
                            {categoryFilterArray.map((filter: any) => (
                                <div>
                                    {filter.displayGroup ===
                                    categoryDisplayGroup ? (
                                        <DataFilterComponent>
                                            <CategoryFilterButton
                                                onClick={(evt: any) =>
                                                    onCategoryFilterClick(
                                                        evt,
                                                        filter.value,
                                                        filter.isChecked
                                                    )
                                                }
                                                style={
                                                    filter.isChecked
                                                        ? {
                                                              background:
                                                                  '#0054AE',
                                                              color: '#fff',
                                                          }
                                                        : {
                                                              whiteSpace:
                                                                  'pre-line',
                                                              color: '#00377c',
                                                          }
                                                }
                                            >
                                                <img
                                                    src={
                                                        filter.isChecked
                                                            ? filter.selectedImg
                                                            : filter.img
                                                    }
                                                    alt={filter.alt}
                                                />
                                                {filter.name}
                                            </CategoryFilterButton>
                                        </DataFilterComponent>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            ))}
                            <NextButton
                                type="button"
                                id="category_next"
                                onClick={(evt: any) => onCategoryNextClick(evt)}
                                title="Next category"
                            >
                                {categoryDisplayGroup === 1 ? (
                                    <img src="/sales_img/chevron-right.svg" />
                                ) : (
                                    <img src="/sales_img/chevron-left.svg" />
                                )}
                            </NextButton>
                        </CategoryContainer> */}

                        <FilterComponent>
                            <FilterRenderDiv>
                                {/* <FilterHeadingSpan>Industry</FilterHeadingSpan>
                                {[IndustryFiltersRender]} */}
                                <FilterHeadingSpan>
                                    Categories
                                </FilterHeadingSpan>
                                {CategoriesFiltersRender}
                            </FilterRenderDiv>
                            <FilterRenderDiv>
                                <div
                                    style={{
                                        borderBottomStyle: 'ridge',
                                        paddingBottom: '0.5rem',
                                    }}
                                >
                                    <FilterHeadingSpan>
                                        Comparison
                                    </FilterHeadingSpan>

                                    {[ComparisonFiltersRender]}
                                </div>
                                <div
                                    style={{
                                        borderBottomStyle: 'ridge',
                                        paddingBottom: '0.5rem',
                                    }}
                                >
                                    <FilterHeadingSpan>
                                        Environment
                                    </FilterHeadingSpan>

                                    {[EnvironmentFilterRender]}
                                </div>

                                <FilterHeadingSpan>Platform</FilterHeadingSpan>
                                {[PlatformFilterRender]}
                            </FilterRenderDiv>
                            <FilterRenderDiv>
                                <FilterHeadingSpan>Feature</FilterHeadingSpan>
                                {[FeatureFilterRender]}
                            </FilterRenderDiv>
                            <FilterRenderDiv
                                style={{
                                    borderRightStyle: 'none',
                                    paddingRight: '0.5rem',
                                }}
                            >
                                <FilterHeadingSpan>
                                    Cloud Availability
                                </FilterHeadingSpan>
                                {/*Hide eco system temporary*/}
                                {/* <EcosystemComponents>
                                    <EcosystemButton
                                        id="oem"
                                        type="button"
                                        title={'Select OEM'}
                                        value={showOEM}
                                        onClick={(evt: any) =>
                                            onEcoSystemClick(evt, 'oem')
                                        }
                                    >
                                        OEM
                                        {showOEM ? arrowUpImg : arrowDownImg}
                                    </EcosystemButton>
                                    {showOEM ? (
                                        <EcosystemExpandDiv>
                                            {[OEMFiltersRender]}
                                        </EcosystemExpandDiv>
                                    ) : (
                                        ''
                                    )}
                                </EcosystemComponents>
                                <EcosystemComponents>
                                    <EcosystemButton
                                        id="osv"
                                        type="button"
                                        title={'Select OS & OSV'}
                                        value={showOSV}
                                        onClick={(evt: any) =>
                                            onEcoSystemClick(evt, 'osv')
                                        }
                                    >
                                        OS & OSV
                                        {showOSV ? arrowUpImg : arrowDownImg}
                                    </EcosystemButton>
                                    {showOSV ? (
                                        <EcosystemExpandDiv>
                                            {[OSVFiltersRender]}
                                        </EcosystemExpandDiv>
                                    ) : (
                                        ''
                                    )}
                                </EcosystemComponents>
                                <EcosystemComponents>
                                    <EcosystemButton
                                        id="isv"
                                        type="button"
                                        title={'Select ISV'}
                                        value={showISV}
                                        onClick={(evt: any) =>
                                            onEcoSystemClick(evt, 'isv')
                                        }
                                    >
                                        ISV
                                        {showISV ? arrowUpImg : arrowDownImg}
                                    </EcosystemButton>
                                    {showISV ? (
                                        <EcosystemExpandDiv>
                                            {[ISVFiltersRender]}
                                        </EcosystemExpandDiv>
                                    ) : (
                                        ''
                                    )}
                                </EcosystemComponents>
                                <EcosystemComponents>
                                    <EcosystemButton
                                        id="si"
                                        type="button"
                                        title={'Select SI'}
                                        value={showSI}
                                        onClick={(evt: any) =>
                                            onEcoSystemClick(evt, 'si')
                                        }
                                    >
                                        SI
                                        {showSI ? arrowUpImg : arrowDownImg}
                                    </EcosystemButton>
                                    {showSI ? (
                                        <EcosystemExpandDiv>
                                            {[SIFiltersRender]}
                                        </EcosystemExpandDiv>
                                    ) : (
                                        ''
                                    )}
                                </EcosystemComponents> */}
                                <EcosystemComponents>
                                    <EcosystemButton
                                        id="csp"
                                        type="button"
                                        title={'Select CSP'}
                                        value={showCSP}
                                        onClick={(evt: any) =>
                                            onEcoSystemClick(evt, 'csp')
                                        }
                                    >
                                        Cloud
                                        {showCSP ? arrowUpImg : arrowDownImg}
                                    </EcosystemButton>
                                    {showCSP ? (
                                        <EcosystemExpandDiv>
                                            {[CSPFiltersRender]}
                                        </EcosystemExpandDiv>
                                    ) : (
                                        ''
                                    )}
                                </EcosystemComponents>
                            </FilterRenderDiv>
                        </FilterComponent>
                    </div>
                )}
            </Overlay>
        </FilterContainer>
    )

    return (
        <FiltersMainContainer>
            <FilterContainerDiv>{filterComponents}</FilterContainerDiv>
        </FiltersMainContainer>
    )
}

export default SalesFiltersPanel
