export enum UserRoles {
    OrgAdmin = 'org-admin',
    OhOrgReader = 'oh:org-reader',
    OhOrgWriter = 'oh:org-writer',
    CiOrgReader = 'ci:org-reader',
    CiOrgWriter = 'ci:org-writer',
    // PhOrgReader = 'ph:org-reader',
    // PhOrgWriter = 'ph:org-writer',
}

export enum UserOtherMetaRoles {
    FlameGraphViewer = 'flamegraph-viewer',
    JiraViewer = 'jira-viewer',
    Analyst = 'analyst',
    DataController = 'data-controller',
    Discounter = "discounter"
}

export enum UserSalesPermissions {
    NDA = 'NDA',
    CNDA = 'CNDA',
    InternalAll = 'Internal All',
    Public = 'Public',
}
