import { useEffect, useState } from 'react'

import { IBenchmarkContainerProps } from './benchmarkContainer'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../config/hooks'
import { getAuthPermissions } from '../../store/authPermissions.slice'

export function BenchmarkContainerLogic({}: IBenchmarkContainerProps) {
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(getAuthPermissions())
    }, [])

    return {}
}
