import { useEffect } from 'react'
import { IAllRequestContainerProps } from './allRequestContainer'
import { useAppDispatch } from '../../config/hooks'

export function AllRequestContainerLogic({
    getAllUploadRequest,
    appName,
    isIntelEmployee,
}: IAllRequestContainerProps) {
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (isIntelEmployee) {
            dispatch(getAllUploadRequest())
        }
    }, [])

    return {
        appName,
    }
}
