import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { LoadingState } from '../models/loadingState'
import PerformanceSamplesService from '../services/performanceSamplesService'

const initialState = {
    data: null,
    loading: LoadingState.Idle,
    error: null,
}

// First, create the thunk
export const getPerformanceSamples = createAsyncThunk(
    'getPerformanceSamples',
    async (payload: any) => {
        return await PerformanceSamplesService.getPerformanceSamples(payload)
    }
)

// Then, handle actions in your reducers:
const performanceSamplesSlice = createSlice({
    name: 'performanceSamples',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(getPerformanceSamples.pending, (state, _) => {
            if (state.loading === LoadingState.Idle) {
                state.loading = LoadingState.Pending
            }
        })
        .addCase(getPerformanceSamples.fulfilled, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.data = action.payload.data
                state.error = null
            }
        })
        .addCase(getPerformanceSamples.rejected, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.error = action.error
            }
        })
    },
})

export const performanceSamplesResult = performanceSamplesSlice.reducer
