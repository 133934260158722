/************************************************
 * Copyright (C) 2021 Intel Corporation
 ************************************************/
import styled from 'styled-components'
import { FunctionComponent, Dispatch, useCallback, FormEvent } from 'react'
import { connect } from 'react-redux'
import FooterPanel from '../../layout/footer'
import { Button, Form, FormLabel } from 'react-bootstrap'
import UserPanel from '../../layout/userProfile'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { useNavigate } from 'react-router-dom'
import HeaderPanel from '../../layout/header'

const TryNowPagePanelContainer = styled.div`
    display: flex;
    height: ${(props) => props.theme.size.mainContainerHeight};
    width: ${(props) => props.theme.size.mainContainerWidth};
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: space-between;
    @media screen and (min-width: 1200px) {
        overflow-x: hidden;
    }
`

const TryNowPageHorizontalContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: space-between;
`
const TryNowPageBodyContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: center;
    background-color: ${(props) => props.theme.color.background.defaultGrey};
    padding-bottom: 25rem;
`

const TryNowPageBodyContainerReposition = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    margin-left: 2rem;
`

const PageLinkPanelPanelWrapper = styled.div`
    display: flex;
    margin-left: 2rem;
    @media screen and (max-width: 500px) {
        display: none;
    }
`
const MobileHeaderWrapper = styled.div`
    display: flex;
    display: none;
    @media screen and (max-width: 500px) {
        display: block;
    }
`

const TryNowPagePanelWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: flex-start;
    margin-top: 2rem;
`
const PageTitleTextLabel = styled(FormLabel)`
    ${(props) => props.theme.typography.xxxLarge}
    text-align: left;
    margin-bottom: 3rem;
    font-weight: 500;
    font-size: 2.25rem;
`

const CommandContainer = styled.div`
    display: flex;
    background-color: ${(props) => props.theme.color.background.label.title};
    width: 80%;
`
const InstructionTextLabel = styled(FormLabel)`
    display: flex;
    ${(props) => props.theme.typography.medium}
    text-align: left;
`
const AMITextLabel = styled(FormLabel)`
    display: flex;
    ${(props) => props.theme.typography.medium}
    text-align: left;
    font-weight: 500;
`
const CommandValue = styled(FormLabel)`
    ${(props) => props.theme.typography.medium}
    display: flex;   
    text-align: left;
    justify-content: left;
    background:  ${(props) => props.theme.color.background.label.title}
    white-space: pre-wrap;
    margin-left: 1rem;
    width:100%;
`
const CopyButton = styled(Button)`
    display: flex;
    justify-content: center;
    font-weight: 400;
    font-size: 1rem;
    background-color: #008000;
    vertical-align: middle;
    border-radius: ${(props) => props.theme.shape.button.borderRadius};
    font-family: 'IntelOne Display';
    &:hover {
        background-color: ${(props) =>
            props.theme.color.button.background.hover.primary};
        transition: 0.5s;
    }
`
const BackButton = styled(CopyButton)`
    display: flex;
    justify-content: center;
    font-weight: 400;
    font-size: 1rem;
    width: 5%;
    background-color: ${(props) =>
        props.theme.color.button.background.default.primary};
    border-radius: ${(props) => props.theme.shape.button.borderRadius};
    font-family: 'IntelOne Display';
    &:hover {
        background-color: ${(props) =>
            props.theme.color.button.background.hover.primary};
        transition: 0.5s;
    }
`

export interface ITryNowPageContainerProps {}

const TryNowPageContainer: FunctionComponent<ITryNowPageContainerProps> & {
    defaultProps: Partial<ITryNowPageContainerProps>
} = ({}: ITryNowPageContainerProps) => {
    let navigate = useNavigate()

    const path = window.location.href
    let params = path.split('?')
    let paramkey = ''
    let AMIValue = ''

    if (params.length >= 1 && params[1] !== '') {
        let pair = params[1].split('=')
        if (pair.length >= 1) {
            paramkey = pair[0]
            AMIValue = pair[1]
        }
    }

    let InstructionCommand =
        'ssh -A -p 22 bitnami@3.141.34.136 -2 -o UserKnownHostsFile=/dev/null -o StrictHostKeyChecking=no -o PreferredAuthentications=publickey -o GSSAPIAuthentication=no -i keyfile.txt'
    const onSCopyLinkClick = useCallback((event: FormEvent<HTMLElement>) => {
        event.preventDefault()

        if (InstructionCommand !== '') {
            navigator.clipboard.writeText(InstructionCommand)
            alert('Command copied: ' + InstructionCommand)
        }
    }, [])
    return (
        <TryNowPagePanelContainer>
            <HeaderPanel />
            <TryNowPageHorizontalContainer>
                <TryNowPageBodyContainer>
                    <TryNowPageBodyContainerReposition>
                        <UserPanel></UserPanel>
                        <Breadcrumb>
                            <Breadcrumb.Item href="/catalog">
                                Catalog
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>Try now</Breadcrumb.Item>
                        </Breadcrumb>
                        <PageTitleTextLabel>
                            Try optimized Workload Image
                        </PageTitleTextLabel>
                        <InstructionTextLabel>
                            {'AMI Value:   '}
                            <AMITextLabel>{'  ' + AMIValue}</AMITextLabel>
                        </InstructionTextLabel>
                        <TryNowPagePanelWrapper>
                            <InstructionTextLabel>
                                {
                                    'To connect to instance, use the following command'
                                }
                            </InstructionTextLabel>
                            <CommandContainer>
                                <CommandValue>
                                    {InstructionCommand}
                                </CommandValue>
                                <CopyButton onClick={onSCopyLinkClick}>
                                    Copy
                                </CopyButton>
                            </CommandContainer>{' '}
                            <br />
                            <a href="/keyfile" download="keyfile.txt">
                                Download keyfile
                            </a>
                            <br />
                            <BackButton onClick={() => navigate(`/catalog`)}>
                                Back
                            </BackButton>
                        </TryNowPagePanelWrapper>
                    </TryNowPageBodyContainerReposition>
                </TryNowPageBodyContainer>
            </TryNowPageHorizontalContainer>
            <FooterPanel></FooterPanel>
        </TryNowPagePanelContainer>
    )
}

TryNowPageContainer.defaultProps = {}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {}
}

const mapStateToProps = (state: any) => {
    return {}
}

type StateToPropsType = ReturnType<typeof mapStateToProps>
type DispatchToPropsType = typeof mapDispatchToProps

export default connect<StateToPropsType, DispatchToPropsType>(
    mapStateToProps,
    mapDispatchToProps
)(TryNowPageContainer)
