/************************************************
 * Copyright (C) 2024 Intel Corporation
 ************************************************/
import styled from 'styled-components'
import { FunctionComponent, Dispatch, useEffect } from 'react'
import { connect } from 'react-redux'
import PageLinkPanel from '../../layout/pageLinks'
import { getAuthPermissions } from '../../store/authPermissions.slice'
import { AuthPermissions } from '../../models/authPermissions'
import { LoadingState } from '../../models/loadingState'
import HeaderPanel from '../../layout/header'
import ChatbotPanel from '../../components/chatbotPanel/chatbotPanel'
import UserPanel from '../../layout/userProfile'
import { getAnswerFromChatbot } from '../../store/chatbot.slice'
import { ChatbotRequest } from '../../models/chatbotRequest'

const ChatbotPanelContainer = styled.div`
    display: flex;
    height: ${(props) => props.theme.size.mainContainerHeight};
    width: ${(props) => props.theme.size.mainContainerWidth};
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: space-between;
    @media screen and (max-width: 500px) {
        width: 100%;
        height: 100%;
    }
    overflow: hidden;
`

const ChatbotHorizontalContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: space-between;
`
const ChatbotBodyContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: center;
    background-color: #fff;
    width: 100%;
`

const ChatbotBodyContainerReposition = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
`
const PageLinkPanelPanelWrapper = styled.div`
    display: flex;
    margin-left: 2rem;
    display: none;
`

export interface IChatbotContainerProps {
    getAuthPermissions: typeof getAuthPermissions
    authPermissionsResult: AuthPermissions
    authPermissionsLoading: LoadingState
    authPermissionsError: any
    getAnswerFromChatbot: typeof getAnswerFromChatbot
    chatbotAnswer: any
    chatbotLoading: LoadingState
    chatbotError: any
}

const ChatbotContainer: FunctionComponent<IChatbotContainerProps> & {
    defaultProps: Partial<IChatbotContainerProps>
} = ({
    getAuthPermissions,
    authPermissionsResult,
    authPermissionsLoading,
    authPermissionsError,
    getAnswerFromChatbot,
    chatbotAnswer,
    chatbotLoading,
    chatbotError,
}: IChatbotContainerProps) => {
    return (
        <ChatbotPanelContainer>
            <HeaderPanel />
            <ChatbotHorizontalContainer>
                <PageLinkPanelPanelWrapper>
                    <PageLinkPanel
                        getAuthPermissions={getAuthPermissions}
                        authPermissionsResult={authPermissionsResult}
                        authPermissionsLoading={authPermissionsLoading}
                        authPermissionsError={authPermissionsError}
                    />
                </PageLinkPanelPanelWrapper>
                <ChatbotBodyContainer>
                    <UserPanel></UserPanel>
                    <ChatbotBodyContainerReposition>
                        <ChatbotPanel
                            getAnswerFromChatbot={getAnswerFromChatbot}
                            chatbotAnswer={chatbotAnswer}
                            chatbotLoading={chatbotLoading}
                            chatbotError={chatbotError}
                        ></ChatbotPanel>
                    </ChatbotBodyContainerReposition>
                </ChatbotBodyContainer>
            </ChatbotHorizontalContainer>
            {/* <FooterPanel></FooterPanel> */}
        </ChatbotPanelContainer>
    )
}

ChatbotContainer.defaultProps = {}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        getAuthPermissions: () => dispatch(getAuthPermissions()),
        getAnswerFromChatbot: (question: ChatbotRequest) =>
            dispatch(getAnswerFromChatbot(question)),
    }
}

const mapStateToProps = (state: any) => {
    return {
        authPermissionsResult: state.authPermissionsResult.data,
        authPermissionsLoading: state.authPermissionsResult.loading,
        authPermissionsError: state.authPermissionsResult.error,
        chatbotAnswer: state.chatbotAnswer.data,
        chatbotLoading: state.chatbotAnswer.loading,
        chatbotError: state.chatbotAnswer.error,
    }
}

type StateToPropsType = ReturnType<typeof mapStateToProps>
type DispatchToPropsType = typeof mapDispatchToProps

export default connect<StateToPropsType, DispatchToPropsType>(
    mapStateToProps,
    mapDispatchToProps
)(ChatbotContainer)
