import { Chart } from 'react-google-charts'
import ChartLoader from './ChartLoader'
import ChartWrapper from './ChartWrapper'
import { LoadingState } from '../../../../models/loadingState'
import chartColors from './chartColors'
import { FC } from 'react'
import { Box } from '@mui/material'

const columnChartBoxSx = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
}

const chartOptions = {
    chartArea: {
        top: 16,
        bottom: 32,
        left: 64,
        right: 48,
    },
    fontName: 'IntelOne Text',
    fontSize: 12,
    vAxis: {
        format: '0',
        minValue: 0,
    },
    legend: {
        position: 'none',
    },
    colors: chartColors,
}

export type ColumnChartData = [
    [string, string, { role: string }],
    ...(string | number)[][]
]

interface ColumnChartProps {
    chartData: ColumnChartData
    dataLoadingState: LoadingState | LoadingState[]
    height?: number
}

const ColumnChart: FC<ColumnChartProps> = ({
    chartData,
    dataLoadingState,
    height = 350,
}) => {
    const isDataLoading = Array.isArray(dataLoadingState)
        ? dataLoadingState.some((state) => state === LoadingState.Pending)
        : dataLoadingState === LoadingState.Pending

    return (
        <Box sx={{ ...columnChartBoxSx, height: `${height}px` }}>
            <ChartWrapper
                isDataLoading={isDataLoading}
                noDataCondition={chartData.length === 1}
            >
                <Chart
                    chartType="ColumnChart"
                    data={chartData}
                    loader={<ChartLoader message="Loading chart..." />}
                    width="100%"
                    height={height}
                    chartLanguage="en-US"
                    options={chartOptions}
                />
            </ChartWrapper>
        </Box>
    )
}

export default ColumnChart
