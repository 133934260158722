import {
    useState,
    useCallback,
    FormEvent,
    ChangeEvent,
} from 'react'
import { ICreateDiscountPanelProps } from './createDiscountPanel'

export function CreateDiscountContainerLogic({
    onCreateDiscountSubmit,
    formRef,
}: ICreateDiscountPanelProps) {
    const [duration, setDuration] = useState(0)
    const [validated, setValidated] = useState(false)
    const [product, setProduct] = useState<any>([])
    const [total, setTotal] = useState(1)


    const onClearAll = useCallback((event: FormEvent<HTMLElement>) => {
        event.preventDefault()
        setValidated(false)
        setDuration(0)
        window.location.reload()
    }, [])

    const onDurationChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLTextAreaElement
            if (target.value) {
                setDuration(Number(target.value))
            } 
        },
        [setDuration]
    )
   
  
    const onTotalChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLTextAreaElement
            if (target.value && Number(target.value) > 0) {
                setTotal(Number(target.value))
            } 
        },
        [setTotal]
    )
   


    return {
        validated,
        setValidated,
      
        onClearAll,

        product,
        setProduct,
        duration,
        setDuration,
        onDurationChange,
        total,
        setTotal,
        onTotalChange
    }
}
