/************************************************
 * Copyright (C) 2021 Intel Corporation
 ************************************************/
import styled from 'styled-components'
import { FunctionComponent, Dispatch, useEffect } from 'react'
import { connect } from 'react-redux'
import FooterPanel from '../../layout/footer'
import {
    getWorkloads,
    getWorkloadBenchmarks,
    getWorkloadOptimizations,
    getWorkloadPartnerSolutions,
    getWorkloadCustomerCaseStudies,
    getWorkloadPartnerSolutionsResult,
    getWorkloadCustomerCaseStudiesResult,
} from '../../store/benchmark.slice'
import { getTagsValues } from '../../store/catalog.slice'
import { LoadingState } from '../../models/loadingState'
import { BenchmarkContainerLogic } from './benchmarkContainer.hook'
import { RecipeTagsValuesResponse } from '../../models/recipeTagsValuesResponse'
import { useParams } from 'react-router-dom'
import { getAuthPermissions } from '../../store/authPermissions.slice'
import { AuthPermissions } from '../../models/authPermissions'
import { BenchmarkSearchRequest } from '../../models/benchmarkSearchRequest'
import {
    BenchmarkSearchResponse,
    WorkloadSearchResponse,
} from '../../models/benchmarkSearchResponse'
import BenchmarkDetails from '../../components/benchmarkPanel/benchmarkDetails'
import { FormLabel, Spinner } from 'react-bootstrap'
import OptimizationDetailsContainer from '../optimizationDetailsContainer/optimizationDetailsContainer'
import OptimizationDetails from '../../components/benchmarkPanel/optimizationDetails'
import SolutionDetails from '../../components/benchmarkPanel/solutionDetails'
import CustomerCaseStudyDetails from '../../components/benchmarkPanel/customerCaseStudyDetails'

const BenchmarkPanelContainer = styled.div`
    display: flex;
    height: ${(props) => props.theme.size.mainContainerHeight};
    width: ${(props) => props.theme.size.mainContainerWidth};

    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: space-between;
    @media screen and (min-width: 80rem) {
        overflow-x: hidden;
    }
    @media screen and (max-width: 500px) {
        width: 100%;
        height: 100%;
    }
`
const BenchmarkContainerWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`

const BenchmarkHorizontalContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: space-between;
`
const BenchmarkBodyContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: center;
    background-color: ${(props) => props.theme.color.background.defaultGrey};
    width: 100%;
`

const BenchmarkBodyContainerReposition = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
`
const NotAutorizeContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: left;
    margin: 2rem;
`
const NotAutorizeLabel = styled(FormLabel)`
    ${(props) => props.theme.typography.xLarge}
    text-align: left;
    font-weight: 400;
    font-size: 1rem;
    margin-bottom: 50rem;
    color: red;
`
const SpinnerDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5rem;
    width: 100%;
`

export interface IBenchmarkContainerProps {
    getWorkloadOptimizations: typeof getWorkloadOptimizations
    getWorkloadOptimizationsLoading: LoadingState
    getWorkloadOptimizationsError: any
    getWorkloadOptimizationsResult: any
    getWorkloadBenchmarks: typeof getWorkloadBenchmarks
    getbenchmarksLoading: LoadingState
    getBenchmarksError: any
    getBenchmarksResult: BenchmarkSearchResponse
    getTagsValues: typeof getTagsValues
    getTagsValuesResult: RecipeTagsValuesResponse
    getAuthPermissions: typeof getAuthPermissions
    authPermissionsResult: AuthPermissions
    authPermissionsLoading: LoadingState
    authPermissionsError: any
    getWorkloadPartnerSolutions: typeof getWorkloadPartnerSolutions
    getWorkloadPartnerSolutionsResult: any
    getWorkloadPartnerSolutionsLoading: LoadingState
    getWorkloadPartnerSolutionsError: any
    getWorkloadCustomerCaseStudies: typeof getWorkloadCustomerCaseStudies
    getWorkloadCustomerCaseStudyResult: any
    getWorkloadCustomerCaseStudyLoading: LoadingState
    getWorkloadCustomerCaseStudyError: any
}

const BenchmarkContainer: FunctionComponent<IBenchmarkContainerProps> & {
    defaultProps: Partial<IBenchmarkContainerProps>
} = ({
    getWorkloadOptimizations,
    getWorkloadOptimizationsLoading,
    getWorkloadOptimizationsError,
    getWorkloadOptimizationsResult,
    getWorkloadBenchmarks,
    getBenchmarksResult,
    getbenchmarksLoading,
    getBenchmarksError,
    getTagsValues,
    getTagsValuesResult,
    authPermissionsResult,
    authPermissionsLoading,
    getWorkloadCustomerCaseStudyResult,
    getWorkloadCustomerCaseStudyLoading,
    getWorkloadCustomerCaseStudyError,
}: IBenchmarkContainerProps) => {
    const {} = BenchmarkContainerLogic({
        getTagsValues,
    } as IBenchmarkContainerProps)

    let { workloadId } = useParams()
    const queryParameters = new URLSearchParams(location.search)
    const assetType = queryParameters.get('assetType')

    let isIntelEmployee = false
    if (authPermissionsResult && authPermissionsResult?.isIntelEmployee) {
        isIntelEmployee = authPermissionsResult?.isIntelEmployee
    }

    return (
        <BenchmarkPanelContainer>
            {authPermissionsLoading !== LoadingState.Pending ? (
                <BenchmarkContainerWrapper>
                    {/* {isIntelEmployee ? ( */}
                    <BenchmarkHorizontalContainer>
                        {workloadId && assetType === 'Benchmark' ? (
                            <BenchmarkBodyContainer
                                style={{ background: '#fff', padding: 0 }}
                            >
                                <BenchmarkBodyContainerReposition>
                                    <BenchmarkDetails
                                        workloadId={workloadId}
                                        getWorkloadBenchmarks={
                                            getWorkloadBenchmarks
                                        }
                                        getBenchmarksResult={
                                            getBenchmarksResult
                                        }
                                        getbenchmarksLoading={
                                            getbenchmarksLoading
                                        }
                                        getBenchmarksError={getBenchmarksError}
                                        authPermissionsResult={
                                            authPermissionsResult
                                        }
                                    />
                                </BenchmarkBodyContainerReposition>
                            </BenchmarkBodyContainer>
                        ) : workloadId && assetType === 'SW Optimization' ? (
                            <BenchmarkBodyContainer
                                style={{ background: '#fff', padding: 0 }}
                            >
                                <BenchmarkBodyContainerReposition>
                                    <OptimizationDetails
                                        workloadId={workloadId}
                                        getWorkloadOptimizations={
                                            getWorkloadBenchmarks
                                        }
                                        getWorkloadOptimizationsResult={
                                            getWorkloadOptimizationsResult
                                        }
                                        getWorkloadOptimizationsLoading={
                                            getWorkloadOptimizationsLoading
                                        }
                                        getWorkloadOptimizationsError={
                                            getWorkloadOptimizationsError
                                        }
                                        authPermissionsResult={
                                            authPermissionsResult
                                        }
                                    />
                                </BenchmarkBodyContainerReposition>
                            </BenchmarkBodyContainer>
                        ) : workloadId && assetType === 'Partner Solution' ? (
                            <BenchmarkBodyContainer
                                style={{ background: '#fff', padding: 0 }}
                            >
                                <BenchmarkBodyContainerReposition>
                                    <SolutionDetails
                                        workloadId={workloadId}
                                        getWorkloadPartnerSolutions={
                                            getWorkloadPartnerSolutions
                                        }
                                        getWorkloadPartnerSolutionsResult={
                                            getWorkloadPartnerSolutionsResult
                                        }
                                        getWorkloadPartnerSolutionsLoading={
                                            getWorkloadOptimizationsLoading
                                        }
                                        getWorkloadPartnerSolutionsError={
                                            getWorkloadOptimizationsError
                                        }
                                        authPermissionsResult={
                                            authPermissionsResult
                                        }
                                    />
                                </BenchmarkBodyContainerReposition>
                            </BenchmarkBodyContainer>
                        ) : workloadId &&
                          assetType === 'Customer Case Study' ? (
                            <BenchmarkBodyContainer
                                style={{ background: '#fff', padding: 0 }}
                            >
                                <BenchmarkBodyContainerReposition>
                                    <CustomerCaseStudyDetails
                                        workloadId={workloadId}
                                        getWorkloadCustomerCaseStudies={
                                            getWorkloadCustomerCaseStudies
                                        }
                                        getWorkloadCustomerCaseStudyResult={
                                            getWorkloadCustomerCaseStudyResult
                                        }
                                        getWorkloadCustomerCaseStudyLoading={
                                            getWorkloadCustomerCaseStudyLoading
                                        }
                                        getWorkloadCustomerCaseStudyError={
                                            getWorkloadCustomerCaseStudyError
                                        }
                                        authPermissionsResult={
                                            authPermissionsResult
                                        }
                                    />
                                </BenchmarkBodyContainerReposition>
                            </BenchmarkBodyContainer>
                        ) : (
                            'No workload ID found'
                        )}
                    </BenchmarkHorizontalContainer>
                    {/* ) : (
                        <NotAutorizeContainer>
                            <NotAutorizeLabel>
                                Permission denied!
                            </NotAutorizeLabel>
                        </NotAutorizeContainer>
                    )} */}
                </BenchmarkContainerWrapper>
            ) : (
                <SpinnerDiv>
                    <Spinner animation="border" variant="dark">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </SpinnerDiv>
            )}
            <FooterPanel></FooterPanel>
        </BenchmarkPanelContainer>
    )
}

BenchmarkContainer.defaultProps = {}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        getWorkloadOptimizations: (params: any) =>
            dispatch(getWorkloadOptimizations(params)),
        getWorkloadBenchmarks: (id: string) =>
            dispatch(getWorkloadBenchmarks(id)),
        getAuthPermissions: () => dispatch(getAuthPermissions()),
        getWorkloadPartnerSolutions: (params: any) =>
            dispatch(getWorkloadPartnerSolutions(params)),
        getWorkloadCustomerCaseStudies: (params: any) =>
            dispatch(getWorkloadCustomerCaseStudies(params)),
    }
}

const mapStateToProps = (state: any) => {
    return {
        getWorkloadOptimizationsResult:
            state.getWorkloadOptimizationsResult.data,
        getWorkloadOptimizationsLoading:
            state.getWorkloadOptimizationsResult.loading,
        getWorkloadOptimizationsError:
            state.getWorkloadOptimizationsResult.error,

        getBenchmarksResult: state.getWorkloadBenchmarksResult.data,
        getbenchmarksLoading: state.getWorkloadBenchmarksResult.loading,
        getBenchmarksError: state.getWorkloadBenchmarksResult.error,

        authPermissionsResult: state.authPermissionsResult.data,
        authPermissionsLoading: state.authPermissionsResult.loading,
        authPermissionsError: state.authPermissionsResult.error,

        getWorkloadPartnerSolutionsResult:
            state.getWorkloadPartnerSolutionsResult,
        getWorkloadPartnerSolutionsLoading:
            state.getWorkloadPartnerSolutionsResult.loading,
        getWorkloadPartnerSolutionsError:
            state.getWorkloadPartnerSolutionsResult.error,

        getWorkloadCustomerCaseStudyResult:
            state.getWorkloadCustomerCaseStudiesResult.data,
        getWorkloadCustomerCaseStudyLoading:
            state.getWorkloadCustomerCaseStudiesResult.loading,
        getWorkloadCustomerCaseStudyError:
            state.getWorkloadCustomerCaseStudiesResult.error,
    }
}

type StateToPropsType = ReturnType<typeof mapStateToProps>
type DispatchToPropsType = typeof mapDispatchToProps

export default connect<StateToPropsType, DispatchToPropsType>(
    mapStateToProps,
    mapDispatchToProps
)(BenchmarkContainer)
