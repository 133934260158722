import { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react'
import { IBenchmarkCardPanelProps } from './benchmarkCardPanel'
import { useAppDispatch } from '../../config/hooks'

export function BenchmarkCardPanelLogic({
    getWorkloads,
    getWorkloadsResult,
    onTabClickCallback,
    paginationPageClickCallback,
    pageCountChangeCallback,
}: IBenchmarkCardPanelProps) {
    const dispatch = useAppDispatch()

    const [activeTabKey, setActiveTabKey] = useState('All')
    const [currPage, setCurrPage] = useState<number>(1)
    const [pageCount, setPageCount] = useState(10)

    const nextPage = getWorkloadsResult?.pageInfo.next
        ? getWorkloadsResult?.pageInfo.next
        : -1
    const totalPage = getWorkloadsResult?.pageInfo.total
        ? getWorkloadsResult?.pageInfo.total
        : -1

    const onTabClick = useCallback(
        (selectedValue: string, event: FormEvent<HTMLElement>) => {
            event.preventDefault()
            setActiveTabKey(selectedValue)
            setCurrPage(1)
            onTabClickCallback(selectedValue, event)
        },
        [activeTabKey]
    )

    const afterPageClicked = (pageNumber: number) => {
        setCurrPage(pageNumber)
        let offset = pageNumber * pageCount - pageCount
        paginationPageClickCallback(offset, pageCount)
    }

    const handlePageCount = useCallback((event: ChangeEvent<HTMLElement>) => {
        const target = event.currentTarget as HTMLSelectElement
        if (target.value != undefined && target.value != '') {
            setPageCount(parseInt(target.value))
            let offset = currPage * pageCount - pageCount
            let updatedPageCount = Number(target.value)
            //callback
            pageCountChangeCallback(offset, updatedPageCount)
            if ((currPage - 1) * pageCount + 1 > totalPage) setCurrPage(1)
        }
    }, [])

    return {
        currPage,
        afterPageClicked,
        pageCount,
        handlePageCount,
        onTabClick,
        activeTabKey,
        setActiveTabKey,
    }
}
