/************************************************
 * Copyright (C) 2021 Intel Corporation
 ************************************************/
import styled from 'styled-components'
import {
    Dispatch,
    FunctionComponent,
    ChangeEvent,
    useState,
    useEffect,
} from 'react'
import { API_SERVICE_URL } from '../../config/service'
import api from '../../services/auth/client'
import { connect } from 'react-redux'
import FooterPanel from '../../layout/footer'
import {
    FormLabel,
    Form,
    Row,
    ProgressBar,
    Button,
    Col,
    Spinner,
} from 'react-bootstrap'
import UserPanel from '../../layout/userProfile'
import {
    getCatalog,
    downloadCatalog,
    submitFeedback,
    saveFavorite,
    saveStarRating,
    saveVoting,
    getRecipeById,
    saveLinkClick,
    saveLike,
} from '../../store/catalog.slice'
import { LoadingState } from '../../models/loadingState'
import { CatalogSearchResponse } from '../../models/catalogSearchResponse'
import { DownloadCatalogResponse } from '../../models/downloadCatalogResponse'
import { RecipeFeedbackRequest } from '../../models/recipeFeedbackRequest'
import { Recipefeedbackresponse } from '../../models/recipefeedbackresponse'
import { RecipeAddFavoriteRequest } from '../../models/recipeAddFavoriteRequest'
import { RecipeAddFavoriteResponse } from '../../models/recipeAddFavoriteResponse'
import { RecipeStarRatingResponse } from '../../models/recipeStarRatingResponse'
import { RecipeStarRatingRequest } from '../../models/recipeStarRatingRequest'
import { RecipeAddVotingRequest } from '../../models/recipeAddVotingRequest'
import { RecipeAddVotingResponse } from '../../models/recipeAddVotingResponse'
import { RecipeAddLinkClickRequest } from '../../models/recipeAddLinkClickRequest'
import { getCartItems, updateCartItems } from '../../store/shoppingCart.slice'
import { UpdateShoppingCartRequest } from '../../models/updateShoppingCartRequest'
import { ShoppingCartResponse } from '../../models/shoppingCartResponse'
import { CatalogSearchRequest } from '../../models/catalogSearchrequest'
import RecipeCardPanel from '../../components/recipe/recipeCardPanel'
import { LayoutViewPreference } from '../../models/LayoutViewPreference'
import StringUtils from '../../lib/stringUtils'
import { CodeBlock, dracula } from 'react-code-blocks'
import HeaderPanel from '../../layout/header'
import { ShoppingCartRequest } from '../../models/shoppingCartRequest'
import { RecipeAddLikeResponse } from '../../models/recipeAddLikeResponse'
import { RecipeAddLikeRequest } from '../../models/recipeAddLikeRequest'
import { getClickCounts } from '../../store/dashboard.slice'
import { Stack, HTMLParser, ColParser, SVGParser } from '../../lib/flamegraph/parser'
import {Optimization, MatchedStack} from "./lib"

const lib = require('./lib')
const fgutils = require('../../lib/flamegraph/utils')

const FlamegraphPanelContainer = styled.div`
    display: flex;
    height: ${(props) => props.theme.size.mainContainerHeight};
    width: ${(props) => props.theme.size.mainContainerWidth};
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: space-between;
    @media screen and (min-width: 80rem) {
        overflow-x: hidden;
    }
    @media screen and (max-width: 500px) {
        width: 100%;
        height: 100%;
    }
`

const FlamegraphHorizontalContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: space-between;
`
const FlamegraphBodyContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: center;
    background-color: ${(props) => props.theme.color.background.defaultGrey};
    padding-bottom: 10rem;
    width: 80%;
`

const FlamegraphBodyContainerReposition = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    margin-left: 2rem;
`

const PageLinkPanelPanelWrapper = styled.div`
    display: flex;
    margin-left: 2rem;
    @media screen and (max-width: 500px) {
        display: none;
    }
`
const MobileHeaderWrapper = styled.div`
    display: flex;
    display: none;
    @media screen and (max-width: 500px) {
        display: block;
    }
`

const FlamegraphPanelWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: flex-start;
    margin-top: 2rem;
`
const PageTitleTextLabel = styled(FormLabel)`
    ${(props) => props.theme.typography.xxxLarge}
    text-align: left;
    margin-bottom: 3rem;
    font-weight: 500;
    font-size: 2.25rem;
`
const CardContainer = styled.div`
    display: flex;
    justify-content: left;
    width: 97%;
    word-wrap: break-word;
`
const Card = styled.div`
    padding: 2rem;
    margin-right: 3rem;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 25px;
    border-top: 5px solid #0073bb;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 30%), 0 0 0 1px rgb(0 0 0 / 4%);
    background-color: #fff;
`
const BigCard = styled(Card)`
    height: 20rem;
    //background: rgba(0, 196, 223, 0.19);
`
const CardHeaderContainer = styled.div`
    display: flex;
    justify-content: left;
`

const CardTitle = styled.span`
    weight: 500;
    font-size: 2rem;
    margin-left: 1rem;
    color: var(--color-text-link-default-753y4e, #0073bb);
`
const CardBodyText = styled(FormLabel)`
    font-family: 'IntelOne Display';
    weight: 400;
    font-size: 1rem;
    margin-left: 2%;
    margin-top: 1rem;
`
const TextLabel = styled(FormLabel)`
    ${(props) => props.theme.typography.xLarge}
    text-align: left;
    padding-top: 0.5rem;
    padding-bottom: 0.2rem;
`
const SpinnerDiv = styled.div`
    display: flex;
    align-items: left;
    justify-content: left;
    margin-top: 5rem;
    margin-left: 10%;
    width: 100%;
`
const serviceConfig = require('../../config/service.json')

export interface IFlamegraphContainerProps {
    getCatalog: typeof getCatalog
    downloadCatalog: typeof downloadCatalog
    downloadCatalogLoading: LoadingState
    downloadCatalogError: any
    catalogloading: LoadingState
    error: any
    catalogResult: CatalogSearchResponse
    downloadCatalogResult: DownloadCatalogResponse
    submitFeedback: typeof submitFeedback
    feedbackResult: Recipefeedbackresponse
    feedbackError: any
    feedbackLoading: LoadingState
    saveFavorite: typeof saveFavorite
    favoriteResult: RecipeAddFavoriteResponse
    favoriteError: any
    favoriteLoading: LoadingState
    saveStarRating: typeof saveStarRating
    saveRatingResult: RecipeStarRatingResponse
    saveRatingError: any
    saveVoting: typeof saveVoting
    saveVotingResult: RecipeAddVotingResponse
    saveVotingError: any
    getRecipeById: typeof getRecipeById
    saveLinkClick: typeof saveLinkClick
    getCartItems: typeof getCartItems
    updateCartItems: typeof updateCartItems
    getCartItemsResult: ShoppingCartResponse
    getCartItemsResultLoading: LoadingState
    getCartItemsResultError: any
    updateCartResult: ShoppingCartResponse
    saveLike: typeof saveLike
    likeResult: RecipeAddLikeResponse
    likeError?: any
    likeLoading: LoadingState
    clickCountsResult: any
}

const FlamegraphContainer: FunctionComponent<IFlamegraphContainerProps> & {
    defaultProps: Partial<IFlamegraphContainerProps>
} = ({
    getCatalog,
    downloadCatalog,
    downloadCatalogResult,
    downloadCatalogLoading,
    downloadCatalogError,
    catalogloading,
    catalogResult,
    error,
    submitFeedback,
    feedbackResult,
    feedbackError,
    feedbackLoading,
    saveFavorite,
    favoriteResult,
    favoriteError,
    favoriteLoading,
    saveStarRating,
    saveRatingResult,
    saveRatingError,
    saveVoting,
    saveVotingResult,
    saveVotingError,
    getRecipeById,
    saveLinkClick,
    getCartItems,
    updateCartItems,
    getCartItemsResult,
    getCartItemsResultLoading,
    getCartItemsResultError,
    updateCartResult,
    saveLike,
    likeResult,
    likeError,
    likeLoading,
    clickCountsResult,
}: IFlamegraphContainerProps) => {
    const [recipes, setRecipes] = useState<Array<Optimization>>([])
    const [matches, setMatches] = useState<Map<string, MatchedStack> | undefined>(undefined)
    const [loading, setLoading] = useState<any>(true)
    const [showLoading, setShowLoading] = useState<any>(false)
    const [fgError, setFGError] = useState<string | undefined>(undefined);

    let empStatus = localStorage.getItem('isIntelEmployee')
    let isIntelEmployee = !StringUtils.isNullOrUndefinedOrEmpty(empStatus)
        ? Boolean(empStatus)
        : false

    useEffect(() => {
        var url = API_SERVICE_URL + serviceConfig.getRecipeURL
        api.get(url + '?count=1000&tags-filter=').then((optimizations: any) => {
            setRecipes(optimizations.data.recipes as Array<Optimization>)
            setLoading(false)
        })
    }, [])
    const showLoadingState = (
        <SpinnerDiv>
            <Spinner animation="border" variant="dark">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </SpinnerDiv>
    )

    const uploadFlamegraph = (event: ChangeEvent<HTMLElement>) => {
        const target = event.currentTarget as HTMLInputElement
        const reader = new FileReader()

        setShowLoading(true)

        let searchMatches: Map<string, MatchedStack> = new Map();

        reader.onload = (e: ProgressEvent<FileReader>) => {
            const contents = (e.target!.result as string);
            var flamegraph: Stack;
            switch (true) {
            case target.files![0].name.endsWith(".col"):
                flamegraph = ColParser(contents);
                break;
            case target.files![0].name.endsWith(".html"):
                flamegraph = HTMLParser(contents);
                break;
            case target.files![0].name.endsWith(".svg"):
                flamegraph = SVGParser(contents);
                break;
            default:
                setFGError("Only .html or .col flamegraphs are supported")
                return
            };

            const matchedOptimizations = lib.findOptimizationsForFlamegraph(flamegraph, recipes);
            matchedOptimizations.forEach((m: MatchedStack) => {
                searchMatches.set(m.optimization.uuid, m)
            })

            setMatches(searchMatches);
            setFGError(undefined);
        }

        if (target.files === undefined) {
            return
        }

        reader.readAsText(target.files![0])
    }
    useEffect(() => {
        if (matches === undefined) {
            return
        }
        if (matches.size == 0) {
            setShowLoading(false)
            return
        }

        // Not ideal, but we build the query string here and pass to getCatalog
        const query = Array.from(matches.keys()).map( u => `uuid=${u}` ).join("&");

        getCatalog({
            uuids: query,
        } as CatalogSearchRequest)
        setShowLoading(false)
    }, [matches])

    useEffect(() => {
        if (fgError && fgError != "") {
            setShowLoading(false)
            setMatches(undefined)
        }
    }, [fgError])

    const commandText = `    wget https://github.com/Granulate/gprofiler/releases/latest/download/gprofiler_$(uname -m) -O gprofiler
    chmod +x gprofiler
    sudo ./gprofiler -o out/`

    function maybeNoMatchingOptimizations() {
        if (matches && matches.size == 0) {
            return <Row style={{ paddingLeft: '0.5rem' }}>
                        <br />
                        No Matching Optimizations
                    </Row>
        }
        return ''
    }

    function maybeDisplayStackMatches() {
        if (matches && matches.size > 0) {
            return <Row>
                       <CardBodyText>
                           <ul>
                               {Array.from(matches.values())
                                   .sort(
                                       (a: MatchedStack, b: MatchedStack) =>
                                           b.benefit -
                                           a.benefit
                                   )
                                   .map((e: any, index) => (
                                       <div
                                           style={{
                                               whiteSpace:
                                                   'initial',
                                           }}
                                       >
                                           <li key={index}>
                                               {e.time.toFixed(
                                                   2
                                               )}
                                               % time spent in{' '}
                                               <b>
                                                   {e.pattern}
                                               </b>
                                               <br />
                                               {Math.round(
                                                   e.ratio *
                                                       100
                                               )}
                                               % optimization
                                               opportunity with{' '}
                                               <b>{e.name}</b>
                                           </li>
                                           <br />
                                       </div>
                                   ))}
                           </ul>
                       </CardBodyText>
                   </Row>
        }
        return ''
    }

    function maybeDisplayOptimizations() {
        if (matches && matches.size > 0 && catalogloading !== LoadingState.Pending) {
            return <RecipeCardPanel
                    isFavoritesLoading={false}
                    recipeResult={catalogResult}
                    downloadCatalog={downloadCatalog}
                    downloadCatalogResult={
                        downloadCatalogResult
                    }
                    downloadCatalogLoading={
                        downloadCatalogLoading
                    }
                    downloadCatalogError={downloadCatalogError}
                    submitFeedback={submitFeedback}
                    feedbackResult={feedbackResult}
                    feedbackError={feedbackError}
                    feedbackLoading={feedbackLoading}
                    saveFavorite={saveFavorite}
                    favoriteResult={favoriteResult}
                    favoriteError={favoriteError}
                    favoriteLoading={favoriteLoading}
                    saveStarRating={saveStarRating}
                    saveRatingResult={saveRatingResult}
                    saveRatingError={saveRatingError}
                    saveVoting={saveVoting}
                    saveVotingResult={saveVotingResult}
                    saveVotingError={saveVotingError}
                    getRecipeById={getRecipeById}
                    saveLinkClick={saveLinkClick}
                    updateCartItems={updateCartItems}
                    updateCartResult={updateCartResult}
                    getCartItems={getCartItems}
                    getCartItemsResult={getCartItemsResult}
                    getCartItemsResultLoading={
                        getCartItemsResultLoading
                    }
                    getCartItemsResultError={
                        getCartItemsResultError
                    }
                    resultLayoutView={
                        LayoutViewPreference.GalleryView
                    }
                    isIntelEmployee={isIntelEmployee}
                    isFlameScannerLoading={true}
                    saveLike={saveLike}
                    likeResult={likeResult}
                    likeError={likeError}
                    likeLoading={likeLoading}
                    getClickCounts={getClickCounts}
                    clickCountsResult={clickCountsResult}
                ></RecipeCardPanel>
        }
        return ""
    }

    function maybeDisplayErrors() {
        if (fgError && fgError != "") {
            return <Row style={{ paddingLeft: '2rem', color: 'red' }}>
                <br />
                    {fgError}
            </Row>
        }
    }

    return (
        <FlamegraphPanelContainer>
            <HeaderPanel />
            <FlamegraphHorizontalContainer>
                <FlamegraphBodyContainer>
                    <FlamegraphBodyContainerReposition>
                        <UserPanel></UserPanel>
                        <PageTitleTextLabel data-testid="page-title-label">
                            <img
                                src={StringUtils.resolve('/gprofiler.svg')}
                                height="40"
                                width="40"
                            ></img>{' '}
                            gProfiler Analyzer
                        </PageTitleTextLabel>
                        <CardContainer>
                            <Card>
                                <Row>
                                    <CardHeaderContainer>
                                        <Form.Group
                                            as={Col}
                                            md="9"
                                            style={{ width: '100%' }}
                                        >
                                            This page scans software stacks for
                                            Intel optimizations.{' '}
                                            <a href="https://github.com/Granulate/gprofiler">
                                                gProfiler
                                            </a>{' '}
                                            can do all of this online through{' '}
                                            <a href="https://profiler.granulate.io/">
                                                https://profiler.granulate.io/
                                            </a>{' '}
                                            or offline with the following
                                            command:
                                            <CodeBlock
                                                theme={dracula}
                                                text={commandText}
                                                language={''}
                                                showLineNumbers={false}
                                            />
                                        </Form.Group>
                                    </CardHeaderContainer>
                                </Row>
                                <Row>
                                    <CardHeaderContainer>
                                        <Form.Group
                                            as={Col}
                                            md="6"
                                            controlId="formFile"
                                        >
                                            Supports stack files in the
                                            following formats
                                            <ul>
                                                <li>.col</li>
                                                <li>.html</li>
                                                <li>.svg</li>
                                            </ul>
                                            <Form.Control
                                                type="file"
                                                onChange={uploadFlamegraph}
                                                disabled={loading}
                                                accept=".html, .col, .svg"
                                            />
                                            * Scan is completely offline.
                                            Telemetry data never leaves your
                                            computer
                                        </Form.Group>
                                    </CardHeaderContainer>
                                </Row>
                                {loading && <ProgressBar animated now={30} />}
                                { maybeNoMatchingOptimizations() }
                                { maybeDisplayStackMatches() }
                                { maybeDisplayErrors() }
                                { showLoading && showLoadingState }
                            </Card>
                        </CardContainer>

                        <FlamegraphPanelWrapper>
                            { maybeDisplayOptimizations() }
                            { matches && showLoading && showLoadingState }
                        </FlamegraphPanelWrapper>
                    </FlamegraphBodyContainerReposition>
                </FlamegraphBodyContainer>
            </FlamegraphHorizontalContainer>
            <FooterPanel></FooterPanel>
        </FlamegraphPanelContainer>
    )
}

FlamegraphContainer.defaultProps = {}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        getCatalog: (catalogSearchCriteria: CatalogSearchRequest) => {
            dispatch(getCatalog(catalogSearchCriteria))
        },
        downloadCatalog: (downloadUrl: string) =>
            dispatch(downloadCatalog(downloadUrl)),
        submitFeedback: (recipeFeedbackRequest: RecipeFeedbackRequest) =>
            dispatch(submitFeedback(recipeFeedbackRequest)),
        saveFavorite: (addFavoriteRequest: RecipeAddFavoriteRequest) =>
            dispatch(saveFavorite(addFavoriteRequest)),
        saveStarRating: (saveRatingRequest: RecipeStarRatingRequest) =>
            dispatch(saveStarRating(saveRatingRequest)),
        saveVoting: (saveVotingRequest: RecipeAddVotingRequest) =>
            dispatch(saveVoting(saveVotingRequest)),
        getRecipeById: (id: any) => dispatch(getRecipeById(id)),
        saveLinkClick: (addLinkClickRequest: RecipeAddLinkClickRequest) =>
            dispatch(saveLinkClick(addLinkClickRequest)),
        getCartItems: (shoppingCartRequest: ShoppingCartRequest) =>
            dispatch(getCartItems(shoppingCartRequest)),
        updateCartItems: (
            updateShoppingCartRequest: UpdateShoppingCartRequest
        ) => dispatch(updateCartItems(updateShoppingCartRequest)),
        saveLike: (addLikeRequest: RecipeAddLikeRequest) =>
            dispatch(saveLike(addLikeRequest)),
    }
}

const mapStateToProps = (state: any) => {
    return {
        error: state.catalogSearchResult.error,
        catalogloading: state.catalogSearchResult.loading,
        catalogResult: state.catalogSearchResult.data,
        recipeGetResult: state.recipeResult,
        downloadCatalogResult: state.downloadCatalogResult,
        downloadCatalogLoading: state.downloadCatalogResult.loading,
        downloadCatalogError: state.downloadCatalogResult.error,
        feedbackResult: state.submitFeedbackResult.data,
        feedbackError: state.submitFeedbackResult.error,
        feedbackLoading: state.submitFeedbackResult.loading,
        favoriteResult: state.saveFavoriteResult.data,
        favoriteError: state.saveFavoriteResult.error,
        favoriteLoading: state.saveFavoriteResult.loading,
        saveRatingResult: state.saveStarRatingResult.data,
        saveRatingError: state.saveStarRatingResult.error,
        saveVotingResult: state.saveVotingResult.data,
        saveVotingError: state.saveVotingResult.error,
        recipeByIdresult: state.getRecipeByIdResult.data,
        saveLinkClickResult: state.saveLinkClickResult.data,
        getCartItemsResult: state.getCartItemsResult.data,
        getCartItemsResultLoading: state.getCartItemsResult.loading,
        getCartItemsResultError: state.getCartItemsResult.error,
        updateCartResult: state.updateCartResult.data,
        likeResult: state.saveLikeResult.data,
        likeError: state.saveLikeResult.error,
        likeLoading: state.saveLikeResult.loading,
    }
}

type StateToPropsType = ReturnType<typeof mapStateToProps>
type DispatchToPropsType = typeof mapDispatchToProps

export default connect<StateToPropsType, DispatchToPropsType>(
    mapStateToProps,
    mapDispatchToProps
)(FlamegraphContainer)
