import { Typography } from '@mui/material'
import { useAppSelector } from '../../../../config/hooks'
import { FC, useEffect, useState } from 'react'
import { defaultSelectedDomainSearchDist } from '../../DashboardPanel'
import BarChart, { BarChartData } from '../../shared/charts/BarChart'

const searchMissesChartTitleSx = { fontSize: '1rem', marginLeft: '2rem' }

const searchMissesByDomainChartHeaders: [string, string, { role: string }] = [
    'Term',
    '# Search Misses',
    { role: 'annotation' },
]

interface SearchMissesByDomainProps {
    selectedDomainSearchDist: string
}

const SearchMissesByDomain: FC<SearchMissesByDomainProps> = ({
    selectedDomainSearchDist,
}) => {
    const searchMissesData = useAppSelector<any>(
        (state) => state.getNoHitsResult.data
    )
    const searchMissesLoading = useAppSelector(
        (state) => state.getNoHitsResult.loading
    )
    const allSearchMissesData = useAppSelector<any>(
        (state) => state.getAllNoHitsResult.data
    )
    const allSearchMissesLoading = useAppSelector<any>(
        (state) => state.getAllNoHitsResult.loading
    )

    const [chartData, setChartData] = useState<BarChartData>([
        searchMissesByDomainChartHeaders,
    ])

    useEffect(() => {
        if (selectedDomainSearchDist !== defaultSelectedDomainSearchDist) {
            if (
                searchMissesData !== null &&
                searchMissesData?.data?.[selectedDomainSearchDist]
            ) {
                const newChartData = Object.entries(
                    searchMissesData.data[selectedDomainSearchDist] as {
                        [key: string]: number
                    }
                )
                    .filter(([name]) => name !== '')
                    .sort(([_A, valueA], [_B, valueB]) => valueB - valueA)
                    .map(([name, value]) => [name, value, value])

                setChartData([
                    searchMissesByDomainChartHeaders,
                    ...newChartData,
                ])
            } else {
                setChartData([searchMissesByDomainChartHeaders])
            }
        } else if (
            allSearchMissesData !== null &&
            allSearchMissesData?.data?.CountDomainsPerTerm
        ) {
            const newChartData = Object.entries(
                allSearchMissesData.data.CountDomainsPerTerm as {
                    [key: string]: number
                }
            )
                .filter(([key, _]) => key !== '')
                .sort(([_A, valueA], [_B, valueB]) => valueB - valueA)
                .map(([name, value]) => [name, value, value])

            setChartData([searchMissesByDomainChartHeaders, ...newChartData])
        } else {
            setChartData([searchMissesByDomainChartHeaders])
        }
    }, [searchMissesData, allSearchMissesData, selectedDomainSearchDist])

    return (
        <>
            <Typography variant="h6" sx={searchMissesChartTitleSx}>
                All Search Misses
            </Typography>
            <BarChart
                chartData={chartData}
                dataLoadingState={[searchMissesLoading, allSearchMissesLoading]}
            />
        </>
    )
}
export default SearchMissesByDomain
