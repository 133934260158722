import { useState, useCallback, FormEvent, ChangeEvent, useEffect } from 'react'
import { CreateOrgRequest } from '../../models/createOrgRequest'
import { IAddOrganizationContainerProps } from './addOrganizationContainer'
import { message } from 'antd'

export function AddOrganizationContainerLogic({
    createOrg,
    createOrgLoading,
    createOrgResult,
    createOrgError,
}: IAddOrganizationContainerProps) {
    const [validated, setValidated] = useState(false)
    const [organizationName, setOrganizationName] = useState<string>('')
    const [onError, setOnError] = useState<any>('')
    const [onSuccessMessage, setOnSuccessMessage] = useState<any>('')
    const [isFormSubmitted, setIsFormSubmitted] = useState(false)

    const onOrgChange = useCallback((event: ChangeEvent<HTMLElement>) => {
        const target = event.currentTarget as HTMLTextAreaElement
        setOrganizationName(target.value)
    }, [])

    const onSubmit = useCallback(
        async (event: FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            const form = event.currentTarget

            if (form.checkValidity() === false) {
                event.preventDefault()
                event.stopPropagation()
            }
            setValidated(true)
            if (organizationName) {
                var createOrgRequest = new CreateOrgRequest()
                createOrgRequest.name = organizationName
                createOrgRequest.description = ''
                createOrg(createOrgRequest)
                setIsFormSubmitted(true)
            }
        },

        [organizationName]
    )
    useEffect(() => {
        if (isFormSubmitted) {
            if (createOrgResult) {
                if (createOrgResult.id) {
                    message.success('Organization created successfully')
                    setIsFormSubmitted(false)
                    setOrganizationName('')
                    setValidated(false)
                } else {
                    message.error('Error retrieving org id')
                }
            }
        }
    }, [createOrgResult])
    useEffect(() => {
        if (createOrgError && isFormSubmitted) {
            message.error('Error creating org')
            setIsFormSubmitted(false)
        }
    }, [createOrgError])

    const onCancel = useCallback((event: FormEvent<HTMLElement>) => {
        event.preventDefault()
        setValidated(false)
        setOnError('')
        setOrganizationName('')
        window.location.reload()
    }, [])

    return {
        onError,
        onSuccessMessage,
        validated,
        onSubmit,
        onCancel,
        onOrgChange,
        organizationName,
    }
}
