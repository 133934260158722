import { FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import { FormLabel } from 'react-bootstrap'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import HeaderPanel from '../../layout/header'
import { FFMPEG_RECIPE_UUID } from '../../config/service'

const OptimizationFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
`

const OptimizationFormComponent = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: no-wrap;
    padding-bottom: 6rem;
`
const OptimizationHeaderDiv = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    background: #004a86;
    margin-top: 2rem;
`
const OptimizationBlueBanner = styled.div`
    display: flex;
    flex-direction: center;
    background: ${(props) =>
        `url('${process.env.PUBLIC_URL}/marguee_blue.png')`};
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
`
const TabsContainer = styled.div`
    //display: flex;
    flex-direction: center;
`
const TabContainer = styled.div`
    display: flex;
    flex-direction: center;
    padding: 3% 25% 3% 25%;
    font-family: 'intel-clear';
    font-size: 16px;
    line-height: 1.42857143;
    color: #262626;
    background-color: #fefefe;
`
const TabLabel = styled(Tab)`   
    display: table-cell;
    padding: 1.5rem 3rem !important;
    .nav-link.active {
        color: red;
        background-color: #fff;
        border-color: #dee2e6 #dee2e6 #fff;
    }
    .nav-link {
        display: block;
        padding: 0.5rem 1rem;
        color: #000;
        text-decoration: none;
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
    }
    
}
`
const PageTitleTextLabel = styled(FormLabel)`
    ${(props) => props.theme.typography.xxxLarge}
    text-align: left;
    margin-bottom: 2rem;
    font-weight: 500;
    font-size: 2rem;
    color: #fff;
    @media screen and (min-width: 500px) {
        font-size: 1.8rem;
    }
    @media screen and (min-width: 768px) {
        font-size: 2rem;
    }
    @media screen and (min-width: 800px) {
        font-size: 2.5rem;
    }
`
const PageTitleLevel2 = styled(FormLabel)`
    ${(props) => props.theme.typography.xLarge}
    justify-content: left;
    text-align: left;
    //margin-bottom: 2rem;
    font-weight: 400;
    font-size: 1.5rem;
    width: 80%;
    color: #fff;
`

export interface IOptPanelProps {
    headerPanelContent: string
    view: string
    benefits: JSX.Element
    capabilities: JSX.Element
    howTo: JSX.Element
    WhatTo: JSX.Element
    FAQ?: JSX.Element
    contacts?: string
}

const OptimizationDetailsPanel: FunctionComponent<IOptPanelProps> = ({
    headerPanelContent,
    view,
    benefits,
    capabilities,
    howTo,
    WhatTo,
    FAQ,
    contacts,
}: IOptPanelProps) => {
    const [activeKey, setActiveKey] = useState('OVERVIEW')
    let ffmpeg_recipe_url = ''

    if (FFMPEG_RECIPE_UUID && FFMPEG_RECIPE_UUID !== '') {
        ffmpeg_recipe_url = `/catalog?uuid=${FFMPEG_RECIPE_UUID}`
    } else {
        ffmpeg_recipe_url = `/catalog?p=${encodeURI(
            'Intel Video Transcode Acceleration Library'
        )}`
    }

    return (
        <OptimizationFormContainer>
            <HeaderPanel />
            <OptimizationFormComponent>
                <OptimizationHeaderDiv>
                    <OptimizationBlueBanner>
                        <div
                            style={{
                                padding: '7% 7% 7% 29%',
                                justifyContent: 'center',
                            }}
                        >
                            <PageTitleTextLabel data-testid="page-title-label">
                                {headerPanelContent}
                            </PageTitleTextLabel>
                            <br />
                            <PageTitleLevel2>
                                {
                                    'Reduce compute and bandwidth costs while improving performance'
                                }
                            </PageTitleLevel2>
                            <p style={{ padding: '1.5rem 0 1.5rem 0' }}>
                                <a
                                    className="btn btn-primary "
                                    style={{
                                        background: '#fff',
                                        color: '#0068b5',
                                        padding: '0.5rem 2rem 0.5rem 2rem',
                                        fontSize: '1.1rem',
                                    }}
                                    href={ffmpeg_recipe_url}
                                >
                                    View optimization{' '}
                                    <i className="uil uil-arrow-right"></i>
                                </a>
                            </p>
                        </div>
                    </OptimizationBlueBanner>
                </OptimizationHeaderDiv>
                <TabsContainer>
                    <Tabs
                        variant="tabs"
                        id="tabs"
                        defaultActiveKey={activeKey}
                        onSelect={(k: any) => setActiveKey(k)}
                        className="mb-3"
                        style={{ justifyContent: 'center' }}
                    >
                        <TabLabel
                            eventKey="OVERVIEW"
                            title="OVERVIEW"
                            style={{ height: '5rem' }}
                        >
                            <TabContainer>{view}</TabContainer>
                        </TabLabel>
                        <TabLabel eventKey="BENEFITS" title="BENEFITS">
                            <TabContainer>{benefits}</TabContainer>
                        </TabLabel>
                        <TabLabel
                            eventKey=" CAPABILITIES"
                            title=" CAPABILITIES"
                        >
                            <TabContainer>{capabilities}</TabContainer>
                        </TabLabel>
                        <TabLabel
                            eventKey="How TO GET IT"
                            title="How TO GET IT"
                        >
                            <TabContainer>{howTo}</TabContainer>
                        </TabLabel>
                        <TabLabel
                            eventKey="WHAT TO LOOK FOR"
                            title="WHAT TO LOOK FOR"
                        >
                            <TabContainer>{WhatTo}</TabContainer>
                        </TabLabel>
                        <TabLabel
                            eventKey="FREQUENTLY ASKED QUESTIONS"
                            title="FREQUENTLY ASKED QUESTIONS"
                        >
                            <TabContainer>{FAQ}</TabContainer>
                        </TabLabel>
                        <TabLabel eventKey="CONTACTS" title="CONTACTS">
                            <TabContainer>{contacts}</TabContainer>
                        </TabLabel>
                    </Tabs>
                </TabsContainer>
            </OptimizationFormComponent>
        </OptimizationFormContainer>
    )
}

export default OptimizationDetailsPanel
