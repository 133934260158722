import DashboardCard from '../shared/cards/DashboardCard'
import BarChart, { BarChartData } from '../shared/charts/BarChart'
import { useEffect, useState } from 'react'
import { useAppSelector } from '../../../config/hooks'

const firstTimeCustomersChartDataHeaders: [string, string, { role: string }] = [
    'Domain',
    '# Customers',
    { role: 'annotation' },
]

const FirstTimeCustomersCard = () => {
    const firstTimeCustomersData = useAppSelector<any>(
        (state) => state.getFirstTimeCustomersResult.data
    )
    const firstTimeCustomersLoading = useAppSelector(
        (state) => state.getFirstTimeCustomersResult.loading
    )

    const [chartData, setChartData] = useState<BarChartData>([
        firstTimeCustomersChartDataHeaders,
    ])

    useEffect(() => {
        const distribution = ![null, undefined].includes(
            firstTimeCustomersData?.domainDistribution
        )
            ? Object.entries(
                  firstTimeCustomersData?.domainDistribution as {
                      [key: string]: number
                  }
              )
                  .sort(([_A, valueA], [_B, valueB]) => valueB - valueA)
                  .map(([key, value]) => [key, value, value])
            : []
        if (distribution.length > 0) {
            setChartData([firstTimeCustomersChartDataHeaders, ...distribution])
        } else {
            setChartData([firstTimeCustomersChartDataHeaders])
        }
    }, [firstTimeCustomersData])

    return (
        <DashboardCard title="First Time Customers">
            <BarChart
                chartData={chartData}
                dataLoadingState={firstTimeCustomersLoading}
            />
        </DashboardCard>
    )
}

export default FirstTimeCustomersCard
