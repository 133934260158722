/************************************************
 * Copyright (C) 2021 Intel Corporation
 ************************************************/
import { FunctionComponent, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Cache } from '@aws-amplify/cache'
import Auth from '@aws-amplify/auth'
import stringUtils from '../../lib/stringUtils'
import StringUtils from '../../lib/stringUtils'
import HomePanelHeader from './landingPageHeader'
import { clearAllItemsFromStorage } from '../../lib/encryptStorage'
import { Button } from 'react-bootstrap'
import FooterPanel from '../../layout/footer'
import MobileHeader from '../../layout/mobileHeader'

const HomePageMainContainer = styled.div`
    display: flex;
    height: ${(props) => props.theme.size.mainContainerHeight};
    width: ${(props) => props.theme.size.mainContainerWidth};
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: space-between;
    background: #fff;
    @media screen and (min-width: 1200px) {
        overflow-x: hidden;
    }
    @media screen and (min-width: 768px) {
        height: 100%;
        width: 100%;
        overflow-x: hidden;
    }
`
const HomePageContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: space-between;
    @media screen and (max-width: 768px) {
        display: none;
    }
`
const MobileContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: space-between;
    width: auto;
    min-width: 360px;
    @media screen and (min-width: 768px) {
        display: none;
    }
`
const TopBanner = styled.div`
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    height: 350px;
    width: 100%;
    background-position-x: 50%;
    background-position-y: 50%;
    background-repeat: no-repeat;
    background-size: 100%;

    @media screen and (max-width: 1050px) and (min-width: 750px) {
        height: 446px;
        background: ${(props) =>
            `url('${process.env.PUBLIC_URL}/top_banner_991-x-446-v3.png')`};
    }
    @media screen and (min-width: 1051px) and (max-width: 1370px) {
        height: 355px;
        background: ${(props) =>
            `url('${process.env.PUBLIC_URL}/top_banner_1280-x-355-v1.png')`};
    }
    @media screen and (min-width: 1371px) and (max-width: 1550px) {
        height: 400px;
        background: ${(props) =>
            `url('${process.env.PUBLIC_URL}/top_banner_1440-x-400-v1.png')`};
    }
    @media screen and (min-width: 1551px) and (max-width: 1750px) {
        height: 510px;
        background: ${(props) =>
            `url('${process.env.PUBLIC_URL}/top_banner_1650-x-510-v1.png')`};
    }
    @media screen and (min-width: 1751px) and (max-width: 2200px) {
        height: 530px;
        background: ${(props) =>
            `url('${process.env.PUBLIC_URL}/top_banner_1920-x-533-v3.png')`};
    }
    @media screen and (min-width: 2201px) {
        height: 700px;
        background: ${(props) =>
            `url('${process.env.PUBLIC_URL}/top_banner_2560-x-710-v1.png')`};
    }
    @media screen and (min-width: 3800px) {
        height: 1030px;
        background: ${(props) =>
            `url('${process.env.PUBLIC_URL}/top_banner_3840-x-1066-v1.png')`};
    }
`
const JumpLinksWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    background-color: #1e1e1e;
    width: 100%;
    position: relative;
`
const JumpLinksHeader = styled(JumpLinksWrapper)`
    justify-content: space-evenly;
`
const IntroductionContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    flex-direction: row;
    background: #fff;
    width: 100%;
    height: auto;
`
const IntroComponent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #fff;
    min-height: 300px;
    padding: 1rem;
`
const VideoComponent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #fff;
    min-height: 300px;
    padding: 1rem;
`
const ContainerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #eaeaea;
    padding: 2rem 0;
`
const ComponentWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
`

const HomePageFooter = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`
const FeatureDiv = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: center;
    align-items: center;
    margin: 2.5rem;
`
const FeatureOptions = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: center;
    align-items: center;
    width: 266px;
    height: 281px;
`
const FeatureSeprator = styled.div`
    margin-top: 1rem;
    height: 1px;
    background: rgb(182, 180, 189);
    width: 100%;
    border-bottom: 1px solid rgb(182, 180, 189);
`
const FeaturLabelDiv = styled.div`
    display: flex;
    justify-content: center;
    color: #653171;
    text-align: center;
    font-family: 'IntelOne Display';
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 1rem 0;
`
const FeaturSubLabel = styled.div`
    display: flex;
    justify-content: center;
    color: #808080; //#1E1E1E
    text-align: center;
    font-family: 'IntelOne Text';
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    margin: 0 0;
    @media screen and (max-width: 768px) {
        flex-wrap: wrap;
        padding: 0 0.5rem;
    }
`
const BenefitsImgDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 77px;
    height: 77px;
    background: #653171;
`
const FeatureImgDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 450px;
    background: ${(props) =>
        `url('${process.env.PUBLIC_URL}/rec_feature1.png')`};
    margin: 1rem 1rem 2rem 1rem;
    @media screen and (max-width: 768px) {
        width: 300px;
        height: 350px;
    }
    @media screen and (min-width: 769px) and (max-width: 1599px) {
        width: 330px;
        height: 400px;
    }
`
const FeatureImgDiv2 = styled(FeatureImgDiv)`
    background: ${(props) =>
        `url('${process.env.PUBLIC_URL}/rec_feature2.png')`};
`
const FeatureImgDiv3 = styled(FeatureImgDiv)`
    background: ${(props) =>
        `url('${process.env.PUBLIC_URL}/rec_feature3.png')`};
`
const FeatureImgDiv4 = styled(FeatureImgDiv)`
    background: ${(props) =>
        `url('${process.env.PUBLIC_URL}/rec_feature4.png')`};
`
const FeaturText = styled.label`
    color: #fff;
    text-align: center;
    font-family: 'IntelOne Display';
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    &:hover {
        cursor: pointer;
    }
    @media screen and (max-width: 768px) {
        font-size: 24px;
    }
    @media screen and (min-width: 769px) and (max-width: 1599px) {
        font-size: 30px;
    }
`
const ResourcesText = styled(FeaturText)`
    color: #1e1e1e;
    font-family: 'IntelOne Text';
    font-size: 1.2rem;
    font-weight: 400;
    width: 88%;
    &:hover {
        cursor: auto;
    }
`
const JumpLink = styled(Link)`
    color: #fff;
    padding: 1rem 0.2rem;
    font-family: 'IntelOne Text';
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
`
const H3Header = styled.h3`
    color: #000;
    text-align: center;
    font-family: 'IntelOne Display';
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 2rem 0;
    @media screen and (max-width: 768px) {
        font-size: 24px;
    }
`
const CSPWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    width: 280px;
    height: 261px;
    margin: 2rem;
`
const ResourcesWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #d9d9d9;
    width: 381px;
    height: 369px;
    margin: 2rem 2rem 4rem 2rem;
`
const ResourcesDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 80%;
`
const ButtonComponent = styled(Button)`
    font-family: 'IntelOne Text';
    font-style: normal;
    font-size: 1rem;
    text-decoration: none;
    border: none;
    color: #fff;
    background-color: #0068b5;
    min-width: 8.5rem;
    border-radius: 0px;
    border: none;
`
const ContactUsButton = styled(ButtonComponent)`
    height: 2.2rem;
    margin-top: 0.6rem;
    margin-right: 2rem;
`
const HomePageVerticalContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #f6f5f8;
`
const IntelLogoStyling = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem 0.8rem;
    background: #fff;
`
const MobileHeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justifycontent: space-between;
`

export interface IHomePanelProps {}

const signOut = async () => {
    try {
        Cache.clear()
        await Auth.signOut()
    } catch (error) {
        console.log('error signing out: ', error)
    }
}

const HomePanel: FunctionComponent<IHomePanelProps> = () => {
    const location = useLocation()
    let navigate = useNavigate()

    const queryParameters = new URLSearchParams(location.search)
    const invitationKey = queryParameters.get('invitationKey')
    const [isExpandResources, setIsExpandResources] = useState(false)

    useEffect(() => {
        if (location.pathname.includes('logOut')) {
            signOut()
            clearAllItemsFromStorage()
            localStorage.removeItem('original_url')
            localStorage.removeItem('invitationKey')
        } else {
            localStorage.setItem(
                'invitationKey',
                invitationKey ? invitationKey : ''
            )
            const fetchUserData = async () => {
                try {
                    await Auth.currentAuthenticatedUser()
                    console.log('user logged in already')
                    if (!stringUtils.isNullOrUndefinedOrEmpty(invitationKey)) {
                        navigate('/invitation')
                    } else {
                        localStorage.setItem('loginRoute', 'catalog')
                        navigate('/catalog')
                    }
                } catch {
                    console.log('user not logged in. sign in required!')
                }
            }
            fetchUserData()
        }
    }, [invitationKey, location.pathname])

    useEffect(() => {
        const onWindowScroll = () => {
            const jumpLinkElement = document.getElementById(`jumpLinksDiv`)
            if (jumpLinkElement) {
                if (
                    document.body.scrollTop > 510 ||
                    document.documentElement.scrollTop > 510
                ) {
                    jumpLinkElement.style.position = `fixed`
                } else {
                    jumpLinkElement.style.position = `relative`
                }
            }
        }
        window.addEventListener('scroll', onWindowScroll)
        return () => window.removeEventListener('scroll', onWindowScroll)
    }, [])

    const jumpLinksComponent = (
        <>
            <JumpLink to="#introduction" reloadDocument>
                Introduction
            </JumpLink>
            <JumpLink to="#benefits" reloadDocument>
                Benefits
            </JumpLink>
            <JumpLink to="#features" reloadDocument>
                Features
            </JumpLink>
            {/* <JumpLink to="#benchmarks" reloadDocument>
                Benchmarks
            </JumpLink>
            <JumpLink to="#resources" reloadDocument>
                Resources
            </JumpLink> */}
        </>
    )

    const benefitItems = (label: string, sublabel: string) => (
        <FeatureOptions>
            <BenefitsImgDiv>
                {label === 'Optimized Performance' ? (
                    <img
                        src={StringUtils.resolve('/feature-performance1.png')}
                        width="61px"
                        height="60px"
                        alt="feature perf 1 logo"
                    />
                ) : label === 'Collaborate Securely' ? (
                    <img
                        src={StringUtils.resolve('/feature-performance2.png')}
                        width="52px"
                        height="51px"
                        alt="feature perf 2 logo"
                    />
                ) : label === 'Proven Results' ? (
                    <img
                        src={StringUtils.resolve('/feature-performance3.png')}
                        width="53px"
                        height="52px"
                        alt="feature perf 3 logo"
                    />
                ) : label === 'Easy and Intuitive' ? (
                    <img
                        src={StringUtils.resolve('/feature-performance4.png')}
                        width="51px"
                        height="51px"
                        alt="feature perf 4 logo"
                    />
                ) : (
                    ''
                )}
            </BenefitsImgDiv>
            <FeatureSeprator></FeatureSeprator>
            <FeaturLabelDiv>{label}</FeaturLabelDiv>
            <FeaturSubLabel>{sublabel}</FeaturSubLabel>
        </FeatureOptions>
    )
    const benefitsPanelComponent = (
        <>
            <FeatureDiv>
                {benefitItems(
                    `Optimized Performance`,
                    `Optimizations are based on real-world
                                        engineering with customers from the last
                                        2 years.`
                )}
            </FeatureDiv>
            <FeatureDiv>
                {benefitItems(
                    `Collaborate Securely`,
                    `Share optimizations with your colleagues.  Request new optimizations.`
                )}
            </FeatureDiv>

            <FeatureDiv>
                {benefitItems(
                    `Proven Results`,
                    `Continuous testing, validation, and creation of optimization ‘packages’.`
                )}
            </FeatureDiv>

            <FeatureDiv>
                {benefitItems(
                    `Easy and Intuitive`,
                    `Search optimizations for your workload(s) using everyday language.`
                )}
            </FeatureDiv>
        </>
    )

    const introductionText = `A comprehensive, central repository that stores all the Intel software and hardware optimizations.  Obtain recommendations on hardware accelerators, software builds, open-source libraries and drivers, recipes, and benchmarks based on the workload whether you deploy on-premises or in the cloud.`

    const renderVideo = (
        height: number,
        width: number,
        isMobileRender: boolean
    ) => {
        return (
            <video
                width={width}
                height={height}
                controls
                muted
                preload="auto"
                poster={
                    isMobileRender
                        ? StringUtils.resolve('/video_poster_mobile.png')
                        : StringUtils.resolve('/video_poster.png')
                }
            >
                <source
                    src="https://cdrdv2.intel.com/v1/dl/getContent/818669?fileName=get-powerful-resources-workloads-optimization-hub-video-3.mp4"
                    type="video/mp4"
                ></source>
            </video>
        )
    }
    const renderBenefits = (
        <ContainerWrapper id="benefits">
            <H3Header>Benefits</H3Header>
            <ComponentWrapper>{benefitsPanelComponent}</ComponentWrapper>
        </ContainerWrapper>
    )
    const renderFeatures = (
        <ContainerWrapper style={{ backgroundColor: '#fff' }} id="features">
            <H3Header>Features</H3Header>
            <ComponentWrapper>
                <a href="/public/catalog">
                    <FeatureImgDiv>
                        <FeaturText style={{ width: '80%' }}>
                            Easy to Use Recipes for On-Premises and Cloud
                        </FeaturText>
                    </FeatureImgDiv>
                </a>
                <a href="/public/catalog">
                    <FeatureImgDiv2>
                        <FeaturText>Workload Benchmarks</FeaturText>
                    </FeatureImgDiv2>
                </a>
                <a href="/public/catalog">
                    <FeatureImgDiv3>
                        <FeaturText style={{ width: '80%' }}>
                            Advanced Search by Workload, Cloud Provider, or
                            Technology
                        </FeaturText>
                    </FeatureImgDiv3>
                </a>
                <a href="/public/catalog">
                    <FeatureImgDiv4>
                        <FeaturText>New Intel Software Offerings</FeaturText>
                    </FeatureImgDiv4>
                </a>
            </ComponentWrapper>
        </ContainerWrapper>
    )
    const renderBenchmarks = (
        <ContainerWrapper id="benchmarks">
            <H3Header>Workload Benchmarks</H3Header>
            <FeaturSubLabel>
                Performance considerations are vital for the overall success of
                computing. Discover how workloads will perform on Intel-based
                data center and cloud solutions.
            </FeaturSubLabel>
            <ComponentWrapper>
                <a
                    href="https://www.intel.com/content/www/us/en/developer/topic-technology/cloud/cloud-performance.html?f:@stm_10381_en=%5BAmazon%20Web%20Services%5D"
                    target="_blank"
                >
                    <CSPWrapper>
                        <img
                            src={StringUtils.resolve('/AWS_home.png')}
                            width="100%"
                            alt="AWS logo"
                        />
                    </CSPWrapper>
                </a>
                <a
                    href="https://www.intel.com/content/www/us/en/developer/topic-technology/cloud/cloud-performance.html?f:@stm_10381_en=%5BGoogle%20Alphabet%5D"
                    target="_blank"
                >
                    <CSPWrapper>
                        <img
                            src={StringUtils.resolve('/Google_home.png')}
                            width="100%"
                            alt="Google logo"
                        />
                    </CSPWrapper>
                </a>
                <a
                    href="https://www.intel.com/content/www/us/en/developer/topic-technology/cloud/cloud-performance.html?f:@stm_10381_en=%5BMicrosoft%5D"
                    target="_blank"
                >
                    <CSPWrapper>
                        <img
                            src={StringUtils.resolve('/Azure_home.png')}
                            width="100%"
                            alt="Azure logo"
                        />
                    </CSPWrapper>
                </a>
                <a
                    href="https://www.intel.com/content/www/us/en/developer/topic-technology/cloud/cloud-performance.html"
                    target="_blank"
                >
                    <CSPWrapper>
                        <img
                            src={StringUtils.resolve(
                                '/other_workloads_home.png'
                            )}
                            width="100%"
                            alt="All workloads logo"
                        />
                        <FeaturSubLabel
                            style={{ width: '50%', marginTop: '-2rem' }}
                        >
                            All Other Workloads
                        </FeaturSubLabel>
                    </CSPWrapper>
                </a>
            </ComponentWrapper>
            <ComponentWrapper style={{ paddingBottom: '1rem' }}>
                <ButtonComponent
                    href="https://www.intel.com/content/www/us/en/developer/topic-technology/cloud/cloud-performance.html"
                    target="_blank"
                >
                    View all benchmarks
                </ButtonComponent>
            </ComponentWrapper>
        </ContainerWrapper>
    )
    const renderResources = (
        <ContainerWrapper style={{ backgroundColor: '#fff' }} id="resources">
            <ComponentWrapper>
                <ResourcesWrapper>
                    <ResourcesDiv>
                        <H3Header style={{ fontSize: '36px' }}>
                            Accelerated Computing Solutions
                        </H3Header>
                        <ResourcesText>
                            Take advantage of built-in workload acceleration
                            features to deliver optimized performance without
                            the need for specialized hardware
                        </ResourcesText>
                    </ResourcesDiv>
                    <ButtonComponent
                        href="https://www.intel.com/content/www/us/en/products/docs/accelerator-engines/overview.html"
                        target="_blank"
                    >
                        Learn More
                    </ButtonComponent>
                </ResourcesWrapper>
                <ResourcesWrapper>
                    <ResourcesDiv>
                        <H3Header style={{ fontSize: '36px' }}>
                            AI Frameworks
                        </H3Header>
                        <ResourcesText>
                            Realize performance gas ranging up to 10x to 100x
                            for popular deep learning and machine learning
                            frameworks through drop-in Intel optimizaitions
                        </ResourcesText>
                    </ResourcesDiv>

                    <ButtonComponent
                        href="intel.com/content/www/us/en/developer/tools/frameworks/overview.html"
                        target="_blank"
                    >
                        Learn More
                    </ButtonComponent>
                </ResourcesWrapper>
                <ResourcesWrapper>
                    <ResourcesDiv>
                        <H3Header style={{ fontSize: '36px' }}>
                            Technical Docs
                        </H3Header>
                        <ResourcesText>
                            Find deployment and design guides, reference
                            architectures, whitepapers, and more. Read Technical
                            Documentation
                        </ResourcesText>
                    </ResourcesDiv>
                    <ButtonComponent
                        href="https://www.intel.com/content/www/us/en/developer/topic-technology/cloud/library.html"
                        target="_blank"
                    >
                        Complete Library
                    </ButtonComponent>
                </ResourcesWrapper>
                <ResourcesWrapper>
                    <ResourcesDiv>
                        <H3Header style={{ fontSize: '36px', width: '80%' }}>
                            Tech Blog and News
                        </H3Header>
                        <ResourcesText>
                            Get the latest news and blogs on everything
                        </ResourcesText>
                    </ResourcesDiv>
                    <ButtonComponent
                        href="https://community.intel.com/t5/Blogs/Tech-Innovation/Cloud/bg-p/blog-cloud"
                        target="_blank"
                    >
                        Read the Blogs
                    </ButtonComponent>
                </ResourcesWrapper>
            </ComponentWrapper>
        </ContainerWrapper>
    )

    const additionalResButtonComponent = (
        <ComponentWrapper
            style={{ paddingBottom: '2rem', backgroundColor: '#fff' }}
        >
            <ButtonComponent
                onClick={() => setIsExpandResources(!isExpandResources)}
            >
                Additional Resources
                {isExpandResources ? (
                    <i className="uil uil-angle-double-up"></i>
                ) : (
                    <i className="uil uil-angle-double-down"></i>
                )}
            </ButtonComponent>
        </ComponentWrapper>
    )

    return (
        <HomePageMainContainer>
            <HomePageContainer>
                <HomePanelHeader></HomePanelHeader>
                <TopBanner></TopBanner>
                <JumpLinksWrapper id="jumpLinksDiv">
                    <JumpLinksHeader>{jumpLinksComponent} </JumpLinksHeader>
                    {/* <ContactUsButton href="mailto:optimizations@intel.com">
                        Stay Informed
                    </ContactUsButton> */}
                </JumpLinksWrapper>
                <IntroductionContainer id="introduction">
                    <IntroComponent>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'left',
                                width: '500px',
                            }}
                        >
                            <H3Header
                                style={{ color: '#653171', fontSize: '24px' }}
                            >
                                What is the Intel Optimization Hub?
                            </H3Header>
                        </div>
                        <FeaturSubLabel
                            style={{ textAlign: 'left', width: '500px' }}
                        >
                            {introductionText}
                        </FeaturSubLabel>
                    </IntroComponent>
                    <VideoComponent>
                        {renderVideo(334, 557, false)}
                    </VideoComponent>
                </IntroductionContainer>
                {renderBenefits}
                {renderFeatures}
                {additionalResButtonComponent}

                {/* {renderBenchmarks}*/}
                {isExpandResources ? renderResources : ''}
            </HomePageContainer>
            <MobileContainer>
                <HomePageVerticalContainer>
                    <MobileHeaderWrapper>
                        <IntelLogoStyling>
                            <a
                                href="https://www.intel.com/content/www/us/en/homepage.html"
                                title="Intel homepage"
                                style={{ padding: '0 0.5rem' }}
                            >
                                <img
                                    src={StringUtils.resolve('/IntelLogo.png')}
                                    height="40"
                                    width="96"
                                    title="Intel logo - Return to the home page"
                                />
                            </a>
                        </IntelLogoStyling>
                        <MobileHeader />
                    </MobileHeaderWrapper>
                    <IntroductionContainer>
                        <VideoComponent style={{ minHeight: '250px' }}>
                            {renderVideo(180, 256, true)}
                        </VideoComponent>
                        <IntroComponent style={{ paddingTop: '0.2rem' }}>
                            <H3Header
                                style={{
                                    color: '#653171',
                                    fontSize: '20px',
                                }}
                            >
                                What is the Intel Optimization Hub?
                            </H3Header>

                            <FeaturSubLabel
                                style={{
                                    textAlign: 'center',
                                    width: 'fit-content',
                                }}
                            >
                                {introductionText}
                            </FeaturSubLabel>
                        </IntroComponent>
                    </IntroductionContainer>

                    <ContainerWrapper>
                        <H3Header>Benefits</H3Header>
                        <ComponentWrapper>
                            {benefitsPanelComponent}
                        </ComponentWrapper>
                    </ContainerWrapper>

                    {renderFeatures}
                    {additionalResButtonComponent}
                    {/* {renderBenchmarks}*/}
                    {isExpandResources ? renderResources : ''}
                </HomePageVerticalContainer>
            </MobileContainer>
            <HomePageFooter>
                <FooterPanel></FooterPanel>
            </HomePageFooter>
        </HomePageMainContainer>
    )
}

export default HomePanel
