export class NewBenchmarkRequest {
    public existingBenchmark: string = ''
    public name: string = ''
    public version: string = ''
    public targetHardware: string = ''
    public customer: string = ''
    public businessImpact: string = ''
    public dueDate: string = ''
    public businessUnit: string = ''
    public requester: string = ''
    public testCase: string = ''
    public softwareComponents: string = ''
    public configuration: string = ''
    public KPI: string = ''
    public source: string = ''
    public contact: string = ''
    public comments: string = ''
}
