import { API_SERVICE_URL } from '../config/service'

import api from './auth/client'

const serviceConfig = require('../config/service.json')

interface TimeObj {
    fromDate: string
    toDate: string
}
class AnalyticsService {
    getDashboardData(args: any) {
        const { fromDate, toDate, customers } = args
        let url = `${API_SERVICE_URL}${serviceConfig.dashboard}?from=${fromDate}`
        if (toDate) url = `${url}&toDate=${toDate}`
        if (customers) url = `${url}&accesscontrol=${customers.join(',')}`
        return api.get(url)
    }

    getAnalytics(args: TimeObj) {
        const { fromDate, toDate } = args
        let url = `${API_SERVICE_URL}${serviceConfig.analyticsURL}/searchdist/v2?fromDate=${fromDate}`
        if (toDate) url = `${url}&to=${toDate}`
        return api.get(url)
    }

    getSearchDist(args: any) {
        const { fromDate, toDate, filterObj } = args
        var url = `${API_SERVICE_URL}${serviceConfig.analyticsURL}/worddist?fromDate=${fromDate}`
        if (toDate) url = `${url}&to=${toDate}`
        if (filterObj) {
            const { type, domain, excludeWords } = filterObj
            if (type && type !== 'all') url = `${url}&type=${type}`
            if (domain) url = `${url}&domain=${domain}`
            if (excludeWords?.length > 0) url = `${url}&exclude=${excludeWords}`
        }
        return api.get(url)
    }

    getUniqueLogins(fromDate: string) {
        const url = `${API_SERVICE_URL}${serviceConfig.analyticsURL}/uniquelogins?&from=${fromDate}`
        return api.get(url)
    }

    getUniqueLoginsWithToDate(timeObj: TimeObj) {
        const { fromDate, toDate } = timeObj
        const url = `${API_SERVICE_URL}${serviceConfig.analyticsURL}/uniquelogins?from=${fromDate}&to=${toDate}`
        return api.get(url)
    }

    getNoHits(timeObj: TimeObj) {
        const { fromDate, toDate } = timeObj
        let url = `${API_SERVICE_URL}${serviceConfig.analyticsURL}/search/nohits?from=${fromDate}`
        if (toDate) url = `${url}&to=${toDate}`
        return api.get(url)
    }

    getAllNoHits(timeObj: TimeObj) {
        const { fromDate, toDate } = timeObj
        let url = `${API_SERVICE_URL}${serviceConfig.analyticsURL}/search/nohits/domain?from=${fromDate}`
        if (toDate) url = `${url}&to=${toDate}`
        return api.get(url)
    }

    getTotalHits(timeObj: TimeObj) {
        const { fromDate, toDate } = timeObj
        let url = `${API_SERVICE_URL}${serviceConfig.analyticsURL}/search/hits/total?from=${fromDate}`
        if (toDate) url = `${url}&to=${toDate}`
        return api.get(url)
    }

    getTotalNoHits(timeObj: TimeObj) {
        const { fromDate, toDate } = timeObj
        let url = `${API_SERVICE_URL}${serviceConfig.analyticsURL}/search/nohits/total?from=${fromDate}`
        if (toDate) url = `${url}&to=${toDate}`
        return api.get(url)
    }

    getTopClickCounts(args: any) {
        const { fromDate, toDate, records } = args
        let url = `${API_SERVICE_URL}${serviceConfig.analyticsURL}/clickcounts/top/link?records=${records}`
        if (fromDate) url = `${url}&from=${fromDate}`
        if (toDate) url = `${url}&to=${toDate}`

        return api.get(url)
    }

    getClickCounts(args: any) {
        const { fromDate, toDate, recipeId } = args
        let url = `${API_SERVICE_URL}${serviceConfig.analyticsURL}/clickcounts?optimization=${recipeId}`
        if (fromDate) url = `${url}&from=${fromDate}`
        if (toDate) url = `${url}&to=${toDate}`

        return api.get(url)
    }
    getCardsActivityDist(args: any) {
        const { fromDate, toDate, filterObj } = args
        var url = `${API_SERVICE_URL}${serviceConfig.dashboardCardsActivityDist}?from=${fromDate}`
        if (toDate) url = `${url}&to=${toDate}`
        if (filterObj) {
            const { limit, excludeWords } = filterObj
            if (limit) url = `${url}&limit=${limit}`
            if (excludeWords?.length > 0)
                url = `${url}&exclude-domains=${excludeWords}`
        }
        return api.get(url)
    }

    getEngagedUsers(args: any) {
        const { fromDate: from, toDate: to } = args
        const url = `${API_SERVICE_URL}${serviceConfig.analyticsURL}/engaged`
        return api.get(url, {
            params: {
                'source-app': 'optimization-registry',
                from,
                to,
            },
        })
    }

    getDisengagedUsers(args: any) {
        const { fromDate: from, toDate: to } = args
        const url = `${API_SERVICE_URL}${serviceConfig.analyticsURL}/disengaged`
        return api.get(url, {
            params: {
                'source-app': 'optimization-registry',
                from,
                to,
            },
        })
    }

    getFirstTimeCustomers(args: any) {
        const { fromDate: from, toDate: to } = args
        const url = `${API_SERVICE_URL}${serviceConfig.analyticsURL}/first-time-customers`
        return api.get(url, {
            params: {
                'source-app': 'optimization-registry',
                from,
                to,
            },
        })
    }
}

export default new AnalyticsService()
