import styled from 'styled-components'
import { _getDecryptedValueFromStorage } from '../../lib/encryptStorage'
import { WorkloadsContainerLogic } from './workloadsContainer.hook'
import { useParams } from 'react-router-dom'
import AllWorkloads from '../../components/benchmarkPanel/allWorkloads'
import WorkloadDetails from '../../components/benchmarkPanel/workloadDetails'
import FooterPanel from '../../layout/footer'

const WorkloadContainer = styled.div`
    display: flex;
    height: ${(props) => props.theme.size.mainContainerHeight};
    width: ${(props) => props.theme.size.mainContainerWidth};

    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: space-between;
    @media screen and (min-width: 80rem) {
        overflow-x: hidden;
    }
    @media screen and (max-width: 500px) {
        width: 100%;
        height: 100%;
    }
`

const WorkloadHorizontalContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: space-between;
`
const WorkloadBodyContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: center;
    background-color: ${(props) => props.theme.color.background.defaultGrey};
    // padding-bottom: 10rem;
    width: 100%;
`

const WorkloadBodyContainerReposition = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
`
const WorkloadDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
`
const WorkloadWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    flex-wrap: no-wrap;

    background: #ffffff;
`

const WorkloadsContainer = () => {
    const {} = WorkloadsContainerLogic()
    let { workloadId } = useParams()

    return (
        <WorkloadContainer>
            <WorkloadHorizontalContainer>
                <WorkloadBodyContainer>
                    <WorkloadBodyContainerReposition>
                        <WorkloadDetailsContainer>
                            <WorkloadWrapper>
                                {workloadId ? (
                                    <WorkloadDetails></WorkloadDetails>
                                ) : (
                                    <AllWorkloads></AllWorkloads>
                                )}
                            </WorkloadWrapper>
                        </WorkloadDetailsContainer>
                    </WorkloadBodyContainerReposition>
                </WorkloadBodyContainer>
            </WorkloadHorizontalContainer>
            <FooterPanel></FooterPanel>
        </WorkloadContainer>
    )
}

export default WorkloadsContainer
