export enum ModelType {
    Trending = 'chat',
    Compare = 'compare',
    Visual = 'visual',
    SummaryDoc = 'summarization',
    Translation = 'translation',
    Retrieval = 'retrieval',
}

export enum ModelSubType {
    textToImage = 'TextToImage',
    imageToText = 'ImageToText',
    imageToVideo = 'ImageToVideo',
    WithDataInputFile = 'WithDataInputFile',
    videoToTextSearch = 'VideoToTextSearch',
    vectorDb = 'vectordb',
    Llms = 'llm',
    none = 'none',
}
