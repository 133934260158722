import { API_SERVICE_URL } from '../config/service'
import { CreateOrgRequest } from '../models/createOrgRequest'
import api from './auth/client'

const serviceConfig = require('../config/service.json')

class CreateOrgService {
    CreateOrg(createOrgRequest: CreateOrgRequest) {
        var url = API_SERVICE_URL + serviceConfig.createOrgURL
        return api.post(url, createOrgRequest)
    }
}

export default new CreateOrgService()
