import { ISpacing } from '../spacing'

const spacing: ISpacing = {
    default: '0.5rem',
    defaultLarge: '1rem',
    button: {
        padding: {
            small: '0.438rem 0.625rem',
            medium: '0.5rem 1rem',
            large: '0.5rem 2rem',
        },
    },
}

export { spacing }
