import { ChangeEvent, FC, useEffect, useState } from 'react'
import { useAppSelector } from '../../../../config/hooks'
import DashboardCard from '../../shared/cards/DashboardCard'
import {
    Box,
    Checkbox,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Select,
    SelectChangeEvent,
    Skeleton,
    Stack,
    Typography,
} from '@mui/material'
import { LoadingState } from '../../../../models/loadingState'
import { colors } from '../../../../utils/colors'
import ColumnChart, { ColumnChartData } from '../../shared/charts/ColumnChart'
import TopSearchesByDomain from './TopSearchesByDomain'
import TopSearchMissesByDomain from './TopSearchMissesByDomain'
import { defaultSelectedDomainSearchDist } from '../../DashboardPanel'
import SearchMissesByDomain from './SearchMissesByDomain'

const height = 400

const chartTitleSx = { marginLeft: '1rem', marginBlock: '0.5rem' }

const searchesByDomainCardGridSx = { paddingRight: '1rem' }

const domainsListTitleSx = { marginBlock: '0.5rem', fontWeight: 500 }

const domainsListSx = {
    padding: 0,
    maxHeight: '332px',
    overflowX: 'hidden',
    scrollbarWidth: 'thin',
    scrollbarColor: `${colors.geode} ${colors.lightGray200}`,
}

const domainsListItemSx = { padding: 0 }

const searchAnalyticsGridSx = { marginLeft: '1rem' }

const searchAnalyticsHeaderSx = { marginBlock: '1rem' }

const searchAnalyticsTitleSx = {
    display: 'inline',
    marginRight: '0.375rem',
    fontWeight: 500,
}

const searchAnalyticsDomainSelectSx = {
    '& .MuiInputBase-input': {
        fontSize: '1rem',
    },
}

const searchAnalyticsDomainSelectMenuProps = {
    sx: {
        '& .MuiMenu-list': {
            padding: 0,
        },
    },
}

const ListSkeletonStack = () => (
    <Stack spacing={1}>
        <Skeleton variant="rectangular" animation="wave" height={30} />
        <Skeleton variant="rectangular" animation="wave" height={30} />
        <Skeleton variant="rectangular" animation="wave" height={30} />
        <Skeleton variant="rectangular" animation="wave" height={30} />
        <Skeleton variant="rectangular" animation="wave" height={30} />
        <Skeleton variant="rectangular" animation="wave" height={30} />
        <Skeleton variant="rectangular" animation="wave" height={30} />
    </Stack>
)

const searchesByDomainChartDataHeaders: [string, string, { role: string }] = [
    'Domain',
    '# Searches',
    { role: 'annotation' },
]

type DomainListItem = {
    name: string
    checked: boolean
}

interface SearchesByDomainCardProps {
    selectedDomainSearchDist: string
    handleSelectedDomainSearchDistChange: (event: SelectChangeEvent) => void
}

const SearchesByDomainCard: FC<SearchesByDomainCardProps> = ({
    selectedDomainSearchDist,
    handleSelectedDomainSearchDistChange,
}) => {
    const analyticsResultData = useAppSelector<any>(
        (state) => state.getAnalyticsResult.data
    )
    const analyticsLoading = useAppSelector(
        (state) => state.getAnalyticsResult.loading
    )
    const searchDistLoading = useAppSelector<any>(
        (state) => state.getSearchDistResult.loading
    )
    const missesByDomainLoading = useAppSelector(
        (state) => state.getNoHitsResult.loading
    )
    const allMissesLoading = useAppSelector<any>(
        (state) => state.getAllNoHitsResult.loading
    )

    const [chartData, setChartData] = useState<ColumnChartData>([
        searchesByDomainChartDataHeaders,
    ])
    const [domainsList, setDomainsList] = useState<DomainListItem[]>([])

    useEffect(() => {
        if (analyticsResultData !== null) {
            const chartDataItems = Object.entries(
                analyticsResultData as { [key: string]: number }
            ).map(([key, value]) => [key, value, value])
            setChartData([searchesByDomainChartDataHeaders, ...chartDataItems])
            setDomainsList(
                Object.keys(analyticsResultData).map((name) => ({
                    name,
                    checked: true,
                }))
            )
        }
    }, [analyticsResultData])

    useEffect(() => {
        if (analyticsResultData !== null) {
            const checkedDomainsNames = domainsList
                .filter(({ checked }) => checked)
                .map(({ name }) => name)
            const filteredAnalyticsResultData = Object.entries(
                analyticsResultData as { [key: string]: number }
            )
                .filter(([name, _]) => checkedDomainsNames.includes(name))
                .map(([key, value]) => [key, value, value])
            setChartData([
                searchesByDomainChartDataHeaders,
                ...filteredAnalyticsResultData,
            ])
        }
    }, [domainsList])

    const onDomainListItemChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { id, checked } = event.target
        setDomainsList((prevList) =>
            prevList.map((item) =>
                item.name === id ? { ...item, checked } : item
            )
        )
    }

    const domainsListItems = domainsList.map(({ name, checked }) => (
        <ListItem sx={domainsListItemSx} key={`li-${name}`}>
            <Checkbox
                size="small"
                checked={checked}
                id={name}
                onChange={onDomainListItemChange}
            />
            <ListItemText primary={name} />
        </ListItem>
    ))

    const domainsMenuItems = domainsList.map(({ name }) => (
        <MenuItem value={name} key={`mi-${name}`}>
            {name}
        </MenuItem>
    ))

    const isAnalyticsDataLoading = analyticsLoading === LoadingState.Pending

    const loadingStates = [
        analyticsLoading,
        searchDistLoading,
        missesByDomainLoading,
        allMissesLoading,
    ]

    const isDomainSearchDistSelectDisabled = loadingStates.some(
        (state) => state === LoadingState.Pending
    )

    return (
        <DashboardCard>
            <Typography variant="h6" sx={chartTitleSx}>
                Searches By Domain
            </Typography>
            <Grid container spacing={2} sx={searchesByDomainCardGridSx}>
                <Grid item xs={12} md={9} sx={{ height: `${height + 24}px` }}>
                    <ColumnChart
                        chartData={chartData}
                        dataLoadingState={analyticsLoading}
                        height={height}
                    />
                </Grid>
                <Grid item md={3}>
                    <Box display={{ xs: 'none', sm: 'none', md: 'block' }}>
                        <Typography variant="body2" sx={domainsListTitleSx}>
                            Domains
                        </Typography>
                        {isAnalyticsDataLoading ? (
                            <ListSkeletonStack />
                        ) : (
                            <List dense sx={domainsListSx}>
                                {domainsListItems}
                            </List>
                        )}
                    </Box>
                </Grid>
                <Grid
                    item
                    container
                    columns={15}
                    xs={15}
                    spacing={2}
                    sx={searchAnalyticsGridSx}
                >
                    <Grid item xs={15}>
                        <Box sx={searchAnalyticsHeaderSx}>
                            <Typography sx={searchAnalyticsTitleSx}>
                                Search Analytics for
                            </Typography>
                            <Select
                                variant="standard"
                                value={selectedDomainSearchDist}
                                sx={searchAnalyticsDomainSelectSx}
                                MenuProps={searchAnalyticsDomainSelectMenuProps}
                                disabled={isDomainSearchDistSelectDisabled}
                                onChange={handleSelectedDomainSearchDistChange}
                            >
                                <MenuItem
                                    value={defaultSelectedDomainSearchDist}
                                >
                                    All Domains
                                </MenuItem>
                                {domainsMenuItems}
                            </Select>
                        </Box>
                    </Grid>
                    <Grid item xs={15} md={7}>
                        <TopSearchesByDomain />
                    </Grid>
                    <Grid item xs={false} md={1}>
                        <Divider orientation="vertical" />
                    </Grid>
                    <Grid item xs={15} md={7}>
                        <TopSearchMissesByDomain
                            selectedDomainSearchDist={selectedDomainSearchDist}
                        />
                    </Grid>
                    <Grid item xs={15}>
                        <Box display={{ xs: 'none', sm: 'none', md: 'block' }}>
                            <Divider />
                        </Box>
                    </Grid>
                    <Grid item xs={15}>
                        <SearchMissesByDomain
                            selectedDomainSearchDist={selectedDomainSearchDist}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </DashboardCard>
    )
}

export default SearchesByDomainCard
