/************************************************
 * Copyright (C) 2021 Intel Corporation
 ************************************************/
import React, { useRef } from 'react'
import styled from 'styled-components'
import { FunctionComponent, Dispatch } from 'react'
import { connect } from 'react-redux'
import FooterPanel from '../../layout/footer'
import SalesHeaderPanel from '../../components/benchmarkPanel/salesHeader'

import { Button, Spinner } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { getWorkloads } from '../../store/benchmark.slice'
import { BenchmarkDevHomeContainerLogic } from './benchmarkDevHomeContainer.hook'
import { useAppSelector } from '../../config/hooks'
import { LoadingState } from '../../models/loadingState'
import BenchmarkCardPanel from '../../components/benchmarkPanel/benchmarkCardPanel'
import NoResultFoundPanel from '../../components/benchmarkPanel/noResultFound'
import WorkloadBubble from '../../components/benchmarkPanel/workloadBubble'

const BenchmarkPanelContainer = styled.div`
    display: flex;
    height: ${(props) => props.theme.size.mainContainerHeight};
    width: ${(props) => props.theme.size.mainContainerWidth};

    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: space-between;
    @media screen and (min-width: 80rem) {
        overflow-x: hidden;
    }
    @media screen and (max-width: 500px) {
        width: 100%;
        height: 100%;
    }
`

const BenchmarkHorizontalContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: space-between;
`
const BenchmarkBodyContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: center;
    background-color: ${(props) => props.theme.color.background.defaultGrey};
    width: 100%;
`

const BenchmarkBodyContainerReposition = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
`
const BenchmarkDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
`
const BenchmarkDetailsWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    flex-wrap: no-wrap;

    background: #ffffff;
`
const RecentSearchContainer = styled.div`
    width: 100%;
    height: auto;
    flex-wrap: wrap;
    padding-top: 2rem;
    background: var(--gray-75, #f9f9f9);
`

const RecentSearchProductImageDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 1.5rem;
    margin-left: 1.5rem;
    text-align: center;
    cursor: pointer;
`
const WorkloadResults = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
`
const NextButton = styled.button`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 2.25rem;
    height: 2.25rem;
    padding: 10px;
    gap: 10px;
    border-radius: 18px;
    background: var(--black-and-white-white, #fff);
    //border-color: #0054ae;
    cursor: pointer;
    tabindex: 0;
    border: none;
    &:hover {
        color: #00a3fb;
    }
    &:focus {
        color: #00a3fb;
        //border: 1px solid transparent;
        outline: auto;
    }
`
const ScrollToTopButton = styled(Button)`
    background: #f9f9f9;
    cursor: pointer;
    color: #00377c;
    content: unset !important;
    border: 1px solid #00377c;
    width: 10rem;
    &:hover {
        background: #f9f9f9;
        border: 1px solid #00377c;
        color: #00377c;
    }
    &:focus {
        background: #f9f9f9;
        border: 1px solid #00377c;
        color: #00377c;
    }
`
const NotAutorizeContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: left;
    margin: 2rem;
`
const SpinnerDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5rem;
    width: 100%;
`
const BenchmarkFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
    background: #f9f9f9;
`
const PreviousButton = styled(NextButton)``

export interface IBenchmarkHomeContainerProps {
    SearchComponentRef?: any
    countRef?: any
}

const BenchmarkDevHomeContainer: FunctionComponent<IBenchmarkHomeContainerProps> & {
    defaultProps: Partial<IBenchmarkHomeContainerProps>
} = ({}: IBenchmarkHomeContainerProps) => {
    let isPublicSearch = false
    let path = `${window.location.pathname}`
    if (path && path.indexOf('/public/catalog') > -1) {
        isPublicSearch = true
    } else isPublicSearch = false
    const navigate = useNavigate()
    const SearchComponentRef = useRef()
    const countRef = useRef(null)

    const {
        onSearchKeyChange,
        onFilterClick,
        onFilterClearAllClick,
        onSearchKeydown,
        onWorkloadClick,
        onNextNavigationClick,
        onPreviousNavigationClick,
        onPageScrollClick,
        onAccessApplyChange,
        onBenchmarkTabClick,
        onAfterPageClicked,
        onPageCountChange,
        categoryDisplayGroup,
        onCategoryNextClick,
    } = BenchmarkDevHomeContainerLogic({
        SearchComponentRef,
        countRef,
        //filterArray,
    } as IBenchmarkHomeContainerProps)

    const workloadsResults = useAppSelector<any>(
        (state: any) => state.getWorkloadsResult
    )

    const authPermissionsResult = useAppSelector<any>(
        (state: any) => state.authPermissionsResult.data
    )
    const authPermissionsLoading = useAppSelector<any>(
        (state: any) => state.authPermissionsResult.loading
    )
    const defaultWorkloadsResult = useAppSelector<any>(
        (state: any) => state.getDefaultWorkloadsResult.data
    )
    let isIntelEmployee = false
    if (authPermissionsResult && authPermissionsResult?.isIntelEmployee) {
        isIntelEmployee = authPermissionsResult?.isIntelEmployee
    }

    const workloadResults = (
        <BenchmarkFormContainer style={{ display: 'flex' }}>
            {workloadsResults && authPermissionsResult && (
                <BenchmarkCardPanel
                    getWorkloads={getWorkloads}
                    getWorkloadsLoading={workloadsResults.loadingState}
                    getWorkloadsError={workloadsResults.error}
                    getWorkloadsResult={workloadsResults.data}
                    urlNavigation={'home'}
                    onTabClickCallback={onBenchmarkTabClick}
                    paginationPageClickCallback={onAfterPageClicked}
                    pageCountChangeCallback={onPageCountChange}
                ></BenchmarkCardPanel>
            )}
        </BenchmarkFormContainer>
    )

    return (
        <BenchmarkPanelContainer>
            <BenchmarkHorizontalContainer>
                {authPermissionsLoading !== LoadingState.Pending ? (
                    <BenchmarkBodyContainer>
                        {/* {isIntelEmployee ? ( */}
                        <BenchmarkBodyContainerReposition>
                            <BenchmarkDetailsContainer>
                                <SalesHeaderPanel
                                    onSearchKeyChange={onSearchKeyChange}
                                    onSearchKeyDown={onSearchKeydown}
                                    SearchComponentRef={SearchComponentRef}
                                    onApplyFilterCallback={onFilterClick}
                                    onClearAllCallback={onFilterClearAllClick}
                                    onAccessChangeCallback={onAccessApplyChange}
                                />

                                <BenchmarkDetailsWrapper>
                                    <RecentSearchContainer>
                                        <WorkloadResults>
                                            <RecentSearchProductImageDiv>
                                                {defaultWorkloadsResult
                                                    ?.pageInfo?.total > 6 ? (
                                                    <PreviousButton
                                                        type="button"
                                                        onClick={(evt: any) =>
                                                            onPreviousNavigationClick(
                                                                evt
                                                            )
                                                        }
                                                        disabled={
                                                            defaultWorkloadsResult
                                                                ?.pageInfo
                                                                ?.next > 6 ||
                                                            (defaultWorkloadsResult
                                                                ?.pageInfo
                                                                ?.total > 6 &&
                                                                defaultWorkloadsResult
                                                                    ?.pageInfo
                                                                    ?.total ===
                                                                    defaultWorkloadsResult
                                                                        ?.pageInfo
                                                                        ?.next)
                                                                ? false
                                                                : true
                                                        }
                                                        style={{
                                                            margin: '3rem 0 0 1rem',
                                                        }}
                                                        title="Previous"
                                                    >
                                                        {defaultWorkloadsResult
                                                            ?.pageInfo?.next >
                                                            6 ||
                                                        (defaultWorkloadsResult
                                                            ?.pageInfo?.total >
                                                            6 &&
                                                            defaultWorkloadsResult
                                                                ?.pageInfo
                                                                ?.total ===
                                                                defaultWorkloadsResult
                                                                    ?.pageInfo
                                                                    ?.next) ? (
                                                            <img src="/sales_img/chevron-left.svg" />
                                                        ) : (
                                                            <img src="/sales_img/chevron-left-dis.svg" />
                                                        )}
                                                    </PreviousButton>
                                                ) : (
                                                    ''
                                                )}
                                            </RecentSearchProductImageDiv>
                                            {defaultWorkloadsResult &&
                                            defaultWorkloadsResult?.workloads &&
                                            defaultWorkloadsResult?.workloads
                                                ?.length > 0
                                                ? defaultWorkloadsResult?.workloads?.map(
                                                      (workload: any) => (
                                                          <WorkloadBubble
                                                              hrefValue={`/allWorkloads/${workload.uuid}?isDevView=true`}
                                                              uuid={
                                                                  workload.uuid
                                                              }
                                                              iconURL={
                                                                  workload.iconURL
                                                              }
                                                              name={
                                                                  workload.workload
                                                              }
                                                              isWinningWorkload={
                                                                  false
                                                              }
                                                          />
                                                      )
                                                  )
                                                : ''}
                                            <RecentSearchProductImageDiv>
                                                {defaultWorkloadsResult
                                                    ?.pageInfo?.total > 6 ? (
                                                    <NextButton
                                                        type="button"
                                                        onClick={(evt: any) =>
                                                            onNextNavigationClick(
                                                                evt
                                                            )
                                                        }
                                                        disabled={
                                                            defaultWorkloadsResult
                                                                ?.pageInfo
                                                                ?.total > 6 &&
                                                            defaultWorkloadsResult
                                                                ?.pageInfo
                                                                ?.total !==
                                                                defaultWorkloadsResult
                                                                    ?.pageInfo
                                                                    ?.next
                                                                ? false
                                                                : true
                                                        }
                                                        title="Next"
                                                        style={{
                                                            margin: '3rem 0px 0px 1rem',
                                                        }}
                                                    >
                                                        {defaultWorkloadsResult
                                                            ?.pageInfo?.total >
                                                            6 &&
                                                        defaultWorkloadsResult
                                                            ?.pageInfo
                                                            ?.total !==
                                                            defaultWorkloadsResult
                                                                ?.pageInfo
                                                                ?.next ? (
                                                            <img src="/sales_img/chevron-right.svg" />
                                                        ) : (
                                                            <img src="/sales_img/chevron-right-dis.svg" />
                                                        )}
                                                    </NextButton>
                                                ) : (
                                                    ''
                                                )}
                                                <div
                                                    style={{
                                                        margin: '3.2rem 0 0 1rem',
                                                    }}
                                                >
                                                    {defaultWorkloadsResult &&
                                                    defaultWorkloadsResult?.workloads &&
                                                    defaultWorkloadsResult
                                                        .workloads.length === 0
                                                        ? NoResultFoundPanel()
                                                        : ''}
                                                    <a
                                                        onClick={() =>
                                                            navigate(
                                                                '/allWorkloads?isDevView=true'
                                                            )
                                                        }
                                                        style={{
                                                            textDecoration:
                                                                'underline',
                                                            cursor: 'pointer',
                                                            color: 'var(--button-light-action-default, #0054AE)',
                                                        }}
                                                        tabIndex={0}
                                                        ref={countRef}
                                                    >
                                                        View All
                                                    </a>
                                                </div>
                                            </RecentSearchProductImageDiv>
                                        </WorkloadResults>
                                    </RecentSearchContainer>
                                    {workloadResults}
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            background: '#f9f9f9',
                                            padding: '2rem 0.5rem',
                                        }}
                                    >
                                        <ScrollToTopButton
                                            onClick={onPageScrollClick}
                                        >
                                            Scroll to top
                                        </ScrollToTopButton>
                                    </div>
                                </BenchmarkDetailsWrapper>
                            </BenchmarkDetailsContainer>
                        </BenchmarkBodyContainerReposition>
                        {/* ) : (
                            <NotAutorizeContainer>
                                <NotAutorizeLabel>
                                    Permission denied!
                                </NotAutorizeLabel>
                            </NotAutorizeContainer>
                        )} */}
                    </BenchmarkBodyContainer>
                ) : (
                    <SpinnerDiv>
                        <Spinner animation="border" variant="dark">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </SpinnerDiv>
                )}
            </BenchmarkHorizontalContainer>
            <FooterPanel></FooterPanel>
        </BenchmarkPanelContainer>
    )
}

BenchmarkDevHomeContainer.defaultProps = {}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {}
}

const mapStateToProps = (state: any) => {
    return {}
}

type StateToPropsType = ReturnType<typeof mapStateToProps>
type DispatchToPropsType = typeof mapDispatchToProps

export default connect<StateToPropsType, DispatchToPropsType>(
    mapStateToProps,
    mapDispatchToProps
)(BenchmarkDevHomeContainer)
