/************************************************
 * Copyright (C) 2021 Intel Corporation
 ************************************************/
import styled from 'styled-components'
import {
    Breadcrumb,
    Button,
    FormControl,
    FormLabel,
    InputGroup,
    Spinner,
} from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { AllWorkloadsLogic } from './allWorkloadsLogic.hook'
import { useAppDispatch, useAppSelector } from '../../config/hooks'
import { LoadingState } from '../../models/loadingState'
import SalesHeaderPanel from './salesHeader'
import SalesFiltersPanel from './salesFilters'
import NoResultFoundPanel from './noResultFound'
import StringUtils from '../../lib/stringUtils'
import WorkloadBubble from './workloadBubble'

const AllWorkloadsWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    flex-wrap: no-wrap;
    background: #ffffff;
`
const AllWorkloadsContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    flex-wrap: no-wrap;
`

const BenchmarkSearchContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    width: 100%;
    padding: 2rem 0 0 0;
    background: var(--theme-light-gray-100, #f4f5f5);
    /* table outlines - light/table-outline-plain/bottom */
`
const BreadcrumbItem = styled(Breadcrumb.Item)`
    display: flex;
    font-family: 'IntelOne Display';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    align-items: center;
    color: #2b2c30;
`
const RecentSearchContainer = styled.div`
    background: #e9eaeb;
    box-shadow: inset 0px -1px 0px rgba(43, 44, 48, 0.12);
    width: 100%;
    padding: 2% 8.5%;
`
const DataFilterContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    padding: 1rem;
    width: 100%;
    background: var(--theme-light-gray-100, #f4f5f5);
    /* table outlines - light/table-outline-plain/bottom */
    box-shadow: 0px -1px 0px 0px rgba(43, 44, 48, 0.12) inset;
`
const FilterButton = styled(Button)`
    display: flex;
    padding: 8px 14px;
    align-items: center;
    gap: 4px;
    border-radius: 24px;
    background: var(--theme-light-gray-300, #e2e2e4);
    color: var(--button-light-action-default, #0054ae);
    font-family: IntelOne Display;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 114.286% */
    letter-spacing: -0.14px;

    &:hover {
        border-radius: 24px;
        background: var(--theme-light-gray-300, #e2e2e4);
        color: var(--button-light-action-default, #0054ae);
        transform: scale(1.03);
        transition: 0.5s;
    }

    &:focus {
        border-radius: 24px;
        background: var(--theme-light-gray-300, #e2e2e4);
        color: var(--button-light-action-default, #0054ae);
    }
    &:click {
        border-radius: 24px;
        background: var(--theme-light-gray-300, #e2e2e4);
        color: var(--button-light-action-default, #0054ae);
        border: 2px solid #0099ec;
    }
`
const DataFilterComponent = styled.div`
    display: flex;
    margin-top: 1rem;
    margin-right: 1rem;
`
const RecentSearchProductImageDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 2rem;
    margin-right: 3rem;
    text-align: center;
`
const WorkloadCount = styled.p`
    font-size: 2.25rem;
    font-family: IntelOne Display;
    font-weight: 300;
    line-height: 2.8125rem;
`
const WorkloadResults = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
`
const SearchTextBoxComponent = styled(FormControl)`
    display: flex;
    background: #ffffff;
    border: 1px solid #8b8e97;
    font-size: 1.1rem !important;
    font-weight: 300;
    font-family: IntelOne Text;
    font-style: italic;
    color: #00377c;
    height: 32px;
    width: 250px;
    border-radius: 0px;
    margin-top: 0.5rem;
    margin-right: 1rem;
`
const WorkloadName = styled.p`
    display: flex;
    color: #0054ae;
    justify-content: center;
    text-align: center;
    font-size: 1rem;
    font-family: IntelOne Display;
    font-weight: 400;
    max-width: 7rem;
    margin-top: 1rem;
    height: 3rem;
`

const WorkloadBubbleContainer = styled(Button)`
    display: flex;
    width: 7.5rem;
    height: 7.5055rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-shrink: 0;
    border-radius: 60px;
    background: var(--theme-light-gray-50, #fff);
    box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.2);
    border: 2px solid #e2e2e4;
    &:hover {
        background: var(--theme-light-gray-50, #fff);
        box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.2);
        transform: scale(1.03);
        transition: 0.5s;
        border: 1px solid rgb(0, 84, 174);
    }
    &:focus {
        background: var(--theme-light-gray-50, #fff);
        box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.2);
        transform: scale(1.03);
        transition: 0.5s;
        border: 1px solid rgb(0, 84, 174);
    }
`
const NotAutorizeContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: left;
    margin: 2rem;
`
const NotAutorizeLabel = styled(FormLabel)`
    ${(props) => props.theme.typography.xLarge}
    text-align: left;
    font-weight: 400;
    font-size: 1rem;
    margin-bottom: 50rem;
    color: red;
`
const SpinnerDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5rem;
    width: 100%;
`
const NextButton = styled.button`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 2.25rem;
    height: 2.25rem;
    padding: 10px;
    gap: 10px;
    border-radius: 18px;
    background: var(--black-and-white-white, #fff);
    //border-color: #0054ae;
    cursor: pointer;
    tabindex: 0;
    border: none;
    &:hover {
        color: #00a3fb;
    }
    &:focus {
        color: #00a3fb;
        //border: 1px solid transparent;
        outline: auto;
    }
`

const AllWorkloads = () => {
    let path = `${window.location.pathname}`
    const navigate = useNavigate()
    const queryParameters = new URLSearchParams(location.search)
    const isDevViewValue = queryParameters.get('isDevView')
    const isDevView = isDevViewValue && isDevViewValue === 'true' ? true : false

    const {
        onRecentSearchClick,
        workloads,
        onTopSearchKeyChange,
        onSearchKeydown,
        onAccessApplyChange,
        categoryDisplayGroup,
        onCategoryNextClick,
        onHeaderFilterClick,
        onHeaderFilterClearAllClick,
    } = AllWorkloadsLogic()

    const defaultWorkloadsResult = useAppSelector<any>(
        (state: any) => state.getDefaultWorkloadsResult.data
    )

    const authPermissionsResult = useAppSelector<any>(
        (state: any) => state.authPermissionsResult.data
    )
    const authPermissionsLoading = useAppSelector<any>(
        (state: any) => state.authPermissionsResult.loading
    )
    let isIntelEmployee = false
    if (authPermissionsResult && authPermissionsResult?.isIntelEmployee) {
        isIntelEmployee = authPermissionsResult?.isIntelEmployee
    }

    return (
        <AllWorkloadsWrapper>
            {authPermissionsLoading !== LoadingState.Pending ? (
                <AllWorkloadsContainer>
                    <div>
                        <SalesHeaderPanel
                            onSearchKeyDown={onSearchKeydown}
                            onSearchKeyChange={onTopSearchKeyChange}
                            onAccessChangeCallback={onAccessApplyChange}
                            onApplyFilterCallback={onHeaderFilterClick}
                            onClearAllCallback={onHeaderFilterClearAllClick}
                        />
                        <div>
                            <Breadcrumb
                                style={{
                                    padding: '0.2rem 1rem',
                                    marginBottom: '-0.6rem',
                                    marginTop: '0.2rem',
                                }}
                            >
                                <BreadcrumbItem
                                    href="/catalog"
                                    style={{
                                        textDecoration: 'none',
                                        color: '#2B2C30',
                                    }}
                                >
                                    Intel Optimization Hub
                                </BreadcrumbItem>
                                {isDevView ? (
                                    <BreadcrumbItem
                                        href={StringUtils.resolve(
                                            '/benchmarks'
                                        )}
                                        style={{
                                            textDecoration: 'none',
                                            color: '#2B2C30',
                                        }}
                                    >
                                        Benchmarks
                                    </BreadcrumbItem>
                                ) : (
                                    <BreadcrumbItem
                                        href={StringUtils.resolve(
                                            '/salesview/home'
                                        )}
                                        style={{
                                            textDecoration: 'none',
                                            color: '#2B2C30',
                                        }}
                                    >
                                        Sales View Home
                                    </BreadcrumbItem>
                                )}
                                <BreadcrumbItem
                                    active
                                    style={{
                                        fontWeight: '500',
                                        color: '#0054AE',
                                    }}
                                >
                                    All Workloads
                                </BreadcrumbItem>
                            </Breadcrumb>
                            <div>
                                <img
                                    src="/sales_img/all-workloads.png"
                                    alt="product logo"
                                    style={{
                                        //position: 'absolute',
                                        height: '200px',
                                        width: '100%',
                                    }}
                                />
                            </div>
                        </div>

                        <RecentSearchContainer>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginLeft: '4%',
                                }}
                            >
                                <WorkloadCount>
                                    Results - (
                                    {defaultWorkloadsResult?.pageInfo?.total})
                                </WorkloadCount>
                            </div>
                            <WorkloadResults>
                                {defaultWorkloadsResult?.workloads?.map(
                                    (workload: any) => (
                                        <WorkloadBubble
                                            hrefValue={`/allWorkloads/${workload.uuid}?isDevView=${isDevView}`}
                                            uuid={workload.uuid}
                                            iconURL={workload.iconURL}
                                            name={workload.workload}
                                            isWinningWorkload={
                                                workload?.tags[
                                                    'selling prioritization'
                                                ] &&
                                                workload?.tags[
                                                    'selling prioritization'
                                                ][0] &&
                                                (workload?.tags[
                                                    'selling prioritization'
                                                ][0] === 'Priority' ||
                                                    workload?.tags[
                                                        'selling prioritization'
                                                    ][0] === 'Prioritized')
                                                    ? true
                                                    : false
                                            }
                                        />
                                    )
                                )}
                                {defaultWorkloadsResult &&
                                defaultWorkloadsResult?.workloads &&
                                defaultWorkloadsResult.workloads.length === 0
                                    ? NoResultFoundPanel()
                                    : ''}
                            </WorkloadResults>
                        </RecentSearchContainer>
                    </div>
                </AllWorkloadsContainer>
            ) : (
                <SpinnerDiv>
                    <Spinner animation="border" variant="dark">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </SpinnerDiv>
            )}
        </AllWorkloadsWrapper>
    )
}

export default AllWorkloads
