/************************************************
 * Copyright (C) 2021 Intel Corporation
 ************************************************/
import styled from 'styled-components'
import { FunctionComponent, Dispatch, useEffect } from 'react'
import { connect } from 'react-redux'
import { LoadingState } from '../../models/loadingState'
import { AcceptInviteContainerLogic } from './acceptInviteContainer.hook'
import { AcceptInvitesRequest } from '../../models/acceptInvitesRequest'
import { acceptInvites } from '../../store/invitation.slice'
import { AcceptInvitesResponse } from '../../models/acceptInvitesResponse'
import { Button, Alert, FormLabel, Form, Spinner } from 'react-bootstrap'
import stringUtils from '../../lib/stringUtils'
import { useNavigate } from 'react-router-dom'

const VerticalContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`

const AcceptInvitePanelContainer = styled.div`
    display: flex;
    height: ${(props) => props.theme.size.mainContainerHeight};
    width: ${(props) => props.theme.size.mainContainerWidth};
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: space-between;
    @media screen and (min-width: 1200px) {
        overflow-x: hidden;
    }
`

const AcceptInviteHorizontalContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: space-between;
`
const AcceptInviteBodyContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: center;
    background-color: ${(props) => props.theme.color.background.defaultGrey};
    padding-bottom: 10rem;
`

const AcceptInviteBodyContainerReposition = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
`
const AcceptInvitePanelWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: center;
`
const IntelLogoStyling = styled.div`
    display: flex;
    margin-left: 2rem;
    margin-top: 1rem;
    padding: 0.5rem;
`
const HeaderContainer = styled.div`
    display: flex;
    background-color: ${(props) => props.theme.color.background.defaultGrey};
    padding-bottom: 2rem;
`

const AcceptInviteFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
`

const HorizontalContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`

const PageTitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`

const AcceptInviteFormComponent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`

const ButtonSpinnerHorizontalComponent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-top: 1rem;
    padding-bottom: 3rem;
`

const SignUpButton = styled(Button)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 9rem;
    max-width: 9rem;
    margin-right: 1rem;
    border-radius: ${(props) => props.theme.shape.button.borderRadius};
    &:hover {
        background-color: ${(props) =>
            props.theme.color.button.background.hover.primary};
        transition: 0.5s;
    }
`

const PageTitleTextLabel = styled(FormLabel)`
    ${(props) => props.theme.typography.xxxLarge}
    text-align: left;
    font-family: 'IntelOne Display';
    font-style: normal;
    font-weight: 500;
`
const PageTitleTextLabel2 = styled(FormLabel)`
    text-align: left;
    font-family: 'IntelOne Display';
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    padding-top: 1rem;
    padding-bottom: 2rem;
`
const TextBoxWrapper = styled(Form.Control)`
    display: flex;
    border-radius: ${(props) => props.theme.shape.input.borderRadius};
    margin-bottom: 1rem;
    max-width: 20vw;
    min-height: 4vh;
`
const TextLabel = styled(Form.Label)`
    display: flex;
    ${(props) => props.theme.typography.xLarge}
    text-align: left;
    font-weight: 500;
`

const RequiredTextLabel = styled(FormLabel)`
    display: flex;
    ${(props) => props.theme.typography.xLarge}
    color: red;
`

const AlertComponent = styled(Alert)`
    display: flex;
    margin-top: 1rem;
    width: 35vw;
    justify-content: center;
`

const ResetButton = styled(SignUpButton)`
    margin-left: 1rem;
    width: 8rem;
    max-width: 8rem;
`

const SpinnerComponent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 0.5rem;
`

const HomePageDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-right: 1rem;
    margin-top: 1rem;
`

const HomePageButton = styled(Button)`
    display: flex;
    justify-content: center;
    font-family: 'IntelOne Display';
    font-weight: 100;
    font-size: 1.25rem;
    filter: drop-shadow(2px 5px 4px rgba(0, 0, 0, 0.25));
    border-radius: ${(props) => props.theme.shape.button.borderRadius};
    &:hover {
        transition: 0.5s;
    }
`

const HomePageText = styled.div`
    display: flex;
    font-family: 'IntelOne Display';
    font-weight: 100;
    font-size: 1.25rem;
    filter: drop-shadow(2px 5px 4px rgba(0, 0, 0, 0.25));
`

export interface IAcceptInviteContainerProps {
    acceptInvites: typeof acceptInvites
    acceptInvitesError: any
    acceptInvitesLoading: LoadingState
    acceptInvitesResult: AcceptInvitesResponse
    invitationKey?: string | null
}

const AcceptInviteContainer: FunctionComponent<IAcceptInviteContainerProps> & {
    defaultProps: Partial<IAcceptInviteContainerProps>
} = ({
    acceptInvites,
    acceptInvitesError,
    acceptInvitesLoading,
    acceptInvitesResult,
}: IAcceptInviteContainerProps) => {
    let navigate = useNavigate()
    let invitationKey = localStorage.getItem('invitationKey')

    useEffect(() => {
        if (stringUtils.isNullOrUndefinedOrEmpty(invitationKey)) {
            localStorage.setItem('loginRoute', 'catalog')
            navigate('/catalog')
        }
    }, [invitationKey])

    const {
        onUserFullNameChange,
        onAccept,
        onDecline,
        inviteError,
        validated,
    } = AcceptInviteContainerLogic({
        acceptInvites,
        acceptInvitesError,
        acceptInvitesLoading,
        acceptInvitesResult,
        invitationKey,
    } as IAcceptInviteContainerProps)

    return (
        <AcceptInvitePanelContainer>
            <HeaderContainer>
                <IntelLogoStyling>
                    <img
                        src="/Intel_energyblue_logo.png"
                        height="65"
                        alt="intel logo"
                    />
                </IntelLogoStyling>
            </HeaderContainer>
            <AcceptInviteHorizontalContainer>
                <AcceptInviteBodyContainer>
                    <AcceptInviteBodyContainerReposition>
                        <AcceptInvitePanelWrapper>
                            <AcceptInviteFormContainer>
                                <PageTitleContainer>
                                    <PageTitleTextLabel>
                                        You have been invited to join an Org
                                    </PageTitleTextLabel>
                                </PageTitleContainer>
                                <PageTitleContainer>
                                    <PageTitleTextLabel2>
                                        Please enter your full name to accept
                                    </PageTitleTextLabel2>
                                </PageTitleContainer>
                                <HorizontalContainer>
                                    <AcceptInviteFormComponent>
                                        <AlertComponent
                                            style={{ width: '25rem' }}
                                            variant="danger"
                                            show={
                                                !stringUtils.isNullOrUndefinedOrEmpty(
                                                    inviteError
                                                )
                                            }
                                        >
                                            {inviteError}
                                        </AlertComponent>
                                        <Form
                                            noValidate
                                            validated={validated}
                                            onSubmit={onAccept}
                                            id="inviteForm"
                                        >
                                            <Form.Group controlId="userFullName">
                                                <TextLabel>
                                                    <RequiredTextLabel>
                                                        *
                                                    </RequiredTextLabel>
                                                    Full name{' '}
                                                </TextLabel>
                                                <TextBoxWrapper
                                                    placeholder="Enter your full name..."
                                                    onChange={
                                                        onUserFullNameChange
                                                    }
                                                    required
                                                />

                                                <Form.Control.Feedback type="invalid">
                                                    Please your full name.
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <ButtonSpinnerHorizontalComponent>
                                                <SignUpButton
                                                    variant="primary"
                                                    type="submit"
                                                    onClick={onAccept}
                                                >
                                                    Accept
                                                </SignUpButton>

                                                <ResetButton
                                                    variant="primary"
                                                    type="reset"
                                                    onClick={onDecline}
                                                >
                                                    Decline
                                                </ResetButton>
                                            </ButtonSpinnerHorizontalComponent>

                                            <SpinnerComponent>
                                                {acceptInvitesLoading ===
                                                LoadingState.Pending ? (
                                                    <Spinner
                                                        animation="border"
                                                        variant="info"
                                                    />
                                                ) : (
                                                    <div></div>
                                                )}
                                            </SpinnerComponent>

                                            <HomePageDiv>
                                                <VerticalContainer>
                                                    <HomePageText>
                                                        Decide later?
                                                    </HomePageText>
                                                </VerticalContainer>
                                                <VerticalContainer>
                                                    <HomePageButton
                                                        variant="link"
                                                        data-testid="homepage-button"
                                                        onClick={async () => {
                                                            navigate('/home')
                                                        }}
                                                    >
                                                        Go to Homepage
                                                    </HomePageButton>
                                                </VerticalContainer>
                                            </HomePageDiv>
                                        </Form>
                                    </AcceptInviteFormComponent>
                                </HorizontalContainer>
                            </AcceptInviteFormContainer>
                        </AcceptInvitePanelWrapper>
                    </AcceptInviteBodyContainerReposition>
                </AcceptInviteBodyContainer>
            </AcceptInviteHorizontalContainer>
        </AcceptInvitePanelContainer>
    )
}

AcceptInviteContainer.defaultProps = {}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        acceptInvites: (acceptInvitesRequest: AcceptInvitesRequest) =>
            dispatch(acceptInvites(acceptInvitesRequest)),
    }
}

const mapStateToProps = (state: any) => {
    return {
        acceptInvitesError: state.acceptInvitesResult.error,
        acceptInvitesLoading: state.acceptInvitesResult.loading,
        acceptInvitesResult: state.acceptInvitesResult.data,
    }
}

type StateToPropsType = ReturnType<typeof mapStateToProps>
type DispatchToPropsType = typeof mapDispatchToProps

export default connect<StateToPropsType, DispatchToPropsType>(
    mapStateToProps,
    mapDispatchToProps
)(AcceptInviteContainer)
