/************************************************
 * Copyright (C) 2024 Intel Corporation
 ************************************************/
import { FunctionComponent, useState } from 'react'
import { Alert, Modal, CloseButton } from 'react-bootstrap'
import styled from 'styled-components'
import { postRetrievalQuery } from '../../store/chatCompletion.slice'
import { LoadingState } from '../../models/loadingState'
import RetrievalInputPanel from './retrievalInputPanel'
import OutputPanel from './outputPanel'

const ModalPopupContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`
const ViewPortControl = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    //margin-top: 20px;
    border-top: solid 1px;
    border-color: rgb(101, 49, 113);
    //border: 1px solid rgb(101, 49, 113);
    min-height: 15rem;
    margin: 1.6rem 1.5rem;
    background-color: #e8e8e8; //rgb(12, 12, 12, 1);
`

const AlertComponent = styled(Alert)`
    display: flex;
    margin-top: 1rem;
    justify-content: center;
    border-radius: 0;
`

export interface IRetrievalModelPanelProps {
    postRetrievalQuery: typeof postRetrievalQuery
    retrievalQueryAnswer: any
    retrievalQueryLoading: LoadingState
    retrievalQueryError: any
    selectedModelName: string
    selectedModelPublishedBy: string
    showModel: boolean
    setShowModel: any
    isNewModelLoad?: boolean
    setIsNewModelLoad?: any
}

const RetrievalModelPanel: FunctionComponent<IRetrievalModelPanelProps> = ({
    postRetrievalQuery,
    retrievalQueryAnswer,
    retrievalQueryLoading,
    retrievalQueryError,
    selectedModelName,
    selectedModelPublishedBy,
    showModel,
    setShowModel,
    isNewModelLoad,
    setIsNewModelLoad,
}: IRetrievalModelPanelProps) => {
    const [moduleDisplayGroup, setModuleDisplayGroup] = useState<number>(1)
    const [currentPromptMessage, setCurrentPromptMessage] = useState<string>('')
    const [isResetClicked, setIsResetClicked] = useState(false)

    return (
        <ModalPopupContainer>
            <Modal
                show={showModel}
                onHide={() => setShowModel((show: boolean) => !show)}
                backdrop="static"
                keyboard={true}
                size="xl"
            >
                <Modal.Body>
                    <CloseButton
                        className="p-1"
                        onClick={() => setShowModel((show: boolean) => !show)}
                        style={{
                            borderRadius: 0,
                            fontSize: '1.5rem',
                            marginRight: '0.3rem',
                        }}
                    />
                    <ViewPortControl>
                        <AlertComponent
                            style={{ width: '100%' }}
                            variant="danger"
                            show={retrievalQueryError ? true : false}
                        >
                            {`We are experiencing issues retrieving the data. ${retrievalQueryError?.message}`}
                        </AlertComponent>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            <div
                                id="chatDiv"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '50%',
                                    height: '100%',
                                }}
                            >
                                <h3
                                    style={{
                                        fontSize: '1.2rem',
                                        paddingLeft: '0.5rem',
                                    }}
                                >
                                    Input
                                </h3>
                                <RetrievalInputPanel
                                    postRetrievalQuery={postRetrievalQuery}
                                    retrievalQueryAnswer={retrievalQueryAnswer}
                                    retrievalQueryLoading={
                                        retrievalQueryLoading
                                    }
                                    retrievalQueryError={retrievalQueryError}
                                    selectedModelName={selectedModelName}
                                    selectedModelPublishedBy={
                                        selectedModelPublishedBy
                                    }
                                    setIsResetClick={setIsResetClicked}
                                ></RetrievalInputPanel>
                            </div>
                            <div
                                id="seprator"
                                style={{
                                    width: '1px',
                                    margin: '0 1rem',
                                    backgroundColor: 'rgb(101, 49, 113)',
                                }}
                            ></div>
                            <div
                                id="outputtDiv"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '50%',
                                    height: '100%',
                                }}
                            >
                                <h3
                                    style={{
                                        fontSize: '1.2rem',
                                        paddingLeft: '0.5rem',
                                    }}
                                >
                                    Output
                                </h3>
                                <OutputPanel
                                    chatMessage={
                                        currentPromptMessage
                                            ? currentPromptMessage
                                            : 'Write about meta/llama3-70b'
                                    }
                                    selectedModelName={selectedModelName}
                                    isRetrievalOutput={true}
                                    retrievalOutputData={retrievalQueryAnswer}
                                    isResetClicked={isResetClicked}
                                    isStream={false}
                                    retrievalQueryLoading={
                                        retrievalQueryLoading
                                    }
                                ></OutputPanel>
                            </div>
                        </div>
                    </ViewPortControl>
                </Modal.Body>
            </Modal>
        </ModalPopupContainer>
    )
}

export default RetrievalModelPanel
