import CryptoJS from 'crypto-js'

export const _setEncryptedValueInStorage = function (
    key: string,
    value: string
) {
    if (key && value) {
        //localStorage.setItem(key, CryptoJS.AES.encrypt(value, '').toString())
        localStorage.setItem(key, value)
    }
}

export const _getDecryptedValueFromStorage = function (key: string) {
    let encrypted_value = localStorage.getItem(key)
    // let result = encrypted_value
    //     ? CryptoJS.AES.decrypt(encrypted_value, '').toString(CryptoJS.enc.Utf8)
    //     : ''
    let result = encrypted_value ? encrypted_value : ''

    return result
}
export const clearAllItemsFromStorage = function () {
    localStorage.removeItem('isAdmin')
    localStorage.removeItem('isIntelEmployee')
    localStorage.removeItem('lastLogin')
    localStorage.removeItem('isPerformanceUploader')
    localStorage.removeItem('organizationalRoles')
    localStorage.removeItem('otherMetaPermissions')
    localStorage.removeItem('orgName')
    localStorage.removeItem('lastLoginDate')
    localStorage.removeItem('lastLocation')
    localStorage.removeItem('featureFlags')
    localStorage.removeItem('publicUrlRoute')
    localStorage.removeItem('userDisplayName')
    localStorage.removeItem('selectedSalesAccess')
}
