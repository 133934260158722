import { FC } from 'react'
import { LoadingState } from '../../../../models/loadingState'
import { Box } from '@mui/material'
import { Chart } from 'react-google-charts'
import { colors } from '../../../../utils/colors'
import ChartWrapper from './ChartWrapper'
import ChartLoader from './ChartLoader'

const barChartBoxSx = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
}

const chartOptions = {
    chartArea: {
        top: 16,
        bottom: 48,
        left: 128,
        right: 64,
    },
    bar: { groupWidth: '95%' },
    fontName: 'IntelOne Text',
    fontSize: 12,
    hAxis: {
        format: '0',
        minValue: 0,
        titleTextStyle: {
            fontSize: 12,
            bold: false,
            italic: false,
        },
    },
    vAxis: {
        titleTextStyle: {
            fontSize: 12,
            bold: false,
            italic: false,
        },
    },
    legend: {
        position: 'none',
    },
    colors: [colors.geode],
}

export type BarChartData = [
    [string, string, { role: string }],
    ...(string | number)[][]
]

interface BarChartProps {
    chartData: BarChartData
    dataLoadingState: LoadingState | LoadingState[]
    vAxisTitle?: string
    hAxisTitle?: string
    fixedHeight?: boolean
}

const BarChart: FC<BarChartProps> = ({
    chartData,
    dataLoadingState,
    vAxisTitle,
    hAxisTitle,
    fixedHeight,
}) => {
    const isDataLoading = Array.isArray(dataLoadingState)
        ? dataLoadingState.some((state) => state === LoadingState.Pending)
        : dataLoadingState === LoadingState.Pending

    return (
        <Box
            sx={{
                ...barChartBoxSx,
                minHeight: fixedHeight ? '386px' : '100px',
            }}
        >
            <ChartWrapper
                isDataLoading={isDataLoading}
                noDataCondition={chartData.length === 1}
            >
                <Chart
                    chartType="BarChart"
                    data={chartData}
                    loader={<ChartLoader message="Loading chart..." />}
                    width="100%"
                    height={
                        fixedHeight ? 386 : (chartData.length - 1) * 32 + 66
                    }
                    chartLanguage="en-US"
                    options={{
                        ...chartOptions,
                        vAxis: {
                            ...chartOptions.vAxis,
                            title: vAxisTitle,
                        },
                        hAxis: {
                            ...chartOptions.hAxis,
                            title: hAxisTitle,
                        },
                    }}
                />
            </ChartWrapper>
        </Box>
    )
}

export default BarChart
