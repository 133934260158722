import { IShape } from '../shape'

const shape: IShape = {
    button: {
        borderRadius: '0.6rem',
    },
    input: {
        borderRadius: '0.25rem',
    },
    container: {
        borderRadius: '0.25rem',
    },
}

export { shape }
