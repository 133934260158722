import { useCallback } from 'react'
import { UploadRegistryRequest } from '../../models/uploadRegistryRequest'
import { IUploadRegistryContainerProps } from './addNewOptimizationContainer'

export function AddNewOptimizationContainerLogic({
    uploadRegistry,
}: IUploadRegistryContainerProps) {
    const onUploadRegistrySubmit = useCallback(
        async (uploadRegistryRequest: UploadRegistryRequest) => {
            uploadRegistry(uploadRegistryRequest)
        },
        []
    )
    return {
        onUploadRegistrySubmit,
    }
}
