/*
 * Sources:
 * - https://spark-island.intel.com/?path=/story/foundation-colors--page
 * - https://dlux.intel.com/colors/usage
 */
export const colors = {
    // primary colors
    classicBlueTint2: '#6cc4f5',
    classicBlueTint1: '#0099ec',
    classicBlue: '#0054ae',
    classicBlueShade1: '#00377c',
    classicBlueShade2: '#001e50',
    energyBlueTint2: '#a0ebff',
    energyBlueTint1: '#6ddcff',
    energyBlue: '#00c7fd',
    energyBlueShade1: '#0095ca',
    energyBlueShade2: '#005b85',

    // neutral colors
    carbonTint2: '#e9e9e9',
    carbonTint1: '#aeaeae',
    carbon: '#808080',
    carbonShade1: '#525252',
    carbonShade2: '#262626',
    blueSteelTint2: '#b9d6e5',
    blueSteelTint1: '#86b3ca',
    blueSteel: '#548fad',
    blueSteelShade1: '#41728a',
    blueSteelShade2: '#183544',

    // natural colors
    geodeTint2: '#eec3f7',
    geodeTint1: '#cc94da',
    geode: '#8f5da2',
    geodeShade1: '#653171',
    rustTint2: '#ffc599',
    rustTint1: '#ff8f51',
    rust: '#e96115',
    rustShade1: '#b24501',
    mossTint2: '#d7f3a2',
    mossTint1: '#b1d272',
    moss: '#8bae46',
    mossShade1: '#708541',
    mossShade2: '#515a3d',

    // electric colors
    cobaltTint2: '#98a1ff',
    cobaltTint1: '#5b69ff',
    cobalt: '#1e2eb8',
    cobaltShade1: '#000f8a',
    cobaltShade2: '#000864',
    cobaltShade3: '#040e35',
    coralTint2: '#ffb6b9',
    coralTint1: '#ff848a',
    coral: '#ff5662',
    coralShade1: '#c81326',
    daisyTint1: '#ffe17a',
    daisy: '#fec91b',
    daisyShade1: '#edb200',
    daisyShade2: '#c98f00',

    // status colors
    error: '#f5c7c7',
    errorSoft: '#f88f8f',
    errorBright: '#ce0000',
    warning: '#fff6cc',
    warningSoft: '#ffeb92',
    warningBright: '#ffd100',
    success: '#c2e5c2',
    successSoft: '#add2ad',
    successBright: '#008a00',

    // theme colors
    // light theme
    lightGray50: '#ffffff',
    lightGray75: '#f9f9f9',
    lightGray100: '#f4f5f5',
    lightGray200: '#e9eaeb',
    lightGray300: '#e2e2e4',
    lightGray400: '#c9cace',
    lightGray500: '#b2b3b9',
    lightGray600: '#8b8e97',
    lightGray700: '#6a6d75',
    lightGray800: '#494b51',
    lightGray900: '#2b2c30',
    lightAction: '#0054ae',

    // dark theme
    darkGray50: '#242528',
    darkGray75: '#2e2f32',
    darkGray100: '#313236',
    darkGray200: '#3c3e42',
    darkGray300: '#484a50',
    darkGray400: '#585a62',
    darkGray500: '#6b6e76',
    darkGray600: '#8e9099',
    darkGray700: '#b7b9be',
    darkGray800: '#e3e3e5',
    darkGray900: '#ffffff',
    darkAction: '#00c7fd',
}
