import { useEffect, useRef, useState } from 'react'
import { ButtonToolbarProps } from 'react-bootstrap'
import moment from 'moment'
import { CatalogSearchRequest } from '../../models/catalogSearchrequest'
import { LoadingState } from '../../models/loadingState'
import { ShoppingCartResponse } from '../../models/shoppingCartResponse'
import { ICatalogContainerProps } from './catalogContainer'
import {
    _getDecryptedValueFromStorage,
    _setEncryptedValueInStorage,
} from '../../lib/encryptStorage'
import { ProductTag } from '../../models/ProductTag'
import { ShoppingCartRequest } from '../../models/shoppingCartRequest'

export function CatalogContainerLogic({
    getCatalog,
    catalogResult,
    catalogloading,
    getTagsValues,
    error,
    updateCartResult,
    updateCartResultLoading,
    updateCartResultError,
    getSubscription,
    getSubscriptionResult,
    getLastLogin,
    getLastLoginResult,
    getLastLoginError,
    getLastLoginLoading,
    getCartItems,
    isPublicSearch,
}: ICatalogContainerProps) {
    const [navigateToPage, setNavigateToPage] = useState<string | null>(null)
    const updatedCheckedOutItemCount = updateCartResult?.cartitems?.length
    const [currentCheckedOutItemCount, setCurrentCheckedOutItemCount] =
        useState<number | undefined>(updatedCheckedOutItemCount)

    useEffect(() => {
        let original_url = `${window.location.pathname}${window.location.search}`
        if (
            original_url.startsWith('/catalog?uuid=') ||
            original_url.startsWith('/catalog?platform=') ||
            original_url.startsWith('/public/catalog?uuid=') ||
            original_url.startsWith('/public/catalog?platform=')
        ) {
            localStorage.setItem('original_url', original_url)
        }
        let callback_url = localStorage.getItem('original_url')
        let search_key = ''
        let search_value = ''
        if (
            callback_url !== undefined &&
            callback_url !== null &&
            callback_url !== '' &&
            (callback_url.startsWith('/catalog?uuid=') ||
                callback_url.startsWith('/catalog?platform=') ||
                callback_url.startsWith('/public/catalog?uuid=') ||
                callback_url.startsWith('/public/catalog?platform='))
        ) {
            search_key = callback_url.substring(
                callback_url.indexOf('?') + 1,
                callback_url.indexOf('=')
            )
            search_value = callback_url.substring(
                callback_url.indexOf('=') + 1,
                callback_url.length
            )
        }
        if (search_key === 'uuid' && search_value !== '') {
            getCatalog({
                uuid: search_value,
                isPublicSearch: isPublicSearch,
            } as CatalogSearchRequest)
        } else if (search_key === 'platform' && search_value !== '') {
            getCatalog({
                appName: '',
                'tags-Platform': decodeURI(search_value),
                isPublicSearch: isPublicSearch,
            } as CatalogSearchRequest)
        }
        if (!isPublicSearch) getLastLogin()
    }, [])

    useEffect(() => {
        if (catalogloading !== LoadingState.Pending || error) {
            if (
                (catalogResult && catalogResult.recipes) ||
                (error && error.message && error.message.indexOf('404') > -1)
            ) {
                localStorage.setItem('original_url', '')
                localStorage.setItem('publicUrlRoute', '')
            }
        }
    }, [catalogResult, error])

    useEffect(() => {
        setNavigateToPage(null)
        if (updatedCheckedOutItemCount != currentCheckedOutItemCount) {
            setNavigateToPage('/cart')
            setCurrentCheckedOutItemCount(updatedCheckedOutItemCount)
        }
    }, [updatedCheckedOutItemCount, currentCheckedOutItemCount])

    useEffect(() => {
        let lastSeenDate = ''
        if (
            getLastLoginLoading !== LoadingState.Pending &&
            getLastLoginResult
        ) {
            if (getLastLoginResult['lastSeen'] !== '') {
                lastSeenDate = moment
                    .utc(getLastLoginResult['lastSeen'])
                    .fromNow()
                localStorage.setItem('lastLoginDate', lastSeenDate)
            }
            if (getLastLoginResult['lastLocation'] !== '') {
                localStorage.setItem(
                    'lastLocation',
                    getLastLoginResult['lastLocation']
                )
            }
        } else if (getLastLoginError?.message.indexOf('404') > -1) {
            lastSeenDate = moment.utc(Date.now()).fromNow()
            localStorage.setItem('lastLoginDate', lastSeenDate)
            localStorage.setItem('lastLocation', '')
        } else {
            localStorage.setItem('lastLoginDate', '')
            localStorage.setItem('lastLocation', '')
        }
    }, [getLastLoginResult, getLastLoginLoading, getLastLoginError])

    useEffect(() => {
        if (!isPublicSearch) {
            getCartItems({
                tags: [ProductTag.OptimizationHub],
            } as ShoppingCartRequest)
        }
    }, [])

    return { navigateToPage }
}
