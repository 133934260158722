import DashboardCard from '../shared/cards/DashboardCard'
import { useAppSelector } from '../../../config/hooks'
import { ChangeEvent, FC, useEffect, useState } from 'react'
import BarChart from '../shared/charts/BarChart'
import {
    Box,
    FormControl,
    FormLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Switch,
    Typography,
    useMediaQuery,
} from '@mui/material'
import theme from '../../../containers/dashboardContainer/theme'
import { LoadingState } from '../../../models/loadingState'

const excludeIntelSwitchBoxSx = { display: 'flex', gap: '1rem' }

const excludeIntelSwitchFormControlSx = { margin: 0, display: 'inline' }

const excludeIntelSwitchFormLabelSx = {
    fontSize: '0.75rem',
    marginRight: '0.25rem',
}

const showTopSelectFormControlSx = {
    margin: 0,
    flexDirection: 'row',
    alignItems: 'center',
}

const showTopSelectFormLabelSx = {
    fontSize: '0.75rem',
    marginRight: '0.5rem',
    marginBottom: 0,
}

const showTopSelectInputProps = {
    sx: {
        padding: '0.125rem 0.5rem',
    },
}

const showTopSelectMenuProps = {
    sx: {
        '& .MuiMenu-list': {
            padding: 0,
        },
    },
}

const showTopSelectMenuItemSx = { padding: '0.25rem 0.5rem' }

type BarChartData = [
    [string, string, { role: string }],
    ...(string | number)[][]
]

const chartDataHeaders: [string, string, { role: string }] = [
    'Optimization Name',
    '# Downloads',
    { role: 'annotation' },
]

const allShowTopOffsetOptions = ['5', '10', '15', '20', '30']

interface DownloadCountsCardProps {
    excludeIntelSwitch: boolean
    handleExcludeIntelSwitchChange: (
        event: ChangeEvent<HTMLInputElement>
    ) => void
}

const DownloadCountsCard: FC<DownloadCountsCardProps> = ({
    excludeIntelSwitch,
    handleExcludeIntelSwitchChange,
}) => {
    const cardsActivitySummaryData = useAppSelector<any>(
        (state) => state.getCardsActivitySummaryResult.data
    )
    const cardsActivitySummaryLoading = useAppSelector(
        (state) => state.getCardsActivitySummaryResult.loading
    )

    const [downloadData, setDownloadData] = useState<(string | number)[][]>([])
    const [chartData, setChartData] = useState<BarChartData>([chartDataHeaders])
    const [totalDownloads, setTotalDownloads] = useState(0)
    const [showTopOffset, setShowTopOffset] = useState('all')

    useEffect(() => {
        if (
            cardsActivitySummaryData !== null &&
            cardsActivitySummaryData?.downloadedDocuments &&
            cardsActivitySummaryData?.totalDownloads
        ) {
            setDownloadData(
                Object.entries(
                    cardsActivitySummaryData?.downloadedDocuments as {
                        [key: string]: number
                    }
                )
                    .filter(([_, value]) => value > 0)
                    .sort(([_A, valueA], [_B, valueB]) => valueB - valueA)
                    .map(([name, value]) => [name, value, value])
            )
            if (
                Object.entries(
                    cardsActivitySummaryData?.downloadedDocuments as {
                        [key: string]: number
                    }
                ).length < 5
            ) {
                setShowTopOffset('all')
            }
            setTotalDownloads(cardsActivitySummaryData.totalDownloads)
        } else {
            setDownloadData([])
            setShowTopOffset('all')
            setTotalDownloads(0)
        }
    }, [cardsActivitySummaryData])

    useEffect(() => {
        let downloadDataItems = downloadData
        if (showTopOffset !== 'all') {
            downloadDataItems = downloadData.slice(0, Number(showTopOffset))
        }
        setChartData([chartDataHeaders, ...downloadDataItems])
    }, [downloadData, showTopOffset])

    const handleShowTopOffsetChange = (event: SelectChangeEvent) => {
        setShowTopOffset(event.target.value)
    }

    const xsBreakpoint = useMediaQuery(theme.breakpoints.only('xs'))

    const cardHeaderBoxSx = {
        display: 'flex',
        marginLeft: '1rem',
        marginBlock: '0.5rem',
        justifyContent: xsBreakpoint ? 'unset' : 'space-between',
        flexDirection: xsBreakpoint ? 'column' : 'row',
    }

    const showTotalDownloads =
        totalDownloads > 0 && cardsActivitySummaryLoading === LoadingState.Idle

    const inputsDisabled = cardsActivitySummaryLoading === LoadingState.Pending

    const showTopOffsetOptions = allShowTopOffsetOptions
        .filter((option) => Number(option) <= downloadData.length)
        .map((option) => (
            <MenuItem key={option} value={option} sx={showTopSelectMenuItemSx}>
                {option}
            </MenuItem>
        ))

    return (
        <DashboardCard>
            <Box sx={cardHeaderBoxSx}>
                <Box>
                    <Typography variant="h6">
                        Most Downloaded Optimizations
                    </Typography>
                    {showTotalDownloads && (
                        <Typography variant="body2">
                            Total Downloads: {totalDownloads}
                        </Typography>
                    )}
                </Box>
                <Box sx={excludeIntelSwitchBoxSx}>
                    <FormControl sx={excludeIntelSwitchFormControlSx}>
                        <FormLabel sx={excludeIntelSwitchFormLabelSx}>
                            Exclude Intel
                        </FormLabel>
                        <Switch
                            checked={excludeIntelSwitch}
                            disabled={inputsDisabled}
                            onChange={handleExcludeIntelSwitchChange}
                            size="small"
                        />
                    </FormControl>
                    <Box>
                        <FormControl
                            fullWidth={false}
                            size="small"
                            sx={showTopSelectFormControlSx}
                        >
                            <FormLabel sx={showTopSelectFormLabelSx}>
                                Show&nbsp;Top
                            </FormLabel>
                            <Select
                                value={showTopOffset}
                                disabled={inputsDisabled}
                                onChange={handleShowTopOffsetChange}
                                inputProps={showTopSelectInputProps}
                                MenuProps={showTopSelectMenuProps}
                            >
                                <MenuItem
                                    value="all"
                                    sx={showTopSelectMenuItemSx}
                                >
                                    All
                                </MenuItem>
                                {showTopOffsetOptions}
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
            </Box>
            <BarChart
                chartData={chartData}
                dataLoadingState={cardsActivitySummaryLoading}
                hAxisTitle="# Downloads"
                fixedHeight
            />
        </DashboardCard>
    )
}

export default DownloadCountsCard
