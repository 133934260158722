export enum LayoutViewPreference {
    ListView = 'list',
    GalleryView = 'gallery',
    DemoView = 'demo',
    SalesView = 'sales',
    ChatbotView = 'chatbot',
}

export enum CatalogCardType {
    All = 'All',
    Optimization = 'Optimization',
    Benchmark = 'Benchmark',
    PowerOptimization = 'Power Optimization',
}
