import { FormEvent, FunctionComponent } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import StringUtils from '../../lib/stringUtils'
import { Button } from 'react-bootstrap'

const LoginButton = styled.button`
    font-family: 'IntelOne Text';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    text-decoration: none;
    color: #000;
    background-color: #78deff;
    margin-right: 1.5rem;
    border: none;
    min-width: 8.5rem;
    border-radius: 0px;
    border: none;
`
const GetStartedButton = styled(LoginButton)`
    color: #fff;
    background-color: #8f5da2;
`
const ContactUsButton = styled(Button)`
    font-family: 'IntelOne Text';
    font-style: normal;
    font-size: 1rem;
    text-decoration: none;
    border: none;
    color: #fff;
    background-color: #0068b5;
    min-width: 8.5rem;
    border-radius: 0px;
    border: none;
    //height: 2.2rem;
    margin-right: 1rem;
`

export interface IHomePanelHeaderProps {}

const HomePanelHeader: FunctionComponent<IHomePanelHeaderProps> = () => {
    let navigate = useNavigate()
    return (
        <div className="rwd">
            <div
                className="global-nav-redesign global component"
                data-component="global-nav-redesign"
                data-component-id="1"
            >
                <header role="banner">
                    <nav
                        className="intel-navbar advanced-search"
                        role="navigation"
                        aria-label="main navigation"
                        data-igm=""
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}
                    >
                        {/* Brand and toggle get grouped for better mobile display */}
                        <div className="navbar-wraper">
                            <div className="navbar-logo">
                                <a
                                    href="https://www.intel.com/content/www/us/en/homepage.html"
                                    title="Intel homepage"
                                    className="intel-logo-rebrand"
                                >
                                    <img
                                        src={StringUtils.resolve(
                                            '/IntelLogo.png'
                                        )}
                                        height="40"
                                        width="96"
                                        title="Intel logo - Return to the home page"
                                    />
                                </a>
                            </div>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                padding: '1rem 0.5rem',
                            }}
                        >
                            <GetStartedButton
                                onClick={() => navigate('/public/catalog')}
                            >
                                Quick View
                            </GetStartedButton>
                            <LoginButton onClick={() => navigate('/signIn')}>
                                Login
                            </LoginButton>

                            <ContactUsButton
                                href="mailto:optimizations@intel.com"
                                style={{ color: '#fff' }}
                            >
                                Contact Us
                            </ContactUsButton>
                        </div>
                    </nav>
                </header>
            </div>
        </div>
    )
}

export default HomePanelHeader
