import { API_SERVICE_URL } from '../config/service'
import { ChatbotRequest } from '../models/chatbotRequest'
const serviceConfig = require('../config/service.json')
import api from './auth/client'

class ChatbotServices {
    SubmitQuestiontoChatbot(request: ChatbotRequest) {
        var url = API_SERVICE_URL + serviceConfig.chatbotURL
        return api.post(url, request)
    }
}
export default new ChatbotServices()
