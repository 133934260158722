import Auth from '@aws-amplify/auth'
import { API_SERVICE_URL } from '../config/service'
import { MyRegistryRequest } from '../models/myRegistryRequest'
import api from './auth/client'

const serviceConfig = require('../config/service.json')
const queryString = require('query-string')

class MyRegistryService {
    async getRecipes(myRegistryRequest: MyRegistryRequest) {
        let auth = await Auth.currentSession()
        let idToken = auth.getIdToken()
        const ID_token = idToken.getJwtToken()
        let parts = ID_token.split('.')
        let user_info = JSON.parse(atob(parts[1]))
        let userId = user_info['cognito:username']

        var url = API_SERVICE_URL + serviceConfig.getRecipeURL
        const queryArgs = queryString.stringify(myRegistryRequest)
        return api.get(`${url}?${queryArgs}&userId=${userId}`)
    }

    deleteRegistry(id: string) {
        var url = API_SERVICE_URL + serviceConfig.uploadRegistryURL + `/${id}`
        return api.delete(url)
    }
}

export default new MyRegistryService()
