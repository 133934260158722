import { API_SERVICE_URL } from '../config/service'

import api from './auth/client'

const serviceConfig = require('../config/service.json')

class OrganizationService {
    getOrganization() {
        var url = API_SERVICE_URL + serviceConfig.getOrganization
        return api.get(url)
    }
    getUserOrganizationsList() {
        var url = API_SERVICE_URL + serviceConfig.getUserOrganizationsList
        return api.get(url)
    }
}

export default new OrganizationService()
