import { useCallback, useState, ChangeEvent } from 'react'
import { IMyRequestPanelProps } from './myRequestPanel'
import { MyRegistryRequest } from '../../models/myRegistryRequest'
import { useAppDispatch, useAppSelector } from '../../config/hooks'

export function MyRequestPanelLogic({
    getData,
    dataResponse,
}: IMyRequestPanelProps) {
    const dispatch = useAppDispatch()

    const [currPage, setCurrPage] = useState<number>(1)
    const [pageCount, setPageCount] = useState(10)

    const totalPage = dataResponse?.pageInfo?.total
        ? dataResponse?.pageInfo.total
        : -1

    const afterPageClicked = (pageNumber: number) => {
        setCurrPage(pageNumber)

        dispatch(
            getData({
                appName: '',
                offset: pageNumber * pageCount - pageCount,
                count: pageCount,
            } as MyRegistryRequest)
        )
    }

    const handlePageCount = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLSelectElement
            if (target.value != undefined) {
                const updatedPageCount = parseInt(target.value)
                setPageCount(updatedPageCount)
                getData({
                    appName: '',
                    offset: currPage * pageCount - pageCount,
                    count: updatedPageCount,
                } as MyRegistryRequest)
                if ((currPage - 1) * pageCount + 1 > totalPage) setCurrPage(1)
            }
        },
        [pageCount]
    )

    return {
        currPage,
        afterPageClicked,
        pageCount,
        handlePageCount,
    }
}
