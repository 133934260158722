import { FunctionComponent } from 'react'
import styled from 'styled-components'
import Nav from 'react-bootstrap/Nav'
import PageLinksPanel from './pageLinks'
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { useNavigate } from 'react-router-dom'
import { Icon } from '@iconify/react'
import StringUtils from '../lib/stringUtils'

const HeaderMainContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
`
const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    background-color: rgb(101, 49, 113);
    background-image: ${(props) =>
        `url('${process.env.PUBLIC_URL}/background-l1-mosaic.svg')`};
    background-position-x: 50%;
    background-position-y: 50%;
    background-repeat: no-repeat;
    background-size: 100%;
    width: 100%;
    height: 4rem;
`

const CatalogHeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`
const HeaderMenuDiv = styled.div`
    display: flex;
    justify-content: left;
    padding-right: 1rem;
`
const HeaderImageDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: left;
    //width: 95%;
    padding: 0.6rem;
    margin-left: 0.5rem;
`
const StyledNavLink = styled(Nav.Link)`
    font-size: 14px;
    line-height: 1;
    padding: 26px 8px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #fff;
    &:active,
    &:hover {
        color: #ffc42a;
        font-weight: 500;
    }
    &:focus {
        color: #fff;
    }
`

const TitleIcon = styled(Icon)`
    display: flex;
    width: 2.2rem;
    height: 2.2rem;
    cursor: pointer;
    margin-right: 0.25rem;
    color: #fff; //#00c7fd;
`
const OHTitleText = styled.a`
    color: #fff; //rgb(0, 199, 253);
    font-family: 'IntelOne Display', sans-serif;
    font-size: 1rem;
    line-height: 1.4;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    justify-content: center;
    padding-top: 0.5rem;
    &:hover {
        color: #fff; //rgb(0, 199, 253);
        font-weight: 500;
    }
`

const HeaderImageComponent = () => (
    <HeaderImageDiv>
        <img
            src="/intel-header-logo.svg"
            height="40"
            width="76"
            alt="intel logo"
        />
    </HeaderImageDiv>
)

const offCanvasNav = () => {
    return (
        <>
            {[false].map((expand) => (
                <Navbar expand={expand}>
                    <Container fluid>
                        <Navbar.Toggle
                            style={{
                                backgroundColor:
                                    'linear-gradient(180deg, #0d1b35, #1e3a84)',
                            }}
                            aria-controls={`offcanvasNavbar-expand-${expand}`}
                            id="offcanvasNavButton"
                        />
                        <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-${expand}`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                            placement="start"
                            style={{ width: '250px' }}
                        >
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title
                                    id={`offcanvasNavbarLabel-expand-${expand}`}
                                ></Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <PageLinksPanel></PageLinksPanel>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </Container>
                </Navbar>
            ))}
        </>
    )
}
export interface IHeaderPanelProps {
    isPublicSearch?: boolean
}

export const HeaderPanel: FunctionComponent<IHeaderPanelProps> & {} = ({
    isPublicSearch,
}: IHeaderPanelProps) => {
    let navigate = useNavigate()
    let isCatalog = false
    if (window.location.pathname.indexOf('/catalog') > -1) isCatalog = true
    const HeaderAppTitleComponent = () => (
        <HeaderImageDiv>
            <TitleIcon
                icon="la:cloud-meatball"
                onClick={() => {
                    navigate('/catalog')
                }}
            />
            <OHTitleText
                onClick={() => navigate('/catalog')}
                href={
                    isPublicSearch
                        ? StringUtils.resolve('/public/catalog')
                        : StringUtils.resolve('/catalog')
                }
            >
                Optimization Hub
            </OHTitleText>
        </HeaderImageDiv>
    )
    return (
        <HeaderMainContainer>
            {isPublicSearch && isCatalog ? (
                <CatalogHeaderContainer>
                    <HeaderImageComponent></HeaderImageComponent>{' '}
                    <HeaderAppTitleComponent></HeaderAppTitleComponent>
                </CatalogHeaderContainer>
            ) : isCatalog ? (
                <CatalogHeaderContainer>
                    <HeaderMenuDiv>{offCanvasNav()}</HeaderMenuDiv>
                    <HeaderImageComponent></HeaderImageComponent>
                    <HeaderAppTitleComponent></HeaderAppTitleComponent>
                </CatalogHeaderContainer>
            ) : (
                <HeaderContainer>
                    <HeaderMenuDiv>{offCanvasNav()}</HeaderMenuDiv>
                    <HeaderImageComponent></HeaderImageComponent>
                    <HeaderAppTitleComponent></HeaderAppTitleComponent>
                </HeaderContainer>
            )}
        </HeaderMainContainer>
    )
}

export default HeaderPanel
