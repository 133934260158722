import { createAsyncThunk, createSlice , PayloadAction} from '@reduxjs/toolkit'
import { LoadingState } from '../models/loadingState'
import { UpdateShoppingCartRequest } from '../models/updateShoppingCartRequest'
import ShoppingCartService from '../services/shoppingCartService'
import { ShoppingCartRequest } from '../models/shoppingCartRequest'
import { CheckoutRequest } from '../models/checkoutRequest'

const initialState = {
    data: null,
    loading: LoadingState.Idle,
    error: null,
}

export const getCartItems = createAsyncThunk(
    'getCartItems',
    async (shoppingCartRequest: ShoppingCartRequest) => {
        return await ShoppingCartService.getCartItems(shoppingCartRequest)
    }
)

export const updateCartItems = createAsyncThunk(
    'updateCartItems',
    async (updateShoppingCartRequest: UpdateShoppingCartRequest) => {
        return await ShoppingCartService.updateCartItems(
            updateShoppingCartRequest
        )
    }
)

export const preCheckoutItems = createAsyncThunk(
    'preCheckoutItems',
    async () => {
        return await ShoppingCartService.preCheckoutItems()
    }
)

export const checkoutItems = createAsyncThunk(
    'checkoutItems',
    async (checkoutRequest: CheckoutRequest) => {
        return await ShoppingCartService.checkoutItems(checkoutRequest)
    }
)

// export const emptyCart = createAsyncThunk('emptyCart', async () => {
//     return await ShoppingCartService.checkoutItems()
// })

const getCartItemsSlice = createSlice({
    name: 'getCartItems',
    initialState: Object.assign({}, initialState, { lastCart: {} }),
    reducers: {
        emptyCart(state, action: PayloadAction<any>) {
            state.data = null
            state.error = null
            state.loading = LoadingState.Idle
            state.lastCart = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(getCartItems.pending, (state, _) => {
            if (state.loading === LoadingState.Idle) {
                state.loading = LoadingState.Pending
            }
        })
        .addCase(getCartItems.fulfilled, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.data = action.payload.data
            }
        })
        .addCase(getCartItems.rejected, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.error = action.error
            }
        })
    },
})

const updateCartItemsSlice = createSlice({
    name: 'updateCartItems',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(updateCartItems.pending, (state, _) => {
            if (state.loading === LoadingState.Idle) {
                state.loading = LoadingState.Pending
            }
        })
        .addCase(updateCartItems.fulfilled, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.data = action.payload.data
            }
        })
        .addCase(updateCartItems.rejected, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.error = action.error
            }
        })
    },
})

const preCheckoutItemsSlice = createSlice({
    name: 'preCheckoutItems',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(preCheckoutItems.pending, (state, _) => {
            if (state.loading === LoadingState.Idle) {
                state.loading = LoadingState.Pending
            }
        })
        .addCase(preCheckoutItems.fulfilled, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.data = action.payload.data
            }
        })
        .addCase(preCheckoutItems.rejected, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.error = action.error
            }
        })
    },
})

const checkoutItemsSlice = createSlice({
    name: 'checkoutItems',
    initialState: initialState,
    reducers: {
        emptyCheckoutItems(state, action: PayloadAction<any>) {
            state.data = null
            state.error = null
            state.loading = LoadingState.Idle
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(checkoutItems.pending, (state, _) => {
            if (state.loading === LoadingState.Idle) {
                state.loading = LoadingState.Pending
            }
        })
        .addCase(checkoutItems.fulfilled, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.data = action.payload.data
            }
        })
        .addCase(checkoutItems.rejected, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.error = action.error
            }
        })
    },
})

export const getCartItemsResult = getCartItemsSlice.reducer
export const updateCartResult = updateCartItemsSlice.reducer
export const preCheckoutItemsResult = preCheckoutItemsSlice.reducer
export const checkoutItemsResult = checkoutItemsSlice.reducer
export const { emptyCheckoutItems } = checkoutItemsSlice.actions
export const { emptyCart } = getCartItemsSlice.actions
