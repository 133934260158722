import Table from 'react-bootstrap/Table'
import styled from 'styled-components'

const StyledTable = styled(Table)`
    display: block;
    height: 14em;
    overflow: auto;
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.15);
    position: relative;
    max-width: 60rem !important;
`

const StickyHeader = styled.th`
    position: sticky;
    top: 0;
    background-color: white !important;
`

interface IBenchmarkTableProps {
    benchmarks: any
}

function BenchmarkTable({ benchmarks }: IBenchmarkTableProps) {
    if (!benchmarks) return null
    const metrics: any = {}
    benchmarks[0]['kpis'].forEach((k: any) => {
        if (metrics[k.metric] === undefined) metrics[k.metric] = true
    })
    const table = benchmarks.map((bm: any) => {
        let m = new Map()
        bm['kpis'].forEach((kpi: any) =>
            m.set(kpi['metric'], `${kpi['value']} ${kpi['unit']}`)
        )

        return {
            instanceType: bm['instanceType'],
            metrics: m,
        }
    })

    return (
        <StyledTable striped responsive="md">
            <thead>
                <tr>
                    <StickyHeader></StickyHeader>
                    {benchmarks.map((b: any) => (
                        <StickyHeader>{b['instanceType']}</StickyHeader>
                    ))}
                </tr>
            </thead>
            <tbody>
                {Object.keys(metrics).map((metric: any) => (
                    <tr>
                        <td>{metric}</td>
                        {table.map((instance: any) => (
                            <td>{instance['metrics'].get(metric)}</td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </StyledTable>
    )
}

export default BenchmarkTable
