import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { LoadingState } from '../models/loadingState'
import OrganizationService from '../services/organizationService'

const initialState = {
    data: null,
    loading: LoadingState.Idle,
    error: null,
}

// First, create the thunk
export const getOrganization = createAsyncThunk('getOrganization', async () => {
    return await OrganizationService.getOrganization()
})

// Then, handle actions in your reducers:
const getOrganizationSlice = createSlice({
    name: 'getOrganization',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(getOrganization.pending, (state, _) => {
            if (state.loading === LoadingState.Idle) {
                state.loading = LoadingState.Pending
            }
        })
        .addCase(getOrganization.fulfilled, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.data = action.payload.data
                state.error = null
            }
        })
        .addCase(getOrganization.rejected, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.error = action.error
            }
        })
    },
})

// First, create the thunk
export const getUserOrganizationsList = createAsyncThunk(
    'getUserOrganizationsList',
    async () => {
        return await OrganizationService.getUserOrganizationsList()
    }
)

// Then, handle actions in your reducers:
const getUserOrganizationsListSlice = createSlice({
    name: 'getUserOrganizationsList',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(getUserOrganizationsList.pending, (state, _) => {
            if (state.loading === LoadingState.Idle) {
                state.loading = LoadingState.Pending
            }
        })
        .addCase(getUserOrganizationsList.fulfilled, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.data = action.payload.data
                state.error = null
            }
        })
        .addCase(getUserOrganizationsList.rejected, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.error = action.error
            }
        })
    },
})

export const organizationInfoResult = getOrganizationSlice.reducer
export const userOrganizationsListResult = getUserOrganizationsListSlice.reducer
