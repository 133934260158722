import {
    useState,
    useCallback,
    FormEvent,
    ChangeEvent,
    useEffect,
    SetStateAction,
} from 'react'
import { useAppDispatch, useAppSelector } from '../../config/hooks'
import { IUploadRegistryPanelProps } from './addNewOptimizationPanel'
import { getFiltersValues } from '../../store/catalog.slice'
import { useNavigate } from 'react-router'
import { useLocation } from 'react-router-dom'

export function AddNewOptimizationContainerLogic({
    id,
    getRegistryResult,
    formRef,
    isController,
    isControllerPage,
}: IUploadRegistryPanelProps) {
    const dispatch = useAppDispatch()
    const location = useLocation()
    const [optimizationName, setOptimizationName] = useState<string>('')
    const [validated, setValidated] = useState(false)
    const [additionalProperties, setAdditionalProperties] = useState<any>(null)
    const [status, setStatus] = useState<string>('')
    const [activeStep, setActiveStep] = useState(0)
    const [optimizationSummary, setOptimizationSummary] = useState<string>('')
    const [workload, setWorkload] = useState<string>('')
    const [valueProposition, setValueProposition] = useState<string>('')
    const [optimizationOwnerEmail, setOptimizationOwnerEmail] =
        useState<string>('')
    const [gProfilerFilter, setGProfilerFilter] = useState<string>('')
    const [usageGuidance, setUsageGuidance] = useState<string>('')
    const [performance_details, setPerformance_details] = useState<string>('')
    const [tags, setTags] = useState<string>('')
    const [approvalStatus, setApprovalStatus] = useState<any>(false)
    const [isPremium, setIsPremium] = useState<any>(false)
    const [scale, setScale] = useState<any>([])
    const [legalClaim, setLegalClaim] = useState<any>([])
    const [deployment, setDeployment] = useState<any>([])
    const [useCase, setUseCase] = useState<any>([])
    const [platform, setPlatform] = useState<any>([])
    const [feature, setFeature] = useState<any>([])
    const [CSP, setCSP] = useState<any>([])
    const [swApplication, setSWApplication] = useState<any>([])
    const [optimizationType, setOptimizationType] = useState<any>([])
    const [PCRApproved, setPCRApproved] = useState<any>([])
    const [PDTApproved, setPDTApproved] = useState<any>([])
    const [readonly, setReadonly] = useState(false)
    const [visibility, setVisibility] = useState<any>([])
    const navigate = useNavigate()
    const [existingFilesList, setExistingFilesList] = useState<
        {
            id: string
            isDeleted: boolean
            fileName: string
            fileAccessGroups: []
        }[]
    >([])

    useEffect(() => {
        if (getRegistryResult) {
            const {
                additionalFields: { additionalProperties },
            } = getRegistryResult
            setAdditionalProperties(additionalProperties)
            setIsPremium(getRegistryResult?.isPremium)
            if (getRegistryResult?.decision === 'Approved')
                setApprovalStatus(true)
            if (getRegistryResult.status) setStatus(getRegistryResult.status)

            let existingFileArr: {
                id: string
                isDeleted: boolean
                fileName: string
                fileAccessGroups: []
            }[] = []
            if (getRegistryResult?.attachmentAccessGroups) {
                Object.entries(getRegistryResult?.attachmentAccessGroups).map(
                    (item: any, index: number) => {
                        existingFileArr.push({
                            id: `${index + 1}`,
                            isDeleted: false,
                            fileName: item[0],
                            fileAccessGroups: item[1],
                        })
                    }
                )
            }
            if (getRegistryResult?.fileNames) {
                getRegistryResult?.fileNames.map((file: any) => {
                    const objFile = existingFileArr.filter(
                        (item: any) => item.fileName === file
                    )

                    if (objFile && objFile.length == 0) {
                        existingFileArr.push({
                            id: `${existingFileArr.length + 1}`,
                            isDeleted: false,
                            fileName: file,
                            fileAccessGroups: [],
                        })
                    }
                })
            }
            setExistingFilesList(existingFileArr)

            if (getRegistryResult?.additionalFields?.accessGroups) {
                setVisibility(
                    getRegistryResult?.additionalFields?.accessGroups.map(
                        (val: any) => ({
                            value: val,
                            label: val,
                        })
                    )
                )
            }
        }
    }, [getRegistryResult])

    const tagValues = useAppSelector<any>(
        (state: any) => state.getFiltersValuesResult.data
    )
    useEffect(() => {
        let filtersParm = `filter=Platform&filter=Feature&filter=Scale%20level&filter=Type&filter=CSP&filter=Workloads&filter=Legal%20Claim&filter=Deployment&filter=Use%20Case&filter=Optimization%20Type&filter=PDT%20Approved&filter=PCR%20Approved&filter=Visibility&filter=AttachmentGroups`
        dispatch(getFiltersValues(filtersParm))
    }, [])

    const onClearAll = useCallback((event: FormEvent<HTMLElement>) => {
        event.preventDefault()
        setValidated(false)
        setOptimizationName('')
        window.location.reload()
    }, [])

    const onCancle = useCallback((event: FormEvent<HTMLElement>) => {
        event.preventDefault()
        setValidated(false)

        if (isControllerPage) navigate('/allUploadRequest')
        else if (id && !isControllerPage) navigate('/myRequest')
        else navigate('/catalog')
    }, [])

    const onNextClick = useCallback(
        (event: FormEvent<HTMLElement>) => {
            if (
                (formRef.current &&
                    formRef.current.checkValidity() === false) ||
                scale.length === 0 ||
                legalClaim.length === 0 ||
                feature.length === 0 ||
                platform.length === 0 ||
                deployment.length === 0 ||
                CSP.length === 0 ||
                useCase.length === 0 ||
                optimizationType.length === 0 ||
                PDTApproved.length === 0 ||
                PCRApproved.length === 0 ||
                swApplication.length === 0
            ) {
                if (!readonly) {
                    setValidated(true)
                    return
                }
            }
            setActiveStep(activeStep + 1)
        },
        [
            activeStep,
            validated,
            scale,
            legalClaim,
            platform,
            feature,
            deployment,
            CSP,
            useCase,
            optimizationType,
            PDTApproved,
            PCRApproved,
            swApplication,
            readonly,
        ]
    )
    const onPreviousClick = useCallback(
        (event: FormEvent<HTMLElement>) => {
            event.preventDefault()

            setValidated(false)
            setActiveStep(activeStep - 1)
        },
        [activeStep, validated]
    )
    const onOptNameChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLTextAreaElement
            if (target.value) {
                setOptimizationName(target.value)
            } else setOptimizationName('')
        },
        [optimizationName]
    )
    const onOptimizationSummaryChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLTextAreaElement
            if (target.value) {
                setOptimizationSummary(target.value)
            } else setOptimizationSummary('')
        },
        [optimizationSummary]
    )
    const onWorkloadChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLTextAreaElement
            if (target.value) {
                setWorkload(target.value)
            } else setWorkload('')
        },
        [workload]
    )
    const onValuePropositionChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLTextAreaElement
            if (target.value) {
                setValueProposition(target.value)
            } else setValueProposition('')
        },
        []
    )
    const onOwnerEmailChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLTextAreaElement
            if (target.value) {
                setOptimizationOwnerEmail(target.value)
            } else setOptimizationOwnerEmail('')
        },
        []
    )
    const onUsageGuidanceChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLTextAreaElement
            if (target.value) {
                setUsageGuidance(target.value)
            } else setUsageGuidance('')
        },
        []
    )
    const onPerfDetailsChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLTextAreaElement
            if (target.value) {
                setPerformance_details(target.value)
            } else setPerformance_details('')
        },
        []
    )
    const ongProfilerFilterChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLTextAreaElement
            if (target.value) {
                setGProfilerFilter(target.value)
            } else setGProfilerFilter('')
        },
        []
    )
    const onTagsChange = useCallback((event: ChangeEvent<HTMLElement>) => {
        const target = event.currentTarget as HTMLTextAreaElement
        if (target.value) {
            setTags(target.value)
        } else setTags('')
    }, [])

    return {
        onCancle,
        validated,
        optimizationName,
        additionalProperties,
        setValidated,
        tagValues,
        status,
        activeStep,
        onNextClick,
        onPreviousClick,
        onOptNameChange,
        optimizationSummary,
        workload,
        onWorkloadChange,
        onOptimizationSummaryChange,
        valueProposition,
        onValuePropositionChange,
        optimizationOwnerEmail,
        onOwnerEmailChange,
        usageGuidance,
        onUsageGuidanceChange,
        performance_details,
        onPerfDetailsChange,
        gProfilerFilter,
        ongProfilerFilterChange,
        tags,
        onTagsChange,
        setOptimizationName,
        setOptimizationSummary,
        setWorkload,
        setValueProposition,
        setOptimizationOwnerEmail,
        setUsageGuidance,
        setPerformance_details,
        setGProfilerFilter,
        setTags,
        approvalStatus,
        setApprovalStatus,
        onClearAll,
        scale,
        setScale,
        legalClaim,
        setLegalClaim,
        platform,
        setPlatform,
        feature,
        setFeature,
        deployment,
        setDeployment,
        useCase,
        setUseCase,
        CSP,
        setCSP,
        swApplication,
        setSWApplication,
        optimizationType,
        setOptimizationType,
        PCRApproved,
        setPCRApproved,
        PDTApproved,
        setPDTApproved,
        readonly,
        setReadonly,
        visibility,
        setVisibility,
        isPremium,
        setIsPremium,
        existingFilesList,
        setExistingFilesList,
    }
}
