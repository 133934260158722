import { Typography } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { useAppSelector } from '../../../../config/hooks'
import { defaultSelectedDomainSearchDist } from '../../DashboardPanel'
import BarChart, { BarChartData } from '../../shared/charts/BarChart'

const topSearchMissesChartTitleSx = { fontSize: '1rem', marginLeft: '2rem' }

const topMissesChartHeaders: [string, string, { role: string }] = [
    'Term',
    '# Search Misses',
    { role: 'annotation' },
]

interface TopSearchMissesByDomainProps {
    selectedDomainSearchDist: string
}

const TopSearchMissesByDomain: FC<TopSearchMissesByDomainProps> = ({
    selectedDomainSearchDist,
}) => {
    const searchMissesByDomainData = useAppSelector<any>(
        (state) => state.getNoHitsResult.data
    )
    const searchMissesByDomainLoading = useAppSelector(
        (state) => state.getNoHitsResult.loading
    )
    const allSearchMissesData = useAppSelector<any>(
        (state) => state.getAllNoHitsResult.data
    )
    const allSearchMissesLoading = useAppSelector<any>(
        (state) => state.getAllNoHitsResult.loading
    )

    const [chartData, setChartData] = useState<BarChartData>([
        topMissesChartHeaders,
    ])

    useEffect(() => {
        if (selectedDomainSearchDist !== defaultSelectedDomainSearchDist) {
            if (
                searchMissesByDomainData !== null &&
                searchMissesByDomainData?.data?.[selectedDomainSearchDist]
            ) {
                const newChartData = Object.entries(
                    searchMissesByDomainData.data[selectedDomainSearchDist] as {
                        [key: string]: number
                    }
                )
                    .filter(([key, _]) => key !== '')
                    .sort(([_A, valueA], [_B, valueB]) => valueB - valueA)
                    .map(([name, value]) => [name, value, value])
                    .slice(0, 10)

                setChartData([topMissesChartHeaders, ...newChartData])
            }
        } else if (
            allSearchMissesData !== null &&
            allSearchMissesData?.data?.CountDomainsPerTerm
        ) {
            const newChartData = Object.entries(
                allSearchMissesData.data.CountDomainsPerTerm as {
                    [key: string]: number
                }
            )
                .filter(([key, _]) => key !== '')
                .sort(([_A, valueA], [_B, valueB]) => valueB - valueA)
                .map(([name, value]) => [name, value, value])
                .slice(0, 10)

            setChartData([topMissesChartHeaders, ...newChartData])
        } else {
            setChartData([topMissesChartHeaders])
        }
    }, [
        searchMissesByDomainData,
        allSearchMissesData,
        selectedDomainSearchDist,
    ])

    return (
        <>
            <Typography variant="h6" sx={topSearchMissesChartTitleSx}>
                Top Search Misses
            </Typography>
            <BarChart
                chartData={chartData}
                dataLoadingState={[
                    searchMissesByDomainLoading,
                    allSearchMissesLoading,
                ]}
                fixedHeight
            />
        </>
    )
}

export default TopSearchMissesByDomain
