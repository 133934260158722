import {
    ChangeEvent,
    FunctionComponent,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react'
import styled from 'styled-components'
import StringUtils from '../../lib/stringUtils'
import { Button, Form, Spinner } from 'react-bootstrap'
import { KeyboardEvent } from 'react'
import { getAnswerFromChatbot } from '../../store/chatbot.slice'
import { LoadingState } from '../../models/loadingState'
import { ChatbotRequest } from '../../models/chatbotRequest'
import { TypeAnimation } from 'react-type-animation'

const ChatbotFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
`

const ChatbotFormComponent = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: no-wrap;
    //padding-bottom: 3rem;
    //min-height: 60rem;
`
const ChatContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: flex-start;
    flex: 1 1 0%;
    max-width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
`
const ChatbotMainComponent = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: center;
    height: 100%;
`
const ChatbotHistoryComponent = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: space-between;
    max-width: 13rem;
    overflow: hidden;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    border: 0 solid #e3e3e3;
    box-sizing: border-box;
    background-color: #f9f9f9;
    height: 100%;
    @media screen and (max-width: 700px) {
        display: none;
    }
`
const ChatbotHistoryContainer = styled.div`
    display: flex;
    flex-direction: column;
`
const ChatbotHistoryContent = styled.div`
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: 0 0.5rem;
    min-height: 25rem;
    max-height: 35rem;
    @media screen and (min-height: 501) and (max-height: 700px) {
        max-height: 30rem;
    }
    @media screen and (min-height: 701) and (max-height: 900) {
        max-height: 35rem;
    }
    @media screen and (min-height: 901) and (max-height: 1500) {
        max-height: 40rem;
    }
    @media screen and (min-height: 1501) {
        max-height: 55rem;
    }

    /* scroll style-width */
    ::-webkit-scrollbar {
        height: 1rem;
        width: 0.5rem;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        // background: #f1f1f1;
        // border-radius: 20px;
        background-color: transparent;
        border-radius: 9999px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        // background: #888;
        // border-radius: 20px;
        --tw-border-opacity: 1;
        background-color: hsla(0, 0%, 89%, 0.8);
        border-color: rgba(255, 255, 255, var(--tw-border-opacity));
        border-radius: 9999px;
        border-width: 1px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
`
const MessageScrollContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    position: relative;
    height: 30rem;
    @media screen and (max-height: 500px) {
        height: 20rem;
    }
    @media screen and (min-height: 501) and (max-height: 700px) {
        height: 20rem;
    }
    @media screen and (min-height: 701) and (max-height: 900) {
        height: 30rem;
    }
    @media screen and (min-height: 901) and (max-height: 1500) {
        height: 40rem;
    }
    @media screen and (min-height: 1501) and (max-height: 2500) {
        height: 50rem;
    }
    @media screen and (min-height: 2501) {
        height: 60rem;
    }

    /* scroll style-width */
    ::-webkit-scrollbar {
        height: 1rem;
        width: 0.5rem;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        // background: #f1f1f1;
        // border-radius: 20px;
        background-color: transparent;
        border-radius: 9999px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        // background: #888;
        // border-radius: 20px;
        --tw-border-opacity: 1;
        background-color: hsla(0, 0%, 89%, 0.8);
        border-color: rgba(255, 255, 255, var(--tw-border-opacity));
        border-radius: 9999px;
        border-width: 1px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
`
const MessageComponent = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;
`
const Container = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    alignitems: center;
    height: 100%;
    width: 70%;
    margin-top: 1rem;
    @media screen and (max-width: 500px) {
        width: 100%;
    }
`

const InputContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
`

const MessageWrapper = styled.div`
    display: grid;
    grid-template-columns: 60px auto;
    min-height: 100px;
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    width: 100%;
    @media screen and (max-width: 500px) {
        width: 50%;
    }
`
const MessageTextContainer = styled.div`
    @media screen and (max-width: 500px) {
        width: 300px;
        paddingright: 0.5rem;
    }
`
const HistoryWrapper = styled.div`
    display: flex;
    justify-content: left;
    flex-direction: column;
    padding: 20px 30px;
    margin-bottom: 10px;
    border-radius: 10px;
    background-color: #fff;
`
const HistoryText = styled.label`
    font-family: 'IntelOne Text';
    font-size: 1rem;
    font-weight: 400;
    cursor: pointer;
`
const HistoryNavWrapper = styled.div`
    display: flex;   
    padding-left: 0.5rem;
    background-color: #fff;
    border: none;
    top:50%
    position:fixed;
    left: 0;
    @media screen and (max-width: 700px) {
        display: none;
    }
    
`
const PresentationWrapper = styled.section`
    display: flex;
    flex-direction: column;
    justification-content: center;
    height: 100%;
    width: 100%;
    overflow: hidden;
`
const InputWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-grow: 1;    
    overflow: hidden;
    height: 55px;
    background-color: #fff;
    border 1px solid rgba(0, 0, 0, .15);   
    border-radius: 1rem; 
    margin-bottom:3rem;
    width:70%;
    margin-left:1.5rem;
    @media screen and (max-width: 500px) {
        width: 90%;
    }
   
}
`
const InputText = styled(Form.Control)`   
    height: 52px;
    overflow-y: hidden;
    padding-right: 3rem;
    padding-left: 1rem;
    padding-bottom: 0.6rem;
    padding-top: 0.6rem;
    background-color: transparent;    
    resize: none;
    width: 100%;
    margin: 0;
    border:none;   
    border-width: 0;   
    appearance: none;
    font-size: 1rem;
    line-height: 1.5rem;
    &:focus {
        border:none;   
        border-width: 0;
        border-radius:0;   
        outline: none;       
        background-color: transparent;
        border: 0px none transparent;
    }
    &:hover {
        border:none;   
        border-width: 0;  
        border-radius:0; 
    }
}
`
const HistoryNavButton = styled.button`
    border: none;
    outline: none;
    cursor: pointer;
    height: 100%;
    background-color: #fff;

    &:focus {
        outline: none;
        background-color: #fff;
        border: 0px none transparent;
    }
    &:hover {
        cursor: pointer;
        background-color: #fff;
        transform: translateY(0.15rem) rotate(0deg) translateZ(0px);
        transform: translateY(-0.15rem) rotate(0deg) translateZ(0px);
    }
`
const GoButton = styled(Button)`
    border: none;
    outline: none;
    cursor: pointer;
    transition-duration: 0.15s;
    transition-property: color, background-color, border-color,
        text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
    border-width: 1px;
    border-radius: 0.5rem;
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
    right: 0.75rem;
    bottom: 0.75rem;
    //position: absolute;
    text-transform: none;
    background-color: transparent;

    &:focus {
        outline: none;
        background-color: transparent;
        border: 0px none transparent;
    }
    &:hover {
        outline: none;
        cursor: pointer;
        background-color: transparent;
    }
    &:disabled {
        opacity: 0.1;
        --tw-text-opacity: 1;
        color: rgba(180, 180, 180, var(--tw-text-opacity));
    }
`
const ClearButton = styled(Button)`
    width: 8rem;
    height: 60px;
    background-color: #ff9d84;
    border: none;
    border-radius: 10px;
    font-size: 16px;
    font-weight: bold;
    margin-left: 1rem;
    &:hover {
        cursor: pointer;
        background-color: #ff886b;
    }
`
const MainNavButtonDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 72px;
    border: 0 solid #e3e3e3;
`
const ChildNavInButtonDiv = styled.div`
    background: #9b9b9b;
    transform: translateY(0.15rem) rotate(0deg) translateZ(0px);
    border-radius: 9999px;
    width: 0.25rem;
    height: 0.75rem;
    border: 0 solid #e3e3e3;
`
const ChildNavOutButtonDiv = styled.div`
    background: #9b9b9b;
    transform: translateY(-0.15rem) rotate(0deg) translateZ(0px);
    border-radius: 9999px;
    width: 0.25rem;
    height: 0.75rem;
    border: 0 solid #e3e3e3;
`
const HeaderWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
`
const TopMessageHeader = styled.h3`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 2.5rem;
    z-index: 10;
    top: 0;
    position: sticky;
    font-family: 'IntelOne Display';
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 1.25rem;
    vertical-align: middle;
    background: var(--theme-light-gray-50, #fff);
    margin-left: 1rem;
    @media screen and (max-width: 700px) {
        font-size: 1rem;
    }
`
const MessageTextP = styled.p`
    display: flex;
    flex-wrap: wrap;
    font-family: 'IntelOne Text';
    font-size: 1rem;
    font-weight: 400;
    @media screen and (max-width: 500px) {
        width: 300px;
    }
`
const LinkTextP = styled(MessageTextP)`
    font-size: 0.9rem;
`

export interface IChatbotPanelProps {
    getAnswerFromChatbot: typeof getAnswerFromChatbot
    chatbotAnswer: any
    chatbotLoading: LoadingState
    chatbotError: any
}

export const UserImage = (userIntitials: string) => {
    return (
        <span
            style={{
                height: '20px',
                width: '20px',
                background: '#653171', //'#ab68ff',
                borderRadius: '25px',
                color: '#fff',
                padding: '0.5rem',
                fontWeight: '500',
            }}
        >
            {userIntitials}
        </span>
    )
}

const ChatbotPanel: FunctionComponent<IChatbotPanelProps> = ({
    getAnswerFromChatbot,
    chatbotAnswer,
    chatbotLoading,
    chatbotError,
}: IChatbotPanelProps) => {
    const inputRef = useRef<HTMLInputElement>(null)
    const aScrollDivRef = useRef<HTMLInputElement>(null)
    const [isRenderLinks, setIsRenderLinks] = useState(false)
    const [expandHistory, setExpandHistory] = useState(true)
    const [inputText, setInputText] = useState('')
    const [messages, setMessages] = useState<
        {
            role: string
            content: string
            links: string[]
            isHistoryView: boolean
        }[]
    >([
        {
            role: 'assistant',
            content: `Hello, how can I help you today?`,
            links: [],
            isHistoryView: false,
        },
    ])
    const [history, setHistory] = useState<
        { question: string; answer: string; links: string[] }[]
    >([])

    const handleSubmit = async () => {
        const prompt = {
            role: 'user',
            content: inputText,
            links: [],
            isHistoryView: false,
        }
        if (inputText.trim() != '') {
            setMessages([...messages, prompt])

            //get answer
            const chatRequest = new ChatbotRequest()
            chatRequest.question = inputText
            getAnswerFromChatbot(chatRequest)
        }
    }
    useEffect(() => {
        if (
            aScrollDivRef &&
            aScrollDivRef.current !== undefined &&
            aScrollDivRef.current !== null
        ) {
            aScrollDivRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
            })
        }
    }, [messages])

    useEffect(() => {
        if (chatbotAnswer) {
            //console.log(chatbotAnswer)

            setMessages((messages) => [
                ...messages,
                {
                    role: 'assistant',
                    content: chatbotAnswer?.answer,
                    links: chatbotAnswer?.links,
                    isHistoryView: false,
                },
            ])
            setHistory((history) => [
                ...history,
                {
                    question: inputText,
                    answer: chatbotAnswer?.answer,
                    links: chatbotAnswer?.links,
                },
            ])
            setInputText('')
            if (
                inputRef &&
                inputRef.current !== undefined &&
                inputRef.current !== null
            )
                inputRef.current.value = ''

            setTimeout(() => setIsRenderLinks(true), 1500)
            if (
                aScrollDivRef &&
                aScrollDivRef.current !== undefined &&
                aScrollDivRef.current !== null
            ) {
                aScrollDivRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'end',
                })
            }
        }
    }, [chatbotAnswer])
    const handleClear = () => {
        setMessages([])
        setHistory([])
    }
    const handleHistoryMessageClick = (index: number) => {
        if (history.length > 0)
            setMessages([
                {
                    role: 'user',
                    content: history[index].question,
                    links: [],
                    isHistoryView: false,
                },
                {
                    role: 'assistant',
                    content: history[index].answer,
                    links: history[index].links,
                    isHistoryView: true,
                },
            ])
    }

    const onInputTextChange = useCallback((event: ChangeEvent<HTMLElement>) => {
        const target = event.currentTarget as HTMLTextAreaElement
        setInputText(target.value)
    }, [])
    const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault()
            handleSubmit()
        }
    }

    const renderMessage = (
        role: string,
        content: string,
        links: string[],
        index: number,
        totalMessageLength: number,
        isHistoryView: boolean
    ) => {
        return (
            <>
                <MessageWrapper key={index}>
                    <div>
                        {role === 'assistant' ? (
                            <img
                                src={StringUtils.resolve('/chatbot.png')}
                                style={{ width: '40px', height: '35px' }}
                                alt="profile avatar"
                            />
                        ) : (
                            UserImage(userIntitials)
                        )}
                    </div>
                    <MessageTextContainer>
                        {/* {role === 'assistant' &&
                        index === totalMessageLength - 1 &&
                        !isHistoryView ? (
                            <TypeAnimation
                                sequence={[content]}
                                wrapper="span"
                                speed={99}
                                style={{
                                    display: 'inline-block',
                                    fontFamily: 'IntelOne Text',
                                    fontSize: '1rem',
                                    fontWeight: '400',
                                }}
                                cursor={false}
                            />
                        ) : (
                            <>
                                <MessageTextP>{content}</MessageTextP>
                            </>
                        )} */}
                        <MessageTextP>{content}</MessageTextP>

                        {isRenderLinks && links && links.length > 0 ? (
                            <>
                                <p>
                                    <h5>Related Links: </h5>
                                </p>
                                <p>
                                    <h6>
                                        For further details, refer to these
                                        links{' '}
                                    </h6>
                                </p>

                                <LinkTextP>
                                    <ul style={{ marginLeft: '-1rem' }}>
                                        {links.map((link: string) => {
                                            return (
                                                <>
                                                    <li>
                                                        <a
                                                            href={link}
                                                            target="_blank"
                                                        >
                                                            {link}
                                                        </a>
                                                    </li>
                                                </>
                                            )
                                        })}
                                    </ul>
                                </LinkTextP>
                            </>
                        ) : (
                            ''
                        )}
                    </MessageTextContainer>
                </MessageWrapper>
                <div ref={aScrollDivRef}> </div>
            </>
        )
    }
    let userIntitials = 'You'
    const userDisplayName = localStorage.getItem('userName')
    if (userDisplayName) {
        let names = userDisplayName.split(',')
        if (names && names.length >= 2)
            userIntitials =
                names[1].trim().substring(0, 1).toUpperCase() +
                names[0].trim().substring(0, 1).toUpperCase()
    }

    return (
        <ChatbotFormContainer>
            <ChatbotFormComponent>
                {expandHistory ? (
                    <ChatbotHistoryComponent>
                        <ChatbotHistoryContainer>
                            <div
                                style={{
                                    marginTop: '1rem',
                                    padding: '0 0.5rem',
                                }}
                            >
                                <HistoryWrapper
                                    style={{
                                        fontWeight: '500',
                                    }}
                                    onClick={() =>
                                        setMessages([
                                            {
                                                role: 'assistant',
                                                content:
                                                    'Hello, how can I help you today?',
                                                links: [],
                                                isHistoryView: false,
                                            },
                                        ])
                                    }
                                >
                                    <HistoryText>New Chat</HistoryText>
                                </HistoryWrapper>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'left',
                                    padding: '1rem',
                                    marginLeft: '0.6rem',
                                }}
                            >
                                <h6> Chat History</h6>
                            </div>
                            <ChatbotHistoryContent>
                                {history.map((item: any, index) => {
                                    return (
                                        <HistoryWrapper
                                            onClick={() =>
                                                handleHistoryMessageClick(index)
                                            }
                                            key={index}
                                        >
                                            <div>
                                                <HistoryText>
                                                    {item.question.substring(
                                                        0,
                                                        15
                                                    )}
                                                    ...
                                                </HistoryText>
                                            </div>
                                        </HistoryWrapper>
                                    )
                                })}
                            </ChatbotHistoryContent>
                        </ChatbotHistoryContainer>
                        <div style={{ margin: '2rem 1rem' }}>
                            {UserImage(userIntitials)} {userIntitials}
                        </div>
                    </ChatbotHistoryComponent>
                ) : (
                    ''
                )}
                <ChatContainer>
                    <ChatbotMainComponent>
                        <HistoryNavWrapper>
                            <HistoryNavButton
                                onClick={() =>
                                    expandHistory
                                        ? setExpandHistory(false)
                                        : setExpandHistory(true)
                                }
                            >
                                {expandHistory ? (
                                    <MainNavButtonDiv>
                                        <ChildNavInButtonDiv></ChildNavInButtonDiv>
                                        <ChildNavOutButtonDiv></ChildNavOutButtonDiv>
                                    </MainNavButtonDiv>
                                ) : (
                                    <MainNavButtonDiv>
                                        <ChildNavInButtonDiv></ChildNavInButtonDiv>
                                        <ChildNavOutButtonDiv></ChildNavOutButtonDiv>
                                    </MainNavButtonDiv>
                                )}
                            </HistoryNavButton>
                        </HistoryNavWrapper>

                        <PresentationWrapper role="presentation">
                            <HeaderWrapper>
                                <Container>
                                    <TopMessageHeader>
                                        ASK this chatbot any Intel optimization
                                        related question{' '}
                                    </TopMessageHeader>
                                </Container>
                            </HeaderWrapper>
                            <MessageComponent>
                                <MessageScrollContainer>
                                    <Container>
                                        <div style={{ height: '100%' }}>
                                            {messages && messages.length > 0 ? (
                                                messages.map((el, index) => {
                                                    return renderMessage(
                                                        el.role,
                                                        el.content,
                                                        el.links,
                                                        index,
                                                        messages.length,
                                                        el.isHistoryView
                                                    )
                                                })
                                            ) : (
                                                <div>
                                                    'How can I help you today'{' '}
                                                </div>
                                            )}
                                        </div>
                                    </Container>
                                </MessageScrollContainer>
                            </MessageComponent>

                            <InputContainer>
                                <Container
                                    style={{
                                        justifyContent: 'left',
                                    }}
                                >
                                    {chatbotLoading === LoadingState.Pending ? (
                                        <div style={{ marginLeft: '1.5rem' }}>
                                            <Spinner
                                                animation="grow"
                                                variant="info"
                                                size="sm"
                                            />
                                            <Spinner
                                                animation="grow"
                                                variant="info"
                                                size="sm"
                                            />
                                            <Spinner
                                                animation="grow"
                                                variant="info"
                                                size="sm"
                                            />
                                            <span> Working on it...</span>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </Container>
                                <Container>
                                    <InputWrapper>
                                        <InputText
                                            id="prompt-textarea"
                                            rows={1}
                                            placeholder="Enter your question and press Enter…"
                                            autoFocus
                                            required
                                            onChange={onInputTextChange}
                                            onKeyDown={onKeyDown}
                                            ref={inputRef}
                                        />
                                        <GoButton onClick={handleSubmit}>
                                            <span>
                                                <img
                                                    src="/send_button.svg"
                                                    height="24"
                                                    width="24"
                                                    alt="send button image"
                                                />
                                            </span>
                                        </GoButton>
                                    </InputWrapper>
                                </Container>
                                {/* <ClearButton onClick={handleClear}>
                                Clear
                            </ClearButton> */}
                            </InputContainer>
                        </PresentationWrapper>
                    </ChatbotMainComponent>
                </ChatContainer>
            </ChatbotFormComponent>
        </ChatbotFormContainer>
    )
}

export default ChatbotPanel
