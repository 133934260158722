/************************************************
 * Copyright (C) 2021 Intel Corporation
 ************************************************/
import styled from 'styled-components'
import { FunctionComponent, Dispatch, useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { AddNewOptimizationContainerLogic } from './addNewOptimizationContainer.hook'
import FooterPanel from '../../layout/footer'
import { uploadRegistry, getRegistry } from '../../store/uploadRegistry.slice'
import { UploadRegistryRequest } from '../../models/uploadRegistryRequest'
import { State } from '../../models/state'
import { UploadRegistryResponse } from '../../models/uploadRegistryResponse'
import { clearData } from '../../store/uploadRegistry.slice'
import UserPanel from '../../layout/userProfile'
import StringUtils from '../../lib/stringUtils'
import { FormLabel } from 'react-bootstrap'

import HeaderPanel from '../../layout/header'
import AddNewOptimizationPanel from '../../components/addNewOptimization/addNewOptimizationPanel'
import { _getDecryptedValueFromStorage } from '../../lib/encryptStorage'
import { UserOtherMetaRoles } from '../../models/userRoles'

const UploadRegistryPanelContainer = styled.div`
    display: flex;
    height: ${(props) => props.theme.size.mainContainerHeight};
    width: ${(props) => props.theme.size.mainContainerWidth};
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: space-between;
    overflow-x: hidden;
    @media screen and (min-width: 64rem), screen and (min-height: 48rem) {
        /*overflow-x: auto;*/
    }
`

const UploadRegistryHorizontalContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: space-between;
`
const UploadRegistryBodyContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: center;
    background-color: ${(props) => props.theme.color.background.defaultGrey};
    padding-bottom: 15rem;
`

const UploadRegistryBodyContainerReposition = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
`

const PageLinkPanelPanelWrapper = styled.div`
    display: flex;
    margin-left: 2rem;
    @media screen and (max-width: 500px) {
        display: none;
    }
`
const MobileHeaderWrapper = styled.div`
    display: flex;
    display: none;
    @media screen and (max-width: 500px) {
        display: block;
    }
`

const UploadRegistryPanelWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: flex-start;
`
const NotAutorizeContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: left;
`
const NotAutorizeLabel = styled(FormLabel)`
    ${(props) => props.theme.typography.xLarge}
    text-align: left;
    font-weight: 400;
    font-size: 1rem;
    margin-bottom: 50rem;
    color: red;
`

export interface IUploadRegistryContainerProps {
    uploadRegistry: typeof uploadRegistry
    uploadRegistryResult: State<UploadRegistryResponse>
    getRegistry: typeof getRegistry
    getRegistryResult: any
    clearData: any
    isController: boolean
}

const AddNewOptimizationContainer: FunctionComponent<IUploadRegistryContainerProps> & {
    defaultProps: Partial<IUploadRegistryContainerProps>
} = ({
    uploadRegistry,
    uploadRegistryResult,
    getRegistry,
    getRegistryResult,
    clearData,
    isController,
}: IUploadRegistryContainerProps) => {
    const { onUploadRegistrySubmit } = AddNewOptimizationContainerLogic({
        uploadRegistry,
    } as IUploadRegistryContainerProps)
    let otherMetaPermissions: string[] = []

    let otherPermissions = _getDecryptedValueFromStorage('otherMetaPermissions')
    if (otherPermissions) {
        otherMetaPermissions = otherPermissions.split(',')
    }
    isController = otherMetaPermissions.includes(
        UserOtherMetaRoles.DataController
    )
    let intelEmpStatus = localStorage.getItem('isIntelEmployee')
    let isIntelEmployee = !StringUtils.isNullOrUndefinedOrEmpty(intelEmpStatus)
        ? Boolean(intelEmpStatus)
        : false

    let { recipeId } = useParams()
    const isControllerPage =
        window.location.pathname.indexOf('controller') > -1 ? true : false

    useEffect(() => {
        if (recipeId) {
            getRegistry({ id: recipeId, isController: isControllerPage })
        } else {
            clearData()
        }
    }, [recipeId])

    return (
        <UploadRegistryPanelContainer>
            <HeaderPanel />
            <UploadRegistryHorizontalContainer>
                <UploadRegistryBodyContainer>
                    <UploadRegistryBodyContainerReposition>
                        <UserPanel></UserPanel>
                        {isIntelEmployee ? (
                            <UploadRegistryPanelWrapper>
                                <AddNewOptimizationPanel
                                    onUploadRegistrySubmit={
                                        onUploadRegistrySubmit
                                    }
                                    uploadRegistryloading={
                                        uploadRegistryResult.loading
                                    }
                                    uploadRegistryResult={
                                        uploadRegistryResult.data
                                    }
                                    uploadRegistryError={
                                        uploadRegistryResult.error
                                    }
                                    getRegistryResult={getRegistryResult}
                                    id={recipeId}
                                    isController={isController}
                                />
                            </UploadRegistryPanelWrapper>
                        ) : (
                            <NotAutorizeContainer>
                                <NotAutorizeLabel>
                                    Permission denied!
                                </NotAutorizeLabel>
                            </NotAutorizeContainer>
                        )}
                    </UploadRegistryBodyContainerReposition>
                </UploadRegistryBodyContainer>
            </UploadRegistryHorizontalContainer>
            <FooterPanel></FooterPanel>
        </UploadRegistryPanelContainer>
    )
}

AddNewOptimizationContainer.defaultProps = {}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        uploadRegistry: (uploadRegistryRequest: UploadRegistryRequest) =>
            dispatch(uploadRegistry(uploadRegistryRequest)),
        getRegistry: (payload: any) => dispatch(getRegistry(payload)),
        clearData: () => dispatch(clearData()),
    }
}

const mapStateToProps = (state: any) => {
    return {
        uploadRegistryResult: state.uploadRegistryResult,
        getRegistryResult: state.getRegistryResult?.data,
    }
}

type StateToPropsType = ReturnType<typeof mapStateToProps>
type DispatchToPropsType = typeof mapDispatchToProps

export default connect<StateToPropsType, DispatchToPropsType>(
    mapStateToProps,
    mapDispatchToProps
)(AddNewOptimizationContainer)
