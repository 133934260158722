import { FunctionComponent, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useAppDispatch, useAppSelector } from '../../config/hooks'
import { CatalogSearchResponse } from '../../models/catalogSearchResponse'
import { LoadingState } from '../../models/loadingState'
import RecipeCard from './recipeCard'
import { DownloadCatalogResponse } from '../../models/downloadCatalogResponse'
import { RecipeFeedbackRequest } from '../../models/recipeFeedbackRequest'
import { Recipefeedbackresponse } from '../../models/recipefeedbackresponse'
import { RecipeAddFavoriteRequest } from '../../models/recipeAddFavoriteRequest'
import { RecipeAddFavoriteResponse } from '../../models/recipeAddFavoriteResponse'
import { RecipeStarRatingRequest } from '../../models/recipeStarRatingRequest'
import { RecipeStarRatingResponse } from '../../models/recipeStarRatingResponse'
import { RecipeAddVotingRequest } from '../../models/recipeAddVotingRequest'
import { RecipeAddVotingResponse } from '../../models/recipeAddVotingResponse'
import { RecipeAddLinkClickRequest } from '../../models/recipeAddLinkClickRequest'
import { getCartItems, updateCartItems } from '../../store/shoppingCart.slice'
import { ShoppingCartResponse } from '../../models/shoppingCartResponse'
import { getBenchmarks } from '../../store/catalog.slice'
import RecipeCard_dev from './recipeCard_dev'
import { isDevAndLocal } from '../../lib/host'
import { APP_DOMAIN } from '../../config/service'
import { LayoutViewPreference } from '../../models/LayoutViewPreference'
import { RecipeAddLikeResponse } from '../../models/recipeAddLikeResponse'
import { RecipeAddLikeRequest } from '../../models/recipeAddLikeRequest'
import GranulateCard from '../../components/recipe/granulateCard'

const RecipePanelContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
`
const FavoriteRecipePanelContainer = styled(RecipePanelContainer)`
    justify-content: left;
`

const ErrorTextContainer = styled.div`
    display: flex;
    margin-top: 5rem;
    justify-content: center;
`

export interface IRecipeCardPanelProps {
    recipeResult?: CatalogSearchResponse
    error?: any
    downloadCatalog: (downloadUrl: string) => void
    downloadCatalogResult: DownloadCatalogResponse
    downloadCatalogLoading: LoadingState
    downloadCatalogError?: any
    submitFeedback: (recipeFeedbackRequest: RecipeFeedbackRequest) => void
    feedbackResult: Recipefeedbackresponse
    feedbackError: any
    feedbackLoading: LoadingState
    saveFavorite: (addFavoriteRequest: RecipeAddFavoriteRequest) => void
    favoriteResult: RecipeAddFavoriteResponse
    favoriteError?: any
    favoriteLoading: LoadingState
    saveStarRating: (saveRatingRequest: RecipeStarRatingRequest) => void
    saveRatingResult: RecipeStarRatingResponse
    saveRatingError?: any
    isFavoritesLoading?: boolean
    saveVoting: (saveVotingRequest: RecipeAddVotingRequest) => void
    saveVotingResult: RecipeAddVotingResponse
    saveVotingError?: any
    getRecipeById: (id: any) => void
    saveLinkClick: (addLinkClickRequest: RecipeAddLinkClickRequest) => void
    updateCartItems: typeof updateCartItems
    updateCartResult: ShoppingCartResponse
    getCartItems: typeof getCartItems
    getCartItemsResult: ShoppingCartResponse
    getCartItemsResultLoading: LoadingState
    getCartItemsResultError: any
    resultLayoutView: any
    isIntelEmployee: boolean
    isFlameScannerLoading?: boolean
    isPublicSearch?: boolean
    saveLike: (addLikeRequest: RecipeAddLikeRequest) => void
    likeResult: RecipeAddLikeResponse
    likeError?: any
    likeLoading: LoadingState
    getClickCounts: (filters: any) => void
    clickCountsResult: any
}

const RecipeCardPanel: FunctionComponent<IRecipeCardPanelProps> = ({
    recipeResult,
    downloadCatalog,
    downloadCatalogResult,
    downloadCatalogLoading,
    downloadCatalogError,
    submitFeedback,
    feedbackResult,
    feedbackLoading,
    feedbackError,
    saveFavorite,
    favoriteResult,
    favoriteError,
    favoriteLoading,
    saveStarRating,
    saveRatingResult,
    saveRatingError,
    isFavoritesLoading,
    saveVoting,
    saveVotingResult,
    saveVotingError,
    getRecipeById,
    saveLinkClick,
    updateCartItems,
    updateCartResult,
    getCartItems,
    getCartItemsResult,
    getCartItemsResultLoading,
    getCartItemsResultError,
    resultLayoutView,
    isIntelEmployee,
    isFlameScannerLoading,
    isPublicSearch,
    saveLike,
    likeResult,
    likeError,
    likeLoading,
    getClickCounts,
    clickCountsResult,
}: IRecipeCardPanelProps) => {
    if (
        recipeResult?.recipes == undefined ||
        recipeResult?.recipes == null ||
        recipeResult?.recipes.length == 0 ||
        recipeResult?.recipes[0].uuid == null
    ) {
        return (
            <div>
                <ErrorTextContainer></ErrorTextContainer>
            </div>
        )
    }
    const dispatch = useAppDispatch()
    const [benchmarkData, setBenchmarkData] = useState<any>({})
    const benchmark = useAppSelector<any>(
        (state) => state.getBenchmarksResults.data
    )

    useEffect(() => {
        if (benchmark && benchmark.benchmarks.length > 0) {
            setBenchmarkData(
                Object.assign({}, benchmarkData, {
                    [benchmark.benchmarks[0].optimizationId]:
                        benchmark.benchmarks,
                })
            )
        }
    }, [benchmark])

    const recipesList = recipeResult?.recipes
        ? [...recipeResult.recipes]
        : undefined

    const recipeCards = recipesList?.map((data) => {
        return (
            <RecipeCard
                key={data.uuid}
                recipeData={data}
                downloadCatalog={downloadCatalog}
                downloadCatalogResult={downloadCatalogResult}
                downloadCatalogLoading={downloadCatalogLoading}
                downloadCatalogError={downloadCatalogError}
                submitFeedback={submitFeedback}
                feedbackResult={feedbackResult}
                feedbackError={feedbackError}
                feedbackLoading={feedbackLoading}
                saveFavorite={saveFavorite}
                favoriteResult={favoriteResult}
                favoriteError={favoriteError}
                favoriteLoading={favoriteLoading}
                saveStarRating={saveStarRating}
                saveRatingResult={saveRatingResult}
                saveRatingError={saveRatingError}
                saveVoting={saveVoting}
                saveVotingResult={saveVotingResult}
                saveVotingError={saveVotingError}
                getRecipeById={getRecipeById}
                saveLinkClick={saveLinkClick}
                updateCartItems={updateCartItems}
                updateCartResult={updateCartResult}
                getCartItems={getCartItems}
                getCartItemsResult={getCartItemsResult}
                getCartItemsResultLoading={getCartItemsResultLoading}
                getCartItemsResultError={getCartItemsResultError}
                getBenchmarks={getBenchmarks}
                benchmarkData={benchmarkData}
                resultLayoutView={resultLayoutView}
                isIntelEmployee={isIntelEmployee}
                isPublicSearch={isPublicSearch}
                saveLike={saveLike}
                likeResult={likeResult}
                likeError={likeError}
                likeLoading={likeLoading}
                getClickCounts={getClickCounts}
                clickCounts={clickCountsResult}
            ></RecipeCard>
        )
    })
    const recipeCards_dev = recipesList?.map((data) => {
        return (
            <RecipeCard_dev
                key={data.uuid}
                recipeData={data}
                downloadCatalog={downloadCatalog}
                downloadCatalogResult={downloadCatalogResult}
                downloadCatalogLoading={downloadCatalogLoading}
                downloadCatalogError={downloadCatalogError}
                submitFeedback={submitFeedback}
                feedbackResult={feedbackResult}
                feedbackError={feedbackError}
                feedbackLoading={feedbackLoading}
                saveFavorite={saveFavorite}
                favoriteResult={favoriteResult}
                favoriteError={favoriteError}
                favoriteLoading={favoriteLoading}
                saveStarRating={saveStarRating}
                saveRatingResult={saveRatingResult}
                saveRatingError={saveRatingError}
                saveVoting={saveVoting}
                saveVotingResult={saveVotingResult}
                saveVotingError={saveVotingError}
                getRecipeById={getRecipeById}
                saveLinkClick={saveLinkClick}
                updateCartItems={updateCartItems}
                updateCartResult={updateCartResult}
                getCartItems={getCartItems}
                getCartItemsResult={getCartItemsResult}
                getCartItemsResultLoading={getCartItemsResultLoading}
                getCartItemsResultError={getCartItemsResultError}
                getBenchmarks={getBenchmarks}
                benchmarkData={benchmarkData}
                resultLayoutView={resultLayoutView}
                isIntelEmployee={isIntelEmployee}
                isPublicSearch={isPublicSearch}
                saveLike={saveLike}
                likeResult={likeResult}
                likeError={likeError}
                likeLoading={likeLoading}
                getClickCounts={getClickCounts}
                clickCounts={clickCountsResult}
            ></RecipeCard_dev>
        )
    })

    return (
        <div>
            {!isFavoritesLoading && !isFlameScannerLoading ? (
                <div>
                    {resultLayoutView === LayoutViewPreference.DemoView ? (
                        <RecipePanelContainer>
                            {recipeCards_dev}
                        </RecipePanelContainer>
                    ) : (
                        <RecipePanelContainer>
                            {recipeCards}
                        </RecipePanelContainer>
                    )}
                </div>
            ) : (
                <FavoriteRecipePanelContainer>
                    {recipeCards}
                    {isFlameScannerLoading ? (
                        <GranulateCard></GranulateCard>
                    ) : (
                        ''
                    )}
                </FavoriteRecipePanelContainer>
            )}
        </div>
    )
}

export default RecipeCardPanel
