import { FunctionComponent } from 'react'
import styled from 'styled-components'

const FooterContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.theme.color.background.defaultGrey};
    margin: 1rem 1.5rem 0 1.5rem;
    border-radius: 0;
    @media screen and (max-width: 700px) {
        flex-direction: column;
        width: 100%;
    }
`
const FooterLink = styled.a`
    color: #000;
    text-decoration: none;
    font-family: 'IntelOne Display';
    &:hover {
        text-decoration: underline;
        text-decoration-color: #000;
    }
`
const FooterCopyright = styled.span`
    color: #000;
    text-decoration: none;
    font-family: 'IntelOne Display';
`
const SepratorSpan = styled.span`
    padding-left: 1rem;
    padding-right: 1rem;
`
const Footer = styled.footer`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 32px;
    color: #fff;
    padding: 22px 0;
`

const AiModelFooterPanel: FunctionComponent = ({}) => {
    return (
        <FooterContainer>
            <Footer>
                <FooterCopyright>© Intel Corporation</FooterCopyright>
                <SepratorSpan></SepratorSpan>{' '}
                <FooterLink href="https://www.intel.com/content/www/us/en/legal/terms-of-use.html">
                    Terms of Use
                </FooterLink>
                <SepratorSpan></SepratorSpan>{' '}
                <FooterLink href="https://www.intel.com/content/www/us/en/privacy/intel-privacy-notice.html">
                    Privacy Policy
                </FooterLink>
                <SepratorSpan></SepratorSpan>{' '}
                <FooterLink href="https://www.intel.com/content/www/us/en/privacy/intel-cookie-notice.html">
                    Cookies
                </FooterLink>
                <SepratorSpan></SepratorSpan>{' '}
                <FooterLink href="https://www.intel.com/content/www/us/en/support/contact-intel.html">
                    Contact Intel
                </FooterLink>
            </Footer>
        </FooterContainer>
    )
}
export default AiModelFooterPanel
