import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LoadingState } from '../models/loadingState'
import PaymentProfileService from '../services/paymentProfileService'
import { PaymentProfileCreateFinalizeRequest } from '../models/paymentProfileCreateFinalizeRequest'
import { PaymentProfileCreateInitRequest } from '../models/paymentProfileCreateInitRequest'

const initialState = {
    data: null,
    loading: LoadingState.Idle,
    error: null,
}

const initialHPPState = {
    data: null,
    cancel: false,
    error: null,
}

export const getPaymentProfilesThunk = createAsyncThunk(
    'getPaymentProfiles',
    async () => {
        return await PaymentProfileService.getPaymentProfiles()
    }
)

const getPaymentProfilesSlice = createSlice({
    name: 'getPaymentProfiles',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(getPaymentProfilesThunk.pending, (state, _) => {
            if (state.loading === LoadingState.Idle) {
                state.loading = LoadingState.Pending
            }
        })
        .addCase(getPaymentProfilesThunk.fulfilled, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.data = action.payload.data
                state.error = null
            }
        })
        .addCase(getPaymentProfilesThunk.rejected, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.error = action.error
            }
        })
    },
})

export const paymentProfileCreateInitThunk = createAsyncThunk(
    'paymentProfileCreateInit',
    async (
        paymentProfileCreateInitRequest: PaymentProfileCreateInitRequest
    ) => {
        return await PaymentProfileService.paymentProfileCreateInit(
            paymentProfileCreateInitRequest
        )
    }
)

const paymentProfileCreateInitSlice = createSlice({
    name: 'paymentProfileCreateInit',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(paymentProfileCreateInitThunk.pending, (state, _) => {
            if (state.loading === LoadingState.Idle) {
                state.loading = LoadingState.Pending
            }
        })
        .addCase(paymentProfileCreateInitThunk.fulfilled, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.data = action.payload.data
                state.error = null
            }
        })
        .addCase(paymentProfileCreateInitThunk.rejected, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.error = action.error
            }
        })
    },
})

const paymentProfileHPPSlice = createSlice({
    name: 'paymentProfileHPP',
    initialState: initialHPPState,
    reducers: {
        completeProfile(state, action: PayloadAction<any>) {
            state.data = action.payload
            state.error = null
            state.cancel = false
        },
        cancelProfile(state, action: PayloadAction<any>) {
            state.data = null
            state.error = null
            state.cancel = true
        },
        errorProfile(state, action: PayloadAction<any>) {
            state.data = null
            state.error = action.payload
            state.cancel = false
        },
        emptyProfile(state, action: PayloadAction<any>) {
            state.data = null
            state.error = null
            state.cancel = false
        },
    },
})

export const paymentProfileCreateFinalizeThunk = createAsyncThunk(
    'paymentProfileCreateFinalize',
    async (
        paymentProfileCreateFinalizeRequest: PaymentProfileCreateFinalizeRequest
    ) => {
        return await PaymentProfileService.paymentProfileCreateFinalize(
            paymentProfileCreateFinalizeRequest
        )
    }
)

const paymentProfileCreateFinalizeSlice = createSlice({
    name: 'paymentProfileCreateFinalize',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(paymentProfileCreateFinalizeThunk.pending, (state, _) => {
            if (state.loading === LoadingState.Idle) {
                state.loading = LoadingState.Pending
            }
        })
        .addCase(paymentProfileCreateFinalizeThunk.fulfilled, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.data = action.payload.data
                state.error = null
            }
        })
        .addCase(paymentProfileCreateFinalizeThunk.rejected, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.error = action.error
            }
        })
    },
})

export const paymentProfiles = getPaymentProfilesSlice.reducer
export const paymentProfileCreateInit = paymentProfileCreateInitSlice.reducer
export const paymentProfileCreateFinalize =
    paymentProfileCreateFinalizeSlice.reducer
export const paymentProfileHPP = paymentProfileHPPSlice.reducer
export const { emptyProfile } = paymentProfileHPPSlice.actions