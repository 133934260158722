import { useEffect } from 'react'
import { MyRegistryRequest } from '../../models/myRegistryRequest'
import { IMyRequestContainerProps } from './myRequestContainer'
import { useAppDispatch } from '../../config/hooks'

export function IMyRequestContainerLogic({
    getOptimizationUploadRequest,
    getOptimizationNewOptimizationRequest,
    getBenchmarkRequest,
    getFeedback,
    getOptimizationOwnerFeedback,
    appName,
    userId,
    deleteOptimizationRequestResult,
    deleteBenchmarkRequestResult,
    deleteFeedbackResult,
    isIntelEmployee,
}: IMyRequestContainerProps) {
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (isIntelEmployee) {
            dispatch(getOptimizationUploadRequest({ offset: 0, count: 10 }))
            getOptimizationOwnerFeedback(userId)
        }
        getFeedback(userId)
        //disabled new optimization and benchmark
        //dispatch(getOptimizationNewOptimizationRequest())
        //getBenchmarkRequest(userId)
    }, [
        deleteOptimizationRequestResult,
        deleteBenchmarkRequestResult,
        deleteFeedbackResult,
    ])

    return {
        appName,
    }
}
