import {
    FormEvent,
    FunctionComponent,
    useCallback,
    useEffect,
    useState,
} from 'react'
import Modal from 'react-bootstrap/Modal'
import { Member } from '../../models/getMembershipResponse'
import { Button, Form } from 'react-bootstrap'
import styled from 'styled-components'

const RoleRow = styled.div`
    display: flex;
    flex-direction: row;
    margin: 1rem;
`

export enum MemberType {
    Member = 'member',
    Invitee = 'invitee',
}

export interface IChangeRolesPopupPanelProps {
    show: boolean
    roles: string[]
    memberType: MemberType
    member?: Member
    inviteEmail?: string
    onCancelCallBack: (e: any) => void
    onRunCallBack: (e: any, selection: string[]) => void
}

export const ChangeRolesPopupPanel: FunctionComponent<
    IChangeRolesPopupPanelProps
> = ({
    show,
    roles,
    memberType,
    member,
    inviteEmail,
    onCancelCallBack,
    onRunCallBack,
}: IChangeRolesPopupPanelProps) => {
    const [selectedRoles, setSelectedRoles] = useState<string[]>([])
    const [selection, setSelection] = useState<{
        role: string
        value: boolean
    }>({ role: '', value: false })

    useEffect(() => {
        if (member?.roles) {
            setSelectedRoles([...member.roles])
        }
    }, [member])

    useEffect(() => {
        if (selection.value) {
            if (!selectedRoles.includes(selection.role)) {
                setSelectedRoles([...selectedRoles, selection.role])
            }
        } else {
            if (selectedRoles.includes(selection.role)) {
                setSelectedRoles(
                    selectedRoles.filter(
                        (testRole) => testRole != selection.role
                    )
                )
            }
        }
    }, [selection, selectedRoles])

    const handleCancel = (event: any) => {
        const target = event as any
        onCancelCallBack(target)
    }

    const handleSave = (event: any) => {
        const target = event as any

        if (memberType == MemberType.Member) {
            onRunCallBack(member, selectedRoles)
        } else {
            onRunCallBack(inviteEmail, selectedRoles)
        }

        onCancelCallBack(target)
    }

    const roleRow = roles.map((role) => (
        <RoleRow>
            <Form.Check
                name="roleSelection"
                label={role}
                defaultChecked={member?.roles?.includes(role)}
                type="switch"
                id={`switch-` + role}
                onChange={(e: any) => {
                    setSelection({ role: role, value: e.target.checked })
                }}
            />
        </RoleRow>
    ))

    return (
        <Modal
            show={show}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable={true}
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    {memberType === MemberType.Invitee
                        ? 'Set Roles for Invitee'
                        : 'Change Roles'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>{roleRow}</Modal.Body>
            <Modal.Footer>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button onClick={handleSave}>Save</Button>
            </Modal.Footer>
        </Modal>
    )
}
