import { FunctionComponent } from 'react'
import { Button, OverlayTrigger, Popover } from 'react-bootstrap'
import styled from 'styled-components'
import StringUtils from '../../lib/stringUtils'

const WorkloadBubbleComponent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 1.5rem;
    margin-left: 1.5rem;
    text-align: center;
    cursor: pointer;
`
const WorkloadBubbleContainer = styled(Button)`
    display: flex;
    width: 7.5rem;
    height: 7.5055rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-shrink: 0;
    border-radius: 60px;
    background: var(--theme-light-gray-50, #fff);
    box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.2);
    border: 2px solid #e2e2e4;
    &:hover {
        background: var(--theme-light-gray-50, #fff);
        box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.2);
        transform: scale(1.03);
        transition: 0.5s;
        border: 1px solid rgb(0, 84, 174);
    }
    &:focus {
        background: var(--theme-light-gray-50, #fff);
        box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.2);
        transform: scale(1.03);
        transition: 0.5s;
        border: 1px solid rgb(0, 84, 174);
    }
`
const WinningWorkloadBubbleContainer = styled(Button)`
    display: flex;
    width: 7.5rem;
    height: 7.5055rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-shrink: 0;

    border-radius: 60px;
    /* A transparent border, so the very edge of the button shows through */
    border: 4px solid transparent;
    background-image: linear-gradient(
        224.44deg,
        rgba(255, 255, 255, 0.15) -7.45%,
        #0099ec 35.22%,
        rgba(0, 22, 141, 0.7) 97.96%
    );
    /* Fill the inside with white */
    background-origin: border-box;
    box-shadow: inset 0 100vw white;

    &:hover {
        background-image: linear-gradient(
            224.44deg,
            rgba(255, 255, 255, 0.15) -7.45%,
            #0099ec 35.22%,
            rgba(0, 22, 141, 0.7) 97.96%
        );
        /* Fill the inside with white */
        background-origin: border-box;
        box-shadow: inset 0 100vw white;
        /* A transparent border, so the very edge of the button shows through */
        border: 4px solid transparent;

        transform: scale(1.03);
        transition: 0.5s;
    }
    &:focus {
        background-image: linear-gradient(
            224.44deg,
            rgba(255, 255, 255, 0.15) -7.45%,
            #0099ec 35.22%,
            rgba(0, 22, 141, 0.7) 97.96%
        );
        /* Fill the inside with white */
        background-origin: border-box;
        box-shadow: inset 0 100vw white;
        /* A transparent border, so the very edge of the button shows through */
        border: 4px solid transparent;

        transform: scale(1.03);
        transition: 0.5s;
    }
`
const WorkloadName = styled.p`
    display: flex;
    color: #0054ae;
    text-align: center;
    justify-content: center;
    font-size: 1rem;
    font-family: IntelOne Display;
    font-weight: 400;
    max-width: 7rem;
    margin-top: 1rem;
`
const WorkloadImageContainer = styled.div`
    display: flex;
    height: 80px;
    align-items: center;
`
const PopoverKeyDiv = styled.div`
    background-color: #cff4fc;
    font-size: 1rem;
    padding: 0.5rem;
`

export interface IWorkloadBubbleProps {
    uuid: string
    name: string
    iconURL: string
    isWinningWorkload: boolean
    hrefValue?: string
}

const WorkloadBubble: FunctionComponent<IWorkloadBubbleProps> = ({
    uuid,
    name,
    iconURL,
    isWinningWorkload,
    hrefValue,
}: IWorkloadBubbleProps) => {
    isWinningWorkload = false //disable badge and highlighting
    const bageImage = (
        <img
            src={StringUtils.resolve('/sales_img/badge.png')}
            alt="Priority badge logo"
            style={{
                width: '32px',
                height: '32px',
                marginTop: '-4.5rem',
                marginLeft: '-0.5rem',
                position: 'relative',
            }}
        />
    )

    const workloadImage = (
        <img
            src={`${iconURL}`}
            style={
                isWinningWorkload
                    ? {
                          maxHeight: '81px',
                          maxWidth: '80px',
                          marginLeft: '-1.5rem',
                      }
                    : {
                          maxHeight: '81px',
                          maxWidth: '80px',
                          marginLeft: '0',
                      }
            }
        />
    )

    const bubbleContent = (
        <div style={{ display: 'inline-flex' }}>
            <div style={{ marginTop: '1rem' }}>
                {isWinningWorkload ? bageImage : ''}
            </div>
            <WorkloadImageContainer>{workloadImage}</WorkloadImageContainer>
        </div>
    )

    return (
        <WorkloadBubbleComponent>
            {isWinningWorkload ? (
                <WinningWorkloadBubbleContainer
                    href={hrefValue ? hrefValue : `/allWorkloads/${uuid}`}
                    tabIndex={0}
                >
                    {bubbleContent}
                </WinningWorkloadBubbleContainer>
            ) : (
                <WorkloadBubbleContainer
                    href={hrefValue ? hrefValue : `/allWorkloads/${uuid}`}
                    tabIndex={0}
                >
                    {bubbleContent}
                </WorkloadBubbleContainer>
            )}

            {name && name.length > 15 ? (
                <OverlayTrigger
                    placement={'top'}
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                        <Popover
                            id="recipe_details"
                            style={{ maxWidth: '20%' }}
                        >
                            <Popover.Body>
                                <PopoverKeyDiv>{name}</PopoverKeyDiv>
                            </Popover.Body>
                        </Popover>
                    }
                >
                    <WorkloadName>{name.slice(0, 15) + '...'}</WorkloadName>
                </OverlayTrigger>
            ) : name ? (
                <WorkloadName>{name}</WorkloadName>
            ) : (
                ''
            )}
        </WorkloadBubbleComponent>
    )
}

export default WorkloadBubble
