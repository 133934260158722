import {
    FormEvent,
    FunctionComponent,
    useEffect,
    useRef,
    useState,
} from 'react'
import {
    Breadcrumb,
    Button,
    FormLabel,
    Table,
    FormControl,
    Dropdown,
    Carousel,
    Spinner,
    Modal,
} from 'react-bootstrap'
import styled from 'styled-components'
import { _getDecryptedValueFromStorage } from '../../lib/encryptStorage'
import {
    BenchmarkSearchResponse,
    benchmarksData,
} from '../../models/benchmarkSearchResponse'
import { LoadingState } from '../../models/loadingState'
import { BenchmarkDetailsLogic } from './benchmarkDetails.hook'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { useLocation } from 'react-router-dom'
import SalesHeaderPanel from './salesHeader'
import NoResultFoundPanel from './noResultFound'
import StringUtils from '../../lib/stringUtils'
import { AuthPermissions } from '../../models/authPermissions'
import WorkloadBubble from './workloadBubble'

const BenchmarkDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
`
const BenchmarkDetailsWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    flex-wrap: no-wrap;

    background: #ffffff;
`

const BreadcrumbItem = styled(Breadcrumb.Item)`
    display: flex;
    font-family: 'IntelOne Display';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    align-items: center;
    color: #0054ae;
`
const BenchmarkResultComponent = styled.div`
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    margin-top: -1rem;
    justify-content: center;
    /* White/WHITE */
    background: #ffffff;
    /* table outlines - light/table-outline-plain/full */
    box-shadow: inset 0px 1px 0px rgba(43, 44, 48, 0.12),
        inset -1px 0px 0px rgba(43, 44, 48, 0.12),
        inset 1px 0px 0px rgba(43, 44, 48, 0.12),
        inset 0px -1px 0px rgba(43, 44, 48, 0.12);
    width: 100%;
    height: 275px;
`

const WorkloadLogoComponent = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    margin-top: 2rem;
    gap: 20px;

    width: 200px;
    height: 200px;
    background: #ffffff;
    /* table outlines - light/table-outline-plain/full */

    box-shadow: inset 0px 1px 0px rgba(43, 44, 48, 0.12),
        inset -1px 0px 0px rgba(43, 44, 48, 0.12),
        inset 1px 0px 0px rgba(43, 44, 48, 0.12),
        inset 0px -1px 0px rgba(43, 44, 48, 0.12);
`
const BenchmarkCardColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 50%;
    padding-left: 2rem;
    max-width: 70%;
`
const BenchmarkShareColumn = styled.div`
    display: flex;
    flex-direction: column;
`
const BenchmarkShareRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex end;
    padding-left: 2rem;
    margin-top: 2rem;
`
const PriorityComponent = styled.div`
    display: flex;
    background: rgba(0, 84, 174, 0.15);
    border-radius: 5px;
    width: 60px;
    height: 24px;
    margin-left: 4rem;
    margin-top: 3rem;
`
const PriorityLabel = styled(FormLabel)`
    display: flex;
    padding: 0.6rem 0.5rem;
    align-items: center;
    font-family: 'IntelOne Display';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;

    /* Badge/Fill/Light/Primary */
    color: #0054ae;
    margin-top: 0.15rem;
`
const BenchmarkTagComponent = styled.div`
    display: flex;
`
const CardNameComponent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 0.5rem;
    padding: 0.5rem 0rem;
    font-family: 'IntelOne Display';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 40px;
    color: #0054ae;
`
const CardDescriptionComponent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 0.5rem;
    padding: 0.5rem 0rem;
    font-family: 'IntelOne Display';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #6b6e76;
`
const TabsContainer = styled.div`
    flex-direction: center;
    width: 100%;
`
const BenchmarkTabComponent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: #fff;
    width: 100%;
`
const TabsComponent = styled(Tabs)`
    .nav-link.active {
        //border-top: 4px solid #00c7fd !important;
        color: var(--text-default, #2b2c30) !important;
        background: var(--theme-light-gray-300, #e2e2e4) !important;
    }
`
const StyledTab = styled(Tab)`
    display: table-cell;
    color: var(--text-placeholder, #6a6d75);
`
const StyledTabLabel = styled.div`
    width: 120px;
    height: 50px;
    padding: 0.5rem;
    margin: 0 0.1rem;
    text-align: center;
    font-family: Intel Clear;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 112.5% */
    border-radius: 6px 6px 0px 0px;
    box-shadow: 1px 0px 0px 0px rgba(43, 44, 48, 0.3) inset,
        -1px 0px 0px 0px rgba(43, 44, 48, 0.3) inset,
        0px 1px 0px 0px rgba(43, 44, 48, 0.3) inset;
`
const BenchmarkListingTabContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 0 3rem;
    margin-top: 4rem;
    margin-bottom: 5rem;
    width: 100%;
`
const ResourcesTabContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: #f4f5f5;
    width: 1200px;
    padding: 0.5rem;
`
const ResourcesInnerDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 8%;
    padding: 2rem 0;
`
const EcosystemTabContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #f4f5f5;
    padding: 3rem;
    margin-bottom: 2rem;
    width: 1200px;
`
const ResourcesTextLabel = styled(FormLabel)`
    font-family: 'IntelOne Display';
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 45px;
    display: flex;
    align-items: center;
    padding-bottom: 1rem;
    padding-left: 2.5rem;
    color: #0054ae;
`
const ListingTabHeaderDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: left;
    padding: 3rem 0 1.5rem 0;
`
const ListingTabHeader = styled(FormLabel)`
    font-family: 'IntelOne Display';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    /* identical to box height, or 125% */

    display: flex;
    align-items: left;

    /* Global Header/Classic Blue/Light/Fill */

    color: #0054ae;
`
const CollateralTextLabel = styled(FormLabel)`
    font-weight: 700;
    font-size: 16px;
    color: #2b2c30;
    padding: 1rem;
    padding-left: 2.5rem;
`
const ResourcesLinksContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 2rem;
`
const ResourcesLinksText = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-family: 'IntelOne Display';
    font-weight: 400;
    font-size: 0.8rem;
    padding-left: 0.9rem;
    ${(props) => props.theme.typography.medium}
    justify-content: left;
    margin-bottom: 1rem;
    padding-left: 1rem;
`
const ResourcesLinksTextValue = styled.span`
    padding-right: 0.5rem;
    overflow-wrap: break-word;
    word-break: break-word;
    max-width: 30rem;
    padding-left: 0.5rem;
`
const ShareButton = styled(Button)`
    background-color: transparent;
    cursor: pointer;
    color: black;
    border-radius: 0;
    border: none;
    content: unset !important;
    &:hover {
        background-color: transparent;
        border: 0px none transparent;
    }
    &:focus {
        outline: none;
        /*box-shadow: none;*/
        background-color: transparent;
        border: 0px none transparent;
    }
`
const BookmarkButton = styled(ShareButton)`
    cursor: pointer;
    padding-left: 1.5rem;
    &:hover {
        background-color: transparent;
        border: 0px none transparent;
    }
    &:focus {
        outline: none;
        /*box-shadow: none;*/
        background-color: transparent;
        border: 0px none transparent;
    }
`
const MoreInfoButton = styled(Button)`
    display: flex;
    align-items: center;
    background-color: transparent;
    font-family: 'IntelOne Display';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #2b2c30;
    border: none;
    margin-left: 1rem;
    &:hover {
        background-color: transparent;
        border: 0px none transparent;
        color: #2b2c30;
    }
    &:focus {
        outline: none;
        /*box-shadow: none;*/
        background-color: transparent;
        border: 0px none transparent;
        color: #2b2c30;
    }
    &:disabled {
        background-color: unset;
    }
`
const PerformanceLabel = styled(FormLabel)`
    display: flex;
    align-items: center;
    text-align: center;
    font-family: 'IntelOne Display';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    color: #0054ae;
`
const ParameterLabel = styled.div`
    display: flex;
    font-family: 'IntelOne Display';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #0054ae;
`

const VSLabel = styled.div`
    display: flex;
    font-family: 'IntelOne Display';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    color: #0054ae;
    align-items: center;
    text-align: center;
`
const BenchmarkMetaDataContainer = styled.div`
    display: flex;
    justify-content: center;
    background: #fff;
`
const UseCaseDetailsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    background: #fff;
    box-shadow: 0px -1px 0px 0px rgba(43, 44, 48, 0.2) inset,
        0px 4px 0px 0px #00b1fd inset;
    padding: 0.6rem 1rem;
    color: var(--gray-800, #494b51);
    font-family: 'IntelOne Display';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
`

const BenchmarkTableHeader = styled.th.attrs({
    colSpan: 7,
})`
    padding: 0.5rem;
    width: 10rem;
    font-family: 'IntelOne Display';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #fff;
    justify-content: center;
    text-align: center;
`
const BenchmarkTableRow = styled.tr`
    //border-style: none;
    //background: #fff;
    //width: 50rem;
`
const BenchmarkTableCol2 = styled.td.attrs({
    colSpan: 2,
    verticalAlign: 'middle',
})`
    //border-style: none;
    //background: #fff;
`

const BenchmarkTableCol = styled.td`
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    text-align: center;
`

const MoreInfoDataCol = styled.td`
    justify-content: center;
    align-items: center;
    text-align: center;
`
const PPTDataContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 1rem;
    padding: 1rem 1.25rem;
    width: 30rem;
    //border: 1.5px solid var(--theme-light-gray-300, #e2e2e4);
    background: #fff;
`
const MoreInfoDataContainer = styled.div`
    display: flex;
    flex-direction: column;
    //justify-content: center;
    align-items: flex-start;
    background-color: #fff;
    margin: 1rem;
    padding: 1.875rem 0.5rem;
`

const MoreInfoSCContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: #fff;
    margin: 1rem;
    padding: 1.875rem 0;
    border-radius: 2px;
    border: 0.5px solid var(--theme-light-gray-300, #e2e2e4);
    background: var(--white-white, #fff);
`

const MoreInfoDatalabel = styled(FormLabel)`
    justify-content: left;
    font-family: 'IntelOne Display';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height, or 150% */

    display: flex;
    align-items: center;

    /* Text/Light/Default */

    color: #2b2c30;
`
const ConfigTableHeader = styled.div`
    width: 40rem;
    background: var(--theme-light-gray-200, #e9eaeb);
    box-shadow: 0px -1px 0px 0px rgba(43, 44, 48, 0.12) inset;
`
const SoftwareCofigDataCell = styled.td`
    justify-content: left;
    font-family: 'IntelOne Display';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    align-items: center;
    width: 15rem;
    margin: 0.01rem;
    //background-color: #e9eaeb;
    padding: 0.2rem 1rem;
    border-right: 1px solid #fff;
    background: var(--black-and-white-white, #fff);
    /* table outlines - light/table-outline-plain/bottom */
    box-shadow: 0px -1px 0px 0px rgba(43, 44, 48, 0.12) inset;
`

const MoreInfoDatalabelValue = styled(MoreInfoDatalabel)`
    justify-content: left;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    padding-bottom: 1rem;
`

const MoreInfoSClabelValue = styled(MoreInfoDatalabel)`
    justify-content: left;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    padding-bottom: 1rem;
    align-items: unset;
`
const SKUTableCell = styled.td`
    border: 1px solid #b9b9b9;
    font-family: 'IntelOne Display';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    align-items: center;
    color: #fff;
`
const EcoSystemHeaderDiv = styled.div`
    font-family: 'IntelOne Display';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    /* identical to box height, or 125% */

    display: flex;
    align-items: center;

    /* Global Header/Classic Blue/Light/Fill */

    color: #0054ae;
    padding: 1rem 1rem 0 1rem;
}
`
const EcoSystemSubHeader = styled(EcoSystemHeaderDiv)`
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    /* identical to box height */

    display: flex;
    align-items: center;

    /* Text/Light/Default */

    color: #2b2c30;
    padding: 1rem 1rem 2rem 1rem;
`
const StatusLabels = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`
const BenchmarkStatusComponent = styled.div`
    display: flex;
    justify-content: center;
    background: rgba(0, 84, 174, 0.15);
    border-radius: 5px;
    height: 24px;
    margin: 0.1rem 0.5rem;
`
const BenchmarkStatusLabel = styled(FormLabel)`
    display: flex;
    padding: 0.6rem 0.5rem;
    align-items: center;
    font-family: 'IntelOne Display';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0em;
    /* Badge/Fill/Light/Primary */
    color: #0054ae;
    margin-top: 0.2rem;
`

const ConfigHeader = styled(FormLabel)`
    margin: 0.3rem 0.5rem;
    //color: #0054ae;
    font-size: 1rem;
    font-family: 'IntelOne Display';
    font-weight: 500;
`
const Header = styled.p`
    color: #0054ae;
    font-size: 1.25rem;
    font-family: IntelOne Display;
    line-height: 1.3125rem;
`
const SubHeader = styled.p`
    color: #2b2c30;
    font-size: 1rem;
    font-family: IntelOne Display;
    font-weight: 500;
    line-height: 1.3125rem;
`
const CSPBrandComponent = styled.div`
    display: flex;
    vertical-align: middle;
    margin-top: -0.3rem;
    padding-left: 1rem;
`
const PerfResultsDiv = styled.div`
    background: #005374;
    width: 40rem;
    height: 8rem;
    margin: 1rem;
    padding: 1rem;
`
const CPUInstanceHeader = styled.th`
    background-color: #183544;
    font-family: Intel Clear;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #fff;
    height: 1.68rem;
    vertical-align: middle;
    padding-left: 0.2rem;
`
const CPUInstanceCell = styled.td`
    font-family: 'IntelOne Display';
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    border-right: 1px solid #183544;
    padding-left: 0.2rem;
`
const RecipeDataLink = styled.a`
    font-size: 14px;
    font-family: IntelOne Display;
    line-height: 20px;
    font-weight: 400;
    padding-bottom: 1rem;
`
const ResourcesLink = styled.a`
    font-size: 14px;
    font-family: 'IntelOne Display';
    font-weight: 400;
    line-height: 20px;
`
const SearchTextBoxComponent = styled(FormControl)`
    display: flex;
    background: #ffffff;
    border: 1px solid #8b8e97;
    font-size: 1.1rem !important;
    font-weight: 300;
    font-family: 'IntelOne Display';
    font-style: italic;
    color: #00377c;
    height: 30px;
    width: 300px;
    border-radius: 0px;
    margin-top: 0.3rem;
`
const TechnicalCollateralsDiv = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 400px;
    min-width: 300px;
    max-width: 590px;
    padding: 30px 20px;
    align-items: flex-start;
    gap: 30px;
    background: var(--black-and-white-white, #fff);
    margin: 1rem;
    color: var(--carbon-carbon-shade-2, #262626);
    font-family: IntelOne Display;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
`
const SpinnerDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5rem;
    width: 100%;
`
const FooterCloseButtonDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: right;
`

export interface IBenchmarkDetailsProps {
    getWorkloadBenchmarks: (workloadId: string) => void
    getBenchmarksResult: BenchmarkSearchResponse
    getbenchmarksLoading: LoadingState
    getBenchmarksError: any
    workloadId: string
    benchmarkGroupName?: string
    SearchComponentRef?: any
    downloadLinkRef?: any
    authPermissionsResult: AuthPermissions
}

const BenchmarkDetails: FunctionComponent<IBenchmarkDetailsProps> = ({
    getWorkloadBenchmarks,
    getBenchmarksResult,
    getbenchmarksLoading,
    getBenchmarksError,
    workloadId,
    authPermissionsResult,
}: IBenchmarkDetailsProps) => {
    const SearchComponentRef = useRef()
    const downloadLinkRef = useRef(null)
    const [activeKey, setActiveKey] = useState('Performance Benefit')
    const location = useLocation()

    const queryParameters = new URLSearchParams(location.search)
    const benchmarkGroupName = queryParameters.get('benchmarkGroup')
    const benchmarkGroupTypeName = queryParameters.get('benchmarkGroupType')
    const workloadName = queryParameters.get('workload')
    const backNavigation = queryParameters.get('nav')
    const isDevView = queryParameters.get('isDevView')
    const benchmarkGroup = benchmarkGroupName
        ? benchmarkGroupName?.replace('Benchmarks', '')
        : 'GCP'

    const {
        onShareLinkClick,
        showBenchmarkMetaData,
        onShowBenchmarkMetaDataClick,
        rowId,
        attachmentFilenames,
        fileDownloadUrlList,
        attachedFileName,
        downloadCompleted,
        downloadPPTPerfSlide,
        onAccessApplyChange,
        onHeaderSearchKeyChange,
        onHeaderSearchKeydown,
        onHeaderFilterClick,
        onHeaderFilterClearAllClick,
        showThumbnailPopup,
        ShowPPTThumbnailPopup,
        ClosePPTThumbnailPopup,
        attachedFileURL,
        handleSalesLinkClick,
    } = BenchmarkDetailsLogic({
        SearchComponentRef,
        downloadLinkRef,
        workloadId,
        benchmarkGroupName,
    } as IBenchmarkDetailsProps)

    const shareLinkURL = `${window.location.href}`
    const benchmarksList = getBenchmarksResult?.benchmarks
        ? [...getBenchmarksResult?.benchmarks]
        : undefined

    const ecoData = getBenchmarksResult?.ecosystemData
        ? getBenchmarksResult?.ecosystemData
        : {}

    const workloadTags = getBenchmarksResult?.workloadTags
        ? getBenchmarksResult?.workloadTags
        : undefined

    const workloadResources = getBenchmarksResult?.workloadResources
        ? getBenchmarksResult?.workloadResources
        : undefined

    const iconURL = getBenchmarksResult?.iconURL
        ? getBenchmarksResult?.iconURL
        : ''

    let isBenchmark = false
    let isCloudBenchmark = false
    let isSWOpt = false
    let isPartnerSol = false
    let isCustomerStudy = false
    let isDataValidator = false
    if (
        authPermissionsResult &&
        authPermissionsResult?.featureFlags &&
        authPermissionsResult?.featureFlags?.includes('isDataValidator')
    ) {
        isDataValidator = true
    }

    let isIntelEmployee = false
    if (authPermissionsResult && authPermissionsResult?.isIntelEmployee) {
        isIntelEmployee = authPermissionsResult?.isIntelEmployee
    }

    if (
        benchmarkGroupTypeName &&
        benchmarkGroupTypeName.toUpperCase().indexOf('ON-PREM') > -1
    )
        isBenchmark = true
    else if (
        benchmarkGroupTypeName &&
        benchmarkGroupTypeName.toUpperCase().indexOf('CLOUD') > -1
    ) {
        isCloudBenchmark = true
    } else if (
        benchmarkGroupTypeName &&
        benchmarkGroupTypeName.toUpperCase().indexOf('SW OPTIMIZATION') > -1
    )
        isSWOpt = true
    else if (
        benchmarkGroupTypeName &&
        benchmarkGroupTypeName.toUpperCase().indexOf('PARTNER SOLUTION') > -1
    )
        isPartnerSol = true
    else isCustomerStudy = true

    const cardLogo = (
        <WorkloadBubble
            hrefValue={
                getBenchmarksResult?.benchmarks &&
                getBenchmarksResult?.benchmarks.length > 0
                    ? `/allWorkloads/${getBenchmarksResult?.benchmarks[0]?.workloadId}?isDevView=${isDevView}`
                    : `/allWorkloads?isDevView=${isDevView}`
            }
            uuid={
                getBenchmarksResult?.benchmarks &&
                getBenchmarksResult?.benchmarks.length > 0
                    ? getBenchmarksResult?.benchmarks[0]?.workloadId
                    : ''
            }
            iconURL={iconURL}
            name={''}
            isWinningWorkload={
                workloadTags &&
                workloadTags['selling prioritization'] &&
                workloadTags['selling prioritization'].length > 0 &&
                (workloadTags['selling prioritization'][0] === 'Priority' ||
                    workloadTags['selling prioritization'][0] === 'Prioritized')
                    ? true
                    : false
            }
        />
    )
    const dataServiceImg = (
        <img
            src="/database_icon.png"
            alt="product category logo"
            style={{ paddingLeft: '0.3rem', paddingRight: '0.2rem' }}
        />
    )
    const LinkImageRender = (
        <span
            style={{
                paddingLeft: '0.2rem',
            }}
        >
            <img
                src="/links.png"
                height="15px"
                width="15px"
                alt="link logo"
                style={{
                    paddingBottom: '0.1rem',
                }}
            />
        </span>
    )

    const winningBadge = (
        <img
            src={StringUtils.resolve('/sales_img/badge.png')}
            alt="Priority badge logo"
            style={{
                width: '32px',
                height: '32px',
                marginTop: '-1.5rem',
            }}
        />
    )

    const onPremTag = (
        <img src="/sales_img/tag-onprem.png" alt="product tag logo" />
    )
    const cloudTag = (
        <img src="/sales_img/tag-cloud.png" alt="product tag logo" />
    )
    const swOptimizationTag = (
        <img src="/sales_img/tag-sw-optimizations.png" alt="product tag logo" />
    )
    const partnerSolutionTag = (
        <img
            src="/sales_img/tag-partner-solutions.png"
            alt="product tag logo"
        />
    )
    const custSuccessTag = (
        <img
            src="/sales_img/tag-customer-success-stories.png"
            alt="product tag logo"
        />
    )

    const amdProcessor = (
        <img
            src="/sales_img/EPYC-processor-white-1260x709 1.png"
            alt="product tag logo"
            style={{ paddingLeft: '0.5rem' }}
        />
    )

    const Intel4thGenXeonProcessor = (
        <img
            src="/sales_img/xeon-processor-badge-rgb-3000 1.png"
            alt="product processor logo"
        />
    )
    const Intel3rdGenXeonProcessor = (
        <img
            src="/sales_img/xeon-processor-badge-rgb-3000 1.png"
            alt="product processor logo"
        />
    )
    const Intel2ndGenXeonProcessor = (
        <img
            src="/sales_img/xeon-processor-badge-rgb-3000 1.png"
            alt="product processor logo"
        />
    )
    const Intel6thGenXeonProcessor = (
        <img
            src="/sales_img/6thGenBrandLogo.png"
            alt="product processor logo"
            height="75px"
            width="75px"
        />
    )
    const gcpBrandImage = (
        <img
            src="/gcp.png"
            alt="product tag logo"
            style={{
                width: '45px',
                height: '45px',
            }}
        />
    )
    const azureBrandImage = (
        <img
            src="/azure.png"
            alt="product tag logo"
            style={{
                width: '45px',
                height: '45px',
            }}
        />
    )
    const awsBrandImage = (
        <img
            src="/aws.png"
            alt="product tag logo"
            style={{
                width: '45px',
                height: '45px',
            }}
        />
    )
    const alibabaImage = (
        <img
            src="/alibaba.png"
            alt="product tag logo"
            style={{
                width: '45px',
                height: '45px',
            }}
        />
    )
    const PPTDisclaimerRender = (benchmarkId?: string) => {
        const attachedfileURL = localStorage.getItem(attachedFileName)
        return (
            <a
                href="javascript:void(0)"
                id={'disclaimerLinkId-' + benchmarkId}
                onClick={() =>
                    ShowPPTThumbnailPopup(
                        attachedfileURL ? attachedfileURL : ''
                    )
                }
            >
                <img
                    src={
                        isIntelEmployee
                            ? StringUtils.resolve('/sales_img/disclaimer.png')
                            : StringUtils.resolve(
                                  '/sales_img/nonNDADisclaimer.png'
                              )
                    }
                    height="100%"
                    width="100%"
                    alt="PPT disclaimer Performance frame"
                />
            </a>
        )
    }
    const PPTFrameRender = (url: string, benchmarkId?: string) => (
        <a
            href="javascript:void(0)"
            id={'downloadLinkId-' + benchmarkId}
            onClick={() => ShowPPTThumbnailPopup(url)}
        >
            <img
                src={url}
                height="100%"
                width="100%"
                alt="PPT PNG Performance frame"
            />
        </a>
    )
    const PPTSlideRender = (fileName: string, benchmarkId?: string) => {
        const attachedfileURL = localStorage.getItem(fileName)
        return (
            <div>
                {attachedfileURL
                    ? PPTFrameRender(attachedfileURL, benchmarkId)
                    : ''}
            </div>
        )
    }
    const renderBenchmarkCSPImage = (cspName: string) => {
        return (
            <CSPBrandComponent
                style={
                    cspName.toUpperCase().indexOf('GCP') > -1
                        ? { marginTop: '-0.6rem' }
                        : { marginTop: '-0.3rem' }
                }
            >
                {cspName.toUpperCase().indexOf('GCP') > -1
                    ? gcpBrandImage
                    : cspName.toUpperCase().indexOf('AWS') > -1
                    ? awsBrandImage
                    : cspName.toUpperCase().indexOf('AZURE') > -1
                    ? azureBrandImage
                    : cspName.toUpperCase().indexOf('ALIBABA') > -1
                    ? alibabaImage
                    : ''}
            </CSPBrandComponent>
        )
    }

    const renderStatusLegandLabel = (
        label: string,
        FontColor: string,
        background: string
    ) => {
        return (
            <BenchmarkStatusComponent
                style={background ? { background: `${background}` } : {}}
            >
                <BenchmarkStatusLabel
                    style={FontColor ? { color: `${FontColor}` } : {}}
                >
                    {label}
                </BenchmarkStatusLabel>
            </BenchmarkStatusComponent>
        )
    }

    const renderBenchmarkCardTag = (tagName: string) => {
        return (
            <div>
                {tagName.toUpperCase().indexOf('ON-PREM') > -1
                    ? onPremTag
                    : tagName.toUpperCase().indexOf('CLOUD') > -1
                    ? cloudTag
                    : tagName.toUpperCase().indexOf('SW OPTIMIZATION') > -1
                    ? swOptimizationTag
                    : tagName.toUpperCase().indexOf('PARTNER SOLUTION') > -1
                    ? partnerSolutionTag
                    : custSuccessTag}
            </div>
        )
    }

    const renderComparisonPlatformRef = (benchmarkMetadata: any) => {
        let highLevelRef = benchmarkMetadata['high level reference platform']
            ? benchmarkMetadata['high level reference platform'][0]
            : ''
        let instanceRef =
            benchmarkMetadata['target platform'] &&
            benchmarkMetadata['target platform'][0]
                ? 'in ' + benchmarkMetadata['target platform'][0]
                : ''
        return (
            <div>
                {highLevelRef.toUpperCase().indexOf('AMD') > -1
                    ? ''
                    : highLevelRef.toLowerCase().indexOf('6th gen intel') >
                          -1 ||
                      highLevelRef.toLowerCase().indexOf('intel® xeon® 6') > -1
                    ? Intel6thGenXeonProcessor
                    : highLevelRef.toLowerCase().indexOf('4th gen intel') >
                          -1 ||
                      highLevelRef.toLowerCase().indexOf('5th gen intel') > -1
                    ? Intel4thGenXeonProcessor
                    : highLevelRef.toLowerCase().indexOf('3rd gen intel') > -1
                    ? Intel3rdGenXeonProcessor
                    : highLevelRef.toLowerCase().indexOf('2nd gen intel') > -1
                    ? Intel2ndGenXeonProcessor
                    : ''}
                <ParameterLabel
                    style={{ justifyContent: 'center', fontWeight: '700' }}
                >
                    {highLevelRef}
                </ParameterLabel>
                {benchmarkGroupName &&
                benchmarkGroupName?.indexOf('Baremetal') === -1 ? (
                    <ParameterLabel
                        style={{
                            fontSize: '0.8rem',
                            fontWeight: '300',
                            justifyContent: 'center',
                        }}
                    >
                        {instanceRef}
                    </ParameterLabel>
                ) : (
                    ''
                )}
            </div>
        )
    }

    const renderComparisonPlatformComp = (benchmarkMetadata: any) => {
        let highLevelComp = benchmarkMetadata['high level comp platform']
            ? benchmarkMetadata['high level comp platform'][0]
            : ''
        let instanceCompRef =
            benchmarkMetadata['comparison platform'] &&
            benchmarkMetadata['comparison platform'][0]
                ? 'in ' + benchmarkMetadata['comparison platform'][0]
                : ''
        return (
            <div>
                {highLevelComp.toUpperCase().indexOf('AMD') > -1
                    ? ''
                    : highLevelComp.toLowerCase().indexOf('6th gen intel') >
                          -1 ||
                      highLevelComp.toLowerCase().indexOf('intel® xeon® 6') > -1
                    ? Intel6thGenXeonProcessor
                    : highLevelComp.toLowerCase().indexOf('4th gen intel') >
                          -1 ||
                      highLevelComp.toLowerCase().indexOf('5th gen intel') > -1
                    ? Intel4thGenXeonProcessor
                    : highLevelComp.toLowerCase().indexOf('3rd gen intel') > -1
                    ? Intel3rdGenXeonProcessor
                    : highLevelComp.toLowerCase().indexOf('2nd gen intel') > -1
                    ? Intel2ndGenXeonProcessor
                    : ''}
                <ParameterLabel
                    style={{ justifyContent: 'center', fontWeight: '700' }}
                >
                    {' '}
                    {highLevelComp}
                </ParameterLabel>
                {benchmarkGroupName &&
                benchmarkGroupName?.indexOf('Baremetal') === -1 ? (
                    <ParameterLabel
                        style={{
                            fontSize: '0.8rem',
                            fontWeight: '300',
                            justifyContent: 'center',
                        }}
                    >
                        {instanceCompRef}
                    </ParameterLabel>
                ) : (
                    ''
                )}
            </div>
        )
    }
    const ExtractSystemConfiguration = (configKey: string, data: any) => {
        let result =
            data &&
            data.length > 0 &&
            data.filter(
                (item: string) =>
                    item &&
                    item.indexOf(':') > -1 &&
                    configKey === item.split(':')[0].valueOf()
            )
        return result && result.length > 0 && result[0].indexOf(':') > -1
            ? result[0].split(':')[1]
            : ''
    }

    const renderMoreInfoComponent = (benchmarkData: any, workloadTags: any) => {
        if (!benchmarkData) return
        let isSKUDataAvailable = false

        if (
            workloadTags &&
            (workloadTags['good sku'] ||
                workloadTags['better sku'] ||
                workloadTags['best sku'])
        )
            isSKUDataAvailable = true

        return (
            <div style={{ display: 'flex' }}>
                <div>
                    <PPTDataContainer
                        style={
                            downloadCompleted ||
                            benchmarkData?.benchmarkMetadata[
                                `link to perf package`
                            ]
                                ? { border: '1.5px solid #e2e2e4' }
                                : { display: 'none' }
                        }
                    >
                        {downloadCompleted
                            ? multiPPTSlideRender(benchmarkData?.benchamarkId)
                            : ''}
                        <div
                            style={{
                                display: 'flex',
                                paddingTop: '1rem',
                            }}
                        >
                            {isIntelEmployee &&
                            benchmarkData?.benchmarkMetadata[
                                `link to perf package`
                            ] &&
                            benchmarkData?.benchmarkMetadata[
                                `link to perf package`
                            ].length > 0 ? (
                                <div
                                    style={{
                                        display: 'flex',
                                        marginRight: '0.5rem',
                                    }}
                                >
                                    <RecipeDataLink
                                        href="javascript:void(0)"
                                        onClick={(ev: FormEvent<HTMLElement>) =>
                                            handleSalesLinkClick(
                                                benchmarkData
                                                    ?.benchmarkMetadata[
                                                    `link to perf package`
                                                ][0],
                                                benchmarkData?.workloadId,
                                                ev
                                            )
                                        }
                                        style={{
                                            color: '#0054AE',
                                            paddingTop: '0.2rem',
                                        }}
                                    >
                                        {'Performance Package'}
                                        {LinkImageRender}
                                    </RecipeDataLink>
                                    <StatusLabels>
                                        {renderStatusLegandLabel(
                                            'CNDA',
                                            '#C81326',
                                            '#FFB6B9'
                                        )}
                                    </StatusLabels>
                                </div>
                            ) : (
                                ''
                            )}
                            {/* <RecipeDataLink
                                href="javascript:void(0)"
                                onClick={(ev: FormEvent<HTMLElement>) =>
                                    handleSalesLinkClick(
                                        'https://edc.intel.com/content/www/us/en/products/performance/benchmarks/4th-generation-intel-xeon-scalable-processors/',
                                        benchmarkData?.workloadId,
                                        ev
                                    )
                                }
                                style={{
                                    color: '#0054AE',
                                    marginRight: '0.1rem',
                                    paddingTop: '0.2rem',
                                }}
                            >
                                {'Performance Index'}
                                {LinkImageRender}
                            </RecipeDataLink>
                            <StatusLabels>
                                {renderStatusLegandLabel(
                                    'Public',
                                    '#fff',
                                    '#8BAE46'
                                )}
                            </StatusLabels> */}
                        </div>
                    </PPTDataContainer>

                    <MoreInfoDataContainer style={{ width: '33rem' }}>
                        <Header>BENEFIT</Header>
                        <ul>
                            {workloadTags[`business probl solved`] &&
                            workloadTags[`business probl solved`][0] ? (
                                <li style={{ color: '#0054ae' }}>
                                    <MoreInfoDatalabelValue
                                        style={{ color: '#0054ae' }}
                                    >
                                        {
                                            workloadTags[
                                                `business probl solved`
                                            ][0]
                                        }
                                    </MoreInfoDatalabelValue>
                                </li>
                            ) : (
                                ''
                            )}
                            {workloadTags[`technical problem solved`] &&
                            workloadTags[`technical problem solved`].length >
                                0 ? (
                                <li style={{ color: '#0054ae' }}>
                                    <MoreInfoDatalabelValue
                                        style={{
                                            color: '#0054ae',
                                            width: '20rem',
                                        }}
                                    >
                                        {
                                            workloadTags[
                                                `technical problem solved`
                                            ][0]
                                        }
                                    </MoreInfoDatalabelValue>
                                </li>
                            ) : (
                                ''
                            )}
                            {workloadTags[`value proposition (why)`] &&
                            workloadTags[`value proposition (why)`].length >
                                0 ? (
                                <li style={{ color: '#0054ae' }}>
                                    <MoreInfoDatalabelValue
                                        style={{
                                            color: '#0054ae',
                                            width: '20rem',
                                        }}
                                    >
                                        {
                                            workloadTags[
                                                `value proposition (why)`
                                            ][0]
                                        }
                                    </MoreInfoDatalabelValue>
                                </li>
                            ) : (
                                ''
                            )}
                        </ul>

                        {benchmarkGroupName &&
                        benchmarkGroupName?.indexOf('Baremetal') > -1 &&
                        isSKUDataAvailable ? (
                            <div>
                                <Header>Recommended SKUs</Header>
                                <Table
                                    style={{
                                        background: '#FFFFFF',
                                        border: '1px solid #B9B9B9',
                                        borderRadius: '1px',
                                        //width: '70%',
                                    }}
                                >
                                    <tbody>
                                        <tr
                                            style={{
                                                background: '#AEAEAE',
                                            }}
                                        >
                                            <SKUTableCell
                                                style={{
                                                    color: '#000',
                                                    fontSize: '0.85rem',
                                                }}
                                            >
                                                Performance
                                            </SKUTableCell>
                                            <SKUTableCell
                                                style={{
                                                    color: '#000',
                                                    fontSize: '0.85rem',
                                                }}
                                            >
                                                Processor SKUs
                                            </SKUTableCell>
                                        </tr>
                                        <tr>
                                            <SKUTableCell
                                                style={{ color: '#000' }}
                                            >
                                                Best
                                            </SKUTableCell>
                                            <SKUTableCell
                                                style={{
                                                    color: '#000',
                                                    fontWeight: '400',
                                                }}
                                            >
                                                {workloadTags &&
                                                workloadTags['best sku']
                                                    ? workloadTags[
                                                          'best sku'
                                                      ][0]
                                                    : ''}
                                            </SKUTableCell>
                                        </tr>
                                        <tr>
                                            <SKUTableCell
                                                style={{ color: '#000' }}
                                            >
                                                Better
                                            </SKUTableCell>
                                            <SKUTableCell
                                                style={{
                                                    color: '#000',
                                                    fontWeight: '400',
                                                }}
                                            >
                                                {workloadTags &&
                                                workloadTags['better sku']
                                                    ? workloadTags[
                                                          'better sku'
                                                      ][0]
                                                    : ''}
                                            </SKUTableCell>
                                        </tr>
                                        <tr>
                                            <SKUTableCell
                                                style={{ color: '#000' }}
                                            >
                                                Good
                                            </SKUTableCell>
                                            <SKUTableCell
                                                style={{
                                                    color: '#000',
                                                    fontWeight: '400',
                                                }}
                                            >
                                                {workloadTags &&
                                                workloadTags['good sku']
                                                    ? workloadTags[
                                                          'good sku'
                                                      ][0]
                                                    : ''}
                                            </SKUTableCell>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        ) : (
                            ''
                        )}

                        <div>
                            <table>
                                <tbody>
                                    <tr>
                                        {benchmarkData?.benchmarkMetadata[
                                            `performance package owner`
                                        ] || isDataValidator ? (
                                            <td>
                                                <SubHeader>
                                                    Performance Package Owner
                                                </SubHeader>
                                                <MoreInfoDatalabelValue
                                                    style={{
                                                        width: '15rem',
                                                        color: '#0054AE',
                                                    }}
                                                >
                                                    {benchmarkData
                                                        ?.benchmarkMetadata[
                                                        `performance package owner`
                                                    ]
                                                        ? benchmarkData
                                                              ?.benchmarkMetadata[
                                                              `performance package owner`
                                                          ][0]
                                                        : 'XXXX'}
                                                </MoreInfoDatalabelValue>
                                            </td>
                                        ) : (
                                            ''
                                        )}
                                        {(benchmarkData?.benchmarkMetadata[
                                            `technical owner`
                                        ] &&
                                            benchmarkData?.benchmarkMetadata[
                                                `technical owner`
                                            ].length > 0) ||
                                        isDataValidator ? (
                                            <td>
                                                <SubHeader>
                                                    Technical Owner
                                                </SubHeader>
                                                <MoreInfoDatalabelValue
                                                    style={{
                                                        width: '15rem',
                                                        color: '#0054AE',
                                                    }}
                                                >
                                                    {benchmarkData
                                                        ?.benchmarkMetadata[
                                                        `technical owner`
                                                    ] &&
                                                    benchmarkData
                                                        ?.benchmarkMetadata[
                                                        `technical owner`
                                                    ].length > 0
                                                        ? benchmarkData
                                                              ?.benchmarkMetadata[
                                                              `technical owner`
                                                          ][0]
                                                        : 'XXXX'}
                                                </MoreInfoDatalabelValue>
                                            </td>
                                        ) : (
                                            ''
                                        )}
                                    </tr>
                                    <tr>
                                        {workloadTags[`marketing owner`] ||
                                        isDataValidator ? (
                                            <td>
                                                <SubHeader>
                                                    Marketing Owner
                                                </SubHeader>
                                                <MoreInfoDatalabelValue
                                                    style={{
                                                        width: '15rem',
                                                        color: '#0054AE',
                                                    }}
                                                >
                                                    {workloadTags[
                                                        `marketing owner`
                                                    ]
                                                        ? workloadTags[
                                                              `marketing owner`
                                                          ][0]
                                                        : 'XXXX'}
                                                </MoreInfoDatalabelValue>
                                            </td>
                                        ) : (
                                            ''
                                        )}
                                        {(benchmarkData?.benchmarkMetadata[
                                            'date created'
                                        ] &&
                                            benchmarkData?.benchmarkMetadata[
                                                'date created'
                                            ].length > 0) ||
                                        isDataValidator ? (
                                            <td>
                                                <SubHeader>
                                                    Last Updated
                                                </SubHeader>
                                                <MoreInfoDatalabelValue
                                                    style={{
                                                        width: '15rem',
                                                        color: '#0054AE',
                                                    }}
                                                >
                                                    {benchmarkData
                                                        ?.benchmarkMetadata[
                                                        'date created'
                                                    ]
                                                        ? benchmarkData
                                                              ?.benchmarkMetadata[
                                                              'date created'
                                                          ][0]
                                                        : 'XXXX'}{' '}
                                                </MoreInfoDatalabelValue>
                                            </td>
                                        ) : (
                                            ''
                                        )}
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </MoreInfoDataContainer>
                </div>

                <div>
                    <PerfResultsDiv
                        style={
                            isBenchmark
                                ? { background: '#0054AE' }
                                : isCloudBenchmark
                                ? { background: '#005374' }
                                : { background: '#0054AE' }
                        }
                    >
                        <Header style={{ color: '#fff' }}>RESULTS </Header>
                        <div style={{ display: 'flex' }}>
                            <PerformanceLabel
                                style={
                                    isBenchmark
                                        ? { color: '#6DDCFF' }
                                        : isCloudBenchmark
                                        ? { color: '#6DDCFF' }
                                        : { color: '#6DDCFF' }
                                }
                            >
                                {benchmarkData?.performanceValue}
                            </PerformanceLabel>
                            <ParameterLabel
                                style={
                                    isBenchmark
                                        ? {
                                              color: '#6DDCFF',
                                              marginTop: '0.8rem',
                                              marginLeft: '0.5rem',
                                          }
                                        : isCloudBenchmark
                                        ? {
                                              color: '#6DDCFF',
                                              marginTop: '0.8rem',
                                              marginLeft: '0.5rem',
                                          }
                                        : {
                                              color: '#6DDCFF',
                                              marginTop: '0.8rem',
                                              marginLeft: '0.5rem',
                                          }
                                }
                            >
                                {benchmarkData?.metricName}
                            </ParameterLabel>
                        </div>
                        {isDataValidator ? (
                            <ParameterLabel
                                style={{ color: '#FFF', fontSize: '0.8rem' }}
                            >
                                Unique ID:{' '}
                                {benchmarkData?.benchmarkMetadata['unique id']}
                            </ParameterLabel>
                        ) : (
                            ''
                        )}
                    </PerfResultsDiv>
                    {benchmarkData &&
                    benchmarkData?.additionalPerformanceNumbers &&
                    benchmarkData?.additionalPerformanceNumbers.values &&
                    benchmarkData?.additionalPerformanceNumbers?.values.length >
                        0 ? (
                        <div
                            style={{
                                width: '40rem',
                                paddingLeft: '1rem',
                            }}
                        >
                            <table
                                style={{
                                    width: '40rem',
                                    border: '1px solid #653171',
                                    boxShadow: '0px 2px 4px 0px #04040461',
                                }}
                            >
                                <CPUInstanceHeader>
                                    Performance
                                </CPUInstanceHeader>
                                {benchmarkData?.additionalPerformanceNumbers
                                    ?.differentiator &&
                                    benchmarkData?.additionalPerformanceNumbers
                                        ?.differentiator.length > 0 &&
                                    benchmarkData?.additionalPerformanceNumbers?.differentiator.map(
                                        (item: string) => (
                                            <>
                                                {item !== 'UID' ? (
                                                    <CPUInstanceHeader>
                                                        {item}
                                                    </CPUInstanceHeader>
                                                ) : item === 'UID' &&
                                                  isDataValidator &&
                                                  isUIDExist(
                                                      benchmarkData
                                                          ?.additionalPerformanceNumbers
                                                          ?.values
                                                  ) ? (
                                                    <CPUInstanceHeader>
                                                        {item}
                                                    </CPUInstanceHeader>
                                                ) : (
                                                    ''
                                                )}
                                            </>
                                        )
                                    )}
                                {benchmarkGroupTypeName &&
                                benchmarkGroupTypeName?.indexOf('Cloud') >
                                    -1 ? (
                                    <CPUInstanceHeader>
                                        Reference Instance
                                    </CPUInstanceHeader>
                                ) : (
                                    <CPUInstanceHeader>
                                        Reference
                                    </CPUInstanceHeader>
                                )}
                                {benchmarkGroupTypeName &&
                                benchmarkGroupTypeName?.indexOf('Cloud') >
                                    -1 ? (
                                    <CPUInstanceHeader>
                                        Target Instance
                                    </CPUInstanceHeader>
                                ) : (
                                    <CPUInstanceHeader>
                                        Target
                                    </CPUInstanceHeader>
                                )}
                                <tbody>
                                    {benchmarkData?.additionalPerformanceNumbers?.values.map(
                                        (item: any) => (
                                            <>
                                                <tr>
                                                    <CPUInstanceCell
                                                        style={{
                                                            fontWeight: '500',
                                                        }}
                                                    >
                                                        {item.value}
                                                    </CPUInstanceCell>

                                                    {benchmarkData
                                                        ?.additionalPerformanceNumbers
                                                        ?.differentiator &&
                                                        benchmarkData
                                                            ?.additionalPerformanceNumbers
                                                            ?.differentiator
                                                            .length > 0 &&
                                                        benchmarkData?.additionalPerformanceNumbers?.differentiator.map(
                                                            (
                                                                columnName: string
                                                            ) => (
                                                                <>
                                                                    {columnName &&
                                                                    columnName !==
                                                                        'UID' &&
                                                                    item?.differentiatorValue ? (
                                                                        <CPUInstanceCell>
                                                                            {
                                                                                item
                                                                                    ?.differentiatorValue[
                                                                                    columnName
                                                                                ]
                                                                            }
                                                                        </CPUInstanceCell>
                                                                    ) : columnName ===
                                                                          'UID' &&
                                                                      isDataValidator &&
                                                                      isUIDExist(
                                                                          benchmarkData
                                                                              ?.additionalPerformanceNumbers
                                                                              ?.values
                                                                      ) ? (
                                                                        <CPUInstanceCell>
                                                                            {
                                                                                item
                                                                                    ?.differentiatorValue[
                                                                                    columnName
                                                                                ]
                                                                            }
                                                                        </CPUInstanceCell>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                </>
                                                            )
                                                        )}

                                                    {benchmarkGroupTypeName &&
                                                    benchmarkGroupTypeName?.indexOf(
                                                        'Cloud'
                                                    ) > -1 ? (
                                                        <CPUInstanceCell>
                                                            {item.refInstance}
                                                        </CPUInstanceCell>
                                                    ) : (
                                                        <CPUInstanceCell>
                                                            {benchmarkData
                                                                ?.benchmarkMetadata[
                                                                'high level reference platform'
                                                            ]
                                                                ? benchmarkData
                                                                      ?.benchmarkMetadata[
                                                                      'high level reference platform'
                                                                  ][0]
                                                                : ''}
                                                        </CPUInstanceCell>
                                                    )}
                                                    {benchmarkGroupTypeName &&
                                                    benchmarkGroupTypeName?.indexOf(
                                                        'Cloud'
                                                    ) > -1 ? (
                                                        <CPUInstanceCell>
                                                            {item.compInstance}
                                                        </CPUInstanceCell>
                                                    ) : (
                                                        <CPUInstanceCell>
                                                            {benchmarkData
                                                                ?.benchmarkMetadata[
                                                                'high level comp platform'
                                                            ]
                                                                ? benchmarkData
                                                                      ?.benchmarkMetadata[
                                                                      'high level comp platform'
                                                                  ][0]
                                                                : ''}
                                                        </CPUInstanceCell>
                                                    )}
                                                </tr>
                                            </>
                                        )
                                    )}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        ''
                    )}

                    <Header
                        style={{
                            textTransform: 'uppercase',
                            marginLeft: '1rem',
                            padding: '1rem 0',
                        }}
                    >
                        System Configurations
                    </Header>

                    <MoreInfoSCContainer style={{ width: '40rem' }}>
                        <MoreInfoDatalabelValue style={{ width: '40rem' }}>
                            <MoreInfoDataCol
                                style={{ width: '10rem', margin: '0.01rem' }}
                            >
                                {''}
                            </MoreInfoDataCol>
                            <MoreInfoDataCol
                                style={{ width: '13rem', margin: '0.01rem' }}
                            >
                                {benchmarkData?.benchmarkMetadata
                                    ? renderComparisonPlatformRef(
                                          benchmarkData?.benchmarkMetadata
                                      )
                                    : '4th Gen Intel® Xeon® Scalable processor'}
                            </MoreInfoDataCol>

                            <MoreInfoDataCol
                                style={{ width: '13rem', margin: '0.01rem' }}
                            >
                                {benchmarkData?.benchmarkMetadata
                                    ? renderComparisonPlatformComp(
                                          benchmarkData?.benchmarkMetadata
                                      )
                                    : '4th Generation AMD EPYC™ Processors'}
                            </MoreInfoDataCol>
                        </MoreInfoDatalabelValue>
                        <ConfigTableHeader>
                            <ConfigHeader>Software Configurations</ConfigHeader>
                        </ConfigTableHeader>

                        <MoreInfoSClabelValue style={{ width: '40rem' }}>
                            <table style={{ width: '39.8rem' }}>
                                <tbody>
                                    {benchmarkData?.benchmarkMetadata[
                                        'sw configuration reference platform'
                                    ] &&
                                    benchmarkData?.benchmarkMetadata[
                                        'sw configuration reference platform'
                                    ].length > 0
                                        ? benchmarkData?.benchmarkMetadata[
                                              'sw configuration reference platform'
                                          ].map(
                                              (val: string, index: number) => {
                                                  return (
                                                      <tr>
                                                          <SoftwareCofigDataCell
                                                              style={{
                                                                  fontWeight:
                                                                      '700',
                                                              }}
                                                          >
                                                              {val &&
                                                              val.indexOf(':') >
                                                                  -1
                                                                  ? val.split(
                                                                        ':'
                                                                    )[0]
                                                                  : ''}
                                                          </SoftwareCofigDataCell>
                                                          <SoftwareCofigDataCell>
                                                              {val &&
                                                              val.indexOf(':') >
                                                                  -1
                                                                  ? val.split(
                                                                        ':'
                                                                    )[1]
                                                                  : ''}
                                                          </SoftwareCofigDataCell>
                                                          <SoftwareCofigDataCell>
                                                              {ExtractSystemConfiguration(
                                                                  val.split(
                                                                      ':'
                                                                  )[0],
                                                                  benchmarkData
                                                                      ?.benchmarkMetadata[
                                                                      'sw configuration comp platform'
                                                                  ]
                                                              )}
                                                          </SoftwareCofigDataCell>
                                                      </tr>
                                                  )
                                              }
                                          )
                                        : ''}
                                </tbody>
                            </table>
                        </MoreInfoSClabelValue>
                        <ConfigTableHeader>
                            <ConfigHeader>Hardware Configurations</ConfigHeader>
                        </ConfigTableHeader>
                        <MoreInfoSClabelValue style={{ width: '40rem' }}>
                            <table style={{ width: '39.8rem' }}>
                                <tbody>
                                    {benchmarkData?.benchmarkMetadata[
                                        'hw configuration reference platform'
                                    ] &&
                                    benchmarkData?.benchmarkMetadata[
                                        'hw configuration reference platform'
                                    ].length > 0
                                        ? benchmarkData?.benchmarkMetadata[
                                              'hw configuration reference platform'
                                          ].map(
                                              (val: string, index: number) => {
                                                  return (
                                                      <tr>
                                                          <SoftwareCofigDataCell
                                                              style={{
                                                                  fontWeight:
                                                                      '700',
                                                              }}
                                                          >
                                                              {val &&
                                                              val.indexOf(':') >
                                                                  -1
                                                                  ? val.split(
                                                                        ':'
                                                                    )[0]
                                                                  : ''}
                                                          </SoftwareCofigDataCell>
                                                          <SoftwareCofigDataCell>
                                                              {val &&
                                                              val.indexOf(':') >
                                                                  -1
                                                                  ? val.split(
                                                                        ':'
                                                                    )[1]
                                                                  : ''}
                                                          </SoftwareCofigDataCell>
                                                          <SoftwareCofigDataCell>
                                                              {ExtractSystemConfiguration(
                                                                  val.split(
                                                                      ':'
                                                                  )[0],
                                                                  benchmarkData
                                                                      ?.benchmarkMetadata[
                                                                      'hw configuration comp platform'
                                                                  ]
                                                              )}
                                                          </SoftwareCofigDataCell>
                                                      </tr>
                                                  )
                                              }
                                          )
                                        : ''}
                                </tbody>
                            </table>
                        </MoreInfoSClabelValue>
                    </MoreInfoSCContainer>
                </div>
            </div>
        )
    }

    const multiPPTSlideRender = (benchamarkId?: string) => {
        return (
            <Carousel variant="dark" interval={null}>
                {attachmentFilenames && attachmentFilenames.length > 0 ? (
                    <Carousel.Item>
                        {PPTSlideRender(attachmentFilenames[0], benchamarkId)}
                    </Carousel.Item>
                ) : (
                    ''
                )}
                {attachmentFilenames && attachmentFilenames.length > 1 ? (
                    <Carousel.Item>
                        {PPTSlideRender(attachmentFilenames[1], benchamarkId)}
                    </Carousel.Item>
                ) : (
                    ''
                )}
                {attachmentFilenames && attachmentFilenames.length > 2 ? (
                    <Carousel.Item>
                        {PPTSlideRender(attachmentFilenames[2], benchamarkId)}
                    </Carousel.Item>
                ) : (
                    ''
                )}
                {attachmentFilenames && attachmentFilenames.length > 3 ? (
                    <Carousel.Item>
                        {PPTSlideRender(attachmentFilenames[3], benchamarkId)}
                    </Carousel.Item>
                ) : (
                    ''
                )}
                {attachmentFilenames && attachmentFilenames.length > 4 ? (
                    <Carousel.Item>
                        {PPTSlideRender(attachmentFilenames[4], benchamarkId)}
                    </Carousel.Item>
                ) : (
                    ''
                )}
                {attachmentFilenames && attachmentFilenames.length > 5 ? (
                    <Carousel.Item>
                        {PPTSlideRender(attachmentFilenames[5], benchamarkId)}
                    </Carousel.Item>
                ) : (
                    ''
                )}

                <Carousel.Item>
                    {PPTDisclaimerRender(benchamarkId)}
                </Carousel.Item>
            </Carousel>
        )
    }
    const renderBenchmarkTable = (benchmarksList: any, workloadTags: any) => {
        return (
            <Table
                responsive
                style={{ background: '#fff', padding: '5%', width: '78rem' }}
            >
                <thead
                    style={{
                        background: '#0054AE',
                        border: '1px solid #0054AE',
                        width: '50rem',
                    }}
                >
                    <BenchmarkTableRow>
                        <BenchmarkTableHeader
                            style={
                                benchmarkGroupName &&
                                benchmarkGroupName?.indexOf('Baremetal') > -1
                                    ? { background: '#0054AE' }
                                    : { background: '#0089B9' }
                            }
                        >
                            {benchmarkGroupName &&
                            benchmarkGroupName?.indexOf('GCP') > -1
                                ? 'Google Cloud Platform Testing'
                                : benchmarkGroupName &&
                                  benchmarkGroupName?.indexOf('AWS') > -1
                                ? 'AWS Cloud Platform Testing'
                                : 'Baremetal Testing'}
                        </BenchmarkTableHeader>
                    </BenchmarkTableRow>
                </thead>
                <tbody>
                    {benchmarksList && benchmarksList?.length === 0 ? (
                        <div>{NoResultFoundPanel()}</div>
                    ) : (
                        ''
                    )}

                    {benchmarksList?.map(
                        (benchmarkData: benchmarksData, index: number) => {
                            return (
                                <>
                                    <BenchmarkTableRow key={index}>
                                        <BenchmarkTableCol2
                                            style={{
                                                verticalAlign: 'middle',
                                                width: '32%',
                                                paddingLeft: '2rem',
                                            }}
                                        >
                                            {benchmarkData?.benchmarkMetadata[
                                                'selling prioritization'
                                            ] &&
                                            benchmarkData?.benchmarkMetadata[
                                                'selling prioritization'
                                            ][0] === 'Prioritized'
                                                ? winningBadge
                                                : ''}
                                            <PerformanceLabel>
                                                {benchmarkData
                                                    .additionalPerformanceNumbers
                                                    ?.range
                                                    ? benchmarkData
                                                          .additionalPerformanceNumbers
                                                          ?.range
                                                    : benchmarkData?.performanceValue}
                                            </PerformanceLabel>
                                            <ParameterLabel>
                                                {benchmarkData?.metricName}
                                            </ParameterLabel>
                                        </BenchmarkTableCol2>

                                        <BenchmarkTableCol
                                            style={{ width: '16%' }}
                                        >
                                            {benchmarkData?.benchmarkMetadata
                                                ? renderComparisonPlatformRef(
                                                      benchmarkData?.benchmarkMetadata
                                                  )
                                                : '4th Gen Intel® Xeon® Scalable processor'}
                                        </BenchmarkTableCol>
                                        <BenchmarkTableCol>
                                            <VSLabel>VS.</VSLabel>
                                        </BenchmarkTableCol>
                                        <BenchmarkTableCol
                                            style={{ width: '16%' }}
                                        >
                                            {benchmarkData?.benchmarkMetadata
                                                ? renderComparisonPlatformComp(
                                                      benchmarkData?.benchmarkMetadata
                                                  )
                                                : '4th Generation AMD EPYC™ Processors'}
                                        </BenchmarkTableCol>
                                        <BenchmarkTableCol
                                            style={{
                                                width: '10%',
                                            }}
                                        >
                                            <StatusLabels>
                                                {benchmarkData?.status ===
                                                'Public'
                                                    ? renderStatusLegandLabel(
                                                          'Public',
                                                          '#fff',
                                                          '#8BAE46'
                                                      )
                                                    : benchmarkData?.status ===
                                                      'CNDA'
                                                    ? renderStatusLegandLabel(
                                                          'CNDA',
                                                          '#C81326',
                                                          '#FFB6B9'
                                                      )
                                                    : benchmarkData?.status ===
                                                      'NDA'
                                                    ? renderStatusLegandLabel(
                                                          'NDA',
                                                          '#C81326',
                                                          '#FFB6B9'
                                                      )
                                                    : benchmarkData?.status ===
                                                      'Internal Only'
                                                    ? renderStatusLegandLabel(
                                                          'Internal Only',
                                                          '#906700',
                                                          '#FFE17A'
                                                      )
                                                    : ''}
                                                {benchmarkData?.CompletionStatus ===
                                                'Complete'
                                                    ? renderStatusLegandLabel(
                                                          'Complete',
                                                          '#fff',
                                                          '#0054AE'
                                                      )
                                                    : benchmarkData?.CompletionStatus ===
                                                      'WIP'
                                                    ? renderStatusLegandLabel(
                                                          'WIP',
                                                          '#0054AE',
                                                          '#D0DDEA'
                                                      )
                                                    : ''}
                                            </StatusLabels>
                                        </BenchmarkTableCol>
                                        <BenchmarkTableCol>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent:
                                                        'space-evenly',
                                                }}
                                            >
                                                <ShareButton
                                                    type="button"
                                                    onClick={(e: any) =>
                                                        onShareLinkClick(e)
                                                    }
                                                    value={shareLinkURL}
                                                    title={'Share with others'}
                                                >
                                                    <img
                                                        src="/share_sm.png"
                                                        alt="share link"
                                                        style={{
                                                            height: '18px',
                                                            width: '17px',
                                                            marginRight: '1rem',
                                                        }}
                                                    />
                                                </ShareButton>
                                                <MoreInfoButton
                                                    id={'expand' + index}
                                                    type="button"
                                                    title={'View more'}
                                                    value={
                                                        showBenchmarkMetaData
                                                    }
                                                    onClick={(evt: any) =>
                                                        onShowBenchmarkMetaDataClick(
                                                            evt,
                                                            `expand${index}`,
                                                            benchmarkData?.benchamarkId,
                                                            benchmarkData?.attachmentFilenames
                                                        )
                                                    }
                                                >
                                                    {showBenchmarkMetaData &&
                                                    rowId ===
                                                        `expand${index}` ? (
                                                        <>
                                                            <img
                                                                src="/sales_img/chevron-up.png"
                                                                alt="down arrow icon"
                                                                style={{
                                                                    height: '18',
                                                                    width: '17',
                                                                    paddingTop:
                                                                        '0.4rem',
                                                                }}
                                                            />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <img
                                                                src="/sales_img/chevron-down.png"
                                                                alt="down arrow icon"
                                                                style={{
                                                                    height: '18',
                                                                    width: '17',
                                                                    paddingTop:
                                                                        '0.4rem',
                                                                }}
                                                            />
                                                        </>
                                                    )}
                                                </MoreInfoButton>
                                            </div>
                                        </BenchmarkTableCol>
                                    </BenchmarkTableRow>
                                    {showBenchmarkMetaData === true &&
                                    rowId === `expand${index}` ? (
                                        <BenchmarkTableRow
                                            style={{
                                                padding: '0px',
                                                borderTopColor: '#00B1FD',
                                                borderTopWidth: '1px',
                                            }}
                                        >
                                            <td
                                                style={{
                                                    padding: '0px',
                                                }}
                                                colSpan={7}
                                            >
                                                <UseCaseDetailsContainer>
                                                    {benchmarkData
                                                        ?.benchmarkMetadata[
                                                        `gmo use cases`
                                                    ] &&
                                                    benchmarkData
                                                        ?.benchmarkMetadata[
                                                        `gmo use cases`
                                                    ].length > 0 &&
                                                    benchmarkData
                                                        ?.benchmarkMetadata[
                                                        `gmo use cases`
                                                    ][0] !== 'NA' ? (
                                                        <div>
                                                            Use Case:
                                                            <span
                                                                style={{
                                                                    fontWeight:
                                                                        '400',
                                                                    padding:
                                                                        '0 0.5rem',
                                                                }}
                                                            >
                                                                {
                                                                    benchmarkData
                                                                        ?.benchmarkMetadata[
                                                                        `gmo use cases`
                                                                    ][0]
                                                                }
                                                            </span>
                                                        </div>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {workloadTags &&
                                                    workloadTags[`industry`] &&
                                                    workloadTags[`industry`]
                                                        .length > 0 &&
                                                    workloadTags[
                                                        `industry`
                                                    ][0] !== 'NA' ? (
                                                        <div>
                                                            Industry:{' '}
                                                            <span
                                                                style={{
                                                                    fontWeight:
                                                                        '400',
                                                                    padding:
                                                                        '0 0.5rem',
                                                                }}
                                                            >
                                                                {
                                                                    workloadTags[
                                                                        `industry`
                                                                    ][0]
                                                                }
                                                            </span>
                                                        </div>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {benchmarkData
                                                        ?.benchmarkMetadata[
                                                        `ref sw workload version`
                                                    ] &&
                                                    benchmarkData
                                                        ?.benchmarkMetadata[
                                                        `ref sw workload version`
                                                    ].length > 0 &&
                                                    benchmarkData
                                                        ?.benchmarkMetadata[
                                                        `ref sw workload version`
                                                    ][0] !== 'NA' ? (
                                                        <div>
                                                            Version:{' '}
                                                            <span
                                                                style={{
                                                                    fontWeight:
                                                                        '400',
                                                                    padding:
                                                                        '0 0.5rem',
                                                                }}
                                                            >
                                                                {
                                                                    benchmarkData
                                                                        ?.benchmarkMetadata[
                                                                        `ref sw workload version`
                                                                    ][0]
                                                                }
                                                            </span>
                                                        </div>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {benchmarkData
                                                        ?.benchmarkMetadata[
                                                        `ref hw accelerators`
                                                    ] &&
                                                    benchmarkData
                                                        ?.benchmarkMetadata[
                                                        `ref hw accelerators`
                                                    ].length > 0 &&
                                                    benchmarkData
                                                        ?.benchmarkMetadata[
                                                        `ref hw accelerators`
                                                    ][0] !== 'NA' ? (
                                                        <div>
                                                            Feature:{' '}
                                                            <span
                                                                style={{
                                                                    fontWeight:
                                                                        '400',
                                                                    padding:
                                                                        '0 0.5rem',
                                                                }}
                                                            >
                                                                {
                                                                    benchmarkData
                                                                        ?.benchmarkMetadata[
                                                                        `ref hw accelerators`
                                                                    ][0]
                                                                }
                                                            </span>
                                                        </div>
                                                    ) : (
                                                        ''
                                                    )}
                                                </UseCaseDetailsContainer>
                                                <BenchmarkMetaDataContainer>
                                                    {renderMoreInfoComponent(
                                                        benchmarkData,
                                                        workloadTags
                                                    )}
                                                </BenchmarkMetaDataContainer>
                                            </td>
                                        </BenchmarkTableRow>
                                    ) : (
                                        ''
                                    )}
                                </>
                            )
                        }
                    )}
                </tbody>
            </Table>
        )
    }
    const renderLinkComponent =
        workloadResources &&
        Object.keys(workloadResources).map((category: any) => {
            {
                const data = workloadResources[category]
                //console.log(data)

                return (
                    <>
                        <div>
                            <CollateralTextLabel>
                                {category}
                            </CollateralTextLabel>
                            {data && data.length > 0
                                ? data.map((link: any) => (
                                      <ResourcesLinksText
                                          style={{ marginLeft: '1rem' }}
                                      >
                                          <ResourcesLinksTextValue>
                                              <ResourcesLink
                                                  href={link.url}
                                                  target={'_blank'}
                                                  rel="noopener"
                                              >
                                                  {link.name}
                                                  {LinkImageRender}
                                              </ResourcesLink>
                                          </ResourcesLinksTextValue>
                                          {link.labels[0] &&
                                          link.labels[0] === 'Public'
                                              ? renderStatusLegandLabel(
                                                    'Public',
                                                    '#fff',
                                                    '#8BAE46'
                                                )
                                              : link.labels[0] === 'CNDA'
                                              ? renderStatusLegandLabel(
                                                    'CNDA',
                                                    '#C81326',
                                                    '#FFB6B9'
                                                )
                                              : link.labels[0] === 'NDA'
                                              ? renderStatusLegandLabel(
                                                    'NDA',
                                                    '#C81326',
                                                    '#FFB6B9'
                                                )
                                              : link.labels[0] ===
                                                'Internal Only'
                                              ? renderStatusLegandLabel(
                                                    'Internal Only',
                                                    '#906700',
                                                    '#FFE17A'
                                                )
                                              : ''}
                                      </ResourcesLinksText>
                                  ))
                                : ''}
                        </div>
                    </>
                )
            }
        })

    const renderEcoSystemTab = () => {
        // TODO acwrenn
        // Always hide ecosystem data for now
        if ((ecoData && Object.keys(ecoData).length === 0) || true) {
            return <div></div>
        }
        return (
            <StyledTab
                eventKey=" Ecosystem Availability"
                title={
                    <StyledTabLabel tabIndex={0}>
                        Ecosystem Availability
                    </StyledTabLabel>
                }
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                        background: '#f4f5f5',
                    }}
                >
                    <EcosystemTabContainer>
                        <EcoSystemHeaderDiv>
                            Ecosystem Availability
                        </EcoSystemHeaderDiv>
                        <Header
                            style={{
                                padding: '1rem',
                                color: '#2B2C30',
                                fontWeight: '400',
                            }}
                        >
                            Supported status on 4th Gen Intel® Xeon® Scalable
                            processor
                        </Header>
                        {renderEcoSystemComponent()}
                    </EcosystemTabContainer>
                </div>
            </StyledTab>
        )
    }

    const renderEcoSystemComponent = () => {
        if (
            !(
                benchmarksList &&
                benchmarksList.length > 0 &&
                benchmarksList[0].benchmarkMetadata
            )
        )
            <div>{'Benchmark Ecosystem data is not available'}</div>

        const ExtractLabelsAndValues = (data: any) => {
            let labelsList: any[] = []
            let valuesList: any[] = []
            data &&
                data.map((item: string, itemIndex: number) => {
                    if (item.indexOf(';') > -1) {
                        let pairs = item.split(';')
                        if (pairs && pairs.length > 0) {
                            pairs.map((pair: string, index: number) => {
                                labelsList[index] = pair.split(':')[0]
                                valuesList[index] = pair.split(':')[1]
                            })
                        }
                    } else {
                        labelsList[itemIndex] = item.split(':')[0]
                        valuesList[itemIndex] = item.split(':')[1]
                    }
                })
            return { labelsList: labelsList, valuesList: valuesList }
        }

        const OEMData = workloadTags
            ? workloadTags[`${benchmarkGroup.toLowerCase()}oem availability`]
            : []

        const OSVData = workloadTags
            ? workloadTags[`${benchmarkGroup.toLowerCase()}os availability`]
            : []

        const ISVData = workloadTags
            ? workloadTags[`${benchmarkGroup.toLowerCase()}isv availability`]
            : []

        const SIData = workloadTags
            ? workloadTags[`${benchmarkGroup.toLowerCase()}si availability`]
            : []

        const CSPData = workloadTags
            ? workloadTags[`${benchmarkGroup.toLowerCase()}csp availability`]
            : []

        const renderEcosystemAvailablityData = (
            data: any,
            dataFieldName: string
        ) => {
            return (
                <>
                    {data && data.length > 0
                        ? data.map((item: string, index: number) => (
                              <tr>
                                  {index == 0 ? (
                                      <SKUTableCell
                                          rowSpan={data.length}
                                          style={{
                                              background: '#0170BA',
                                          }}
                                      >
                                          {dataFieldName}
                                      </SKUTableCell>
                                  ) : (
                                      ''
                                  )}
                                  <SKUTableCell
                                      style={{
                                          background: '#01538C',
                                      }}
                                  >
                                      {item.split(':')[0]}
                                  </SKUTableCell>
                                  <SKUTableCell
                                      style={
                                          item &&
                                          item.split(':')[1] &&
                                          item
                                              .split(':')[1]
                                              .toLowerCase()
                                              .trim() == 'tbd'
                                              ? {
                                                    background: '#CBE1EF',
                                                    fontWeight: '500',
                                                    color: '#01538C',
                                                }
                                              : item &&
                                                item.split(':')[1] &&
                                                item
                                                    .split(':')[1]
                                                    .toLowerCase()
                                                    .trim() == 'not planned'
                                              ? {
                                                    background: '#E9EAEB',
                                                    fontWeight: '500',
                                                    color: '#01538C',
                                                }
                                              : {
                                                    background: '#90CEFA',
                                                    color: '#01538C',
                                                    fontWeight: '700',
                                                }
                                      }
                                  >
                                      {item.split(':')[1]}
                                  </SKUTableCell>
                              </tr>
                          ))
                        : ''}
                </>
            )
        }

        return (
            <Table responsive style={{ padding: '0' }}>
                <tbody>
                    <tr>
                        <td style={{ border: 'none' }}>
                            <EcoSystemSubHeader
                                style={{ paddingLeft: '0.2rem' }}
                            >
                                OEM, OS & OSV, ISV, SI Availability
                            </EcoSystemSubHeader>
                            <Table
                                style={{
                                    background: '#FFFFFF',
                                    border: '1px solid #B9B9B9',
                                    borderRadius: '2px',
                                    width: '100%',
                                }}
                            >
                                <tbody>
                                    <tr style={{ background: '#0170BA' }}>
                                        <SKUTableCell></SKUTableCell>
                                        <SKUTableCell></SKUTableCell>
                                        <SKUTableCell
                                            style={{ fontWeight: '700' }}
                                        >
                                            4th Gen Xeon
                                        </SKUTableCell>
                                    </tr>
                                    {renderEcosystemAvailablityData(
                                        OEMData,
                                        'OEM'
                                    )}
                                    {renderEcosystemAvailablityData(
                                        OSVData,
                                        'OS & OSV'
                                    )}
                                    {renderEcosystemAvailablityData(
                                        ISVData,
                                        'ISV'
                                    )}
                                    {renderEcosystemAvailablityData(
                                        SIData,
                                        'SI'
                                    )}
                                </tbody>
                            </Table>
                        </td>
                        <td style={{ border: 'none' }}>
                            {' '}
                            <EcoSystemSubHeader
                                style={{ marginLeft: '1.2rem' }}
                            >
                                CSP Availability
                            </EcoSystemSubHeader>
                            <Table
                                style={{
                                    background: '#FFFFFF',
                                    border: '1px solid #B9B9B9',
                                    borderRadius: '2px',
                                    width: '90%',
                                    marginLeft: '2rem',
                                }}
                            >
                                <tbody>
                                    <tr style={{ background: '#0170BA' }}>
                                        <SKUTableCell
                                            style={{
                                                fontWeight: '700',
                                            }}
                                        >
                                            CSP
                                        </SKUTableCell>
                                        <SKUTableCell
                                            style={{
                                                fontWeight: '700',
                                            }}
                                        >
                                            Private Preview
                                        </SKUTableCell>
                                        <SKUTableCell
                                            style={{
                                                fontWeight: '700',
                                            }}
                                        >
                                            Public Preview
                                        </SKUTableCell>
                                        <SKUTableCell
                                            style={{
                                                fontWeight: '700',
                                            }}
                                        >
                                            GA
                                        </SKUTableCell>
                                    </tr>
                                    {CSPData &&
                                        CSPData.map((item: string) => {
                                            return (
                                                <>
                                                    <tr>
                                                        <SKUTableCell
                                                            style={{
                                                                background:
                                                                    '#01538C',
                                                            }}
                                                        >
                                                            {item.split(':')[0]}
                                                        </SKUTableCell>
                                                        {item.split(':')[1] ? (
                                                            <>
                                                                {item
                                                                    .split(
                                                                        ':'
                                                                    )[1]
                                                                    .split(';')
                                                                    .map(
                                                                        (
                                                                            val: string
                                                                        ) => {
                                                                            return (
                                                                                <>
                                                                                    <SKUTableCell
                                                                                        style={
                                                                                            val &&
                                                                                            val.trim() ==
                                                                                                'N/A'
                                                                                                ? {
                                                                                                      background:
                                                                                                          '#E9EAEB',
                                                                                                      color: '#005B85',
                                                                                                      fontWeight:
                                                                                                          '400',
                                                                                                  }
                                                                                                : val &&
                                                                                                  val.trim() ==
                                                                                                      'TBD'
                                                                                                ? {
                                                                                                      background:
                                                                                                          '#CBE1EF',
                                                                                                      color: '#005B85',
                                                                                                      fontWeight:
                                                                                                          '400',
                                                                                                  }
                                                                                                : {
                                                                                                      background:
                                                                                                          '#90CEFA',
                                                                                                      color: '#01538C',
                                                                                                      fontWeight:
                                                                                                          '700',
                                                                                                  }
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            val
                                                                                        }
                                                                                    </SKUTableCell>
                                                                                </>
                                                                            )
                                                                        }
                                                                    )}
                                                            </>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </tr>
                                                </>
                                            )
                                        })}
                                </tbody>
                            </Table>
                            <div
                                style={{
                                    marginTop: '8rem',
                                    marginLeft: '2.5rem',
                                }}
                            >
                                <img
                                    src="/legand_temp_image.png"
                                    height="24"
                                    width="271"
                                    alt="PPT frame"
                                />
                            </div>
                        </td>
                    </tr>
                </tbody>
            </Table>
        )
    }

    const hasTechCollat = (): boolean => {
        return (
            workloadTags &&
            ((workloadTags[`on-prem recipes`] &&
                workloadTags[`on-prem recipes`].length > 0) ||
                (workloadTags[`optimized cloud recipes`] &&
                    workloadTags[`optimized cloud recipes`].length > 0) ||
                (workloadTags[`optimized cloud recipes`] &&
                    workloadTags[`optimized cloud recipes`].length > 0) ||
                (workloadTags[`tuning guides`] &&
                    workloadTags[`tuning guides`].length > 0) ||
                (workloadTags[`optimized cloud modules`] &&
                    workloadTags[`optimized cloud modules`].length > 0) ||
                (workloadTags[`proof of concept guide`] &&
                    workloadTags[`proof of concept guide`].length > 0))
        )
    }
    const renderTechnicalCollaterals = (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'center',
            }}
        >
            {workloadTags &&
            workloadTags[`on-prem recipes`] &&
            workloadTags[`on-prem recipes`].length > 0 ? (
                <TechnicalCollateralsDiv>
                    ON-PREM RECIPES
                    {workloadTags[`on-prem recipes`].map((item: string) => {
                        let [key, ...value] = item.split(':')
                        let valueStr = value.join(':')
                        return (
                            <RecipeDataLink
                                href="javascript:void(0)"
                                onClick={(ev: FormEvent<HTMLElement>) =>
                                    handleSalesLinkClick(
                                        valueStr,
                                        benchmarksList &&
                                            benchmarksList.length > 0
                                            ? benchmarksList[0].workloadId
                                            : '',
                                        ev
                                    )
                                }
                            >
                                {key}
                                {LinkImageRender}
                            </RecipeDataLink>
                        )
                    })}
                </TechnicalCollateralsDiv>
            ) : (
                ''
            )}
            {workloadTags &&
            workloadTags[`optimized containers`] &&
            workloadTags[`optimized containers`].length > 0 ? (
                <TechnicalCollateralsDiv>
                    CONTAINERS
                    {workloadTags[`optimized containers`].map(
                        (item: string) => {
                            let [key, ...value] = item.split(':')
                            let valueStr = value.join(':')
                            return (
                                <RecipeDataLink
                                    href="javascript:void(0)"
                                    onClick={(ev: FormEvent<HTMLElement>) =>
                                        handleSalesLinkClick(
                                            valueStr,
                                            benchmarksList &&
                                                benchmarksList.length > 0
                                                ? benchmarksList[0].workloadId
                                                : '',
                                            ev
                                        )
                                    }
                                >
                                    {key}
                                    {LinkImageRender}
                                </RecipeDataLink>
                            )
                        }
                    )}
                </TechnicalCollateralsDiv>
            ) : (
                ''
            )}

            {workloadTags &&
            workloadTags[`optimized cloud recipes`] &&
            workloadTags[`optimized cloud recipes`].length > 0 ? (
                <TechnicalCollateralsDiv>
                    OPTIMIZED CLOUD RECIPES
                    {workloadTags[`optimized cloud recipes`].map(
                        (item: string) => {
                            let [key, ...value] = item.split(':')
                            let valueStr = value.join(':')
                            return (
                                <RecipeDataLink
                                    href="javascript:void(0)"
                                    onClick={(ev: FormEvent<HTMLElement>) =>
                                        handleSalesLinkClick(
                                            valueStr,
                                            benchmarksList &&
                                                benchmarksList.length > 0
                                                ? benchmarksList[0].workloadId
                                                : '',
                                            ev
                                        )
                                    }
                                >
                                    {key}
                                    {LinkImageRender}
                                </RecipeDataLink>
                            )
                        }
                    )}
                </TechnicalCollateralsDiv>
            ) : (
                ''
            )}
            {workloadTags &&
            workloadTags[`tuning guides`] &&
            workloadTags[`tuning guides`].length > 0 ? (
                <TechnicalCollateralsDiv>
                    TUNING GUIDES
                    {workloadTags[`tuning guides`].map((item: string) => {
                        let [key, ...value] = item.split(':')
                        let valueStr = value.join(':')
                        return (
                            <RecipeDataLink
                                href="javascript:void(0)"
                                onClick={(ev: FormEvent<HTMLElement>) =>
                                    handleSalesLinkClick(
                                        valueStr,
                                        benchmarksList &&
                                            benchmarksList.length > 0
                                            ? benchmarksList[0].workloadId
                                            : '',
                                        ev
                                    )
                                }
                            >
                                {key}
                                {LinkImageRender}
                            </RecipeDataLink>
                        )
                    })}
                </TechnicalCollateralsDiv>
            ) : (
                ''
            )}

            {workloadTags &&
            workloadTags[`optimized cloud modules`] &&
            workloadTags[`optimized cloud modules`].length > 0 ? (
                <TechnicalCollateralsDiv>
                    OPTIMIZED CLOUD MODULES
                    {workloadTags[`optimized cloud modules`].map(
                        (item: string) => {
                            let [key, ...value] = item.split(':')
                            let valueStr = value.join(':')
                            return (
                                <RecipeDataLink
                                    href="javascript:void(0)"
                                    onClick={(ev: FormEvent<HTMLElement>) =>
                                        handleSalesLinkClick(
                                            valueStr,
                                            benchmarksList &&
                                                benchmarksList.length > 0
                                                ? benchmarksList[0].workloadId
                                                : '',
                                            ev
                                        )
                                    }
                                >
                                    {key}
                                    {LinkImageRender}
                                </RecipeDataLink>
                            )
                        }
                    )}
                </TechnicalCollateralsDiv>
            ) : (
                ''
            )}
            {workloadTags &&
            workloadTags[`proof of concept guide`] &&
            workloadTags[`proof of concept guide`].length > 0 ? (
                <TechnicalCollateralsDiv>
                    PROOF OF CONCEPT GUIDES
                    {workloadTags[`proof of concept guide`].map(
                        (item: string) => {
                            let [key, ...value] = item.split(':')
                            let valueStr = value.join(':')
                            return (
                                <RecipeDataLink
                                    href="javascript:void(0)"
                                    onClick={(ev: FormEvent<HTMLElement>) =>
                                        handleSalesLinkClick(
                                            valueStr,
                                            benchmarksList &&
                                                benchmarksList.length > 0
                                                ? benchmarksList[0].workloadId
                                                : '',
                                            ev
                                        )
                                    }
                                >
                                    {key}
                                    {LinkImageRender}
                                </RecipeDataLink>
                            )
                        }
                    )}
                </TechnicalCollateralsDiv>
            ) : (
                ''
            )}
        </div>
    )

    const isUIDExist = (diffrentiatorValues: any) => {
        let hasUID = false
        if (diffrentiatorValues && diffrentiatorValues.length > 0) {
            diffrentiatorValues.map((item: any) => {
                if (
                    item?.differentiatorValue &&
                    item?.differentiatorValue['UID'] !== ''
                ) {
                    hasUID = true
                }
            })
        }

        return hasUID
    }

    return (
        <BenchmarkDetailsContainer>
            <SalesHeaderPanel
                onSearchKeyDown={onHeaderSearchKeydown}
                onSearchKeyChange={onHeaderSearchKeyChange}
                onAccessChangeCallback={onAccessApplyChange}
                onApplyFilterCallback={onHeaderFilterClick}
                onClearAllCallback={onHeaderFilterClearAllClick}
            />
            <BenchmarkDetailsWrapper>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    <Breadcrumb style={{ padding: '0.5rem 1rem' }}>
                        <BreadcrumbItem
                            href={StringUtils.resolve('/catalog')}
                            style={{ textDecoration: 'none', color: '#2B2C30' }}
                        >
                            Intel Optimization Hub
                        </BreadcrumbItem>
                        {isDevView && isDevView === 'true' ? (
                            <BreadcrumbItem
                                href={StringUtils.resolve('/benchmarks')}
                                style={{
                                    textDecoration: 'none',
                                    color: '#2B2C30',
                                }}
                            >
                                Benchmarks
                            </BreadcrumbItem>
                        ) : (
                            <BreadcrumbItem
                                href={StringUtils.resolve('/salesview/home')}
                                style={{
                                    textDecoration: 'none',
                                    color: '#2B2C30',
                                }}
                            >
                                Sales View Home
                            </BreadcrumbItem>
                        )}
                        {backNavigation === 'workload' ? (
                            <BreadcrumbItem
                                href={StringUtils.resolve(
                                    `/allWorkloads/${workloadId}`
                                )}
                                style={{
                                    textDecoration: 'none',
                                    color: '#2B2C30',
                                }}
                            >
                                {workloadName} *
                            </BreadcrumbItem>
                        ) : (
                            ''
                        )}
                        <BreadcrumbItem
                            active
                            style={{ fontWeight: '500', color: '#0054AE' }}
                        >
                            {getBenchmarksResult?.name}
                        </BreadcrumbItem>
                    </Breadcrumb>
                </div>

                <BenchmarkResultComponent>
                    <WorkloadLogoComponent>{cardLogo}</WorkloadLogoComponent>
                    <BenchmarkCardColumn
                        style={
                            getBenchmarksResult &&
                            getBenchmarksResult?.name &&
                            getBenchmarksResult?.name.length > 90
                                ? { marginBottom: '' }
                                : { marginBottom: '2.5rem' }
                        }
                    >
                        <BenchmarkTagComponent>
                            {benchmarkGroupTypeName
                                ? renderBenchmarkCardTag(benchmarkGroupTypeName)
                                : ''}
                            {/* {benchmarkTag} */}
                            {benchmarkGroupName
                                ? renderBenchmarkCSPImage(benchmarkGroupName)
                                : ''}
                        </BenchmarkTagComponent>
                        <CardNameComponent>
                            {getBenchmarksResult?.name}
                        </CardNameComponent>
                        <CardDescriptionComponent>
                            {getBenchmarksResult?.description}
                        </CardDescriptionComponent>
                        <CardDescriptionComponent>
                            <div style={{ display: 'inline' }}>
                                Category:{' '}
                                {workloadTags &&
                                workloadTags['workload categories'] &&
                                workloadTags['workload categories'].length >
                                    0 ? (
                                    <span>
                                        {workloadTags[
                                            'workload categories'
                                        ][0] === 'Data Services'
                                            ? dataServiceImg
                                            : ''}

                                        <span
                                            style={{
                                                color: '#0054AE',
                                                paddingTop: '0.2rem',
                                            }}
                                        >
                                            {
                                                workloadTags[
                                                    'workload categories'
                                                ][0]
                                            }
                                        </span>
                                    </span>
                                ) : (
                                    ''
                                )}
                            </div>
                        </CardDescriptionComponent>
                    </BenchmarkCardColumn>
                    <BenchmarkShareColumn>
                        <BenchmarkShareRow>
                            <ShareButton
                                type="button"
                                onClick={(e: any) => onShareLinkClick(e)}
                                value={shareLinkURL}
                                title={'Share with others'}
                            >
                                <img
                                    src="/share_lg.png"
                                    alt="share link"
                                    style={{
                                        height: '24px',
                                        width: '22px',
                                        marginLeft: '1rem',
                                    }}
                                />
                            </ShareButton>
                            {/* <BookmarkButton
                                type="button"
                                onClick={(e: any) => onShareLinkClick(e)}
                                value={shareLinkURL}
                                title={'set following'}
                            >
                                <img
                                    src="/bookmark.png"
                                    alt="bookmark link"
                                    style={{
                                        height: '24',
                                        width: '22',
                                    }}
                                />
                            </BookmarkButton> */}
                        </BenchmarkShareRow>
                        {/* <PriorityComponent>
                            <PriorityLabel>Priority</PriorityLabel>
                        </PriorityComponent> */}
                    </BenchmarkShareColumn>
                </BenchmarkResultComponent>
                <BenchmarkTabComponent>
                    <TabsContainer>
                        <TabsComponent
                            variant="tabs"
                            id="tabs"
                            defaultActiveKey={activeKey}
                            onSelect={(k: any) => setActiveKey(k)}
                            className="nav-link.active"
                            style={{
                                width: '100%',
                                justifyContent: 'center',
                                paddingTop: '0.5rem',
                                borderBottom: '2px solid #c9cace',
                            }}
                        >
                            <StyledTab
                                eventKey="Performance Benefit"
                                title={
                                    <StyledTabLabel tabIndex={0}>
                                        Performance Benefit
                                    </StyledTabLabel>
                                }
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'flex-start',
                                        width: '100%',
                                        background: '#f4f5f5',
                                    }}
                                >
                                    <BenchmarkListingTabContainer>
                                        {getbenchmarksLoading !==
                                        LoadingState.Pending ? (
                                            renderBenchmarkTable(
                                                benchmarksList,
                                                workloadTags
                                            )
                                        ) : (
                                            <SpinnerDiv>
                                                <Spinner
                                                    animation="border"
                                                    variant="dark"
                                                >
                                                    <span className="visually-hidden">
                                                        Loading...
                                                    </span>
                                                </Spinner>
                                            </SpinnerDiv>
                                        )}
                                    </BenchmarkListingTabContainer>
                                </div>
                            </StyledTab>

                            <StyledTab
                                eventKey="Technical Collaterals "
                                title={
                                    <StyledTabLabel tabIndex={0}>
                                        Technical Collateral
                                    </StyledTabLabel>
                                }
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'flex-start',
                                        width: '100%',
                                        background: '#f4f5f5',
                                    }}
                                >
                                    <BenchmarkListingTabContainer>
                                        {hasTechCollat() ? (
                                            renderTechnicalCollaterals
                                        ) : (
                                            <div>{NoResultFoundPanel()}</div>
                                        )}
                                    </BenchmarkListingTabContainer>
                                </div>
                            </StyledTab>

                            {/*
                            // TODO Acwrenn
                            // Always hide resources for now
                            */}
                            {workloadResources &&
                            Object.keys(workloadResources).length > 0 &&
                            false ? (
                                <StyledTab
                                    eventKey="Resources"
                                    title={
                                        <StyledTabLabel tabIndex={0}>
                                            Resources
                                        </StyledTabLabel>
                                    }
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            width: '100%',
                                            background: '#f4f5f5',
                                        }}
                                    >
                                        <ResourcesTabContainer>
                                            <ResourcesInnerDiv>
                                                <ResourcesTextLabel>
                                                    Resources
                                                </ResourcesTextLabel>

                                                <ResourcesLinksContainer>
                                                    {renderLinkComponent}
                                                </ResourcesLinksContainer>
                                            </ResourcesInnerDiv>
                                        </ResourcesTabContainer>
                                    </div>
                                </StyledTab>
                            ) : (
                                ''
                            )}
                            {renderEcoSystemTab()}
                        </TabsComponent>
                    </TabsContainer>
                </BenchmarkTabComponent>
            </BenchmarkDetailsWrapper>
            <div>
                <a
                    className="hidden"
                    download={attachedFileName}
                    href={fileDownloadUrlList[0]}
                    id={'downloadLinkId-' + workloadId}
                    ref={downloadLinkRef}
                ></a>
            </div>
            <Modal
                show={showThumbnailPopup}
                onHide={ClosePPTThumbnailPopup}
                backdrop="static"
                keyboard={true}
                size="xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {downloadCompleted ? multiPPTSlideRender('modal') : ''}
                </Modal.Body>
                <Modal.Footer>
                    <FooterCloseButtonDiv>
                        <Button
                            variant="secondary"
                            onClick={ClosePPTThumbnailPopup}
                            style={{ borderRadius: 0 }}
                        >
                            Close
                        </Button>
                    </FooterCloseButtonDiv>
                </Modal.Footer>
            </Modal>
        </BenchmarkDetailsContainer>
    )
}

export default BenchmarkDetails
