import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { LoadingState } from '../models/loadingState'
import FavoriteService from '../services/favoriteService'

const initialState = {
    data: null,
    loading: LoadingState.Idle,
    error: null,
}

// First, create the thunk
export const getFavorites = createAsyncThunk(
    'favorite/getFavorite',
    async (param: any) => {
        return await FavoriteService.getUsersFavoritesList(param)
    }
)

// Then, handle actions in your reducers:
const getFavoritesSlice = createSlice({
    name: 'getFavorite',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(getFavorites.pending, (state, _) => {
            if (state.loading === LoadingState.Idle) {
                state.loading = LoadingState.Pending
            }
        })
        .addCase(getFavorites.fulfilled, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.data = action.payload.data
                state.error = null
            }
        })
        .addCase(getFavorites.rejected, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.error = action.error
            }
        })
    },
})

export const getFavoritesResult = getFavoritesSlice.reducer
