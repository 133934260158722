import { useState, useCallback, FormEvent, ChangeEvent, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { IActivateAccountProps } from './activateAccountContainer'
import amplify_conf from '../../services/auth/amplify'
import Amplify from '@aws-amplify/core'
import Auth from '@aws-amplify/auth'
Amplify.configure(amplify_conf)
import { KeyboardEvent } from 'react'
import { isEmailFormatValid } from '../../lib/validate'
import { AcceptInvitesRequest } from '../../models/acceptInvitesRequest'
import { CreateOrgRequest } from '../../models/createOrgRequest'

export function ActivateAccountPanelLogic({
    createOrg,
    createOrgLoading,
    createOrgResult,
    createOrgError,
    acceptInvites,
    acceptInvitesError,
    acceptInvitesLoading,
    acceptInvitesResult,
    invitationKey,
}: IActivateAccountProps) {
    const [validated, setValidated] = useState(false)
    const [acountOrgName, setAcountOrgName] = useState<string>('')
    const [accountEmail, setAccountEmail] = useState<string>('')
    const [accountPassword, setAccountPassword] = useState<string>('')
    const [confirmationCode, setConfirmationCode] = useState<string>('')
    const [onError, setOnError] = useState<any>('')
    const [onSuccess, setOnSuccess] = useState<any>('')

    let navigate = useNavigate()

    const onAccountOrgChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLTextAreaElement
            if (target.value) {
                setAcountOrgName(target.value)
            }
        },
        []
    )

    const onAccountEmailChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLTextAreaElement
            if (target.value) {
                setAccountEmail(target.value)
                setOnError('')
            }
        },
        []
    )

    const onAccountPasswordChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLTextAreaElement
            if (target.value) {
                setAccountPassword(target.value)
            }
        },
        []
    )

    async function confirmSignUp(username: string, code: string) {
        try {
            await Auth.confirmSignUp(username, code).then((res) => {
                //silent signIn
                let successMessage: {
                    message: string
                } = {
                    message:
                        "Your account has been activated now! You're being sign in...",
                }
                setOnSuccess(successMessage)
                setTimeout(() => {
                    signIn(accountEmail, accountPassword)
                }, 1000)
            })
        } catch (error) {
            setOnError(error)
        }
    }
    async function resendConfirmationCode(username: string) {
        try {
            await Auth.resendSignUp(username)
            console.log('code resent successfully')
        } catch (err) {
            setOnError(err)
        }
    }
    async function signIn(username: string, password: string) {
        try {
            const user = await Auth.signIn(username, password)
            if (user) {
                let callback_url = localStorage.getItem('original_url')
                if (!callback_url || callback_url === '') {
                    callback_url = '/catalog'
                }
                navigate(callback_url)
                if (invitationKey) {
                    acceptInvites({
                        decision: 'accept',
                        invitationKey: invitationKey,
                        displayName: user?.userFullName,
                    } as AcceptInvitesRequest)
                } else {
                    if (acountOrgName) {
                        var createOrgRequest = new CreateOrgRequest()
                        createOrgRequest.name = acountOrgName
                        createOrgRequest.displayName = user?.userFullName
                            ? user?.userFullName
                            : username
                        createOrgRequest.description = ''
                        createOrg(createOrgRequest)
                    }
                }
            }
        } catch (error) {
            console.log('error signing in', error)
            setOnError(error)
        }
    }
    useEffect(() => {
        if (createOrgError) {
            let error: {
                message: string
            } = { message: 'Error creating org' }

            onError(error)
        }
    }, [createOrgError])

    useEffect(() => {
        if (createOrgResult) {
            if (createOrgResult.id) {
                //nevigate to catalog (home)
                localStorage.setItem('loginRoute', 'catalog')
                navigate('/catalog')
            } else {
                let error: {
                    message: string
                } = { message: 'Error retrieving org id' }

                onError(error)
            }
        }
    }, [createOrgResult])

    useEffect(() => {
        if (acceptInvitesError) {
            let error: {
                message: string
            } = { message: 'Error adding new user to the invited org' }

            onError(error)
        }
    }, [acceptInvitesError])

    useEffect(() => {
        if (acceptInvitesResult) {
            localStorage.setItem('loginRoute', 'catalog')
            navigate('/catalog')
        }
    }, [acceptInvitesResult])

    const onCancel = useCallback((event: FormEvent<HTMLElement>) => {
        event.preventDefault()
        setValidated(false)
        setOnError('')
        window.location.reload()
    }, [])

    const onCodeConfirmChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLTextAreaElement
            if (target.value) {
                setConfirmationCode(target.value)
            }
        },
        []
    )

    const onSubmit = useCallback(
        async (event: FormEvent<HTMLFormElement>) => {
            event.preventDefault()

            const form = event.currentTarget
            if (form.checkValidity() === false) {
                event.preventDefault()
                event.stopPropagation()
            }
            setValidated(true)

            if (
                accountEmail !== '' &&
                isEmailFormatValid(accountEmail) &&
                confirmationCode !== ''
            ) {
                //confirm signup using user entered code
                confirmSignUp(accountEmail, confirmationCode)
            }
        },
        [accountEmail, confirmationCode]
    )

    const onResendConfirmationCode = useCallback(
        (event: FormEvent<HTMLElement>) => {
            event.preventDefault()
            //resend code
            if (
                accountEmail.trim() !== '' &&
                isEmailFormatValid(accountEmail)
            ) {
                resendConfirmationCode(accountEmail)
            } else {
                let error: {
                    message: string
                } = {
                    message:
                        'Please enter email address in format name@xyg.com, to resend code!',
                }
                setOnError(error)
            }
        },
        [accountEmail]
    )

    const onCodeConfirmKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        const key = event.key
        if (
            key.match(/[0-9,c,v]/gi) ||
            key === 'Enter' ||
            key === 'Backspace' ||
            key === 'Delete' ||
            key === 'ArrowLeft' ||
            key === 'ArrowRight' ||
            key === 'Home' ||
            key === 'End'
        ) {
            return true
        } else {
            event.preventDefault()
        }
    }

    return {
        onAccountOrgChange,
        onAccountEmailChange,
        onAccountPasswordChange,
        onCodeConfirmKeyDown,
        onSubmit,
        validated,
        onCancel,
        onError,
        onSuccess,
        onCodeConfirmChange,
        onResendConfirmationCode,
    }
}
