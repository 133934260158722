import { API_SERVICE_URL } from '../config/service'
import { UploadRegistryRequest } from '../models/uploadRegistryRequest'

import api from './auth/client'

const serviceConfig = require('../config/service.json')

class RegistryService {
    uploadRegistry(uploadRegistryRequest: UploadRegistryRequest) {
        const { id, finalize, isEdit, isController } = uploadRegistryRequest
        delete uploadRegistryRequest.id
        delete uploadRegistryRequest.finalize
        var url = isController
            ? API_SERVICE_URL + serviceConfig.getAllUploadRequestURL
            : API_SERVICE_URL + serviceConfig.uploadRegistryURL
        if (id) url += `/${id}`
        if (!id && finalize) {
            uploadRegistryRequest['finalize'] = true
        } else if (!finalize && id) {
            uploadRegistryRequest['finalize'] = false
        }
        if (id && finalize) {
            uploadRegistryRequest['finalize'] = true
        }
        return api.post(url, uploadRegistryRequest)
    }

    getRegistry(payload: any) {
        const { id, isController } = payload
        var url = isController
            ? API_SERVICE_URL + serviceConfig.getAllUploadRequestURL + `/${id}`
            : API_SERVICE_URL + serviceConfig.uploadRegistryURL + `/${id}`

        return api.get(url)
    }

    attachFile(payload: any) {
        const { id, file, isController } = payload
        const formData = new FormData()
        formData.append('id', id)
        formData.append('filename', file.name)
        formData.append('document', file)
        var url = isController
            ? API_SERVICE_URL + serviceConfig.getAllUploadRequestURL
            : API_SERVICE_URL + serviceConfig.uploadRegistryURL
        url = url + `/${id}/attachment/${file.name}`

        return api.post(url, formData.get('document'), {
            headers: {
                'Content-Type': 'application/octet-stream',
            },
        })
    }
    deleteFile(payload: any) {
        const { id, fileName, isController } = payload

        var url = isController
            ? API_SERVICE_URL + serviceConfig.getAllUploadRequestURL
            : API_SERVICE_URL + serviceConfig.deleteOptimizationRequestFile

        url = url + `/${id}/attachment/${fileName}`
        return api.delete(url)
    }
}

export default new RegistryService()
