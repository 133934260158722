import { useCallback } from 'react'
import { ICreateDiscountContainerProps } from './createDiscountContainer'

export function CreateDiscountContainerLogic({
    createDiscountCode,
}: ICreateDiscountContainerProps) {
    const onCreateDiscountSubmit = useCallback(
        async (createDiscountRequest: any) => {
            createDiscountCode(createDiscountRequest)
        },
        []
    )
    return {
        onCreateDiscountSubmit,
    }
}
