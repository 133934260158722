import { FormEvent, FunctionComponent, useRef } from 'react'
import {
    Accordion,
    Button,
    FormLabel,
    Modal,
    OverlayTrigger,
    Table,
    Tooltip,
    Alert,
    Form,
    Col,
    Popover,
    CloseButton,
} from 'react-bootstrap'
import styled from 'styled-components'
import { API_SERVICE_URL } from '../../config/service'
import {
    RecipeData,
    SubscriptionStatus,
} from '../../models/catalogSearchResponse'
import { RecipeCardLogic } from './recipeCard.hook'
const serviceConfig = require('../../config/service.json')
import { DownloadCatalogResponse } from '../../models/downloadCatalogResponse'
import { LoadingState } from '../../models/loadingState'
import { RecipeFeedbackRequest } from '../../models/recipeFeedbackRequest'
import { Recipefeedbackresponse } from '../../models/recipefeedbackresponse'
import SetFavorite from './favorite'
import StringUtils from '../../lib/stringUtils'
import { RecipeAddFavoriteRequest } from '../../models/recipeAddFavoriteRequest'
import { RecipeAddFavoriteResponse } from '../../models/recipeAddFavoriteResponse'
import { RecipeStarRatingRequest } from '../../models/recipeStarRatingRequest'
import { RecipeStarRatingResponse } from '../../models/recipeStarRatingResponse'
import { RecipeAddVotingRequest } from '../../models/recipeAddVotingRequest'
import { RecipeAddVotingResponse } from '../../models/recipeAddVotingResponse'
import { RecipeAddLinkClickRequest } from '../../models/recipeAddLinkClickRequest'
import { useNavigate } from 'react-router-dom'
import { getCartItems, updateCartItems } from '../../store/shoppingCart.slice'
import { ShoppingCartResponse } from '../../models/shoppingCartResponse'
import BenchmarkTable from './benchmarkTable'
import { UserRoles, UserOtherMetaRoles } from '../../models/userRoles'
import { LayoutViewPreference } from '../../models/LayoutViewPreference'
import { _getDecryptedValueFromStorage } from '../../lib/encryptStorage'
import { RecipeAddLikeResponse } from '../../models/recipeAddLikeResponse'
import { SetLike } from './likes'
import { RecipeAddLikeRequest } from '../../models/recipeAddLikeRequest'
import { Icon } from '@iconify/react'
import {
    CloudComputingImage,
    calicoLogo,
    cassandraLogo,
    clickhouseLogo,
    dethStarBenchLogo,
    envoyLogo,
    ffmpegLogo,
    hpcgLogo,
    javaLogo,
    kafkaLogo,
    lammpsLogo,
    lstioLogo,
    mongoDbLogo,
    mssqlLogo,
    mysqlLogo,
    nginxLogo,
    openFomeLogo,
    openJDKLogo,
    openSearchLogo,
    openvinoLogo,
    postgreLogo,
    pytorchLogo,
    rocksdbLogo,
    scikitLearnLogo,
    tenserflowLogo,
    wordpressLogo,
    xboostLogo,
} from './recipeLogo'

const RecipeCardContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0.1rem;
    &:hover {
        transform: scale(1.03);
        transition: 0.5s;
    }
`
const GalleryViewContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0.5rem 0 0.5rem 0;
    padding: 0.5rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background: ${(props) => props.theme.color.background.defaultWhite};
    width: 16rem;
`
const RecipeCardImageHorizonalComponent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`

const RecipeCardDescriptionComponent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 0.5rem;
    padding: 0.5rem;
`
const RecipeLinksComponent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 0.5rem;
    padding: 0.5rem;
    background-color: #eff6ff;
    min-width: 15rem;
`

const RecipeCardText = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    font-family: 'IntelOne Display';
    font-weight: 400;
    font-size: 0.8rem;
    padding-left: 0.9rem;
    ${(props) => props.theme.typography.medium}
    justify-content: left;
    margin-bottom: 1rem;
`
const RecipeCardDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    font-family: 'IntelOne Display';
    font-weight: 400;
    font-size: 0.8rem;
    ${(props) => props.theme.typography.medium}
    margin-bottom: 0.2rem;
`
const RecipeCardLinkText = styled(RecipeCardText)`
    padding-left: 1rem;
`

const DownloadButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: left;
`

const DownloadButton = styled(Button)`
    display: flex;
    justify-content: center;
    font-weight: 400;
    font-size: 1.25rem;
    background-color: #00c7fd;
    border-color: #00c7fd;
    font-family: 'IntelOne Display';
    border-radius: 0px;
    max-width: 10.8rem;
    &:hover {
        background-color: ${(props) =>
            props.theme.color.button.background.hover.primary};
        transition: 0.5s;
    }
    &:disabled {
        background-color: ${(props) =>
            props.theme.color.button.background.disabled.primary};
        border-color: ${(props) =>
            props.theme.color.button.background.disabled.primary};
    }
`
const DownloadSingleFileButton = styled(DownloadButton)`
    display: flex;
    justify-content: center;
    color: none;
    background-color: transparent;
    border: 0px none transparent;
    width: 133px;
    &:hover {
        background-color: transparent;
        border: 0px none transparent;
    }
    &:focus {
        outline: none;
        /*box-shadow: none;*/
        background-color: transparent;
        border: 0px none transparent;
    }
    &:disabled {
        background-color: unset;
    }
`
const ReadMoreContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 1.5rem;
`

const ReadMoreButton = styled(Button)`
    display: flex;
    justify-content: center;
    font-family: 'IntelOne Display';
    font-weight: 400;
    font-size: 1.25rem;
    background: transparent;
    border-color: #0d6efd;
    &:hover {
        background: #0068b5;
        border-color: #0068b5;
        transition: 0.5s;
    }
`

const BenchMarkButton = styled(ReadMoreButton)`
    background-color: transparent;
    border-color: #0d6efd;
    width: 80%;
    color: black;
    border-radius: 0;
`
const PerformanceButton = styled(ReadMoreButton)`
    background-color: transparent;
    border-color: #0d6efd;
    width: 50%;
    color: black;
    border-radius: 0;
`

const RecipeCardComponent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`
const RecipeCardInnerImage = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 0.3rem;
    height: 10rem;
`
const RecipeCardImage = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 0.3rem;
    height: 6.5rem;
`

const ModalPopupContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`
const RecipeTileLabel = styled.span`
    display: flex;
    ${(props) => props.theme.typography.medium}
    text-align: left;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    color: rgb(0, 153, 236); //#808080;
    font-size: 1.15rem;
`
const RecipeTileLinkLabel = styled(RecipeTileLabel)`
    font-style: normal;
`
const RecipeTileValue = styled.span`
    padding-left: 0.5rem;
    overflow-wrap: break-word;
    max-width: 30rem;
`
const AppNameTextLabel = styled.div`
    display: flex;
    text-align: center;
    justify-content: center;
    font-weight: 500;
    max-width: 18rem;
    max-height: 5rem;
    word-break: break-word;
    font-size: 1.28rem;
    font-family: 'IntelOne Display';
`
const TileLinkTextValue = styled.span`
    padding-right: 0.5rem;
    overflow-wrap: break-word;
    word-break: break-word;
    max-width: 30rem;
    padding-left: 0.5rem;
`

const BuyRecipeContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: right;
`

const BuyButton = styled(DownloadButton)`
    background-color: #8bae46;
    border-color: #8bae46;
    width: 93px;
`
const FeedbackImageDiv = styled.div`
    mix-blend-mode: hard-light;
    padding-right: 0.5rem;
`
const AlertComponent = styled(Alert)`
    display: flex;
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: auto;
    justify-content: center;
`
const AttachButton = styled(Button)`
    display: flex;
    justify-content: left;
    vertical-align: middle;
    font-weight: 400;
    font-size: 1rem;
    background-color: #ff8918;
    min-width: 15%;
    height: 2.25rem;
    &:hover {
        background-color: ${(props) =>
            props.theme.color.button.background.hover.primary};
        transition: 0.5s;
    }
`
const RequiredTextLabel = styled.span`
    display: flex;
    ${(props) => props.theme.typography.xLarge}
    color: red;
`
const TextLabel = styled(FormLabel)`
    display: flex;
    ${(props) => props.theme.typography.medium}
    text-align: left;
    padding: 0.5rem;
`
const FeedbackPrioritySelect = styled(Form.Select)`
    width: 20%;
    height: 20%;
    padding: 0.5rem;
    margin-right: 0.5rem;
    margin-left: 1.1rem;
`
const PriorityContainer = styled.div`
    display: flex;
    flex-grow:1
    justify-content: left;
    vertical-align: middle;
`
const ShareButtonDiv = styled.div`
    display: flex;
    justify-content: right;
`
const DownloadLink = styled(Button)`
    display: flex;
    justify-content: center;
    background: none !important;
    border: unset;
    padding: 0 !important;
    color: #0d6efd;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
        color: #0d6efd;
        background-color: #0d6efd;
    }
    &:click {
        border: unset;
        content: unset !important;
        color: #0d6efd;
    }
`
const RecipeCardColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`
const PopoverKeyDiv = styled.div`
    background-color: #cff4fc;
    font-size: 1rem;
    padding: 0.5rem;
`

const FooterCloseButtonDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: right;
`

const ModalHeaderButton = styled(Button)`
    background-color: transparent;
    cursor: pointer;
    color: black;
    margin-left: 1rem;
    border-radius: 0;
    &:hover {
        background-color: transparent;
        color: #000;
    }
`
const ExpandadCardHeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`
const PremiumLabel = styled(FormLabel)`
    width: 5rem;
    height: auto;
    font-family: 'IntelOne Display';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    color: #ffffff;
    transform: rotate(-44.87deg);
    padding-left: 1.1rem;
    text-align: center;
`
const DownloadLicenseButton = styled(Button)`
    justify-content: center;
    font-weight: 400;
    font-size: 1.25rem;
    font-family: 'IntelOne Display';
    border-radius: 0px;
    background-color: #00c7fd;
    border-color: #00c7fd;
    max-width: 15rem;
    margin-left: 50% !important;
`
const ClicksLabel = styled.span`
    padding-left: 0.5rem;
    color: orange;
    font-size: 0.7rem;
`
const ViewsIcon = styled(Icon)`
    width: 15px;
    height: 15px;
    margin-right: 0.25rem;
    color: orange;
`
const LicenseConfirmationLabel = styled(FormLabel)`
    display: flex;
    ${(props) => props.theme.typography.medium}
    text-align: left;
    justify-content: left;
    white-space: pre-wrap;
    margin-left: 1rem;
    padding-top: 1rem;
`

export interface IRecipeCardProps {
    recipeData?: RecipeData
    downloadCatalog: (downloadUrl: string) => void
    downloadCatalogResult: DownloadCatalogResponse
    downloadCatalogLoading: LoadingState
    downloadCatalogError?: any
    downloadLinkRef?: any
    feedbackFileInputRef?: any
    submitFeedback: (recipeFeedbackRequest: RecipeFeedbackRequest) => void
    feedbackResult: Recipefeedbackresponse
    feedbackError: any
    feedbackLoading: LoadingState
    saveFavorite: (addFavoriteRequest: RecipeAddFavoriteRequest) => void
    favoriteResult?: RecipeAddFavoriteResponse
    favoriteError?: any
    favoriteLoading: LoadingState
    saveStarRating: (saveRatingRequest: RecipeStarRatingRequest) => void
    saveRatingResult: RecipeStarRatingResponse
    saveRatingError?: any
    saveVoting: (saveVotingRequest: RecipeAddVotingRequest) => void
    saveVotingResult: RecipeAddVotingResponse
    saveVotingError?: any
    getRecipeById: (id: any) => void
    saveLinkClick: (addLinkClickRequest: RecipeAddLinkClickRequest) => void
    updateCartItems: typeof updateCartItems
    updateCartResult: ShoppingCartResponse
    getCartItems: typeof getCartItems
    getCartItemsResult: ShoppingCartResponse
    getCartItemsResultLoading: LoadingState
    getCartItemsResultError: any
    getBenchmarks: any
    benchmarkData: any
    resultLayoutView: any
    isIntelEmployee: boolean
    isPublicSearch?: boolean
    saveLike: (addLikeRequest: RecipeAddLikeRequest) => void
    likeResult: RecipeAddLikeResponse
    likeError?: any
    likeLoading: LoadingState
    organizationalRoles?: string[]
    getClickCounts: (filters: any) => void
    clickCounts: any
}

const RecipeCard: FunctionComponent<IRecipeCardProps> = ({
    recipeData,
    downloadCatalogResult,
    downloadCatalog,
    downloadCatalogLoading,
    downloadCatalogError,
    submitFeedback,
    feedbackResult,
    feedbackLoading,
    feedbackError,
    saveFavorite,
    favoriteResult,
    favoriteError,
    favoriteLoading,
    saveStarRating,
    saveRatingResult,
    saveRatingError,
    getRecipeById,
    saveLinkClick,
    updateCartItems,
    updateCartResult,
    getCartItems,
    getCartItemsResult,
    getCartItemsResultLoading,
    getCartItemsResultError,
    getBenchmarks,
    benchmarkData,
    resultLayoutView,
    isIntelEmployee,
    isPublicSearch,
    saveLike,
    likeResult,
    likeError,
    likeLoading,
    getClickCounts,
    clickCounts,
}: IRecipeCardProps) => {
    const downloadLinkRef = useRef(null)
    const feedbackFileInputRef = useRef(null)
    //get user org details
    const orgRoles = _getDecryptedValueFromStorage('organizationalRoles')
    let organizationalRoles: string[] = []
    if (orgRoles) {
        organizationalRoles = orgRoles.split(',')
    }
    const {
        onDownloadClick,
        onShowBenchmarkClick,
        showBenchMark,
        fileDownloadUrl,
        downloadFileName,
        onFeedbackButtonClick,
        showFeedbackPopup,
        handleFeedbackClose,
        onFeedbackAttachClick,
        onFeedbackFileChange,
        onFeedbackTextChange,
        feedbackSelectedFileName,
        validated,
        onFeedbackFormSubmit,
        isFeedbackLoad,
        onPrioritySelectChange,
        onFeedbackDateChange,
        isFavorite,
        onFavoriteClick,
        favoriteCount,
        starRating,
        onStarRatingClick,
        onShareLinkClick,
        handleRecipeLinksClick,
        onBuyButtonClick,
        onGalleryReadMoreClick,
        showGalleryReadme,
        CloseGalleryReadme,
        isLiked,
        onLikeClick,
        onDownloadLicenseClick,
        showLicensePopup,
        handleLicensePopupClose,
        handleAcceptLicenseClick,
        handleRejectLicenseClick,
        showLicenseConfirmation,
        handleCloseConfirmation,
        onLicenseConfirmationClick,
        saveLicenseAcceptanceResult,
        feedbackAttachResult,
        feedbackAttachError,
        onSubscriptionClick,
        addOptSubscriptionResult,
        deleteOptSubscriptionResult,
        showOptSubscriptionConfirmation,
        handleOptSubsCloseConfirmation,
        isOptSubsCribed,
    } = RecipeCardLogic({
        recipeData,
        downloadCatalog,
        downloadCatalogResult,
        downloadCatalogLoading,
        downloadLinkRef,
        downloadCatalogError,
        feedbackFileInputRef,
        submitFeedback,
        saveFavorite,
        favoriteResult,
        favoriteError,
        favoriteLoading,
        saveStarRating,
        getRecipeById,
        saveLinkClick,
        updateCartItems,
        updateCartResult,
        getCartItems,
        getBenchmarks,
        feedbackResult,
        feedbackLoading,
        feedbackError,
        isPublicSearch,
        saveLike,
        likeResult,
        likeError,
        likeLoading,
        organizationalRoles,
        getClickCounts,
    } as IRecipeCardProps)

    let navigate = useNavigate()
    let otherMetaPermissions: string[] = []
    const otherPermissions = _getDecryptedValueFromStorage(
        'otherMetaPermissions'
    )
    if (otherPermissions) {
        otherMetaPermissions = otherPermissions.split(',')
    }
    const isPerformanceUploader = _getDecryptedValueFromStorage(
        'isPerformanceUploader'
    )

    let isAnalyst = otherMetaPermissions.includes(UserOtherMetaRoles.Analyst)

    const recipeUrl = API_SERVICE_URL + serviceConfig.getRecipeURL
    const accessGroup = recipeData?.accessGroup ?? ''
    const appName = recipeData?.appName ?? 'Recipe'
    const recipeName =
        appName.slice(0, 1).toUpperCase() + appName.slice(1, appName.length)
    const licenseText = recipeData?.usageTerms ?? ''
    const hasAcceptedUsageTerms = recipeData?.hasAcceptedUsageTerms ?? false

    let fileName = ''
    let isFileExist = false
    let fileCount = 0
    let isAPaidDownload = false
    let isSubscribed = false

    if (
        recipeData !== null &&
        recipeData?.attachments !== undefined &&
        recipeData?.attachments.length > 0
    ) {
        isFileExist = true
        fileName = recipeData.attachments[0].filename
        fileCount = recipeData?.attachments.length
        if (fileCount === 1) isAPaidDownload = !recipeData.attachments[0].isFree
        if (recipeData.subscriptionStatus === SubscriptionStatus.Subscribed)
            isSubscribed = true
    }
    const downloadFileLink = recipeData?.uuid
        ? recipeUrl + '/' + recipeData?.uuid
        : ''

    let downloadAllLink = downloadFileLink + '/document/' + fileName

    let shareLinkURL = `${window.location.protocol}//${window.location.host}${window.location.pathname}?uuid=${recipeData?.uuid}`

    const defaultTagValue = 'NA'

    let platform = defaultTagValue
    let feature = defaultTagValue
    let value_proposition = defaultTagValue
    let performance_value = defaultTagValue
    let performance_details = defaultTagValue
    let pipelineStage = defaultTagValue
    let name_solution_optimization_coll = defaultTagValue
    let opt_owner = defaultTagValue
    let sw_configuration: any[] = []
    let hw_configuration: any[] = []
    let bios_configuration: any[] = []
    let usage_guidance = defaultTagValue

    const tagList = recipeData?.tags
    let linksArray: { tagName: string; values: any }[] = []

    if (tagList != undefined) {
        Object.keys(tagList).map((tags) => {
            let tagValuesList = tagList[tags]
            let tagValues: any
            if (tagValuesList) {
                tagValuesList.map((tag) => {
                    tagValues = tagValues ? tagValues + ', ' + tag : tag
                })
            }
            tags = tags.trim()
            if (tags === 'Platform' && tagValues) platform = tagValues
            else if (
                tags === 'Name Solution / Optimization / Collateral' &&
                tagValues
            )
                name_solution_optimization_coll = tagValues
            else if (tags === 'Pipeline Stage' && tagValues)
                pipelineStage = tagValues
            else if (tags === 'Feature' && tagValues) feature = tagValues
            else if (
                tags === 'Perf Value Prop' ||
                (tags === 'intel_value_prop' && tagValues)
            )
                value_proposition = tagValues
            else if (tags === 'Performance Value' && tagValues)
                performance_value = tagValues
            else if (tags === 'Performance Details' && tagValues)
                performance_details = tagValues
            else if (tags.endsWith('URL')) {
                if (isIntelEmployee)
                    linksArray.push({ tagName: tags, values: tagValues })
                else if (tags !== 'Jira URL')
                    linksArray.push({ tagName: tags, values: tagValues })
            } else if (tags === 'Optimization Owner') opt_owner = tagValues
            else if (tags === 'SW Configuration')
                sw_configuration = tagValuesList
            else if (tags === 'HW Configuration')
                hw_configuration = tagValuesList
            else if (tags === 'BIOS Configuration')
                bios_configuration = tagValuesList
            else if (tags === 'Usage guidance') usage_guidance = tagValues
        })
    }
    let isProductImageExist: boolean = false
    if (
        recipeName.toUpperCase().indexOf('NGINX') > -1 ||
        recipeName.indexOf('TensorFlow') > -1 ||
        recipeName.indexOf('RocksDB') > -1
    ) {
        isProductImageExist = true
    }

    const PremiumBanner = (
        <div>
            {recipeData?.isPremium && hasAcceptedUsageTerms ? (
                <img
                    src={StringUtils.resolve('/premium_green.svg')}
                    height="104px"
                    width="135px"
                    alt="premium logo"
                    style={{
                        marginLeft: '-5.9rem',
                        marginTop: '-0.5rem',
                    }}
                />
            ) : recipeData?.isPremium ? (
                <img
                    src={StringUtils.resolve('/premium.svg')}
                    height="104px"
                    width="135px"
                    alt="premium logo"
                    style={{
                        marginLeft: '-5.8rem',
                        marginTop: '-0.5rem',
                    }}
                />
            ) : (
                ''
            )}
            <PremiumLabel
                style={{
                    marginLeft: '-7.8rem',
                }}
            >
                Premium
            </PremiumLabel>
        </div>
    )
    const productBasedImage = (
        <RecipeCardInnerImage>
            {recipeName.toUpperCase().indexOf('NGINX') > -1
                ? nginxLogo
                : recipeName.indexOf('TensorFlow') > -1
                ? tenserflowLogo
                : recipeName.indexOf('RocksDB') > -1
                ? rocksdbLogo
                : recipeName.indexOf('Cassandra') > -1
                ? cassandraLogo
                : recipeName.indexOf('Wordpress') > -1
                ? wordpressLogo
                : recipeName.indexOf('MySQL') > -1
                ? mysqlLogo
                : recipeName.toUpperCase().indexOf('PYTORCH') > -1
                ? pytorchLogo
                : recipeName.toUpperCase().indexOf('CLICKHOUSE') > -1
                ? clickhouseLogo
                : recipeName.toUpperCase().indexOf('OPENFOAM') > -1
                ? openFomeLogo
                : recipeName.toUpperCase().indexOf('LAMMPS') > -1
                ? lammpsLogo
                : recipeName.toUpperCase().indexOf('SCIKIT') > -1
                ? scikitLearnLogo
                : recipeName.toUpperCase().indexOf('DEATHSTARBENCH') > -1
                ? dethStarBenchLogo
                : recipeName.toUpperCase().indexOf('KAFKA') > -1
                ? kafkaLogo
                : recipeName.toUpperCase().indexOf('MONGODB') > -1
                ? mongoDbLogo
                : recipeName.toUpperCase().indexOf('MSSQL') > -1 ||
                  recipeName.toUpperCase().indexOf('MS SQL') > -1 ||
                  recipeName.toUpperCase().indexOf('MICROSOFT SQL') > -1
                ? mssqlLogo
                : recipeName.toUpperCase().indexOf('JAVA') > -1
                ? javaLogo
                : recipeName.toUpperCase().indexOf('OPENSEARCH') > -1
                ? openSearchLogo
                : recipeName.toUpperCase().indexOf('OPENJDK') > -1
                ? openJDKLogo
                : recipeName.toUpperCase().indexOf('HPCG') > -1
                ? hpcgLogo
                : recipeName.toUpperCase().indexOf('POSTGRE') > -1
                ? postgreLogo
                : recipeName.toUpperCase().indexOf('FFMPEG') > -1
                ? ffmpegLogo
                : recipeName.toUpperCase().indexOf('XGBOOST') > -1
                ? xboostLogo
                : recipeName.toUpperCase().indexOf('ENVOY') > -1
                ? envoyLogo
                : recipeName.toUpperCase().indexOf('OPENVINO') > -1
                ? openvinoLogo
                : recipeName.toUpperCase().indexOf('ISTIO') > -1
                ? lstioLogo
                : recipeName.toUpperCase().indexOf('CALICO') > -1
                ? calicoLogo
                : CloudComputingImage}
        </RecipeCardInnerImage>
    )

    const ExternalCardPictureComponent = (showPremiumBanner: boolean) => {
        let style: any = isProductImageExist ? { marginLeft: '-1rem' } : {}
        style['width'] = '100px'
        style['height'] = '100px'
        return (
            <div style={{ display: 'flex' }}>
                <div>
                    {recipeData?.isPremium && showPremiumBanner
                        ? PremiumBanner
                        : ''}
                </div>
                <RecipeCardImage
                    style={
                        recipeData?.isPremium
                            ? {
                                  MozMarginStart: '-2.38rem',
                              }
                            : {}
                    }
                >
                    {productBasedImage}
                </RecipeCardImage>
            </div>
        )
    }

    let multiAttachmentDownloadComponent
    if (fileCount >= 1) {
        downloadAllLink = downloadFileLink + '/bulk'
        fileName = appName + '_bulk.zip'

        multiAttachmentDownloadComponent = recipeData?.attachments.map(
            (obj, index) => {
                const downLoadLink =
                    downloadFileLink + '/document/' + obj.filename
                if (!obj.isFree) {
                    isAPaidDownload = true
                }

                return (
                    <>
                        <tr>
                            <td
                                style={
                                    index + 1 === recipeData?.attachments.length
                                        ? {
                                              borderStyle: 'none',
                                              width: '60%',
                                              maxWidth: '60%',
                                              verticalAlign: 'middle',
                                          }
                                        : {
                                              width: '60%',
                                              maxWidth: '60%',
                                              verticalAlign: 'middle',
                                          }
                                }
                            >
                                {index + 1 + '.' + obj.filename}
                            </td>
                            <td
                                style={
                                    index + 1 === recipeData?.attachments.length
                                        ? { borderStyle: 'none' }
                                        : {}
                                }
                            >
                                <OverlayTrigger
                                    placement={'top'}
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={
                                        <Tooltip>
                                            {'Download: ' + obj.filename}
                                        </Tooltip>
                                    }
                                >
                                    <DownloadSingleFileButton
                                        onClick={(
                                            url: string,
                                            name: string,
                                            ev: FormEvent<HTMLElement>
                                        ) => {
                                            onDownloadClick(
                                                downLoadLink,
                                                obj.filename,
                                                licenseText,
                                                hasAcceptedUsageTerms,
                                                ev
                                            )
                                        }}
                                        type="submit"
                                        disabled={!obj.isFree && !isSubscribed}
                                    >
                                        {obj.isFree || isSubscribed ? (
                                            <img
                                                src={StringUtils.resolve(
                                                    '/Download-Icon.png'
                                                )}
                                                height="40px"
                                                width="40px"
                                                alt="download logo"
                                            />
                                        ) : (
                                            <img
                                                src={StringUtils.resolve(
                                                    '/fileLock.png'
                                                )}
                                                height="40px"
                                                width="40px"
                                                alt="download lock logo"
                                            />
                                        )}
                                    </DownloadSingleFileButton>
                                </OverlayTrigger>
                            </td>
                        </tr>
                    </>
                )
            }
        )
    }

    const LinksImageRender = (
        <span
            style={{
                paddingLeft: '0.2rem',
            }}
        >
            <img
                src={StringUtils.resolve('/links.png')}
                height="20px"
                width="20px"
                alt="link logo"
                style={{
                    paddingBottom: '0.1rem',
                }}
            />
        </span>
    )

    const isRecipeCheckedOut = getCartItemsResult?.cartitems.some(
        (item) => item.optimizationId == recipeData?.uuid
    )

    const showBuyButton = false //hide buy button
    // recipeData?.subscriptionStatus == SubscriptionStatus.NotSubscribed ||
    // isRecipeCheckedOut

    const buyButton = (
        <BuyRecipeContainer
            style={
                resultLayoutView === LayoutViewPreference.ListView
                    ? { justifyContent: 'right' }
                    : { justifyContent: 'center' }
            }
        >
            <BuyButton
                onClick={(e: any) => onBuyButtonClick(e, recipeData?.uuid)}
                variant="primary"
                type="submit"
                disabled={isRecipeCheckedOut}
                data-testid={`buy-button-${recipeData?.uuid}`}
                style={{ borderRadius: 0 }}
            >
                {isRecipeCheckedOut ? 'In-Cart' : 'Buy'}
            </BuyButton>
        </BuyRecipeContainer>
    )

    const downloadButton = (showdownloadAll?: boolean) => {
        return (
            <DownloadButton
                data-testid={`download-button-${recipeData?.uuid}`}
                onClick={(
                    url: string,
                    name: string,
                    ev: FormEvent<HTMLElement>
                ) => {
                    onDownloadClick(
                        downloadAllLink,
                        fileName,
                        licenseText,
                        hasAcceptedUsageTerms,
                        ev
                    )
                }}
                variant="primary"
                type="submit"
            >
                {showdownloadAll ? 'Download All' : 'Download'}
            </DownloadButton>
        )
    }

    const buyButtonOnCard = showBuyButton ? buyButton : <div />

    const buildLinkComponent = (
        name: string,
        value: string,
        index: number,
        isMultiLink: boolean,
        clicks: any
    ) => {
        return (
            <RecipeCardLinkText style={{ marginLeft: '1rem' }}>
                <TileLinkTextValue>
                    {value.startsWith('https://') ||
                    value.startsWith('www.') ? (
                        <a
                            href={
                                isPublicSearch
                                    ? '/signIn'
                                    : value.startsWith('www.')
                                    ? `https://${value}`
                                    : value
                            }
                            target={!isPublicSearch ? '_blank' : '_self'}
                            rel="noopener"
                            onClick={(e) =>
                                handleRecipeLinksClick(
                                    value.startsWith('www.')
                                        ? `https://${value}`
                                        : value,
                                    e
                                )
                            }
                            style={{
                                color: '#0099EC',
                            }}
                        >
                            {isMultiLink
                                ? index + 1
                                : name.substring(0, name.length - 3)}
                            {LinksImageRender}
                        </a>
                    ) : value.startsWith('attachment://') ? (
                        <DownloadLink
                            onClick={(
                                url: string,
                                name: string,
                                ev: FormEvent<HTMLElement>
                            ) => {
                                onDownloadClick(
                                    `${downloadFileLink}/document/${value.substring(
                                        13,
                                        value.length
                                    )}`,
                                    value.substring(13, value.length),
                                    licenseText,
                                    hasAcceptedUsageTerms,
                                    ev
                                )
                            }}
                        >
                            {isMultiLink
                                ? name.substring(0, name.length - 3) +
                                  (index + 1)
                                : name.substring(0, name.length - 3)}
                            {LinksImageRender}
                        </DownloadLink>
                    ) : (
                        ''
                    )}

                    <>
                        {isAnalyst ? (
                            <ClicksLabel>
                                <ViewsIcon icon="la:eye" />
                                {clicks && clicks.result && clicks.result[value]
                                    ? clicks.result[value] + ' views'
                                    : 0 + ' views'}
                            </ClicksLabel>
                        ) : (
                            ''
                        )}
                    </>
                </TileLinkTextValue>
            </RecipeCardLinkText>
        )
    }

    let isValidLinks = false
    const renderLinkComponent = linksArray.map((link) => {
        let linkValues = link.values ? link.values.split(',') : link.values
        let isMultiLink: boolean =
            linkValues && linkValues.length > 1 ? true : false
        let isJiraViewer = otherMetaPermissions.includes(
            UserOtherMetaRoles.JiraViewer
        )
        return (
            <div>
                {linkValues
                    ? linkValues.map((value: string, index: number) => {
                          if (
                              (value && value.trim().startsWith('https://')) ||
                              value.trim().startsWith('www.')
                          ) {
                              if (
                                  link.tagName.indexOf('Jira') > -1 &&
                                  !isJiraViewer
                              ) {
                                  return
                              }
                              isValidLinks = true

                              return (
                                  <>
                                      {index === 0 && isMultiLink ? (
                                          <RecipeTileLinkLabel
                                              style={{
                                                  marginLeft: '1rem',
                                                  color: '#0099EC',
                                                  fontSize: '1rem',
                                              }}
                                          >
                                              {link.tagName.substring(
                                                  0,
                                                  link.tagName.length - 3
                                              )}
                                          </RecipeTileLinkLabel>
                                      ) : (
                                          ''
                                      )}
                                      {buildLinkComponent(
                                          link.tagName,
                                          value.trim(),
                                          index,
                                          isMultiLink,
                                          clickCounts
                                      )}
                                  </>
                              )
                          }
                      })
                    : ''}
            </div>
        )
    })

    const renderLinkWithInText = (text: string) => {
        if (
            text &&
            (text.indexOf('https://') > -1 || text.indexOf('http://') > -1)
        ) {
            let subText = ''
            let linkValue = ''
            let startIndex = 0
            let endIndex = 0
            if (text.indexOf('https://') > -1) {
                subText = text.substring(text.indexOf('https://'), text.length)
                startIndex = text.indexOf('https://')
                if (subText.indexOf(' ') > -1) {
                    linkValue = subText.substring(0, subText.indexOf(' '))
                    endIndex = subText.indexOf(' ')
                } else {
                    linkValue = subText
                    endIndex = text.length
                }
            }
            if (text.indexOf('http://') > -1) {
                subText = text.substring(text.indexOf('http://'), text.length)
                startIndex = text.indexOf('http://')
                if (subText.indexOf(' ') > -1) {
                    linkValue = subText.substring(0, subText.indexOf(' '))
                    endIndex = subText.indexOf(' ')
                } else {
                    linkValue = subText
                    endIndex = text.length
                }
            }
            subText = subText.substring(endIndex, subText.length)
            return (
                <span>
                    {text.substring(0, startIndex) + ' '}
                    <a
                        href={isPublicSearch ? '/signIn' : linkValue}
                        target="_blank"
                        rel="noopener"
                        style={{ color: 'rgb(0,153,236)' }}
                    >
                        {linkValue}
                    </a>

                    <span>
                        {subText.indexOf('https://') > -1 ||
                        subText.indexOf('http://') > -1
                            ? renderLinkWithInText(subText)
                            : ' ' + subText}
                    </span>
                </span>
            )
        } else return text
    }
    const renderNewLine = (text: string) => {
        if (text && text.indexOf('\n') > -1) {
            let lines = text.split('\n')
            return (
                <div>
                    {lines.map((textLine: string) => {
                        return (
                            <div>
                                {renderLinkWithInText(textLine)}
                                <br />
                            </div>
                        )
                    })}
                </div>
            )
        } else return renderLinkWithInText(text)
    }

    const renderTable = (arr: any, keyLabel: string, valueLabel: string) => {
        return (
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>{keyLabel}</th>
                        <th>{valueLabel}</th>
                    </tr>
                </thead>
                <tbody>
                    {arr.map((pair: string) => {
                        let [key, ...value] = pair.split(':')
                        let valueStr = value.join(':')
                        return (
                            <tr>
                                <td>{key}</td>
                                <td>
                                    <div style={{ maxWidth: '20rem' }}>
                                        {renderNewLine(valueStr)}
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        )
    }

    const recipeCardColumnPanel1 = (
        <RecipeCardDescriptionComponent>
            <RecipeCardText>
                <RecipeTileLabel
                    data-testid={`platform-label-${recipeData?.uuid}`}
                >
                    {'Platform: '}
                </RecipeTileLabel>
                <RecipeTileValue
                    data-testid={`platform-value-${recipeData?.uuid}`}
                >
                    {platform}
                </RecipeTileValue>
            </RecipeCardText>
            <RecipeCardText>
                <RecipeTileLabel
                    data-testid={`feature-label-${recipeData?.uuid}`}
                >
                    {'Feature: '}
                </RecipeTileLabel>
                <RecipeTileValue
                    data-testid={`feature-value-${recipeData?.uuid}`}
                >
                    {feature}
                </RecipeTileValue>
            </RecipeCardText>

            <RecipeCardText>
                <RecipeTileLabel
                    data-testid={`valueProp-label-${recipeData?.uuid}`}
                >
                    {'Value Proposition: '}
                </RecipeTileLabel>
                <RecipeTileValue
                    data-testid={`valueProp-value-${recipeData?.uuid}`}
                >
                    {renderNewLine(value_proposition)}
                </RecipeTileValue>
            </RecipeCardText>
            <RecipeCardText>
                <RecipeTileLabel
                    data-testid={`usage-guidance-label-${recipeData?.uuid}`}
                >
                    {'How To Apply: '}
                </RecipeTileLabel>
                <RecipeTileValue
                    data-testid={`usage-guidance-value-${recipeData?.uuid}`}
                >
                    {renderNewLine(usage_guidance)}
                </RecipeTileValue>
            </RecipeCardText>
            {performance_details && performance_details !== 'NA' ? (
                <RecipeCardText>
                    <RecipeTileLabel
                        data-testid={`performance-details-label-${recipeData?.uuid}`}
                    >
                        {'Performance Details: '}
                    </RecipeTileLabel>
                    <RecipeTileValue
                        data-testid={`performance-details-value-${recipeData?.uuid}`}
                    >
                        {renderNewLine(performance_details)}
                    </RecipeTileValue>
                </RecipeCardText>
            ) : (
                ''
            )}
            {isIntelEmployee ? (
                <RecipeCardText>
                    <RecipeTileLabel
                        data-testid={`optimization-owner-label-${recipeData?.uuid}`}
                    >
                        {'Optimization Owner: '}
                    </RecipeTileLabel>
                    <RecipeTileValue
                        data-testid={`optimization-owner-value-${recipeData?.uuid}`}
                    >
                        {opt_owner}
                    </RecipeTileValue>
                </RecipeCardText>
            ) : (
                ''
            )}
        </RecipeCardDescriptionComponent>
    )
    const recipeCardColumnPanel2 = (
        <RecipeCardDescriptionComponent>
            {/* Disable Download license button */}
            {/* {(recipeData?.licensePlugin === 'standard' ||
                recipeData?.licensePlugin === 'ivtal') && (
                <DownloadLicenseButton
                    onClick={() => onDownloadLicenseClick(recipeData.uuid)}
                >
                    Download License
                </DownloadLicenseButton>
            )} */}
            {linksArray && linksArray.length > 0 && isValidLinks ? (
                <RecipeLinksComponent>
                    <RecipeTileLinkLabel
                        data-testid={`Links-label-${recipeData?.uuid}`}
                        style={{ fontSize: '1.1rem' }}
                    >
                        Links:
                    </RecipeTileLinkLabel>
                    {renderLinkComponent}
                </RecipeLinksComponent>
            ) : (
                ''
            )}
        </RecipeCardDescriptionComponent>
    )
    const benchmarkPanel = (
        <td colSpan={5}>
            {recipeData && recipeData['uuid'] in benchmarkData ? (
                <BenchmarkTable
                    benchmarks={benchmarkData[recipeData['uuid']]}
                ></BenchmarkTable>
            ) : (
                <p>No Benchmark data available for this optimization</p>
            )}
        </td>
    )
    const downloadPanelComponent = (
        <RecipeCardDescriptionComponent>
            <DownloadButtonContainer>
                {isFileExist ? (
                    <div style={{ width: '100%' }}>
                        {fileCount > 0 ? (
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                        <span>Attachments</span>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <Table>
                                            <tbody>
                                                {
                                                    multiAttachmentDownloadComponent
                                                }
                                            </tbody>
                                        </Table>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        ) : (
                            ''
                        )}
                    </div>
                ) : (
                    <div></div>
                )}
            </DownloadButtonContainer>
        </RecipeCardDescriptionComponent>
    )

    const galleryViewPanel = (
        <RecipeCardColumn>
            <RecipeCardImageHorizonalComponent>
                {ExternalCardPictureComponent(true)}
            </RecipeCardImageHorizonalComponent>

            <RecipeCardComponent
                style={{
                    justifyContent: 'flex-start',
                    maxHeight: '16.5rem',
                    height: '16.5rem',
                }}
            >
                <RecipeCardDescriptionComponent>
                    <RecipeCardDiv>
                        {recipeName && recipeName.length > 50 ? (
                            <OverlayTrigger
                                placement={'top'}
                                delay={{ show: 250, hide: 400 }}
                                overlay={
                                    <Popover
                                        id="recipe_name"
                                        style={{ maxWidth: '20%' }}
                                    >
                                        <Popover.Body>
                                            <PopoverKeyDiv>
                                                {recipeName}
                                            </PopoverKeyDiv>
                                        </Popover.Body>
                                    </Popover>
                                }
                            >
                                <AppNameTextLabel
                                    data-testid={`app-name-label-${recipeData?.uuid}`}
                                >
                                    {recipeName.slice(0, 50) + '...'}
                                </AppNameTextLabel>
                            </OverlayTrigger>
                        ) : (
                            <AppNameTextLabel
                                data-testid={`app-name-label-${recipeData?.uuid}`}
                            >
                                {recipeName}
                            </AppNameTextLabel>
                        )}
                    </RecipeCardDiv>
                </RecipeCardDescriptionComponent>

                <RecipeCardDiv>
                    {name_solution_optimization_coll &&
                    name_solution_optimization_coll.length > 110 ? (
                        <OverlayTrigger
                            placement={'top'}
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                                <Popover
                                    id="recipe_details"
                                    style={{ maxWidth: '20%' }}
                                >
                                    <Popover.Body>
                                        <PopoverKeyDiv>
                                            {name_solution_optimization_coll}
                                        </PopoverKeyDiv>
                                    </Popover.Body>
                                </Popover>
                            }
                        >
                            <RecipeTileValue
                                data-testid={`summary-value-${recipeData?.uuid}`}
                                style={{
                                    fontSize: '1.15rem',
                                    padding: '0.5rem',
                                    maxWidth: '15rem',
                                    textAlign: 'center',
                                }}
                            >
                                {name_solution_optimization_coll &&
                                name_solution_optimization_coll.length > 110
                                    ? name_solution_optimization_coll.slice(
                                          0,
                                          110
                                      ) + '...'
                                    : name_solution_optimization_coll}
                            </RecipeTileValue>
                        </OverlayTrigger>
                    ) : (
                        <RecipeTileValue
                            data-testid={`summary-value-${recipeData?.uuid}`}
                            style={{
                                fontSize: '1.15rem',
                                padding: '0.5rem',
                                maxWidth: '15rem',
                                textAlign: 'center',
                            }}
                        >
                            {name_solution_optimization_coll}
                        </RecipeTileValue>
                    )}
                </RecipeCardDiv>
            </RecipeCardComponent>
        </RecipeCardColumn>
    )

    const expandedCardHeader = (
        <ExpandadCardHeaderContainer>
            <div style={{ display: 'flex', paddingLeft: '3rem' }}>
                {ExternalCardPictureComponent(false)}
                <div>
                    <Modal.Title style={{ padding: '1rem 1rem 0.5rem 0.5rem' }}>
                        {recipeName}
                    </Modal.Title>
                    <RecipeTileValue
                        data-testid={`name-value-${recipeData?.uuid}`}
                    >
                        {name_solution_optimization_coll}
                    </RecipeTileValue>
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <div style={{ display: 'flex', paddingLeft: '2rem' }}>
                    {/* <StarRatingDiv>
                        <StarRating
                            starRating={starRating}
                            onRatingClick={(e) => onStarRatingClick(e)}
                        />
                        <span>
                            {saveRatingResult &&
                            saveRatingResult?.ratingAverage &&
                            saveRatingResult?.ratingCount
                                ? saveRatingResult?.ratingAverage +
                                  '(' +
                                  saveRatingResult.ratingCount +
                                  ')'
                                : recipeData?.rating +
                                  ' (' +
                                  recipeData?.ratingCount +
                                  ')'}
                        </span>
                    </StarRatingDiv> */}
                    <SetLike
                        isLiked={isLiked}
                        likesCount={
                            likeResult && likeResult?.likeCount
                                ? likeResult.likeCount
                                : recipeData?.likeCount
                                ? recipeData?.likeCount
                                : 0
                        }
                        onLikeClick={(e) => onLikeClick(e)}
                    ></SetLike>
                    <SetFavorite
                        isFavorite={isFavorite}
                        favoriteCount={
                            favoriteResult && favoriteResult?.favoriteCount
                                ? favoriteResult.favoriteCount
                                : recipeData?.favoriteCount
                                ? recipeData?.favoriteCount
                                : 0
                        }
                        recipePipelineStage={pipelineStage}
                        onFavoriteClick={(e) => onFavoriteClick(e)}
                    ></SetFavorite>
                    {/* <StarRatingDiv>
                        <i className="uil uil-download-alt"></i>{' '}
                        <span>
                            {recipeData?.downloadCount
                                ? recipeData.downloadCount
                                : 0}{' '}
                            {' downloads'}
                        </span>
                    </StarRatingDiv> */}
                </div>
                <div style={{ display: 'flex' }}>
                    {showBuyButton ? buyButtonOnCard : ''}
                    <ModalHeaderButton
                        data-testid={`subscription-button-${recipeData?.uuid}`}
                        onClick={onSubscriptionClick}
                    >
                        {isOptSubsCribed ? 'Unsubscribe' : ' Subscribe'}
                    </ModalHeaderButton>
                    <ShareButtonDiv>
                        <ModalHeaderButton
                            type="button"
                            onClick={(e: any) => onShareLinkClick(e)}
                            value={shareLinkURL}
                            title={'Share with others'}
                            data-testid={`share-button-${recipeData?.uuid}`}
                        >
                            <div style={{ display: 'flex' }}>
                                <img
                                    src={StringUtils.resolve('/share_link.svg')}
                                    height="20"
                                    width="20"
                                    alt="share logo"
                                />
                                <span
                                    style={{
                                        marginLeft: '1rem',
                                    }}
                                >
                                    Share
                                </span>
                            </div>
                        </ModalHeaderButton>
                    </ShareButtonDiv>

                    <ModalHeaderButton
                        data-testid={`requestChange-button-${recipeData?.uuid}`}
                        onClick={onFeedbackButtonClick}
                    >
                        Get Support
                    </ModalHeaderButton>
                </div>
            </div>
        </ExpandadCardHeaderContainer>
    )

    const expandedCardBody = (
        <RecipeCardDescriptionComponent>
            <Table responsive>
                <tbody>
                    <tr>
                        <td
                            style={{
                                borderStyle: 'none',
                                width: '60%',
                            }}
                        >
                            {recipeCardColumnPanel1}
                        </td>
                        <td
                            style={{
                                borderStyle: 'none',
                            }}
                        >
                            {recipeCardColumnPanel2}
                            {downloadPanelComponent}
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end',
                                    padding: '0.5rem',
                                }}
                            >
                                {fileCount > 1 ? downloadButton(true) : ''}
                            </div>
                        </td>
                    </tr>
                    <tr
                        style={{
                            position: 'relative',
                            borderBottom: '1px solid #dee2e6',
                        }}
                    >
                        <td>
                            <RecipeCardDescriptionComponent>
                                <ReadMoreContainer>
                                    {isPerformanceUploader &&
                                    recipeData?.isPerformanceDataExist ? (
                                        <PerformanceButton
                                            onClick={() =>
                                                navigate(
                                                    `/performanceSamples/${recipeData?.uuid}`,
                                                    {
                                                        state: {
                                                            appName:
                                                                recipeData?.appName,
                                                        },
                                                    }
                                                )
                                            }
                                            variant="primary"
                                            type="submit"
                                            data-testid={`performance-button-${recipeData?.uuid}`}
                                            style={{ width: '50%' }}
                                        >
                                            Performance
                                        </PerformanceButton>
                                    ) : (
                                        ''
                                    )}
                                </ReadMoreContainer>
                            </RecipeCardDescriptionComponent>
                        </td>
                        <td
                            style={{
                                borderStyle: 'none',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end',
                                    padding: '0.5rem',
                                }}
                            >
                                {recipeData &&
                                recipeData?.isBenchmarkDataExist ? (
                                    <BenchMarkButton
                                        style={{
                                            maxWidth: '8.8rem',
                                            marginLeft: '1rem',
                                        }}
                                        onClick={(evt: any) =>
                                            onShowBenchmarkClick(
                                                evt,
                                                recipeData?.uuid
                                                    ? recipeData?.uuid
                                                    : ''
                                            )
                                        }
                                        variant="primary"
                                        type="submit"
                                        data-testid={`show-benchmark-button-${recipeData?.uuid}`}
                                    >
                                        {showBenchMark ? 'Close' : 'Benchmark'}
                                    </BenchMarkButton>
                                ) : (
                                    ''
                                )}
                            </div>
                        </td>
                    </tr>
                    {showBenchMark ? <tr>{benchmarkPanel}</tr> : <div></div>}

                    <tr>
                        {sw_configuration && sw_configuration.length > 0 ? (
                            <td
                                style={{
                                    width: '50%',
                                    borderBottomWidth: 0,
                                }}
                            >
                                <RecipeCardDescriptionComponent>
                                    <RecipeCardText>
                                        <RecipeTileLabel
                                            data-testid={`swconfiguration-label-${recipeData?.uuid}`}
                                        >
                                            {
                                                'Optimized Software Configuration: '
                                            }
                                        </RecipeTileLabel>
                                        <RecipeTileValue
                                            data-testid={`swconfiguration-value-${recipeData?.uuid}`}
                                        >
                                            {renderTable(
                                                sw_configuration,
                                                'SW Parameters',
                                                'Version Number/Value'
                                            )}
                                        </RecipeTileValue>
                                    </RecipeCardText>
                                </RecipeCardDescriptionComponent>
                            </td>
                        ) : (
                            ''
                        )}
                        {hw_configuration && hw_configuration.length > 0 ? (
                            <td
                                style={{
                                    width: '50%',
                                    borderBottomWidth: 0,
                                }}
                                colSpan={2}
                            >
                                <RecipeCardDescriptionComponent>
                                    <RecipeCardText>
                                        <RecipeTileLabel
                                            data-testid={`hwconfiguration-label-${recipeData?.uuid}`}
                                        >
                                            {
                                                'Optimized Hardware Configuration: '
                                            }
                                        </RecipeTileLabel>
                                        <RecipeTileValue
                                            data-testid={`hwconfiguration-value-${recipeData?.uuid}`}
                                        >
                                            {renderTable(
                                                hw_configuration,
                                                'HW Parameters',
                                                'Version Number/Value'
                                            )}
                                        </RecipeTileValue>
                                    </RecipeCardText>
                                </RecipeCardDescriptionComponent>
                            </td>
                        ) : (
                            ''
                        )}
                    </tr>

                    {bios_configuration && bios_configuration.length > 0 ? (
                        <tr>
                            <td
                                style={{
                                    width: '50%',
                                }}
                            >
                                <RecipeCardDescriptionComponent>
                                    <RecipeCardText>
                                        <RecipeTileLabel
                                            data-testid={`bios-label-${recipeData?.uuid}`}
                                        >
                                            {'Optimized BIOS Configuration: '}
                                        </RecipeTileLabel>
                                        <RecipeTileValue
                                            data-testid={`bios-value-${recipeData?.uuid}`}
                                        >
                                            {renderTable(
                                                bios_configuration,
                                                'BIOS Parameters',
                                                'Version Number/Value'
                                            )}
                                        </RecipeTileValue>
                                    </RecipeCardText>
                                </RecipeCardDescriptionComponent>
                            </td>
                            <td
                                style={{
                                    width: '50%',
                                }}
                                colSpan={2}
                            ></td>
                        </tr>
                    ) : (
                        ''
                    )}
                </tbody>
            </Table>
            <div style={{ padding: '0.8rem' }}>
                Note: For more information on the performance claims related to
                previous generation products, please visit the{' '}
                <a
                    href={
                        !isPublicSearch
                            ? 'https://edc.intel.com/content/www/us/en/products/performance/benchmarks/5th-generation-intel-xeon-scalable-processors/'
                            : '/signIn'
                    }
                    target={!isPublicSearch ? '_blank' : '_self'}
                    rel="noopener"
                >
                    Performance Index
                </a>{' '}
                page.
            </div>
        </RecipeCardDescriptionComponent>
    )

    return (
        <RecipeCardContainer>
            <a
                onClick={(e: any) =>
                    onGalleryReadMoreClick(e, recipeData?.uuid)
                }
                style={{ cursor: 'pointer' }}
            >
                <GalleryViewContainer>{galleryViewPanel}</GalleryViewContainer>
            </a>

            <div>
                <a
                    className="hidden"
                    download={downloadFileName}
                    href={fileDownloadUrl}
                    id={'downloadLinkId-' + recipeData?.uuid}
                    ref={downloadLinkRef}
                ></a>
            </div>
            <RecipeCardDescriptionComponent>
                <ModalPopupContainer>
                    {/* Readmore Model popup */}
                    <Modal
                        show={showGalleryReadme}
                        onHide={CloseGalleryReadme}
                        backdrop="static"
                        keyboard={true}
                        size="xl"
                    >
                        <Modal.Header>
                            {expandedCardHeader}
                            <CloseButton
                                className="p-1"
                                onClick={CloseGalleryReadme}
                                style={{
                                    borderRadius: 0,
                                    marginTop: '-8rem',
                                    fontSize: '1.5rem',
                                    marginRight: '0.5rem',
                                    opacity: '.5',
                                }}
                            />
                        </Modal.Header>
                        <Modal.Body>{expandedCardBody}</Modal.Body>
                        <Modal.Footer>
                            <FooterCloseButtonDiv>
                                <Button
                                    variant="secondary"
                                    onClick={CloseGalleryReadme}
                                    style={{ borderRadius: 0 }}
                                >
                                    Close
                                </Button>
                            </FooterCloseButtonDiv>
                        </Modal.Footer>
                    </Modal>
                </ModalPopupContainer>
                <ModalPopupContainer>
                    {/* Feedback Model */}
                    <Modal
                        show={showFeedbackPopup}
                        onHide={handleFeedbackClose}
                        backdrop="static"
                        keyboard={true}
                        size="lg"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Get Support</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <AlertComponent
                                variant={feedbackError ? 'danger' : 'success'}
                                show={
                                    !isFeedbackLoad &&
                                    ((feedbackResult?.msg !== undefined &&
                                        feedbackResult?.msg !== '') ||
                                        feedbackError)
                                }
                            >
                                {feedbackError
                                    ? 'Error: ' + feedbackError.message
                                    : feedbackResult?.msg
                                    ? feedbackResult.msg
                                    : ''}
                                {feedbackAttachResult?.msg &&
                                    ` Successfully added attachments!`}
                            </AlertComponent>
                            <Form
                                noValidate
                                validated={validated}
                                onSubmit={onFeedbackFormSubmit}
                                id="feedbackForm"
                            >
                                <Form.Group className="mb-3">
                                    <PriorityContainer>
                                        <TextLabel>Priority:</TextLabel>
                                        <FeedbackPrioritySelect
                                            size="sm"
                                            onChange={onPrioritySelectChange}
                                            disabled={!isFeedbackLoad}
                                        >
                                            <option value="Low">Low</option>
                                            <option value="Medium">
                                                Medium
                                            </option>
                                            <option value="High">High</option>
                                            <option value="Showstopper">
                                                Showstopper
                                            </option>
                                        </FeedbackPrioritySelect>
                                        {/* <TextLabel>Date:</TextLabel>
                                        <Form.Control
                                            type="date"
                                            style={{
                                                width: '25%',
                                                height: '2.4rem',
                                            }}
                                            onChange={onFeedbackDateChange}
                                        ></Form.Control> */}
                                    </PriorityContainer>
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <TextLabel>
                                        <RequiredTextLabel>*</RequiredTextLabel>
                                        Description:
                                    </TextLabel>
                                    <Form.Control
                                        as="textarea"
                                        rows={4}
                                        placeholder="Enter description..."
                                        autoFocus
                                        required
                                        onChange={onFeedbackTextChange}
                                        disabled={!isFeedbackLoad}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter description.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} className="mb-3">
                                    <AttachButton
                                        onClick={onFeedbackAttachClick}
                                    >
                                        <FeedbackImageDiv>
                                            <i className="uil uil-paperclip"></i>
                                        </FeedbackImageDiv>
                                        Attach
                                    </AttachButton>
                                    <input
                                        style={{ display: 'none' }}
                                        type="file"
                                        onChange={onFeedbackFileChange}
                                        ref={feedbackFileInputRef}
                                        disabled={!isFeedbackLoad}
                                    />

                                    <Form.Label>
                                        {feedbackSelectedFileName}
                                    </Form.Label>
                                </Form.Group>
                                <Modal.Footer>
                                    <Button
                                        variant="secondary"
                                        onClick={handleFeedbackClose}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        variant="primary"
                                        type="submit"
                                        disabled={!isFeedbackLoad}
                                    >
                                        Submit
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        </Modal.Body>
                    </Modal>
                </ModalPopupContainer>
                <ModalPopupContainer>
                    {/* Accept License Model */}
                    <Modal
                        show={showLicensePopup}
                        onHide={handleLicensePopupClose}
                        backdrop="static"
                        keyboard={true}
                        size="lg"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>License Agreement</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group className="mb-3">
                                <Form.Control
                                    as="textarea"
                                    rows={20}
                                    autoFocus
                                    required
                                    defaultValue={licenseText}
                                    readOnly={true}
                                />
                            </Form.Group>
                            <Modal.Footer>
                                <Button
                                    variant="primary"
                                    disabled={licenseText !== '' ? false : true}
                                    onClick={handleRejectLicenseClick}
                                >
                                    Reject
                                </Button>
                                <Button
                                    variant="primary"
                                    disabled={licenseText !== '' ? false : true}
                                    onClick={handleAcceptLicenseClick}
                                >
                                    Accept
                                </Button>
                            </Modal.Footer>
                        </Modal.Body>
                    </Modal>
                </ModalPopupContainer>
                <ModalPopupContainer>
                    {/* Confirmation message Model popup */}
                    <Modal
                        show={showLicenseConfirmation}
                        onHide={handleCloseConfirmation}
                        backdrop="static"
                        keyboard={true}
                        centered
                    >
                        <Modal.Header>
                            <Modal.Title>Confirmation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <LicenseConfirmationLabel>
                                {saveLicenseAcceptanceResult?.data?.msg
                                    ? 'License has been accepted successfully.'
                                    : saveLicenseAcceptanceResult?.error}
                            </LicenseConfirmationLabel>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="primary"
                                onClick={onLicenseConfirmationClick}
                            >
                                OK
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </ModalPopupContainer>
                <ModalPopupContainer>
                    {/* Confirmation message Model popup for Subscription */}
                    <Modal
                        show={showOptSubscriptionConfirmation}
                        onHide={handleOptSubsCloseConfirmation}
                        backdrop="static"
                        keyboard={true}
                        top
                    >
                        <Modal.Header>
                            <Modal.Title>Confirmation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <LicenseConfirmationLabel>
                                {addOptSubscriptionResult?.data?.msg &&
                                isOptSubsCribed
                                    ? `Your subscription for ${recipeData?.appName} has been created successfully.`
                                    : addOptSubscriptionResult?.error}
                                {deleteOptSubscriptionResult?.data?.msg &&
                                !isOptSubsCribed
                                    ? `Your subscription for ${recipeData?.appName} has been removed successfully.`
                                    : deleteOptSubscriptionResult?.error}
                            </LicenseConfirmationLabel>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="primary"
                                onClick={handleOptSubsCloseConfirmation}
                            >
                                OK
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </ModalPopupContainer>
            </RecipeCardDescriptionComponent>
        </RecipeCardContainer>
    )
}

export default RecipeCard
