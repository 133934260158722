import { API_SERVICE_URL } from '../config/service'
import { BenchmarkSearchRequest, Tag } from '../models/benchmarkSearchRequest'
import api from './auth/client'

const serviceConfig = require('../config/service.json')

class BenchmarkService {
    isEcosystemTag(tagName: string) {
        if (
            tagName.indexOf('oem') > -1 ||
            tagName.indexOf('isv') > -1 ||
            tagName.indexOf('osv') > -1 ||
            tagName.indexOf('si') > -1 ||
            tagName.indexOf('csp') > -1
        )
            return true
        else return false
    }
    isEnvironmentTag(tagName: string) {
        if (tagName === 'group-type' || tagName === 'group') return true
        else return false
    }
    getWorkloads(benchmarkSearchCriteria: BenchmarkSearchRequest) {
        const {
            searchValue,
            groupType,
            accessGroup,
            tagValues,
            offset,
            count,
        } = benchmarkSearchCriteria
        let url = `${API_SERVICE_URL}${serviceConfig.getWorkloads}`
        if (
            groupType === 'On-Prem' ||
            groupType === 'Cloud' ||
            groupType === 'benchmarks'
        ) {
            url = `${API_SERVICE_URL}${serviceConfig.getWorkloadsgroupbenchmarks}`
            url = `${url}?search=${searchValue}`
        } else if (groupType === 'Software Optimizations') {
            url = `${API_SERVICE_URL}${serviceConfig.getOptimizationGroups}`
            url = `${url}?search=${searchValue}`
        } else if (groupType === 'Partner Solutions') {
            url = `${API_SERVICE_URL}${serviceConfig.getPartnerSolutionsGroups}`
            url = `${url}?search=${searchValue}`
        } else if (groupType === 'Customer Success Stories') {
            url = `${API_SERVICE_URL}${serviceConfig.getCustomerCaseStudiessGroups}`
            url = `${url}?search=${searchValue}`
        } else {
            url = `${url}?search=${searchValue}`
        }
        if (offset) url = `${url}&offset=${offset}`
        if (count) url = `${url}&count=${count}`
        if (accessGroup) url = `${url}${accessGroup}`
        if (tagValues)
            tagValues.forEach((tag: Tag) => {
                if (url.indexOf('?') > -1)
                    url = this.isEcosystemTag(tag.tagName)
                        ? `${url}&eco-${tag.tagName}=${tag.tagValue}`
                        : this.isEnvironmentTag(tag.tagName)
                        ? `${url}&${tag.tagName}=${tag.tagValue}`
                        : `${url}&tags-${tag.tagName}=${tag.tagValue}`
                else
                    url = this.isEcosystemTag(tag.tagName)
                        ? `${url}?eco-${tag.tagName}=${tag.tagValue}`
                        : this.isEnvironmentTag(tag.tagName)
                        ? `${url}&${tag.tagName}=${tag.tagValue}`
                        : `${url}?tags-${tag.tagName}=${tag.tagValue}`
            })
        if (
            url.indexOf('group-type') === -1 &&
            (groupType === 'On-Prem' ||
                groupType === 'Cloud' ||
                groupType === 'benchmarks')
        )
            if (groupType === 'benchmarks') url = `${url.trim()}&group-type=`
            else url = `${url}&group-type=${groupType}`
        return api.get(url)
    }

    getDefaultWorkloads(searchObject: any) {
        const { count, offset, searchValue, accessGroup, tagValues } =
            searchObject
        let url = `${API_SERVICE_URL}${serviceConfig.getWorkloadBenchmarks}?count=${count}&offset=${offset}`
        if (accessGroup) url = `${url}${accessGroup}`
        if (searchValue) url = `${url}&search=${searchValue}`
        if (tagValues)
            tagValues.forEach((tag: Tag) => {
                url = this.isEcosystemTag(tag.tagName)
                    ? `${url}&eco-${tag.tagName}=${tag.tagValue}`
                    : this.isEnvironmentTag(tag.tagName)
                    ? `${url}&${tag.tagName}=${tag.tagValue}`
                    : `${url}&tags-${tag.tagName}=${tag.tagValue}`
            })
        return api.get(url)
    }

    getWorkloadBenchmarks(searchObject: any) {
        const { params, tagValues } = searchObject
        let url = `${API_SERVICE_URL}${serviceConfig.getWorkloadBenchmarks}/${params}`

        if (tagValues)
            tagValues.forEach((tag: any) => {
                url = this.isEcosystemTag(tag.tagName)
                    ? `${url}&eco-${tag.tagName}=${tag.tagValue}`
                    : this.isEnvironmentTag(tag.tagName)
                    ? `${url}&${tag.tagName}=${tag.tagValue}`
                    : `${url}&tags-${tag.tagName}=${tag.tagValue}`
            })
        return api.get(url)
    }
    getWorkloadBenchmarksList(searchObject: any) {
        const { id, groupType, accessGroup, tagValues } = searchObject
        let url = `${API_SERVICE_URL}${serviceConfig.getWorkloadBenchmarksList}?wl-uuid=${id}${accessGroup}`

        if (tagValues)
            tagValues.forEach((tag: any) => {
                url = this.isEcosystemTag(tag.tagName)
                    ? `${url}&eco-${tag.tagName}=${tag.tagValue}`
                    : this.isEnvironmentTag(tag.tagName)
                    ? `${url}&${tag.tagName}=${tag.tagValue}`
                    : `${url}&tags-${tag.tagName}=${tag.tagValue}`
            })
        if (url.indexOf('group-type') === -1)
            url = `${url}&group-type=${groupType}`
        return api.get(url)
    }

    getWorkloadBenchmarksSearch(
        benchmarkSearchCriteria: BenchmarkSearchRequest
    ) {
        const { searchValue, tagValues } = benchmarkSearchCriteria
        let url = `${API_SERVICE_URL}${serviceConfig.getWorkloadBenchmarks}?search=${searchValue}`
        if (tagValues)
            tagValues.forEach((tag: Tag) => {
                url = `${url}&tag-${tag.tagName}=${tag.tagValue}`
            })
        return api.get(url)
    }

    async downloadBenchmarkSlide(downloadUrl: string) {
        const url = `${API_SERVICE_URL}${serviceConfig.downloadBenchmarkAttachment}/${downloadUrl}`

        //return api.get(url, { responseType: 'blob' })
        const response = await api.get(url, { responseType: 'blob' })
        return response
    }
    getWorkloadOptimizations(searchObject: any) {
        const { params, tagValues } = searchObject
        let url = `${API_SERVICE_URL}${serviceConfig.getWorkloadOptimizations}/${params}`

        if (tagValues)
            tagValues.forEach((tag: any) => {
                url = this.isEcosystemTag(tag.tagName)
                    ? `${url}&eco-${tag.tagName}=${tag.tagValue}`
                    : this.isEnvironmentTag(tag.tagName)
                    ? `${url}&${tag.tagName}=${tag.tagValue}`
                    : `${url}&tags-${tag.tagName}=${tag.tagValue}`
            })
        return api.get(url)
    }
    getWorkloadPartnerSolutions(searchObject: any) {
        const { params, tagValues } = searchObject
        let url = `${API_SERVICE_URL}${serviceConfig.getWorkloadOptimizations}/${params}`

        if (tagValues)
            tagValues.forEach((tag: any) => {
                url = this.isEcosystemTag(tag.tagName)
                    ? `${url}&eco-${tag.tagName}=${tag.tagValue}`
                    : this.isEnvironmentTag(tag.tagName)
                    ? `${url}&${tag.tagName}=${tag.tagValue}`
                    : `${url}&tags-${tag.tagName}=${tag.tagValue}`
            })
        return api.get(url)
    }
    getWorkloadCustomerCaseStudies(searchObject: any) {
        const { params, tagValues } = searchObject
        let url = `${API_SERVICE_URL}${serviceConfig.getWorkloadOptimizations}/${params}`

        if (tagValues)
            tagValues.forEach((tag: any) => {
                url = this.isEcosystemTag(tag.tagName)
                    ? `${url}&eco-${tag.tagName}=${tag.tagValue}`
                    : this.isEnvironmentTag(tag.tagName)
                    ? `${url}&${tag.tagName}=${tag.tagValue}`
                    : `${url}&tags-${tag.tagName}=${tag.tagValue}`
            })
        return api.get(url)
    }
    getSalesSidebarLinks() {
        const url = `${API_SERVICE_URL}${serviceConfig.getSalesSidebarLinks}`
        return api.get(url)
    }
}

export default new BenchmarkService()
