import { useEffect, useState } from 'react'
import { IFavoritesContainerProps } from './favoritesContainer'
import { ShoppingCartRequest } from '../../models/shoppingCartRequest'
import { ProductTag } from '../../models/ProductTag'

export function FavoriteContainerLogic({
    getFavorites,
    updateCartResult,
    updateCartResultLoading,
    updateCartResultError,
    getSubscription,
    getSubscriptionResult,
    getCartItems,
}: IFavoritesContainerProps) {
    const [navigateToPage, setNavigateToPage] = useState<string | null>(null)
    const updatedCheckedOutItemCount = updateCartResult?.cartitems?.length
    const [currentCheckedOutItemCount, setCurrentCheckedOutItemCount] =
        useState<number | undefined>(updatedCheckedOutItemCount)

    useEffect(() => {
        getFavorites({ offset: 0, count: 10 })
    }, [])

    useEffect(() => {
        getCartItems({
            tags: [ProductTag.OptimizationHub],
        } as ShoppingCartRequest)
    }, [])

    useEffect(() => {
        setNavigateToPage(null)
        if (updatedCheckedOutItemCount != currentCheckedOutItemCount) {
            setNavigateToPage('/cart')
            setCurrentCheckedOutItemCount(updatedCheckedOutItemCount)
        }
    }, [updatedCheckedOutItemCount, currentCheckedOutItemCount])

    return { navigateToPage }
}
