import { colors } from '../../../../utils/colors'

const chartColors: string[] = [
    colors.geode,
    colors.blueSteel,
    colors.energyBlue,
    colors.cobaltTint1,
    colors.rust,
    colors.moss,
    colors.coral,
    colors.daisy,
    colors.classicBlue,
    colors.geodeShade1,
    colors.blueSteelTint1,
    colors.energyBlueTint1,
    colors.cobalt,
    colors.rustTint1,
    colors.mossTint1,
    colors.coralTint1,
    colors.daisyShade1,
    colors.classicBlueTint1,
]

export default chartColors
