import { FunctionComponent, useEffect, useState, ChangeEvent } from 'react'
import styled from 'styled-components'
import { useAppDispatch, useAppSelector } from '../../config/hooks'
import { LoadingState } from '../../models/loadingState'
import {
    Form,
    FormControl,
    FormLabel,
    Spinner,
    Table,
    Dropdown,
    DropdownButton,
    Modal,
    Button,
    Alert,
    Col,
    Row,
    OverlayTrigger,
    Popover,
    Breadcrumb,
    BreadcrumbItem,
} from 'react-bootstrap'
import { attachFile } from '../../store/uploadRegistry.slice'
import Pagination from '../pagination/pagination'
import { MyRequestPanelLogic } from './myRequestPanel.hook'
import StringUtils from '../../lib/stringUtils'
import { isDevAndLocal } from '../../lib/host'
import { APP_DOMAIN } from '../../config/service'
import moment from 'moment'

const MyRegistryPanelFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
`

const VericalContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

const RecipeContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    background: #ffffff;
    padding: 0 2rem 2rem 2rem;
    margin: 2rem;
`

const RecipeTitleTextLabel = styled(FormLabel)`
    ${(props) => props.theme.typography.xxLarge}
    text-align: left;
    font-weight: 400;
    font-size: 1.5rem;
    padding-top: 1rem;
`

const RecipeTextLabel = styled(FormLabel)`
    display: flex;
    ${(props) => props.theme.typography.medium}
    text-align: left;
    margin-top: 0.5rem;
    white-space: pre-wrap;
    width: 12rem;
`

const RecipeTextLabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`
const RecipeLinklWrapper = styled.div`
    flex-direction: column;
    justify-content: center;
    margin-top: 0.6rem;
`

const RecipeStatusTextLabel = styled(RecipeTextLabel)`
    width: 12rem;
`
const RecipeNameTextLabel = styled(RecipeTextLabel)`
    width: 15rem;
`
const RecipeImageLabel = styled(RecipeTextLabel)`
    width: 7rem;
`

const RecipeTextLabelHeadings = styled(RecipeTextLabel)`
    color: ${(props) => props.theme.color.text.default};
    ${(props) => props.theme.typography.large}
`

const RecipeStatusTextLabelHeadings = styled(RecipeTextLabel)`
    color: ${(props) => props.theme.color.text.default};
    ${(props) => props.theme.typography.large}
    width: 12rem;
`

const ModalPopupContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const ModalTextLabelKey = styled(FormLabel)`
    display: flex;
    ${(props) => props.theme.typography.medium}
    text-align: left;
    margin-top: 0.5rem;
`
const ModalTextSpan = styled.span`
    display: flex;
    ${(props) => props.theme.typography.medium}
    text-align: left;
    font-weight: 500;
    padding-right: 0.5rem;
`

const AlertComponent = styled(Alert)`
    display: flex;
    margin-top: 1rem;
    width: 35vw;
    justify-content: center;
`

const PaginationComponent = styled.div`
    display: flex;
    flex-grow: 1;
    margin-top: 1rem;
    flex-direction: column;
    justify-content: center;
    width: 96%;
`

const PaginationPanelContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 10rem;
    margin-right: 10%;
    @media screen and (max-width: 500px) {
        justify-content: center;
        padding-left: 3rem;
        padding-right: 0rem;
    }
`
const PaginationDiv = styled.div`
    @media screen and (max-width: 500px) {
        padding-left: 5rem;
    }
`

const ShowingPageContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: left;
    flex-direction: row;
    padding-left: 3rem;
    width: 30rem;
    margin-top: 0.2rem;
    @media screen and (max-width: 500px) {
        margin-left: 2rem;
    }
`

const PageCountSelect = styled(Form.Select)`
    width: 5rem;
    height: 60%;
    margin-left: 0.5rem;
`

const TextLabel = styled(FormLabel)`
    display: flex;
    ${(props) => props.theme.typography.xLarge}
    text-align: left;
`

const PopoverKeyDiv = styled.div`
    background-color: #cff4fc;
    font-size: 1rem;
    padding: 0.5rem;
`

const FileUploadControl = styled(FormControl)`
    display: flex;
    margin-bottom: 1rem;
    min-height: 4vh;
`

const AddMoreFilesButton = styled(Button)`
    display: flex;
    justify-content: center;

    margin-top: 2rem;

    border-radius: ${(props) => props.theme.shape.button.borderRadius};
    background-color: ${(props) =>
        props.theme.color.button.background.default.primary};
    &:hover {
        background-color: ${(props) =>
            props.theme.color.button.background.hover.primary};
        transition: 0.5s;
    }
    &:disabled {
        background-color: ${(props) =>
            props.theme.color.button.background.disabled.primary};
    }
`
const SpinnerDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5rem;
    width: 100%;
`

export interface IMyRequestPanelProps {
    title: string
    optimizationAdd?: boolean
    optimizationRequest?: boolean
    getData: any
    dataResponse?: any
    dataLoading: LoadingState
    dataError: any
    deleteData: any
    deleteResult: any
    clearData: any
    isOptOwnersSupportRequest?: boolean
}

const MyRequestPanel: FunctionComponent<IMyRequestPanelProps> = ({
    title,
    optimizationAdd,
    optimizationRequest,
    getData,
    dataResponse,
    dataLoading,
    dataError,
    deleteData,
    deleteResult,
    clearData,
    isOptOwnersSupportRequest,
}: IMyRequestPanelProps) => {
    const dispatch = useAppDispatch()
    const [showDeletePopup, setShowDeletePopup] = useState(false)
    const [recipeData, setRecipeData] = useState<any>(null)
    const [disableButtons, setDisableButtons] = useState(false)
    const [showMultiModal, setShowMultiModal] = useState(false)
    const [files, setFiles] = useState<any>([{}])
    const [showSuccessMessage, setShowSuccessMessage] = useState(false)
    const [showErrorMessage, setShowErrorMessage] = useState(false)

    const { afterPageClicked, handlePageCount, currPage, pageCount } =
        MyRequestPanelLogic({
            getData,
            dataResponse,
        } as IMyRequestPanelProps)

    useEffect(() => {
        if (deleteResult?.data) {
            setDisableButtons(true)
        }
    }, [deleteResult])

    const attachSuccess = useAppSelector<any>(
        (state) => state.attachFileResult.data
    )
    useEffect(() => {
        if (attachSuccess) {
            setShowSuccessMessage(true)
            setTimeout(() => setShowSuccessMessage(false), 3000)
            handleClose()
            setFiles([{}])
        }
    }, [attachSuccess])
    const attachError = useAppSelector<any>(
        (state) => state.attachFileResult.error
    )
    useEffect(() => {
        if (attachError) setShowErrorMessage(true)
        setTimeout(() => setShowErrorMessage(false), 3000)
    }, [attachError])

    const handleClose = () => {
        setShowDeletePopup(false)
        setShowMultiModal(false)
        setDisableButtons(false)
        setFiles([{}])
    }
    const handleDelete = () => {
        const { uuid } = recipeData
        deleteData(uuid)
        setTimeout(() => {
            handleClose()
            clearData()
        }, 3000)
    }

    let recipes = dataResponse?.recipes
        ? [...dataResponse?.recipes]
        : dataResponse?.requests
        ? [...dataResponse?.requests]
        : dataResponse?.feedbacks
        ? [...dataResponse?.feedbacks]
        : undefined

    if (optimizationAdd) {
        recipes = recipes?.filter(
            (optimization) => optimization.requestType === 'optimizationUpload'
        )
    } else if (optimizationRequest) {
        recipes = recipes?.filter(
            (optimization) => optimization.requestType === 'newOptimization'
        )
    }

    const totalPage = dataResponse?.pageInfo?.total
        ? dataResponse?.pageInfo.total
        : -1

    const nextPage = dataResponse?.pageInfo?.next
        ? dataResponse?.pageInfo.next
        : -1

    const recipeRows = recipes
        ?.sort(
            (a: any, b: any) =>
                +new Date(b.timeCreated) - +new Date(a.timeCreated)
        )
        .map((recipe) => {
            const timeUpdated = moment
                .utc(recipe.timeUpdated)
                .local()
                .format('MM/DD/YY, h:mm a')
            const timeCreated = moment
                .utc(recipe.timeCreated)
                .local()
                .format('MM/DD/YY, h:mm a')
            return (
                <tr key={recipe.uuid}>
                    <td>
                        <RecipeImageLabel>
                            <img
                                src="/Cloud-Computing.png"
                                height="39px"
                                width="55px"
                                alt="Cloud Computng"
                            />
                        </RecipeImageLabel>
                    </td>
                    <td>
                        <RecipeTextLabelWrapper>
                            <RecipeNameTextLabel
                                data-testid={`recipeName-value-${recipe?.uuid}`}
                            >
                                {recipe?.additionalFields?.additionalProperties
                                    ?.optimizationName ||
                                    recipe?.additionalFields
                                        ?.additionalProperties?.appName ||
                                    recipe?.additionalFields
                                        ?.additionalProperties?.feedback}
                            </RecipeNameTextLabel>
                        </RecipeTextLabelWrapper>
                    </td>
                    <td>
                        <RecipeTextLabelWrapper>
                            <RecipeStatusTextLabel
                                data-testid={`created-date-value-${recipe?.uuid}`}
                            >
                                {timeCreated}
                            </RecipeStatusTextLabel>
                        </RecipeTextLabelWrapper>
                    </td>
                    <td>
                        <RecipeTextLabelWrapper>
                            <RecipeStatusTextLabel
                                data-testid={`status-value-${recipe?.uuid}`}
                            >
                                {recipe.decision
                                    ? recipe.decision
                                    : recipe.status}
                            </RecipeStatusTextLabel>
                        </RecipeTextLabelWrapper>
                    </td>
                    <td>
                        <RecipeTextLabelWrapper>
                            <RecipeStatusTextLabel
                                data-testid={`updated-date-value-${recipe?.uuid}`}
                            >
                                {timeUpdated}
                            </RecipeStatusTextLabel>
                        </RecipeTextLabelWrapper>
                    </td>
                    <td>
                        <RecipeLinklWrapper>
                            <DropdownButton
                                id="dropdown-basic-button"
                                title="Action"
                                variant="outline-primary"
                                data-testid={`action-button-${recipe?.uuid}`}
                            >
                                {optimizationAdd &&
                                recipe.decision !== 'Saved' ? (
                                    <Dropdown.Item
                                        href={`addNewOptimization/${recipe.uuid}`}
                                    >
                                        View
                                    </Dropdown.Item>
                                ) : null}
                                {recipe?.additionalFields?.additionalProperties
                                    ?.feedback ? (
                                    <Dropdown.Item
                                        href={
                                            recipe.additionalFields
                                                .additionalProperties
                                                .feedback ||
                                            recipe.additionalFields
                                                .additionalProperties.appName
                                                ? `viewDetails/${recipe.uuid}`
                                                : recipe?.additionalFields
                                                      ?.additionalProperties
                                                      ?.description
                                                ? `viewDetails/request/${recipe.uuid}`
                                                : `viewDetails/benchmarkRequest/${recipe.uuid}`
                                        }
                                        data-testid={`action-item-view-value-${recipe?.uuid}`}
                                    >
                                        View
                                    </Dropdown.Item>
                                ) : (
                                    ''
                                )}
                                {optimizationAdd ? (
                                    <div>
                                        <Dropdown.Item
                                            href={`addNewOptimization/${recipe.uuid}`}
                                            disabled={false}
                                            data-testid={`action-item-edit-value-${recipe?.uuid}`}
                                        >
                                            Edit
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            data-testid={`action-item-add-attachment-${recipe?.uuid}`}
                                            onClick={() => {
                                                setShowMultiModal(true)
                                                setRecipeData(recipe)
                                            }}
                                        >
                                            Add Attachments
                                        </Dropdown.Item>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </DropdownButton>
                        </RecipeLinklWrapper>
                    </td>
                </tr>
            )
        })

    const handleSubmit = (event: any) => {
        event.preventDefault()

        for (let file of files) {
            const payload = {
                id: recipeData.uuid,
                file: file[0],
            }
            dispatch(attachFile(payload))
        }
    }

    return (
        <MyRegistryPanelFormContainer>
            <VericalContainer>
                {dataLoading !== LoadingState.Pending ? (
                    <RecipeContainer>
                        <AlertComponent
                            variant={'danger'}
                            show={dataError ? true : false}
                            data-testid={`error-label-${title}`}
                        >
                            {dataError && dataError.message !== undefined
                                ? 'Error!: ' + dataError.message
                                : ''}
                        </AlertComponent>
                        <Alert variant="success" show={showSuccessMessage}>
                            Successfully added attachments!
                        </Alert>
                        <Alert variant="danger" show={showErrorMessage}>
                            Error adding attachments!
                        </Alert>
                        <RecipeTitleTextLabel
                            data-testid={`title-label-${title}`}
                        >
                            {title}
                        </RecipeTitleTextLabel>
                        <Table
                            responsive
                            borderless
                            style={{ minHeight: '14rem' }}
                        >
                            <thead>
                                <tr>
                                    <th>&nbsp;</th>
                                    <th>
                                        <RecipeTextLabelHeadings
                                            data-testid={`name-label-${title}`}
                                        >
                                            Name
                                        </RecipeTextLabelHeadings>
                                    </th>
                                    <th>
                                        <RecipeStatusTextLabelHeadings
                                            data-testid={`created-date-label-${title}`}
                                        >
                                            Created Date
                                        </RecipeStatusTextLabelHeadings>
                                    </th>
                                    <th>
                                        <RecipeStatusTextLabelHeadings
                                            data-testid={`status-label-${title}`}
                                        >
                                            Status
                                        </RecipeStatusTextLabelHeadings>
                                    </th>
                                    <th>
                                        <RecipeStatusTextLabelHeadings
                                            data-testid={`created-date-label-${title}`}
                                        >
                                            Last Updated Date
                                        </RecipeStatusTextLabelHeadings>
                                    </th>
                                    <th>
                                        <RecipeStatusTextLabelHeadings
                                            data-testid={`action-label-${title}`}
                                        >
                                            Actions
                                        </RecipeStatusTextLabelHeadings>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>{recipeRows}</tbody>
                        </Table>
                        <PaginationComponent>
                            <PaginationPanelContainer>
                                <ShowingPageContainer>
                                    {totalPage > 0 ? (
                                        <span>
                                            {' '}
                                            Showing{' '}
                                            {(currPage - 1) * pageCount +
                                                1} to{' '}
                                            {currPage * pageCount < totalPage
                                                ? currPage * pageCount
                                                : totalPage}{' '}
                                            of {totalPage} results{' '}
                                        </span>
                                    ) : (
                                        ''
                                    )}
                                    {totalPage > 10 && totalPage >= nextPage ? (
                                        <PageCountSelect
                                            size="sm"
                                            onChange={handlePageCount}
                                            value={pageCount}
                                        >
                                            <option value={10}>10</option>
                                            <option value={20}>20</option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                        </PageCountSelect>
                                    ) : (
                                        ''
                                    )}
                                </ShowingPageContainer>
                                <PaginationDiv>
                                    <Pagination
                                        totalPage={Math.ceil(
                                            totalPage / pageCount
                                        )}
                                        currentPage={currPage}
                                        onPageClicked={(ele: any) => {
                                            afterPageClicked(ele)
                                        }}
                                    />
                                </PaginationDiv>
                            </PaginationPanelContainer>
                        </PaginationComponent>
                    </RecipeContainer>
                ) : (
                    <SpinnerDiv>
                        <Spinner animation="border" variant="dark" />
                    </SpinnerDiv>
                )}
            </VericalContainer>

            <ModalPopupContainer>
                <Modal
                    show={showDeletePopup}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Optimization</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <span>Are you sure you want to delete?</span>
                        <ModalTextLabelKey>
                            <ModalTextSpan>Name:</ModalTextSpan>
                            {recipeData?.appName}
                        </ModalTextLabelKey>

                        <ModalTextLabelKey>
                            <ModalTextSpan>Description: </ModalTextSpan>{' '}
                            {recipeData?.description}
                        </ModalTextLabelKey>
                        <AlertComponent
                            id="successAlert"
                            variant="success"
                            show={deleteResult?.data ? true : false}
                        >
                            {'Successfully deleted recipe.'}
                        </AlertComponent>
                        <AlertComponent
                            id="errorAlert"
                            variant="danger"
                            show={deleteResult?.error ? true : false}
                        >
                            {'Error deleting recipe.'}
                        </AlertComponent>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={handleClose}
                            disabled={disableButtons}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="danger"
                            onClick={handleDelete}
                            disabled={disableButtons}
                        >
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
            </ModalPopupContainer>
            <ModalPopupContainer>
                <Modal
                    show={showMultiModal}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Add Attachment(s)</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {files.map((file: any, index: number) => (
                            <Row className="mb-3" key={index}>
                                <Form.Group as={Col} md="8">
                                    <TextLabel data-testid="file-label">
                                        File
                                        <OverlayTrigger
                                            placement={'top'}
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={
                                                <Popover
                                                    id="pop_platform"
                                                    style={{ maxWidth: '20%' }}
                                                >
                                                    <Popover.Body>
                                                        <PopoverKeyDiv>
                                                            Please attach any
                                                            other documents or
                                                            performance reports
                                                            for this
                                                            Optimization
                                                        </PopoverKeyDiv>
                                                    </Popover.Body>
                                                </Popover>
                                            }
                                        >
                                            <i className="uil uil-info-circle pointer"></i>
                                        </OverlayTrigger>
                                    </TextLabel>
                                    <FileUploadControl
                                        type="file"
                                        id={`file${index}`}
                                        name={`file${index}`}
                                        onChange={(
                                            event: ChangeEvent<HTMLElement>
                                        ) => {
                                            const target =
                                                event.currentTarget as HTMLInputElement
                                            files[index] = target.files
                                        }}
                                        key={`file${index}`}
                                        data-testid={`file-${index}-value`}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please choose a file.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="4">
                                    {/* <RemoveFilesButton
                                        onClick={() => {
                                            if (files.length <= 1) return
                                            setFiles(
                                                files
                                                    .slice(0, index)
                                                    .concat(
                                                        files.slice(index + 1)
                                                    )
                                            )
                                        }}
                                    >
                                        -
                                    </RemoveFilesButton> */}
                                </Form.Group>
                            </Row>
                        ))}

                        <Row>
                            <Col>
                                <AddMoreFilesButton
                                    onClick={() => {
                                        if (
                                            files[files.length - 1][0] &&
                                            files[files.length - 1][0].name
                                        )
                                            setFiles(files.concat({}))
                                    }}
                                >
                                    Add Attachment
                                </AddMoreFilesButton>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={handleClose}
                            disabled={disableButtons}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            onClick={handleSubmit}
                            disabled={disableButtons}
                        >
                            Submit
                        </Button>
                    </Modal.Footer>
                </Modal>
            </ModalPopupContainer>
        </MyRegistryPanelFormContainer>
    )
}

export default MyRequestPanel
