import { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Alert, Form, FormLabel, Spinner } from 'react-bootstrap'
import { LoadingState } from '../../models/loadingState'
import RecipeCardPanel from '../recipe/recipeCardPanel'
import { CatalogSearchResponse } from '../../models/catalogSearchResponse'
import Pagination from '../pagination/pagination'
import { DownloadCatalogResponse } from '../../models/downloadCatalogResponse'
import { RecipeFeedbackRequest } from '../../models/recipeFeedbackRequest'
import { Recipefeedbackresponse } from '../../models/recipefeedbackresponse'
import { RecipeAddFavoriteRequest } from '../../models/recipeAddFavoriteRequest'
import { RecipeAddFavoriteResponse } from '../../models/recipeAddFavoriteResponse'
import { RecipeStarRatingRequest } from '../../models/recipeStarRatingRequest'
import { RecipeStarRatingResponse } from '../../models/recipeStarRatingResponse'
import { FavoritePanelLogic } from './favoritePanel.hook'
import { RecipeAddVotingRequest } from '../../models/recipeAddVotingRequest'
import { RecipeAddVotingResponse } from '../../models/recipeAddVotingResponse'
import { RecipeAddLinkClickRequest } from '../../models/recipeAddLinkClickRequest'
import { getCartItems, updateCartItems } from '../../store/shoppingCart.slice'
import { ShoppingCartResponse } from '../../models/shoppingCartResponse'
import { LayoutViewPreference } from '../../models/LayoutViewPreference'
import StringUtils from '../../lib/stringUtils'
import { RecipeAddLikeResponse } from '../../models/recipeAddLikeResponse'
import { RecipeAddLikeRequest } from '../../models/recipeAddLikeRequest'

const FavoriteFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
`

const FavoriteFormComponent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: no-wrap;
`
const FavoriteResultComponent = styled.div`
    display: flex;
    flex-grow: 1;
    margin-top: 1rem;
    flex-direction: column;
    justify-content: center;
`
const FavoriteCardPanelContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: left;
`
const PaginationComponent = styled.div`
    display: flex;
    flex-grow: 1;
    margin-top: 1rem;
    flex-direction: column;
    justify-content: center;
`
const PaginationPanelContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: right;
    padding-right: 22.5%;
`

const ShowingPageContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: left;
    flex-direction: row;
    margin-top: 1rem;
    padding-left: 0.8%;
`
const AlertComponent = styled(Alert)`
    display: flex;
    margin-top: 1rem;
    justify-content: center;
`
const FeaturedComponent = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: center;
`
const PageCountSelect = styled(Form.Select)`
    width: 5rem;
    height: 2rem;
    margin-left: 0.5rem;
`
const SpinnerDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5rem;
    width: 100%;
`
const NoDataAvailableContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: left;
`
const NoDataAvailableLabel = styled(FormLabel)`
    ${(props) => props.theme.typography.xLarge}
    text-align: left;
    font-weight: 400;
    font-size: 1rem;
    margin-bottom: 30rem;
    color: green;
`

export interface IFavoritePanelProps {
    getFavorites: (param: any) => void
    getFavoriteLoading: LoadingState
    getFavoriteResult: CatalogSearchResponse
    getFavoriteError: any
    downloadCatalog: (downloadUrl: string) => void
    downloadCatalogResult: DownloadCatalogResponse
    downloadCatalogLoading: LoadingState
    downloadCatalogError?: any
    submitFeedback: (recipeFeedbackRequest: RecipeFeedbackRequest) => void
    feedbackResult: Recipefeedbackresponse
    feedbackError: any
    feedbackLoading: LoadingState
    saveFavorite: (addFavoriteRequest: RecipeAddFavoriteRequest) => void
    favoriteResult: RecipeAddFavoriteResponse
    favoriteError?: any
    favoriteLoading: LoadingState
    saveStarRating: (saveRatingRequest: RecipeStarRatingRequest) => void
    saveRatingResult: RecipeStarRatingResponse
    saveRatingError?: any
    saveVoting: (saveVotingRequest: RecipeAddVotingRequest) => void
    saveVotingResult: RecipeAddVotingResponse
    saveVotingError?: any
    getRecipeById: (id: any) => void
    saveLinkClick: (addLinkClickRequest: RecipeAddLinkClickRequest) => void
    updateCartItems: typeof updateCartItems
    updateCartResult: ShoppingCartResponse
    getCartItems: typeof getCartItems
    getCartItemsResult: ShoppingCartResponse
    getCartItemsResultLoading: LoadingState
    getCartItemsResultError: any
    saveLike: (addLikeRequest: RecipeAddLikeRequest) => void
    likeResult: RecipeAddLikeResponse
    likeError?: any
    likeLoading: LoadingState
    getClickCounts: (filters: any) => void
    clickCountsResult: any
}

const FavoritePanel: FunctionComponent<IFavoritePanelProps> = ({
    getFavorites,
    getFavoriteLoading,
    getFavoriteResult,
    getFavoriteError,
    downloadCatalog,
    downloadCatalogResult,
    downloadCatalogLoading,
    downloadCatalogError,
    submitFeedback,
    feedbackResult,
    feedbackLoading,
    feedbackError,
    saveFavorite,
    favoriteResult,
    favoriteError,
    favoriteLoading,
    saveStarRating,
    saveRatingResult,
    saveRatingError,
    saveVoting,
    saveVotingResult,
    saveVotingError,
    getRecipeById,
    saveLinkClick,
    updateCartItems,
    updateCartResult,
    getCartItems,
    getCartItemsResult,
    getCartItemsResultLoading,
    getCartItemsResultError,
    saveLike,
    likeResult,
    likeError,
    likeLoading,
    getClickCounts,
    clickCountsResult,
}: IFavoritePanelProps) => {
    var errorText = null
    if (getFavoriteError) {
        errorText = 'We are experiencing issues retrieving the data.'
    }
    const { currPage, afterPageClicked, handlePageCount, pageCount } =
        FavoritePanelLogic({
            getFavorites,
            getFavoriteResult,
        } as IFavoritePanelProps)
    let empStatus = localStorage.getItem('isIntelEmployee')
    let isIntelEmployee = !StringUtils.isNullOrUndefinedOrEmpty(empStatus)
        ? Boolean(empStatus)
        : false

    const favoritesList = getFavoriteResult?.recipes
        ? [...getFavoriteResult?.recipes]
        : undefined

    const totalPage = getFavoriteResult?.pageInfo.total
        ? getFavoriteResult?.pageInfo.total
        : -1
    const nextPage = getFavoriteResult?.pageInfo.next
        ? getFavoriteResult?.pageInfo.next
        : -1

    let resultCountContainer = (
        <ShowingPageContainer>
            {totalPage > 0 ? (
                <span>
                    {' '}
                    Showing {(currPage - 1) * pageCount + 1} to{' '}
                    {currPage * pageCount < totalPage
                        ? currPage * pageCount
                        : totalPage}{' '}
                    of {totalPage} results{' '}
                </span>
            ) : (
                ''
            )}
            {totalPage > 10 && totalPage >= nextPage ? (
                <PageCountSelect
                    size="sm"
                    onChange={handlePageCount}
                    value={pageCount}
                >
                    <option value={10}>10</option>
                    {totalPage >= 10 ? <option value={20}>20</option> : ''}
                    {totalPage > 20 ? <option value={50}>{50}</option> : ''}
                    {totalPage >= 50 ? <option value={100}>{100}</option> : ''}
                </PageCountSelect>
            ) : (
                ''
            )}
        </ShowingPageContainer>
    )

    let NoDataFoundContainer = (
        <div>
            {favoritesList !== undefined && favoritesList?.length <= 0 ? (
                <NoDataAvailableContainer>
                    <NoDataAvailableLabel>
                        No favorites data found!
                    </NoDataAvailableLabel>
                </NoDataAvailableContainer>
            ) : (
                ''
            )}
        </div>
    )

    return (
        <FavoriteFormContainer>
            <FavoriteFormComponent>
                <AlertComponent
                    variant="danger"
                    show={getFavoriteError ? true : false}
                >
                    {errorText}
                </AlertComponent>
            </FavoriteFormComponent>
            {getFavoriteLoading !== LoadingState.Pending ? (
                <div>
                    {NoDataFoundContainer}
                    <FeaturedComponent>
                        <FavoriteResultComponent>
                            {resultCountContainer}
                            <FavoriteCardPanelContainer>
                                <RecipeCardPanel
                                    isFavoritesLoading={true}
                                    recipeResult={getFavoriteResult}
                                    downloadCatalog={downloadCatalog}
                                    downloadCatalogResult={
                                        downloadCatalogResult
                                    }
                                    downloadCatalogLoading={
                                        downloadCatalogLoading
                                    }
                                    downloadCatalogError={downloadCatalogError}
                                    submitFeedback={submitFeedback}
                                    feedbackResult={feedbackResult}
                                    feedbackError={feedbackError}
                                    feedbackLoading={feedbackLoading}
                                    saveFavorite={saveFavorite}
                                    favoriteResult={favoriteResult}
                                    favoriteError={favoriteError}
                                    favoriteLoading={favoriteLoading}
                                    saveStarRating={saveStarRating}
                                    saveRatingResult={saveRatingResult}
                                    saveRatingError={saveRatingError}
                                    saveVoting={saveVoting}
                                    saveVotingResult={saveVotingResult}
                                    saveVotingError={saveVotingError}
                                    getRecipeById={getRecipeById}
                                    saveLinkClick={saveLinkClick}
                                    updateCartItems={updateCartItems}
                                    updateCartResult={updateCartResult}
                                    getCartItems={getCartItems}
                                    getCartItemsResult={getCartItemsResult}
                                    getCartItemsResultLoading={
                                        getCartItemsResultLoading
                                    }
                                    getCartItemsResultError={
                                        getCartItemsResultError
                                    }
                                    resultLayoutView={
                                        LayoutViewPreference.GalleryView
                                    }
                                    isIntelEmployee={isIntelEmployee}
                                    saveLike={saveLike}
                                    likeResult={likeResult}
                                    likeError={likeError}
                                    likeLoading={likeLoading}
                                    getClickCounts={getClickCounts}
                                    clickCountsResult={clickCountsResult}

                                ></RecipeCardPanel>
                            </FavoriteCardPanelContainer>
                        </FavoriteResultComponent>
                    </FeaturedComponent>
                    <PaginationComponent>
                        <PaginationPanelContainer>
                            {totalPage > 10 && totalPage >= nextPage ? (
                                <>
                                    <Pagination
                                        totalPage={Math.ceil(
                                            totalPage / pageCount
                                        )}
                                        currentPage={currPage}
                                        onPageClicked={(ele: any) => {
                                            afterPageClicked(ele)
                                        }}
                                    />
                                </>
                            ) : (
                                <div></div>
                            )}
                        </PaginationPanelContainer>
                    </PaginationComponent>
                </div>
            ) : (
                <SpinnerDiv>
                    <Spinner animation="border" variant="dark">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </SpinnerDiv>
            )}
        </FavoriteFormContainer>
    )
}

export default FavoritePanel
