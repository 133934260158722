import DashboardCard from '../shared/cards/DashboardCard'
import { useAppSelector } from '../../../config/hooks'
import { useEffect, useState } from 'react'
import { ColumnChartData } from '../shared/charts/ColumnChart'
import ColumnChart from '../shared/charts/ColumnChart'

const searchHitsChartHeaders: [string, string, { role: string }] = [
    'Search',
    'Count',
    { role: 'annotation' },
]

const SearchHitsCard = () => {
    const hitsData = useAppSelector<any>(
        (state: any) => state.getTotalHitsResult.data
    )
    const hitsLoading = useAppSelector<any>(
        (state: any) => state.getTotalHitsResult.loading
    )
    const noHitsData = useAppSelector<any>(
        (state: any) => state.getTotalNoHitsResult.data
    )
    const noHitsLoading = useAppSelector<any>(
        (state: any) => state.getTotalNoHitsResult.loading
    )

    const [chartData, setChartData] = useState<ColumnChartData>([
        searchHitsChartHeaders,
    ])

    useEffect(() => {
        if (
            hitsData !== null &&
            hitsData?.Total &&
            noHitsData !== null &&
            noHitsData?.Total
        ) {
            setChartData([
                searchHitsChartHeaders,
                ['Hits', hitsData.Total, hitsData.Total],
                ['No Hits', noHitsData.Total, noHitsData.Total],
            ])
        }
    }, [hitsData, noHitsData])

    return (
        <DashboardCard title="Search Hits">
            <ColumnChart
                chartData={chartData}
                dataLoadingState={[hitsLoading, noHitsLoading]}
            />
        </DashboardCard>
    )
}

export default SearchHitsCard
