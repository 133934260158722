import { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { KeyboardEvent } from 'react'
import { useAppDispatch, useAppSelector } from '../../config/hooks'
import { getFiltersValues } from '../../store/catalog.slice'
import { getAuthPermissions } from '../../store/authPermissions.slice'
import { getDefaultWorkloads } from '../../store/benchmark.slice'
import { UserSalesPermissions } from '../../models/userRoles'

export function AllWorkloadsLogic() {
    const dispatch = useAppDispatch()

    const [searchKey, setSearchKey] = useState<string>('')
    const [originalWorkloads, setOriginalWorkloads] = useState<any>(null)
    const [workloads, setWorkloads] = useState<any>(null)
    const [selectedAccessLevel, setSelectedAccessLevel] = useState<string>('')
    const [categoryDisplayGroup, setCategoryDisplayGroup] = useState<number>(1)
    const [selectedFiltersValue, setSelectedFiltersValue] = useState<string[]>(
        []
    )

    let navigate = useNavigate()

    const getData = () => {
        console.log(selectedFiltersValue)
        dispatch(
            getDefaultWorkloads({
                count: 100,
                offset: 0,
                accessGroup: getSelectedAccessGroup(),
                searchValue: searchKey,
                tagValues: selectedFiltersValue,
            })
        )
    }

    useEffect(() => {
        dispatch(getAuthPermissions())
        getData()
        dispatch(
            getFiltersValues(
                'filter=GMO Use Case&filter=Platform&filter=Feature&filter=Scale level&filter=Type&filter=CSP'
            )
        )
    }, [])

    const onRecentSearchClick = useCallback(
        (event: FormEvent<HTMLElement>, workloadName: string) => {
            event.preventDefault()
            navigate('/benchmark?workload=' + workloadName)
        },
        []
    )
    const getSelectedAccessGroup = () => {
        let accessGroupFilters = ''
        if (selectedAccessLevel === UserSalesPermissions.InternalAll)
            accessGroupFilters = '&access-group=' + 'internal'
        else if (selectedAccessLevel) {
            accessGroupFilters =
                '&access-group=' + selectedAccessLevel.toLowerCase()
        }

        return accessGroupFilters
    }

    const onAccessApplyChange = useCallback((selectedAccessLevel: string) => {
        setSelectedAccessLevel(selectedAccessLevel)
    }, [])

    useEffect(() => {
        if (selectedAccessLevel || selectedFiltersValue) {
            getData()
        }
    }, [selectedAccessLevel, selectedFiltersValue])

    const onTopSearchKeyChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLTextAreaElement
            if (target.value != undefined) {
                setSearchKey(target.value)
                if (target.value === '') {
                    //reset search refresh data for empty search
                    getData()
                }
            }
        },
        []
    )

    const onSearchKeydown = (eventKey: string) => {
        if (eventKey === 'Enter') {
            getData()
        }
    }
    const onCategoryNextClick = useCallback(
        (event: FormEvent<HTMLElement>) => {
            event.preventDefault()
            if (categoryDisplayGroup === 1) setCategoryDisplayGroup(2)
            else if (categoryDisplayGroup === 2) setCategoryDisplayGroup(1)
        },
        [categoryDisplayGroup]
    )
    const onHeaderFilterClick = useCallback(
        (selectedFiltersValue: string[], count: number) => {
            setSelectedFiltersValue(selectedFiltersValue)
        },
        []
    )
    const onHeaderFilterClearAllClick = useCallback(
        (selectedFiltersValue: string[], count: number) => {
            setSelectedFiltersValue([])
        },
        []
    )

    return {
        onRecentSearchClick,
        workloads,
        onTopSearchKeyChange,
        onSearchKeydown,
        onAccessApplyChange,
        categoryDisplayGroup,
        onCategoryNextClick,
        onHeaderFilterClick,
        onHeaderFilterClearAllClick,
    }
}
