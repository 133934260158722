import { API_SERVICE_URL } from '../config/service'
import api from './auth/client'
const serviceConfig = require('../config/service.json')

class AuthPermissionsService {
    getAuthPermission() {
        var url = API_SERVICE_URL + serviceConfig.getAuthPermissionURL
        return api.get(url)
    }
}
export default new AuthPermissionsService()
