import { API_SERVICE_URL } from '../config/service'
import { SaveLicenseRequest } from '../models/saveLicenseRequest'

import api from './auth/client'

const serviceConfig = require('../config/service.json')

class LicenseService {
    getLicense(id: string) {
        var url =
            API_SERVICE_URL + serviceConfig.getRecipeURL + `/${id}/license`
        return api.get(url)
    }
    saveLicenseAcceptenceDecision(saveLicenseRequest: SaveLicenseRequest) {
        var url =
            API_SERVICE_URL +
            serviceConfig.getRecipeURL +
            `/${saveLicenseRequest.uuid}` +
            '/usage-terms/acceptance'
        //post with empty body
        return api.post(url, {})
    }
}

export default new LicenseService()
