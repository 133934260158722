import { FunctionComponent } from 'react'
import styled from 'styled-components'
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { useNavigate } from 'react-router-dom'

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: right;
    width: 100%;
    padding-right: 2rem;
    background-color: #fff;
`
const HeaderMenuDiv = styled.div`
    display: flex;
    justify-content: left;
    width: 20%;
    padding-right: 1rem;
`
const LinkPanelContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-direction: space-between;
    justify-content: center;
    background-color: ${(props) => props.theme.color.background.defaultWhite};
    width: 8rem;
    max-width: 20rem;
    min-width: 8rem;
    flex-grow: 1;
`

const LinkPanelBodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: ${(props) => props.theme.color.background.defaultWhite};
    flex-grow: 1;
    margin-top: 2rem;
`
const IntelLogoStyling = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.5rem 2rem;
`
const TopLink = styled.a`
    font-family: 'IntelOne Display';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-decoration-line: underline;
    color: #808080;
    padding-top: 0.2rem;
    margin: 1rem 2rem;
`
const NavbarToggle = styled(Navbar.Toggle)`
    .navbar-light .navbar-toggler-icon {
        background-image: ${(props) =>
            `url('${process.env.PUBLIC_URL}/ic_menu.png')`}!important;
        border: none;
        width: 2rem;
        height: 2rem;
    }
`
const LoginButton = styled.button`
    font-family: 'IntelOne Text';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    text-decoration: none;
    color: #000;
    background-color: #78deff;
    border: none;
    min-width: 8.5rem;
    margin: 1rem;
`
const GetStartedButton = styled(LoginButton)`
    color: #fff;
    background-color: #8f5da2;
`

const MobilePageLinksPanel = (navigate: any) => (
    <LinkPanelContainer>
        <IntelLogoStyling>
            <img
                src="/Intel_energyblue_logo.png"
                height="35"
                width="76"
                alt="intel logo"
            />
        </IntelLogoStyling>
        <LinkPanelBodyContainer>
            <GetStartedButton onClick={() => navigate('/public/catalog')}>
                Get Started
            </GetStartedButton>
            <LoginButton onClick={() => navigate('/signIn')}>Login</LoginButton>
            <TopLink
                href="https://www.intel.com/content/www/us/en/developer/topic-technology/cloud/tools.html"
                target="_blank"
            >
                Tools
            </TopLink>
            <TopLink
                href="https://www.intel.com/content/www/us/en/developer/topic-technology/cloud/cloud-performance.html"
                target="_blank"
            >
                Benchmarks
            </TopLink>
            <TopLink
                href="https://www.intel.com/content/www/us/en/developer/topic-technology/cloud/library.html"
                target="_blank"
            >
                Resources
            </TopLink>
            <TopLink href="mailto:optimizations@intel.com">Contact</TopLink>
        </LinkPanelBodyContainer>
    </LinkPanelContainer>
)

export const MobileHeader: FunctionComponent = ({}) => {
    let navigate = useNavigate()
    const offCanvasNav = () => {
        return (
            <>
                {[false].map((expand) => (
                    <Navbar expand={expand} id="mobileNavbar">
                        <Container fluid>
                            <NavbarToggle
                                style={{
                                    backgroundColor:
                                        'linear-gradient(180deg, #0d1b35, #1e3a84)',
                                }}
                                aria-controls={`offcanvasNavbar-expand-${expand}`}
                            />
                            <Navbar.Offcanvas
                                id={`offcanvasNavbar-expand-${expand}`}
                                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                                placement="end"
                                style={{ width: '200px' }}
                                variant="dark"
                            >
                                <Offcanvas.Header closeButton>
                                    <Offcanvas.Title
                                        id={`offcanvasNavbarLabel-expand-${expand}`}
                                    ></Offcanvas.Title>
                                </Offcanvas.Header>
                                <Offcanvas.Body>
                                    {MobilePageLinksPanel(navigate)}
                                </Offcanvas.Body>
                            </Navbar.Offcanvas>
                        </Container>
                    </Navbar>
                ))}
            </>
        )
    }
    return (
        <HeaderContainer>
            <HeaderMenuDiv>{offCanvasNav()}</HeaderMenuDiv>
        </HeaderContainer>
    )
}

export default MobileHeader
