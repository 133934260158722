import { FunctionComponent } from 'react'
import { Button, FormLabel } from 'react-bootstrap'
import styled from 'styled-components'
import { BenchmarkCardLogic } from './benchmarkCard.hook'
import { _getDecryptedValueFromStorage } from '../../lib/encryptStorage'
import { workloadData } from '../../models/benchmarkSearchResponse'
import StringUtils from '../../lib/stringUtils'

const BenchmarkCardContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0.6rem 0.6rem;
    &:hover {
        transform: scale(1.03);
        transition: 0.5s;
    }
`
const SalesViewContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background: ${(props) => props.theme.color.background.defaultWhite};
    width: 18rem;
    height: 26rem;
`
const DevViewContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background: ${(props) => props.theme.color.background.defaultWhite};
    width: 16rem;
    height: 22rem;
`

const CardImageHorizonalComponent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 8rem;
`

const CardNameComponent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: 0.5rem;
    padding: 0.5rem;
    height: 8rem;
`
const CardNameLabel = styled(FormLabel)`
    font-family: 'IntelOne Display';
    font-style: normal;
    text-align: left;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #0054ae;
`
const CardDescriptionComponent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: left;
    width: 100%;
`
const CardDescriptionLabel = styled(FormLabel)`
    display: flex;
    width: 100%;
    justify-content: center;
    text-align: center;
    padding: 0.5rem;
    font-family: 'IntelOne Display';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
`
const BenchmarkTagComponent = styled.div`
    //display: inline-flex;
    // padding: 0.25rem 0.5rem;
    // height: 2.5rem;
    // align-items: center;
    // gap: 0.25rem;
    // background-color: #00285a;
    // color: white;
    // font-weight: 500;
    // line-height: 1rem;
    // letter-spacing: -0.02rem;
    // width: 9.5rem;
`
const CSPBrandComponent = styled.div`
    display: flex;
    vertical-align: middle;
    padding-top: 0.5rem;
    padding-left: 1.5rem;
    height: 3rem;
`
const CardInnerImage = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 0.6rem;
`
const BenchmarkSalesCardColumn = styled.div`
    display: flex;
    flex-direction: column;
    -webkit-box-pack: justify;
    justify-content: space-between;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px;
    background: rgb(255, 255, 255);
    width: 18rem;
    height: 26rem;
`
const BenchmarkCardColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: left;
`
const CardWrapperButton = styled(Button)`
    display: flex;
    background: #fff;
    border: none;
    padding: 0;
    margin: 0;

    &:hover {
        background: #fff;
    }

    &:focus {
        background: #fff;
    }
`
const WinningWorkloadCardButton = styled(Button)`
    display: flex;
    padding: 0;
    margin: 0;

    /* A transparent border, so the very edge of the button shows through */
    border: 4px solid transparent;
    background-image: linear-gradient(
            28.85deg,
            rgba(0, 104, 181, 0.5) 9.76%,
            #0099ec 31.4%,
            rgba(255, 255, 255, 0.15) 117.29%
        ),
        linear-gradient(0deg, #e2e2e4, #e2e2e4);
    /* Fill the inside with white */
    background-origin: border-box;
    box-shadow: inset 0 100vw white;

    &:hover {
        /* A transparent border, so the very edge of the button shows through */
        border: 4px solid transparent;
        background-image: linear-gradient(
                28.85deg,
                rgba(0, 104, 181, 0.5) 9.76%,
                #0099ec 31.4%,
                rgba(255, 255, 255, 0.15) 117.29%
            ),
            linear-gradient(0deg, #e2e2e4, #e2e2e4);
        /* Fill the inside with white */
        background-origin: border-box;
        box-shadow: inset 0 100vw white;
    }

    &:focus {
        /* A transparent border, so the very edge of the button shows through */
        border: 4px solid transparent;
        background-image: linear-gradient(
                28.85deg,
                rgba(0, 104, 181, 0.5) 9.76%,
                #0099ec 31.4%,
                rgba(255, 255, 255, 0.15) 117.29%
            ),
            linear-gradient(0deg, #e2e2e4, #e2e2e4);
        /* Fill the inside with white */
        background-origin: border-box;
        box-shadow: inset 0 100vw white;
    }
`

export interface IBenchmarkCardProps {
    workloadData?: workloadData
    urlNavigation?: string
}

const BenchmarkCard: FunctionComponent<IBenchmarkCardProps> = ({
    workloadData,
    urlNavigation,
}: IBenchmarkCardProps) => {
    const { onBenchmarkCardClick } = BenchmarkCardLogic({
        workloadData,
        urlNavigation,
    } as IBenchmarkCardProps)

    const queryParameters = new URLSearchParams(location.search)
    const isDevViewValue = queryParameters.get('isDevView')

    const isDevView =
        location.pathname.indexOf('/benchmarks') > -1 ||
        (isDevViewValue && isDevViewValue === 'true')
            ? true
            : false

    const isSalesView =
        location.pathname.indexOf('/salesview/home') > -1 ? true : false

    const benchmarkName = workloadData?.name ?? 'Workload'
    const WorkloadCategoryName = workloadData?.workloadCategory
    const WorkloadCategoryParam = workloadData?.workloadCategory
        ? `&workloadCategory=${encodeURIComponent(
              workloadData?.workloadCategory
          )}`
        : ''
    const isWinningWorkload =
        workloadData &&
        workloadData.tags &&
        workloadData?.tags['selling prioritization'] &&
        workloadData?.tags['selling prioritization'][0] &&
        (workloadData?.tags['selling prioritization'][0] === 'Priority' ||
            workloadData?.tags['selling prioritization'][0] === 'Prioritized')
            ? true
            : false
    const onPremTag = (
        <img src="/sales_img/tag-onprem.png" alt="product tag logo" />
    )
    const cloudTag = (
        <img src="/sales_img/tag-cloud.png" alt="product tag logo" />
    )
    const swOptimizationTag = (
        <img src="/sales_img/tag-sw-optimizations.png" alt="product tag logo" />
    )
    const partnerSolutionTag = (
        <img
            src="/sales_img/tag-partner-solutions.png"
            alt="product tag logo"
        />
    )
    const custSuccessTag = (
        <img
            src="/sales_img/tag-customer-success-study.png"
            alt="product tag logo"
        />
    )
    const renderBenchmarkCardTag = (tagName: string, groupType: string) => {
        return (
            <div style={{ textAlign: 'left' }}>
                {tagName === 'Benchmark' && groupType === 'On-Prem'
                    ? onPremTag
                    : tagName === 'Benchmark' && groupType === 'Cloud'
                    ? cloudTag
                    : tagName === 'SW Optimization'
                    ? swOptimizationTag
                    : tagName === 'Partner Solution'
                    ? partnerSolutionTag
                    : tagName === 'Customer Case Study'
                    ? custSuccessTag
                    : ''}
            </div>
        )
    }
    const gcpBrandImage = (
        <img
            src="/gcp.png"
            alt="product tag logo"
            style={{
                width: '45px',
                height: '45px',
            }}
        />
    )
    const azureBrandImage = (
        <img
            src="/azure.png"
            alt="product tag logo"
            style={{
                width: '45px',
                height: '45px',
            }}
        />
    )
    const awsBrandImage = (
        <img
            src="/aws.png"
            alt="product tag logo"
            style={{
                width: '45px',
                height: '45px',
            }}
        />
    )
    const alibabaBrandImage = (
        <img
            src="/alibaba.png"
            alt="product tag logo"
            style={{
                width: '45px',
                height: '45px',
            }}
        />
    )
    const winningBageImage = (
        <img
            src={StringUtils.resolve('/sales_img/badge.png')}
            alt="Priority badge logo"
            style={{
                width: '32px',
                height: '32px',
                marginTop: '-1rem',
            }}
        />
    )

    const renderBenchmarkCSPImage = (cspName: string) => {
        return (
            <CSPBrandComponent
                style={
                    isDevView ? { justifyContent: 'right', height: '1rem' } : {}
                }
            >
                {cspName.toUpperCase().indexOf('GCP') > -1
                    ? gcpBrandImage
                    : cspName.toUpperCase().indexOf('AWS') > -1
                    ? awsBrandImage
                    : cspName.toUpperCase().indexOf('AZURE') > -1
                    ? azureBrandImage
                    : cspName.toUpperCase().indexOf('ALIBABA') > -1
                    ? alibabaBrandImage
                    : ''}
            </CSPBrandComponent>
        )
    }
    const renderCardProductLogo = (iconURL: string) => {
        return (
            <img
                src={`${iconURL}`}
                style={
                    isSalesView && iconURL.indexOf('default_workload.png') > -1
                        ? {
                              marginTop: '1.5rem',
                              marginBottom: '1.5rem',
                              maxHeight: '120px',
                              maxWidth: '240px',
                          }
                        : isSalesView && iconURL.indexOf('golang.png') > -1
                        ? {
                              marginTop: '0.5rem',
                              marginBottom: '0.5rem',
                              maxHeight: '95px',
                              maxWidth: '200px',
                          }
                        : isDevView
                        ? {
                              marginTop: '0.2rem',
                              marginBottom: '0',
                              maxHeight: '90px',
                              maxWidth: '150px',
                          }
                        : {
                              marginTop: '0.5rem',
                              marginBottom: '0.5rem',
                              maxHeight: '140px',
                              maxWidth: '240px',
                          }
                }
            />
        )
    }
    const salesViewBenchmarkCardPanel = (
        <BenchmarkCardColumn>
            <div style={{ display: 'inline-flex' }}>
                <BenchmarkTagComponent>
                    {workloadData?.assetType
                        ? renderBenchmarkCardTag(
                              workloadData?.assetType,
                              workloadData?.groupType
                          )
                        : ''}
                </BenchmarkTagComponent>
                {workloadData?.group
                    ? renderBenchmarkCSPImage(workloadData?.group)
                    : ''}

                {isWinningWorkload ? winningBageImage : ''}
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'left',
                    margin: '0.5rem',
                }}
            >
                <CardNameComponent>
                    <CardNameLabel>
                        {benchmarkName && benchmarkName.length > 110
                            ? benchmarkName.slice(0, 110) + '...'
                            : benchmarkName}
                    </CardNameLabel>
                </CardNameComponent>
                <CardImageHorizonalComponent>
                    {workloadData?.iconURL
                        ? renderCardProductLogo(workloadData?.iconURL)
                        : ''}
                </CardImageHorizonalComponent>
                <CardDescriptionComponent>
                    <CardDescriptionLabel>
                        {workloadData && workloadData.workload
                            ? workloadData?.workload
                            : WorkloadCategoryName}
                    </CardDescriptionLabel>
                </CardDescriptionComponent>
            </div>
        </BenchmarkCardColumn>
    )
    const devViewBenchmarkCardPanel = (
        <BenchmarkCardColumn>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'left',
                    margin: '0.5rem',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'right',
                    }}
                >
                    {workloadData?.group
                        ? renderBenchmarkCSPImage(workloadData?.group)
                        : ''}
                </div>
                <CardImageHorizonalComponent style={{ height: '6rem' }}>
                    {workloadData?.iconURL
                        ? renderCardProductLogo(workloadData?.iconURL)
                        : ''}
                </CardImageHorizonalComponent>

                <CardNameComponent
                    style={{
                        height: 'auto',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <CardNameLabel
                        style={{
                            justifyContent: 'center',
                            textAlign: 'center',
                        }}
                    >
                        {workloadData && workloadData.workload
                            ? workloadData?.workload
                            : WorkloadCategoryName}
                    </CardNameLabel>
                </CardNameComponent>
                <CardNameComponent>
                    <CardDescriptionLabel>
                        {benchmarkName && benchmarkName.length > 110
                            ? benchmarkName.slice(0, 110) + '...'
                            : benchmarkName}
                    </CardDescriptionLabel>
                </CardNameComponent>
            </div>
        </BenchmarkCardColumn>
    )

    return (
        <BenchmarkCardContainer>
            {isDevView ? (
                <CardWrapperButton
                    href={`/benchmark/${workloadData?.uuid}?workload=${workloadData?.workload}${WorkloadCategoryParam}&benchmarkGroup=${workloadData?.group}&benchmarkGroupType=${workloadData?.groupType}&assetType=${workloadData?.assetType}&nav=${urlNavigation}&isDevView=${isDevView}`}
                    style={{ cursor: 'pointer' }}
                    tabIndex={0}
                >
                    <DevViewContainer>
                        {devViewBenchmarkCardPanel}
                    </DevViewContainer>
                </CardWrapperButton>
            ) : isWinningWorkload ? (
                <WinningWorkloadCardButton
                    href={`/benchmark/${workloadData?.uuid}?workload=${workloadData?.workload}${WorkloadCategoryParam}&benchmarkGroup=${workloadData?.group}&benchmarkGroupType=${workloadData?.groupType}&assetType=${workloadData?.assetType}&nav=${urlNavigation}&isDevView=${isDevView}`}
                    style={{ cursor: 'pointer' }}
                    tabIndex={0}
                >
                    <SalesViewContainer>
                        {salesViewBenchmarkCardPanel}
                    </SalesViewContainer>
                </WinningWorkloadCardButton>
            ) : (
                <CardWrapperButton
                    href={`/benchmark/${workloadData?.uuid}?workload=${workloadData?.workload}${WorkloadCategoryParam}&benchmarkGroup=${workloadData?.group}&benchmarkGroupType=${workloadData?.groupType}&assetType=${workloadData?.assetType}&nav=${urlNavigation}&isDevView=${isDevView}`}
                    style={{ cursor: 'pointer' }}
                    tabIndex={0}
                >
                    <SalesViewContainer>
                        {salesViewBenchmarkCardPanel}
                    </SalesViewContainer>
                </CardWrapperButton>
            )}
        </BenchmarkCardContainer>
    )
}

export default BenchmarkCard
