import { useState, useCallback, FormEvent, useEffect, ChangeEvent } from 'react'
import { useAppDispatch, useAppSelector } from '../../config/hooks'
import { IRecipeCardProps } from './recipeCard'
import { LoadingState } from '../../models/loadingState'
import { RecipeFeedbackRequest } from '../../models/recipeFeedbackRequest'
import { RecipeAddFavoriteRequest } from '../../models/recipeAddFavoriteRequest'
import { RecipeStarRatingRequest } from '../../models/recipeStarRatingRequest'
import { RecipeAddVotingRequest } from '../../models/recipeAddVotingRequest'
import { RecipeAddLinkClickRequest } from '../../models/recipeAddLinkClickRequest'
import { UpdateShoppingCartRequest } from '../../models/updateShoppingCartRequest'
import { _generateRandom } from '../../lib/generate'
import { ProductTag } from '../../models/ProductTag'
import { ShoppingCartRequest } from '../../models/shoppingCartRequest'
import { RecipeAddLikeRequest } from '../../models/recipeAddLikeRequest'
import {
    getLicense,
    saveLicenseDecision,
    saveLicenseDecisionResult,
} from '../../store/license.slice'
import { useNavigate } from 'react-router'
import { UserOtherMetaRoles, UserRoles } from '../../models/userRoles'
import { SubscriptionStatus } from '../../models/catalogSearchResponse'
import moment from 'moment'
import { _getDecryptedValueFromStorage } from '../../lib/encryptStorage'
import { SaveLicenseRequest } from '../../models/saveLicenseRequest'
import { LicenseDecision } from '../../models/licenseDecision'
import {
    addOptimizationSubscription,
    deleteOptimizationSubscription,
    feedbackAttachFile,
} from '../../store/catalog.slice'
import { RecipeAddSubscriptionRequest } from '../../models/recipeAddSubscriptionRequest'

export function RecipeCardLogic({
    recipeData,
    downloadCatalog,
    downloadCatalogResult,
    downloadCatalogLoading,
    downloadLinkRef,
    downloadCatalogError,
    feedbackFileInputRef,
    submitFeedback,
    saveFavorite,
    favoriteResult,
    favoriteLoading,
    saveStarRating,
    saveVoting,
    getRecipeById,
    saveLinkClick,
    updateCartItems,
    updateCartResult,
    getCartItems,
    getBenchmarks,
    feedbackResult,
    feedbackLoading,
    feedbackError,
    saveLike,
    likeResult,
    likeError,
    likeLoading,
    isPublicSearch,
    organizationalRoles,
    getClickCounts,
}: IRecipeCardProps) {
    const dispatch = useAppDispatch()
    const [showReadmePopup, setShowReadmePopup] = useState(false)
    const [showBenchMark, setShowBenchMark] = useState(false)
    const [showProductPerformance, setShowProductPerformance] = useState(false)
    const [isDownloadClicked, setDownloadClick] = useState<boolean>(false)
    const [fileDownloadUrl, setfileDownloadUrl] = useState<string>('')
    const [downloadFileName, setDownloadFileName] = useState<string>('')
    const [isReadMoreLoad, setIsReadMoreLoad] = useState(false)
    const [showFeedbackPopup, setShowFeedbackPopup] = useState(false)
    const [feedbackSelectedFileName, setFeedbackSelectedFileName] = useState('')
    const [selectedFiles, setSelectedFiles] = useState<FileList>()
    const [feedbackText, setFeedbackText] = useState('')
    const [isFeedbackLoad, setIsFeedbackLoad] = useState(false)
    const [validated, setValidated] = useState(false)
    const [feedbackPriority, setFeedbackPriority] = useState<string>('Low')
    const [feedbackDate, setFeedbackDate] = useState<string>('')
    const [isFavorite, setIsFavorite] = useState<boolean>(
        recipeData?.isFavorite ? recipeData.isFavorite : false
    )
    const [favoriteCount, setFavoriteCount] = useState<number>(
        recipeData?.favoriteCount ? recipeData.favoriteCount : 0
    )
    const [isLiked, setIsLiked] = useState<boolean>(
        recipeData?.isLiked ? recipeData.isLiked : false
    )
    const [isFavoriteClick, setIsFavoriteClick] = useState<boolean>(false)
    const [showVotePopup, setShowVotePopup] = useState(false)
    const [voteMessage, setVoteMessage] = useState('')
    const [starRating, setStarRating] = useState(
        recipeData?.userRating ? recipeData.userRating : 0
    )
    const [isVotingLoad, setIsVotingLoad] = useState(false)
    const [showGalleryReadme, setShowGalleryReadme] = useState(false)
    const [reduceImageSize, setReduceImageSize] = useState(false)
    const [isDownloadLicenseClick, setIsDownloadLicenseClick] = useState(false)
    const [hasLicenseAccepted, setHasLicenseAccepted] = useState(false)
    const ShowGalleryReadme = () => {
        setShowGalleryReadme(true)
        setReduceImageSize(true)
    }
    const CloseGalleryReadme = () => {
        setShowGalleryReadme(false)
        setReduceImageSize(false)
        if (isPublicSearch) localStorage.setItem('publicUrlRoute', '')
    }
    const handleFeedbackClose = () => setShowFeedbackPopup(false)
    const handleFeedbackShow = () => setShowFeedbackPopup(true)

    const handleVoteClose = () => setShowVotePopup(false)
    const handleVoteShow = () => setShowVotePopup(true)
    const [showLicensePopup, setShowLicensePopup] = useState(false)
    const handleLicensePopupClose = () => setShowLicensePopup(false)
    const [downloadFileDetails, setDownloadFileDetails] = useState<{
        fileName: string
        downLoadLink: string
    }>({ fileName: '', downLoadLink: '' })
    const [showLicenseConfirmation, setShowLicenseConfirmation] =
        useState(false)
    const handleCloseConfirmation = () => setShowLicenseConfirmation(false)

    const [
        showOptSubscriptionConfirmation,
        setShowOptSubscriptionConfirmation,
    ] = useState(false)
    const [isSubsCribedClicked, setIsSubsCribedClicked] = useState(false)
    const [isOptSubsCribed, setIsOptSubsCribed] = useState(
        recipeData?.isWatched
    )
    const handleOptSubsCloseConfirmation = () =>
        setShowOptSubscriptionConfirmation(false)

    let otherMetaPermissions: string[] = []
    const otherPermissions = _getDecryptedValueFromStorage(
        'otherMetaPermissions'
    )
    if (otherPermissions) {
        otherMetaPermissions = otherPermissions.split(',')
    }

    let isAnalyst = otherMetaPermissions.includes(UserOtherMetaRoles.Analyst)

    let navigate = useNavigate()

    const onDownloadClick = useCallback(
        (
            downLoadLink: string,
            fileName: string,
            licenseText: string,
            hasAcceptedUsageTerms: boolean,
            event: FormEvent<HTMLElement>
        ) => {
            setDownloadFileDetails({
                fileName: fileName,
                downLoadLink: downLoadLink,
            })
            if (
                hasAcceptedUsageTerms == false &&
                licenseText !== '' &&
                !hasLicenseAccepted
            ) {
                //show license popup
                setDownloadFileDetails({
                    fileName: fileName,
                    downLoadLink: downLoadLink,
                })
                setShowLicensePopup(true)
            } else {
                //proceed with download
                setDownloadFileName(fileName)
                if (downLoadLink) downloadCatalog(downLoadLink)
                setDownloadClick(true)
            }
        },
        [hasLicenseAccepted]
    )

    const handleAcceptLicenseClick = useCallback(
        (event: FormEvent<HTMLElement>) => {
            setHasLicenseAccepted(true)
            const saveLicenserequest = new SaveLicenseRequest()
            saveLicenserequest.uuid = recipeData?.uuid
            saveLicenserequest.desicion = LicenseDecision.Accepted
            dispatch(saveLicenseDecision(saveLicenserequest))
            setShowLicensePopup(false)
            setTimeout(() => setShowLicenseConfirmation(true), 500)
        },
        []
    )
    const handleRejectLicenseClick = useCallback(
        (event: FormEvent<HTMLElement>) => {
            setShowLicensePopup(false)
        },
        []
    )
    const saveLicenseAcceptanceResult = useAppSelector<any>(
        (state) => state.saveLicenseDecisionResult
    )

    const onLicenseConfirmationClick = useCallback(
        (event: FormEvent<HTMLElement>) => {
            //proceed with download
            setDownloadFileName(downloadFileDetails.fileName)
            if (downloadFileDetails.downLoadLink)
                downloadCatalog(downloadFileDetails.downLoadLink)
            setDownloadClick(true)
            handleCloseConfirmation()
        },
        [downloadFileDetails]
    )

    useEffect(() => {
        if (downloadCatalogResult && isDownloadClicked) {
            if (downloadCatalogLoading !== LoadingState.Pending) {
                if (
                    downloadCatalogResult.data !== undefined &&
                    downloadCatalogError === null
                ) {
                    const fileType = downloadFileName.substring(
                        downloadFileName.length - 3
                    )
                    const blob = new Blob([downloadCatalogResult.data], {
                        type: `application/${fileType}`,
                    })
                    const fileDownloadUrl = URL.createObjectURL(blob)
                    setfileDownloadUrl(fileDownloadUrl)
                    if (downloadLinkRef.current !== undefined) {
                        downloadLinkRef.current.href = fileDownloadUrl
                        downloadLinkRef.current.download = downloadFileName
                        downloadLinkRef.current.click() //download file
                    }
                } else {
                    setIsReadMoreLoad(false)
                }
                URL.revokeObjectURL(fileDownloadUrl)
                setfileDownloadUrl('')
                setDownloadClick(false)
            }
        }
    }, [downloadCatalogResult, isDownloadClicked, downloadCatalogLoading])

    const onReadMoreClick = useCallback(
        (event: FormEvent<HTMLElement>) => {
            event.preventDefault()
            setIsReadMoreLoad(true)
            if (showReadmePopup) {
                setShowReadmePopup(false)
            } else {
                setShowReadmePopup(true)
                //service call to capture recipe view count
                if (recipeData?.uuid !== undefined && recipeData?.uuid !== '')
                    getRecipeById(recipeData?.uuid)
            }
        },
        [showReadmePopup]
    )

    const onShowBenchmarkClick = useCallback(
        (event: FormEvent<HTMLElement>, id: string) => {
            event.preventDefault()
            dispatch(getBenchmarks(id))
            if (showBenchMark) {
                setShowBenchMark(false)
            } else {
                setShowBenchMark(true)
            }
        },
        [showBenchMark]
    )

    const onFeedbackButtonClick = useCallback(
        (event: FormEvent<HTMLElement>) => {
            event.preventDefault()
            if (!isPublicSearch) {
                setShowFeedbackPopup(true)
                setFeedbackSelectedFileName('')
                //setFileBase64String('')
                setSelectedFiles(undefined)
                setValidated(false)
                handleFeedbackShow()
                setIsFeedbackLoad(true)
                setFeedbackPriority('')
                setFeedbackDate('')
                setFeedbackText('')
            } else {
                navigate('/signIn')
            }
        },
        []
    )

    const onFeedbackAttachClick = useCallback(
        (event: FormEvent<HTMLElement>) => {
            event.preventDefault()
            if (feedbackFileInputRef.current)
                feedbackFileInputRef.current.click()
        },
        []
    )
    const onFeedbackFileChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLInputElement

            if (target.files) {
                setFeedbackSelectedFileName(target.files[0].name)
                setSelectedFiles(target.files)
            }
        },
        []
    )
    const onFeedbackTextChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLTextAreaElement
            if (target.value) {
                setFeedbackText(target.value)
            }
        },
        []
    )
    const onPrioritySelectChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLSelectElement
            if (target.value) {
                setFeedbackPriority(target.value)
            }
        },
        []
    )
    const onFeedbackDateChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLDataElement
            if (target.value) {
                setFeedbackDate(target.value)
            }
        },
        []
    )

    const onFeedbackFormSubmit = useCallback(
        (event: FormEvent<HTMLFormElement>) => {
            event.preventDefault()

            const form = event.currentTarget
            if (form.checkValidity() === false) {
                event.preventDefault()
                event.stopPropagation()
            }
            setValidated(true)

            if (feedbackText) {
                const randomID = _generateRandom(18)
                //Service Call for submit feedback
                var feedbackRequest = new RecipeFeedbackRequest()
                feedbackRequest.requestType = 'requestSupport'
                feedbackRequest.additionalProperties.appName =
                    recipeData?.appName
                feedbackRequest.additionalProperties.uuid = recipeData?.uuid
                feedbackRequest.additionalProperties.feedback = feedbackText
                feedbackRequest.additionalProperties.fileName =
                    feedbackSelectedFileName
                feedbackRequest.additionalProperties.priority = feedbackPriority
                    ? feedbackPriority
                    : 'Low'
                feedbackRequest.additionalProperties.date = feedbackDate
                feedbackRequest.additionalProperties.randomID = randomID
                if (feedbackRequest.additionalProperties.uuid) {
                    //console.log(feedbackRequest)
                    submitFeedback(feedbackRequest)
                    setIsFeedbackLoad(false)
                }
            }
        },
        [feedbackText, feedbackSelectedFileName, feedbackPriority, feedbackDate]
    )
    useEffect(() => {
        if (feedbackLoading !== LoadingState.Pending) {
            if (feedbackResult && !isFeedbackLoad) {
                if (
                    feedbackResult?.uuid !== undefined &&
                    selectedFiles &&
                    selectedFiles.length > 0
                ) {
                    const payload = {
                        id: feedbackResult.uuid,
                        file: selectedFiles,
                    }

                    dispatch(feedbackAttachFile(payload))
                } else {
                    setTimeout(() => setShowFeedbackPopup(false), 5000)
                }
            }
        }
    }, [feedbackResult, isFeedbackLoad, selectedFiles])
    const feedbackAttachResult = useAppSelector<any>(
        (state) => state.feedbackAttachFileResult.data
    )
    const feedbackAttachError = useAppSelector<any>(
        (state) => state.feedbackAttachFileResult.error
    )
    useEffect(() => {
        if (feedbackAttachResult) {
            setTimeout(() => setShowFeedbackPopup(false), 3000)
        }
    }, [feedbackAttachResult])

    const onFavoriteClick = useCallback((event: FormEvent<HTMLElement>) => {
        event.preventDefault()
        const target = event.currentTarget as HTMLButtonElement
        if (!isPublicSearch) {
            if (target.value) {
                let newFavorite = target.value === 'true' ? false : true
                setIsFavorite(newFavorite)
                var addFavoriteRequest = new RecipeAddFavoriteRequest()
                addFavoriteRequest.uuid = recipeData?.uuid
                    ? recipeData?.uuid
                    : ''
                addFavoriteRequest.favorite = newFavorite
                //service call to save favorite
                if (addFavoriteRequest.uuid) {
                    saveFavorite(addFavoriteRequest)
                }
                setIsFavoriteClick(true)
            }
        } else navigate('/signIn')
    }, [])
    const onLikeClick = useCallback((event: FormEvent<HTMLElement>) => {
        event.preventDefault()
        if (!isPublicSearch) {
            const target = event.currentTarget as HTMLButtonElement
            if (target.value) {
                let newLike = target.value === 'true' ? false : true
                setIsLiked(newLike)
                var addLikeRequest = new RecipeAddLikeRequest()
                addLikeRequest.uuid = recipeData?.uuid ? recipeData?.uuid : ''
                addLikeRequest.like = newLike
                //service call to save like
                if (addLikeRequest.uuid) {
                    saveLike(addLikeRequest)
                }
            }
        } else navigate('/signIn')
    }, [])

    const onVoteButtonClick = useCallback((event: FormEvent<HTMLElement>) => {
        event.preventDefault()
        handleVoteShow()
        setIsVotingLoad(true)
        setValidated(false)
        setVoteMessage('')
    }, [])

    const onVoteTextChange = useCallback((event: ChangeEvent<HTMLElement>) => {
        const target = event.currentTarget as HTMLTextAreaElement
        if (target.value) {
            setVoteMessage(target.value)
        }
    }, [])

    const onVoteFormSubmit = useCallback(
        (event: FormEvent<HTMLFormElement>) => {
            event.preventDefault()

            const form = event.currentTarget
            if (form.checkValidity() === false) {
                event.preventDefault()
                event.stopPropagation()
            }
            setValidated(true)
            if (voteMessage) {
                var votingRequest = new RecipeAddVotingRequest()
                votingRequest.uuid = recipeData?.uuid ? recipeData?.uuid : ''
                votingRequest.voteDetails = voteMessage
                if (votingRequest.uuid && votingRequest.voteDetails) {
                    saveVoting(votingRequest)
                    setIsVotingLoad(false)
                }
            }
        },
        [voteMessage]
    )

    const onStarRatingClick = useCallback((event: FormEvent<HTMLElement>) => {
        event.preventDefault()
        const target = event.currentTarget as HTMLButtonElement
        if (target.value !== '') {
            let newRating = Number(target.value)
            setStarRating(newRating)
            var addStarRatingRequest = new RecipeStarRatingRequest()
            addStarRatingRequest.uuid = recipeData?.uuid ? recipeData?.uuid : ''
            addStarRatingRequest.rating = newRating
            //service call to save rating
            if (addStarRatingRequest.uuid && addStarRatingRequest.rating) {
                saveStarRating(addStarRatingRequest)
            }
        }
    }, [])

    const onShareLinkClick = useCallback((event: FormEvent<HTMLElement>) => {
        event.preventDefault()
        const target = event.currentTarget as HTMLButtonElement
        if (target.value !== '') {
            navigator.clipboard.writeText(target.value)
            alert('Link copied to clipboard: ' + target.value)
        }
    }, [])

    const handleRecipeLinksClick = useCallback(
        (url: string, event: FormEvent<HTMLElement>) => {
            event.preventDefault()
            if (isPublicSearch) {
                navigate('/signIn')
            } else {
                if (url !== '') {
                    window.open(url, '_blank')
                    //log link click into DB.
                    let addLinkClickRequest = new RecipeAddLinkClickRequest()
                    addLinkClickRequest.uuid = recipeData?.uuid
                        ? recipeData?.uuid
                        : ''
                    addLinkClickRequest.url = url
                    if (addLinkClickRequest.uuid && addLinkClickRequest.url) {
                        saveLinkClick(addLinkClickRequest)
                    }
                }
            }
        },
        []
    )

    const onBuyButtonClick = useCallback(
        (event: FormEvent<HTMLElement>, recipeId: string | undefined) => {
            event.preventDefault()
            if (!isPublicSearch) {
                if (recipeId) {
                    if (
                        organizationalRoles &&
                        organizationalRoles.includes(UserRoles.OrgAdmin)
                    ) {
                        let updateShoppingCartRequest =
                            new UpdateShoppingCartRequest()
                        updateShoppingCartRequest.toAdd = [recipeId]
                        updateCartItems(updateShoppingCartRequest)
                    } else {
                        alert(
                            'Please contact your organization admin to purchase the optimization'
                        )
                    }
                }
            } else navigate('/signIn')
        },
        [updateCartItems]
    )

    useEffect(() => {
        const cartItem = updateCartResult?.cartitems?.find(
            (cartItem) => cartItem.optimizationId == recipeData?.uuid
        )
        if (cartItem) {
            getCartItems({
                tags: [ProductTag.OptimizationHub],
            } as ShoppingCartRequest)
        }
    }, [updateCartResult])

    const onGalleryReadMoreClick = useCallback(
        (event: FormEvent<HTMLElement>, uuid: string | undefined) => {
            event.preventDefault()
            downloadCatalogError = null
            setIsReadMoreLoad(true)
            ShowGalleryReadme()
            if (isAnalyst && recipeData?.uuid) {
                getClickCounts({
                    recipeId: recipeData?.uuid,
                    fromDate: moment().subtract(10, 'years').format(),
                })
            }
            if (uuid && isPublicSearch)
                localStorage.setItem('publicUrlRoute', `/catalog?uuid=${uuid}`)
        },
        []
    )
    const onClickProductPerfDataButton = useCallback(
        (event: FormEvent<HTMLElement>) => {
            event.preventDefault()

            if (showProductPerformance) {
                setShowProductPerformance(false)
            } else {
                setShowProductPerformance(true)
            }
        },
        [showProductPerformance]
    )
    const onDownloadLicenseClick = async (id: string) => {
        if (!isPublicSearch) {
            if (
                id &&
                recipeData?.subscriptionStatus === SubscriptionStatus.Subscribed
            ) {
                dispatch(getLicense(id))
                setIsDownloadLicenseClick(true)
            } else {
                alert('Please Buy the Optimization to download the license')
            }
        } else navigate('/signIn')
    }
    const licenseResult = useAppSelector<any>(
        (state) => state.getLicenseResult.data
    )
    useEffect(() => {
        const copyToClipboard = async () => {
            if (
                licenseResult &&
                licenseResult?.license &&
                isDownloadLicenseClick
            ) {
                const fileType = 'txt'
                const blob = new Blob([licenseResult.license], {
                    type: `application/${fileType}`,
                })
                const licenseFileUrl = URL.createObjectURL(blob)
                if (downloadLinkRef.current !== undefined) {
                    downloadLinkRef.current.href = licenseFileUrl
                    downloadLinkRef.current.download = recipeData?.appName
                        ? recipeData?.appName.trim() + '_license.txt'
                        : 'license.txt'
                    downloadLinkRef.current.click()
                }
                setIsDownloadLicenseClick(false)
            }
        }
        if (licenseResult?.license) copyToClipboard()
    }, [licenseResult, isDownloadLicenseClick])

    const onSubscriptionClick = useCallback(
        (event: FormEvent<HTMLElement>) => {
            event.preventDefault()
            const target = event.currentTarget as HTMLButtonElement

            var payload = new RecipeAddSubscriptionRequest()
            payload.itemId = recipeData?.uuid ? recipeData?.uuid : ''
            payload.type =
                recipeData?.tags?.Type && recipeData?.tags?.Type.length > 0
                    ? recipeData?.tags?.Type[0]
                    : ''
            //service call to add subscription

            if (!isOptSubsCribed && recipeData?.uuid) {
                dispatch(addOptimizationSubscription(payload))
                setIsSubsCribedClicked(true)
            } else if (isOptSubsCribed && recipeData?.uuid) {
                setIsSubsCribedClicked(true)
                dispatch(deleteOptimizationSubscription(recipeData?.uuid))
            }
        },
        [isOptSubsCribed]
    )
    const addOptSubscriptionResult = useAppSelector<any>(
        (state) => state.addOptimizationSubscriptionResult
    )
    const deleteOptSubscriptionResult = useAppSelector<any>(
        (state) => state.deleteOptimizationSubscriptionResult
    )
    useEffect(() => {
        if (
            isSubsCribedClicked &&
            (addOptSubscriptionResult || deleteOptSubscriptionResult)
        ) {
            setIsOptSubsCribed(!isOptSubsCribed)
            setShowOptSubscriptionConfirmation(true)
            setIsSubsCribedClicked(false)
        }
    }, [
        isOptSubsCribed,
        isSubsCribedClicked,
        addOptSubscriptionResult,
        deleteOptSubscriptionResult,
    ])

    return {
        onReadMoreClick,
        showReadmePopup,
        onDownloadClick,
        fileDownloadUrl,
        downloadFileName,
        onFeedbackButtonClick,
        showFeedbackPopup,
        handleFeedbackClose,
        onFeedbackAttachClick,
        onFeedbackFileChange,
        feedbackSelectedFileName,
        onFeedbackTextChange,
        validated,
        onFeedbackFormSubmit,
        isFeedbackLoad,
        onPrioritySelectChange,
        onFeedbackDateChange,
        isFavorite,
        onFavoriteClick,
        favoriteCount,
        onVoteButtonClick,
        showVotePopup,
        handleVoteClose,
        onVoteFormSubmit,
        onVoteTextChange,
        starRating,
        onStarRatingClick,
        isVotingLoad,
        onShareLinkClick,
        handleRecipeLinksClick,
        onBuyButtonClick,
        showBenchMark,
        onShowBenchmarkClick,
        onGalleryReadMoreClick,
        showGalleryReadme,
        CloseGalleryReadme,
        reduceImageSize,
        setReduceImageSize,
        onClickProductPerfDataButton,
        showProductPerformance,
        isLiked,
        onLikeClick,
        onDownloadLicenseClick,
        showLicensePopup,
        handleLicensePopupClose,
        handleAcceptLicenseClick,
        handleRejectLicenseClick,
        showLicenseConfirmation,
        onLicenseConfirmationClick,
        handleCloseConfirmation,
        saveLicenseAcceptanceResult,
        feedbackAttachResult,
        feedbackAttachError,
        onSubscriptionClick,
        addOptSubscriptionResult,
        deleteOptSubscriptionResult,
        showOptSubscriptionConfirmation,
        handleOptSubsCloseConfirmation,
        isOptSubsCribed,
    }
}
