/************************************************
 * Copyright (C) 2024 Intel Corporation
 ************************************************/
import styled from 'styled-components'
import { FunctionComponent, Dispatch } from 'react'
import { connect } from 'react-redux'
import SideLinkPanel from '../layouts/sidePanel'
import AiModelHeaderPanel from '../layouts/header'
import AiModelFooterPanel from '../layouts/footer'

const DiscoverPanelContainer = styled.div`
    display: flex;
    height: ${(props) => props.theme.size.mainContainerHeight};
    width: ${(props) => props.theme.size.mainContainerWidth};
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: space-between;
    @media screen and (max-width: 500px) {
        width: 100%;
        height: 100%;
    }
    overflow: hidden;
    background-color: rgb(24, 24, 24, 0.5);
`
const DiscoverHorizontalContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: space-between;
`
const DiscoverBodyContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: center;
    width: 100%;
    background-color: #fefefe; //#d8d8d8; //#8f8f8f; //#5e5e5e;
    overflow-y: auto;
`
const ContentScrollContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: visible;
    overflow-x: hidden;
    position: relative;
    width: 100%;
    height: 100vh;

    margin-bottom: 2rem;
    padding: 1rem;

    /* scroll style-width */
    ::-webkit-scrollbar {
        height: 1rem;
        width: 0.8rem;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        // background: #f1f1f1;
        // border-radius: 20px;
        background-color: transparent;
        border-radius: 9999px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        // background: #888;
        // border-radius: 20px;
        --tw-border-opacity: 1;
        background-color: hsla(0, 0%, 89%, 0.8);
        border-color: rgba(255, 255, 255, var(--tw-border-opacity));
        border-radius: 9999px;
        border-width: 1px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
`

const MessageContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: center;
    width: 100%;
    background-color: #fefefe;
    color: green;
    overflow-y: auto;
    font-size: 1.5rem;
`

export interface IOtherModelContainerProps {}

const OtherModelContainer: FunctionComponent<IOtherModelContainerProps> & {
    defaultProps: Partial<IOtherModelContainerProps>
} = ({}: IOtherModelContainerProps) => {
    const message = 'Coming Soon!!'

    return (
        <DiscoverPanelContainer>
            <AiModelHeaderPanel></AiModelHeaderPanel>
            <DiscoverHorizontalContainer>
                <SideLinkPanel></SideLinkPanel>
                <DiscoverBodyContainer>
                    <ContentScrollContainer>
                        <MessageContainer>{message}</MessageContainer>
                        <AiModelFooterPanel></AiModelFooterPanel>
                    </ContentScrollContainer>
                </DiscoverBodyContainer>
            </DiscoverHorizontalContainer>
        </DiscoverPanelContainer>
    )
}

OtherModelContainer.defaultProps = {}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {}

const mapStateToProps = (state: any) => {}

type StateToPropsType = ReturnType<typeof mapStateToProps>
type DispatchToPropsType = typeof mapDispatchToProps

export default connect<StateToPropsType, DispatchToPropsType>(
    mapStateToProps,
    mapDispatchToProps
)(OtherModelContainer)
