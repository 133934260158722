import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { Table, FormLabel, Button, Alert } from 'react-bootstrap'
import moment from 'moment'
import { useAppDispatch, useAppSelector } from '../../config/hooks'
import UserPanel from '../../layout/userProfile'
import { getDiscounts, acceptDiscounts } from '../../store/discount.slice'
import HeaderPanel from '../../layout/header'

const DiscountContainerPanel = styled.div`
    display: flex;
    height: ${(props) => props.theme.size.mainContainerHeight};
    width: ${(props) => props.theme.size.mainContainerWidth};
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: space-between;
    @media screen and (min-width: 1200px) {
        overflow-x: hidden;
    }
`

const HorizontalContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: space-between;
`

const PageLinkPanelPanelWrapper = styled.div`
    display: flex;
    margin-left: 2rem;
    @media screen and (max-width: 500px) {
        display: none;
    }
`
const MobileHeaderWrapper = styled.div`
    display: flex;
    display: none;
    @media screen and (max-width: 500px) {
        display: block;
    }
`

const BodyContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: center;
    background-color: ${(props) => props.theme.color.background.defaultGrey};
    padding-bottom: 10rem;
`

const BodyContainerReposition = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
`

const MainContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: center;
    background-color: ${(props) => props.theme.color.background.defaultGrey};
    padding-bottom: 10rem;
`

const VerticalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: no-wrap;
    border-radius: 1.56rem;
    margin: 2rem;
    padding-bottom: 1rem;
    height: 100%;
`

const Header = styled.h1`
    font-family: 'IntelOne Display';
    font-weight: 400;
    font-size: 2.5rem;
    line-height: 31px;
    margin: 2rem 0;
    color: #424242;
`

const TableContainer = styled.div`
    background: #ffffff;
    box-shadow: 0px 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
    border-radius: 1.5rem;
    padding: 2rem;
    max-width: 57rem;
`

const TextLabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`

const TextLabel = styled(FormLabel)`
    display: flex;
    ${(props) => props.theme.typography.medium}
    text-align: left;
    margin-top: 0.5rem;
    white-space: pre-wrap;
    width: 12rem;
`

const NameTextLabel = styled(TextLabel)`
    width: 15rem;
`

const TableHeading = styled(TextLabel)`
    color: ${(props) => props.theme.color.text.default};
    ${(props) => props.theme.typography.large}
`

const AcceptButton = styled(Button)`
    width: 15%;
    background: #8bae46;
    border-radius: 0.625rem;
    margin-left: 2em;
`

const AlertComponent = styled(Alert)`
    display: flex;
    margin-top: 1rem;
    justify-content: center;
`

const DiscountContainer = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    let { code } = useParams()

    const [showSuccess, setShowSuccess] = useState(false)
    const [showError, setShowError] = useState(false)

    useEffect(() => {
        dispatch(getDiscounts(code))
    }, [])
    const discounts = useAppSelector<any>(
        (state) => state.getDiscountResult.data
    )
    const acceptDiscountSuccess = useAppSelector<any>(
        (state) => state.acceptDiscountResult.data
    )
    const acceptDiscountError = useAppSelector<any>(
        (state) => state.acceptDiscountResult.error
    )
    useEffect(() => {
        if (acceptDiscountSuccess) {
            setShowSuccess(true)
            localStorage.setItem('loginRoute', 'catalog')
            navigate('/catalog')
        }
        if (acceptDiscountError) setShowError(true)
    }, [acceptDiscountSuccess, acceptDiscountError])

    const tableRows = discounts?.Products?.map((discount: any) => {
        return (
            <tr key={discount.uuid}>
                <td>
                    <TextLabelWrapper>
                        <NameTextLabel
                            data-testid={`discount-value-${discount?.name}`}
                        >
                            {discount.name}
                        </NameTextLabel>
                    </TextLabelWrapper>
                </td>
                <td>
                    <TextLabelWrapper>
                        <NameTextLabel
                            data-testid={`price-value-${discount?.price}`}
                        >
                            $0
                        </NameTextLabel>
                    </TextLabelWrapper>
                </td>
                <td>
                    <TextLabelWrapper>
                        <NameTextLabel
                            data-testid={`expiration-value-${discount?.expiration}`}
                        >
                            {moment(discounts.ExpiresAt).format(
                                'MMM Do YYYY, h:mm a'
                            )}
                        </NameTextLabel>
                    </TextLabelWrapper>
                </td>
            </tr>
        )
    })

    return (
        <DiscountContainerPanel>
            <HeaderPanel />
            <HorizontalContainer>
                <BodyContainer>
                    <BodyContainerReposition>
                        <UserPanel></UserPanel>
                        <MainContainer>
                            <VerticalWrapper>
                                <Header>Trial Offers</Header>

                                <TableContainer>
                                    <AlertComponent
                                        id="errorAlert"
                                        variant="danger"
                                        show={showError}
                                    >
                                        Error accepting trial. Please try again.
                                    </AlertComponent>

                                    <AlertComponent
                                        id="errorAlert"
                                        variant="success"
                                        show={showSuccess}
                                    >
                                        Trial accepted
                                    </AlertComponent>
                                    {tableRows ? (
                                        <Table
                                            responsive
                                            borderless
                                            style={{ minHeight: '14rem' }}
                                        >
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <TableHeading
                                                            data-testid={`name-label`}
                                                        >
                                                            Optimization Name
                                                        </TableHeading>
                                                    </th>
                                                    <th>
                                                        <TableHeading
                                                            data-testid={`price-label`}
                                                        >
                                                            Normal Price
                                                        </TableHeading>
                                                    </th>
                                                    <th>
                                                        <TableHeading
                                                            data-testid={`expiration-label`}
                                                        >
                                                            Expiration
                                                        </TableHeading>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>{tableRows}</tbody>
                                        </Table>
                                    ) : (
                                        'Invalid Code'
                                    )}
                                </TableContainer>
                            </VerticalWrapper>
                            {tableRows ? (
                                <AcceptButton
                                    onClick={() =>
                                        dispatch(acceptDiscounts(code))
                                    }
                                >
                                    Accept
                                </AcceptButton>
                            ) : null}
                        </MainContainer>
                    </BodyContainerReposition>
                </BodyContainer>
            </HorizontalContainer>
        </DiscountContainerPanel>
    )
}

export default DiscountContainer
