import { FunctionComponent } from 'react'
import styled from 'styled-components'
import { MembershipPanelLogic } from './membershipPanel.hook'
import {
    getMembership,
    updateMembership,
    updateUserDefaultOrg,
} from '../../store/membership.slice'
import {
    GetMembershipResponse,
    Member,
} from '../../models/getMembershipResponse'
import { UpdateMembershipResponse } from '../../models/updateMembershipResponse'
import { LoadingState } from '../../models/loadingState'
import { SelectDropDownPanel } from '../selectDropDownPanel/selectDropDownPanel'
import { ChangeRolesPopupPanel, MemberType } from './changeRolesPopupPanel'
import {
    FormControl,
    Button,
    Spinner,
    Form,
    OverlayTrigger,
    Popover,
} from 'react-bootstrap'
import Select from 'react-select'

import { OrganizationInfoResponse } from '../../models/organizationInfoResponse'
import {
    getOrganization,
    getUserOrganizationsList,
} from '../../store/organization.slice'
import { getInvites, sendInvites } from '../../store/invitation.slice'
import { SendInvitesResponse } from '../../models/sendInvitesResponse'
import { GetInvitesResponse } from '../../models/getInvitesResponse'
import { AuthPermissions } from '../../models/authPermissions'
import stringUtils from '../../lib/stringUtils'
import moment from 'moment'
import { UserOrganizationsResponse } from '../../models/UserOrganizationsResponse'
import { UpdateUserDefaultOrgResponse } from '../../models/UpdateUserDefaultOrgResponse'

const MembershipFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
`

const MemberContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 3rem;
    min-height: 45rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    background: #ffffff;
    margin-top: 1rem;
    margin-bottom: 2rem;
`

const MemberHeaderRow = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
`

const OrganizationHeaderRow = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 2rem;
`

const AddMemberHeaderRow = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
`

const MemberRow = styled.div`
    display: flex;
    flex-direction: row;
    margin: 0.5rem;
`

const MemberColumn = styled.div`
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 800px) {
        overflow-x: auto;
    }
`

const MemberRowsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    overflow: auto;
    max-height: 20rem;
    overflow-x: hidden;

    /* width */
    ::-webkit-scrollbar {
        width: 0.25rem;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
`

const FieldText = styled.div`
    display: flex;
    font-family: IntelOne Text;
    text-align: left;
    width: 25%;
    margin-left: 1rem;
`

const FieldTextHeader = styled.div`
    display: flex;
    text-align: left;
    font-family: IntelOne Text;
    width: 25%;
    margin-left: 1rem;
`
const FieldDate = styled.div`
    display: flex;
    font-family: IntelOne Text;
    text-align: left;
    width: 15rem;
    margin-left: 1rem;
`

const FieldDateHeader = styled.div`
    display: flex;
    text-align: left;
    font-family: IntelOne Text;
    width: 15rem;
    margin-left: 1rem;
`

const FormCheck = styled(Form.Check)`
    display: flex;
`

const FormCheckWrapper = styled.div`
    display: flex;
    width: 5rem;
`

const HeaderText = styled.div`
    display: flex;
    ${(props) => props.theme.typography.xxLarge}
    font-family: IntelOne Text;
    text-align: left;
    color: green;
`

const OrganizationHeaderText = styled(HeaderText)`
    display: flex;
    ${(props) => props.theme.typography.large}
    color: #023020;
`
const UserOrgListDiv = styled.div`
    padding: 0px 0.5rem;
    margin-top: -0.3rem;
    width: 30%;
`

const EmailTextBox = styled(FormControl)`
    display: flex;
    border-radius: ${(props) => props.theme.shape.input.borderRadius};
    width: 50rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
`

const AddMemberButton = styled(Button)`
    display: flex;
    justify-content: center;
    font-weight: 400;
    font-size: 1rem;
    background-color: rgb(143, 93, 162); //#008000;
    border-color: rgb(143, 93, 162);
    border-radius: 0;
    font-family: IntelOne Text;
    &:hover {
        background-color: rgb(143, 93, 162);
        border-color: rgb(143, 93, 162);
        transition: 0.5s;
    }
    &:disabled {
        background-color: gray;
        border-color: gray;
    }
    max-width: 20rem;
`

const SpinnerDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5rem;
    width: 100%;
`

const ErrorText = styled.div`
    display: flex;
    ${(props) => props.theme.typography.medium}
    font-family: IntelOne Text;
    text-align: left;
    color: red;
    margin-top: 0.25rem;
`

const SuccessText = styled.div`
    display: flex;
    ${(props) => props.theme.typography.medium}
    font-family: IntelOne Text;
    text-align: left;
    color: green;
    margin-top: 0.25rem;
`

export interface IMembershipPanelProps {
    getMembership: typeof getMembership
    getMembershipError: any
    getMembershipLoading: LoadingState
    getMembershipResult: GetMembershipResponse
    updateMembership: typeof updateMembership
    updateMembershipError: any
    updateMembershipLoading: LoadingState
    updateMembershipResult: UpdateMembershipResponse
    getOrganization: typeof getOrganization
    organizationInfoError: any
    organizationInfoLoading: LoadingState
    organizationInfoResult: OrganizationInfoResponse

    getInvites: typeof getInvites
    getInvitesError: any
    getInvitesLoading: LoadingState
    getInvitesResult: GetInvitesResponse

    sendInvites: typeof sendInvites
    sendInvitesError: any
    sendInvitesLoading: LoadingState
    sendInvitesResult: SendInvitesResponse

    authPermissionsResult?: AuthPermissions
    authPermissionsError?: any
    authPermissionsLoading?: LoadingState

    getUserOrganizationsList: typeof getUserOrganizationsList
    userOrganizationsListError: any
    userOrganizationsListLoading: LoadingState
    userOrganizationsListResult: UserOrganizationsResponse

    updateUserDefaultOrg: typeof updateUserDefaultOrg
    updateUserDefaultOrgError: any
    updateUserDefaultOrgLoading: LoadingState
    updateUserDefaultOrgResult: UpdateUserDefaultOrgResponse
}

const MembershipPanel: FunctionComponent<IMembershipPanelProps> = ({
    getMembership,
    updateMembership,
    getMembershipError,
    getMembershipLoading,
    getMembershipResult,
    updateMembershipError,
    updateMembershipLoading,
    updateMembershipResult,
    getOrganization,
    organizationInfoError,
    organizationInfoLoading,
    organizationInfoResult,
    getInvites,
    getInvitesError,
    getInvitesLoading,
    getInvitesResult,
    sendInvites,
    sendInvitesError,
    sendInvitesLoading,
    sendInvitesResult,
    authPermissionsResult,
    authPermissionsError,
    authPermissionsLoading,

    getUserOrganizationsList,
    userOrganizationsListError,
    userOrganizationsListLoading,
    userOrganizationsListResult,
    updateUserDefaultOrg,
    updateUserDefaultOrgError,
    updateUserDefaultOrgLoading,
    updateUserDefaultOrgResult,
}: IMembershipPanelProps) => {
    let errorText = ''
    if (getMembershipError) {
        errorText = 'We are experiencing issues retrieving the data.'
    }
    const {
        onInputInviteEmail,
        onAddInviteEmail,
        onSelectMember,
        onActionsClick,
        onUpdateRoles,
        onClosePopup,
        memberSelected,
        email,
        roles,
        showAddRolesPopup,
        showAddRolesInvitePopup,
        onAddInviteKeyDown,
        badEmailFormatMsg,
        isLoading,
        memberDDActions,
        onInviteClosePopup,
        onInviteUpdatedRoles,
        inviteSentMessage,
        userOrgsListOptions,
        selectedUserOrganization,
        setSelectedUserOrganization,
        onApplyUserOrgChange,
    } = MembershipPanelLogic({
        getOrganization,
        getMembership,
        updateMembership,
        updateMembershipResult,
        updateMembershipLoading,
        organizationInfoResult,
        getMembershipLoading,
        organizationInfoLoading,
        getInvites,
        getInvitesError,
        getInvitesLoading,
        getInvitesResult,
        sendInvites,
        sendInvitesError,
        sendInvitesLoading,
        sendInvitesResult,
        authPermissionsResult,
        getUserOrganizationsList,
        userOrganizationsListError,
        userOrganizationsListLoading,
        userOrganizationsListResult,
        updateUserDefaultOrg,
        updateUserDefaultOrgError,
        updateUserDefaultOrgLoading,
        updateUserDefaultOrgResult,
    } as IMembershipPanelProps)

    const memberHeading = (
        <MemberRow>
            <FormCheckWrapper>{'Select'}</FormCheckWrapper>
            <FieldTextHeader>{'Name'}</FieldTextHeader>
            <FieldTextHeader>{'Email'}</FieldTextHeader>
        </MemberRow>
    )

    const inviteHeading = (
        <MemberRow>
            <FieldTextHeader>{'Email'}</FieldTextHeader>
            <FieldDateHeader>{'Date sent'}</FieldDateHeader>
            <FieldDateHeader>{'Expires'}</FieldDateHeader>
        </MemberRow>
    )

    const memberRows = getMembershipResult?.members
        ?.map((member) => (
            <MemberRow key={member.userId}>
                <FormCheckWrapper>
                    <FormCheck
                        inline
                        name="memberSelection"
                        checked={member.userId == memberSelected?.userId}
                        type="radio"
                        id={`radio-member-` + member.userId}
                        onChange={(e: any) => onSelectMember(e, member)}
                    />
                </FormCheckWrapper>
                <FieldText data-testid={`member-userid-${member.displayName}`}>
                    {member.displayName}
                </FieldText>
                <FieldText data-testid={`member-email-${member.email}`}>
                    {member.email}
                </FieldText>
            </MemberRow>
        ))
        .sort((a, b) => ((a.key ? a.key : '') > (b.key ? b.key : '') ? 1 : -1))

    const inviteRows = getInvitesResult?.invitations
        ?.map((invite) => (
            <MemberRow key={invite.createdAt}>
                <FieldText
                    data-testid={`invite-email-${invite.invitationEmail}`}
                >
                    {invite.invitationEmail}
                </FieldText>
                <FieldDate
                    data-testid={`invite-date-sent-${invite.invitationEmail}`}
                >
                    {invite.createdAt
                        ? moment(Date.parse(invite.createdAt)).format(
                              'MMMM Do YYYY'
                          )
                        : ''}
                </FieldDate>
                <FieldDate
                    data-testid={`invite-expiration-${invite.invitationEmail}`}
                >
                    {invite.expiresAt && invite.createdAt
                        ? moment(Date.parse(invite.expiresAt)).fromNow()
                        : ''}
                </FieldDate>
                <Button
                    variant="outline-primary"
                    onClick={(e: any) =>
                        onInviteUpdatedRoles(
                            invite.invitationEmail,
                            invite.roles ? invite.roles : []
                        )
                    }
                >
                    Resend
                </Button>
            </MemberRow>
        ))
        .sort((a, b) => ((a.key ? a.key : '') > (b.key ? b.key : '') ? 1 : -1))

    const spinner = (
        <SpinnerDiv>
            <Spinner animation="border" variant="dark">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </SpinnerDiv>
    )

    return (
        <MembershipFormContainer>
            <MemberContainer>
                {!stringUtils.isNullOrUndefinedOrEmpty(
                    updateUserDefaultOrgError
                ) ? (
                    <ErrorText style={{ marginBottom: '1rem' }}>
                        {'Error while updating default org: ' +
                            updateUserDefaultOrgError?.message}
                    </ErrorText>
                ) : (
                    <div></div>
                )}
                <OrganizationHeaderRow>
                    <OrganizationHeaderText data-testid="current-org-label">
                        {'Organization: '}
                    </OrganizationHeaderText>
                    <UserOrgListDiv>
                        <Select
                            name="UserOrgs"
                            isSearchable={false}
                            isClearable={false}
                            isMulti={false}
                            options={userOrgsListOptions}
                            value={selectedUserOrganization || null}
                            onChange={(target: any) =>
                                setSelectedUserOrganization(target)
                            }
                        />
                    </UserOrgListDiv>
                    <OverlayTrigger
                        placement={'right'}
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                            <Popover
                                id="pop_timeline"
                                style={{
                                    maxWidth: '20%',
                                }}
                            >
                                <Popover.Body>
                                    Change your default organization by click on
                                    apply button.
                                </Popover.Body>
                            </Popover>
                        }
                    >
                        <AddMemberButton
                            style={{ marginTop: '-0.3rem' }}
                            onClick={onApplyUserOrgChange}
                            variant="primary"
                            disabled={
                                selectedUserOrganization &&
                                selectedUserOrganization.length < 1
                            }
                            type="submit"
                            data-testid="update-user-org-button"
                        >
                            {'Apply'}
                        </AddMemberButton>
                    </OverlayTrigger>
                </OrganizationHeaderRow>
                <MemberHeaderRow>
                    <HeaderText data-testid="current-members-heading-label">
                        {'Current Members'}
                    </HeaderText>
                </MemberHeaderRow>
                <MemberHeaderRow>
                    <SelectDropDownPanel
                        options={memberSelected?.userId ? memberDDActions : []}
                        loading={organizationInfoLoading}
                        onOptionChange={onActionsClick}
                        placeholder="Actions"
                        noOptionsMessage="Select a user first"
                    ></SelectDropDownPanel>
                    <ChangeRolesPopupPanel
                        show={showAddRolesPopup}
                        roles={roles}
                        memberType={MemberType.Member}
                        member={memberSelected}
                        onCancelCallBack={onClosePopup}
                        onRunCallBack={onUpdateRoles}
                    />
                </MemberHeaderRow>
                <MemberRowsWrapper>
                    {(memberRows?.length ?? 0) > 0 ? (
                        <MemberColumn>
                            {memberHeading}

                            {memberRows}
                        </MemberColumn>
                    ) : (
                        <div data-testid="no-members-label">
                            No members added
                        </div>
                    )}
                </MemberRowsWrapper>

                <AddMemberHeaderRow>
                    <HeaderText data-testid="add-members-label">
                        {'Pending Invitations'}
                    </HeaderText>
                </AddMemberHeaderRow>
                <MemberHeaderRow>
                    <ChangeRolesPopupPanel
                        show={showAddRolesInvitePopup}
                        roles={roles}
                        memberType={MemberType.Invitee}
                        inviteEmail={email}
                        onCancelCallBack={onInviteClosePopup}
                        onRunCallBack={onInviteUpdatedRoles}
                    />
                </MemberHeaderRow>
                <MemberRowsWrapper>
                    {(inviteRows?.length ?? 0) > 0 ? (
                        <MemberColumn>
                            {inviteHeading}
                            {inviteRows}
                        </MemberColumn>
                    ) : (
                        <div data-testid="no-members-label">
                            No pending invitations
                        </div>
                    )}
                </MemberRowsWrapper>
                <EmailTextBox
                    id="timeline"
                    placeholder="Input email"
                    value={email}
                    onChange={onInputInviteEmail}
                    onKeyDown={onAddInviteKeyDown}
                    required
                    data-testid="input-member-email-text"
                />
                <AddMemberButton
                    onClick={onAddInviteEmail}
                    variant="primary"
                    disabled={email === ''}
                    type="submit"
                    data-testid="add-member-button"
                >
                    {'Send Invite'}
                </AddMemberButton>
                {badEmailFormatMsg !== '' ? (
                    <ErrorText>{badEmailFormatMsg}</ErrorText>
                ) : (
                    <div></div>
                )}
                {!stringUtils.isNullOrUndefinedOrEmpty(sendInvitesError) ? (
                    <ErrorText>{'Error sending invite'}</ErrorText>
                ) : (
                    <div></div>
                )}
                {!stringUtils.isNullOrUndefinedOrEmpty(inviteSentMessage) ? (
                    <SuccessText>{inviteSentMessage}</SuccessText>
                ) : (
                    <div></div>
                )}
                {isLoading ? spinner : <div></div>}
            </MemberContainer>
        </MembershipFormContainer>
    )
}

export default MembershipPanel
