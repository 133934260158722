import { API_SERVICE_URL } from '../config/service'
import { UpdateUserDefaultOrgRequest } from '../models/UpdateUserDefaultOrgRequest'
import { GetMembershipRequest } from '../models/getMembershipRequest'
import { UpdateMembershipRequest } from '../models/updateMembershipRequest'
import api from './auth/client'
const serviceConfig = require('../config/service.json')

class MembershipService {
    getMembers(getMembershipRequest: GetMembershipRequest) {
        var url = API_SERVICE_URL + serviceConfig.getMembersInAnOrg
        return api.get(url)
    }

    updateMembers(updateMembershipRequest: UpdateMembershipRequest) {
        var url = API_SERVICE_URL + serviceConfig.updateMembersInAnOrg
        return api.post(url, updateMembershipRequest)
    }
    updateUserDefaultOrg(updateUserOrgRequest: UpdateUserDefaultOrgRequest) {
        var url = API_SERVICE_URL + serviceConfig.updateUserDefaultOrg
        return api.post(url, updateUserOrgRequest)
    }
}

export default new MembershipService()
