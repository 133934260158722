/************************************************
 * Copyright (C) 2021 Intel Corporation
 ************************************************/
import styled from 'styled-components'
import { FunctionComponent, Dispatch, useEffect } from 'react'
import { connect } from 'react-redux'
import FooterPanel from '../../layout/footer'
import { FormLabel } from 'react-bootstrap'
import UserPanel from '../../layout/userProfile'
import { getFavorites } from '../../store/favorite.slice'
import {
    downloadCatalog,
    submitFeedback,
    saveFavorite,
    saveStarRating,
    saveVoting,
    getRecipeById,
    saveLinkClick,
    saveLike,
} from '../../store/catalog.slice'
import { FavoriteContainerLogic } from './favoritesContainer.hook'
import FavoritePanel from '../../components/favoritesPanel/favoritePanel'
import { LoadingState } from '../../models/loadingState'
import { DownloadCatalogResponse } from '../../models/downloadCatalogResponse'
import { Recipefeedbackresponse } from '../../models/recipefeedbackresponse'
import { RecipeAddFavoriteResponse } from '../../models/recipeAddFavoriteResponse'
import { RecipeStarRatingResponse } from '../../models/recipeStarRatingResponse'
import { RecipeFeedbackRequest } from '../../models/recipeFeedbackRequest'
import { RecipeAddFavoriteRequest } from '../../models/recipeAddFavoriteRequest'
import { RecipeStarRatingRequest } from '../../models/recipeStarRatingRequest'
import { RecipeAddVotingRequest } from '../../models/recipeAddVotingRequest'
import { RecipeAddVotingResponse } from '../../models/recipeAddVotingResponse'
import { RecipeAddLinkClickRequest } from '../../models/recipeAddLinkClickRequest'
import { UpdateShoppingCartRequest } from '../../models/updateShoppingCartRequest'
import { getCartItems, updateCartItems } from '../../store/shoppingCart.slice'
import { ShoppingCartResponse } from '../../models/shoppingCartResponse'
import { useNavigate } from 'react-router-dom'
import { getSubscription } from '../../store/subscription.slice'
import { SubscriptionsResponse } from '../../models/subscriptionsResponse'
import HeaderPanel from '../../layout/header'
import { ShoppingCartRequest } from '../../models/shoppingCartRequest'
import { RecipeAddLikeResponse } from '../../models/recipeAddLikeResponse'
import { RecipeAddLikeRequest } from '../../models/recipeAddLikeRequest'
import { getClickCounts } from '../../store/dashboard.slice'

const FavoritesPanelContainer = styled.div`
    display: flex;
    height: ${(props) => props.theme.size.mainContainerHeight};
    width: ${(props) => props.theme.size.mainContainerWidth};
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: space-between;
    @media screen and (min-width: 1200px) {
        overflow-x: hidden;
    }
    @media screen and (max-width: 500px) {
        width: 100%;
        height: 100%;
    }
`

const FavoritesHorizontalContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: space-between;
`
const FavoritesBodyContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: center;
    background-color: ${(props) => props.theme.color.background.defaultGrey};
    padding-bottom: 10rem;
    width: 100%;
`

const FavoritesBodyContainerReposition = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    margin-left: 2rem;
`

const PageLinkPanelPanelWrapper = styled.div`
    display: flex;
    margin-left: 2rem;
    @media screen and (max-width: 500px) {
        display: none;
    }
`
const MobileHeaderWrapper = styled.div`
    display: flex;
    display: none;
    @media screen and (max-width: 500px) {
        display: block;
    }
`

const FavoritesPanelWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: flex-start;
`

const PageTitleTextLabel = styled(FormLabel)`
    ${(props) => props.theme.typography.xxxLarge}
    text-align: left;
    font-weight: 500;
    font-size: 2.25rem;
    padding-left: 0.2em;
`

export interface IFavoritesContainerProps {
    getFavorites: typeof getFavorites
    getFavoritesResult: any
    getFavoritesLoading: LoadingState
    getFavoritesError?: any
    downloadCatalog: typeof downloadCatalog
    downloadCatalogLoading: LoadingState
    downloadCatalogError: any
    downloadCatalogResult: DownloadCatalogResponse
    submitFeedback: typeof submitFeedback
    feedbackResult: Recipefeedbackresponse
    feedbackError: any
    feedbackLoading: LoadingState
    saveFavorite: typeof saveFavorite
    favoriteResult: RecipeAddFavoriteResponse
    favoriteError: any
    favoriteLoading: LoadingState
    saveStarRating: typeof saveStarRating
    saveRatingResult: RecipeStarRatingResponse
    saveRatingError: any
    saveVoting: typeof saveVoting
    saveVotingResult: RecipeAddVotingResponse
    saveVotingError: any
    getRecipeById: typeof getRecipeById
    saveLinkClick: typeof saveLinkClick
    updateCartItems: typeof updateCartItems
    updateCartResult: ShoppingCartResponse
    updateCartResultLoading: LoadingState
    updateCartResultError: any
    getSubscription: typeof getSubscription
    getSubscriptionResult: SubscriptionsResponse
    getSubscriptionResultLoading: LoadingState
    getSubscriptionResultError: any
    getCartItems: typeof getCartItems
    getCartItemsResult: ShoppingCartResponse
    getCartItemsResultLoading: LoadingState
    getCartItemsResultError: any
    saveLike: typeof saveLike
    likeResult: RecipeAddLikeResponse
    likeError?: any
    likeLoading: LoadingState
    getClickCounts: typeof getClickCounts
    clickCountsResult: any
}

const FavoritesContainer: FunctionComponent<IFavoritesContainerProps> & {
    defaultProps: Partial<IFavoritesContainerProps>
} = ({
    getFavorites,
    getFavoritesResult,
    getFavoritesLoading,
    getFavoritesError,
    downloadCatalogResult,
    downloadCatalogLoading,
    downloadCatalogError,
    submitFeedback,
    feedbackResult,
    feedbackError,
    feedbackLoading,
    saveFavorite,
    favoriteResult,
    favoriteError,
    favoriteLoading,
    saveStarRating,
    saveRatingResult,
    saveRatingError,
    saveVoting,
    saveVotingResult,
    saveVotingError,
    getRecipeById,
    saveLinkClick,
    updateCartItems,
    updateCartResult,
    updateCartResultLoading,
    updateCartResultError,
    getSubscription,
    getSubscriptionResult,
    getSubscriptionResultLoading,
    getSubscriptionResultError,
    getCartItems,
    getCartItemsResult,
    getCartItemsResultLoading,
    getCartItemsResultError,
    saveLike,
    likeResult,
    likeError,
    likeLoading,
    getClickCounts,
    clickCountsResult,
}: IFavoritesContainerProps) => {
    const { navigateToPage } = FavoriteContainerLogic({
        getFavorites,
        updateCartResult,
        updateCartResultLoading,
        updateCartResultError,
        getSubscription,
        getSubscriptionResult,
        getSubscriptionResultLoading,
        getSubscriptionResultError,
        getCartItems,
    } as IFavoritesContainerProps)

    let navigate = useNavigate()

    useEffect(() => {
        if (navigateToPage) {
            navigate(navigateToPage)
        }
    }, [navigateToPage])

    return (
        <FavoritesPanelContainer>
            <HeaderPanel />
            <FavoritesHorizontalContainer>
                <FavoritesBodyContainer>
                    <FavoritesBodyContainerReposition>
                        <UserPanel></UserPanel>
                        <FavoritesPanelWrapper>
                            <PageTitleTextLabel>
                                My favorites
                            </PageTitleTextLabel>
                            <FavoritePanel
                                getFavorites={getFavorites}
                                getFavoriteResult={getFavoritesResult}
                                getFavoriteLoading={getFavoritesLoading}
                                getFavoriteError={getFavoritesError}
                                downloadCatalog={downloadCatalog}
                                downloadCatalogResult={downloadCatalogResult}
                                downloadCatalogLoading={downloadCatalogLoading}
                                downloadCatalogError={downloadCatalogError}
                                submitFeedback={submitFeedback}
                                feedbackResult={feedbackResult}
                                feedbackError={feedbackError}
                                feedbackLoading={feedbackLoading}
                                saveFavorite={saveFavorite}
                                favoriteResult={favoriteResult}
                                favoriteError={favoriteError}
                                favoriteLoading={favoriteLoading}
                                saveStarRating={saveStarRating}
                                saveRatingResult={saveRatingResult}
                                saveRatingError={saveRatingError}
                                saveVoting={saveVoting}
                                saveVotingResult={saveVotingResult}
                                saveVotingError={saveVotingError}
                                getRecipeById={getRecipeById}
                                saveLinkClick={saveLinkClick}
                                updateCartItems={updateCartItems}
                                updateCartResult={updateCartResult}
                                getCartItems={getCartItems}
                                getCartItemsResult={getCartItemsResult}
                                getCartItemsResultLoading={
                                    getCartItemsResultLoading
                                }
                                getCartItemsResultError={
                                    getCartItemsResultError
                                }
                                saveLike={saveLike}
                                likeResult={likeResult}
                                likeError={likeError}
                                likeLoading={likeLoading}
                                getClickCounts={getClickCounts}
                                clickCountsResult= {clickCountsResult}
                            ></FavoritePanel>
                        </FavoritesPanelWrapper>
                    </FavoritesBodyContainerReposition>
                </FavoritesBodyContainer>
            </FavoritesHorizontalContainer>
            <FooterPanel></FooterPanel>
        </FavoritesPanelContainer>
    )
}

FavoritesContainer.defaultProps = {}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        getFavorites: (param: any) => dispatch(getFavorites(param)),
        downloadCatalog: (downloadUrl: string) =>
            dispatch(downloadCatalog(downloadUrl)),
        submitFeedback: (recipeFeedbackRequest: RecipeFeedbackRequest) =>
            dispatch(submitFeedback(recipeFeedbackRequest)),
        saveFavorite: (addFavoriteRequest: RecipeAddFavoriteRequest) =>
            dispatch(saveFavorite(addFavoriteRequest)),
        saveStarRating: (saveRatingRequest: RecipeStarRatingRequest) =>
            dispatch(saveStarRating(saveRatingRequest)),
        saveVoting: (saveVotingRequest: RecipeAddVotingRequest) =>
            dispatch(saveVoting(saveVotingRequest)),
        getRecipeById: (id: any) => dispatch(getRecipeById(id)),
        saveLinkClick: (addLinkClickRequest: RecipeAddLinkClickRequest) =>
            dispatch(saveLinkClick(addLinkClickRequest)),
        updateCartItems: (
            updateShoppingCartRequest: UpdateShoppingCartRequest
        ) => dispatch(updateCartItems(updateShoppingCartRequest)),
        getSubscription: () => dispatch(getSubscription()),
        getCartItems: (shoppingCartRequest: ShoppingCartRequest) =>
            dispatch(getCartItems(shoppingCartRequest)),
        saveLike: (addLikeRequest: RecipeAddLikeRequest) =>
            dispatch(saveLike(addLikeRequest)),
        getClickCounts: (filters : any) =>
            dispatch(getClickCounts(filters)),    
    }
}

const mapStateToProps = (state: any) => {
    return {
        getFavoritesResult: state.getFavoritesResult.data,
        getFavoritesLoading: state.getFavoritesResult.loading,
        getFavoritesError: state.getFavoritesResult.error,
        downloadCatalogResult: state.downloadCatalogResult,
        downloadCatalogLoading: state.downloadCatalogResult.loading,
        downloadCatalogError: state.downloadCatalogResult.error,
        feedbackResult: state.submitFeedbackResult.data,
        feedbackError: state.submitFeedbackResult.error,
        feedbackLoading: state.submitFeedbackResult.loading,
        favoriteResult: state.saveFavoriteResult.data,
        favoriteError: state.saveFavoriteResult.error,
        favoriteLoading: state.saveFavoriteResult.loading,
        getTagsValuesResult: state.getTagsValuesResult.data,
        saveRatingResult: state.saveStarRatingResult.data,
        saveRatingError: state.saveStarRatingResult.error,
        saveVotingResult: state.saveVotingResult.data,
        saveVotingError: state.saveVotingResult.error,
        recipeByIdresult: state.getRecipeByIdResult.data,
        saveLinkClickResult: state.saveLinkClickResult.data,
        updateCartResult: state.updateCartResult.data,
        updateCartResultLoading: state.updateCartResult.loading,
        updateCartResultError: state.updateCartResult.error,
        getSubscriptionResult: state.getSubscriptionResult.data,
        getSubscriptionResultLoading: state.getSubscriptionResult.loading,
        getSubscriptionResultError: state.getSubscriptionResult.error,
        getCartItemsResult: state.getCartItemsResult.data,
        getCartItemsResultLoading: state.getCartItemsResult.loading,
        getCartItemsResultError: state.getCartItemsResult.error,
        likeResult: state.saveLikeResult.data,
        likeError: state.saveLikeResult.error,
        likeLoading: state.saveLikeResult.loading,
        clickCountsResult: state.getClickCountsResult.data,

    }
}

type StateToPropsType = ReturnType<typeof mapStateToProps>
type DispatchToPropsType = typeof mapDispatchToProps

export default connect<StateToPropsType, DispatchToPropsType>(
    mapStateToProps,
    mapDispatchToProps
)(FavoritesContainer)
