import { FunctionComponent } from 'react'
import { Nav } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

const SideLinkPanelContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 14rem;
    height: 100%;
    background-color: rgb(242, 242, 242);
    @media screen and (max-width: 1900px) {
        width: 9rem;
    }
`

const SideLinkPanelDiv = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2rem 0rem 2rem 1rem;
`
const StyledNavLink = styled(Nav.Link)`
    font-family: 'IntelOne Text';
    font-size: 14px;
    color: #000;
    font-weight: 500;
    line-height: 3rem;
    padding: 0.6rem 0rem 0.6rem 1rem;
    &.active {
        border-left: 4px solid #61dafb; //4px solid rgb(84, 255, 46);
        font-weight: 700;
        line-height: 2rem;
    }
    &:hover {
        color: #000;
        font-weight: 700;
    }
    &:disabled {
        color: #00a3fb;
    }
`
const SubLinkHeading = styled.div`
    display: flex;
    font-family: 'IntelOne Display';
    font-size: 14px;
    color: rgb(153, 153, 153);
    text-transform: uppercase;
    padding: 1rem;
`
const DisabledNavLink = styled(SubLinkHeading)`
    text-transform: none;
`
const LeftNavTextSpan = styled.span`
    font-family: 'IntelOne Text';
    font-size: 14px;
    color: #000;
    &.active {
        margin-left: -4px;
    }
`

export interface ISideLinkPanel {}

export const SideLinkPanel: FunctionComponent<
    ISideLinkPanel
> = ({}: ISideLinkPanel) => {
    let isDiscoverActive = true
    let isRetrievalActive = false
    let isCompareActive = false
    let isVisualActive = false
    let isSummarizationActive = false
    let isTranslationActive = false

    if (location.pathname.indexOf('/explore') > -1) isDiscoverActive = true
    if (location.pathname.indexOf('/retrieval') > -1) {
        isDiscoverActive = false
        isRetrievalActive = true
        isVisualActive = false
    }
    if (location.pathname.indexOf('/evaluate') > -1) {
        //rename Compare to evaluate
        isDiscoverActive = false
        isCompareActive = true
        isVisualActive = false
    }
    if (location.pathname.indexOf('/visual') > -1) {
        isDiscoverActive = false
        isCompareActive = false
        isVisualActive = true
    }
    if (location.pathname.indexOf('/summarization') > -1) {
        isDiscoverActive = false
        isSummarizationActive = true
    }
    if (location.pathname.indexOf('/translation') > -1) {
        isDiscoverActive = false
        isTranslationActive = true
    }

    return (
        <SideLinkPanelContainer>
            <SideLinkPanelDiv>
                <StyledNavLink
                    href="/explore"
                    className={isDiscoverActive ? 'active' : ''}
                >
                    <LeftNavTextSpan
                        className={isDiscoverActive ? 'active' : ''}
                    >
                        Explore
                    </LeftNavTextSpan>
                </StyledNavLink>

                <StyledNavLink
                    href="/explore/evaluate"
                    className={isCompareActive ? 'active' : ''}
                >
                    <LeftNavTextSpan
                        className={isCompareActive ? 'active' : ''}
                    >
                        Evaluate
                    </LeftNavTextSpan>
                </StyledNavLink>
                <StyledNavLink
                    href="/explore/retrieval"
                    className={isRetrievalActive ? 'active' : ''}
                >
                    <LeftNavTextSpan
                        className={isRetrievalActive ? 'active' : ''}
                    >
                        Retrieval
                    </LeftNavTextSpan>
                </StyledNavLink>
                <StyledNavLink
                    href="/explore/summarization"
                    className={isSummarizationActive ? 'active' : ''}
                >
                    <LeftNavTextSpan
                        className={isSummarizationActive ? 'active' : ''}
                    >
                        Summarization
                    </LeftNavTextSpan>
                </StyledNavLink>
                <StyledNavLink
                    href="/explore/visual"
                    className={isVisualActive ? 'active' : ''}
                >
                    <LeftNavTextSpan>Visual</LeftNavTextSpan>
                </StyledNavLink>

                <StyledNavLink
                    href="/explore/translation"
                    className={isTranslationActive ? 'active' : ''}
                >
                    <LeftNavTextSpan>Translation</LeftNavTextSpan>
                </StyledNavLink>

                {/* <DisabledNavLink style={{ color: 'rgb(153, 153, 153); ' }}>
                    <span>Search</span>
                </DisabledNavLink> */}

                {/* <DisabledNavLink style={{ color: 'rgb(153, 153, 153); ' }}>
                    <span>Search</span>
                </DisabledNavLink> */}

                {/* <StyledNavLink href="/explore/speech" disabled={true}>
                    <span>Speech</span>
                </StyledNavLink>
                <StyledNavLink href="/explore/biology" disabled={true}>
                    <span>Biology</span>
                </StyledNavLink>
                <SubLinkHeading>industries</SubLinkHeading>
                <StyledNavLink href="/explore/gaming" disabled={true}>
                    <span>Gaming</span>
                </StyledNavLink>
                <StyledNavLink href="/explore/healthcare" disabled={true}>
                    <span>Healthcare</span>
                </StyledNavLink>
                <StyledNavLink href="/explore/industrial" disabled={true}>
                    <span>Industrial</span>
                </StyledNavLink> */}
            </SideLinkPanelDiv>
        </SideLinkPanelContainer>
    )
}

export default SideLinkPanel
