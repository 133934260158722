import { ChangeEvent, MouseEvent, useEffect, useState } from 'react'
import DashboardFilters, {
    DateRange,
    FilterType,
    TimeFiltersOption,
} from './DashboardFilters'
import {
    getAllNoHits,
    getAnalytics,
    getCardsActivitySummary,
    getDashboardData,
    getDisengagedUsers,
    getEngagedUsers,
    getFirstTimeCustomers,
    getNoHits,
    getSearchDist,
    getTotalHits,
    getTotalNoHits,
    getUniqueLogins,
} from '../../store/dashboard.slice'
import moment, { Moment } from 'moment/moment'
import MostMetricsCards from './cards/MostMetricsCards'
import { useAppDispatch, useAppSelector } from '../../config/hooks'
import { LoadingState } from '../../models/loadingState'
import SearchesByDomainCard from './cards/searches-by-domain/SearchesByDomainCard'
import { Grid, SelectChangeEvent } from '@mui/material'
import DownloadCountsCard from './cards/DownloadCountsCard'
import LinkClicksCountsCard from './cards/LinkClicksCountsCard'
import AppSearchesCard from './cards/AppSearchesCard'
import TopViewsCard from './cards/TopViewsCard'
import SearchHitsCard from './cards/SearchHitsCard'
import FirstTimeCustomersCard from './cards/FirstTimeCustomersCard'
import UsersEngagementCard from './cards/UsersEngagementCard'

export const defaultSelectedDomainSearchDist = 'all'

const DashboardPanel = () => {
    const uniqueLoginsLoading = useAppSelector<any>(
        (state: any) => state.getUniqueLoginsResult.loading
    )
    const dashboardDataLoading = useAppSelector<any>(
        (state: any) => state.getDashboardDataResult.loading
    )
    const totalHitsLoading = useAppSelector<any>(
        (state: any) => state.getTotalHitsResult.loading
    )
    const totalNoHitsLoading = useAppSelector<any>(
        (state: any) => state.getTotalNoHitsResult.loading
    )
    const analyticsLoading = useAppSelector(
        (state) => state.getAnalyticsResult.loading
    )
    const hitsLoading = useAppSelector<any>(
        (state: any) => state.getTotalHitsResult.loading
    )
    const noHitsLoading = useAppSelector<any>(
        (state: any) => state.getTotalNoHitsResult.loading
    )
    const disengagedUsersLoading = useAppSelector(
        (state) => state.getDisengagedUsersResult.loading
    )
    const engagedUsersLoading = useAppSelector(
        (state) => state.getEngagedUsersResult.loading
    )
    const cardsActivitySummaryLoading = useAppSelector(
        (state) => state.getCardsActivitySummaryResult.loading
    )
    const firstTimeCustomersLoading = useAppSelector<any>(
        (state) => state.getFirstTimeCustomersResult.loading
    )
    const searchDistLoading = useAppSelector<any>(
        (state) => state.getSearchDistResult.loading
    )
    const allNoHitLoading = useAppSelector<any>(
        (state) => state.getAllNoHitsResult.loading
    )
    const missesByDomainLoading = useAppSelector(
        (state) => state.getNoHitsResult.loading
    )

    const [isDashboardLoading, setIsDashboardLoading] = useState(false)

    useEffect(() => {
        const loadingStates = [
            uniqueLoginsLoading,
            dashboardDataLoading,
            totalHitsLoading,
            totalNoHitsLoading,
            analyticsLoading,
            hitsLoading,
            noHitsLoading,
            disengagedUsersLoading,
            engagedUsersLoading,
            cardsActivitySummaryLoading,
            firstTimeCustomersLoading,
            searchDistLoading,
            allNoHitLoading,
            missesByDomainLoading,
        ]
        setIsDashboardLoading(
            loadingStates.some(
                (state: LoadingState) => state === LoadingState.Pending
            )
        )
    }, [
        uniqueLoginsLoading,
        dashboardDataLoading,
        totalHitsLoading,
        totalNoHitsLoading,
        analyticsLoading,
        hitsLoading,
        noHitsLoading,
        disengagedUsersLoading,
        engagedUsersLoading,
        cardsActivitySummaryLoading,
        firstTimeCustomersLoading,
        searchDistLoading,
        allNoHitLoading,
        missesByDomainLoading,
    ])

    const [selectedFilterType, setSelectedFilterType] =
        useState<FilterType>('time-filters')
    const [selectedTimeFilter, setSelectedTimeFilter] =
        useState<TimeFiltersOption>(TimeFiltersOption.OneMonth)
    const [selectedDateRange, setSelectedDateRange] = useState<DateRange>([
        moment().subtract(1, 'month'),
        moment(),
    ])
    const [excludeIntelSwitch, setExcludeIntelSwitch] = useState(false)
    const [selectedDomainSearchDist, setSelectedDomainSearchDist] =
        useState<string>(defaultSelectedDomainSearchDist)

    const dispatch = useAppDispatch()
    const fetchDashboardData = () => {
        const [fromDate, toDate] = getTimeRange()

        let cardsActivitySummaryFilterObj: { [key: string]: any } = {}
        if (excludeIntelSwitch) {
            cardsActivitySummaryFilterObj.excludeWords = 'intel'
        }

        let searchDistFilterObj: { [key: string]: any } = {}
        if (selectedDomainSearchDist !== defaultSelectedDomainSearchDist) {
            searchDistFilterObj = { domain: selectedDomainSearchDist, time: 1 }
        }

        Promise.all([
            dispatch(getDashboardData({ fromDate, toDate })),
            dispatch(getUniqueLogins(fromDate)),
            dispatch(getTotalHits({ fromDate, toDate })),
            dispatch(getTotalNoHits({ fromDate, toDate })),
            dispatch(getAnalytics({ fromDate, toDate })),
            dispatch(
                getCardsActivitySummary({
                    fromDate,
                    toDate,
                    filterObj: cardsActivitySummaryFilterObj,
                })
            ),
            dispatch(getEngagedUsers({ fromDate, toDate })),
            dispatch(getDisengagedUsers({ fromDate, toDate })),
            dispatch(getFirstTimeCustomers({ fromDate, toDate })),
            dispatch(
                getSearchDist({
                    fromDate,
                    toDate,
                    filterObj: searchDistFilterObj,
                })
            ),
            dispatch(getNoHits({ fromDate, toDate })),
            dispatch(getAllNoHits({ fromDate, toDate })),
        ])
    }

    useEffect(() => {
        fetchDashboardData()
    }, [])

    useEffect(() => {
        setSelectedDomainSearchDist(defaultSelectedDomainSearchDist)
        fetchDashboardData()
    }, [selectedFilterType, selectedTimeFilter])

    useEffect(() => {
        const [fromDate, toDate] = getTimeRange()
        let filterObj: { [key: string]: any } = {}
        if (excludeIntelSwitch) {
            filterObj.excludeWords = 'intel'
        }
        dispatch(getCardsActivitySummary({ fromDate, toDate, filterObj }))
    }, [excludeIntelSwitch])

    useEffect(() => {
        const [fromDate, toDate] = getTimeRange()
        let filterObj
        if (selectedDomainSearchDist !== defaultSelectedDomainSearchDist) {
            filterObj = { domain: selectedDomainSearchDist, time: 1 }
        }
        Promise.all([
            dispatch(getSearchDist({ fromDate, toDate, filterObj })),
            dispatch(getNoHits({ fromDate, toDate })),
            dispatch(getAllNoHits({ fromDate, toDate })),
        ])
    }, [selectedDomainSearchDist])

    const getTimeRange = () => {
        let [fromDate, toDate] = ['', '']
        if (selectedFilterType === 'date-range') {
            ;[fromDate, toDate] = selectedDateRange.map((value) =>
                value.toISOString()
            )
        }
        if (selectedFilterType === 'time-filters') {
            const now = moment()
            const fromMoment = moment()

            if (selectedTimeFilter === TimeFiltersOption.OneDay) {
                fromMoment.subtract(1, 'days')
            } else if (selectedTimeFilter === TimeFiltersOption.OneWeek) {
                fromMoment.subtract(7, 'days')
            } else if (selectedTimeFilter === TimeFiltersOption.OneMonth) {
                fromMoment.subtract(1, 'months')
            } else if (selectedTimeFilter === TimeFiltersOption.ThreeMonths) {
                fromMoment.subtract(3, 'months')
            } else if (selectedTimeFilter === TimeFiltersOption.SixMonths) {
                fromMoment.subtract(6, 'months')
            } else if (selectedTimeFilter === TimeFiltersOption.OneYear) {
                fromMoment.subtract(1, 'years')
            }

            fromDate = fromMoment.toISOString()
            toDate = now.toISOString()
        }
        return [fromDate, toDate]
    }

    const handleFilterSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
        const newSelectedFilterType = event.target.checked
            ? 'date-range'
            : 'time-filters'
        setSelectedFilterType(newSelectedFilterType)
    }

    const handleTimeFilterChange = (
        _: MouseEvent<HTMLElement>,
        newTimeFilter: TimeFiltersOption | null
    ) => {
        if (newTimeFilter !== null) {
            setSelectedTimeFilter(newTimeFilter)
        }
    }

    const handleStartDateChange = (newStartDate: Moment | null) => {
        if (newStartDate) {
            setSelectedDateRange((prevDateRange) => [
                newStartDate,
                prevDateRange[1],
            ])
        }
    }

    const handleEndDateChange = (newEndDate: Moment | null) => {
        if (newEndDate) {
            setSelectedDateRange((prevDateRange) => [
                prevDateRange[0],
                newEndDate,
            ])
        }
    }

    const handleExcludeIntelSwitchChange = (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        setExcludeIntelSwitch(event.target.checked)
    }

    const handleSelectedDomainSearchDistChange = (event: SelectChangeEvent) => {
        setSelectedDomainSearchDist(event.target.value)
    }

    return (
        <>
            <DashboardFilters
                disabled={isDashboardLoading}
                selectedFilterType={selectedFilterType}
                selectedTimeFilter={selectedTimeFilter}
                handleFilterSwitchChange={handleFilterSwitchChange}
                handleTimeFilterChange={handleTimeFilterChange}
                selectedDateRange={selectedDateRange}
                handleStartDateChange={handleStartDateChange}
                handleEndDateChange={handleEndDateChange}
                onApplyDateRangeFilters={fetchDashboardData}
            />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <MostMetricsCards />
                </Grid>
                <Grid item xs={12}>
                    <DownloadCountsCard
                        excludeIntelSwitch={excludeIntelSwitch}
                        handleExcludeIntelSwitchChange={
                            handleExcludeIntelSwitchChange
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <LinkClicksCountsCard />
                </Grid>
                <Grid item xs={12}>
                    <FirstTimeCustomersCard />
                </Grid>
                <Grid item xs={12}>
                    <UsersEngagementCard />
                </Grid>
                <Grid item xs={12} md={4}>
                    <AppSearchesCard />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TopViewsCard />
                </Grid>
                <Grid item xs={12} md={4}>
                    <SearchHitsCard />
                </Grid>
                <Grid item xs={12}>
                    <SearchesByDomainCard
                        selectedDomainSearchDist={selectedDomainSearchDist}
                        handleSelectedDomainSearchDistChange={
                            handleSelectedDomainSearchDistChange
                        }
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default DashboardPanel
