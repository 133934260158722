/************************************************
 * Copyright (C) 2024 Intel Corporation
 ************************************************/
import styled from 'styled-components'
import { FunctionComponent, useState, useCallback } from 'react'
import { LoadingState } from '../../models/loadingState'
import { Alert, CloseButton, Modal } from 'react-bootstrap'
import SummarizationInputPanel from './summarizationInputPanel'
import OutputPanel from './outputPanel'
import { postChatCompletion } from '../../store/chatCompletion.slice'
import '../components/emblaCarousel/css/embla.css'
import React from 'react'

const ViewPortControl = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    //margin-top: 20px;
    border-top: solid 1px;
    border-color: rgb(101, 49, 113);
    //border: 1px solid rgb(101, 49, 113);
    min-height: 15rem;
    margin: 1.6rem 1.5rem;
    background-color: #e8e8e8; //rgb(12, 12, 12, 1);
`

const AlertComponent = styled(Alert)`
    display: flex;
    margin-top: 1rem;
    justify-content: center;
    border-radius: 0;
`
const ButtonTab = styled.button`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgb(44, 44, 44);
    border-radius: 0px;
    border: none;
    color: rgb(224, 224, 224);
    font-family: 'IntelOne Display';
    font-size: 0.8rem;
    font-weight: 500;
    padding: 3px 12px;
    text-transform: capitalize;
    margin-right: 0.5rem;
    &:hover {
        color: rgb(224, 224, 224);
        border: none;
        font-family: 'IntelOne Display';
        font-size: 0.8rem;
        font-weight: 500;
    }
    &:focus {
        color: rgb(224, 224, 224);
        border: none;
        font-family: 'IntelOne Display';
        font-size: 0.8rem;
        font-weight: 500;
    }
    &.active {
        border-bottom: 4px solid #61dafb; //4px solid rgb(84, 255, 46);
        font-weight: 500;
    }
    &:disabled {
        opacity: 0.5;
        --tw-text-opacity: 0.1;
    }
`
const ModalPopupContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

export interface ISummarizationModelPanelProps {
    postChatQuery: typeof postChatCompletion
    chatCompletionAnswer: any
    chatCompletionLoading: LoadingState
    chatCompletionError: any
    selectedModelName: string
    selectedModelPublishedBy: string
    showModel: boolean
    setShowModel: any
    isStream: boolean
}

const SummarizationModelPanel: FunctionComponent<
    ISummarizationModelPanelProps
> = ({
    postChatQuery,
    chatCompletionAnswer,
    chatCompletionLoading,
    chatCompletionError,
    selectedModelName,
    selectedModelPublishedBy,
    showModel,
    setShowModel,
    isStream,
}: ISummarizationModelPanelProps) => {
    const [currentPromptMessage, setCurrentPromptMessage] = useState<string>('')
    const [selectedOutputTab, setSelectedOutputTab] = useState<string>('output')

    const warningMessageLine1 = `AI models generate responses and outputs based on complex algorithms and machine learning techniques, and those responses or outputs may be inaccurate or indecent.`
    const warningMessageLine2 = `By testing this model, you assume the risk of any harm caused by any response or output of the model. Please do not upload any confidential information or personal data. Your use is logged for security.`
    const headingText = 'Trending Now'
    const subHeadingText =
        "The leading open models built by the community, optimized and accelerated by INTEL's enterprise-ready inference runtime"
    const [isResetClicked, setIsResetClicked] = useState(false)

    const handleOutputButtonTabClick = useCallback(
        (name: string) => {
            setSelectedOutputTab(name)
        },
        [selectedOutputTab]
    )

    return (
        <ModalPopupContainer>
            <Modal
                show={showModel}
                onHide={() => setShowModel((show: boolean) => !show)}
                backdrop="static"
                keyboard={true}
                size="xl"
            >
                <Modal.Body>
                    <CloseButton
                        className="p-1"
                        onClick={() => setShowModel((show: boolean) => !show)}
                        style={{
                            borderRadius: 0,
                            fontSize: '1.5rem',
                            marginRight: '0.3rem',
                        }}
                    />
                    <ViewPortControl>
                        <AlertComponent
                            style={{ width: '100%' }}
                            variant="danger"
                            show={chatCompletionError ? true : false}
                        >
                            {`We are experiencing issues retrieving the data. ${chatCompletionError?.message}`}
                        </AlertComponent>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '50%',
                                    height: '100%',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        padding: '0.5rem',
                                    }}
                                >
                                    <ButtonTab>Input</ButtonTab>
                                </div>

                                <div id="chatDiv">
                                    <SummarizationInputPanel
                                        postChatQuery={postChatQuery}
                                        chatCompletionAnswer={
                                            chatCompletionAnswer
                                        }
                                        chatCompletionLoading={
                                            chatCompletionLoading
                                        }
                                        chatCompletionError={
                                            chatCompletionError
                                        }
                                        selectedModelName={selectedModelName}
                                        selectedModelPublishedBy={
                                            selectedModelPublishedBy
                                        }
                                        setIsResetClick={setIsResetClicked}
                                        isStream={isStream}
                                    ></SummarizationInputPanel>
                                </div>
                            </div>

                            <div
                                id="seprator"
                                style={{
                                    width: '1px',
                                    margin: '0 1rem',
                                    backgroundColor: 'rgb(101, 49, 113)',
                                }}
                            ></div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '50%',
                                    height: '100%',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        padding: '0.5rem',
                                    }}
                                >
                                    <ButtonTab
                                        className={
                                            selectedOutputTab === 'output'
                                                ? 'active'
                                                : ''
                                        }
                                        onClick={() =>
                                            handleOutputButtonTabClick('output')
                                        }
                                    >
                                        Output
                                    </ButtonTab>
                                    <ButtonTab
                                        className={
                                            selectedOutputTab === 'python'
                                                ? 'active'
                                                : ''
                                        }
                                        onClick={() =>
                                            handleOutputButtonTabClick('python')
                                        }
                                    >
                                        Python
                                    </ButtonTab>
                                    <ButtonTab
                                        disabled
                                        className={
                                            selectedOutputTab === 'container'
                                                ? 'active'
                                                : ''
                                        }
                                        onClick={() =>
                                            handleOutputButtonTabClick(
                                                'container'
                                            )
                                        }
                                    >
                                        Container
                                    </ButtonTab>
                                </div>
                                <div id="outputtDiv">
                                    <OutputPanel
                                        chatMessage={
                                            currentPromptMessage
                                                ? currentPromptMessage
                                                : 'Text..'
                                        }
                                        selectedModelName={selectedModelName}
                                        isSummarizationOutput={true}
                                        summarizationOutputData={
                                            chatCompletionAnswer
                                        }
                                        summarizationOutputLoading={
                                            chatCompletionLoading
                                        }
                                        isResetClicked={isResetClicked}
                                        isDiscoverCodeOutput={true}
                                        selectedOutputTab={selectedOutputTab}
                                        isStream={isStream}
                                    ></OutputPanel>
                                </div>
                            </div>
                        </div>
                    </ViewPortControl>
                </Modal.Body>
            </Modal>
        </ModalPopupContainer>
    )
}

export default SummarizationModelPanel
