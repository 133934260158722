import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { LoadingState } from '../models/loadingState'
import { UnSubscribeRequest } from '../models/unSubscribeRequest'
import SubscriptionService from '../services/subscriptionService'

const initialState = {
    data: null,
    loading: LoadingState.Idle,
    error: null,
}

export const getSubscription = createAsyncThunk('getSubscription', async () => {
    return await SubscriptionService.getSubscription()
})

export const removeSubscription = createAsyncThunk(
    'removeSubscription',
    async (unsubscribeRequest: UnSubscribeRequest) => {
        return await SubscriptionService.removeSubscription(unsubscribeRequest)
    }
)

const getSubscriptionSlice = createSlice({
    name: 'getSubscription',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(getSubscription.pending, (state, _) => {
            if (state.loading === LoadingState.Idle) {
                state.loading = LoadingState.Pending
            }
        })
        .addCase(getSubscription.fulfilled, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.data = action.payload.data
            }
        })
        .addCase(getSubscription.rejected, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.error = action.error
            }
        })
    },
})

const removeSubscriptionSlice = createSlice({
    name: 'removeSubscription',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(removeSubscription.pending, (state, _) => {
            if (state.loading === LoadingState.Idle) {
                state.loading = LoadingState.Pending
            }
        })
        .addCase(removeSubscription.fulfilled, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.data = action.payload.data
            }
        })
        .addCase(removeSubscription.rejected, (state, action: any) => {
            if (state.loading === LoadingState.Pending) {
                state.loading = LoadingState.Idle
                state.error = action.error
            }
        })
    },
})

export const getSubscriptionResult = getSubscriptionSlice.reducer
export const removeSubscriptionResult = removeSubscriptionSlice.reducer
