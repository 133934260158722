/************************************************
 * Copyright (C) 2021 Intel Corporation
 ************************************************/
import styled from 'styled-components'
import { FunctionComponent, Dispatch } from 'react'
import { connect } from 'react-redux'

import {
    deleteUploadRequest,
    getAllUploadRequest,
} from '../../store/allRequest.slice'
import { AllRequestContainerLogic } from './allRequestContainer.hook'
import FooterPanel from '../../layout/footer'
import { FormLabel, Alert } from 'react-bootstrap'
import UserPanel from '../../layout/userProfile'
import StringUtils from '../../lib/stringUtils'
import HeaderPanel from '../../layout/header'
import { useAppSelector } from '../../config/hooks'
import AllRequestPanel from '../../components/allRequestPanel/allRequestPanel'
import { _getDecryptedValueFromStorage } from '../../lib/encryptStorage'
import { UserOtherMetaRoles } from '../../models/userRoles'

const AllRequestPanelContainer = styled.div`
    display: flex;
    height: ${(props) => props.theme.size.mainContainerHeight};
    width: ${(props) => props.theme.size.mainContainerWidth};
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: space-between;
    @media screen and (min-width: 80rem) {
        overflow-x: hidden;
    }
    @media screen and (max-width: 500px) {
        width: 100%;
        overflow-x: hidden;
    }
`

const AllRegistryHorizontalContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: space-between;
`
const AllRegistryBodyContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: center;
    background-color: #f4f5f5;
    padding-bottom: 10rem;
    width: 100%;
`

const AllRegistryBodyContainerReposition = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    //margin-left: 2rem;
`
const AllRegistryPanelWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: flex-start;
`
const PageTitleTextLabel = styled(FormLabel)`
    ${(props) => props.theme.typography.xxxLarge}
    text-align: left;
    font-weight: 500;
    font-size: 2.25rem;
`
const AlertComponent = styled(Alert)`
    display: flex;
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: auto;
    justify-content: center;
`
const NotAutorizeContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: left;
`
const NotAutorizeLabel = styled(FormLabel)`
    ${(props) => props.theme.typography.xLarge}
    text-align: left;
    font-weight: 400;
    font-size: 1rem;
    margin-bottom: 50rem;
    color: red;
`

export interface IAllRequestContainerProps {
    getAllUploadRequest: any
    userId: string
    appName: string
    isIntelEmployee: boolean
}

const AllRequestContainer: FunctionComponent<IAllRequestContainerProps> & {
    defaultProps: Partial<IAllRequestContainerProps>
} = ({ userId, appName, isIntelEmployee }: IAllRequestContainerProps) => {
    let otherMetaPermissions: string[] = []
    let intelEmpStatus = localStorage.getItem('isIntelEmployee')
    isIntelEmployee = !StringUtils.isNullOrUndefinedOrEmpty(intelEmpStatus)
        ? Boolean(intelEmpStatus)
        : false

    const {} = AllRequestContainerLogic({
        getAllUploadRequest,
        appName,
        userId,
        isIntelEmployee,
    } as IAllRequestContainerProps)

    let otherPermissions = _getDecryptedValueFromStorage('otherMetaPermissions')

    if (otherPermissions) {
        otherMetaPermissions = otherPermissions.split(',')
    }

    const isDataController = otherMetaPermissions.includes(
        UserOtherMetaRoles.DataController
    )

    const allUploadRequestsResult = useAppSelector<any>(
        (state) => state.getAllUploadRequestResult
    )

    return (
        <AllRequestPanelContainer>
            <HeaderPanel />
            <AllRegistryHorizontalContainer>
                <AllRegistryBodyContainer>
                    <AllRegistryBodyContainerReposition>
                        <UserPanel></UserPanel>
                        <AllRegistryPanelWrapper>
                            {isIntelEmployee && isDataController ? (
                                <AllRequestPanel
                                    title={'All Upload Requests'}
                                    getData={getAllUploadRequest}
                                    optimizationAdd={true}
                                    dataResponse={allUploadRequestsResult.data}
                                    dataLoading={
                                        allUploadRequestsResult.loading
                                    }
                                    dataError={allUploadRequestsResult.error}
                                />
                            ) : (
                                <NotAutorizeContainer>
                                    <NotAutorizeLabel>
                                        Permission denied!
                                    </NotAutorizeLabel>
                                </NotAutorizeContainer>
                            )}
                        </AllRegistryPanelWrapper>
                    </AllRegistryBodyContainerReposition>
                </AllRegistryBodyContainer>
            </AllRegistryHorizontalContainer>
            <FooterPanel></FooterPanel>
        </AllRequestPanelContainer>
    )
}

AllRequestContainer.defaultProps = {}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        getAllUploadRequest: (allUploadRequest: any) =>
            dispatch(getAllUploadRequest(allUploadRequest)),
    }
}

const mapStateToProps = (state: any) => {
    return {}
}

type StateToPropsType = ReturnType<typeof mapStateToProps>
type DispatchToPropsType = typeof mapDispatchToProps

export default connect<StateToPropsType, DispatchToPropsType>(
    mapStateToProps,
    mapDispatchToProps
)(AllRequestContainer)
