import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { CatalogSearchRequest } from '../models/catalogSearchrequest'
import { LoadingState } from '../models/loadingState'
import CatalogService from '../services/catalogService'
import { RecipeFeedbackRequest } from '../models/recipeFeedbackRequest'
import { RecipeAddFavoriteRequest } from '../models/recipeAddFavoriteRequest'
import { RecipeStarRatingRequest } from '../models/recipeStarRatingRequest'
import { RecipeAddVotingRequest } from '../models/recipeAddVotingRequest'
import catalogService from '../services/catalogService'
import { RecipeAddLinkClickRequest } from '../models/recipeAddLinkClickRequest'
import { SiteFeedbackRequest } from '../models/siteFeedbackRequest'
import CatalogPublicService from '../services/catalogPublicService'
import { RecipeAddLikeRequest } from '../models/recipeAddLikeRequest'
import { RecipeAddSubscriptionRequest } from '../models/recipeAddSubscriptionRequest'

const initialState = {
    data: null,
    loading: LoadingState.Idle,
    error: null,
}

// First, create the thunk
export const getCatalog = createAsyncThunk(
    'catalog/getCatalog',
    async (catalogSearchCriteria: CatalogSearchRequest) => {
        if (catalogSearchCriteria.isPublicSearch) {
            return await CatalogPublicService.searchRecipes(
                catalogSearchCriteria
            )
        } else return await CatalogService.searchRecipes(catalogSearchCriteria)
    }
)

// Then, handle actions in your reducers:
const getCatalogSlice = createSlice({
    name: 'catalog',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCatalog.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(getCatalog.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload.data
                    state.error = null
                }
            })
            .addCase(getCatalog.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})

// First, create the thunk
export const downloadCatalog = createAsyncThunk(
    'catalog/downloadCatalog',
    async (downloadURL: string) => {
        return await CatalogService.downloadRecipe(downloadURL)
    }
)

// Then, handle actions in your reducers:
const downloadCatalogSlice = createSlice({
    name: 'downloadCatalog',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(downloadCatalog.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(downloadCatalog.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload.data
                    state.error = null
                }
            })
            .addCase(downloadCatalog.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})

let response: any = {}
export const submitFeedback = createAsyncThunk(
    'submitFeedback',
    async (recipeFeedbackRequest: RecipeFeedbackRequest) => {
        response = await CatalogService.submitRecipeFeedback(
            recipeFeedbackRequest
        )
    }
)
// Then, handle actions in your reducers:
const submitFeedbackSlice = createSlice({
    name: 'submitFeedback',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(submitFeedback.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(submitFeedback.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    if (
                        action.payload === undefined &&
                        response.data !== undefined
                    )
                        state.data = response.data
                    else state.data = action.payload.data
                    state.error = null
                }
            })
            .addCase(submitFeedback.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})
export const saveFavorite = createAsyncThunk(
    'saveFavorite',
    async (addFavoriteRequest: RecipeAddFavoriteRequest) => {
        response = await CatalogService.saveRecipeFavorite(addFavoriteRequest)
    }
)
// Then, handle actions in your reducers:
const saveFavoriteSlice = createSlice({
    name: 'saveFavorite',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(saveFavorite.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(saveFavorite.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    if (
                        action.payload === undefined &&
                        response.data !== undefined
                    )
                        state.data = response.data
                    else state.data = action.payload.data
                    state.error = null
                }
            })
            .addCase(saveFavorite.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})
export const getTagsValues = createAsyncThunk(
    'catalog/getTagsValues',
    async (tagNames: string) => {
        return await CatalogService.getTagsValues(tagNames)
    }
)

// Then, handle actions in your reducers:
const getTagsValuesSlice = createSlice({
    name: 'getTagsValues',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getTagsValues.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(getTagsValues.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload.data
                    state.error = null
                }
            })
            .addCase(getTagsValues.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})
export const saveStarRating = createAsyncThunk(
    'saveStarRating',
    async (addStarRatingRequest: RecipeStarRatingRequest) => {
        response = await CatalogService.saveRecipeStarRating(
            addStarRatingRequest
        )
    }
)
// Then, handle actions in your reducers:
const saveStarRatingSlice = createSlice({
    name: 'saveStarRating',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(saveStarRating.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(saveStarRating.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    if (
                        action.payload === undefined &&
                        response.data !== undefined
                    )
                        state.data = response.data
                    else state.data = action.payload.data
                    state.error = null
                }
            })
            .addCase(saveStarRating.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})
export const saveVoting = createAsyncThunk(
    'saveVoting',
    async (saveVotingRequest: RecipeAddVotingRequest) => {
        response = await CatalogService.saveRecipeVoting(saveVotingRequest)
    }
)
// Then, handle actions in your reducers:
const saveVotingSlice = createSlice({
    name: 'saveVoting',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(saveVoting.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(saveVoting.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    if (
                        action.payload === undefined &&
                        response.data !== undefined
                    )
                        state.data = response.data
                    else state.data = action.payload.data
                    state.error = null
                }
            })
            .addCase(saveVoting.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})
export const getRecipeById = createAsyncThunk(
    'getRecipeById',
    async (id: any) => {
        return await catalogService.getRecipeById(id)
    }
)

// Then, handle actions in your reducers:
const getRecipeByIdSlice = createSlice({
    name: 'getRecipeById',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getRecipeById.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(getRecipeById.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload.data
                }
            })
            .addCase(getRecipeById.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})
export const saveLinkClick = createAsyncThunk(
    'saveLinkClick',
    async (addLinkClickRequest: RecipeAddLinkClickRequest) => {
        response = await CatalogService.addRecipeLinkClickLog(
            addLinkClickRequest
        )
    }
)
const saveLinkClickSlice = createSlice({
    name: 'saveLinkClick',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(saveLinkClick.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(saveLinkClick.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    if (
                        action.payload === undefined &&
                        response.data !== undefined
                    )
                        state.data = response.data
                    else state.data = action.payload.data
                    state.error = null
                }
            })
            .addCase(saveLinkClick.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})
export const saveSiteFeedback = createAsyncThunk(
    'saveSiteFeedback',
    async (siteFeedbackRequest: SiteFeedbackRequest) => {
        response = await CatalogService.saveSiteFeedback(siteFeedbackRequest)
    }
)
// Then, handle actions in your reducers:
const saveSiteFeedbackSlice = createSlice({
    name: 'saveSiteFeedback',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(saveSiteFeedback.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(saveSiteFeedback.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    if (
                        action.payload === undefined &&
                        response.data !== undefined
                    )
                        state.data = response.data
                    else state.data = action.payload.data
                    state.error = null
                }
            })
            .addCase(saveSiteFeedback.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})
export const getLastLogin = createAsyncThunk('getLastLogin', async () => {
    return await catalogService.getLastLogin()
})

// Then, handle actions in your reducers:
const getLastLoginSlice = createSlice({
    name: 'getLastLogin',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getLastLogin.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(getLastLogin.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload.data
                }
            })
            .addCase(getLastLogin.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})

export const getBenchmarks = createAsyncThunk(
    'benchmarks/getBenchmarks',
    async (id: string) => {
        return await catalogService.getBenchmarks(id)
    }
)

// Then, handle actions in your reducers:
const getBenchmarksSlice = createSlice({
    name: 'benchmarks',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getBenchmarks.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(getBenchmarks.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload.data
                }
            })
            .addCase(getBenchmarks.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})

export const getFiltersValues = createAsyncThunk(
    'catalog/getFiltersValues',
    async (filtersParam: string) => {
        return await CatalogService.getFiltersValues(filtersParam)
    }
)

const getFiltersValuesSlice = createSlice({
    name: 'getFiltersValues',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getFiltersValues.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(getFiltersValues.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload.data
                }
            })
            .addCase(getFiltersValues.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})
export const getPublicFiltersValues = createAsyncThunk(
    'catalog/getPublicFiltersValues',
    async (filtersParam: string) => {
        return await CatalogPublicService.getPublicFiltersValues(filtersParam)
    }
)

const getPublicFiltersValuesSlice = createSlice({
    name: 'getPublicFiltersValues',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getPublicFiltersValues.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(getPublicFiltersValues.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload.data
                }
            })
            .addCase(getPublicFiltersValues.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})
export const saveLike = createAsyncThunk(
    'saveLike',
    async (addLikeRequest: RecipeAddLikeRequest) => {
        response = await CatalogService.saveRecipeLike(addLikeRequest)
    }
)
// Then, handle actions in your reducers:
const saveLikeSlice = createSlice({
    name: 'saveLike',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(saveLike.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(saveLike.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    if (
                        action.payload === undefined &&
                        response.data !== undefined
                    )
                        state.data = response.data
                    else state.data = action.payload.data
                    state.error = null
                }
            })
            .addCase(saveLike.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})
export const feedbackAttachFile = createAsyncThunk(
    'recipeFeedback/feedbackAttachFile',
    async (payload: any) => {
        return await CatalogService.feedbackAttachFile(payload)
    }
)

const feedbackAttachFileSlice = createSlice({
    name: 'feedbackAttachFile',
    initialState: {
        data: null,
        loading: LoadingState.Idle,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(feedbackAttachFile.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(feedbackAttachFile.fulfilled, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload.data
                    state.error = null
                }
            })
            .addCase(feedbackAttachFile.rejected, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})
export const addOptimizationSubscription = createAsyncThunk(
    'optimization/addOptimizationSubscription',
    async (payload: RecipeAddSubscriptionRequest) => {
        return await CatalogService.addOptimizationSubscription(payload)
    }
)

const addOptimizationSubscriptionSlice = createSlice({
    name: 'addOptimizationSubscription',
    initialState: {
        data: null,
        loading: LoadingState.Idle,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(addOptimizationSubscription.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(
                addOptimizationSubscription.fulfilled,
                (state, action: any) => {
                    if (state.loading === LoadingState.Pending) {
                        state.loading = LoadingState.Idle
                        state.data = action.payload.data
                        state.error = null
                    }
                }
            )
            .addCase(
                addOptimizationSubscription.rejected,
                (state, action: any) => {
                    if (state.loading === LoadingState.Pending) {
                        state.loading = LoadingState.Idle
                        state.error = action.error
                    }
                }
            )
    },
})
export const deleteOptimizationSubscription = createAsyncThunk(
    'optimization/deleteOptimizationSubscription',
    async (uuid: string) => {
        return await CatalogService.deleteOptimizationSubscription(uuid)
    }
)

const deleteOptimizationSubscriptionSlice = createSlice({
    name: 'deleteOptimizationSubscription',
    initialState: {
        data: null,
        loading: LoadingState.Idle,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(deleteOptimizationSubscription.pending, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(
                deleteOptimizationSubscription.fulfilled,
                (state, action: any) => {
                    if (state.loading === LoadingState.Pending) {
                        state.loading = LoadingState.Idle
                        state.data = action.payload.data
                        state.error = null
                    }
                }
            )
            .addCase(
                deleteOptimizationSubscription.rejected,
                (state, action: any) => {
                    if (state.loading === LoadingState.Pending) {
                        state.loading = LoadingState.Idle
                        state.error = action.error
                    }
                }
            )
    },
})

export const catalogSearchResult = getCatalogSlice.reducer
export const downloadCatalogResult = downloadCatalogSlice.reducer
export const submitFeedbackResult = submitFeedbackSlice.reducer
export const saveFavoriteResult = saveFavoriteSlice.reducer
export const getTagsValuesResult = getTagsValuesSlice.reducer
export const saveStarRatingResult = saveStarRatingSlice.reducer
export const saveVotingResult = saveVotingSlice.reducer
export const getRecipeByIdResult = getRecipeByIdSlice.reducer
export const saveLinkClickResult = saveLinkClickSlice.reducer
export const saveSiteFeedbackResult = saveSiteFeedbackSlice.reducer
export const getLastLoginResult = getLastLoginSlice.reducer
export const getBenchmarksResults = getBenchmarksSlice.reducer
export const getFiltersValuesResult = getFiltersValuesSlice.reducer
export const saveLikeResult = saveLikeSlice.reducer
export const getPublicFiltersValuesResult = getPublicFiltersValuesSlice.reducer
export const feedbackAttachFileResult = feedbackAttachFileSlice.reducer
export const addOptimizationSubscriptionResult =
    addOptimizationSubscriptionSlice.reducer
export const deleteOptimizationSubscriptionResult =
    deleteOptimizationSubscriptionSlice.reducer
