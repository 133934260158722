import { API_SERVICE_URL } from '../config/service'
import api from './auth/client'
const serviceConfig = require('../config/service.json')
const queryString = require('query-string')

class FavoriteService {
    getUsersFavoritesList(param: any) {
        var url = API_SERVICE_URL + serviceConfig.getUsersFavorites
        const queryArgs = queryString.stringify(param)
        return api.get(url + '?' + queryArgs)
    }
}

export default new FavoriteService()
