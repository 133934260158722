/************************************************
 * Copyright (C) 2021 Intel Corporation
 ************************************************/
import styled from 'styled-components'
import { FunctionComponent, Dispatch } from 'react'
import { connect } from 'react-redux'
import FooterPanel from '../../layout/footer'
import PageLinkPanel from '../../layout/pageLinks'
import { Col, Form, FormLabel, Row } from 'react-bootstrap'
import UserPanel from '../../layout/userProfile'
import MobileHeader from '../../layout/mobileHeader'
import HeaderPanel from '../../layout/header'

const AboutPanelContainer = styled.div`
    display: flex;
    height: ${(props) => props.theme.size.mainContainerHeight};
    width: ${(props) => props.theme.size.mainContainerWidth};
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: space-between;
    @media screen and (min-width: 1200px) {
        overflow-x: hidden;
    }
    @media screen and (max-width: 500px) {
        width: 100%;
    }
`

const AboutHorizontalContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: space-between;
`
const AboutBodyContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: center;
    background-color: ${(props) => props.theme.color.background.defaultGrey};
    padding-bottom: 10rem;
    padding-right: 0.5rem;
`

const AboutBodyContainerReposition = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    margin-left: 2rem;
`

const PageLinkPanelPanelWrapper = styled.div`
    display: flex;
    margin-left: 2rem;
    @media screen and (max-width: 500px) {
        display: none;
    }
`
const MobileHeaderWrapper = styled.div`
    display: flex;
    display: none;
    @media screen and (max-width: 500px) {
        display: block;
    }
`

const AboutPanelWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: flex-start;
    margin-top: 2rem;
`
const PageTitleTextLabel = styled(FormLabel)`
    ${(props) => props.theme.typography.xxxLarge}
    text-align: left;
    margin-bottom: 3rem;
    font-weight: 500;
    font-size: 2.25rem;
`
const CardContainer = styled.div`
    display: flex;
    justify-content: left;
    width: 80%;
`
const Card = styled.div`
    padding: 2rem;
    margin-right: 3rem;
    max-width: 100%;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 25px;
    border-top: 5px solid #0073bb;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 30%), 0 0 0 1px rgb(0 0 0 / 4%);
    background-color: #fff;
    height: 90%;
`
const CardHeaderContainer = styled.div`
    display: flex;
    justify-content: left;
`

const CardTitle = styled.span`
    weight: 500;
    font-size: 2rem;
    margin-left: 1rem;
    color: var(--color-text-link-default-753y4e, #0073bb);
`
const CardBodyText = styled(FormLabel)`
    font-family: 'IntelOne Display';
    weight: 400;
    font-size: 1rem;
    margin-left: 2%;
    margin-top: 1rem;
`

export interface IAboutContainerProps {}

const AboutContainer: FunctionComponent<IAboutContainerProps> & {
    defaultProps: Partial<IAboutContainerProps>
} = ({}: IAboutContainerProps) => {
    return (
        <AboutPanelContainer>
            <HeaderPanel />
            <AboutHorizontalContainer>
                <AboutBodyContainer>
                    <AboutBodyContainerReposition>
                        <UserPanel></UserPanel>
                        <PageTitleTextLabel data-testid="page-title-label">
                            About
                        </PageTitleTextLabel>
                        <CardContainer>
                            <Row style={{ width: '90%' }}>
                                <Form.Group as={Col} className="mb-3">
                                    <Card>
                                        <Row>
                                            <CardHeaderContainer>
                                                <CardTitle data-testid="card1-label">
                                                    WHAT IT IS
                                                </CardTitle>
                                            </CardHeaderContainer>
                                        </Row>
                                        <Row>
                                            <CardBodyText>
                                                <ul>
                                                    <li data-testid="card1-list1-text">
                                                        Optimization ‘packages’
                                                        of value propositions,
                                                        performance, technical
                                                        papers configurations,
                                                        code, user guides,
                                                        marketing collateral &
                                                        proof points
                                                    </li>
                                                    <li data-testid="card1-list2-text">
                                                        Both internal and
                                                        customer facing
                                                    </li>
                                                </ul>
                                            </CardBodyText>
                                        </Row>
                                        <Row></Row>
                                    </Card>
                                </Form.Group>
                                <Form.Group as={Col} className="mb-3">
                                    <Card>
                                        <Row>
                                            <CardHeaderContainer>
                                                <CardTitle data-testid="card2-label">
                                                    WHAT’S IN IT
                                                </CardTitle>
                                            </CardHeaderContainer>
                                        </Row>
                                        <Row>
                                            <CardBodyText>
                                                <ul>
                                                    <li data-testid="card2-list1-text">
                                                        Populated from CESG/SATG
                                                        engineering, WSF,
                                                        Granulate & Densify​
                                                    </li>
                                                    <li data-testid="card2-list2-text">
                                                        ICX Crypto & AI
                                                        optimizations​
                                                    </li>
                                                    <li data-testid="card2-list3-text">
                                                        Gold customer
                                                        optimizations from the
                                                        last 2 years​
                                                    </li>
                                                    <li data-testid="card2-list4-text">
                                                        SPR planned
                                                        optimizations​
                                                    </li>
                                                </ul>
                                            </CardBodyText>
                                        </Row>
                                        <Row></Row>
                                    </Card>
                                </Form.Group>
                            </Row>
                        </CardContainer>
                        <CardContainer style={{ marginTop: '3rem' }}>
                            <Row style={{ width: '90%' }}>
                                <Form.Group as={Col} className="mb-3">
                                    <Card>
                                        <Row>
                                            <CardHeaderContainer>
                                                <CardTitle data-testid="card3-label">
                                                    WHAT IT DOES
                                                </CardTitle>
                                            </CardHeaderContainer>
                                        </Row>
                                        <Row>
                                            <CardBodyText>
                                                <ul>
                                                    <li data-testid="card3-list1-text">
                                                        Search & filter based on
                                                        workload, platform,
                                                        feature, segment & more​
                                                    </li>
                                                    <li data-testid="card3-list2-text">
                                                        Provides request &
                                                        feedback functions which
                                                        feed into the
                                                        Optimization Decision
                                                        making forum​​
                                                    </li>
                                                    <li data-testid="card3-list3-text">
                                                        Allows voting & feedback
                                                        on planned
                                                        optimizations​
                                                    </li>
                                                    <li data-testid="card3-list4-text">
                                                        Allows rating on
                                                        existing optimizations ​
                                                    </li>
                                                    <li data-testid="card3-list5-text">
                                                        In the future tied into
                                                        Seismic & KPI’s​ ​
                                                    </li>
                                                </ul>
                                            </CardBodyText>
                                        </Row>
                                        <Row></Row>
                                    </Card>
                                </Form.Group>
                                <Form.Group as={Col} className="mb-3">
                                    <Card>
                                        <Row>
                                            <CardHeaderContainer>
                                                <CardTitle data-testid="card4-label">
                                                    Software License Insights
                                                </CardTitle>
                                            </CardHeaderContainer>
                                        </Row>
                                        <Row>
                                            <CardBodyText
                                                style={{
                                                    marginLeft: '1.2rem',
                                                }}
                                                data-testid="card4-text"
                                            >
                                                <a
                                                    href="/dependencies-2022-09-08T22_40_40.553Z.csv"
                                                    target="_blank"
                                                    data-testid="dependencies-link-text"
                                                >
                                                    Download dependencies
                                                    license details
                                                </a>{' '}
                                                <br />
                                                <br />
                                                <a
                                                    href="https://intel-privacy.my.onetrust.com/webform/ceaf6349-7a96-40c4-b1e1-f03d6f923a69/2d689e0b-847f-4773-85b3-3cd06c358974"
                                                    target="_blank"
                                                    data-testid="delete-data-link-text"
                                                >
                                                    Request to delete your
                                                    personal data
                                                </a>
                                            </CardBodyText>
                                        </Row>
                                        <Row></Row>
                                    </Card>
                                </Form.Group>
                            </Row>
                        </CardContainer>
                        <AboutPanelWrapper></AboutPanelWrapper>
                    </AboutBodyContainerReposition>
                </AboutBodyContainer>
            </AboutHorizontalContainer>
            <FooterPanel></FooterPanel>
        </AboutPanelContainer>
    )
}

AboutContainer.defaultProps = {}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {}
}

const mapStateToProps = (state: any) => {
    return {}
}

type StateToPropsType = ReturnType<typeof mapStateToProps>
type DispatchToPropsType = typeof mapDispatchToProps

export default connect<StateToPropsType, DispatchToPropsType>(
    mapStateToProps,
    mapDispatchToProps
)(AboutContainer)
