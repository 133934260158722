/************************************************
 * Copyright (C) 2024 Intel Corporation
 ************************************************/
import {
    ChangeEvent,
    FunctionComponent,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react'
import styled from 'styled-components'
import { LoadingState } from '../../models/loadingState'
import {
    Button,
    Form,
    FormLabel,
    OverlayTrigger,
    Popover,
    Spinner,
} from 'react-bootstrap'
import { postChatCompletion } from '../../store/chatCompletion.slice'
import {
    ChatCompletionRequest,
    ChatContentMessage,
} from '../models/chatCompletionRequest'
import Select from 'react-select'
import { languageList } from '../data/languagesData'
const ChatContainer = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    border: 1px solid rgb(101, 49, 113);
    border-radius: 0;
    padding: 1rem 0;
`
const Container = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    alignitems: center;
    height: 100%;
    width: 100%;
    padding: 1rem;
    @media screen and (max-width: 500px) {
        width: 100%;
    }
`
const InputContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
`

const RunButton = styled.button`
    font-family: 'IntelOne Text';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    text-decoration: none;
    color: #fff;
    background-color: #8f5da2;
    margin-right: 1.5rem;
    border: none;
    min-width: 6rem;
    height: 2rem;
    border-radius: 0;
    border: none;
    &:disabled {
        opacity: 0.1;
        --tw-text-opacity: 1;
    }
`
const ResetButton = styled(RunButton)`
    background-color: #8f5da2;
`

const TextLabel = styled(FormLabel)`
    display: flex;
    color: var(--input-label-light-default, #494b51);
    font-family: 'IntelOne Display';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
`
const PopoverKeyDiv = styled.div`
    background-color: #000;
    color: #fff;
    font-size: 1rem;
    padding: 0.5rem;
`

export interface ITranslationInputPanelProps {
    postChatQuery: typeof postChatCompletion
    chatCompletionAnswer: any
    chatCompletionLoading: LoadingState
    chatCompletionError: any
    selectedModelName: string
    selectedModelPublishedBy: string
    setIsResetClick?: any
    setCurrentPromptMessage?: any
    selectedOutputTab?: string
    inputLanguage: any
    setInputLanguage: any
    outputLanguage: any
    setOutputLanguage: any
    isStream: boolean
}

const TranslationInputPanel: FunctionComponent<ITranslationInputPanelProps> = ({
    postChatQuery,
    chatCompletionAnswer,
    chatCompletionLoading,
    chatCompletionError,
    selectedModelName,
    selectedModelPublishedBy,
    setIsResetClick,
    setCurrentPromptMessage,
    selectedOutputTab,
    inputLanguage,
    setInputLanguage,
    outputLanguage,
    setOutputLanguage,
    isStream,
}: ITranslationInputPanelProps) => {
    const defaultInputQuery = 'I love computers'
    const inputRef = useRef<HTMLTextAreaElement>(null)
    const [inputText, setInputText] = useState(defaultInputQuery)
    const [errorFile, setErrorFile] = useState<string | undefined>(undefined)

    const [contentMessage, setContentMessage] = useState<
        ChatContentMessage[] | undefined
    >(undefined)

    const languageOptions = languageList.map((lan: any) => ({
        value: lan,
        label: lan,
    }))

    const handleRunClick = async () => {
        //get answer
        setIsResetClick(false)

        //get answer
        SubmitQueryForAnswer(selectedModelName)
    }

    const SubmitQueryForAnswer = (model: string, listCountIndex?: number) => {
        if (contentMessage) {
            const request = new ChatCompletionRequest()
            request.messages = contentMessage
            request.model = model
            request.stream = isStream

            postChatQuery(request)
        }
    }

    const buildContentMessage = () => {
        if (inputText && inputLanguage && outputLanguage) {
            const messageLanguages = new ChatContentMessage()
            messageLanguages.role = 'system'
            messageLanguages.content = `Translate this from ${inputLanguage.label} to ${outputLanguage.label}`
            const messageText = new ChatContentMessage()
            messageText.role = 'user'
            messageText.content = inputText

            setContentMessage([messageText, messageLanguages])
            setCurrentPromptMessage(
                JSON.stringify([messageText, messageLanguages])
            )
        }
    }

    const onInputTextChange = useCallback((event: ChangeEvent<HTMLElement>) => {
        const target = event.currentTarget as HTMLTextAreaElement
        setInputText(target.value)
    }, [])

    useEffect(() => {
        buildContentMessage()
    }, [inputText, inputLanguage, outputLanguage])

    return (
        <ChatContainer>
            <InputContainer>
                <Container>
                    {selectedOutputTab == 'output' ? (
                        <>
                            <div>
                                <TextLabel>
                                    Output Language
                                    <OverlayTrigger
                                        placement={'right'}
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                            <Popover
                                                id="pop_timeline"
                                                style={{
                                                    maxWidth: '20%',
                                                }}
                                            >
                                                <Popover.Body>
                                                    <PopoverKeyDiv>
                                                        Select the language you
                                                        want to translate to
                                                    </PopoverKeyDiv>
                                                </Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <i
                                            className="uil uil-info-circle pointer"
                                            tabIndex={0}
                                        ></i>
                                    </OverlayTrigger>
                                </TextLabel>
                                <Select
                                    name="outputLanguage"
                                    aria-label="Select Output Language"
                                    isSearchable={true}
                                    isClearable={true}
                                    isMulti={false}
                                    options={languageOptions}
                                    value={outputLanguage}
                                    onChange={(target: any) => {
                                        setOutputLanguage(target)
                                    }}
                                />
                            </div>
                            <div style={{ paddingTop: '1rem' }}>
                                <TextLabel>Translation</TextLabel>
                                <Form.Control
                                    id="output"
                                    name="output"
                                    as="textarea"
                                    rows={12}
                                    value={
                                        chatCompletionAnswer?.choices
                                            ? chatCompletionAnswer?.choices[0]
                                                  .message.content
                                            : 'Amo las computadoras.'
                                    }
                                    autoFocus
                                    disabled
                                />
                            </div>
                        </>
                    ) : (
                        <>
                            <div>
                                <TextLabel>
                                    Input Language
                                    <OverlayTrigger
                                        placement={'right'}
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                            <Popover
                                                id="pop_timeline"
                                                style={{
                                                    maxWidth: '20%',
                                                }}
                                            >
                                                <Popover.Body>
                                                    <PopoverKeyDiv>
                                                        Select the language you
                                                        want to translate from
                                                    </PopoverKeyDiv>
                                                </Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <i
                                            className="uil uil-info-circle pointer"
                                            tabIndex={0}
                                        ></i>
                                    </OverlayTrigger>
                                </TextLabel>
                                <Select
                                    name="inputLanguage"
                                    aria-label="Select Input Language"
                                    isSearchable={true}
                                    isClearable={true}
                                    isMulti={false}
                                    options={languageOptions}
                                    value={inputLanguage}
                                    onChange={(target: any) =>
                                        setInputLanguage(target)
                                    }
                                />
                            </div>
                            <div style={{ paddingTop: '1rem' }}>
                                <TextLabel>
                                    Input
                                    <OverlayTrigger
                                        placement={'right'}
                                        delay={{
                                            show: 250,
                                            hide: 400,
                                        }}
                                        overlay={
                                            <Popover
                                                id="pop_timeline"
                                                style={{
                                                    maxWidth: '20%',
                                                }}
                                            >
                                                <Popover.Body>
                                                    <PopoverKeyDiv>
                                                        Input text to embed as a
                                                        string.
                                                    </PopoverKeyDiv>
                                                </Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <i
                                            className="uil uil-info-circle pointer"
                                            tabIndex={0}
                                        ></i>
                                    </OverlayTrigger>
                                </TextLabel>
                                <Form.Control
                                    ref={inputRef}
                                    id="input"
                                    name="input"
                                    placeholder="Enter your text.."
                                    as="textarea"
                                    rows={11}
                                    onChange={onInputTextChange}
                                    defaultValue={defaultInputQuery}
                                    autoFocus
                                    required
                                />
                            </div>
                        </>
                    )}
                </Container>
                <Container style={{ flexDirection: 'row' }}>
                    {selectedOutputTab == 'output' ? (
                        ''
                    ) : (
                        <RunButton
                            disabled={contentMessage == undefined}
                            onClick={handleRunClick}
                        >
                            Translate
                        </RunButton>
                    )}
                </Container>
            </InputContainer>
        </ChatContainer>
    )
}

export default TranslationInputPanel
