import { FormEvent, FunctionComponent, useRef } from 'react'
import styled from 'styled-components'
import {
    FormControl,
    Alert,
    CloseButton,
    Form,
    InputGroup,
    Button,
    Overlay,
    Spinner,
    Modal,
    FormLabel,
    Dropdown,
} from 'react-bootstrap'
import { LoadingState } from '../../models/loadingState'
import { CatalogPanelLogic } from './catalogPanel.hook'
import RecipeCardPanel from '../recipe/recipeCardPanel'
import { CatalogSearchResponse } from '../../models/catalogSearchResponse'
import { CatalogSearchRequest } from '../../models/catalogSearchrequest'
import Pagination from '../pagination/pagination'
import { DownloadCatalogResponse } from '../../models/downloadCatalogResponse'
import { RecipeFeedbackRequest } from '../../models/recipeFeedbackRequest'
import { Recipefeedbackresponse } from '../../models/recipefeedbackresponse'
import { RecipeAddFavoriteRequest } from '../../models/recipeAddFavoriteRequest'
import { RecipeAddFavoriteResponse } from '../../models/recipeAddFavoriteResponse'
import { RecipeTagsValuesResponse } from '../../models/recipeTagsValuesResponse'
import { RecipeStarRatingRequest } from '../../models/recipeStarRatingRequest'
import { RecipeStarRatingResponse } from '../../models/recipeStarRatingResponse'
import { RecipeAddVotingRequest } from '../../models/recipeAddVotingRequest'
import { RecipeAddVotingResponse } from '../../models/recipeAddVotingResponse'
import { RecipeAddLinkClickRequest } from '../../models/recipeAddLinkClickRequest'
import { SiteFeedbackRequest } from '../../models/siteFeedbackRequest'
import { getCartItems, updateCartItems } from '../../store/shoppingCart.slice'
import { ShoppingCartResponse } from '../../models/shoppingCartResponse'
import { LayoutViewPreference } from '../../models/LayoutViewPreference'
import UserPanel from '../../layout/userProfile'
import HeaderPanel from '../../layout/header'
import { AuthPermissions } from '../../models/authPermissions'
import { RecipeAddLikeResponse } from '../../models/recipeAddLikeResponse'
import { RecipeAddLikeRequest } from '../../models/recipeAddLikeRequest'
import StringUtils from '../../lib/stringUtils'
import NoResultFoundPanel from '../benchmarkPanel/noResultFound'
import ScrollToTop from '../../layout/scrollToTop'
import { isDevAndLocal } from '../../lib/host'
import { APP_DOMAIN } from '../../config/service'

const CatalogFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
`

const CatalogFormComponent = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: no-wrap;
    padding-bottom: 2.5rem;
`

const CatalogResultComponent = styled.div`
    display: flex;
    flex-grow: 1;
    margin-top: 1rem;
    flex-direction: column;
    justify-content: center;
`
const CatalogCardPanelContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: center;
`
const PaginationComponent = styled.div`
    display: flex;
    flex-grow: 1;
    margin-top: 1rem;
    flex-direction: column;
    justify-content: center;
`
const PaginationPanelContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: right;
    padding-right: 5%;
    @media screen and (max-width: 700px) {
        justify-content: center;
    }
`
const PageDetailsComponent = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: center;
`

const ShowingPageContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: left;
    padding-left: 7%;
    margin-top: 1rem;
    width: 50%;
    vertical-align: middle;
`
const PageCountContainerDiv = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: right;
    padding-right: 7.7%;
    margin-top: 1rem;
    width: 50%;
    vertical-align: middle;
`

const AlertComponent = styled(Alert)`
    display: flex;
    margin-top: 1rem;
    justify-content: center;
`

const SearchHorizontalContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0.2rem;
`

const SearchVerticalContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`
const SearchBannerComponent = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
`
const SearchBannerContainer = styled.div`
    width: 65vw;
`
const SearchBannerSpan = styled.span`
    display: inline-flex;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: center;
    padding: 4px 10px;
    margin-left: 1px;
    margin-top: 6px;
    margin-right: 8px;
    background: #00a3fb;
    border-radius: 100px;
    align-items: center;
    line-height: 0.2;
    color: white;
`
const SearchIcon = styled(InputGroup.Text)`
    border-top-left-radius: 0.7rem !important;
    border-bottom-left-radius: 0.7rem !important;
`

const SearchComponent = styled(FormControl)`
    display: flex;
    border-top-right-radius: 0.7rem !important;
    border-bottom-right-radius: 0.7rem !important;
    font-size: 1.1rem !important;
`
const SearchGroupComponent = styled(InputGroup)`
    display: flex;
    border-radius: 0.5rem;
    min-height: 3vw;
    width: 72vw;
    @media screen and (max-width: 500px) {
        width: 70vw !important;
    }
`
const FeaturedComponent = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: center;
    margin-top: 2rem;
`
const PageCountSelect = styled(Form.Select)`
    width: 5rem;
    height: 2rem;
    margin-left: 0.5rem;
`
const FilterContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0.02rem 0.5rem;
`
const FilterContainerDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`
const FilterComponent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`
const CloseButtonDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: right;
    padding-top: 0.2rem;
    padding-left: 0.8rem;
`

const FilterButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    color: #333;
    border-color: #fff;
    border-radius: 0.5rem;
    &:hover {
        border-color: #fff;
        background-color: #fff;
        color: #333;
    }
`
const FilterButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`
const ApplyFilters = styled(Button)`
    display: flex;
    justify-content: center;
    vertical-align: middle;
    font-weight: 400;
    font-size: 1rem;
    background-color: #6c757d;
    min-width: 20%;
    height: 2.25rem;
    &:hover {
        background-color: ${(props) =>
            props.theme.color.button.background.hover.primary};
        transition: 0.5s;
    }
`
const RemoveFilters = styled(ApplyFilters)`
    margin-left: 0.5rem;
`
const ScaleFilterRenderDiv = styled.div`
    margin-left: 2rem;
    margin-top: 1rem;
    border-right-style: ridge;
    padding-right: 1rem;
`
const TypeFilterRenderDiv = styled.div`
    margin-left: 2rem;
    margin-top: 1rem;
    border-right-style: ridge;
    padding-right: 1.5rem;
`
const PlatformFilterDiv = styled(TypeFilterRenderDiv)`
    padding-right: 2rem;
`
const OthersFilterRenderDiv = styled.div`
    margin-left: 2rem;
    margin-top: 1rem;
    padding-right: 2rem;
`
const CSPFilterRenderDiv = styled.div`
    margin-top: 1rem;
    border-right-style: ridge;
    padding-right: 2rem;
`
const FilterHeadingSpan = styled.span`
    padding: 2rem, 0;
    font-weight: 600;
    font-size: 0.9rem;
    text-transform: uppercase;
    color: #000;
`
const SortContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: 0.5rem;
`
const SortDivComponent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-color: #fff;
`
const SortButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    color: #333;
    border-color: #fff;
    border-radius: 0.5rem;
    font-size: 0.9rem;
    content: unset;
    &:hover {
        border-color: #fff;
        background-color: #fff;
        color: #333;
    }
    &:before {
        content: unset !important;
    }
`
const SortListItemButton = styled.button`
    border: none;
    outline: 0;
    width: 100%;
    background-color: transparent;
    padding: 0.2rem 1rem;
    margin: 0;
    justify-content: center;
    font-size: 0.9rem;
    min-height: 1.5rem;
    &:hover {
        border-color: #fff;
        background-color: #f2f2f2;
    }
`
const SortListItemDiv = styled.div`
    justify-content: left;
`
const SpinnerDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5rem;
    width: 100%;
`
const FeedbackButtonDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: right;
    padding-right: 5%;
    padding-top: 2rem;
`
const FeedbackButton = styled(Button)`
    display: flex;
    justify-content: center;
    vertical-align: middle;
    font-weight: 400;
    font-size: 1rem;
    background-color: #ff6b6b;
    width: 12%;
    &:hover {
        background-color: ${(props) =>
            props.theme.color.button.background.hover.primary};
        transition: 0.5s;
    }
`
const ModalPopupContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`
const TextLabel = styled(FormLabel)`
    display: flex;
    ${(props) => props.theme.typography.medium}
    text-align: left;
    padding: 0.5rem;
`
const RequiredTextLabel = styled.span`
    display: flex;
    ${(props) => props.theme.typography.xLarge}
    color: red;
`
const AlertContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0.2rem;
`
const LayoutViewContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: 0.5rem;
    @media screen and (max-width: 500px) {
        display: none;
    }
`
const LayoutViewDropdown = styled(Dropdown)`
    display: flex;
    border-radius: 0.5rem;
    &:hover &:focus &:click &:blur {
        border-color: #fff;
        background-color: #fff;
        color: #333;
    }
`
const ViewDropdownToggle = styled(Dropdown.Toggle)`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    color: #333;
    border-color: #fff;
    border-radius: 0.5rem;
    &:hover &:click &:focus &:blur {
        border-radius: 0.5rem;
        border-color: #fff;
        background-color: #fff;
        color: #333;
    }
`
const CatalogBlueBanner = styled.div`
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    background-color: rgb(101, 49, 113);
    background-image: ${(props) =>
        `url('${process.env.PUBLIC_URL}/background-l1-mosaic.svg')`};
    background-position-x: 50%;
    background-position-y: 50%;
    background-repeat: no-repeat;
    background-size: 100%;
    width: 100%;
`
const CatalogTypeFilter = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    border-bottom: 2px solid rgb(201, 202, 206);
    padding: 0.5rem 0;
`
const PageTitleTextLabel = styled(FormLabel)`
    ${(props) => props.theme.typography.xxxLarge}
    text-align: center;
    margin-bottom: 1rem;
    font-weight: 500;
    font-size: 2rem;
    color: #fff;
    @media screen and (min-width: 500px) {
        font-size: 1.8rem;
    }
    @media screen and (min-width: 768px) {
        font-size: 2rem;
    }
    @media screen and (min-width: 800px) {
        font-size: 2.5rem;
    }
`
const PageTitleLevel2 = styled(FormLabel)`
    ${(props) => props.theme.typography.xLarge}
    justify-content: center;
    text-align: center;
    margin-bottom: 2rem;
    font-weight: 400;
    font-size: 1.1rem;
    width: 60%;
    color: #fff;
`
export const ChatbotImage = (
    <img
        src={StringUtils.resolve('/chatbot.png')}
        height="30"
        width="40"
        alt="chatbot logo"
    />
)

export interface ICatalogPanelProps {
    getCatalog: (catalogSearchRequest: CatalogSearchRequest) => void
    catalogloading: LoadingState
    catalogResult: CatalogSearchResponse
    error: any
    resetMultiselectValues?: () => void
    SearchComponentRef?: any
    downloadCatalog: (downloadUrl: string) => void
    downloadCatalogResult: DownloadCatalogResponse
    downloadCatalogLoading: LoadingState
    downloadCatalogError?: any
    submitFeedback: (recipeFeedbackRequest: RecipeFeedbackRequest) => void
    feedbackResult: Recipefeedbackresponse
    feedbackError: any
    feedbackLoading: LoadingState
    sortTargetRef?: any
    saveFavorite: (addFavoriteRequest: RecipeAddFavoriteRequest) => void
    favoriteResult: RecipeAddFavoriteResponse
    favoriteError?: any
    favoriteLoading: LoadingState
    getTagsValuesResult: RecipeTagsValuesResponse
    saveStarRating: (saveRatingRequest: RecipeStarRatingRequest) => void
    saveRatingResult: RecipeStarRatingResponse
    saveRatingError?: any
    saveVoting: (saveVotingRequest: RecipeAddVotingRequest) => void
    saveVotingResult: RecipeAddVotingResponse
    saveVotingError?: any
    getRecipeById: (id: any) => void
    saveLinkClick: (addLinkClickRequest: RecipeAddLinkClickRequest) => void
    saveSiteFeedback: (siteFeedbackRequest: SiteFeedbackRequest) => void
    saveSiteFeedbackResult: any
    saveSiteFeedbackError?: any
    getCartItems: typeof getCartItems
    getCartItemsResult: ShoppingCartResponse
    getCartItemsResultLoading: LoadingState
    getCartItemsResultError: any
    updateCartItems: typeof updateCartItems
    updateCartResult: ShoppingCartResponse
    updateCartResultLoading: LoadingState
    updateCartResultError: any
    authPermissionsResult?: AuthPermissions
    isPublicSearch?: boolean
    saveLike: (addLikeRequest: RecipeAddLikeRequest) => void
    likeResult: RecipeAddLikeResponse
    likeError?: any
    likeLoading: LoadingState
    getClickCounts: (filters: any) => void
    clickCountsResult: any
}

const CatalogPanel: FunctionComponent<ICatalogPanelProps> = ({
    getCatalog,
    catalogloading,
    catalogResult,
    error,
    downloadCatalog,
    downloadCatalogResult,
    downloadCatalogLoading,
    downloadCatalogError,
    submitFeedback,
    feedbackResult,
    feedbackLoading,
    feedbackError,
    saveFavorite,
    favoriteResult,
    favoriteError,
    favoriteLoading,
    sortTargetRef,
    getTagsValuesResult,
    saveStarRating,
    saveRatingResult,
    saveRatingError,
    saveVoting,
    saveVotingResult,
    saveVotingError,
    getRecipeById,
    saveLinkClick,
    saveSiteFeedback,
    saveSiteFeedbackResult,
    saveSiteFeedbackError,
    getCartItems,
    getCartItemsResult,
    getCartItemsResultLoading,
    getCartItemsResultError,
    updateCartItems,
    updateCartResult,
    updateCartResultLoading,
    updateCartResultError,
    authPermissionsResult,
    isPublicSearch,
    saveLike,
    likeResult,
    likeError,
    likeLoading,
    getClickCounts,
    clickCountsResult,
}: ICatalogPanelProps) => {
    var errorText = null
    if (error) {
        errorText =
            error?.message.indexOf('404') > -1
                ? 'Optimization ID not found.'
                : 'We are experiencing issues retrieving the data. ' +
                  error?.message
    }
    const SearchComponentRef = useRef()
    const filterTarget = useRef(null)
    const sortTarget = useRef(null)
    sortTargetRef = sortTarget
    const {
        onAppNameChange,
        onKeyDown,
        searchBannerNames,
        handleBannerClick,
        currPage,
        afterPageClicked,
        handlePageCount,
        pageCount,
        showFilters,
        onCloseClick,
        setFiltersShow,
        onCSPChange,
        onSolutionScaleChange,
        onContentTypeChange,
        onApplyFiltersClick,
        onRemoveFiltersClick,
        totalFilterCount,
        cloudCSP,
        solutionScale,
        contentType,
        showSorting,
        setSortingShow,
        onSortItemClick,
        selectedSortItem,
        onPlatformFilterChange,
        onFeatureFilterChange,
        platformFilters,
        featureFilters,
        validated,
        showSiteFeedbackPopup,
        isSiteFeedbackLoad,
        handleSiteFeedbackClose,
        onSiteFeedbackFormSubmit,
        onSiteFeedbackTextChange,
        onSiteFeedbackClick,
        layoutView,
        onLayoutViewClick,
        defaultPageSize,
    } = CatalogPanelLogic({
        getCatalog,
        catalogResult,
        SearchComponentRef,
        sortTargetRef,
        getTagsValuesResult,
        saveSiteFeedback,
        isPublicSearch,
    } as ICatalogPanelProps)

    let featureFlags: string[] = authPermissionsResult?.featureFlags
        ? authPermissionsResult?.featureFlags
        : []
    let isIntelEmployee = authPermissionsResult?.isIntelEmployee
        ? authPermissionsResult?.isIntelEmployee
        : false

    const recipes = catalogResult?.recipes
        ? [...catalogResult?.recipes]
        : undefined

    const totalPage = catalogResult?.pageInfo.total
        ? catalogResult?.pageInfo.total
        : -1
    const nextPage = catalogResult?.pageInfo.next
        ? catalogResult?.pageInfo.next
        : -1
    let CSPFiltersRender = cloudCSP.map((item, index) => {
        if (item.name !== null) {
            return (
                <Form.Check
                    type="checkbox"
                    key={item.name + index}
                    id={item.name + index}
                    value={item.name}
                    label={item.name}
                    aria-label={item.name}
                    checked={item.checkedValue}
                    onChange={onCSPChange}
                    data-testid={`csp-checkboxLabel-${index}`}
                />
            )
        }
    })
    let SolutionScaleRender = solutionScale.map((item, index) => {
        if (item.name !== null && item.displayName !== null) {
            return (
                <Form.Check
                    type="checkbox"
                    key={item.name + index}
                    id={item.name + index}
                    value={item.name}
                    label={item.displayName}
                    aria-label={item.displayName}
                    checked={item.checkedValue}
                    onChange={onSolutionScaleChange}
                    data-testid={`scale-checkboxLabel-${index}`}
                />
            )
        }
    })
    let ContentTypeRender = contentType.map((item, index) => {
        if (item.name !== null) {
            return (
                <Form.Check
                    type="checkbox"
                    key={item.name + index}
                    id={item.name + index}
                    value={item.name}
                    label={item.name}
                    aria-label={item.name}
                    checked={item.checkedValue}
                    onChange={onContentTypeChange}
                    data-testid={`type-checkboxLabel-${index}`}
                />
            )
        }
    })
    let PlatformFilterRender = platformFilters.map((item, index) => {
        if (item.name !== null) {
            return (
                <Form.Check
                    type="checkbox"
                    key={item.name + index}
                    id={item.name + index}
                    value={item.name}
                    label={item.name}
                    aria-label={item.name}
                    checked={item.checkedValue}
                    onChange={onPlatformFilterChange}
                    data-testid={`platform-checkboxLabel-${index}`}
                />
            )
        }
    })
    let FeatureFilterRender = featureFilters.map((item, index) => {
        if (item.name !== null) {
            return (
                <Form.Check
                    type="checkbox"
                    key={item.name + index}
                    id={item.name + index}
                    value={item.name}
                    label={item.name}
                    aria-label={item.name}
                    checked={item.checkedValue}
                    onChange={onFeatureFilterChange}
                    data-testid={`feature-checkboxLabel-${index}`}
                />
            )
        }
    })

    let pageCountContainer = (
        <PageCountContainerDiv
            style={
                layoutView === LayoutViewPreference.ListView
                    ? { paddingRight: '15%' }
                    : {}
            }
        >
            {totalPage > defaultPageSize &&
            totalPage >= nextPage &&
            pageCount > 0 ? (
                <span data-testid="search-page-count-label">
                    {' '}
                    Page {currPage} of {Math.ceil(totalPage / pageCount)}
                </span>
            ) : (
                ''
            )}
        </PageCountContainerDiv>
    )

    let resultCountContainer = (
        <ShowingPageContainer
            style={
                layoutView === LayoutViewPreference.ListView
                    ? { paddingLeft: '14%' }
                    : {}
            }
        >
            {totalPage > 0 ? (
                <span data-testid="search-result-count-label">
                    {' '}
                    Showing {(currPage - 1) * pageCount + 1} to{' '}
                    {currPage * pageCount < totalPage
                        ? currPage * pageCount
                        : totalPage}{' '}
                    of {totalPage} results{' '}
                </span>
            ) : (
                ''
            )}
            {totalPage > defaultPageSize && totalPage >= nextPage ? (
                <PageCountSelect
                    size="sm"
                    onChange={handlePageCount}
                    value={pageCount}
                >
                    <option value={30}>30</option>
                    {totalPage >= 30 ? <option value={50}>50</option> : ''}
                    {totalPage > 50 ? <option value={70}>{70}</option> : ''}
                    {totalPage >= 70 ? <option value={100}>{100}</option> : ''}
                </PageCountSelect>
            ) : (
                ''
            )}
        </ShowingPageContainer>
    )
    const GalleryViewImage = (
        <img
            src={StringUtils.resolve('/gallery_view.svg')}
            height="20"
            width="20"
            alt="gallery logo"
        />
    )
    const ListViewImage = (
        <img
            src={StringUtils.resolve('/list_view.svg')}
            height="20"
            width="20"
            alt="list logo"
        />
    )
    const DemoViewImage = (
        <img
            src={StringUtils.resolve('/demo_view.png')}
            height="25"
            width="25"
            alt="demo logo"
        />
    )
    const SalesViewImage = (
        <img
            src={StringUtils.resolve('/benchmark.png')}
            height="25"
            width="25"
            alt="demo logo"
        />
    )
    const filterAndSortComponents = (
        <FilterContainer>
            <FilterButton
                type="submit"
                data-testid="filter-button"
                onClick={() => {
                    setFiltersShow(!showFilters), setSortingShow(false)
                }}
                ref={filterTarget}
            >
                Filters
                <span>
                    {totalFilterCount > 0 ? ' (' + totalFilterCount + ')' : ''}
                </span>
                <i className="uil uil-filter"></i>
            </FilterButton>
            <Overlay
                target={filterTarget.current}
                show={showFilters}
                placement="bottom-end"
            >
                {({ placement, arrowProps, show: _show, popper, ...props }) => (
                    <div
                        {...props}
                        style={{
                            position: 'absolute',
                            backgroundColor: '#ffff',
                            padding: '1rem 1rem',
                            marginTop: '0.29rem',
                            borderRadius: 5,
                            border: '1px',
                            borderColor: '#6c757d',
                            borderStyle: 'double',
                            ...props.style,
                        }}
                        onMouseLeave={() => setFiltersShow(false)}
                    >
                        <FilterButtonContainer>
                            <ApplyFilters onClick={onApplyFiltersClick}>
                                Apply Filters
                            </ApplyFilters>
                            <RemoveFilters onClick={onRemoveFiltersClick}>
                                Clear All Filters
                            </RemoveFilters>
                            <CloseButtonDiv>
                                <CloseButton
                                    className="p-1"
                                    onClick={onCloseClick}
                                />
                            </CloseButtonDiv>
                        </FilterButtonContainer>

                        <FilterComponent>
                            <CSPFilterRenderDiv>
                                <FilterHeadingSpan>CSP</FilterHeadingSpan>
                                {[CSPFiltersRender]}
                            </CSPFilterRenderDiv>
                            {/* {isIntelEmployee ? (
                                <ScaleFilterRenderDiv>
                                    <FilterHeadingSpan>Scale</FilterHeadingSpan>
                                    {[SolutionScaleRender]}
                                </ScaleFilterRenderDiv>
                            ) : (
                                ''
                            )} */}
                            <TypeFilterRenderDiv>
                                <FilterHeadingSpan>Type</FilterHeadingSpan>
                                {[ContentTypeRender]}
                            </TypeFilterRenderDiv>
                            <PlatformFilterDiv>
                                <FilterHeadingSpan>Platform</FilterHeadingSpan>
                                {[PlatformFilterRender]}
                            </PlatformFilterDiv>
                            <OthersFilterRenderDiv>
                                <FilterHeadingSpan>Feature</FilterHeadingSpan>
                                {[FeatureFilterRender]}
                            </OthersFilterRenderDiv>
                        </FilterComponent>
                    </div>
                )}
            </Overlay>

            <SortContainer>
                <SortButton
                    onClick={() => {
                        setSortingShow(!showSorting), setFiltersShow(false)
                    }}
                    type="submit"
                    data-testid="sortby-button"
                    ref={sortTarget}
                    id={selectedSortItem.id}
                >
                    Sort by: {selectedSortItem.name}
                    <i className="uil uil-angle-down"></i>
                </SortButton>
                <Overlay
                    target={sortTarget.current}
                    show={showSorting}
                    placement="bottom"
                    key={'sortOverlay'}
                >
                    {({
                        placement,
                        arrowProps,
                        show: _show,
                        popper,
                        ...props
                    }) => (
                        <div
                            {...props}
                            style={{
                                position: 'absolute',
                                backgroundColor: '#ffff',
                                padding: '0.5rem',
                                marginTop: '0.1rem',
                                border: '1px',
                                borderColor: '#6c757d',
                                borderStyle: 'double',
                                borderRadius: 5,
                                ...props.style,
                            }}
                            onMouseLeave={() => setSortingShow(false)}
                        >
                            <SortDivComponent>
                                <SortListItemDiv>
                                    <SortListItemButton
                                        id={'published_time'}
                                        aria-label={'Newest'}
                                        value={'Newest'}
                                        onClick={onSortItemClick}
                                    >
                                        Newest
                                    </SortListItemButton>
                                </SortListItemDiv>
                                <SortListItemDiv>
                                    <SortListItemButton
                                        id={'updated_time'}
                                        aria-label={'Recently Updated'}
                                        value={'Recently Updated'}
                                        onClick={onSortItemClick}
                                    >
                                        Recently Updated
                                    </SortListItemButton>
                                </SortListItemDiv>
                                <SortListItemDiv>
                                    <SortListItemButton
                                        id={'most_relevant'}
                                        aria-label={'Most Relevant'}
                                        value={'Most Relevant'}
                                        onClick={onSortItemClick}
                                    >
                                        Most Relevant
                                    </SortListItemButton>
                                </SortListItemDiv>
                                <SortListItemDiv>
                                    <SortListItemButton
                                        id={'top_downloads'}
                                        aria-label={'Top Downloads'}
                                        value={'Top Downloads'}
                                        onClick={onSortItemClick}
                                    >
                                        Top Downloads
                                    </SortListItemButton>
                                </SortListItemDiv>
                                <SortListItemDiv>
                                    <SortListItemButton
                                        id={'top_favorites'}
                                        aria-label={'Top Favorites'}
                                        value={'Top Favorites'}
                                        onClick={onSortItemClick}
                                    >
                                        Top Favorites
                                    </SortListItemButton>
                                </SortListItemDiv>
                            </SortDivComponent>
                        </div>
                    )}
                </Overlay>
            </SortContainer>
            {isIntelEmployee ? (
                <LayoutViewContainer>
                    <LayoutViewDropdown data-testid="layout-view-button">
                        <ViewDropdownToggle>
                            {layoutView === LayoutViewPreference.GalleryView
                                ? GalleryViewImage
                                : layoutView === LayoutViewPreference.SalesView
                                ? SalesViewImage
                                : layoutView ===
                                  LayoutViewPreference.ChatbotView
                                ? ChatbotImage
                                : DemoViewImage}
                        </ViewDropdownToggle>
                        <Dropdown.Menu>
                            <Dropdown.Item
                                onClick={(ev: FormEvent<HTMLElement>) =>
                                    onLayoutViewClick(
                                        LayoutViewPreference.GalleryView,
                                        ev
                                    )
                                }
                                eventKey={LayoutViewPreference.GalleryView}
                                data-testid="gallery-view-button"
                                href="javascript:void(0)"
                            >
                                {GalleryViewImage} Developer View
                            </Dropdown.Item>
                            {isIntelEmployee ? (
                                <Dropdown.Item
                                    onClick={(ev: FormEvent<HTMLElement>) =>
                                        onLayoutViewClick(
                                            LayoutViewPreference.SalesView,
                                            ev
                                        )
                                    }
                                    eventKey={LayoutViewPreference.SalesView}
                                    data-testid="sales-view-button"
                                    href="javascript:void(0)"
                                >
                                    {SalesViewImage} Sales View
                                </Dropdown.Item>
                            ) : (
                                ''
                            )}
                            {featureFlags?.includes('isDemoViewer') ? (
                                <div>
                                    <Dropdown.Item
                                        onClick={(ev: FormEvent<HTMLElement>) =>
                                            onLayoutViewClick(
                                                LayoutViewPreference.DemoView,
                                                ev
                                            )
                                        }
                                        data-testid="demo-view-button"
                                        href="javascript:void(0)"
                                    >
                                        {DemoViewImage}Demo View
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={(ev: FormEvent<HTMLElement>) =>
                                            onLayoutViewClick(
                                                LayoutViewPreference.ChatbotView,
                                                ev
                                            )
                                        }
                                        data-testid="chatbot-view-button"
                                        href="javascript:void(0)"
                                    >
                                        {ChatbotImage}Chatbot
                                    </Dropdown.Item>
                                </div>
                            ) : (
                                ''
                            )}
                        </Dropdown.Menu>
                    </LayoutViewDropdown>
                </LayoutViewContainer>
            ) : (
                ''
            )}
        </FilterContainer>
    )

    return (
        <CatalogFormContainer>
            <CatalogBlueBanner>
                <HeaderPanel isPublicSearch={isPublicSearch} />
                <UserPanel isPublicSearch={isPublicSearch}></UserPanel>
                <CatalogFormComponent>
                    <PageTitleTextLabel data-testid="page-title-label">
                        {/* Welcome to the Optimization Hub! */}
                    </PageTitleTextLabel>
                    <ScrollToTop />
                    <SearchHorizontalContainer>
                        <SearchVerticalContainer>
                            <SearchGroupComponent size="lg">
                                <SearchIcon>
                                    <i className="uil uil-search"></i>
                                </SearchIcon>
                                <SearchComponent
                                    placeholder="Search for SW optimizations, platform, feature, workload, customer, CSP, instance type, benchmarks & more..."
                                    onChange={onAppNameChange}
                                    onKeyDown={onKeyDown}
                                    required
                                    ref={SearchComponentRef}
                                    type="search"
                                    data-testid="search-textbox"
                                    onMouseDown={() => {
                                        setFiltersShow(false),
                                            setSortingShow(false)
                                    }}
                                />
                                <FilterContainerDiv>
                                    {filterAndSortComponents}
                                </FilterContainerDiv>
                            </SearchGroupComponent>
                        </SearchVerticalContainer>
                    </SearchHorizontalContainer>
                    <SearchBannerComponent>
                        <SearchBannerContainer>
                            {searchBannerNames &&
                                searchBannerNames.map((bannerName) => (
                                    <SearchBannerSpan
                                        id={bannerName + '_span'}
                                        key={bannerName + '_span'}
                                    >
                                        {bannerName}{' '}
                                        <CloseButton
                                            variant="white"
                                            className="p-1"
                                            onClick={handleBannerClick}
                                            id={bannerName.toString()}
                                        />
                                    </SearchBannerSpan>
                                ))}
                        </SearchBannerContainer>
                    </SearchBannerComponent>
                </CatalogFormComponent>
            </CatalogBlueBanner>
            <AlertContainer>
                <AlertComponent
                    style={{ width: '75%' }}
                    variant="danger"
                    show={error ? true : false}
                >
                    {errorText}
                </AlertComponent>
            </AlertContainer>

            {catalogloading !== LoadingState.Pending ? (
                <div>
                    {catalogResult &&
                    catalogResult?.recipes &&
                    catalogResult?.recipes.length === 0 ? (
                        <div style={{ marginTop: '3rem' }}>
                            {NoResultFoundPanel()}
                        </div>
                    ) : (
                        <div>
                            <FeaturedComponent>
                                <CatalogResultComponent>
                                    <PageDetailsComponent>
                                        {resultCountContainer}
                                        {pageCountContainer}
                                    </PageDetailsComponent>
                                    <CatalogCardPanelContainer>
                                        <RecipeCardPanel
                                            isFavoritesLoading={false}
                                            recipeResult={catalogResult}
                                            downloadCatalog={downloadCatalog}
                                            downloadCatalogResult={
                                                downloadCatalogResult
                                            }
                                            downloadCatalogLoading={
                                                downloadCatalogLoading
                                            }
                                            downloadCatalogError={
                                                downloadCatalogError
                                            }
                                            submitFeedback={submitFeedback}
                                            feedbackResult={feedbackResult}
                                            feedbackError={feedbackError}
                                            feedbackLoading={feedbackLoading}
                                            saveFavorite={saveFavorite}
                                            favoriteResult={favoriteResult}
                                            favoriteError={favoriteError}
                                            favoriteLoading={favoriteLoading}
                                            saveStarRating={saveStarRating}
                                            saveRatingResult={saveRatingResult}
                                            saveRatingError={saveRatingError}
                                            saveVoting={saveVoting}
                                            saveVotingResult={saveVotingResult}
                                            saveVotingError={saveVotingError}
                                            getRecipeById={getRecipeById}
                                            saveLinkClick={saveLinkClick}
                                            updateCartItems={updateCartItems}
                                            updateCartResult={updateCartResult}
                                            getCartItems={getCartItems}
                                            getCartItemsResult={
                                                getCartItemsResult
                                            }
                                            getCartItemsResultLoading={
                                                getCartItemsResultLoading
                                            }
                                            getCartItemsResultError={
                                                getCartItemsResultError
                                            }
                                            resultLayoutView={layoutView}
                                            isIntelEmployee={isIntelEmployee}
                                            isPublicSearch={isPublicSearch}
                                            saveLike={saveLike}
                                            likeResult={likeResult}
                                            likeError={likeError}
                                            likeLoading={likeLoading}
                                            getClickCounts={getClickCounts}
                                            clickCountsResult={
                                                clickCountsResult
                                            }
                                        ></RecipeCardPanel>
                                    </CatalogCardPanelContainer>
                                </CatalogResultComponent>
                            </FeaturedComponent>
                            <PaginationComponent>
                                <PaginationPanelContainer
                                    style={
                                        layoutView ===
                                        LayoutViewPreference.ListView
                                            ? { paddingRight: '14.5%' }
                                            : {}
                                    }
                                >
                                    {totalPage > 10 && totalPage >= nextPage ? (
                                        <>
                                            <Pagination
                                                totalPage={Math.ceil(
                                                    totalPage / pageCount
                                                )}
                                                currentPage={currPage}
                                                onPageClicked={(ele: any) => {
                                                    afterPageClicked(ele)
                                                }}
                                            />
                                        </>
                                    ) : (
                                        <div></div>
                                    )}
                                </PaginationPanelContainer>
                            </PaginationComponent>
                        </div>
                    )}
                    {/*Disabled site feedback for now on request*/}
                    {/* <FeedbackButtonDiv>
                        <FeedbackButton onClick={onSiteFeedbackClick}>
                            Feedback
                        </FeedbackButton>
                    </FeedbackButtonDiv> */}
                    <ModalPopupContainer>
                        {/* Site Feedback message Model */}
                        <Modal
                            show={showSiteFeedbackPopup}
                            onHide={handleSiteFeedbackClose}
                            backdrop="static"
                            keyboard={true}
                            size="lg"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Feedback</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <AlertComponent
                                    variant={
                                        saveSiteFeedbackError
                                            ? 'danger'
                                            : 'success'
                                    }
                                    show={
                                        !isSiteFeedbackLoad &&
                                        ((saveSiteFeedbackResult?.msg !==
                                            undefined &&
                                            saveSiteFeedbackResult?.msg !==
                                                '') ||
                                            saveSiteFeedbackError)
                                    }
                                >
                                    {saveSiteFeedbackError
                                        ? 'Error: ' +
                                          saveSiteFeedbackError.message
                                        : saveSiteFeedbackResult &&
                                          saveSiteFeedbackResult?.msg
                                        ? saveSiteFeedbackResult.msg
                                        : ''}
                                </AlertComponent>
                                <Form
                                    noValidate
                                    validated={validated}
                                    onSubmit={onSiteFeedbackFormSubmit}
                                    id="siteFeedbackForm"
                                >
                                    <Form.Group className="mb-3">
                                        <TextLabel>
                                            <RequiredTextLabel>
                                                *
                                            </RequiredTextLabel>
                                            Feedback:
                                        </TextLabel>
                                        <Form.Control
                                            as="textarea"
                                            rows={4}
                                            placeholder="Enter Feedback..."
                                            autoFocus
                                            required
                                            onChange={onSiteFeedbackTextChange}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter Feedback.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Modal.Footer>
                                        <Button
                                            variant="secondary"
                                            onClick={handleSiteFeedbackClose}
                                        >
                                            Cancel
                                        </Button>
                                        <Button variant="primary" type="submit">
                                            Submit
                                        </Button>
                                    </Modal.Footer>
                                </Form>
                            </Modal.Body>
                        </Modal>
                    </ModalPopupContainer>
                </div>
            ) : (
                <SpinnerDiv>
                    <Spinner animation="border" variant="dark">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </SpinnerDiv>
            )}
        </CatalogFormContainer>
    )
}

export default CatalogPanel
