import {
    FunctionComponent,
    useRef,
    useState,
    FormEvent,
    useCallback,
    useEffect,
} from 'react'
import styled from 'styled-components'
import {
    Button,
    FormControl,
    Spinner,
    Alert,
    FormLabel,
    Form,
    Col,
    Row,
    OverlayTrigger,
    Popover,
    Breadcrumb,
    BreadcrumbItem,
    Table,
} from 'react-bootstrap'
import Select from 'react-select'
import { LoadingState } from '../../models/loadingState'
import { CreateDiscountContainerLogic } from './createDiscountPanel.hook'
import StringUtils from '../../lib/stringUtils'
import React from 'react'
import { CopyToClipboardPanel } from '../copyToClipboardPanel/copyToClipboardPanel'
import { clearCodeState } from '../../store/discount.slice'
import { useDispatch } from 'react-redux'

const CreateDiscountFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
`
const CreateDiscountFormComponent = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
const HorizontalContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`

const ButtonSpinnerHorizontalComponent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 1rem;
`

const UploadButton = styled(Button)`
    display: flex;
    justify-content: center;
    margin-left: 0.6rem;
    margin-right: 1.35rem;
    border-radius: 0;
    background: #0054ae;
    &:hover {
        border-radius: 0;
        background: #0054ae;
        transition: 0.5s;
    }
`
const CancelButton = styled(Button)`
    display: flex;
    justify-content: center;
    margin-right: 1rem;
    border-radius: 0;
    background: #0054ae;
    &:hover {
        border-radius: 0;
        background: #0054ae;
        transition: 0.5s;
    }
`
const TextBoxWrapper = styled(FormControl)`
    display: flex;
    border-radius: ${(props) => props.theme.shape.input.borderRadius};
    margin-bottom: 1rem;
    //min-height: 4vh;
`

const PageTitleTextLabel = styled(FormLabel)`
    color: var(
        --global-header-light-light-fill,
        var(--black-and-white-white, #fff)
    );
    text-align: center;
    font-family: 'IntelOne Display';
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px; /* 120% */
    letter-spacing: -0.2px;
    text-align: left;
    padding: 1rem 0;
`

const TextLabel = styled(FormLabel)`
    display: flex;
    color: var(--input-label-light-default, #494b51);
    font-family: 'IntelOne Display';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
`

const SpinnerComponent = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    justify-content: center;
    align-items: center;
`

const AlertComponent = styled(Alert)`
    display: flex;
    margin-top: 1rem;
    width: 35vw;
    justify-content: center;
`
const RequiredTextLabel = styled.span`
    display: flex;
    ${(props) => props.theme.typography.xLarge}
    color: red;
`
const PopoverKeyDiv = styled.div`
    background-color: #cff4fc;
    font-size: 1rem;
    padding: 0.5rem;
`
const PageTitleTextLabel2 = styled(FormLabel)`
    color: var(--text-default, #2b2c30);
    font-family: IntelOne Display;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px; /* 150% */
    padding: 2rem 0.8rem;
`
const StyledRow = styled(Row)`
    //width: 70%;
    padding: 0.5rem;
`
const FormGroupCol = styled(Form.Group)`
    //width: 50%;
`

const RequiredFieldMessage = styled.span`
    display: flex;
    font-size: 0.875em;
    color: #dc3545;
`
const HeaderComponent = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: -1rem;
    justify-content: center;
    padding: 0 3%;
    background-color: rgb(101, 49, 113);
    background-image: ${(props) =>
        `url('${process.env.PUBLIC_URL}/background-l1-mosaic.svg')`};
    background-position-x: 50%;
    background-position-y: 50%;
    background-repeat: no-repeat;
    background-size: 100%;
    width: 100%;

    height: 200px;
`

const FlexEndContainer: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'flex-end',
}

export interface ICreateDiscountPanelProps {
    onCreateDiscountSubmit: (createDiscountRequest: any) => void
    createDiscountResult: any
    createDiscountError: any
    createDiscountLoading: any
    clearData: any
    formRef?: any
}

const CreateDiscountPanel: FunctionComponent<ICreateDiscountPanelProps> = ({
    onCreateDiscountSubmit,
    createDiscountResult,
    createDiscountError,
    createDiscountLoading,
    clearData,
}: ICreateDiscountPanelProps) => {
    var errorText = null
    if (createDiscountError) {
        errorText =
            'We are experiencing issues while creating a discount request.'
    }
    const formRef = useRef<HTMLFormElement>(null)
    const {
        validated,
        setValidated,
        product,
        setProduct,
        duration,
        onDurationChange,
        total,
        onTotalChange,
    } = CreateDiscountContainerLogic({
        onCreateDiscountSubmit,
        formRef,
    } as ICreateDiscountPanelProps)

    const [submitted, setSubmitted] = useState(false)
    const dispatch = useDispatch()

    const durationToNanoSeconds = (days: number) => {
        return days * 24 * 60 * 60 * 1e9
    }
            
    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const form = event.currentTarget

        if (form.checkValidity() === false) {
            event.preventDefault()
            event.stopPropagation()
            setValidated(true)
            return
        }

        dispatch(clearCodeState() as any) //reset report state when removing a path from the list

        const payload: any = {
            duration: durationToNanoSeconds(duration),
            productIds: [product.value],
        }

        for (let index = 0; index < total; index++) {
            onCreateDiscountSubmit(payload)
        }
    }

    const productOptions = [
        {
            value: 'performance_hub_premium_tier',
            label: 'PH premium tier',
        },
    ]

    const copyCode = useCallback((e: FormEvent<HTMLElement>, code: string) => {
        e.preventDefault()
        try {
            const cpCommand = code ?? 'Issue copying code'
            navigator.clipboard.writeText(cpCommand)
        } catch (err) {
            console.log(err)
            navigator.clipboard.writeText('Issue copying code')
        }
    }, [])

    const createForm = (
        <div>
            <StyledRow>
                <PageTitleTextLabel2>
                    Create a new discount code
                </PageTitleTextLabel2>
            </StyledRow>
            <StyledRow>
                <FormGroupCol as={Col}>
                    <TextLabel data-testid="duration-label">
                        <RequiredTextLabel>*</RequiredTextLabel>
                        Duration in days
                        <OverlayTrigger
                            placement={'right'}
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                                <Popover
                                    id="pop_timeline"
                                    style={{
                                        maxWidth: '20%',
                                    }}
                                >
                                    <Popover.Body>
                                        <PopoverKeyDiv>
                                            Please enter an expiration time in
                                            days.
                                        </PopoverKeyDiv>
                                    </Popover.Body>
                                </Popover>
                            }
                        >
                            <i
                                className="uil uil-info-circle pointer"
                                tabIndex={0}
                            ></i>
                        </OverlayTrigger>
                    </TextLabel>
                    <TextBoxWrapper
                        id="duration"
                        name="duration"
                        placeholder="Enter duration..."
                        required
                        defaultValue={duration}
                        data-testid="duration-value"
                        onChange={onDurationChange}
                        type="number"
                        min={0}
                    />
                    <Form.Control.Feedback type="invalid">
                        Please enter an duration.
                    </Form.Control.Feedback>
                </FormGroupCol>
            </StyledRow>

            <StyledRow>
                <FormGroupCol as={Col}>
                    <TextLabel data-testid="product-label">
                        <RequiredTextLabel>*</RequiredTextLabel>
                        Product
                    </TextLabel>
                    <Select
                        name="product"
                        aria-label="Select product"
                        data-testid="product-value"
                        isSearchable={false}
                        isClearable={false}
                        isMulti={false}
                        options={productOptions}
                        value={product}
                        onChange={(target: any) => setProduct(target)}
                        required
                    />
                    {validated && product.length === 0 ? (
                        <RequiredFieldMessage>
                            Please select a product.
                        </RequiredFieldMessage>
                    ) : (
                        ''
                    )}
                </FormGroupCol>
            </StyledRow>
            <StyledRow>
                <FormGroupCol as={Col}>
                    <TextLabel data-testid="duration-label">
                        Number of promocodes
                    </TextLabel>
                    <TextBoxWrapper
                        id="total"
                        name="total"
                        placeholder="Enter the number of promocodes..."
                        required
                        defaultValue={total}
                        data-testid="total-value"
                        onChange={onTotalChange}
                        type="number"
                        min="1"
                    />
                </FormGroupCol>
            </StyledRow>
        </div>
    )

    return (
        <CreateDiscountFormContainer>
            <HorizontalContainer>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        background: '#fff',
                    }}
                >
                    <Breadcrumb style={{ padding: '0.5rem 1rem' }}>
                        <BreadcrumbItem
                            href={StringUtils.resolve('/catalog')}
                            style={{ textDecoration: 'none', color: '#0068b5' }}
                        >
                            Intel Optimization Hub
                        </BreadcrumbItem>

                        <BreadcrumbItem
                            active
                            style={{ fontWeight: '500', color: '#262626' }}
                        >
                            Create Discount Code
                        </BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <HeaderComponent>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <PageTitleTextLabel data-testid="createDiscountPage-title">
                            Create Discount Code
                        </PageTitleTextLabel>
                    </div>
                </HeaderComponent>

                <CreateDiscountFormComponent>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: '50%',
                        }}
                    >
                        <Form
                            ref={formRef}
                            noValidate
                            validated={validated}
                            id="uploadForm"
                            onSubmit={handleSubmit}
                            style={{ paddingRight: '0.5rem', width: '60%' }}
                        >
                            {createForm}
                            <ButtonSpinnerHorizontalComponent>
                                <UploadButton
                                    variant="primary"
                                    type="submit"
                                    data-testid="finalize-button"
                                    disabled={submitted}
                                >
                                    Submit
                                </UploadButton>
                            </ButtonSpinnerHorizontalComponent>
                            {/* </div> */}
                        </Form>
                        <SpinnerComponent>
                            {createDiscountLoading === LoadingState.Pending ? (
                                <Spinner animation="border" variant="dark" />
                            ) : (
                                <div></div>
                            )}
                        </SpinnerComponent>
                    </div>

                    {createDiscountResult.size > 0 && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                alignItems: 'center',
                                width: '50%',
                            }}
                        >
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Code</th>
                                        <th>Duration</th>
                                        <th>Expires At</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.from(createDiscountResult).map(
                                        (item: any, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent:
                                                                    'space-between',
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    marginLeft:
                                                                        '0.5rem',
                                                                    marginRight:
                                                                        '1rem',
                                                                    marginBottom:
                                                                        '1rem',
                                                                }}
                                                            >
                                                                {item[1].code}{' '}
                                                            </div>
                                                            <div
                                                                style={
                                                                    FlexEndContainer
                                                                }
                                                            >
                                                                <CopyToClipboardPanel
                                                                    isDisabled={
                                                                        false
                                                                    }
                                                                    onClickCallBack={(
                                                                        event: FormEvent<HTMLElement>
                                                                    ) => {
                                                                        copyCode(
                                                                            event,
                                                                            item[1]
                                                                                .code
                                                                        )
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>{`${duration} days`}</td>
                                                    <td>
                                                        <div>
                                                            {item[1].expiresAt}
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    )}
                                </tbody>
                            </Table>
                        </div>
                    )}
                </CreateDiscountFormComponent>

                <AlertComponent
                    id="errorAlert"
                    variant="primary"
                    show={createDiscountError?.message ? true : false}
                    data-testid="error-text"
                >
                    {'Error!: ' + errorText}
                </AlertComponent>
            </HorizontalContainer>
        </CreateDiscountFormContainer>
    )
}

export default CreateDiscountPanel
