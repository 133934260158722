export const ragModelExampleData = `[
	0.082053415, 
	0.0360537, 
	-0.003854463, 
	-0.0048799193, 
	0.025683796, 
	-0.05718582, 
	0.012177993, 
	0.0046576336, 
	0.03503192, 
	-0.02239194, 
	-0.008060708, 
	-0.10933292, 
	0.022713095, 
	-0.029317794, 
	-0.043502346, 
	-0.12026458, 
	-0.00085718965, 
	-0.018142177, 
	0.05619083, 
	0.0030806356, 
	0.0023060027, 
	-0.016876576, 
	0.063648626, 
	-0.023684721, 
	0.031507663, 
	-0.034890167, 
	-0.020549683, 
	-0.002786161, 
	-0.0110764615, 
	-0.036140054, 
	0.054120027, 
	-0.036583215, 
	-0.025011724, 
	-0.038254034, 
	-0.04967295, 
	-0.015192999, 
	0.021378718, 
	-0.012772322, 
	0.076642945, 
	0.044378694, 
	-0.010812854, 
	-0.029750308, 
	-0.016903697, 
	-0.02467768, 
	0.008030328, 
	0.043571327, 
	0.0071560945, 
	0.07550244, 
	0.032825973, 
	-0.062037773, 
	0.066731565, 
	0.027043108, 
	-0.04572608, 
	-0.03142908, 
	-0.031152584, 
	0.09157666, 
	-0.0017861156, 
	-0.011269201, 
	0.03653846, 
	0.056943975, 
	0.002313242, 
	-0.037770163, 
	-0.01548338, 
	0.052389022, 
	0.060334176, 
	-0.016586425, 
	0.00883662, 
	-0.006657562, 
	-0.106253564, 
	0.001670348, 
	-0.048428908, 
	-0.029719073, 
	0.004335352, 
	-0.085691735, 
	0.0662365, 
	-0.055175226, 
	-0.11329983, 
	0.05082034, 
	-0.009341265, 
	0.0059642154, 
	0.021016268, 
	-0.02251657, 
	0.0004965661, 
	0.056396205, 
	0.045467425, 
	-0.0052640955, 
	0.093610026, 
	0.027505804, 
	0.029326594, 
	-0.045665685, 
	-0.048965108, 
	0.00141913, 
	-0.012904292, 
	0.07979506, 
	-0.11906316, 
	0.06881054, 
	-0.022728547, 
	0.044899497, 
	-0.081354745, 
	0.044010967, 
	0.0029387283, 
	0.017588636, 
	0.083176024, 
	-0.018080637, 
	-0.047953177, 
	0.05869629, 
	0.006255999, 
	-0.01468543, 
	-0.007279633, 
	-0.07814077, 
	-0.10078438, 
	-0.033518795, 
	-0.00090215, 
	-0.051115405, 
	0.027155442, 
	0.07080291, 
	0.047403276, 
	-0.104559526, 
	0.004433659, 
	-0.028793098, 
	-0.018349672, 
	-0.050571322, 
	-0.03153601, 
	-0.009529343, 
	-0.060607858, 
	0.021096358, 
	-0.04666442, 
	-7.760821e-33, 
	-0.031347148, 
	0.05640987, 
	0.07738295, 
	0.063865796, 
	-0.046649057, 
	-0.007651372, 
	-0.055304833, 
	0.040276323, 
	-0.03158867, 
	-0.0070744194, 
	0.03946533, 
	-0.13170666, 
	-0.0661355, 
	0.021738606, 
	0.09701852, 
	0.011771649, 
	0.0890193, 
	0.034684137, 
	-0.043858312, 
	-0.00024668692, 
	0.0146653075, 
	-0.0026912095, 
	-0.003328232, 
	0.01735398, 
	0.060071215, 
	0.039420944, 
	-0.0017148664, 
	0.07728501, 
	0.01458591, 
	-0.002228884, 
	-0.0018495192, 
	0.0150420405, 
	0.021677528, 
	0.007390475, 
	0.018044282, 
	0.049770243, 
	0.012640178, 
	-0.0025361932, 
	0.04344402, 
	0.06292428, 
	0.06657811, 
	-0.03636353, 
	-0.03873837, 
	0.044048995, 
	0.005625407, 
	0.0056241625, 
	-0.034928575, 
	-0.071458615, 
	0.1008508, 
	-0.02475284, 
	0.014768495, 
	-0.025863484, 
	-0.07270944, 
	-0.017329965, 
	0.026055258, 
	0.11418177, 
	-0.0708858, 
	0.017944269, 
	-0.0033797962, 
	0.008472165, 
	-0.0031895214, 
	0.005916743, 
	-0.022965753, 
	0.07753361, 
	0.034708157, 
	0.08747694, 
	0.046299223, 
	0.018652396, 
	0.011080422, 
	-0.045819014, 
	-0.046470754, 
	0.026545957, 
	0.0739458, 
	0.06559945, 
	0.06267112, 
	0.07235097, 
	-0.008901157, 
	-0.035396542, 
	-0.0053283293, 
	-0.0031782032, 
	-0.037932415, 
	-0.0413545, 
	-0.09662536, 
	0.04416768, 
	-0.033495013, 
	-0.07138676, 
	-0.011685527, 
	-0.007115763, 
	0.00060310756, 
	-0.08838736, 
	-0.11327571, 
	-0.12125187, 
	-0.0013441198, 
	-0.044261463, 
	-0.08663444, 
	3.9976182e-33, 
	0.025347263, 
	-0.002666439, 
	-0.08115418, 
	0.02547563, 
	0.0013200137, 
	0.016009865, 
	0.095509686, 
	0.033245146, 
	-0.012021056, 
	0.017043531, 
	-0.08307677, 
	-0.124536596, 
	0.04391074, 
	0.01205463, 
	0.06585122, 
	0.10055866, 
	0.07288042, 
	-0.026909743, 
	-0.032203205, 
	-0.05358868, 
	-0.12635627, 
	0.005483027, 
	-0.035359755, 
	-0.004276711, 
	-0.025037408, 
	0.04156676, 
	-0.09992487, 
	-0.047651537, 
	-0.02397306, 
	0.002648153, 
	-0.055137087, 
	0.013584516, 
	0.04899451, 
	0.08497756, 
	-0.0420358, 
	0.07670477, 
	0.033197954, 
	0.0013003969, 
	0.0399736, 
	0.06451197, 
	-0.04338604, 
	-0.049685672, 
	0.057945102, 
	0.112596214, 
	0.07072986, 
	0.008242617, 
	0.043967377, 
	-0.022483742, 
	-0.007249645, 
	0.049910028, 
	0.038655475, 
	0.067874305, 
	-0.04100786, 
	0.0057572634, 
	0.017925665, 
	0.04926154, 
	-0.051394492, 
	0.051044166, 
	-0.09388298, 
	-0.06816434, 
	0.06533875, 
	0.07544791, 
	-0.016858414, 
	0.06610558, 
	-0.002878036, 
	-0.020672297, 
	-0.12706217, 
	0.061549358, 
	-0.009856405, 
	-0.01471292, 
	0.13545972, 
	0.03415541, 
	-0.06486573, 
	0.051000226, 
	-0.06637929, 
	0.02918287, 
	0.07942373, 
	0.014391765, 
	-0.027299652, 
	0.0053012134, 
	-0.067571945, 
	-0.020459477, 
	-0.02708977, 
	-0.026134996, 
	-0.070554845, 
	0.034694377, 
	0.007587482, 
	-0.102162704, 
	0.058431737, 
	-0.07477193, 
	-0.022021074, 
	-0.006806209, 
	-0.051266257, 
	-0.03697175, 
	0.025662806, 
	-1.7506446e-8, 
	0.06809503, 
	0.045011617, 
	-0.044067286, 
	0.012844112, 
	-0.057828475, 
	-0.09543543, 
	0.062184565, 
	-0.004233669, 
	-0.008603852, 
	0.00022124458, 
	-0.07360624, 
	0.056031022, 
	-0.06966707, 
	-0.05110823, 
	-0.04107145, 
	-0.004761556, 
	-0.032432225, 
	0.04311428, 
	0.008688461, 
	0.022734078, 
	-0.0048714117, 
	0.023290716, 
	-0.045668215, 
	-0.058109388, 
	0.012546777, 
	-0.099042214, 
	0.04064745, 
	0.04567546, 
	0.002722525, 
	-0.005268416, 
	0.06631189, 
	-0.027339695, 
	-0.05007115, 
	-0.09027358, 
	-0.036165286, 
	0.012681415, 
	-0.0058860113, 
	-0.0049702167, 
	0.009472349, 
	-0.029026067, 
	0.09500838, 
	0.06195468, 
	0.012483396, 
	-0.01197728, 
	0.024468577, 
	0.045390017, 
	0.053827565, 
	-0.035200987, 
	0.11462477, 
	-0.089033, 
	-0.11142512, 
	0.09939129, 
	0.0039211195, 
	0.004484974, 
	0.0033698583, 
	0.07088602, 
	-0.05134891, 
	-0.012625692, 
	0.021861322, 
	-0.02007207, 
	-0.014920605, 
	0.04920885, 
	0.08939083, 
	-0.011082741
    ]`
