import { FunctionComponent, useEffect, useState } from 'react'
import styled from 'styled-components'
import amplify_conf from '../services/auth/amplify'
import Amplify from '@aws-amplify/core'
import Auth from '@aws-amplify/auth'
import { useAppSelector } from '../config/hooks'
import { Nav, NavDropdown, Badge } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import StringUtils from '../lib/stringUtils'
import SignInHeader from './signInHeader'

Amplify.configure(amplify_conf)
import { _getDecryptedValueFromStorage } from '../lib/encryptStorage'
import stringUtils from '../lib/stringUtils'
import { APP_BASE_URL } from '../config/service'

const LinkPanelContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
    margin-top: -4.2rem;
    margin-left: 50%;
    @media screen and (max-width: 700px) {
        display: none;
    }
`

const UserInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
`
const UserLogoStyling = styled.div`
    display: flex;
    margin-right: 2rem;
    padding: 0.5rem;
    justify-content: right;
    Corner radius: 40px;
    Scale Mode: Fill;  
`
const UserInfoStyling = styled.div`
    display: flex;
    padding-right: 1rem;
    justify-content: right;
    font-weight: 400;
    font-style: italic;
`

const ParentNavDiv = styled(NavDropdown.Item)`
    font-family: 'IntelOne Display', sans-serif;
    font-size: 1rem;
    line-height: 1.4;
    padding-left: 0.2rem;
    color: #292f4c;
    font-weight: 400;
    &:active,
    &:hover {
        color: #ffc42a;
        font-weight: 500;
    }
`

const StyledNavDropdown = styled(NavDropdown)`
    font-family: 'IntelOne Display', sans-serif;
    font-size: 1rem;
    line-height: 1.4;
    padding-left: 0.2rem;
    color: #292f4c;
    font-weight: 400;
    margin-right: 0.5rem;
`

const UserNameText = styled.div`
    font-family: 'IntelOne Display', sans-serif;
    font-size: 1rem;
    line-height: 1.4;
    padding-left: 0.2rem;
    font-weight: 400;
    margin-right: 0.5rem;
    color: #fff;
    @media screen and (max-width: 700px) {
        color: #292f4c;
    }
`
const LastSeenText = styled.div`
    font-family: 'IntelOne Display', sans-serif;
    font-size: 0.875rem;
    line-height: 1.2;
    padding-left: 0.5rem;
    font-weight: 400;
    font-style: italic;
    justify-content: center;
    color: #fff;
    @media screen and (max-width: 700px) {
        display: none;
    }
`

const UserNameVerticalContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 1rem;
`

const StyledNavLink = styled(Nav.Link)`
    font-family: 'IntelOne Display', sans-serif;
    font-size: 1rem;
    color: #292f4c;
    font-weight: lighter;
    line-height: 3rem;

    &:active,
    &:hover {
        color: #ffc42a;
        font-weight: 500;
    }
    @media screen and (max-width: 500px) {
        min-width: 6rem;
    }
`

export interface IUserPanel {
    isPublicSearch?: boolean
}

export const UserPanel: FunctionComponent<IUserPanel> & {} = ({
    isPublicSearch,
}: IUserPanel) => {
    const [userLoginName, setUserLoginName] = useState({})

    const userDisplayName = localStorage.getItem('userDisplayName')

    const GetSignInInfo = async () => {
        try {
            await Auth.currentSession().then((res) => {
                let idToken = res.getIdToken()
                const ID_token = idToken.getJwtToken()
                let parts = ID_token.split('.')
                let user_info = atob(parts[1])
                if (user_info) {
                    AssignUserInfo(user_info)
                }
                return user_info
            })
        } catch (error) {
            console.log('error getting signing in info: ', error)
        }
    }

    const formattedName = (name: string) => {
        if (name.includes('@')) {
            return name
        }
        const arr = name.split(' ')
        for (var i = 0; i < arr.length; i++) {
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
        }
        const str2 = arr.join(' ')
        return str2
    }

    const AssignUserInfo = (userSignInfo: string) => {
        if (userSignInfo) {
            let userInfo = JSON.parse(userSignInfo)
            let userName = ''
            if (
                userInfo &&
                userInfo['name'] &&
                !stringUtils.isNullOrUndefinedOrEmpty(userInfo['name'])
            ) {
                userName = userInfo['name']
            } else if (!stringUtils.isNullOrUndefinedOrEmpty(userDisplayName)) {
                const userDisplayNameNotNull = userDisplayName
                    ? userDisplayName
                    : ''
                const formattedUserDisplayName = formattedName(
                    userDisplayNameNotNull
                )

                userName = formattedUserDisplayName ?? ''
            } else if (userInfo && userInfo['email']) {
                userName = userInfo['email']
            } else if (userInfo && userInfo['cognito:username']) {
                userName = userInfo['cognito:username']
            }
            setUserLoginName(userName)
            localStorage.setItem('userName', userName)
        }
    }

    useEffect(() => {
        //get user info from amplify session after cognito login.
        if (!isPublicSearch) GetSignInInfo()
    }, [])

    const UserComponent = () => (
        <UserLogoStyling>
            {/* <img
            src="/profile.png"
            height="30px"
            width="30px"
            alt="profile logo"            
        />         */}
        </UserLogoStyling>
    )

    const userName = userLoginName ? 'Welcome, ' + userLoginName : ''

    return (
        <UserInfoContainer>
            {!isPublicSearch ? (
                <div>
                    <UserComponent></UserComponent>
                    <UserInfoStyling></UserInfoStyling>
                    <UserSettings userName={userName}></UserSettings>
                </div>
            ) : (
                <SignInHeader />
            )}
        </UserInfoContainer>
    )
}

export interface IUserSettings {
    userName: string
}

const UserProfileImage = (
    <img src="/myaccount.svg" height="25" width="25" alt="profile logo" />
)

export const UserSettings: FunctionComponent<IUserSettings> = ({
    userName,
}: IUserSettings) => {
    let navigate = useNavigate()
    let featureFlags: string[] = []
    let empStatus = _getDecryptedValueFromStorage('isIntelEmployee')
    let featureFlagsValue = _getDecryptedValueFromStorage('featureFlags')
    if (featureFlagsValue) featureFlags = featureFlagsValue.split(',')
    let isIntelEmployee = !StringUtils.isNullOrUndefinedOrEmpty(empStatus)
        ? Boolean(empStatus)
        : false
    const lastLoginDate = localStorage.getItem('lastLoginDate')
    const lastLocation = localStorage.getItem('lastLocation')
    const [cartItemCount, setCartItemCount] = useState(0)
    let isCloudInsightsLoad = false
    let isCatalog = false
    const requestedUrl = `${window.location.pathname}${window.location.search}`
    if (requestedUrl && requestedUrl.indexOf('/catalog') > -1) isCatalog = true

    if (
        (requestedUrl && requestedUrl.startsWith('/cloudInsights')) ||
        requestedUrl.startsWith('/addOrganization')
    )
        isCloudInsightsLoad = true

    const cartData = useAppSelector<any>(
        (state) => state.getCartItemsResult.data
    )

    useEffect(() => {
        if (cartData?.cartitems) {
            setCartItemCount(cartData.cartitems.length)
        } else {
            setCartItemCount(0)
        }
    }, [cartData])

    const CartImage = (
        <StyledNavLink onClick={() => navigate('/cart')} href="/cart">
            <img
                className="uil uil-shopping-cart"
                height="20"
                width="20"
                alt="user cart"
                src="/cart.svg"
            />
            <Badge
                pill
                bg={'warning'}
                text="dark"
                style={{ marginLeft: '.5em' }}
            >
                {cartItemCount}
            </Badge>
        </StyledNavLink>
    )

    return (
        <LinkPanelContainer>
            <UserNameVerticalContainer>
                <UserNameText style={isCatalog ? { color: '#fff' } : {}}>
                    {userName}
                </UserNameText>
                {lastLoginDate &&
                lastLoginDate !== '' &&
                lastLocation === '' &&
                isCatalog ? (
                    <LastSeenText>Seen {lastLoginDate}</LastSeenText>
                ) : lastLoginDate &&
                  lastLoginDate !== '' &&
                  lastLocation &&
                  lastLocation !== '' &&
                  isCatalog ? (
                    <LastSeenText>
                        Seen {lastLoginDate} from {lastLocation}
                    </LastSeenText>
                ) : (
                    ''
                )}
            </UserNameVerticalContainer>
            {/*hide cart*/}
            {/* <div>{CartImage}</div> */}
            <StyledNavDropdown
                title={UserProfileImage}
                id="basic-nav-dropdown"
                href="javascript:void(0)"
            >
                <ParentNavDiv
                    href={
                        APP_BASE_URL
                            ? `${APP_BASE_URL}/membership`
                            : '/membership'
                    }
                >
                    <i className="uil uil-apps"></i> Membership
                </ParentNavDiv>
                {isCloudInsightsLoad &&
                featureFlags?.includes('isDemoViewer') ? (
                    <ParentNavDiv
                        onClick={() => navigate('/addOrganization')}
                        href="javascript:void(0)"
                    >
                        <i className="uil uil-book-medical"></i>
                        Add Organization
                    </ParentNavDiv>
                ) : (
                    ''
                )}
                {isIntelEmployee ? (
                    <ParentNavDiv
                        href={
                            APP_BASE_URL
                                ? `${APP_BASE_URL}/accessMgmt`
                                : '/accessMgmt'
                        }
                    >
                        <i className="uil uil-users-alt"></i> Access
                    </ParentNavDiv>
                ) : (
                    ''
                )}
                <ParentNavDiv
                    href={APP_BASE_URL ? `${APP_BASE_URL}/logOut` : '/logOut'}
                >
                    <i className="uil uil-sign-out-alt"></i> Log out
                </ParentNavDiv>
            </StyledNavDropdown>
        </LinkPanelContainer>
    )
}

export default UserPanel
