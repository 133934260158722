import { Typography } from '@mui/material'
import { FC, PropsWithChildren, ReactElement } from 'react'
import ChartLoader from './ChartLoader'

interface ChartWrapperProps extends PropsWithChildren {
    isDataLoading: boolean
    noDataCondition: boolean
    children: ReactElement
}

const ChartWrapper: FC<ChartWrapperProps> = ({
    isDataLoading,
    noDataCondition,
    children,
}) => (
    <>
        {isDataLoading ? (
            <ChartLoader message="Fetching data..." />
        ) : noDataCondition ? (
            <Typography>No data to display</Typography>
        ) : (
            children
        )}
    </>
)

export default ChartWrapper
