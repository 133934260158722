/************************************************
 * Copyright (C) 2021 Intel Corporation
 ************************************************/
import HeaderPanel from '../../layout/header'
import FooterPanel from '../../layout/footer'
import UserPanel from '../../layout/userProfile'
import StringUtils from '../../lib/stringUtils'
import { _getDecryptedValueFromStorage } from '../../lib/encryptStorage'
import { UserOtherMetaRoles } from '../../models/userRoles'
import { Alert, Box, Typography } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import theme from './theme'
import DashboardPanel from '../../components/dashboardPanel/DashboardPanel'

const dashboardPageBoxSx = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    height: '100vh',
    overflowX: 'hidden',
    flexWrap: 'no-wrap',
}

const dashboardContainerBoxSx = {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
}

const DashboardContainer = () => {
    //get isAnalyst and isIntelEmployee info from storage
    let otherMetaPermissions: string[] = []
    const otherPermissions = _getDecryptedValueFromStorage(
        'otherMetaPermissions'
    )
    if (otherPermissions) {
        otherMetaPermissions = otherPermissions.split(',')
    }
    let isAnalyst = otherMetaPermissions.includes(UserOtherMetaRoles.Analyst)

    let intelEmpStatus = localStorage.getItem('isIntelEmployee')

    let isIntelEmployee = !StringUtils.isNullOrUndefinedOrEmpty(intelEmpStatus)
        ? Boolean(intelEmpStatus)
        : false

    const showDashboard = isAnalyst && isIntelEmployee

    return (
        <Box sx={dashboardPageBoxSx}>
            <HeaderPanel />
            <Box sx={dashboardContainerBoxSx}>
                <UserPanel />
                <ThemeProvider theme={theme}>
                    <Box sx={{ padding: '1rem 2rem' }}>
                        <Typography
                            variant="h1"
                            sx={{ marginBottom: '0.5rem' }}
                            data-testid="dashboard-title"
                        >
                            Dashboard
                        </Typography>
                        {showDashboard ? (
                            <DashboardPanel />
                        ) : (
                            <Alert severity="error">Permission denied!</Alert>
                        )}
                    </Box>
                </ThemeProvider>
            </Box>
            <FooterPanel />
        </Box>
    )
}

export default DashboardContainer
