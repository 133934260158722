/************************************************
 * Copyright (C) 2021 Intel Corporation
 ************************************************/
import { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { useAppDispatch, useAppSelector } from '../../config/hooks'
import { useParams, useLocation } from 'react-router-dom'
import {
    Button,
    FormControl,
    Table,
    FormLabel,
    Form,
    Col,
    Row,
    Breadcrumb,
} from 'react-bootstrap'
import UserPanel from '../../layout/userProfile'
import { getPerformanceSamples } from '../../store/performanceSamples.slice'
import HeaderPanel from '../../layout/header'

const PerformanceSamplesContainerDiv = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: space-between;
    @media screen and (min-width: 1200px) {
        overflow-x: hidden;
    }
`
const PerformanceSamplesHorizontalContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: space-between;
`
const PerformanceSamplesBodyContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: center;
    background-color: ${(props) => props.theme.color.background.defaultGrey};
    padding-bottom: 25rem;
`

const PerformanceBodyContainerReposition = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    margin-left: 2rem;
`

const PageLinkPanelPanelWrapper = styled.div`
    display: flex;
    margin-left: 2rem;
    @media screen and (max-width: 500px) {
        display: none;
    }
`
const MobileHeaderWrapper = styled.div`
    display: flex;
    display: none;
    @media screen and (max-width: 500px) {
        display: block;
    }
`

const PerformanceSamplesWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: flex-start;
`

const PageTitleTextLabel = styled(FormLabel)`
    ${(props) => props.theme.typography.xxxLarge}
    text-align: left;
    font-weight: 500;
    font-size: 2.25rem;
`

const TextLabel = styled(FormLabel)`
    display: flex;
    ${(props) => props.theme.typography.xLarge}
    text-align: left;
`

const TextBoxWrapper = styled(FormControl)`
    display: flex;
    border-radius: ${(props) => props.theme.shape.input.borderRadius};
    margin-bottom: 1rem;
    min-height: 4vh;
`

const RecipeTextLabel = styled(FormLabel)`
    display: flex;
    ${(props) => props.theme.typography.medium}
    text-align: left;
    margin-top: 0.5rem;
    white-space: pre-wrap;
    width: 12rem;
`

const RecipeTextLabelHeadings = styled(RecipeTextLabel)`
    color: ${(props) => props.theme.color.text.default};
    ${(props) => props.theme.typography.large}
`

const RecipeStatusTextLabelHeadings = styled(RecipeTextLabel)`
    color: ${(props) => props.theme.color.text.default};
    ${(props) => props.theme.typography.large}
    width: 12rem;
`

const TableContainer = styled.div`
    background: #ffffff;
    box-shadow: 0px 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
    border-radius: 1.5rem;
    padding: 2rem;
    margin: 0 2rem 2rem 0;
    max-width: 57rem;
`

const TableTitleTextLabel = styled(FormLabel)`
    ${(props) => props.theme.typography.xxLarge}
    text-align: left;
    font-weight: 400;
    font-size: 2rem;
`

export const PerformanceSamplesContainer: React.FC = () => {
    const dispatch = useAppDispatch()
    let { id } = useParams()
    const location = useLocation()
    const { appName } = location.state as { appName: string }

    const [startDate, setStartDate] = useState(
        JSON.parse(JSON.stringify(new Date(Date.now() - 86400000)))
    )
    const [endDate, setEndDate] = useState(
        JSON.parse(JSON.stringify(new Date()))
    )

    useEffect(() => {
        if (id)
            dispatch(
                getPerformanceSamples({
                    id,
                    startDate: JSON.parse(
                        JSON.stringify(new Date(Date.now() - 86400000))
                    ),
                    endDate: JSON.parse(JSON.stringify(new Date())),
                })
            )
    }, [])

    const performanceSamples = useAppSelector<any>(
        (state) => state.performanceSamplesResult.data
    )

    const data = []
    if (performanceSamples) {
        for (let version of Object.keys(performanceSamples)) {
            let currentSample = performanceSamples[version]
            for (let sample of currentSample) {
                const currentDate = sample['date']
                for (let customer of Object.keys(sample['customers'])) {
                    data.push({
                        date: currentDate,
                        customer,
                        version,
                        ratio: sample['customers'][customer]['avgCpuRatio'],
                    })
                }
            }
        }
    }
    const tableRows = data.map((sample: any, i: number) => (
        <tr key={i}>
            <td>{JSON.parse(JSON.stringify(sample.date)).split('T')[0]}</td>
            <td>{sample.customer}</td>
            <td>{sample.version}</td>
            <td>{sample.ratio}</td>
        </tr>
    ))

    return (
        <PerformanceSamplesContainerDiv>
            <HeaderPanel />
            <PerformanceSamplesHorizontalContainer>
                <PerformanceSamplesBodyContainer>
                    <PerformanceBodyContainerReposition>
                        <UserPanel></UserPanel>
                        <Breadcrumb>
                            <Breadcrumb.Item href="/catalog">
                                Catalog
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                Performance Samples
                            </Breadcrumb.Item>
                        </Breadcrumb>
                        <PerformanceSamplesWrapper>
                            <PageTitleTextLabel>
                                Performance Samples
                            </PageTitleTextLabel>
                            <Form>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="3">
                                        <TextLabel data-testid="target-date-label">
                                            Start Date
                                        </TextLabel>
                                        <TextBoxWrapper
                                            required
                                            type="date"
                                            name="startDate"
                                            id="startDate"
                                            placeholder="Enter target date..."
                                            data-testid="target-date-value"
                                            defaultValue={
                                                JSON.parse(
                                                    JSON.stringify(
                                                        new Date(
                                                            Date.now() -
                                                                86400000
                                                        )
                                                    )
                                                ).split('T')[0]
                                            }
                                            onChange={(evt: any) => {
                                                const startDate =
                                                    evt.target.value
                                                setStartDate(startDate)

                                                dispatch(
                                                    getPerformanceSamples({
                                                        id,
                                                        startDate: JSON.parse(
                                                            JSON.stringify(
                                                                new Date(
                                                                    startDate
                                                                )
                                                            )
                                                        ),
                                                        endDate: JSON.parse(
                                                            JSON.stringify(
                                                                new Date(
                                                                    endDate
                                                                )
                                                            )
                                                        ),
                                                    })
                                                )
                                            }}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="3">
                                        <TextLabel data-testid="target-date-label">
                                            End Date
                                        </TextLabel>
                                        <TextBoxWrapper
                                            required
                                            type="date"
                                            name="endDate"
                                            id="endDate"
                                            placeholder="Enter target date..."
                                            data-testid="target-date-value"
                                            min={startDate}
                                            defaultValue={
                                                JSON.parse(
                                                    JSON.stringify(new Date())
                                                ).split('T')[0]
                                            }
                                            onChange={(evt: any) => {
                                                const endDate = evt.target.value

                                                setEndDate(endDate)
                                                dispatch(
                                                    getPerformanceSamples({
                                                        id,
                                                        startDate: JSON.parse(
                                                            JSON.stringify(
                                                                new Date(
                                                                    startDate
                                                                )
                                                            )
                                                        ),
                                                        endDate: JSON.parse(
                                                            JSON.stringify(
                                                                new Date(
                                                                    endDate
                                                                )
                                                            )
                                                        ),
                                                    })
                                                )
                                            }}
                                        />
                                    </Form.Group>
                                </Row>
                            </Form>
                            <TableContainer>
                                <TableTitleTextLabel>
                                    {appName}
                                </TableTitleTextLabel>
                                <Table
                                    responsive
                                    style={{ minHeight: '14rem' }}
                                >
                                    <thead>
                                        <tr>
                                            <th>
                                                <RecipeStatusTextLabelHeadings>
                                                    Date
                                                </RecipeStatusTextLabelHeadings>
                                            </th>
                                            <th>
                                                <RecipeTextLabelHeadings>
                                                    Customer
                                                </RecipeTextLabelHeadings>
                                            </th>
                                            <th>
                                                <RecipeTextLabelHeadings>
                                                    Version
                                                </RecipeTextLabelHeadings>
                                            </th>
                                            <th>
                                                <RecipeStatusTextLabelHeadings>
                                                    Performance
                                                </RecipeStatusTextLabelHeadings>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>{tableRows}</tbody>
                                </Table>
                            </TableContainer>
                        </PerformanceSamplesWrapper>
                    </PerformanceBodyContainerReposition>
                </PerformanceSamplesBodyContainer>
            </PerformanceSamplesHorizontalContainer>
        </PerformanceSamplesContainerDiv>
    )
}
