import { useState, useCallback, FormEvent, useEffect, ChangeEvent } from 'react'
import { KeyboardEvent } from 'react'
import { _generateRandom } from '../../lib/generate'
import { useNavigate } from 'react-router'
import { IBenchmarkDetailsProps } from './benchmarkDetails'
import { useAppDispatch, useAppSelector } from '../../config/hooks'
import {
    downloadBenchmarkSlide,
    getWorkloadCustomerCaseStudies,
} from '../../store/benchmark.slice'
import { getFiltersValues, saveLinkClick } from '../../store/catalog.slice'
import { UserSalesPermissions } from '../../models/userRoles'
import { ICustomerCaseStudyProps } from './customerCaseStudyDetails'

export function CustomerCaseStudyLogic({
    getWorkloadCustomerCaseStudies,
    SearchComponentRef,
    downloadLinkRef,
    workloadId,
    optimizationGroupName,
    workloadCategory,
}: ICustomerCaseStudyProps) {
    const navigate = useNavigate()
    const [showBenchmarkMetaData, setShowBenchmarkMetaData] = useState(false)
    const [rowId, setRowId] = useState('')
    const [searchKey, setSearchKey] = useState('')
    const [attachmentFilenames, setAttachmentFilenames] = useState('')
    const [fileDownloadUrl, setfileDownloadUrl] = useState('')
    const [downloadCompleted, setDownloadCompleted] = useState(false)
    const [selectedAccessLevel, setSelectedAccessLevel] = useState<string>('')
    const [selectedFiltersValue, setSelectedFiltersValue] = useState<string[]>(
        []
    )
    const [showThumbnailPopup, setShowThumbnailPopup] = useState(false)
    const [attachedFileURL, setAttachedFileURL] = useState('')

    const ShowPPTThumbnailPopup = (url: string) => {
        setAttachedFileURL(url)
        setShowThumbnailPopup(true)
    }
    const ClosePPTThumbnailPopup = () => {
        setShowThumbnailPopup(false)
        setAttachedFileURL('')
    }

    const dispatch = useAppDispatch()
    const getFilteredData = () => {
        const paramsValues = `${workloadId}/customer-case-study?search=${
            searchKey ? searchKey.trim() : ''
        }${getSelectedAccessGroup()}&workload-category=${
            workloadCategory ? workloadCategory.replace('&', '%26') : ''
        }`
        dispatch(
            getWorkloadCustomerCaseStudies({
                params: paramsValues,
                tagValues: selectedFiltersValue ? selectedFiltersValue : [],
            })
        )
    }

    useEffect(() => {
        if (workloadId) {
            getFilteredData()
        }
    }, [])
    useEffect(() => {
        //get data based on user access permission and filter change
        if (selectedAccessLevel || selectedFiltersValue) {
            getFilteredData()
        }
    }, [selectedAccessLevel, selectedFiltersValue])

    const downloadSlideResultData = useAppSelector<any>(
        (state: any) => state.downloadBenchmarkSlideResult.data
    )

    const onShareLinkClick = useCallback((event: FormEvent<HTMLElement>) => {
        event.preventDefault()
        const target = event.currentTarget as HTMLButtonElement
        if (target.value !== '') {
            navigator.clipboard.writeText(target.value)
            alert('Link copied to clipboard: ' + target.value)
        }
    }, [])
    const onShowBenchmarkMetaDataClick = useCallback(
        (
            event: FormEvent<HTMLElement>,
            id: string,
            benchmarkId: string,
            attachmentFilenames: string[]
        ) => {
            event.preventDefault()
            setDownloadCompleted(false) //reset
            if (showBenchmarkMetaData && rowId === id) {
                setShowBenchmarkMetaData(false)
                setRowId(id)
                URL.revokeObjectURL(fileDownloadUrl) //remove on collapse
                if (attachmentFilenames && attachmentFilenames.length > 0)
                    localStorage.removeItem(attachmentFilenames[0])
            } else {
                setShowBenchmarkMetaData(true)
                setRowId(id)
                //initiate download
                if (
                    benchmarkId &&
                    attachmentFilenames &&
                    attachmentFilenames.length > 0
                ) {
                    setAttachmentFilenames(attachmentFilenames[0])
                    dispatch(
                        downloadBenchmarkSlide(
                            `${benchmarkId}/attachment/${attachmentFilenames[0]}`
                        )
                    )
                }
            }
        },
        [showBenchmarkMetaData, rowId]
    )
    useEffect(() => {
        if (downloadSlideResultData && showBenchmarkMetaData) {
            const fileType = attachmentFilenames.substring(
                attachmentFilenames.length - 3
            )
            const blob = new Blob([downloadSlideResultData], {
                type: `application/${fileType}`,
            })
            const fileDownloadUrl = URL.createObjectURL(blob)
            setfileDownloadUrl(fileDownloadUrl)

            if (downloadLinkRef.current !== undefined) {
                downloadLinkRef.current.href = fileDownloadUrl
                localStorage.setItem(
                    attachmentFilenames,
                    downloadLinkRef.current.href
                )
            }
            setDownloadCompleted(true)
        }
    }, [downloadSlideResultData])

    const downloadPPTPerfSlide = useCallback(
        (event: FormEvent<HTMLElement>) => {
            event.preventDefault()
            if (downloadLinkRef.current !== undefined && downloadCompleted) {
                downloadLinkRef.current.download = attachmentFilenames
                downloadLinkRef.current.click() //download file
            }
        },
        [downloadCompleted]
    )

    const onSearchKeyChange = useCallback((event: ChangeEvent<HTMLElement>) => {
        const target = event.currentTarget as HTMLTextAreaElement
        if (target.value != undefined) {
            setSearchKey(target.value.trim())

            if (target.value === '') {
                //reset search refresh data for default search
                if (workloadId) {
                    getFilteredData()
                }
            }
        }
    }, [])

    const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault()
            if (workloadId) {
                getFilteredData()
            }
        }
    }

    const getSelectedAccessGroup = () => {
        let accessGroupFilters = ''
        if (
            selectedAccessLevel &&
            selectedAccessLevel === UserSalesPermissions.InternalAll
        )
            accessGroupFilters = '&access-group=' + 'internal'
        else if (selectedAccessLevel) {
            accessGroupFilters =
                '&access-group=' + selectedAccessLevel.toLowerCase()
        } else {
            let getSalesAccessLevelValue = localStorage.getItem(
                'selectedSalesAccess'
            )
            if (getSalesAccessLevelValue) {
                accessGroupFilters =
                    '&access-group=' +
                    getSalesAccessLevelValue
                        .replace('All', '')
                        .trim()
                        .toLowerCase()
            }
        }

        return accessGroupFilters
    }
    const onAccessApplyChange = useCallback((selectedAccessLevel: string) => {
        setSelectedAccessLevel(selectedAccessLevel)
    }, [])

    const onHeaderSearchKeyChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLTextAreaElement
            if (target.value != undefined) {
                setSearchKey(target.value)
                if (target.value === '') {
                    //reset search refresh data for default search
                    if (workloadId) {
                        getFilteredData()
                    }
                }
            }
        },
        []
    )

    const onHeaderSearchKeydown = (eventKey: string) => {
        if (eventKey === 'Enter') {
            if (workloadId) {
                getFilteredData()
            }
        }
    }
    const onHeaderFilterClick = useCallback(
        (selectedFiltersValue: string[], count: number) => {
            setSelectedFiltersValue(selectedFiltersValue)
        },
        []
    )
    const onHeaderFilterClearAllClick = useCallback(
        (selectedFiltersValue: string[], count: number) => {
            setSelectedFiltersValue([])
        },
        []
    )
    const handleSalesLinkClick = useCallback(
        (
            linkUrl: string,
            workloadId: string,
            event: FormEvent<HTMLElement>
        ) => {
            event.preventDefault()
            if (linkUrl) {
                window.open(linkUrl, '_blank')
                //log link click into DB.
                if (workloadId)
                    dispatch(saveLinkClick({ uuid: workloadId, url: linkUrl }))
            }
        },
        []
    )
    return {
        onShareLinkClick,
        showBenchmarkMetaData,
        onShowBenchmarkMetaDataClick,
        rowId,
        onSearchKeyChange,
        onKeyDown,
        attachmentFilenames,
        fileDownloadUrl,
        downloadCompleted,
        downloadPPTPerfSlide,
        onAccessApplyChange,
        onHeaderSearchKeyChange,
        onHeaderSearchKeydown,
        onHeaderFilterClick,
        onHeaderFilterClearAllClick,
        ShowPPTThumbnailPopup,
        ClosePPTThumbnailPopup,
        showThumbnailPopup,
        attachedFileURL,
        handleSalesLinkClick,
    }
}
