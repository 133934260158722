import { Stack } from "./parser"

export {
    SVGParser
}

function SVGParser(input: string): Stack {
    const parser = new DOMParser();
    const doc = parser.parseFromString(input, "application/xml");
    const nodes = doc.getElementsByTagName("g")

    var svgNodes: Array<SVGNode> = new Array();

    for (let i = 0; i < nodes.length; i++) {
        let node = nodes[i]
        let title = node.getElementsByTagName("title")[0]
        const sample = parseFuncAndSample(title.textContent!)
        if (sample === undefined) {
            continue
        }
        let rect = node.getElementsByTagName("rect")[0]
        if (title.textContent === undefined) {
            continue
        }
        let x = +rect.getAttribute("x")!
        let y = +rect.getAttribute("y")!
        let width = +rect.getAttribute("width")!
        svgNodes.push({
            ...sample,
            x: x,
            y: y,
            width: width,
        })
    }

    svgNodes = svgNodes.sort((a, b) => {
        if (a.y > b.y) {
            return -1
        }
        if (a.y < b.y) {
            return 1
        }
        return 0
    })

    return collapse(svgNodes)
}

interface FuncAndSamples {
    name: string
    count: number
}

interface SVGNode extends FuncAndSamples {
    x: number
    y: number
    width: number
}

const parseFuncAndSample = function(input: string): (FuncAndSamples | undefined) {
    /*
     * Our input looks like
     *     some_Crazy_func_name (10 samples, 5%)
     *
     * We wanna start looking for the sample count from the end,
     * in case the some_Crazy_func_name contains parentheses
     */
    const closingParenIdx = input.lastIndexOf(")");
    if (closingParenIdx == -1) {
        return undefined
    }
    let openParenIdx = -1;
    for (let i = closingParenIdx; i >= 0; i--) {
        if (input[i] == "(") {
            openParenIdx = i;
            break;
        }
        continue
    }
    if (openParenIdx == -1) {
        return undefined
    }

    const samplesBit = input.slice(openParenIdx+1, closingParenIdx-1).split("samples,")
    if (samplesBit.length == 0) {
        return undefined
    }
    // Remove thousands-separator comma, because of course they have it.
    const sampleCount = +(samplesBit[0].replace(",", ""))

    let func = ""
    for (let i = 0; i < input.length; i++) {
        if (input[i] == "(") {
            func = input.slice(0, i).trim()
        }
    }
    return {
        name: func,
        count: sampleCount,
    }
}


function collapse(nodes: Array<SVGNode>): Stack {
    let levelsSet: Set<number> = new Set()
    nodes.forEach((n) => {
        levelsSet.add(n.y)
    })

    // Partition all of our nodes into a list of list of nodes,
    // where each higher-level list corresponds to a depth in the tree
    var nodesInLevels = splitIntoLevels(nodes).map((levels) => {
        return levels.map((n) => {
            // convert each SVGNode into a _Stack
            return svgNodeToStack(n)
        })
    })

    const rootNode = nodesInLevels[0][0]
    let notChildren = addChildren(rootNode, nodesInLevels[1])

    rootNode.stack.name = "root"

    // Iterate over each depth level, over each node in the level,
    // and find its children.
    for (let level = 1; level < nodesInLevels.length; level++) {
        const nodes = nodesInLevels[level]

        // remainingNodes starts pointing to the nodes in the level we are processing,
        // but we will be pointing it to the notChildren array that we get from the
        // addChildren func, which returns the nodes that were found NOT to be children.
        var remainingNodes = nodesInLevels[level+1]
        for (let nodeIdx = 0; nodeIdx < nodes.length; nodeIdx++) {
            const node = nodes[nodeIdx]
            if (level == nodesInLevels.length-1) {
                // We are at the top (or bottom?) of the tree, no more children
                break
            }
            notChildren = addChildren(node, remainingNodes)
            remainingNodes = notChildren
        }
    }

    return rootNode.stack;
}

function splitIntoLevels(nodes: Array<SVGNode>): Array<Array<SVGNode>> {
    var out: Array<Array<SVGNode>> = new Array();
    let theseNodes = nodes;
    let nextNodes: Array<SVGNode>;
    while (true) {
        [theseNodes, nextNodes] = getNextLevelNodes(theseNodes)
        if (theseNodes.length == 0) {
            out.push(nextNodes)
            break;
        }
        out.push(theseNodes);
        theseNodes = nextNodes
    }
    return out
}

function getNextLevelNodes(nodes: Array<SVGNode>): [Array<SVGNode>, Array<SVGNode>] {
    const thisLevel = nodes[0].y

    const nextLevelStart = nodes.findIndex((n) => {
        return n.y < thisLevel
    })
    if (nextLevelStart == -1) {
        // We ran out of nodes, this is the last level
        return [[], nodes]
    }

    const thisLevelNodes = nodes.slice(0, nextLevelStart);
    const nextLevelNodes = nodes.slice(nextLevelStart)
    return [thisLevelNodes, nextLevelNodes]
}

// A small interface extending SVGNode but including a FG stack,
// so we can add its children in addChildren
interface _Stack extends SVGNode {
    stack: Stack,
}

function svgNodeToStack(node: SVGNode): _Stack {
    return {
        ...node,
        stack: {
            name: node.name,
            value: node.count,
            children: [],
        }
    }
}

function addChildren(node: _Stack, nodes: Array<_Stack>): Array<_Stack> {
    // So, in order to find if a node belongs to us,
    // we have to check their x coordinates - cause by now this function
    // should be receiving only the nodes of the next depth level
    //
    // For this, we check that the potential child's x coordinate
    // is >= this node's x, but also that the child's x
    // is not beyond the current node's x+width (maxX) coords.
    // So basically, that the potential child is visually
    // "stacked" on top of the current node.

    let notChildren: Array<_Stack> = new Array();

    const maxX = parseFloat((node.x + node.width).toFixed(2))

    for (let potentialChild of nodes) {
        const startOfChild = parseFloat(potentialChild.x.toFixed(2))
        node.x = parseFloat(node.x.toFixed(2))
        node.width = parseFloat(node.width.toFixed(2))
        if (startOfChild >= node.x && startOfChild < maxX) {
            node.stack.children.push(potentialChild.stack)
            continue
        }
        notChildren.push(potentialChild);
    }
    return notChildren
}

