/************************************************
 * Copyright (C) 2024 Intel Corporation
 ************************************************/
import { FunctionComponent, useCallback, useState } from 'react'
import { Alert, Modal, CloseButton } from 'react-bootstrap'
import styled from 'styled-components'
import ChatCompletionPanel from './chatCompletionPanel'
import { postChatCompletion } from '../../store/chatCompletion.slice'
import { LoadingState } from '../../models/loadingState'
import { ModelSubType, ModelType } from '../models/modelType'
import OutputPanel from '../components/outputPanel'

const ChatModelPopupContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`
const ViewPortControl = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    //margin-top: 20px;
    border-top: solid 1px;
    border-color: rgb(101, 49, 113);
    //border: 1px solid rgb(101, 49, 113);
    min-height: 15rem;
    margin: 1.6rem 1.5rem;
    background-color: #e8e8e8; //rgb(12, 12, 12, 1);
`

const AlertComponent = styled(Alert)`
    display: flex;
    margin-top: 1rem;
    justify-content: center;
    border-radius: 0;
`
const ButtonTab = styled.button`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgb(44, 44, 44);
    border-radius: 0px;
    border: none;
    color: rgb(224, 224, 224);
    font-family: 'IntelOne Display';
    font-size: 0.8rem;
    font-weight: 500;
    padding: 3px 12px;
    text-transform: capitalize;
    margin-right: 0.5rem;
    &:hover {
        color: rgb(224, 224, 224);
        border: none;
        font-family: 'IntelOne Display';
        font-size: 0.8rem;
        font-weight: 500;
    }
    &:focus {
        color: rgb(224, 224, 224);
        border: none;
        font-family: 'IntelOne Display';
        font-size: 0.8rem;
        font-weight: 500;
    }
    &.active {
        border-bottom: 4px solid #61dafb; //4px solid rgb(84, 255, 46);
        font-weight: 500;
    }
`

export interface IChatModelPanelProps {
    postChatQuery: typeof postChatCompletion
    chatCompletionAnswer: any
    chatCompletionLoading: LoadingState
    chatCompletionError: any
    selectedModelName: string
    selectedModelPublishedBy: string
    showModel: boolean
    setShowModel: any
    isNewModelLoad?: boolean
    setIsNewModelLoad?: any
    modelType: ModelType
    modelSubType: ModelSubType
    isStream: boolean
}

const ChatModelPanel: FunctionComponent<IChatModelPanelProps> = ({
    postChatQuery,
    chatCompletionAnswer,
    chatCompletionLoading,
    chatCompletionError,
    selectedModelName,
    selectedModelPublishedBy,
    showModel,
    setShowModel,
    isNewModelLoad,
    setIsNewModelLoad,
    modelType,
    modelSubType,
    isStream,
}: IChatModelPanelProps) => {
    const [selectedOutputTab, setSelectedOutputTab] = useState<string>('python')
    const [currentPromptMessage, setCurrentPromptMessage] = useState<string>('')
    const [selectedTab, setSelectedTab] = useState<string>('preview')

    const handleOutputButtonTabClick = useCallback(
        (name: string) => {
            setSelectedOutputTab(name)
        },
        [selectedOutputTab]
    )

    return (
        <ChatModelPopupContainer>
            <Modal
                show={showModel}
                onHide={() => setShowModel((show: boolean) => !show)}
                backdrop="static"
                keyboard={true}
                size="xl"
            >
                <Modal.Body>
                    <CloseButton
                        className="p-1"
                        onClick={() => setShowModel((show: boolean) => !show)}
                        style={{
                            borderRadius: 0,
                            fontSize: '1.5rem',
                            marginRight: '0.3rem',
                        }}
                    />
                    <ViewPortControl>
                        <AlertComponent
                            style={{ width: '100%' }}
                            variant="danger"
                            show={chatCompletionError ? true : false}
                        >
                            {`We are experiencing issues retrieving the data. ${chatCompletionError?.message}`}
                        </AlertComponent>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            <div id="scrollUpToId"></div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '50%',
                                    height: '100%',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        padding: '0.5rem',
                                    }}
                                >
                                    <ButtonTab>Preview</ButtonTab>
                                    {/* <ButtonTab>Json</ButtonTab> */}
                                </div>
                                <div id="chatDiv">
                                    <ChatCompletionPanel
                                        postChatQuery={postChatQuery}
                                        chatCompletionAnswer={
                                            chatCompletionAnswer
                                        }
                                        chatCompletionLoading={
                                            chatCompletionLoading
                                        }
                                        chatCompletionError={
                                            chatCompletionError
                                        }
                                        selectedModelName={selectedModelName}
                                        selectedModelPublishedBy={
                                            selectedModelPublishedBy
                                        }
                                        CurrentPromptMessage={
                                            currentPromptMessage
                                        }
                                        setCurrentPromptMessage={
                                            setCurrentPromptMessage
                                        }
                                        isStream={isStream}
                                        modelType={modelType}
                                        modelSubType={modelSubType}
                                        isNewModelLoad={isNewModelLoad}
                                        setIsNewModelLoad={setIsNewModelLoad}
                                    ></ChatCompletionPanel>
                                </div>
                            </div>
                            <div
                                id="seprator"
                                style={{
                                    width: '1px',
                                    margin: '0 1rem',
                                    backgroundColor: 'rgb(101, 49, 113)',
                                }}
                            ></div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '50%',
                                    height: '100%',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        padding: '0.5rem',
                                    }}
                                >
                                    <ButtonTab
                                        className={
                                            selectedOutputTab === 'python'
                                                ? 'active'
                                                : ''
                                        }
                                        onClick={() =>
                                            handleOutputButtonTabClick('python')
                                        }
                                    >
                                        Python
                                    </ButtonTab>
                                    {modelType.length > 0 && modelType[0] === ModelType.Trending && (
                                        <ButtonTab
                                            className={
                                                selectedOutputTab ===
                                                'container'
                                                    ? 'active'
                                                    : ''
                                            }
                                            onClick={() =>
                                                handleOutputButtonTabClick(
                                                    'container'
                                                )
                                            }
                                        >
                                            Container
                                        </ButtonTab>
                                    )}
                                </div>
                                <div id="outputtDiv">
                                    <OutputPanel
                                        chatMessage={
                                            currentPromptMessage
                                                ? currentPromptMessage
                                                : `Ask a question`
                                        }
                                        selectedModelName={selectedModelName}
                                        isDiscoverCodeOutput={true}
                                        selectedOutputTab={selectedOutputTab}
                                        modelType={modelType}
                                        isStream={isStream}
                                    ></OutputPanel>
                                </div>
                            </div>
                        </div>
                    </ViewPortControl>
                </Modal.Body>
            </Modal>
        </ChatModelPopupContainer>
    )
}

export default ChatModelPanel
