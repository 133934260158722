import { API_SERVICE_URL } from '../config/service'

import api from './auth/client'

const serviceConfig = require('../config/service.json')

class DiscountService {
    getDiscounts(code: string) {
        var url = API_SERVICE_URL + serviceConfig.getDiscounts + `?code=${code}`
        return api.get(url)
    }

    acceptDiscounts(code: string) {
        var url = API_SERVICE_URL + serviceConfig.getDiscounts

        return api.post(url, {
            code,
        })
    }
    
    generateDiscount(discountRequest : any ){
        var url = API_SERVICE_URL + serviceConfig.discounter + "/organization/shopping/discount"

        return api.post(url, discountRequest)
    }
}

export default new DiscountService()
