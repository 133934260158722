import { API_SERVICE_URL } from '../config/service'
import { NewOptimizationRequest } from '../models/newOptimizationRequest'

import api from './auth/client'

const serviceConfig = require('../config/service.json')

class NewOptimizationService {
    submitNewOptimization(newOptimizationRequest: NewOptimizationRequest) {
        var url = API_SERVICE_URL + serviceConfig.submitNewOptimizationURL
        return api.post(url, newOptimizationRequest)
    }
}

export default new NewOptimizationService()
