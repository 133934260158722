import { Dispatch, FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { _getDecryptedValueFromStorage } from '../../lib/encryptStorage'
import stringUtils from '../../lib/stringUtils'
import { Hub } from 'aws-amplify'

export interface ILoginCallBackContainerProps {}

const LoginCallBackContainer: FunctionComponent<ILoginCallBackContainerProps> & {
    defaultProps: Partial<ILoginCallBackContainerProps>
} = ({}: ILoginCallBackContainerProps) => {
    const location = useLocation()
    let navigate = useNavigate()

    const callback_url = localStorage.getItem('original_url')
    const publicUrlRouteId = localStorage.getItem('publicUrlRoute')
    let invitationKey = localStorage.getItem('invitationKey')
    Hub.listen('auth', ({ payload: { event, data } }) => {
        //console.log(event)
        switch (event) {
            case 'signIn':
                if (callback_url && callback_url !== '') {
                    navigate(callback_url!)
                } else if (
                    publicUrlRouteId &&
                    publicUrlRouteId?.startsWith('/catalog?uuid=')
                ) {
                    navigate(publicUrlRouteId)
                } else if (
                    !stringUtils.isNullOrUndefinedOrEmpty(invitationKey)
                ) {
                    navigate('/invitation')
                } else {
                    localStorage.setItem('loginRoute', 'catalog')
                    navigate('/catalog')
                }
                break
            case 'signIn_failure':
            case 'cognitoHostedUI_failure':
                console.log('Error', data)
                break
        }
    })

    return <div>Login Successful</div>
}

LoginCallBackContainer.defaultProps = {}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {}
}

const mapStateToProps = (state: any) => {
    return {}
}

type StateToPropsType = ReturnType<typeof mapStateToProps>
type DispatchToPropsType = typeof mapDispatchToProps

export default connect<StateToPropsType, DispatchToPropsType>(
    mapStateToProps,
    mapDispatchToProps
)(LoginCallBackContainer)
