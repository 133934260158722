import { useCallback } from 'react'
import { CreateOrgRequest } from '../../models/createOrgRequest'
import { ICreateOrgContainerProps } from './createOrgContainer'

export function CreateOrgContainerLogic({
    createOrg,
}: ICreateOrgContainerProps) {
    const onCreateOrgSubmit = useCallback(
        async (createOrgRequest: CreateOrgRequest) => {
            createOrg(createOrgRequest)
        },
        []
    )

    return {
        onCreateOrgSubmit,
    }
}
