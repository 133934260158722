import { useEffect } from 'react'
import { IAllSupportReqContainerProps } from './allSupportReqContainer'
import { useAppDispatch } from '../../config/hooks'

export function AllSupportReqContainerLogic({
    getAllSupportRequest,
    appName,
    isIntelEmployee,
}: IAllSupportReqContainerProps) {
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (isIntelEmployee) {
            dispatch(getAllSupportRequest())
        }
    }, [])

    return {
        appName,
    }
}
