import {
    FormEvent,
    FunctionComponent,
    useEffect,
    useRef,
    useState,
} from 'react'
import {
    Breadcrumb,
    Button,
    FormLabel,
    Table,
    FormControl,
    Dropdown,
    Carousel,
    Spinner,
    Modal,
} from 'react-bootstrap'
import styled from 'styled-components'
import { _getDecryptedValueFromStorage } from '../../lib/encryptStorage'
import { LoadingState } from '../../models/loadingState'
import { OptimizationDetailsLogic } from './optimizationDetails.hook'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { useLocation } from 'react-router-dom'
import SalesHeaderPanel from './salesHeader'
import SalesFiltersPanel from './salesFilters'
import NoResultFoundPanel from './noResultFound'
import { AuthPermissions } from '../../models/authPermissions'

const OptimizationDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
`
const OptimizationDetailsWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    flex-wrap: no-wrap;

    background: #ffffff;
`

const BreadcrumbItem = styled(Breadcrumb.Item)`
    display: flex;
    font-family: 'IntelOne Display';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    align-items: center;
    color: #0054ae;
`
const OptimizationResultComponent = styled.div`
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    margin-top: -1rem;
    justify-content: center;
    /* White/WHITE */
    background: #ffffff;
    /* table outlines - light/table-outline-plain/full */
    box-shadow: inset 0px 1px 0px rgba(43, 44, 48, 0.12),
        inset -1px 0px 0px rgba(43, 44, 48, 0.12),
        inset 1px 0px 0px rgba(43, 44, 48, 0.12),
        inset 0px -1px 0px rgba(43, 44, 48, 0.12);
    width: 100%;
    height: 275px;
`

const WorkloadLogoComponent = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    margin-top: 2rem;
    gap: 20px;

    width: 200px;
    height: 200px;
    background: #ffffff;
    /* table outlines - light/table-outline-plain/full */

    box-shadow: inset 0px 1px 0px rgba(43, 44, 48, 0.12),
        inset -1px 0px 0px rgba(43, 44, 48, 0.12),
        inset 1px 0px 0px rgba(43, 44, 48, 0.12),
        inset 0px -1px 0px rgba(43, 44, 48, 0.12);
`
const OptimizationCardColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 50%;
    padding-left: 2rem;
    margin-bottom: 2.5rem;
`
const OptimizationShareColumn = styled.div`
    display: flex;
    flex-direction: column;
`
const OptimizationShareRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex end;
    padding-left: 2rem;
    margin-top: 2rem;
`
const PriorityComponent = styled.div`
    display: flex;
    background: rgba(0, 84, 174, 0.15);
    border-radius: 5px;
    width: 60px;
    height: 24px;
    margin-left: 4rem;
    margin-top: 3rem;
`
const PriorityLabel = styled(FormLabel)`
    display: flex;
    padding: 0.6rem 0.5rem;
    align-items: center;
    font-family: 'IntelOne Display';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;

    /* Badge/Fill/Light/Primary */
    color: #0054ae;
    margin-top: 0.15rem;
`
const OptimizationTagComponent = styled.div`
    display: flex;
`
const CardNameComponent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 0.5rem;
    padding: 0.5rem 0rem;
    font-family: 'IntelOne Display';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 40px;
    color: #0054ae;
`
const CardDescriptionComponent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 0.5rem;
    padding: 0.5rem 0rem;
    font-family: 'IntelOne Display';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #6b6e76;
`
const TabsContainer = styled.div`
    flex-direction: center;
    width: 100%;
`
const OptimizationTabComponent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: #fff;
    width: 100%;
`
const TabsComponent = styled(Tabs)`
    .nav-link.active {
        //border-top: 4px solid #00c7fd !important;
        color: var(--text-default, #2b2c30) !important;
        background: var(--theme-light-gray-300, #e2e2e4) !important;
    }
`
const StyledTab = styled(Tab)`
    display: table-cell;
    color: var(--text-placeholder, #6a6d75);
`
const StyledTabLabel = styled.div`
    width: 120px;
    height: 50px;
    padding: 0.5rem;
    margin: 0 0.1rem;
    text-align: center;
    font-family: Intel Clear;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 112.5% */
    border-radius: 6px 6px 0px 0px;
    box-shadow: 1px 0px 0px 0px rgba(43, 44, 48, 0.3) inset,
        -1px 0px 0px 0px rgba(43, 44, 48, 0.3) inset,
        0px 1px 0px 0px rgba(43, 44, 48, 0.3) inset;
`
const OptimizationListingTabContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 0 3rem;
    margin-top: 4rem;
    margin-bottom: 5rem;
    width: 100%;
`
const ResourcesTabContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: #f4f5f5;
    width: 1200px;
    padding: 0.5rem;
`
const ResourcesInnerDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 8%;
    padding: 2rem 0;
`
const EcosystemTabContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #f4f5f5;
    padding: 3rem;
    margin-bottom: 2rem;
    width: 1200px;
`
const ResourcesTextLabel = styled(FormLabel)`
    font-family: 'IntelOne Display';
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 45px;
    display: flex;
    align-items: center;
    padding-bottom: 1rem;
    padding-left: 2.5rem;
    color: #0054ae;
`
const ListingTabHeaderDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: left;
    padding: 3rem 0 1.5rem 0;
`
const ListingTabHeader = styled(FormLabel)`
    font-family: 'IntelOne Display';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    /* identical to box height, or 125% */

    display: flex;
    align-items: left;

    /* Global Header/Classic Blue/Light/Fill */

    color: #0054ae;
`
const CollateralTextLabel = styled(FormLabel)`
    font-weight: 700;
    font-size: 16px;
    color: #2b2c30;
    padding: 1rem;
    padding-left: 2.5rem;
`
const ResourcesLinksContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 2rem;
`
const ResourcesLinksText = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-family: 'IntelOne Display';
    font-weight: 400;
    font-size: 0.8rem;
    padding-left: 0.9rem;
    ${(props) => props.theme.typography.medium}
    justify-content: left;
    margin-bottom: 1rem;
    padding-left: 1rem;
`
const ResourcesLinksTextValue = styled.span`
    padding-right: 0.5rem;
    overflow-wrap: break-word;
    word-break: break-word;
    max-width: 30rem;
    padding-left: 0.5rem;
`
const ShareButton = styled(Button)`
    background-color: transparent;
    cursor: pointer;
    color: black;
    border-radius: 0;
    border: none;
    content: unset !important;
    &:hover {
        background-color: transparent;
        border: 0px none transparent;
    }
    &:focus {
        outline: none;
        /*box-shadow: none;*/
        background-color: transparent;
        border: 0px none transparent;
    }
`
const BookmarkButton = styled(ShareButton)`
    cursor: pointer;
    padding-left: 1.5rem;
    &:hover {
        background-color: transparent;
        border: 0px none transparent;
    }
    &:focus {
        outline: none;
        /*box-shadow: none;*/
        background-color: transparent;
        border: 0px none transparent;
    }
`
const MoreInfoButton = styled(Button)`
    display: flex;
    align-items: center;
    background-color: transparent;
    font-family: 'IntelOne Display';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #2b2c30;
    border: none;
    margin-left: 1rem;
    &:hover {
        background-color: transparent;
        border: 0px none transparent;
        color: #2b2c30;
    }
    &:focus {
        outline: none;
        /*box-shadow: none;*/
        background-color: transparent;
        border: 0px none transparent;
        color: #2b2c30;
    }
    &:disabled {
        background-color: unset;
    }
`
const PerformanceLabel = styled(FormLabel)`
    display: flex;
    align-items: center;
    text-align: center;
    font-family: 'IntelOne Display';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    color: #0054ae;
`
const ParameterLabel = styled.div`
    display: flex;
    font-family: 'IntelOne Display';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #0054ae;
    padding-top: 0.65rem;
    padding-left: 0.5rem;
`

const VSLabel = styled.div`
    display: flex;
    font-family: 'IntelOne Display';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    color: #0054ae;
    align-items: center;
    text-align: center;
`
const OptimizationMetaDataContainer = styled.div`
    display: flex;
    justify-content: center;
    background: #fff;
`

const OptimizationTableHeader = styled.th.attrs({
    colSpan: 7,
})`
    padding: 0.5rem;
    width: 10rem;
    font-family: 'IntelOne Display';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #fff;
    justify-content: center;
    text-align: center;
    border-color: #653171;
`
const OptimizationTableRow = styled.tr`
    //border-style: none;
    //background: #fff;
    //width: 50rem;
`
const OptimizationTableCol2 = styled.td.attrs({
    colSpan: 2,
    verticalAlign: 'middle',
})`
    //border-style: none;
    //background: #fff;
`

const OptimizationTableCol = styled.td`
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    text-align: center;
`

const MoreInfoDataCol = styled.td`
    justify-content: center;
    align-items: center;
    text-align: center;
`
const PPTDataContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 1rem;
    padding: 1rem 1.25rem;
    width: 30rem;
    border: 1.5px solid var(--theme-light-gray-300, #e2e2e4);
    background: #fff;
`
const MoreInfoDataContainer = styled.div`
    display: flex;
    flex-direction: column;
    //justify-content: center;
    align-items: flex-start;
    background-color: #fff;
    margin: 1rem;
    padding: 1.875rem 0.5rem;
`

const MoreInfoSCContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: #fff;
    margin: 1rem;
    padding: 1.875rem 0;
    border-radius: 2px;
    border: 0.5px solid var(--theme-light-gray-300, #e2e2e4);
    background: var(--white-white, #fff);
`

const MoreInfoDatalabel = styled(FormLabel)`
    justify-content: left;
    font-family: 'IntelOne Display';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height, or 150% */

    display: flex;
    align-items: center;

    /* Text/Light/Default */

    color: #2b2c30;
`
const SoftwareCofigDataCell = styled.td`
    justify-content: left;
    font-family: 'IntelOne Display';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    align-items: center;
    width: 18rem;
    margin: 0.01rem;
    //background-color: #e9eaeb;
    padding: 0.2rem 1rem;
    border-right: 1px solid #fff;
    background: var(--black-and-white-white, #fff);
    /* table outlines - light/table-outline-plain/bottom */
    box-shadow: 0px -1px 0px 0px rgba(43, 44, 48, 0.12) inset;
`

const MoreInfoDatalabelValue = styled(MoreInfoDatalabel)`
    justify-content: left;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    padding-bottom: 1rem;
`

const MoreInfoSClabelValue = styled(MoreInfoDatalabel)`
    justify-content: left;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    padding-bottom: 1rem;
    align-items: unset;
`
const SKUTableCell = styled.td`
    border: 1px solid #b9b9b9;
    font-family: 'IntelOne Display';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    align-items: center;
    color: #fff;
`
const EcoSystemHeaderDiv = styled.div`
    font-family: 'IntelOne Display';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    /* identical to box height, or 125% */

    display: flex;
    align-items: center;

    /* Global Header/Classic Blue/Light/Fill */

    color: #0054ae;
    padding: 1rem 1rem 0 1rem;
}
`
const EcoSystemSubHeader = styled(EcoSystemHeaderDiv)`
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    /* identical to box height */

    display: flex;
    align-items: center;

    /* Text/Light/Default */

    color: #2b2c30;
    padding: 1rem 1rem 2rem 1rem;
`
const StatusLabels = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`
const OptimizationStatusComponent = styled.div`
    display: flex;
    justify-content: center;
    background: rgba(0, 84, 174, 0.15);
    border-radius: 5px;
    height: 24px;
    margin: 0.1rem 0.5rem;
`
const OptimizationStatusLabel = styled(FormLabel)`
    display: flex;
    padding: 0.6rem 0.5rem;
    align-items: center;
    font-family: 'IntelOne Display';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0em;
    /* Badge/Fill/Light/Primary */
    color: #0054ae;
    margin-top: 0.2rem;
`

const SubscribeButton = styled.div`
    width: 100%;
    background: #0054ae;
    color: white;
    // margin-top: 3rem;
    display: inline-flex;
    height: 2.5rem;
    justify-content: center;
    align-items: center;
    // flex-shrink: 0;
`
const Header = styled.p`
    color: #0054ae;
    font-size: 1.25rem;
    font-family: IntelOne Display;
    line-height: 1.3125rem;
`
const SubHeader = styled.p`
    color: #2b2c30;
    font-size: 1rem;
    font-family: IntelOne Display;
    font-weight: 500;
    line-height: 1.3125rem;
`
const CSPBrandComponent = styled.div`
    display: flex;
    vertical-align: middle;
    margin-top: -0.3rem;
    padding-left: 1rem;
`
const PerfResultsDiv = styled.div`
    background: #005374;
    width: 35rem;
    margin: 1rem;
    padding: 1rem;
`
const CPUInstanceHeader = styled.th`
    background-color: #183544;
    font-family: Intel Clear;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #fff;
    height: 1.68rem;
    vertical-align: middle;
    padding-left: 0.2rem;
`
const CPUInstanceCell = styled.td`
    font-family: 'IntelOne Display';
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    border-right: 1px solid #183544;
    padding-left: 0.2rem;
`
const TechnicalCollateralsDiv = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 400px;
    min-width: 300px;
    max-width: 590px;
    padding: 30px 20px;
    align-items: flex-start;
    gap: 30px;
    background: var(--black-and-white-white, #fff);
    margin: 1rem;
    color: var(--carbon-carbon-shade-2, #262626);
    font-family: IntelOne Display;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
`
const RecipeDataHeading = styled(FormLabel)`
    color: #fff;
    font-size: 1rem;
    font-family: IntelOne Display;
    line-height: 1.3125rem;
    font-weight: 500;
`
const RecipeDataLink = styled.a`
    font-size: 14px;
    font-family: IntelOne Display;
    line-height: 20px;
    font-weight: 400;
    padding-bottom: 1rem;
    width: fit-content;
`
const ResourcesLink = styled.a`
    font-size: 14px;
    font-family: 'IntelOne Display';
    font-weight: 400;
    line-height: 20px;
`
const SearchTextBoxComponent = styled(FormControl)`
    display: flex;
    background: #ffffff;
    border: 1px solid #8b8e97;
    font-size: 1.1rem !important;
    font-weight: 300;
    font-family: 'IntelOne Display';
    font-style: italic;
    color: #00377c;
    height: 30px;
    width: 300px;
    border-radius: 0px;
    margin-top: 0.3rem;
`
const ListingWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;
    width: 78rem;
`
const ConfigTableHeader = styled.div`
    width: 33rem;
    background: var(--theme-light-gray-200, #e9eaeb);
    box-shadow: 0px -1px 0px 0px rgba(43, 44, 48, 0.12) inset;
`
const ConfigHeader = styled(FormLabel)`
    margin: 0.3rem 0.5rem;
    //color: #0054ae;
    font-size: 1rem;
    font-family: 'IntelOne Display';
    font-weight: 500;
`
const UseCaseDetailsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    background: #fff;
    box-shadow: 0px -1px 0px 0px rgba(43, 44, 48, 0.2) inset,
        0px 4px 0px 0px #00b1fd inset;
    padding: 0.6rem 1rem;
    color: var(--gray-800, #494b51);
    font-family: 'IntelOne Display';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
`
const SpinnerDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5rem;
    width: 100%;
`
const FooterCloseButtonDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: right;
`

export interface IOptimizationDetailsProps {
    getWorkloadOptimizations: (workloadId: string) => void
    getWorkloadOptimizationsLoading: LoadingState
    getWorkloadOptimizationsError: any
    getWorkloadOptimizationsResult: any
    workloadId: string
    SearchComponentRef?: any
    downloadLinkRef?: any
    optimizationGroupName?: string
    authPermissionsResult: AuthPermissions
}

const OptimizationDetails: FunctionComponent<IOptimizationDetailsProps> = ({
    getWorkloadOptimizations,
    getWorkloadOptimizationsLoading,
    getWorkloadOptimizationsError,
    getWorkloadOptimizationsResult,
    workloadId,
    authPermissionsResult,
}: IOptimizationDetailsProps) => {
    const SearchComponentRef = useRef()
    const downloadLinkRef = useRef(null)
    const [activeKey, setActiveKey] = useState('Performance Benefit')
    const location = useLocation()

    const queryParameters = new URLSearchParams(location.search)
    const optimizationGroupName = queryParameters.get('benchmarkGroup')
    const optimizationGroupTypeName = 'Baremetal Benchmarks'
    const workloadName = queryParameters.get('workload')
    const backNavigation = queryParameters.get('nav')
    const optimizationGroup = optimizationGroupName
        ? optimizationGroupName?.replace('Benchmarks', '')
        : 'GCP'

    const {
        onShareLinkClick,
        showBenchmarkMetaData,
        onShowBenchmarkMetaDataClick,
        rowId,
        attachmentFilenames,
        fileDownloadUrl,
        downloadCompleted,
        downloadPPTPerfSlide,
        onAccessApplyChange,
        onHeaderSearchKeyChange,
        onHeaderSearchKeydown,
        onHeaderFilterClick,
        onHeaderFilterClearAllClick,
        showThumbnailPopup,
        ShowPPTThumbnailPopup,
        ClosePPTThumbnailPopup,
        attachedFileURL,
    } = OptimizationDetailsLogic({
        SearchComponentRef,
        downloadLinkRef,
        workloadId,
        optimizationGroupName,
    } as IOptimizationDetailsProps)

    let isDataValidator = false
    if (
        authPermissionsResult &&
        authPermissionsResult?.featureFlags &&
        authPermissionsResult?.featureFlags?.includes('isDataValidator')
    ) {
        isDataValidator = true
    }

    const shareLinkURL = `${window.location.href}`
    const optimizationsList = getWorkloadOptimizationsResult?.optimizations
        ? [...getWorkloadOptimizationsResult?.optimizations]
        : undefined

    const workloadTags = getWorkloadOptimizationsResult?.workloadTags
        ? getWorkloadOptimizationsResult?.workloadTags
        : undefined

    const workloadResources = getWorkloadOptimizationsResult?.workloadResources
        ? getWorkloadOptimizationsResult?.workloadResources
        : undefined

    const iconURL = getWorkloadOptimizationsResult?.iconURL
        ? getWorkloadOptimizationsResult?.iconURL
        : ''
    let isBenchmark = false
    let isCloudBenchmark = false
    let isSWOpt = false
    let isPartnerSol = false
    let isCustomerStudy = false

    if (
        optimizationGroupTypeName &&
        optimizationGroupTypeName.toUpperCase().indexOf('ON-PREM') > -1
    )
        isBenchmark = true
    else if (
        optimizationGroupTypeName &&
        optimizationGroupTypeName.toUpperCase().indexOf('CLOUD') > -1
    ) {
        isCloudBenchmark = true
    } else if (
        optimizationGroupTypeName &&
        optimizationGroupTypeName.toUpperCase().indexOf('SW OPTIMIZATION') > -1
    )
        isSWOpt = true
    else if (
        optimizationGroupTypeName &&
        optimizationGroupTypeName.toUpperCase().indexOf('PARTNER SOLUTION') > -1
    )
        isPartnerSol = true
    else isCustomerStudy = true

    const cardLogo = (
        <img
            src={iconURL}
            alt="product logo"
            style={{
                maxHeight: '200px',
                maxWidth: '150px',
            }}
        />
    )
    const dataServiceImg = (
        <img
            src="/database_icon.png"
            alt="product category logo"
            style={{ paddingLeft: '0.3rem' }}
        />
    )
    const LinkImageRender = (
        <span
            style={{
                paddingLeft: '0.2rem',
            }}
        >
            <img
                src="/links.png"
                height="15px"
                width="15px"
                alt="link logo"
                style={{
                    paddingBottom: '0.1rem',
                }}
            />
        </span>
    )

    const benchmarkTag = (
        <img
            src="/sales_img/tags-benchmark.png"
            style={{ paddingLeft: '0.2rem', height: '2rem' }}
            alt="product tag logo"
        />
    )

    const onPremTag = (
        <img src="/sales_img/tag-onprem.png" alt="product tag logo" />
    )
    const cloudTag = (
        <img src="/sales_img/tag-cloud.png" alt="product tag logo" />
    )
    const swOptimizationTag = (
        <img src="/sales_img/tag-sw-optimizations.png" alt="product tag logo" />
    )
    const partnerSolutionTag = (
        <img
            src="/sales_img/tag-partner-solutions.png"
            alt="product tag logo"
        />
    )
    const custSuccessTag = (
        <img
            src="/sales_img/tag-customer-success-stories.png"
            alt="product tag logo"
        />
    )

    const amdProcessor = (
        <img
            src="/sales_img/EPYC-processor-white-1260x709 1.png"
            alt="product tag logo"
            style={{ paddingLeft: '0.5rem' }}
        />
    )

    const Intel4thGenXeonProcessor = (
        <img
            src="/sales_img/xeon-processor-badge-rgb-3000 1.png"
            alt="product processor logo"
        />
    )
    const Intel3rdGenXeonProcessor = (
        <img
            src="/sales_img/3rdGenBrandLogo.png"
            alt="product processor logo"
        />
    )
    const Intel2ndGenXeonProcessor = (
        <img
            src="/sales_img/2ndGenBrandLogo.png"
            alt="product processor logo"
        />
    )
    const Intel6thGenXeonProcessor = (
        <img
            src="/sales_img/6thGenBrandLogo.png"
            alt="product processor logo"
        />
    )
    const gcpBrandImage = (
        <img
            src="/gcp.png"
            alt="product tag logo"
            style={{
                width: '45px',
                height: '45px',
            }}
        />
    )
    const azureBrandImage = (
        <img
            src="/azure.png"
            alt="product tag logo"
            style={{
                width: '45px',
                height: '45px',
            }}
        />
    )
    const awsBrandImage = (
        <img
            src="/aws.png"
            alt="product tag logo"
            style={{
                width: '45px',
                height: '45px',
            }}
        />
    )
    const alibabaImage = (
        <img
            src="/alibaba.png"
            alt="product tag logo"
            style={{
                width: '45px',
                height: '45px',
            }}
        />
    )
    const PPTAssociatedLinksArray = [
        { name: 'Performance Package', url: '#' },
        { name: 'Optimization Brief', url: '#' },
    ]
    const PPTDisclaimerRender = (optimizationId: string) => (
        <a
            href="javascript:void(0)"
            id={'disclaimerLinkId-' + optimizationId}
            onClick={() => ShowPPTThumbnailPopup('/sales_img/disclaimer.png')}
        >
            <img
                src="/sales_img/disclaimer.png"
                height="100%"
                width="100%"
                alt="PPT disclaimer Performance frame"
            />
        </a>
    )
    const PPTFrameRender = (url: string, optimizationId: string) => (
        <a
            href="javascript:void(0)"
            id={'downloadLinkId-' + optimizationId}
            onClick={() => ShowPPTThumbnailPopup(url)}
        >
            <img
                src={url}
                height="100%"
                width="100%"
                alt="PPT PNG Performance frame"
            />
        </a>
    )
    const PPTSlideRender = (optimizationId: string) => {
        const attachedfileURL = localStorage.getItem(attachmentFilenames)
        return (
            <div>
                {attachedfileURL
                    ? PPTFrameRender(attachedfileURL, optimizationId)
                    : ''}
            </div>
        )
    }
    const renderBenchmarkCSPImage = (cspName: string) => {
        return (
            <CSPBrandComponent
                style={
                    cspName.toUpperCase().indexOf('GCP') > -1
                        ? { marginTop: '-0.6rem' }
                        : { marginTop: '-0.3rem' }
                }
            >
                {cspName.toUpperCase().indexOf('GCP') > -1
                    ? gcpBrandImage
                    : cspName.toUpperCase().indexOf('AWS') > -1
                    ? awsBrandImage
                    : cspName.toUpperCase().indexOf('AZURE') > -1
                    ? azureBrandImage
                    : cspName.toUpperCase().indexOf('ALIBABA') > -1
                    ? alibabaImage
                    : ''}
            </CSPBrandComponent>
        )
    }

    const renderStatusLegandLabel = (
        label: string,
        FontColor: string,
        background: string
    ) => {
        return (
            <OptimizationStatusComponent
                style={background ? { background: `${background}` } : {}}
            >
                <OptimizationStatusLabel
                    style={FontColor ? { color: `${FontColor}` } : {}}
                >
                    {label}
                </OptimizationStatusLabel>
            </OptimizationStatusComponent>
        )
    }

    const renderOptimizationCardTag = (tagName: string) => {
        return <div>{swOptimizationTag}</div>
    }

    const renderComparisonPlatformRef = (optimizationMetadata: any) => {
        let highLevelRef = optimizationMetadata['reference platform']
            ? optimizationMetadata['reference platform'][0]
            : ''
        let instanceRef =
            optimizationMetadata['target platform'] &&
            optimizationMetadata['target platform'][0]
                ? 'in ' + optimizationMetadata['target platform'][0]
                : ''
        return (
            <div>
                {highLevelRef.toUpperCase().indexOf('AMD') > -1
                    ? ''
                    : highLevelRef.toLowerCase().indexOf('6th gen intel xeon') >
                      -1
                    ? Intel6thGenXeonProcessor
                    : highLevelRef.toLowerCase().indexOf('4th gen intel xeon') >
                      -1
                    ? Intel4thGenXeonProcessor
                    : Intel4thGenXeonProcessor}
                <ParameterLabel
                    style={{ justifyContent: 'center', fontWeight: '700' }}
                >
                    {highLevelRef}
                </ParameterLabel>
                {optimizationGroupName &&
                optimizationGroupName?.indexOf('Baremetal') === -1 ? (
                    <ParameterLabel
                        style={{
                            fontSize: '0.8rem',
                            fontWeight: '300',
                            justifyContent: 'center',
                        }}
                    >
                        {/* {instanceRef} */}
                    </ParameterLabel>
                ) : (
                    ''
                )}
            </div>
        )
    }

    const renderComparisonPlatformComp = (optimizationMetadata: any) => {
        let highLevelComp = optimizationMetadata['comparison platform']
            ? optimizationMetadata['comparison platform'][0]
            : ''
        let instanceCompRef =
            optimizationMetadata['comparison platform'] &&
            optimizationMetadata['comparison platform'][0]
                ? 'in ' + optimizationMetadata['comparison platform'][0]
                : ''
        return (
            <div>
                {highLevelComp.toUpperCase().indexOf('AMD') > -1
                    ? ''
                    : highLevelComp
                          .toLowerCase()
                          .indexOf('6th gen intel xeon') > -1
                    ? Intel6thGenXeonProcessor
                    : highLevelComp
                          .toLowerCase()
                          .indexOf('4th gen intel xeon') > -1
                    ? Intel4thGenXeonProcessor
                    : highLevelComp
                          .toLowerCase()
                          .indexOf('2nd gen intel xeon') > -1
                    ? Intel2ndGenXeonProcessor
                    : Intel4thGenXeonProcessor}
                <ParameterLabel
                    style={{ justifyContent: 'center', fontWeight: '700' }}
                >
                    {' '}
                    {highLevelComp}
                </ParameterLabel>
                {optimizationGroupName &&
                optimizationGroupName?.indexOf('Baremetal') === -1 ? (
                    <ParameterLabel
                        style={{
                            fontSize: '0.8rem',
                            fontWeight: '300',
                            justifyContent: 'center',
                        }}
                    >
                        {/* {instanceCompRef} */}
                    </ParameterLabel>
                ) : (
                    ''
                )}
            </div>
        )
    }
    const ExtractSystemConfiguration = (configKey: string, data: any) => {
        let result =
            data &&
            data.length > 0 &&
            data.filter(
                (item: string) =>
                    item &&
                    item.indexOf(':') > -1 &&
                    configKey === item.split(':')[0].valueOf()
            )
        return result && result.length > 0 && result[0].indexOf(':') > -1
            ? result[0].split(':')[1]
            : ''
    }

    const renderMoreInfoComponent = (
        optimizationData: any,
        workloadTags: any
    ) => {
        if (!optimizationData) return

        return (
            <div style={{ display: 'flex' }}>
                <div>
                    {downloadCompleted ? (
                        <PPTDataContainer>
                            <Carousel variant="dark" interval={null}>
                                <Carousel.Item>
                                    {PPTSlideRender(
                                        optimizationData?.benchamarkId
                                    )}
                                </Carousel.Item>
                                <Carousel.Item>
                                    {PPTDisclaimerRender(
                                        optimizationData?.benchamarkId
                                    )}
                                </Carousel.Item>
                            </Carousel>
                            <div
                                style={{
                                    display: 'flex',
                                    paddingTop: '1rem',
                                    marginBottom: '-1rem',
                                }}
                            >
                                {PPTAssociatedLinksArray &&
                                    PPTAssociatedLinksArray.map((link) => (
                                        <RecipeDataLink
                                            href={link.url}
                                            target="_blank"
                                            style={{
                                                color: '#0054AE',
                                                marginRight: '0.8rem',
                                            }}
                                        >
                                            {link.name}
                                            {LinkImageRender}
                                        </RecipeDataLink>
                                    ))}
                            </div>
                        </PPTDataContainer>
                    ) : (
                        ''
                    )}
                    <MoreInfoDataContainer style={{ width: '35rem' }}>
                        <Header>BENEFIT</Header>
                        <ul>
                            {optimizationData?.optimizationMetadata[
                                `business benefits`
                            ] &&
                            optimizationData?.optimizationMetadata[
                                `business benefits`
                            ][0] ? (
                                <li style={{ color: '#0054ae' }}>
                                    <MoreInfoDatalabelValue
                                        style={{ color: '#0054ae' }}
                                    >
                                        {
                                            optimizationData
                                                ?.optimizationMetadata[
                                                `business benefits`
                                            ][0]
                                        }
                                    </MoreInfoDatalabelValue>
                                </li>
                            ) : (
                                ''
                            )}
                            {optimizationData?.optimizationMetadata[
                                `technical benefits`
                            ] ? (
                                <li style={{ color: '#0054ae' }}>
                                    <MoreInfoDatalabelValue
                                        style={{
                                            color: '#0054ae',
                                            width: '20rem',
                                        }}
                                    >
                                        {
                                            optimizationData
                                                ?.optimizationMetadata[
                                                `technical benefits`
                                            ][0]
                                        }
                                    </MoreInfoDatalabelValue>
                                </li>
                            ) : (
                                ''
                            )}
                        </ul>
                        <Header>CHALLENGE</Header>

                        {optimizationData?.optimizationMetadata[`challange `] &&
                        optimizationData?.optimizationMetadata[
                            `challange `
                        ][0] ? (
                            <MoreInfoDatalabelValue style={{ color: '#000' }}>
                                {
                                    optimizationData?.optimizationMetadata[
                                        `challange `
                                    ][0]
                                }
                            </MoreInfoDatalabelValue>
                        ) : (
                            ''
                        )}

                        <Header>SOLUTION</Header>

                        {optimizationData?.optimizationMetadata[`solution`] &&
                        optimizationData?.optimizationMetadata[
                            `solution`
                        ][0] ? (
                            <MoreInfoDatalabelValue style={{ color: '#000' }}>
                                {
                                    optimizationData?.optimizationMetadata[
                                        `solution`
                                    ][0]
                                }
                            </MoreInfoDatalabelValue>
                        ) : (
                            ''
                        )}

                        <div>
                            <table>
                                <tbody>
                                    <tr>
                                        {workloadTags[`marketing owner`] ? (
                                            <td>
                                                <SubHeader>
                                                    Marketing Owner
                                                </SubHeader>
                                                <MoreInfoDatalabelValue
                                                    style={{
                                                        width: '15rem',
                                                        color: '#0054AE',
                                                    }}
                                                >
                                                    {
                                                        workloadTags[
                                                            `marketing owner`
                                                        ][0]
                                                    }
                                                </MoreInfoDatalabelValue>
                                            </td>
                                        ) : (
                                            ''
                                        )}
                                        {optimizationData?.optimizationMetadata[
                                            `technical owner`
                                        ] &&
                                        optimizationData?.optimizationMetadata[
                                            `technical owner`
                                        ].length > 0 ? (
                                            <td>
                                                <SubHeader>
                                                    Technical Owner
                                                </SubHeader>
                                                <MoreInfoDatalabelValue
                                                    style={{
                                                        width: '15rem',
                                                        color: '#0054AE',
                                                    }}
                                                >
                                                    {
                                                        optimizationData
                                                            ?.optimizationMetadata[
                                                            `technical owner`
                                                        ][0]
                                                    }
                                                </MoreInfoDatalabelValue>
                                            </td>
                                        ) : (
                                            ''
                                        )}
                                    </tr>
                                    <tr>
                                        <td>
                                            <SubHeader>Last Updated</SubHeader>
                                            <MoreInfoDatalabelValue
                                                style={{
                                                    width: '15rem',
                                                    color: '#0054AE',
                                                }}
                                            >
                                                {optimizationData
                                                    ?.optimizationMetadata[
                                                    'last updated'
                                                ]
                                                    ? optimizationData
                                                          ?.optimizationMetadata[
                                                          'last updated'
                                                      ][0]
                                                    : ''}{' '}
                                            </MoreInfoDatalabelValue>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </MoreInfoDataContainer>
                </div>

                <div>
                    <PerfResultsDiv style={{ background: '#653171' }}>
                        <Header style={{ color: '#fff' }}>RESULTS </Header>
                        <div style={{ display: 'flex' }}>
                            <PerformanceLabel style={{ color: '#6DDCFF' }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        {optimizationData?.performanceNumbers
                                            ? Object.keys(
                                                  optimizationData?.performanceNumbers
                                              ).map((item: any) => {
                                                  if (item)
                                                      return (
                                                          <div
                                                              style={{
                                                                  display:
                                                                      'flex',
                                                                  flexDirection:
                                                                      'row',
                                                              }}
                                                          >
                                                              <ParameterLabel
                                                                  style={{
                                                                      color: '#EEC3F7',
                                                                      paddingLeft:
                                                                          '0',
                                                                      fontSize:
                                                                          '1.5rem',
                                                                      fontWeight:
                                                                          '700',
                                                                  }}
                                                              >
                                                                  {
                                                                      optimizationData
                                                                          ?.performanceNumbers[
                                                                          item
                                                                      ]
                                                                  }
                                                              </ParameterLabel>
                                                              <ParameterLabel
                                                                  style={{
                                                                      color: '#EEC3F7',
                                                                  }}
                                                              >
                                                                  {item}
                                                              </ParameterLabel>
                                                          </div>
                                                      )
                                              })
                                            : ''}
                                    </div>
                                </div>
                            </PerformanceLabel>
                            <ParameterLabel
                                style={
                                    isBenchmark
                                        ? {
                                              color: '#6DDCFF',
                                              marginTop: '0.8rem',
                                              marginLeft: '0.5rem',
                                          }
                                        : isCloudBenchmark
                                        ? {
                                              color: '#6DDCFF',
                                              marginTop: '0.8rem',
                                              marginLeft: '0.5rem',
                                          }
                                        : {
                                              color: '#6DDCFF',
                                              marginTop: '0.8rem',
                                              marginLeft: '0.5rem',
                                          }
                                }
                            >
                                {optimizationData?.metricName}
                            </ParameterLabel>
                        </div>
                    </PerfResultsDiv>
                    {/* <RecipeDataContainer>
                        <Header
                            style={{
                                color: '#0054AE',
                                fontSize: '1.25rem',
                                fontWeight: '500',
                            }}
                        >
                            INTEL OPTIMIZED RECIPES
                        </Header>

                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            {optimizationData.optimizationMetadata[
                                'Intel Recipe Links'
                            ]
                                ? optimizationData.optimizationMetadata[
                                      'Intel Recipe Links'
                                  ].map((item: string) => {
                                      let [key, ...value] = item.split(':')
                                      let valueStr = value.join(':')
                                      return (
                                          <div
                                              style={{
                                                  display: 'flex',
                                                  flexDirection: 'column',
                                              }}
                                          >
                                              <RecipeDataHeading
                                                  style={{ color: '#494B51' }}
                                              >
                                                  {key}
                                              </RecipeDataHeading>
                                              {valueStr &&
                                              (valueStr.trim() === 'TBD' ||
                                                  valueStr.trim() === '') ? (
                                                  <div>
                                                      {valueStr}{' '}
                                                      {LinkImageRender}
                                                  </div>
                                              ) : (
                                                  <RecipeDataLink
                                                      href={valueStr}
                                                      target="_blank"
                                                  >
                                                      {`Links`}
                                                      {LinkImageRender}
                                                  </RecipeDataLink>
                                              )}
                                          </div>
                                      )
                                  })
                                : ''}
                        </div>
                    </RecipeDataContainer> */}
                    <Header
                        style={{
                            textTransform: 'uppercase',
                            marginLeft: '1rem',
                            padding: '1rem 0',
                        }}
                    >
                        System Configurations
                    </Header>

                    <MoreInfoSCContainer style={{ width: '35rem' }}>
                        <MoreInfoDatalabelValue>
                            <MoreInfoDataCol
                                style={{ width: '14rem', margin: '0.01rem' }}
                            >
                                {''}
                            </MoreInfoDataCol>
                            <MoreInfoDataCol
                                style={{ width: '13rem', margin: '0.01rem' }}
                            >
                                {optimizationData?.optimizationMetadata
                                    ? renderComparisonPlatformRef(
                                          optimizationData?.optimizationMetadata
                                      )
                                    : ''}
                            </MoreInfoDataCol>
                        </MoreInfoDatalabelValue>

                        <ConfigTableHeader style={{ width: '35rem' }}>
                            <ConfigHeader>Software Configurations</ConfigHeader>
                        </ConfigTableHeader>

                        <MoreInfoSClabelValue>
                            <table>
                                <tbody>
                                    {optimizationData?.optimizationMetadata[
                                        'sw configuration'
                                    ] &&
                                    optimizationData?.optimizationMetadata[
                                        'sw configuration'
                                    ].length > 0
                                        ? optimizationData?.optimizationMetadata[
                                              'sw configuration'
                                          ].map(
                                              (val: string, index: number) => {
                                                  return (
                                                      <tr>
                                                          <SoftwareCofigDataCell
                                                              style={{
                                                                  fontWeight:
                                                                      '700',
                                                              }}
                                                          >
                                                              {val &&
                                                              val.indexOf(':') >
                                                                  -1
                                                                  ? val.split(
                                                                        ':'
                                                                    )[0]
                                                                  : ''}
                                                          </SoftwareCofigDataCell>
                                                          <SoftwareCofigDataCell>
                                                              {val &&
                                                              val.indexOf(':') >
                                                                  -1
                                                                  ? val.split(
                                                                        ':'
                                                                    )[1]
                                                                  : ''}
                                                          </SoftwareCofigDataCell>
                                                      </tr>
                                                  )
                                              }
                                          )
                                        : ''}
                                </tbody>
                            </table>
                        </MoreInfoSClabelValue>

                        <ConfigTableHeader style={{ width: '35rem' }}>
                            <ConfigHeader>Hardware Configurations</ConfigHeader>
                        </ConfigTableHeader>
                        <MoreInfoSClabelValue>
                            <table>
                                <tbody>
                                    {optimizationData?.optimizationMetadata[
                                        'hw configuration'
                                    ] &&
                                    optimizationData?.optimizationMetadata[
                                        'hw configuration'
                                    ].length > 0
                                        ? optimizationData?.optimizationMetadata[
                                              'hw configuration'
                                          ].map(
                                              (val: string, index: number) => {
                                                  return (
                                                      <tr>
                                                          <SoftwareCofigDataCell
                                                              style={{
                                                                  fontWeight:
                                                                      '700',
                                                              }}
                                                          >
                                                              {val &&
                                                              val.indexOf(':') >
                                                                  -1
                                                                  ? val.split(
                                                                        ':'
                                                                    )[0]
                                                                  : ''}
                                                          </SoftwareCofigDataCell>
                                                          <SoftwareCofigDataCell>
                                                              {val &&
                                                              val.indexOf(':') >
                                                                  -1
                                                                  ? val.split(
                                                                        ':'
                                                                    )[1]
                                                                  : ''}
                                                          </SoftwareCofigDataCell>
                                                      </tr>
                                                  )
                                              }
                                          )
                                        : ''}
                                </tbody>
                            </table>
                        </MoreInfoSClabelValue>
                    </MoreInfoSCContainer>
                </div>
            </div>
        )
    }
    const renderoptimizationTable = (
        optimizationsList: any,
        workloadTags: any
    ) => {
        return (
            <Table
                responsive
                style={{ background: '#fff', padding: '5%', width: '78rem' }}
            >
                <thead
                    style={{
                        background: '#653171',
                        border: '1px solid #653171',
                        width: '50rem',
                    }}
                >
                    <OptimizationTableRow>
                        <OptimizationTableHeader
                            style={{
                                background: '#653171',
                                borderColor: '#653171',
                            }}
                        >
                            {'Software Optimization Testing'}
                        </OptimizationTableHeader>
                    </OptimizationTableRow>
                </thead>
                <tbody>
                    {optimizationsList && optimizationsList?.length === 0 ? (
                        <div>{NoResultFoundPanel()}</div>
                    ) : (
                        ''
                    )}

                    {optimizationsList?.map(
                        (optimizationData: any, index: number) => {
                            return (
                                <>
                                    <OptimizationTableRow key={index}>
                                        <OptimizationTableCol2
                                            style={{
                                                verticalAlign: 'middle',
                                                width: '32%',
                                                paddingLeft: '2rem',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                }}
                                            >
                                                <ParameterLabel
                                                    style={{
                                                        color: '#0099EC',
                                                        paddingLeft: '0',
                                                    }}
                                                >
                                                    {optimizationData
                                                        .optimizationMetadata[
                                                        'optimization name'
                                                    ]
                                                        ? optimizationData
                                                              .optimizationMetadata[
                                                              'optimization name'
                                                          ][0]
                                                        : ''}
                                                </ParameterLabel>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                    }}
                                                >
                                                    {optimizationData?.performanceNumbers
                                                        ? Object.keys(
                                                              optimizationData?.performanceNumbers
                                                          ).map((item: any) => {
                                                              if (item)
                                                                  return (
                                                                      <div
                                                                          style={{
                                                                              display:
                                                                                  'flex',
                                                                              flexDirection:
                                                                                  'row',
                                                                          }}
                                                                      >
                                                                          <ParameterLabel
                                                                              style={{
                                                                                  color: '#0054AE',
                                                                                  paddingLeft:
                                                                                      '0',
                                                                                  fontSize:
                                                                                      '1.5rem',
                                                                                  fontWeight:
                                                                                      '700',
                                                                              }}
                                                                          >
                                                                              {
                                                                                  optimizationData
                                                                                      ?.performanceNumbers[
                                                                                      item
                                                                                  ]
                                                                              }
                                                                          </ParameterLabel>
                                                                          <ParameterLabel
                                                                              style={{
                                                                                  color: '#0054AE',
                                                                              }}
                                                                          >
                                                                              {
                                                                                  item
                                                                              }
                                                                          </ParameterLabel>
                                                                      </div>
                                                                  )
                                                          })
                                                        : ''}
                                                </div>
                                            </div>
                                        </OptimizationTableCol2>

                                        <OptimizationTableCol
                                            style={{ width: '16%' }}
                                        >
                                            {optimizationData?.optimizationMetadata
                                                ? renderComparisonPlatformRef(
                                                      optimizationData?.optimizationMetadata
                                                  )
                                                : ''}
                                        </OptimizationTableCol>
                                        <OptimizationTableCol>
                                            <VSLabel>VS.</VSLabel>
                                        </OptimizationTableCol>
                                        <OptimizationTableCol
                                            style={{ width: '16%' }}
                                        >
                                            {optimizationData?.optimizationMetadata
                                                ? renderComparisonPlatformComp(
                                                      optimizationData?.optimizationMetadata
                                                  )
                                                : ''}
                                        </OptimizationTableCol>
                                        <OptimizationTableCol
                                            style={{
                                                width: '10%',
                                            }}
                                        >
                                            <StatusLabels>
                                                {optimizationData?.status ===
                                                'Public'
                                                    ? renderStatusLegandLabel(
                                                          'Public',
                                                          '#fff',
                                                          '#8BAE46'
                                                      )
                                                    : optimizationData?.status ===
                                                      'CNDA'
                                                    ? renderStatusLegandLabel(
                                                          'CNDA',
                                                          '#C81326',
                                                          '#FFB6B9'
                                                      )
                                                    : optimizationData?.status ===
                                                      'NDA'
                                                    ? renderStatusLegandLabel(
                                                          'NDA',
                                                          '#C81326',
                                                          '#FFB6B9'
                                                      )
                                                    : optimizationData?.status ===
                                                      'Internal Only'
                                                    ? renderStatusLegandLabel(
                                                          'Internal Only',
                                                          '#906700',
                                                          '#FFE17A'
                                                      )
                                                    : ''}
                                                {optimizationData?.CompletionStatus ===
                                                'Complete'
                                                    ? renderStatusLegandLabel(
                                                          'Complete',
                                                          '#fff',
                                                          '#0054AE'
                                                      )
                                                    : optimizationData?.CompletionStatus ===
                                                      'WIP'
                                                    ? renderStatusLegandLabel(
                                                          'WIP',
                                                          '#0054AE',
                                                          '#D0DDEA'
                                                      )
                                                    : ''}
                                            </StatusLabels>
                                        </OptimizationTableCol>
                                        <OptimizationTableCol>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent:
                                                        'space-evenly',
                                                }}
                                            >
                                                <ShareButton
                                                    type="button"
                                                    onClick={(e: any) =>
                                                        onShareLinkClick(e)
                                                    }
                                                    value={shareLinkURL}
                                                    title={'Share with others'}
                                                >
                                                    <img
                                                        src="/share_sm.png"
                                                        alt="share link"
                                                        style={{
                                                            height: '18px',
                                                            width: '17px',
                                                            marginRight: '1rem',
                                                        }}
                                                    />
                                                </ShareButton>
                                                <MoreInfoButton
                                                    id={'expand' + index}
                                                    type="button"
                                                    title={'View more'}
                                                    value={
                                                        showBenchmarkMetaData
                                                    }
                                                    onClick={(evt: any) =>
                                                        onShowBenchmarkMetaDataClick(
                                                            evt,
                                                            `expand${index}`,
                                                            optimizationData?.benchamarkId,
                                                            optimizationData?.attachmentFilenames
                                                        )
                                                    }
                                                >
                                                    {showBenchmarkMetaData &&
                                                    rowId ===
                                                        `expand${index}` ? (
                                                        <>
                                                            <img
                                                                src="/sales_img/chevron-up.png"
                                                                alt="down arrow icon"
                                                                style={{
                                                                    height: '18',
                                                                    width: '17',
                                                                    paddingTop:
                                                                        '0.4rem',
                                                                }}
                                                            />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <img
                                                                src="/sales_img/chevron-down.png"
                                                                alt="down arrow icon"
                                                                style={{
                                                                    height: '18',
                                                                    width: '17',
                                                                    paddingTop:
                                                                        '0.4rem',
                                                                }}
                                                            />
                                                        </>
                                                    )}
                                                </MoreInfoButton>
                                            </div>
                                        </OptimizationTableCol>
                                    </OptimizationTableRow>
                                    {showBenchmarkMetaData === true &&
                                    rowId === `expand${index}` ? (
                                        <OptimizationTableRow
                                            style={{
                                                padding: '0px',
                                                borderTopColor: '#00B1FD',
                                                borderTopWidth: '1px',
                                            }}
                                        >
                                            <td
                                                style={{
                                                    padding: '0px',
                                                }}
                                                colSpan={7}
                                            >
                                                <UseCaseDetailsContainer>
                                                    <div>
                                                        Use Case:
                                                        <span
                                                            style={{
                                                                fontWeight:
                                                                    '400',
                                                                padding:
                                                                    '0 0.5rem',
                                                            }}
                                                        >
                                                            {optimizationData
                                                                ?.optimizationMetadata[
                                                                `gmo use cases`
                                                            ]
                                                                ? optimizationData
                                                                      ?.optimizationMetadata[
                                                                      `gmo use cases`
                                                                  ][0]
                                                                : ''}
                                                        </span>
                                                    </div>
                                                    <div>
                                                        Industry:{' '}
                                                        <span
                                                            style={{
                                                                fontWeight:
                                                                    '400',
                                                                padding:
                                                                    '0 0.5rem',
                                                            }}
                                                        >
                                                            {optimizationData
                                                                ?.optimizationMetadata[
                                                                `industry`
                                                            ]
                                                                ? optimizationData
                                                                      ?.optimizationMetadata[
                                                                      `industry`
                                                                  ][0]
                                                                : ''}
                                                        </span>
                                                    </div>
                                                    <div>
                                                        Version:{' '}
                                                        <span
                                                            style={{
                                                                fontWeight:
                                                                    '400',
                                                                padding:
                                                                    '0 0.5rem',
                                                            }}
                                                        >
                                                            {optimizationData
                                                                ?.optimizationMetadata[
                                                                `version`
                                                            ]
                                                                ? optimizationData
                                                                      ?.optimizationMetadata[
                                                                      `version`
                                                                  ][0]
                                                                : ''}
                                                        </span>
                                                    </div>
                                                    <div>
                                                        Feature:{' '}
                                                        <span
                                                            style={{
                                                                fontWeight:
                                                                    '400',
                                                                padding:
                                                                    '0 0.5rem',
                                                            }}
                                                        >
                                                            {optimizationData
                                                                ?.optimizationMetadata[
                                                                `feature`
                                                            ]
                                                                ? optimizationData
                                                                      ?.optimizationMetadata[
                                                                      `feature`
                                                                  ][0]
                                                                : ''}
                                                        </span>
                                                    </div>
                                                </UseCaseDetailsContainer>
                                                <OptimizationMetaDataContainer>
                                                    {renderMoreInfoComponent(
                                                        optimizationData,
                                                        workloadTags
                                                    )}
                                                </OptimizationMetaDataContainer>
                                            </td>
                                        </OptimizationTableRow>
                                    ) : (
                                        ''
                                    )}
                                </>
                            )
                        }
                    )}
                </tbody>
            </Table>
        )
    }
    const hasTechCollat = (): boolean => {
        return (
            workloadTags &&
            ((workloadTags[`on-prem recipes`] &&
                workloadTags[`on-prem recipes`].length > 0) ||
                (workloadTags[`optimized cloud recipes`] &&
                    workloadTags[`optimized cloud recipes`].length > 0) ||
                (workloadTags[`optimized cloud recipes`] &&
                    workloadTags[`optimized cloud recipes`].length > 0) ||
                (workloadTags[`tuning guides`] &&
                    workloadTags[`tuning guides`].length > 0) ||
                (workloadTags[`optimized cloud modules`] &&
                    workloadTags[`optimized cloud modules`].length > 0) ||
                (workloadTags[`proof of concept guide`] &&
                    workloadTags[`proof of concept guide`].length > 0))
        )
    }
    const renderTechnicalCollaterals = (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'wrap',
                justifyContent: 'center',
            }}
        >
            {workloadTags &&
            workloadTags[`on-prem recipes`] &&
            workloadTags[`on-prem recipes`].length > 0 ? (
                <TechnicalCollateralsDiv>
                    ON-PREM RECIPES
                    {workloadTags[`on-prem recipes`].map((item: string) => {
                        let [key, ...value] = item.split(':')
                        let valueStr = value.join(':')
                        return (
                            <RecipeDataLink href={valueStr} target="_blank">
                                {key}
                                {LinkImageRender}
                            </RecipeDataLink>
                        )
                    })}
                </TechnicalCollateralsDiv>
            ) : (
                ''
            )}
            {workloadTags &&
            workloadTags[`optimized containers`] &&
            workloadTags[`optimized containers`].length > 0 ? (
                <TechnicalCollateralsDiv>
                    CONTAINERS
                    {workloadTags[`optimized containers`].map(
                        (item: string) => {
                            let [key, ...value] = item.split(':')
                            let valueStr = value.join(':')
                            return (
                                <RecipeDataLink href={valueStr} target="_blank">
                                    {key}
                                    {LinkImageRender}
                                </RecipeDataLink>
                            )
                        }
                    )}
                </TechnicalCollateralsDiv>
            ) : (
                ''
            )}

            {workloadTags &&
            workloadTags[`optimized cloud recipes`] &&
            workloadTags[`optimized cloud recipes`].length > 0 ? (
                <TechnicalCollateralsDiv>
                    OPTIMIZED CLOUD RECIPES
                    {workloadTags[`optimized cloud recipes`].map(
                        (item: string) => {
                            let [key, ...value] = item.split(':')
                            let valueStr = value.join(':')
                            return (
                                <RecipeDataLink href={valueStr} target="_blank">
                                    {key}
                                    {LinkImageRender}
                                </RecipeDataLink>
                            )
                        }
                    )}
                </TechnicalCollateralsDiv>
            ) : (
                ''
            )}
            {workloadTags &&
            workloadTags[`tuning guides`] &&
            workloadTags[`tuning guides`].length > 0 ? (
                <TechnicalCollateralsDiv>
                    TUNING GUIDES
                    {workloadTags[`tuning guides`].map((item: string) => {
                        let [key, ...value] = item.split(':')
                        let valueStr = value.join(':')
                        return (
                            <RecipeDataLink href={valueStr} target="_blank">
                                {key}
                                {LinkImageRender}
                            </RecipeDataLink>
                        )
                    })}
                </TechnicalCollateralsDiv>
            ) : (
                ''
            )}

            {workloadTags &&
            workloadTags[`optimized cloud modules`] &&
            workloadTags[`optimized cloud modules`].length > 0 ? (
                <TechnicalCollateralsDiv>
                    OPTIMIZED CLOUD MODULES
                    {workloadTags[`optimized cloud modules`].map(
                        (item: string) => {
                            let [key, ...value] = item.split(':')
                            let valueStr = value.join(':')
                            return (
                                <RecipeDataLink href={valueStr} target="_blank">
                                    {key}
                                    {LinkImageRender}
                                </RecipeDataLink>
                            )
                        }
                    )}
                </TechnicalCollateralsDiv>
            ) : (
                ''
            )}
            {workloadTags &&
            workloadTags[`proof of concept guide`] &&
            workloadTags[`proof of concept guide`].length > 0 ? (
                <TechnicalCollateralsDiv>
                    PROOF OF CONCEPT GUIDES
                    {workloadTags[`proof of concept guide`].map(
                        (item: string) => {
                            let [key, ...value] = item.split(':')
                            let valueStr = value.join(':')
                            return (
                                <RecipeDataLink href={valueStr} target="_blank">
                                    {key}
                                    {LinkImageRender}
                                </RecipeDataLink>
                            )
                        }
                    )}
                </TechnicalCollateralsDiv>
            ) : (
                ''
            )}
        </div>
    )
    const renderLinkComponent =
        workloadResources &&
        Object.keys(workloadResources).map((category: any) => {
            {
                const data = workloadResources[category]
                //console.log(data)

                return (
                    <>
                        <div>
                            <CollateralTextLabel>
                                {category}
                            </CollateralTextLabel>
                            {data && data.length > 0
                                ? data.map((link: any) => (
                                      <ResourcesLinksText
                                          style={{ marginLeft: '1rem' }}
                                      >
                                          <ResourcesLinksTextValue>
                                              <ResourcesLink
                                                  href={link.url}
                                                  target={'_blank'}
                                                  rel="noopener"
                                              >
                                                  {link.name}
                                                  {LinkImageRender}
                                              </ResourcesLink>
                                          </ResourcesLinksTextValue>
                                          {link.labels[0] &&
                                          link.labels[0] === 'Public'
                                              ? renderStatusLegandLabel(
                                                    'Public',
                                                    '#fff',
                                                    '#8BAE46'
                                                )
                                              : link.labels[0] === 'CNDA'
                                              ? renderStatusLegandLabel(
                                                    'CNDA',
                                                    '#C81326',
                                                    '#FFB6B9'
                                                )
                                              : link.labels[0] === 'NDA'
                                              ? renderStatusLegandLabel(
                                                    'NDA',
                                                    '#C81326',
                                                    '#FFB6B9'
                                                )
                                              : link.labels[0] ===
                                                'Internal Only'
                                              ? renderStatusLegandLabel(
                                                    'Internal Only',
                                                    '#906700',
                                                    '#FFE17A'
                                                )
                                              : ''}
                                      </ResourcesLinksText>
                                  ))
                                : ''}
                        </div>
                    </>
                )
            }
        })

    return (
        <OptimizationDetailsContainer>
            <SalesHeaderPanel
                onSearchKeyDown={onHeaderSearchKeydown}
                onSearchKeyChange={onHeaderSearchKeyChange}
                onAccessChangeCallback={onAccessApplyChange}
                onApplyFilterCallback={onHeaderFilterClick}
                onClearAllCallback={onHeaderFilterClearAllClick}
            />
            <OptimizationDetailsWrapper>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    <Breadcrumb style={{ padding: '0.5rem 1rem' }}>
                        <BreadcrumbItem
                            href="/catalog"
                            style={{ textDecoration: 'none', color: '#2B2C30' }}
                        >
                            Intel Optimization Hub
                        </BreadcrumbItem>
                        <BreadcrumbItem
                            href="/salesview/home"
                            style={{
                                textDecoration: 'none',
                                color: '#2B2C30',
                            }}
                        >
                            Sales View Home
                        </BreadcrumbItem>
                        {backNavigation === 'workload' ? (
                            <BreadcrumbItem
                                href={`/allWorkloads/${workloadId}`}
                                style={{
                                    textDecoration: 'none',
                                    color: '#2B2C30',
                                }}
                            >
                                {workloadName} *
                            </BreadcrumbItem>
                        ) : (
                            ''
                        )}
                        <BreadcrumbItem
                            active
                            style={{ fontWeight: '500', color: '#0054AE' }}
                        >
                            {getWorkloadOptimizationsResult?.name}
                        </BreadcrumbItem>
                    </Breadcrumb>
                </div>

                <OptimizationResultComponent>
                    <WorkloadLogoComponent>{cardLogo}</WorkloadLogoComponent>
                    <OptimizationCardColumn style={{ maxWidth: '70%' }}>
                        <OptimizationTagComponent>
                            {optimizationGroupTypeName
                                ? renderOptimizationCardTag(
                                      optimizationGroupTypeName
                                  )
                                : ''}
                            {/* {benchmarkTag} */}
                            {optimizationGroupName
                                ? renderBenchmarkCSPImage(optimizationGroupName)
                                : ''}
                        </OptimizationTagComponent>
                        <CardNameComponent>
                            {getWorkloadOptimizationsResult?.name}
                        </CardNameComponent>
                        <CardDescriptionComponent>
                            {getWorkloadOptimizationsResult?.description}
                        </CardDescriptionComponent>
                        <CardDescriptionComponent>
                            <div style={{ display: 'inline' }}>
                                Category:{' '}
                                {workloadTags &&
                                workloadTags['workload categories'] &&
                                workloadTags['workload categories'][0] ===
                                    'Data Services'
                                    ? dataServiceImg
                                    : ''}{' '}
                                <span
                                    style={{
                                        color: '#0054AE',
                                        paddingTop: '0.2rem',
                                    }}
                                >
                                    {workloadTags &&
                                    workloadTags['workload categories'].length >
                                        0
                                        ? workloadTags['workload categories'][0]
                                        : ''}
                                </span>
                            </div>
                        </CardDescriptionComponent>
                    </OptimizationCardColumn>
                    <OptimizationShareColumn>
                        <OptimizationShareRow>
                            <ShareButton
                                type="button"
                                onClick={(e: any) => onShareLinkClick(e)}
                                value={shareLinkURL}
                                title={'Share with others'}
                            >
                                <img
                                    src="/share_lg.png"
                                    alt="share link"
                                    style={{
                                        height: '24px',
                                        width: '22px',
                                        marginLeft: '1rem',
                                    }}
                                />
                            </ShareButton>
                        </OptimizationShareRow>
                    </OptimizationShareColumn>
                </OptimizationResultComponent>
                <OptimizationTabComponent>
                    <TabsContainer>
                        <TabsComponent
                            variant="tabs"
                            id="tabs"
                            defaultActiveKey={activeKey}
                            onSelect={(k: any) => setActiveKey(k)}
                            className="nav-link.active"
                            style={{
                                width: '100%',
                                justifyContent: 'center',
                                paddingTop: '0.5rem',
                                borderBottom: '2px solid #c9cace',
                            }}
                        >
                            <StyledTab
                                eventKey="Performance Benefit"
                                title={
                                    <StyledTabLabel tabIndex={0}>
                                        Performance Benefit
                                    </StyledTabLabel>
                                }
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'flex-start',
                                        width: '100%',
                                        background: '#f4f5f5',
                                    }}
                                >
                                    <OptimizationListingTabContainer>
                                        {getWorkloadOptimizationsLoading !==
                                        LoadingState.Pending ? (
                                            renderoptimizationTable(
                                                optimizationsList,
                                                workloadTags
                                            )
                                        ) : (
                                            <SpinnerDiv>
                                                <Spinner
                                                    animation="border"
                                                    variant="dark"
                                                >
                                                    <span className="visually-hidden">
                                                        Loading...
                                                    </span>
                                                </Spinner>
                                            </SpinnerDiv>
                                        )}
                                    </OptimizationListingTabContainer>
                                </div>
                            </StyledTab>

                            <StyledTab
                                eventKey="Technical Collaterals "
                                title={
                                    <StyledTabLabel tabIndex={0}>
                                        Technical Collateral
                                    </StyledTabLabel>
                                }
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'flex-start',
                                        width: '100%',
                                        background: '#f4f5f5',
                                    }}
                                >
                                    <OptimizationListingTabContainer>
                                        {hasTechCollat() ? (
                                            renderTechnicalCollaterals
                                        ) : (
                                            <div>{NoResultFoundPanel()}</div>
                                        )}
                                    </OptimizationListingTabContainer>
                                </div>
                            </StyledTab>

                            {workloadResources &&
                            Object.keys(workloadResources).length > 0 ? (
                                <StyledTab
                                    eventKey="Resources"
                                    title={
                                        <StyledTabLabel tabIndex={0}>
                                            Resources
                                        </StyledTabLabel>
                                    }
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            width: '100%',
                                            background: '#f4f5f5',
                                        }}
                                    >
                                        <ResourcesTabContainer>
                                            <ResourcesInnerDiv>
                                                <ResourcesTextLabel>
                                                    Resources
                                                </ResourcesTextLabel>

                                                <ResourcesLinksContainer>
                                                    {renderLinkComponent}
                                                </ResourcesLinksContainer>
                                            </ResourcesInnerDiv>
                                        </ResourcesTabContainer>
                                    </div>
                                </StyledTab>
                            ) : (
                                ''
                            )}
                        </TabsComponent>
                    </TabsContainer>
                </OptimizationTabComponent>
            </OptimizationDetailsWrapper>

            <div>
                <a
                    className="hidden"
                    download={attachmentFilenames}
                    href={fileDownloadUrl}
                    id={'downloadLinkId-' + workloadId}
                    ref={downloadLinkRef}
                ></a>
            </div>
            <Modal
                show={showThumbnailPopup}
                onHide={ClosePPTThumbnailPopup}
                backdrop="static"
                keyboard={true}
                size="xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img
                        src={attachedFileURL}
                        height="100%"
                        width="100%"
                        alt="PPT PNG Performance frame"
                    />
                </Modal.Body>
                <Modal.Footer>
                    <FooterCloseButtonDiv>
                        <Button
                            variant="secondary"
                            onClick={ClosePPTThumbnailPopup}
                            style={{ borderRadius: 0 }}
                        >
                            Close
                        </Button>
                    </FooterCloseButtonDiv>
                </Modal.Footer>
            </Modal>
        </OptimizationDetailsContainer>
    )
}

export default OptimizationDetails
