import { FunctionComponent, useRef } from 'react'
import styled from 'styled-components'
import { useAppDispatch } from '../../config/hooks'
import { LoadingState } from '../../models/loadingState'
import {
    Form,
    FormControl,
    FormLabel,
    Table,
    Dropdown,
    DropdownButton,
    Button,
    Alert,
    Col,
    Row,
    Breadcrumb,
    BreadcrumbItem,
    Overlay,
    CloseButton,
    Spinner,
    Modal,
} from 'react-bootstrap'

import Pagination from '../pagination/pagination'
import moment from 'moment'
import StringUtils from '../../lib/stringUtils'
import { KeyboardEvent } from 'react'
import NoResultFoundPanel from '../benchmarkPanel/noResultFound'
import { AllSupportRequestPanelLogic } from './allSupportRequestPanel.hook'

const AllRequestPanelFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
`
const DataContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    border-radius: 0;
    margin: 2rem;
    width: 100%;
`

const VericalContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

const RecipeContainer = styled.div`
    background: #ffffff;
    padding: 0 2rem 2rem 2rem;
    width: 96%;
`

const RecipeTextLabel = styled(FormLabel)`
    display: flex;
    ${(props) => props.theme.typography.medium}
    text-align: left;
    margin-top: 0.5rem;
    white-space: pre-wrap;
`

const RecipeTextLabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`
const RecipeLinklWrapper = styled.div`
    flex-direction: column;
    justify-content: center;
    margin-top: 0.6rem;
`

const RecipeStatusTextLabel = styled(RecipeTextLabel)`
    // width: 6%;
`
const RecipeDateTextLabel = styled(RecipeTextLabel)`
    //width: 10rem;
`
const RecipeNameTextLabel = styled(RecipeTextLabel)`
    //width: 18rem;
`
const RecipeImageLabel = styled(RecipeTextLabel)`
    //width: 6rem;
`

const RecipeTextLabelHeadings = styled(RecipeTextLabel)`
    color: ${(props) => props.theme.color.text.default};
    font-size: 1rem;
`

const AlertComponent = styled(Alert)`
    display: flex;
    margin-top: 1rem;
    width: 35vw;
    justify-content: center;
`
const ModelAlertComponent = styled(AlertComponent)`
    width: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
`

const PaginationComponent = styled.div`
    display: flex;
    flex-grow: 1;
    margin-top: 1rem;
    flex-direction: column;
    justify-content: center;
    width: 96%;
`

const PaginationPanelContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 10rem;
    @media screen and (max-width: 500px) {
        justify-content: center;
        padding-left: 3rem;
        padding-right: 0rem;
    }
`
const PaginationDiv = styled.div`
    @media screen and (max-width: 500px) {
        padding-left: 5rem;
    }
    justify-content: space-between;
`

const ShowingPageContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: left;
    flex-direction: row;
    padding-left: 0.1rem;
    width: 30rem;
    margin-top: 0.2rem;
    @media screen and (max-width: 500px) {
        margin-left: 2rem;
    }
`

const PageCountSelect = styled(Form.Select)`
    width: 5rem;
    height: 1.7rem;
    margin-left: 0.5rem;
`

const TextLabel = styled(FormLabel)`
    display: flex;
    text-align: left;
    font-family: 'IntelOne Display';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
`

const HeaderTitleComponent = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: -1rem;
    justify-content: center;
    padding: 0 3%;
    background-color: rgb(101, 49, 113);
    background-image: ${(props) =>
        `url('${process.env.PUBLIC_URL}/background-l1-mosaic.svg')`};
    background-position-x: 50%;
    background-position-y: 50%;
    background-repeat: no-repeat;
    background-size: 100%;
    width: 100%;
    height: 200px;
`
const PageTitleTextLabel = styled(FormLabel)`
    color: var(
        --global-header-light-light-fill,
        var(--black-and-white-white, #fff)
    );
    text-align: center;
    font-family: 'IntelOne Display';
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px; /* 120% */
    letter-spacing: -0.2px;
    text-align: left;
    padding: 1rem 0;
`
const DecisionSelect = styled(Form.Select)`
    width: auto;
    margin: 0 1rem 0 0;
    width: 10rem;
`
const AlertContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: row;
`
const SearchContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    width: 96%;
`
const SearchTextboxComponent = styled(FormControl)`
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    font-size: 1.1rem !important;
    font-weight: 300;
    font-family: 'IntelOne Display';
    font-style: italic;
    border-radius: 0px;
    padding: 0.2rem;
    align-items: flex-start;
    gap: 4px;
    width: 17rem;
    height: 2rem;
    color: var(--theme-light-gray-700, #6a6d75);
    border: 1px solid var(--theme-light-gray-400, #c9cace);
    background: var(--theme-light-gray-50, #fff);
`
const FilterContainerDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`
const FilterButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 32px;
    padding: 0px 12px;
    border: 1px solid var(--input-stroke-dark-normal, #8e9099);
    background: var(--classic-blue-classic-blue-shade-1, #00377c);
    &:hover {
        border: 1px solid var(--input-stroke-dark-normal, #8e9099);
        background: var(--classic-blue-classic-blue-shade-1, #00377c);
    }
    &:focus {
        border: 1px solid var(--input-stroke-dark-normal, #8e9099);
        background: var(--classic-blue-classic-blue-shade-1, #00377c);
    }
`
const FilterContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 0.5rem;
`
const FilterButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`
const FilterComponent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-top-style: ridge;
    margin-top: 0.5rem;
    max-height: 30rem;
    overflow: auto;
    overflow-x: hidden;
`
const FilterHeadingSpan = styled.span`
    padding: 2rem, 0;
    font-size: 0.9rem;
    color: var(--text-light-default, #2b2c30);
    font-family: 'IntelOne Display';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 187.5% */
    letter-spacing: 0.16px;
`
const ApplyFilters = styled(Button)`
    display: flex;
    justify-content: center;
    vertical-align: middle;
    background-color: #0054ae;
    height: 2rem;
    margin: 0.5rem;
    color: var(--text-dark-default, #fff);
    font-family: 'IntelOne Display';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;

    &:hover {
        background-color: #0054ae;
        transition: 0.5s;
    }
`
const RemoveFilters = styled(ApplyFilters)`
    background-color: #fff;
    border: none;
    color: var(--text-light-default, #2b2c30);
    /* Button/Medium */
    font-family: 'IntelOne Display';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 114.286% */
    height: 2rem;
    padding-top: 0.6rem;
    margin: 0.5rem;
    &:hover {
        background-color: #fff;
        border: none;
        color: var(--text-light-default, #2b2c30);
    }
`
const CloseButtonDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: right;
    padding-top: 0.6rem;
    padding-left: 0.8rem;
`
const FilterCheckbox = styled(Form.Check)`
    color: var(--text-default, #2b2c30);
    /* List/Label/Medium */
    font-family: IntelOne Text;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
`
const SpinnerComponent = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    justify-content: center;
    align-items: center;
`
const ModalPopupContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const ModalTextLabelKey = styled(FormLabel)`
    display: flex;
    ${(props) => props.theme.typography.medium}
    text-align: left;
    margin-top: 0.5rem;
`
const ModalTextSpan = styled.span`
    display: flex;
    ${(props) => props.theme.typography.medium}
    text-align: left;
    font-weight: 500;
    padding-right: 0.5rem;
`
const ValidationFieldSpan = styled.span`
    display: flex;
    font-size: 0.875em;
    color: #dc3545;
`

export interface IAllRequestPanelProps {
    title: string
    getData: any
    dataResponse?: any
    dataLoading: LoadingState
    dataError: any
    handleClose?: any
}

const AllSupportRequestPanel: FunctionComponent<IAllRequestPanelProps> = ({
    title,
    getData,
    dataResponse,
    dataLoading,
    dataError,
}: IAllRequestPanelProps) => {
    const filterTarget = useRef(null)
    const dispatch = useAppDispatch()

    const {
        afterPageClicked,
        handlePageCount,
        currPage,
        pageCount,
        handleStatusUpdateChange,
        selectedDecision,
        setSelectedDecision,
        showApprovalErrorMessage,
        showApprovalMessage,
        updateDecisionError,
        updateSupportSuccess,
        onSearchKeyChange,
        onSearchKeydown,
        totalFilterCount,
        showFilters,
        setShowFilters,
        onRemoveFiltersClick,
        onCloseClick,
        onApplyFiltersClick,
        onFromDateChange,
        onToDateChange,
        fromDate,
        toDate,
        statusFilters,
        onStatusFilterChange,
        searchKey,
        showDeletePopup,
        setShowDeletePopup,
        handleClose,
        recipeData,
        setRecipeData,
        deleteResult,
        deleteError,
        disableButtons,
        handleDelete,
        setIsPopupLoad,
        isPopupLoad,
        fromDateValError,
        toDateValError,
    } = AllSupportRequestPanelLogic({
        getData,
        dataResponse,
    } as IAllRequestPanelProps)

    let recipes = dataResponse?.requests
        ? [...dataResponse?.requests]
        : undefined

    const totalPage = dataResponse?.pageInfo?.total
        ? dataResponse?.pageInfo.total
        : -1

    const nextPage = dataResponse?.pageInfo?.next
        ? dataResponse?.pageInfo.next
        : -1

    const recipeRows = recipes?.map((recipe, index) => {
        const timeUpdated = moment
            .utc(recipe.timeUpdated)
            .local()
            .format('MM/DD/YY, h:mm a')
        const timeCreated = moment
            .utc(recipe.timeCreated)
            .local()
            .format('MM/DD/YY, h:mm a')
        return (
            <tr key={recipe.uuid}>
                <td style={{ width: '8%' }}>
                    <RecipeImageLabel>
                        <span>{index + 1}</span>
                    </RecipeImageLabel>
                </td>
                <td style={{ width: '15%' }}>
                    <RecipeTextLabelWrapper>
                        <RecipeNameTextLabel
                            data-testid={`recipeName-value-${recipe?.uuid}`}
                        >
                            {recipe?.additionalFields?.additionalProperties
                                ?.appName ||
                                recipe?.additionalFields?.additionalProperties
                                    ?.feedback}
                        </RecipeNameTextLabel>
                    </RecipeTextLabelWrapper>
                </td>
                <td style={{ width: '20%' }}>
                    <RecipeTextLabelWrapper>
                        <RecipeNameTextLabel>
                            {recipe.submitterEmail}
                        </RecipeNameTextLabel>
                    </RecipeTextLabelWrapper>
                </td>
                <td style={{ width: '15%' }}>
                    <RecipeTextLabelWrapper>
                        <RecipeDateTextLabel
                            data-testid={`status-value-${recipe?.uuid}`}
                        >
                            {timeCreated}
                        </RecipeDateTextLabel>
                    </RecipeTextLabelWrapper>
                </td>
                <td style={{ width: '15%' }}>
                    <RecipeTextLabelWrapper>
                        <RecipeStatusTextLabel
                            data-testid={`status-value-${recipe?.uuid}`}
                        >
                            {recipe.status}
                        </RecipeStatusTextLabel>
                    </RecipeTextLabelWrapper>
                </td>
                <td style={{ width: '15%' }}>
                    <RecipeTextLabelWrapper>
                        <RecipeDateTextLabel
                            data-testid={`status-value-${recipe?.uuid}`}
                        >
                            {timeUpdated}
                        </RecipeDateTextLabel>
                    </RecipeTextLabelWrapper>
                </td>
                <td style={{ width: '15%' }}>
                    <RecipeLinklWrapper>
                        <DecisionSelect
                            style={{}}
                            size="sm"
                            onChange={(
                                ev: any,
                                uuid: string,
                                additionalFields: any
                            ) =>
                                handleStatusUpdateChange(
                                    ev,
                                    recipe?.uuid,
                                    recipe?.additionalFields
                                        ?.additionalProperties
                                )
                            }
                            value={
                                recipe && recipe.status ? recipe?.status : -1
                            }
                        >
                            <option value={'-1'} style={{ color: 'gray' }}>
                                Select...
                            </option>
                            <option value={'Work In Progress'}>
                                Work In Progress
                            </option>
                            <option value={'Resolved'}>Resolved</option>
                        </DecisionSelect>
                    </RecipeLinklWrapper>
                </td>
                <td>
                    <RecipeLinklWrapper>
                        <DropdownButton
                            id="dropdown-basic-button"
                            title="Action"
                            variant="outline-primary"
                            data-testid={`action-button-${recipe?.uuid}`}
                        >
                            <Dropdown.Item
                                href={`viewDetails/all/${recipe.uuid}`}
                            >
                                View
                            </Dropdown.Item>
                            <Dropdown.Item
                                data-testid={`action-item-delete-${recipe?.uuid}`}
                                onClick={() => {
                                    setShowDeletePopup(true)
                                    setRecipeData(recipe)
                                    setIsPopupLoad(true)
                                }}
                            >
                                Delete
                            </Dropdown.Item>
                        </DropdownButton>
                    </RecipeLinklWrapper>
                </td>
            </tr>
        )
    })

    let statusFiltersRender = statusFilters.map((item, index) => {
        if (item.name !== null) {
            return (
                <FilterCheckbox
                    type="checkbox"
                    key={item.name + index}
                    id={item.name + index}
                    value={item.name}
                    label={item.name}
                    aria-label={item.name}
                    checked={item.checkedValue}
                    onChange={onStatusFilterChange}
                />
            )
        }
    })
    const filterComponents = (
        <FilterContainer>
            <FilterButton
                style={
                    totalFilterCount > 0
                        ? { width: '3.5rem' }
                        : { width: '2.5rem' }
                }
                type="submit"
                data-testid="filter-button"
                onClick={() => {
                    setShowFilters(!showFilters)
                }}
                ref={filterTarget}
            >
                <span>
                    {totalFilterCount > 0 ? ' (' + totalFilterCount + ')' : ''}
                </span>
                <i className="uil uil-filter"></i>
            </FilterButton>
            <Overlay
                target={filterTarget.current}
                show={showFilters}
                placement="bottom-end"
            >
                {({ placement, arrowProps, show: _show, popper, ...props }) => (
                    <div
                        {...props}
                        style={{
                            position: 'absolute',
                            backgroundColor: '#ffff',
                            padding: '1rem 1rem',
                            marginTop: '0.29rem',
                            borderRadius: 5,
                            border: '1px',
                            borderColor: '#6c757d',
                            borderStyle: 'double',
                            ...props.style,
                        }}
                        //onMouseLeave={() => setShowFilters(false)}
                    >
                        <FilterButtonContainer>
                            <RemoveFilters onClick={onRemoveFiltersClick}>
                                Clear All
                            </RemoveFilters>
                            <ApplyFilters onClick={onApplyFiltersClick}>
                                Apply Filters
                            </ApplyFilters>
                            <CloseButtonDiv>
                                <CloseButton
                                    className="p-1"
                                    onClick={onCloseClick}
                                />
                            </CloseButtonDiv>
                        </FilterButtonContainer>

                        <FilterComponent>
                            <div
                                style={{
                                    borderRightStyle: 'ridge',
                                    padding: '0 1rem',
                                    marginTop: '0.5rem',
                                    width: '10rem',
                                }}
                            >
                                <FilterHeadingSpan>Status</FilterHeadingSpan>

                                {statusFiltersRender}
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginTop: '0.5rem',
                                }}
                            >
                                <div style={{ padding: '0 1rem' }}>
                                    <TextLabel>From date:</TextLabel>

                                    <Form.Control
                                        type="date"
                                        id="fromDate"
                                        style={{
                                            width: '100%',
                                            height: '2.4rem',
                                        }}
                                        defaultValue={fromDate}
                                        onChange={onFromDateChange}
                                        max={
                                            new Date()
                                                .toISOString()
                                                .split('T')[0]
                                        }
                                    ></Form.Control>
                                    <ValidationFieldSpan>
                                        {fromDateValError}
                                    </ValidationFieldSpan>
                                </div>
                                <div style={{ padding: '1rem' }}>
                                    <TextLabel>To date:</TextLabel>

                                    <Form.Control
                                        type="date"
                                        id="toDate"
                                        style={{
                                            width: '100%',
                                            height: '2.4rem',
                                        }}
                                        defaultValue={toDate}
                                        onChange={onToDateChange}
                                        max={
                                            new Date()
                                                .toISOString()
                                                .split('T')[0]
                                        }
                                    ></Form.Control>
                                    <ValidationFieldSpan>
                                        {toDateValError}
                                    </ValidationFieldSpan>
                                </div>
                            </div>
                        </FilterComponent>
                    </div>
                )}
            </Overlay>
        </FilterContainer>
    )

    return (
        <AllRequestPanelFormContainer>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    background: '#F4F5F5',
                    width: '100%',
                }}
            >
                <Breadcrumb style={{ padding: '0.5rem 1rem' }}>
                    <BreadcrumbItem
                        href={StringUtils.resolve('/catalog')}
                        style={{ textDecoration: 'none', color: '#0068b5' }}
                    >
                        Intel Optimization Hub
                    </BreadcrumbItem>

                    <BreadcrumbItem
                        active
                        style={{ fontWeight: '500', color: '#262626' }}
                    >
                        All Support Requests
                    </BreadcrumbItem>
                </Breadcrumb>
            </div>
            <HeaderTitleComponent>
                <div style={{ display: 'flex', justifyContent: 'left' }}>
                    <PageTitleTextLabel>{title}</PageTitleTextLabel>
                </div>
            </HeaderTitleComponent>
            <DataContainer>
                <VericalContainer>
                    <SearchContainer>
                        <div>
                            <ShowingPageContainer>
                                {totalPage > 0 ? (
                                    <span>
                                        {' '}
                                        Showing {(currPage - 1) * pageCount +
                                            1}{' '}
                                        to{' '}
                                        {currPage * pageCount < totalPage
                                            ? currPage * pageCount
                                            : totalPage}{' '}
                                        of {totalPage} results{' '}
                                    </span>
                                ) : (
                                    ''
                                )}
                                {totalPage > 10 && totalPage >= nextPage ? (
                                    <PageCountSelect
                                        size="sm"
                                        onChange={handlePageCount}
                                        value={pageCount}
                                    >
                                        <option value={10}>10</option>
                                        <option value={20}>20</option>
                                        <option value={50}>50</option>
                                        <option value={100}>100</option>
                                    </PageCountSelect>
                                ) : (
                                    ''
                                )}
                            </ShowingPageContainer>
                        </div>
                        <FilterContainerDiv>
                            <SearchTextboxComponent
                                placeholder={`🔍 Search...`}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => onSearchKeyChange(e)}
                                onKeyDown={(
                                    event: KeyboardEvent<HTMLElement>
                                ) => onSearchKeydown(event.key)}
                                value={searchKey}
                                type="search"
                            />
                            <FilterContainerDiv>
                                {filterComponents}
                            </FilterContainerDiv>
                        </FilterContainerDiv>
                    </SearchContainer>
                    <AlertContainer>
                        <AlertComponent
                            variant={'danger'}
                            show={dataError ? true : false}
                            data-testid={`error-label-${title}`}
                        >
                            {dataError && dataError.message !== undefined
                                ? 'Error!: ' + dataError.message
                                : ''}
                        </AlertComponent>
                        <AlertComponent
                            variant="success"
                            show={showApprovalMessage}
                        >
                            Successfully updated status!
                        </AlertComponent>
                        <AlertComponent
                            variant="danger"
                            show={showApprovalErrorMessage}
                        >
                            Error while updating status! {updateDecisionError}
                        </AlertComponent>
                    </AlertContainer>
                    {dataLoading !== LoadingState.Pending ? (
                        <RecipeContainer>
                            <Table
                                responsive
                                borderless
                                style={{ minHeight: '14rem' }}
                            >
                                <thead>
                                    <tr
                                        style={{
                                            borderBottom: '1px solid #C9CACE',
                                        }}
                                    >
                                        <th>
                                            <RecipeTextLabelHeadings
                                                style={{ width: '4rem' }}
                                                data-testid={`id-label-${title}`}
                                            >
                                                Serial
                                            </RecipeTextLabelHeadings>
                                        </th>
                                        <th>
                                            <RecipeTextLabelHeadings
                                                data-testid={`name-label-${title}`}
                                            >
                                                Name
                                            </RecipeTextLabelHeadings>
                                        </th>
                                        <th>
                                            <RecipeTextLabelHeadings
                                                data-testid={`submitter-label-${title}`}
                                            >
                                                Submitter
                                            </RecipeTextLabelHeadings>
                                        </th>
                                        <th>
                                            <RecipeTextLabelHeadings>
                                                Created Date
                                            </RecipeTextLabelHeadings>
                                        </th>
                                        <th>
                                            <RecipeTextLabelHeadings
                                                data-testid={`status-label-${title}`}
                                            >
                                                Status
                                            </RecipeTextLabelHeadings>
                                        </th>
                                        <th>
                                            <RecipeTextLabelHeadings>
                                                Last Updated Date
                                            </RecipeTextLabelHeadings>
                                        </th>
                                        <th>
                                            <RecipeTextLabelHeadings
                                                data-testid={`updatestatus-label-${title}`}
                                            >
                                                Update Status
                                            </RecipeTextLabelHeadings>
                                        </th>
                                        <th>
                                            <RecipeTextLabelHeadings
                                                data-testid={`action-label-${title}`}
                                            >
                                                Actions
                                            </RecipeTextLabelHeadings>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {recipes && recipes.length > 0 ? (
                                        recipeRows
                                    ) : (
                                        <tr>
                                            <td colSpan={7}>
                                                <div
                                                    style={{
                                                        marginTop: '3rem',
                                                    }}
                                                >
                                                    {NoResultFoundPanel()}
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                            <PaginationComponent>
                                <PaginationPanelContainer>
                                    {totalPage > 10 && totalPage >= nextPage ? (
                                        <>
                                            <Row
                                                style={{
                                                    width: '100%',
                                                    justifyContent: 'right',
                                                }}
                                            >
                                                <Form.Group as={Col} md="3">
                                                    <PaginationDiv>
                                                        <Pagination
                                                            totalPage={Math.ceil(
                                                                totalPage /
                                                                    pageCount
                                                            )}
                                                            currentPage={
                                                                currPage
                                                            }
                                                            onPageClicked={(
                                                                ele: any
                                                            ) => {
                                                                afterPageClicked(
                                                                    ele
                                                                )
                                                            }}
                                                        />
                                                    </PaginationDiv>
                                                </Form.Group>
                                            </Row>
                                        </>
                                    ) : (
                                        <div></div>
                                    )}
                                </PaginationPanelContainer>
                            </PaginationComponent>
                        </RecipeContainer>
                    ) : (
                        <SpinnerComponent>
                            <Spinner animation="border" variant="dark" />
                        </SpinnerComponent>
                    )}
                </VericalContainer>
            </DataContainer>
            <ModalPopupContainer>
                <Modal
                    show={showDeletePopup}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Support Request</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ModelAlertComponent
                            id="successAlert"
                            variant="success"
                            show={
                                !isPopupLoad && deleteResult?.msg ? true : false
                            }
                        >
                            {'Support Request Successfully Deleted'}
                        </ModelAlertComponent>
                        <ModelAlertComponent
                            id="errorAlert"
                            variant="danger"
                            show={
                                !isPopupLoad && deleteError?.message
                                    ? true
                                    : false
                            }
                        >
                            {'Error while deleting request. ' +
                                deleteError?.message}
                        </ModelAlertComponent>
                        <span>Are you sure you want to delete?</span>
                        <ModalTextLabelKey>
                            <ModalTextSpan>Name:</ModalTextSpan>
                            {
                                recipeData?.additionalFields
                                    ?.additionalProperties?.appName
                            }
                        </ModalTextLabelKey>

                        <ModalTextLabelKey>
                            <ModalTextSpan>Submitter: </ModalTextSpan>{' '}
                            {recipeData?.submitterEmail}
                        </ModalTextLabelKey>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button
                            variant="danger"
                            onClick={handleDelete}
                            disabled={disableButtons}
                        >
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
            </ModalPopupContainer>
        </AllRequestPanelFormContainer>
    )
}

export default AllSupportRequestPanel
