/************************************************
 * Copyright (C) 2021 Intel Corporation
 ************************************************/
import styled from 'styled-components'
import { FunctionComponent, Dispatch, useEffect } from 'react'
import { connect } from 'react-redux'
import FooterPanel from '../../layout/footer'
import PageLinkPanel from '../../layout/pageLinks'
import { useNavigate } from 'react-router-dom'
import OptimizationDetailsPanel from '../../components/optimizationDetailsPanel/optimizationDetailsPanel'

const OptimizationPanelContainer = styled.div`
    display: flex;
    height: ${(props) => props.theme.size.mainContainerHeight};
    width: ${(props) => props.theme.size.mainContainerWidth};

    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: space-between;
    @media screen and (min-width: 80rem) {
        overflow-x: hidden;
    }
    @media screen and (max-width: 500px) {
        width: 100%;
        height: 100%;
    }
`

const OptimizationHorizontalContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: space-between;
`
const OptimizationBodyContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: no-wrap;
    justify-content: center;
    background-color: #fff; //${(props) =>
        props.theme.color.background.defaultGrey};
    padding-bottom: 10rem;
    width: 100%;
`

const OptimizationBodyContainerReposition = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
`

const PageLinkPanelPanelWrapper = styled.div`
    display: flex;
    margin-left: 2rem;
    display: none;
`

export interface IOptContainerProps {}

const OptimizationDetailsContainer: FunctionComponent<IOptContainerProps> & {
    defaultProps: Partial<IOptContainerProps>
} = ({}: IOptContainerProps) => {
    //let navigate = useNavigate()
    let headerPanelContent = 'Intel® Video Transcode Acceleration Library'
    let viewContent =
        'Video service providers report transcoding contributes significant cost overhead. Costs are influenced by two key factors: computing overhead and network traffic. Intel has developed new media library interoperable with existing video encoding formats to help customers manage cost while maintaining quality.'
    const benefitsContent = (
        <div>
            <b>Improve performance:</b> Throughput mode can improve throughput
            by up to 75% while maintaining quality similar to standard
            transcoding <br />
            <br />
            <b>Reduce computing overhead: </b>More efficient software means more
            content can be transcoded with less computing time, reducing the
            amount of compute capacity necessary.
            <br />
            <br />
            <b> Reduce Network Traffic:</b> More efficient encoding means less
            bandwidth can be used without a perceptible difference in video
            quality.
            <br />
            <br />
            <b> Reduce costs:</b> Reduced computing capacity and network traffic
            can deliver up to 25% savings compared to standard transcoding when
            used in Quality Mode; up to 40% when used in throughput mode!
        </div>
    )

    const capabilitiesContent = (
        <div>
            {' '}
            <b>User-selectable “Quality Mode” and “Throughput Mode"</b> <br />
            <br />
            <b>Current version: h.265 or h.264 to h.264</b>
            <br />
            <br />
            <b>
                Coming soon (early 2023): transcode either direction between
                h.264 and h.265
            </b>{' '}
            <br />
            <br />
            <b>
                Future versions (2023/2024): AV1 and h.266 supported as input
                and output video coding formats
            </b>
        </div>
    )

    const howToContent = (
        <div>
            <b>
                Intel Video Transcode Acceleration Library is offered through
                Intel Optimization Hub. Free trials are available.
            </b>
        </div>
    )
    let whattoContent = (
        <div>
            {' '}
            <ul>
                <li>
                    Customers offering streaming video to their customers,
                    including:{' '}
                </li>
                <ul>
                    <li> Movie and television services </li>
                    <li> Social media and user-generated </li>
                    <li> Cloud gaming and broadcasting (value unclear) </li>
                </ul>
            </ul>
        </div>
    )

    const faqContent = (
        <div>
            {' '}
            <b>How does this work?</b>
            <ul>
                <li>
                    FFMPEG is a common component of a media transcoding
                    pipeline. We offer a drop-in replacement library. No
                    significant architecture changes are needed.{' '}
                </li>
            </ul>
            <br />
            <b>How much will this cost?</b>
            <ul>
                <li>Offered with unlimited-use subscription</li>
            </ul>
        </div>
    )

    return (
        <OptimizationPanelContainer>
            <OptimizationHorizontalContainer>
                <PageLinkPanelPanelWrapper>
                    <PageLinkPanel />
                </PageLinkPanelPanelWrapper>
                <OptimizationBodyContainer>
                    <OptimizationBodyContainerReposition>
                        <OptimizationDetailsPanel
                            headerPanelContent={headerPanelContent}
                            view={viewContent}
                            benefits={benefitsContent}
                            capabilities={capabilitiesContent}
                            howTo={howToContent}
                            WhatTo={whattoContent}
                            FAQ={faqContent}
                            contacts={''}
                        />
                    </OptimizationBodyContainerReposition>
                </OptimizationBodyContainer>
            </OptimizationHorizontalContainer>
            <FooterPanel></FooterPanel>
        </OptimizationPanelContainer>
    )
}

OptimizationDetailsContainer.defaultProps = {}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {}
}

const mapStateToProps = (state: any) => {
    return {}
}

type StateToPropsType = ReturnType<typeof mapStateToProps>
type DispatchToPropsType = typeof mapDispatchToProps

export default connect<StateToPropsType, DispatchToPropsType>(
    mapStateToProps,
    mapDispatchToProps
)(OptimizationDetailsContainer)
