import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import {
    Button,
    Spinner,
    Alert,
    FormLabel,
    Form,
    Col,
    Row,
    Modal,
} from 'react-bootstrap'
import { LoadingState } from '../../models/loadingState'
import { NewBenchmarkPanelLogic } from './newBenchmarkPanel.took'
import { NewBenchmarkRequest } from '../../models/newBenchmarkRequest'
import { NewBenchmarkResponse } from '../../models/newBenchmarkResponse'
import Select from 'react-select'
import { useNavigate } from 'react-router-dom'

const NewBenchmarkFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
`
const NewBenchmarkFormComponent = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: flex-start;
`
const HorizontalContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-grow: 1;
`

const ButtonSpinnerHorizontalComponent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 2rem;
`

const SubmitButton = styled(Button)`
    display: flex;
    justify-content: center;

    border-radius: ${(props) => props.theme.shape.button.borderRadius};
    background-color: ${(props) =>
        props.theme.color.button.background.default.primary};
    &:hover {
        background-color: ${(props) =>
            props.theme.color.button.background.hover.primary};
        transition: 0.5s;
    }
`
const CancelButton = styled(Button)`
    display: flex;
    justify-content: center;
    margin-left: 1.35rem;
    border-radius: ${(props) => props.theme.shape.button.borderRadius};
    background-color: ${(props) =>
        props.theme.color.button.background.default.primary};
    &:hover {
        background-color: ${(props) =>
            props.theme.color.button.background.hover.primary};
        transition: 0.5s;
    }
`
const PageTitleTextLabel = styled(FormLabel)`
    ${(props) => props.theme.typography.xxxLarge}
    text-align: left;
    margin-bottom: 3rem;
    font-weight: 500;
    font-size: 2.25rem;
`

const TextLabel = styled(FormLabel)`
    display: flex;
    ${(props) => props.theme.typography.xLarge}
    text-align: left;
`

const NewBenchmarkResultLabel = styled(FormLabel)`
    display: flex;
    ${(props) => props.theme.typography.medium}
    text-align: left;
    justify-content: left;
    background:  ${(props) => props.theme.color.background.label.title}
    white-space: pre-wrap;
    margin-left: 1rem;
    padding-top:1rem;
`
const SpinnerComponent = styled.div`
    display: flex;
    margin-top: 0.5rem;
`
const AlertComponent = styled(Alert)`
    display: flex;
    margin-top: 1rem;
    width: 35vw;
    justify-content: center;
`
const RequiredTextLabel = styled.span`
    display: flex;
    ${(props) => props.theme.typography.xLarge}
    color: red;
`
const RequiredFieldMessage = styled.span`
    display: flex;
    font-size: 0.875em;
    color: #dc3545;
`
const RequiredFieldOutLine = styled.span`
    display: flex;
    font-size: 0.875em;
    color: #dc3545;
    border: 1px solid;
    border-color: #dc3545;
    width: 100%;
`
const ModalPopupContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

export interface INewBenchmarkPanelProps {
    onNewBenchmarkSubmit: (newBenchmarkRequest: NewBenchmarkRequest) => void
    newBenchmarkResult: NewBenchmarkResponse
    newBenchmarkLoading: LoadingState
    newBenchmarkError?: any
}

const NewBenchmarkPanel: FunctionComponent<INewBenchmarkPanelProps> = ({
    onNewBenchmarkSubmit,
    newBenchmarkResult,
    newBenchmarkLoading,
    newBenchmarkError,
}: INewBenchmarkPanelProps) => {
    const {
        onCancle,
        validated,
        onSubmit,
        showExistingWorkloadlist,
        existingWorkloadName,
        onNewOneRequestChange,
        onExistingRequestChange,
        onNewWorkloadNameChange,
        onNewWorkloadVersionChange,
        onExistingWorkloadChange,
        onTargetHWChange,
        onCustomersChange,
        onBusinessImpactChange,
        onDueDateChange,
        onBusinessUnitChange,
        onRequesterNameChange,
        onTestCaseChange,
        onSoftwareComponentChange,
        onConfigurationChange,
        onKPIMetricChange,
        onWorkloadLocationChange,
        onContactChange,
        onCommentChange,
        isRequired,
        newWorkloadName,
        targetHW,
    } = NewBenchmarkPanelLogic({
        onNewBenchmarkSubmit,
    } as INewBenchmarkPanelProps)
    const navigate = useNavigate()
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false)
    const handleConfirmationClose = () => {
        setShowConfirmationPopup(false)
        navigate('/myRequest')
    }

    const workloadOptions = [
        { value: 'NGINX', label: 'NGINX' },
        { value: 'Wordpress', label: 'Wordpress' },
        { value: 'Java', label: 'Java' },
        { value: 'MongoDB', label: 'MongoDB' },
    ]
    useEffect(() => {
        if (newBenchmarkResult) setShowConfirmationPopup(true)
    }, [newBenchmarkResult])

    return (
        <NewBenchmarkFormContainer>
            <HorizontalContainer>
                <NewBenchmarkFormComponent>
                    <PageTitleTextLabel data-testid="newbenchmarkpage-title">
                        Request new benchmark
                    </PageTitleTextLabel>
                    <Form
                        noValidate
                        validated={validated}
                        onSubmit={onSubmit}
                        id="benchmarkForm"
                        style={{ paddingRight: '0.5rem' }}
                    >
                        <Row className="mb-3">
                            <Form.Group as={Col} md="4">
                                <Form.Check
                                    type="radio"
                                    id={`newOne`}
                                    label="New one"
                                    name="requestType"
                                    inline={true}
                                    checked={!showExistingWorkloadlist}
                                    onChange={onNewOneRequestChange}
                                    data-testid="requestType-new"
                                />
                                <Form.Check
                                    type="radio"
                                    id={`existing`}
                                    label="Existing one"
                                    name="requestType"
                                    inline={true}
                                    onChange={onExistingRequestChange}
                                    data-testid="requestType-existing"
                                />
                            </Form.Group>
                        </Row>
                        {showExistingWorkloadlist ? (
                            <Row className="mb-3">
                                <Form.Group as={Col} md="4">
                                    <TextLabel data-testid="select-Workload-label">
                                        <RequiredTextLabel>*</RequiredTextLabel>
                                        Select workload
                                    </TextLabel>
                                    <Select
                                        isSearchable={true}
                                        isClearable={true}
                                        isMulti={false}
                                        options={workloadOptions}
                                        onChange={onExistingWorkloadChange}
                                        data-testid="select-Workload-text"
                                    />

                                    {validated &&
                                    existingWorkloadName === '' ? (
                                        <RequiredFieldMessage>
                                            Please select workload.
                                        </RequiredFieldMessage>
                                    ) : (
                                        ''
                                    )}
                                </Form.Group>
                            </Row>
                        ) : (
                            <div>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="4">
                                        <TextLabel data-testid="workload-name-label">
                                            <RequiredTextLabel>
                                                *
                                            </RequiredTextLabel>
                                            Workload name
                                        </TextLabel>
                                        <Form.Control
                                            id="workloadName"
                                            placeholder="Enter workload name..."
                                            onChange={onNewWorkloadNameChange}
                                            required={isRequired}
                                            data-testid="workload-name-text"
                                        />

                                        <Form.Control.Feedback type="invalid">
                                            Please enter workload name.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="4">
                                        <TextLabel data-testid="workload-version-label">
                                            <RequiredTextLabel>
                                                *
                                            </RequiredTextLabel>
                                            Workload version
                                        </TextLabel>
                                        <Form.Control
                                            id="workloadVersion"
                                            placeholder="Enter workload version..."
                                            onChange={
                                                onNewWorkloadVersionChange
                                            }
                                            required={isRequired}
                                            data-testid="workload-version-text"
                                        />

                                        <Form.Control.Feedback type="invalid">
                                            Please enter workload version.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                            </div>
                        )}
                        <Row className="mb-3">
                            <Form.Group as={Col} md="4">
                                <TextLabel data-testid="targeted-hw-label">
                                    <RequiredTextLabel>*</RequiredTextLabel>
                                    Targeted HW
                                </TextLabel>
                                <Form.Control
                                    id="targeted_HW"
                                    placeholder="Enter targeted HW..."
                                    onChange={onTargetHWChange}
                                    required
                                    data-testid="targeted-hw-text"
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please enter targeted HW.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="4">
                                <TextLabel data-testid="customer-label">
                                    <RequiredTextLabel>*</RequiredTextLabel>
                                    Customers for whom it's requested
                                </TextLabel>
                                <Form.Control
                                    id="customer"
                                    placeholder="Enter customers name..."
                                    onChange={onCustomersChange}
                                    required
                                    data-testid="customer-text"
                                />

                                <Form.Control.Feedback type="invalid">
                                    Please enter customers name.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="4">
                                <TextLabel data-testid="business-impact-label">
                                    <RequiredTextLabel>*</RequiredTextLabel>
                                    Business impact
                                </TextLabel>
                                <Form.Control
                                    id="business_impact"
                                    placeholder="Enter business impact..."
                                    onChange={onBusinessImpactChange}
                                    required
                                    as="textarea"
                                    rows={2}
                                    data-testid="business-impact-text"
                                />

                                <Form.Control.Feedback type="invalid">
                                    Please enter business impact.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="4">
                                <TextLabel data-testid="dueDate-picker-label">
                                    Due date
                                </TextLabel>
                                <Form.Control
                                    type="date"
                                    style={{
                                        width: '50%',
                                        height: '50%',
                                    }}
                                    onChange={onDueDateChange}
                                    data-testid="dueDate-text-picker"
                                ></Form.Control>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="4">
                                <TextLabel data-testid="business-unit-label">
                                    <RequiredTextLabel>*</RequiredTextLabel>
                                    Business Unit
                                </TextLabel>
                                <Form.Control
                                    id="business_unit"
                                    placeholder="Enter business unit..."
                                    onChange={onBusinessUnitChange}
                                    required
                                    as="textarea"
                                    rows={2}
                                    data-testid="business-unit-text"
                                />

                                <Form.Control.Feedback type="invalid">
                                    Please enter business unit.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="4">
                                <TextLabel data-testid="requester-name-label">
                                    <RequiredTextLabel>*</RequiredTextLabel>
                                    Requester's Name
                                </TextLabel>
                                <Form.Control
                                    id="requester_Name"
                                    placeholder="Enter requester's name..."
                                    onChange={onRequesterNameChange}
                                    required
                                    data-testid="requester-name-text"
                                />

                                <Form.Control.Feedback type="invalid">
                                    Please enter requester's name.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="4">
                                <TextLabel data-testid="testcase-label">
                                    <RequiredTextLabel>*</RequiredTextLabel>
                                    Test case
                                </TextLabel>
                                <Form.Control
                                    id="test_case"
                                    placeholder="Enter test case..."
                                    onChange={onTestCaseChange}
                                    required
                                    as="textarea"
                                    rows={2}
                                    data-testid="testcase-text"
                                />

                                <Form.Control.Feedback type="invalid">
                                    Please enter test case.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="4">
                                <TextLabel data-testid="software-list-label">
                                    <RequiredTextLabel>*</RequiredTextLabel>
                                    List of all Software components to be used
                                    with versions
                                </TextLabel>
                                <Form.Control
                                    id="list_of_software"
                                    placeholder="Enter list of all software components..."
                                    onChange={onSoftwareComponentChange}
                                    required
                                    as="textarea"
                                    rows={2}
                                    data-testid="software-list-text"
                                />

                                <Form.Control.Feedback type="invalid">
                                    Please enter list of all software
                                    components.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="4">
                                <TextLabel data-testid="configuration-label">
                                    <RequiredTextLabel>*</RequiredTextLabel>
                                    Configuration
                                </TextLabel>
                                <Form.Control
                                    id="configuration"
                                    placeholder="Enter configuration..."
                                    onChange={onConfigurationChange}
                                    required
                                    as="textarea"
                                    rows={2}
                                    data-testid="configuration-text"
                                />

                                <Form.Control.Feedback type="invalid">
                                    Please enter configuration.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="4">
                                <TextLabel data-testid="kpi_measure-label">
                                    <RequiredTextLabel>*</RequiredTextLabel>
                                    KPI/Metric to measure
                                </TextLabel>
                                <Form.Control
                                    id="kpi_measure "
                                    placeholder="Enter KPI/Metric to measure ..."
                                    onChange={onKPIMetricChange}
                                    required
                                    as="textarea"
                                    rows={2}
                                    data-testid="kpi_measure-text"
                                />

                                <Form.Control.Feedback type="invalid">
                                    Please enter KPI/Metric to measure.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="4">
                                <TextLabel data-testid="workload-source-label">
                                    <RequiredTextLabel>*</RequiredTextLabel>
                                    Where source of WL can be found
                                </TextLabel>
                                <Form.Control
                                    id="wl_source"
                                    placeholder="Enter where source of WL can be found ..."
                                    onChange={onWorkloadLocationChange}
                                    required
                                    as="textarea"
                                    rows={2}
                                    data-testid="workload-source-text"
                                />

                                <Form.Control.Feedback type="invalid">
                                    Please enter where source of WL can be
                                    found.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="4">
                                <TextLabel data-testid="contact-label">
                                    <RequiredTextLabel>*</RequiredTextLabel>
                                    Contact for tech details
                                </TextLabel>
                                <Form.Control
                                    id="contact"
                                    placeholder="Enter contact..."
                                    onChange={onContactChange}
                                    required
                                    as="textarea"
                                    rows={2}
                                    data-testid="contact-text"
                                />

                                <Form.Control.Feedback type="invalid">
                                    Please enter contact.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="4">
                                <TextLabel data-testid="comments-label">
                                    Comments
                                </TextLabel>
                                <Form.Control
                                    id="comments"
                                    placeholder="Enter comments..."
                                    onChange={onCommentChange}
                                    as="textarea"
                                    rows={4}
                                    data-testid="comments-text"
                                />
                            </Form.Group>
                        </Row>
                        <ButtonSpinnerHorizontalComponent>
                            <SubmitButton
                                variant="primary"
                                type="submit"
                                data-testid="submit-button"
                            >
                                Submit
                            </SubmitButton>
                            <CancelButton
                                variant="primary"
                                type="cancel"
                                onClick={onCancle}
                                data-testid="reset-button"
                            >
                                Reset
                            </CancelButton>
                        </ButtonSpinnerHorizontalComponent>
                    </Form>
                    <SpinnerComponent>
                        {newBenchmarkLoading === LoadingState.Pending ? (
                            <Spinner animation="border" variant="dark" />
                        ) : (
                            <div></div>
                        )}
                    </SpinnerComponent>
                    <ModalPopupContainer>
                        {/* Confirmation message Model popup */}
                        <Modal
                            show={showConfirmationPopup}
                            onHide={handleConfirmationClose}
                            backdrop="static"
                            keyboard={false}
                        >
                            <Modal.Header>
                                <Modal.Title>Confirmation</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {newBenchmarkResult?.msg && (
                                    <NewBenchmarkResultLabel>
                                        {newBenchmarkResult?.msg}
                                    </NewBenchmarkResultLabel>
                                )}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    variant="primary"
                                    type="submit"
                                    onClick={handleConfirmationClose}
                                >
                                    OK
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </ModalPopupContainer>
                    <AlertComponent
                        id="errorAlert"
                        variant="danger"
                        show={newBenchmarkError ? true : false}
                        data-testid="error-text"
                    >
                        {'Error!' + newBenchmarkError?.message}
                    </AlertComponent>
                </NewBenchmarkFormComponent>
            </HorizontalContainer>
        </NewBenchmarkFormContainer>
    )
}

export default NewBenchmarkPanel
