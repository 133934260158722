import { API_SERVICE_URL } from '../config/service'
import api from './auth/client'

const serviceConfig = require('../config/service.json')
const queryString = require('query-string')

class DiagnosticsService {
    async getDiagnostics() {
        const url = API_SERVICE_URL + serviceConfig.diagnosticsURL
        return await api.get(url)
    }
    async getUserGroups() {
        const url = API_SERVICE_URL + serviceConfig.userGroupsUrl
        return await api.get(url)
    }

    async submitDiagnostic(diagnostic: any) {
        const onUploadProgress = diagnostic.callback

        let formData = new FormData()
        const file = diagnostic.upload[0].originFileObj
        formData.append('attachment', file)

        const url = API_SERVICE_URL + serviceConfig.diagnosticsURL
        return await api.post(
            `${url}?filename=${file.name}`,
            formData.get('attachment'),
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress,
            }
        )
    }

    async downloadDiagnostic(uuid: string) {
        const url = API_SERVICE_URL + serviceConfig.diagnosticsURL
        const response = await api.get(`${url}/${uuid}`, {
            responseType: 'blob',
        })

        return response
    }

    async deleteDiagnostic(uuid: string) {
        const url = API_SERVICE_URL + serviceConfig.diagnosticsURL
        return await api.delete(`${url}/${uuid}`)
    }
    async processDiagnostic(uuid: string) {
        const url = API_SERVICE_URL + serviceConfig.diagnosticsURL
        return await api.post(`${url}/${uuid}/process`)
    }
    async downloadScraperScript(cspName: string) {
        const url = API_SERVICE_URL + serviceConfig.downloadScrapersURL
        const response = await api.get(`${url}/${cspName}`, {
            responseType: 'blob',
        })

        return response
    }
}

export default new DiagnosticsService()
