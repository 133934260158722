import { FC, useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import DashboardMetricCard from '../shared/cards/DashboardMetricCard'
import { useAppSelector } from '../../../config/hooks'

const mostMetricsCardsGridSx = { paddingTop: '1rem' }

type MostMetric = {
    count: number | null
    name: string | undefined
}

const initialMostMetric = {
    count: null,
    name: undefined,
}

const findMost = (array: any[], nameKey: string) =>
    array.reduce(
        (previous: MostMetric, current: any) => {
            return current[nameKey] !== '' &&
                current.Count > Number(previous.count)
                ? {
                      count: current.Count,
                      name: current[nameKey],
                  }
                : previous
        },
        { count: 0, name: undefined }
    )

interface MostMetricsCardsProps {}

const MostMetricsCards: FC<MostMetricsCardsProps> = () => {
    const uniqueLoginsCount = useAppSelector<any>(
        (state: any) =>
            state.getUniqueLoginsResult.data?.UniqueTotalLogins || null
    )
    const uniqueLoginsLoading = useAppSelector<any>(
        (state: any) => state.getUniqueLoginsResult.loading
    )
    const dashboardData = useAppSelector<any>(
        (state: any) => state.getDashboardDataResult.data
    )
    const dashboardDataLoading = useAppSelector(
        (state: any) => state.getDashboardDataResult.loading
    )
    const totalHitsCount = useAppSelector<any>((state: any) => {
        let totalHitsCount = 0
        if (state.getTotalHitsResult.data?.Total) {
            totalHitsCount += state.getTotalHitsResult.data?.Total
        }
        if (state.getTotalNoHitsResult.data?.Total) {
            totalHitsCount += state.getTotalNoHitsResult.data?.Total
        }

        return totalHitsCount
    })
    const totalHitsLoading = useAppSelector(
        (state: any) => state.getTotalHitsResult.loading
    )
    const totalNoHitsLoading = useAppSelector(
        (state: any) => state.getTotalNoHitsResult.loading
    )

    const [mostSearched, setMostSearched] =
        useState<MostMetric>(initialMostMetric)
    const [mostViewed, setMostViewed] = useState<MostMetric>(initialMostMetric)
    const [mostClicked, setMostClicked] =
        useState<MostMetric>(initialMostMetric)

    const mostMetricOptions = [
        {
            arrayKey: 'searchperappsearch',
            nameKey: 'Appsearch',
            setter: setMostSearched,
        },
        {
            arrayKey: 'searchperrecipe',
            nameKey: 'OriginalAppName',
            setter: setMostViewed,
        },
        {
            arrayKey: 'downloadsperfile',
            nameKey: 'OriginalAppName',
            setter: setMostClicked,
        },
    ]

    useEffect(() => {
        if (dashboardData) {
            for (let option of mostMetricOptions) {
                const { arrayKey, nameKey, setter } = option
                if (dashboardData.filteredMetrics[arrayKey].length > 0) {
                    const array = dashboardData.filteredMetrics[arrayKey]
                    setter(findMost(array, nameKey))
                }
            }
        }
    }, [dashboardData])

    return (
        <Grid
            container
            columns={30}
            spacing={2}
            alignItems="stretch"
            sx={mostMetricsCardsGridSx}
        >
            <Grid item xs={30} sm={15} lg={6}>
                <DashboardMetricCard
                    title="Unique Logins"
                    count={uniqueLoginsCount}
                    loading={uniqueLoginsLoading}
                    dataTestId="Unique Logins-label"
                />
            </Grid>
            <Grid item xs={30} sm={15} lg={6}>
                <DashboardMetricCard
                    title="Most Searched"
                    count={mostSearched.count}
                    technologyName={mostSearched.name}
                    loading={dashboardDataLoading}
                    dataTestId="Most Searched-label"
                />
            </Grid>
            <Grid item xs={30} sm={15} lg={6}>
                <DashboardMetricCard
                    title="Most Viewed"
                    count={mostViewed.count}
                    technologyName={mostViewed.name}
                    loading={dashboardDataLoading}
                    dataTestId="Most Viewed-label"
                />
            </Grid>
            <Grid item xs={30} sm={15} lg={6}>
                <DashboardMetricCard
                    title="Most Clicked"
                    count={mostClicked.count}
                    technologyName={mostClicked.name}
                    loading={dashboardDataLoading}
                    dataTestId="Most Clicked-label"
                />
            </Grid>
            <Grid item xs={30} sm={15} lg={6}>
                <DashboardMetricCard
                    title="Total Searches"
                    count={totalHitsCount}
                    loading={[totalHitsLoading, totalNoHitsLoading]}
                    dataTestId="Total Searches-label"
                />
            </Grid>
        </Grid>
    )
}

export default MostMetricsCards
