import { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import stringUtils from '../../lib/stringUtils'
import { AcceptInvitesRequest } from '../../models/acceptInvitesRequest'
import { IAcceptInviteContainerProps } from './acceptInviteContainer'

export function AcceptInviteContainerLogic({
    acceptInvites,
    acceptInvitesError,
    acceptInvitesResult,
    invitationKey,
}: IAcceptInviteContainerProps) {
    const [userFullName, setUserFullName] = useState<string>('')
    const [inviteError, setInviteError] = useState<any>('')
    const [validated, setValidated] = useState(false)

    let navigate = useNavigate()

    const onUserFullNameChange = useCallback(
        (event: ChangeEvent<HTMLElement>) => {
            const target = event.currentTarget as HTMLTextAreaElement
            if (target.value) {
                setUserFullName(target.value)
            }
        },
        []
    )

    const onAccept = useCallback(
        async (event: FormEvent<HTMLFormElement>) => {
            event.preventDefault()

            const form = event.currentTarget
            if (form.checkValidity() === false) {
                event.preventDefault()
                event.stopPropagation()
            }
            setValidated(true)

            if (!stringUtils.isNullOrUndefinedOrEmpty(userFullName)) {
                acceptInvites({
                    decision: 'accept',
                    invitationKey: invitationKey,
                    displayName: userFullName,
                } as AcceptInvitesRequest)
            }
        },
        [userFullName, invitationKey]
    )

    const onDecline = useCallback(
        (event: FormEvent<HTMLElement>) => {
            event.preventDefault()
            setValidated(false)
            setInviteError(null)
            acceptInvites({
                decision: 'decline',
                invitationKey: invitationKey,
            } as AcceptInvitesRequest)
        },
        [invitationKey]
    )

    useEffect(() => {
        if (acceptInvitesError) {
            setInviteError('Error adding existing user to the invited org' )
        } else {
            if (acceptInvitesResult) {
                localStorage.setItem('invitationKey', '')
                navigate('/catalog')
            }
        }
    }, [acceptInvitesError, acceptInvitesResult])

    return {
        onUserFullNameChange,
        onAccept,
        onDecline,
        inviteError,
        validated,
    }
}
