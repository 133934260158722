import { FunctionComponent, useEffect, useState } from 'react'
import Pagination from 'react-bootstrap/Pagination'

export interface IPagination {
    totalPage: number
    currentPage: number
    onPageClicked: (pageNumber: number) => void
}

export const PaginationPanel: FunctionComponent<IPagination> = ({
    totalPage,
    currentPage,
    onPageClicked,
}: IPagination) => {
    const [pageArray, setPageArray] = useState<number[]>([])
    useEffect(() => {
        var pageArr: any[] = []
        if (totalPage > 1) {
            if (totalPage <= 9) {
                var i: number = 1
                while (i <= totalPage) {
                    pageArr.push(i)
                    i++
                }
            } else {
                if (currentPage <= 5)
                    pageArr = [1, 2, 3, 4, 5, 6, 7, 8, '', totalPage]
                else if (totalPage - currentPage <= 4)
                    pageArr = [
                        1,
                        '',
                        totalPage - 7,
                        totalPage - 6,
                        totalPage - 5,
                        totalPage - 4,
                        totalPage - 3,
                        totalPage - 2,
                        totalPage - 1,
                        totalPage,
                    ]
                else
                    pageArr = [
                        1,
                        '',
                        currentPage - 3,
                        currentPage - 2,
                        currentPage - 1,
                        currentPage,
                        currentPage + 1,
                        currentPage + 2,
                        currentPage + 3,
                        totalPage,
                    ]
            }
        }
        setPageArray(pageArr)
    }, [totalPage])

    return (
        <Pagination>
            {pageArray.map((ele, ind) => {
                const toReturn = []

                if (ind === 0) {
                    toReturn.push(
                        <Pagination.First
                            key={'firstpage'}
                            onClick={
                                currentPage === 1
                                    ? () => {}
                                    : () => {
                                          onPageClicked(1)
                                      }
                            }
                            href="javascript:void(0)"
                        />
                    )

                    toReturn.push(
                        <Pagination.Prev
                            key={'prevpage'}
                            onClick={
                                currentPage === 1
                                    ? () => {}
                                    : () => {
                                          onPageClicked(currentPage - 1)
                                      }
                            }
                            href="javascript:void(0)"
                        />
                    )
                }
                if (ele !== undefined && ele.toString() === '')
                    toReturn.push(<Pagination.Ellipsis key={ind + '...'} />)
                if (ele !== undefined && ele.toString() !== '')
                    toReturn.push(
                        <Pagination.Item
                            key={ind}
                            active={currentPage === ele ? true : false}
                            onClick={
                                currentPage === ele
                                    ? () => {}
                                    : () => {
                                          onPageClicked(ele)
                                      }
                            }
                            href="javascript:void(0)"
                        >
                            {ele}
                        </Pagination.Item>
                    )

                if (ind === pageArray.length - 1) {
                    toReturn.push(
                        <Pagination.Next
                            key={'nextpage'}
                            onClick={
                                currentPage === ele
                                    ? () => {}
                                    : () => {
                                          onPageClicked(currentPage + 1)
                                      }
                            }
                            href="javascript:void(0)"
                        />
                    )

                    toReturn.push(
                        <Pagination.Last
                            key={'lastpage'}
                            onClick={
                                currentPage === ele
                                    ? () => {}
                                    : () => {
                                          onPageClicked(ele)
                                      }
                            }
                            href="javascript:void(0)"
                        />
                    )
                }

                return toReturn
            })}
        </Pagination>
    )
}
export default PaginationPanel
